import React from 'react'
import File from '../../../../images/image 2.png'
import EmployeeViaCsvTemplate from '../../../../images/EmployeeViaCsvTemplate.csv'
const AsiignViaCsv = ({ changeHandler, csvFile, nameOfFile, submitHandler }) => (
    <div className='container'>
        {/* <h3 className='text-center blue-text'>Assign Via CSV </h3> */}
        <div className='row'>

            <div className='col-sm-6 col-xs-12'>
                <div className='card-csv text-center'>
                    <h3><b>Upload CSV File</b></h3>
                    <div className='card-inner' style={{ background: '#DBE9FF', padding: '20px', marginTop: '30px', borderRadius: '10px', minHeight: '200px' }}>
                        <div className="upload-csv text-center" style={{ marginTop: '53px' }}>
                            <label className="" for="formFileLg">
                                <i className="bi bi-cloud-arrow-up-fill me-3"></i>
                                <span className="align-middle">
                                    {nameOfFile ? nameOfFile : <><i className='fa fa-cloud-upload' style={{fontSize:'20px',marginTop:'10px'}} ></i>&nbsp;&nbsp;  Click here to Upload CSV File</>}

                                </span>
                            </label>
                            <input
                                className="form-control form-control-lg"
                                id="formFileLg"
                                accept='.csv'
                                type='file' onChange={(e) => changeHandler(e)}
                            />
                        </div>
                    </div>
                    {nameOfFile?
                    <div className='text-center' style={{ marginTop: '20px' }}>
                        <button className='btn btn-primary' style={{ background: '#2B78FF' }} onClick={() => submitHandler()}>Submit</button>
                    </div>
                    :""}
                </div>

            </div>
            <div className='col-sm-6 col-xs-12 text-center'>
                <div className='card-csv'>
                    <h3><b>CSV Sample File </b></h3>
                    <img src={File} width='70%' style={{ marginTop: '20px', marginBottom: '20px' }} />
                   <a href={EmployeeViaCsvTemplate}> <button className='btn btn-primary' style={{ background: '#2B78FF', marginBottom: '20px' }}><i className='fa fa-cloud-download'></i>&nbsp;&nbsp;Download CSV Sample File </button></a>
                </div>
            </div>

        </div>
    </div>
)
export default AsiignViaCsv