import React from 'react'
import { TYPE, STRING, PLACEHOLDER } from '../../../utils/Constant'
import DatePicker from 'react-datepicker';

const ApproveDataPopup = ({ approveData, approveDataHandler, approveDataChange,
    closeApprovePopup, emiTableData, errorMsg, disabled }) => (
        <div className="">
            <div className="background-blur"></div>
            <div className="popup-main small-popup container-fluid dbl-border">
                <div className="row from-group">
                    <div className="col-xs-12">
                        <button className="close" onClick={e => closeApprovePopup()}>&times;</button>
                        <h4 className="text-center" style={{ height: "18px" }}>Kindly confirm the details For NBFC<br />&nbsp;</h4>
                    </div>
                </div>
                <div className="row form-group">
                    <div className="col-sm-6 col-xs-12">
                        <label className="font-12px">Select Date</label>
                        <DatePicker
                            value={approveData.approveDate}
                            selected={approveData.approveDateToShow}
                            onChange={(e) => approveDataChange(e, TYPE.LOAN_APPROVE_DATE, '')}
                            className="form-control"
                            placeholderText="select Date"
                            dateFormat="DD-MM-YYYY"
                            fixedHeight
                        />
                    </div>
                    <div className="col-sm-6 col-xs-12">
                        <label className="font-12px">Enter Amount</label>
                        <input type="number" className="form-control" value={approveData.ApproveAmount} onChange={(e) => approveDataChange(e, TYPE.LOAN_AMOUNT, '')} />
                    </div>
                </div>
                <div className="row">
                    {/* <div className="col-sm-6 col-xs-12 form-group">
                        <label className="font-12px">Select instalment</label>
                        <select required className="form-control" id="phocketEMI" value={approveData.selectEMI} onChange={(e) => approveDataChange(e, TYPE.SELECT_EMI, '')} >
                            <option value="" disabled selected>{PLACEHOLDER.SELECT_EMI}</option>
                            <option value={STRING.FIRST_EMI}>{STRING.FIRST_EMI + " Month"}</option>
                            <option value={STRING.SECOND_EMI}>{STRING.SECOND_EMI + " Months"}</option>
                            <option value={STRING.THIRD_EMI}>{STRING.THIRD_EMI + " Months"}</option>
                        </select>
                    </div> */}
                    {emiTableData != null && emiTableData != '' && emiTableData != undefined && emiTableData.length > 0 ?
                        emiTableData.map((emi, i) => {
                            return (
                                <div key={i} className="col-sm-4 col-xs-12 form-group">
                                    <label className="font-12px">{i + 1} Repayment Date</label>
                                    <DatePicker
                                        value={approveData['repaymentDate' + i]}
                                        selected={approveData['repaymentDateToShow' + i]}
                                        onChange={(e) => approveDataChange(e, TYPE.FIRST_REPAYMENT_DATE, i)}
                                        className="form-control"
                                        placeholderText="select Date"
                                        dateFormat="DD/MM/YYYY"
                                        id={"RepaymentDate_" + i}
                                        fixedHeight
                                    />
                                </div>

                            )
                        })
                        : null}
                </div>
                <p className="text-center height-20px red-text margin-0">{errorMsg}</p>
                <div className="row text-center">
                    <div className="col-xs-12">
                        <button className="profile-save-btn mt-0px" disabled={disabled} onClick={e => approveDataHandler()}>SAVE</button>
                    </div>
                </div>
            </div>
        </div>
    )

export default ApproveDataPopup;