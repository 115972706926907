import React from 'react';
import moment from 'moment';
import 'rc-time-picker/assets/index.css';
import TimePicker from 'rc-time-picker';
import { TYPE } from '../../../../utils/Constant';

const FieldBoy = ({ fieldBoyHandler, fieldBoyChange, fieldBoyData, employeeData,
    assignedData, AddressHandler, updateData,
    docType, progressBarState, progressValue }) => (
        <div className="container-fluid">
            <div className="row max-width-500px">
                <h3 className="text-center blue-text">Assign Tasks</h3>
                <div className="col-xs-12 form-group">
                    <label className="font-12px">Field Boy</label>
                    <select className="form-control" value={fieldBoyData.boyNameState} onChange={e => fieldBoyChange(e, 'selectBoy')}>
                        <optgroup className="display-none">
                            <option value="">Select one</option>
                        </optgroup>
                        <optgroup label="Members">
                            {employeeData != '' && employeeData.length > 0 ?
                                employeeData.map((data,i) => {
                                    return (
                                        <option key={i} value={data.userid}>{data.name}</option>
                                    )
                                })
                                : null}
                        </optgroup>
                    </select>
                </div>
                <div className="col-xs-12">
                    <label className="font-12px">Address</label>
                    <textarea className="form-control" value={fieldBoyData.addressState} onChange={e => fieldBoyChange(e, 'address')}></textarea>
                </div>
                <div className="row margin-0">
                    <div className="col-xs-4 form-group">
                        <input type="radio" id="SelectPermanentAddress" name="addressType" onChange={(e) => AddressHandler('permanent')} />
                        <span>&nbsp;&nbsp;Permanent</span>
                    </div>
                    <div className="col-xs-4 form-group">
                        <input type="radio" id="SelectCurrentAddress" name="addressType" onChange={(e) => AddressHandler('current')} />
                        <span>&nbsp;&nbsp;Current</span>
                    </div>
                    <div className="col-xs-4 form-group">
                        <input type="radio" id="SelectCurrentAddress" name="addressType" onChange={(e) => AddressHandler('office')} />
                        <span>&nbsp;&nbsp;Office</span>
                    </div>
                </div>
                <div className="row margin-0">
                    <div className="col-xs-6 form-group">
                        <label className="font-12px">Time From</label>
                        <TimePicker
                            format="HH:mm"
                            showSecond={false}
                            value={fieldBoyData.timeFrom}
                            minuteStep={10}
                            className="full-width"
                            onChange={e => fieldBoyChange(e, 'timeFrom')}
                        />
                        {/* <input type="text" value={fieldBoyData.timeSlotState} className="form-control" onChange={e => fieldBoyChange(e, 'timeSlot')} /> */}
                    </div>
                    <div className="col-xs-6 form-group">
                        <label className="font-12px">Time To</label>
                        <TimePicker
                            format="HH:mm"
                            showSecond={false}
                            value={fieldBoyData.timeTo}
                            minuteStep={10}
                            className="full-width"
                            onChange={e => fieldBoyChange(e, 'timeTo')}
                        />
                        {/* <input type="text" value={fieldBoyData.timeSlotState} className="form-control" onChange={e => fieldBoyChange(e, 'timeSlot')} /> */}
                    </div>
                </div>
                <div className="col-xs-12 form-group text-center">
                    <button className="btn profile-save-btn display-inline" onClick={e => fieldBoyHandler('save')}>SAVE</button>
                    <button className="btn profile-save-btn display-inline margin-left-25px" onClick={e => fieldBoyHandler('update')}>UPDATE</button>
                </div>
            </div>
            <div className="row max-width-750px center-block">
                <div className="col-xs-12 col-sm-4">
                    <label className="block bold">Cheque Img</label>
                    <label htmlFor="ChequeImg" className="input-field-label-new">
                        <span className="fa fa-paperclip"></span>
                    </label>
                    <span className="font-12px document-upload-ellipsis">{fieldBoyData.ChequeState}</span>
                    <input multiple="multiple" accept="image/png , image/jpeg" type="file" className="form-control display-none" id="ChequeImg" onChange={(e) => fieldBoyChange(e, TYPE.CHEQUE)} />
                    <div className="font-12px margin-left-25px validation-color">
                        {docType == TYPE.CHEQUE && progressBarState == true ?
                            <progress value={progressValue} max="100"></progress>
                            : ""}
                    </div>
                </div>
                <div className="col-xs-12 col-sm-4">
                    <label className="block bold">Location Img</label>
                    <label htmlFor="LocationImg" className="input-field-label-new">
                        <span className="fa fa-paperclip"></span>
                    </label>
                    <span className="font-12px document-upload-ellipsis">{fieldBoyData.LocationState}</span>
                    <input multiple="multiple" accept="image/png , image/jpeg" type="file" className="form-control display-none" id="LocationImg" onChange={(e) => fieldBoyChange(e, TYPE.LOCATION)} />
                    <div className="font-12px margin-left-25px validation-color">
                        {docType == TYPE.LOCATION && progressBarState == true ?
                            <progress value={progressValue} max="100"></progress>
                            : ""}
                    </div>
                </div>
                <div className="col-xs-12 col-sm-4">
                    <label className="block bold">Id Img</label>
                    <label htmlFor="idProofImg" className="input-field-label-new">
                        <span className="fa fa-paperclip"></span>
                    </label>
                    <span className="font-12px document-upload-ellipsis">{fieldBoyData.idProofState}</span>
                    <input multiple="multiple" accept="image/png , image/jpeg" type="file" className="form-control display-none" id="idProofImg" onChange={(e) => fieldBoyChange(e, TYPE.OFFICE_ID)} />
                    <div className="font-12px margin-left-25px validation-color">
                        {docType == TYPE.OFFICE_ID && progressBarState == true ?
                            <progress value={progressValue} max="100"></progress>
                            : ""}
                    </div>
                </div>
            </div>
            {assignedData != '' && assignedData != null ?
                <div className="row form-group">
                    <h3 className="text-center blue-text">Assigned Data</h3>
                    <div className="col-xs-12 overflow-auto">
                        <table className="text-center loan-detail-table">
                            <thead>
                                <tr>
                                    <th>Employee Id</th>
                                    <th>Address</th>
                                    <th>TimeSlot</th>
                                    <th>Distance</th>
                                    <th>Created On</th>
                                    <th>AssignedBy</th>
                                    <th>Cheque Img</th>
                                    <th>Location Img</th>
                                    <th>Id Img</th>
                                    <th>Edit</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{assignedData.empid}</td>
                                    <td>{assignedData.address}</td>
                                    <td>{assignedData.timeSlot}</td>
                                    <td>{assignedData.distance}</td>
                                    <td>{moment(new Date(assignedData.createdon)).format('DD-MM-YYYY')}</td>
                                    <td>{assignedData.assignedby}</td>
                                    <td><a href={assignedData.checqueUrl} target="_blank"><img src={assignedData.checqueUrl} className="area-200px" /></a></td>
                                    <td><a href={assignedData.locationImgUrl} target="_blank"><img src={assignedData.locationImgUrl} className="area-200px" /></a></td>
                                    <td><a href={assignedData.officeIdUrl} target="_blank"><img src={assignedData.officeIdUrl} className="area-200px" /></a></td>
                                    <td onClick={e => updateData(assignedData)}><span className="fa fa-pencil-square-o pointer" /></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                : null}
        </div>
    )

export default FieldBoy;