const apiReducer = (state, action) => {
    switch (action.type) {
      case "FETCH_SUCCESS":
        return {
          ...state,
          loading: false,
          data: action.payload,
          error: null,
        };
      case "FETCH_ERROR":
        return {
          ...state,
          loading: false,
          data: [],
          error: action.payload,
        };
      default:
        return state;
    }
  };
  
  export default apiReducer;
  