import React from 'react'
import ReactTable from "react-table";
import { filterCaseInsensitive } from '../../../../utils/commonUtility'
import { ROLE } from '../../../../utils/Constant'
import DatePicker from 'react-datepicker';

const ScheduledCall = ({ scheduledLoanData, getFilterValue, FilteredData, admin,
    getPageValue, pageIndexToShow, userDetailHandler, searchByDate, dateFilter }) => (
    <div className="container-fluid full-height">
        <h3 className="text-center blue-text">Scheduled Calls</h3>
        <div className="row form-group">
            <div className="col-sm-4 col-xs-12"></div>
            <div className="col-sm-4 col-xs-12">
                <DatePicker
                    value={searchByDate}
                    onChange={e => dateFilter(e)}
                    className="form-control"
                    dateFormat="DD/MM/YYYY"
                    placeholderText="Select Date"
                />
            </div>
            <div className="col-sm-4 col-xs-12">
            </div>
        </div>

        {scheduledLoanData != null && scheduledLoanData != '' && scheduledLoanData.length > 0 ?
            <div className='overflow-auto'>
                <table className='phocket-table'>
                    <thead>

                        <tr>
                            <th>Details</th>
                            <th>Application Id</th>
                            <th>User Name</th>
                            <th>Phone Number</th>
                            <th>Approved Amount</th>
                            <th>PayBack Date</th>
                            <th>Call Duration</th>
                            <th>Call Time</th>
                            <th>Call Scheduled Date</th>
                            <th>Call Shift</th>
                            <th>IVR Status</th>
                        </tr>

                    </thead>
                    <tbody>
                        {scheduledLoanData && scheduledLoanData.map((promo, i) => {
                            return (
                                <tr key={i}>
                                    <td>  <div>
                                        {promo.CallShift == 0 ? 'AfterNoon' : promo.CallShift == 1 ? 'Evening' : null}
                                    </div></td>
                                    <td>{promo.ApplicationID}</td>
                                    <td>{promo.UserName}</td>
                                    <td>{promo.PhoneNumber}</td>
                                    <td>{promo.ApprovedAmount}</td>
                                    <td>{promo.Tenure}</td>
                                    <td>{promo.CallDuration}</td>
                                    <td>{promo.CallTime}</td>
                                    <td>{promo.CallScheduledDate}</td>
                                    <td>  <div>
                                        {data.CallShift == 0 ? 'AfterNoon' : data.CallShift == 1 ? 'Evening' : null}
                                    </div>
                                    </td>
                                    <td> <div>
                                        {data.IVRStatus == -2 ? null
                                            : data.IVRStatus == -1 ? "Didn't Picked"
                                                : data.IVRStatus == 1 ? "Accepted"
                                                    : data.IVRStatus == 2 ? "Declined"
                                                        : null}
                                    </div></td>


                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
            : ""}
    </div>
)

export default ScheduledCall;