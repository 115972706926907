import React from 'react'
import ProductConfigUi from '../../../presentationals/Admin/NbfcManagementNew/productConfig.component';
import PopUp from '../../../presentationals/Popup/Popup.component';
import { saveProductConfigNbfc } from '../AdminActionCreator.component';
import { nbfcProductConfigurationValidation } from '../../../../utils/validation';
import Loader from '../../../presentationals/Loader/Loader.component'
import moment from 'moment';

class ProductConfig extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            productConfig: {
                id: '',
                nbfcId: '',
                roiReflectDate: '',
                previouslyAffect: '',
                stampDutyCharge: '',
                penaltyInterest: '',
                penaltyAmount: '',
                normalInterest: '',
                emiPenaltyInterest: '',
                gstInterest: '',
                chequeBounceCharge: '',
                mandateBounceCharge: '',
                interestType: '',
                gstIncluded: '',
                emiType: '',
                // processingType:''
            },
            validationMsg: '',
            popupState: false,
            popupStatus: '',
            loaderState: false

        }
    }
    componentDidMount() {
        if (this.props && this.props.nbfcId !== null && this.props.nbfcId !== undefined) {
            let changeData = Object.assign({}, this.state.productConfig)
            changeData.nbfcId = this.props.nbfcId
            this.setState({ productConfig: changeData })
        }
        if (this.props && this.props.nbfcData !== undefined && this.props.nbfcData !== '') {
         

            let changeData = Object.assign({}, this.state.productConfig)
            changeData.roiReflectDate = moment(new Date(this.props.nbfcData.roiReflectDate)).format('yyyy-MM-DD')
            changeData.previouslyAffect = this.props.nbfcData.previouslyAffect
            changeData.stampDutyCharge = this.props.nbfcData.stampDutyCharge
            changeData.penaltyInterest = this.props.nbfcData.penaltyInterest
            changeData.penaltyAmount = this.props.nbfcData.penaltyAmount
            changeData.normalInterest = this.props.nbfcData.normalInterest
            changeData.emiPenaltyInterest = this.props.nbfcData.emiPenaltyInterest
            changeData.gstInterest = this.props.nbfcData.gstInterest
            changeData.chequeBounceCharge = this.props.nbfcData.chequeBounceCharge
            changeData.mandateBounceCharge = this.props.nbfcData.mandateBounceCharge
            changeData.interestType = this.props.nbfcData.interestType
            changeData.gstIncluded = this.props.nbfcData.gstIncluded
            changeData.emiType = this.props.nbfcData.emiType
            changeData.nbfcId = this.props.nbfcData.nbfcId
            changeData.id = this.props.nbfcData.id
            this.setState({ productConfig: changeData })
        }
    }
    removePopup() {
        setTimeout(function () {
            this.setState({ popupState: false });
        }.bind(this), 5000)
    }

    closePopup() {
        this.setState({ popupState: false });
    }
    changeHandler(e, type) {
        let changeData = Object.assign({}, this.state.productConfig)
        if (type === 'roiReflectDate') {
            // console.log(e.target.value)
            changeData.roiReflectDate = e.target.value
        }
        if (type === 'previouslyAffect') {
            changeData.previouslyAffect = e.target.value
        }
        if (type === 'stampDutyCharge') {
            changeData.stampDutyCharge = e.target.value
        }
        if (type === 'penaltyInterest') {
            changeData.penaltyInterest = e.target.value
        }
        if (type === 'penaltyAmount') {
            changeData.penaltyAmount = e.target.value
        }
        if (type === 'normalInterest') {
            changeData.normalInterest = e.target.value
        }
        if (type === 'emiPenaltyInterest') {
            changeData.emiPenaltyInterest = e.target.value
        }
        if (type === 'gstInterest') {
            changeData.gstInterest = e.target.value
        }
        if (type === 'chequeBounceCharge') {
            changeData.chequeBounceCharge = e.target.value
        }
        if (type === 'mandateBounceCharge') {
            changeData.mandateBounceCharge = e.target.value
        }
        if (type === 'interestType') {
            changeData.interestType = e.target.value
        }
        if (type === 'gstIncluded') {
            changeData.gstIncluded = e.target.value
        }
        if (type === 'emiType') {
            changeData.emiType = e.target.value
        }
        // if (type === 'processingType') {
        //     changeData.processingType = e.target.value
        // }
        this.setState({ productConfig: changeData })
    }
    submit() {
        let validation = nbfcProductConfigurationValidation(this.state.productConfig)
        if (validation.status) {
            this.setState({ loaderState: true })
            saveProductConfigNbfc(this.state.productConfig, callback => {
                if (callback === 'success') {
                    this.setState({ loaderState: false, popupStatus: 'Successfully Saved !', popupState: true }, () => this.removePopup())
                } else {
                    this.setState({ loaderState: false, popupStatus: 'Please try again later !', popupState: true }, () => this.removePopup())

                }
            })
        } else {
            this.setState({ validationMsg: validation })
        }
    }
    render() {
        return (
            <div>
                <ProductConfigUi
                    validationMsg={this.state.validationMsg}
                    submit={this.submit.bind(this)}
                    productConfig={this.state.productConfig}
                    changeHandler={this.changeHandler.bind(this)}
                />
                {this.state.popupState == true ?
                    <PopUp
                        closePopup={this.closePopup.bind(this)}
                        popupStatus={this.state.popupStatus}
                    />
                    : ""}
                {this.state.loaderState ?
                    <Loader />
                    : ""}
            </div>
        )
    }
}
export default ProductConfig