import React, { Component } from 'react'
import RegisterationUi from '../../../presentationals/Admin/NbfcManagement/Registration.component'

class Registration extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    // componentWillMount() {
    //     fetch(APIS.COUPON_STATS)
    //         .then(res => res.json())
    //         .then(res => {
    //             this.setState({ couponStatData: res })
    //         })
    // }
    render() {
        return (
            <div className="">
                <RegisterationUi />
            </div>
        )
    }

}
export default Registration;