import React from "react";
import { getEmailsByDepartment, saveAssignByStatusAPINew } from "../../AdminActionCreator.component";
import ListingRule from "./listing";
import { APIS } from "../../../../../utils/api-factory";
import Loader from '../../../../presentationals/Loader/Loader.component'

class CompleteLeads extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            salaryBucket: '>30000',
            departMent: ['Finance / Credit'],
            agentsEmail: '',
            status: 'repeat',
            emailId: '',
            userStatusData:'',
            loaderState:false


        }
    }
    componentDidMount() {
        this.getAssignedByStatus()

        getEmailsByDepartment(this.state.departMent, callBack => {
            this.setState({ agentsEmail: callBack })
        })

    }
    getAssignedByStatus() {
        this.setState({ loaderState: true })
        fetch(APIS.GET_ASSIGN_BY_STATUS +'?status='+this.state.status)
            .then(res => res.json())
            .then(res => {
                this.setState({
                    userStatusData: res,
                    loaderState: false
                })

            })
    }
    changeHandler(e, data) {
        console.log(e.target.value)
        if (data === 'agent') {
            this.setState({ emailId: e.target.value })
        }
        if (data === 'salary') {
            this.setState({ salaryBucket: e.target.value })
        }
    }

    saveData() {
        const { admin } = this.props
        console.log(this.state.emailId)
        saveAssignByStatusAPINew(this.state.status, this.state.emailId, admin, this.state.salaryBucket, callBack => {
            console.log(callBack)
            this.getAssignedByStatus()
        })
    }
    render() {
        const { userStatusData } = this.props
        return (
            <div className="container-fluid details-new">
                    {this.state.loaderState ?
                    <Loader />
                    : ""}
                <div className="row" style={{ marginTop: '20px' }}>
                    <div className="col-sm-3 col-xs-12">
                        <label>Agent Email Id</label>
                        <select className="form-control" style={{ border: 'none' }} onChange={(e) => this.changeHandler(e, 'agent')}>
                            <option value={''}>Select Agent</option>
                            {this.state.agentsEmail.length > 0 ?
                                this.state.agentsEmail.map((data, i) => {
                                    return (
                                        <option value={data.emailId}>{data.emailId}</option>
                                    )
                                })
                                : ""}
                        </select>
                    </div>
                    <div className="col-sm-3 col-xs-12">
                        <label>Salary Bucket</label>
                        <select value={this.state.salaryBucket} className="form-control" onChange={(e) => this.changeHandler(e, 'salary')} style={{ border: 'none' }}>
                            <option value={''} >Select Salary</option>
                            <option value={"<30000"}>less then 30000</option>
                            <option value={">30000"}>greater then 30000</option>

                        </select>
                    </div>
                    <div className="col-sm-1 col-xs-12">
                        <button className="btn btn-primary" style={{ background: '#2B78FF', marginTop: '22px', width: '100%' }} onClick={() => this.saveData()}>Save</button>
                    </div>
                </div>
                <hr />
                <ListingRule getAssignedByStatus={this.getAssignedByStatus.bind(this)} userStatusData={this.state.userStatusData} />
            </div>
        )
    }
}
export default CompleteLeads