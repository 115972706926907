import React from 'react'

const MessagePage = ({ sendHandler, commentHandler, commentDataState }) => (
    <div className="container-fluid">
        <div className="row">
            <div className="col-sm-5 col-xs-12">
                <h3 className="text-center blue-text">Comment</h3>
                <textarea className="form-control white-bg-transparent" rows="15" placeholder="Comment..." onChange={e => commentHandler(e)} />
                <button className="profile-save-btn center-block" id="submitDetail" onClick={e => sendHandler()}><span className="fa fa-save" />&nbsp;&nbsp;Save</button>
            </div>
            <div className="col-sm-7 col-xs-12">
                <div className="comment-details">
                    <ul className="admin-detail-list">
                        <li><h3 className="text-center blue-text">Logs</h3></li>
                        {commentDataState != '' && commentDataState != null && commentDataState.length > 0 ?
                            commentDataState.map((commentData, i) => {
                                return (
                                    <li key={i}>
                                        <div className="main amb-comments">
                                            <div className="date">{commentData.date}</div>
                                            <div className="mail">Emp-id:-{commentData.empId}</div>
                                            <div className="type">{commentData.type}</div>
                                            <div className="appid">Ambassador-id:-{commentData.ambassadorId != null ? commentData.ambassadorId : ""}</div>
                                            <div className="display-flex">{commentData.body}</div>
                                        </div>
                                    </li>
                                )
                            })
                            : <div className="text-center"><h3>No data to display</h3></div>}
                    </ul>
                </div>
            </div>
        </div>
    </div>
)

export default MessagePage;