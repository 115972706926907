import React, { Component } from 'react'
import CouponStatistics from '../../../presentationals/Admin/Promotions/CouponStats.component'
import { APIS } from '../../../../utils/api-factory'


class CouponStats extends Component {
    constructor(props) {
        super(props);
        this.state = {
            couponStatData: '',
            FilteredData: [],
            pageIndexToShow: 0,
        }
    }

    componentWillMount() {
        fetch(APIS.COUPON_STATS)
            .then(res => res.json())
            .then(res => {
                this.setState({ couponStatData: res })
            })
    }
    render() {
        return (
            <div className="">
                <CouponStatistics
                    couponStatData={this.state.couponStatData}
                    getFilterValue={this.getFilterValue.bind(this)}
                    FilteredData={this.state.FilteredData}
                    getPageValue={this.getPageValue.bind(this)}
                    pageIndexToShow={this.state.pageIndexToShow}
                />
            </div>
        )
    }

    getFilterValue(filtered) {
        this.setState({ FilteredData: filtered })
    }

    getPageValue(pageIndex) {
        this.setState({ pageIndexToShow: pageIndex })
    }
}
export default CouponStats;