import React from 'react'
import {
    BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer,
    Scatter, ComposedChart, Line,
} from 'recharts';

export default function BarChartComponent({Data,ActiveMonthFilter,Heading}) {

    const data = {
        'Projected Loan amt disbrused': [
            {
                date: 'June 2024',
                value: '60000000',value2:'59388300'
            },
            {
                date: 'July 2024',
                value: '60000000',value2:'54388300'
            },
            {
                date: 'August 2024',
                value: '60000000',value2:'60388300'
            },
        ],
        'Actual Loan amt disbrused': [
            {
                date: 'June 2024',
                value: '49502200',value2:'38938300'
            },
            {
                date: 'July 2024',
                value: '56354000',value2:'68938300'
            },
            {
                date: 'August 2024',
                value: '36652000',value2:'48038300'
            },
        ],
        'Growth of Loan disbrused amt in %': [
            {
                date: 'June 2024',
                value: '28',value2:'30'
            },
            {
                date: 'July 2024',
                value: '19',value2:'5'
            },
            {
                date: 'August 2024',
                value: '88',value2:'57'
            },
        ],
        'Growth of Loan disbrused amt in % Highest Disbrushed': [
            {
                date: 'June 2024',
                value: '59.35',value2:'84.93'
            },
            {
                date: 'July 2024',
                value: '66.08',value2:'73.03'
            },
            {
                date: 'August 2024',
                value: '76.40',value2:'93.02'
            },
        ],
        'Actual Loan Amt disbursed(NC)': [
            {
                date: 'June 2024',
                value: '17053000',value2:'28938300'
            },
            {
                date: 'July 2024',
                value: '14034000',value2:'48938300'
            },
            {
                date: 'August 2024',
                value: '6602000',value2:'58938300'
            },
        ],
        'Actual Loan Amt disbursed(EC)': [
            {
                date: 'June 2024',
                value: '12334024',value2:'12938300'
            },
            {
                date: 'July 2024',
                value: '10394000',value2:'18378200'
            },
            {
                date: 'August 2024',
                value: '14034000',value2:'12038638'
            },
        ],
        'Average Projected Loan Ticket size': [
            {
                date: 'June 2024',
                value: '18000',value2:'16383'
            },
            {
                date: 'July 2024',
                value: '17856',value2:'17392'
            },
            {
                date: 'August 2024',
                value: '19333',value2:'14262'
            },
        ],
        'Target Achieved %': [
            {
                date: 'June 2024',
                value: '103',value2:'91'
            },
            {
                date: 'July 2024',
                value: '90',value2:'89'
            },
            {
                date: 'August 2024',
                value: '61',value2:'78'
            },
        ],
        'Average New Loan Ticket size disbrushed': [
            {
                date: 'June 2024',
                value: '15072',value2:'13893'
            },
            {
                date: 'July 2024',
                value: '16131',value2:'14793'
            },
            {
                date: 'August 2024',
                value: '14671',value2:'16829'
            },
        ],
        'Average Existing Loan Ticket size disbrushed': [
            {
                date: 'June 2024',
                value: '21307',value2:'17927'
            },
            {
                date: 'July 2024',
                value: '20132',value2:'16839'
            },
            {
                date: 'August 2024',
                value: '20635',value2:'23892'
            },
        ],
        'Average Loan Ticket size disbrushed': [
            {
                date: 'June 2024',
                value: '19412',value2:'28922'
            },
            {
                date: 'July 2024',
                value: '19229',value2:'16728'
            },
            {
                date: 'August 2024',
                value: '19220',value2:'16829'
            },
        ],
        '% of Ticket Size': [
            {
                date: 'June 2024',
                value: '107',value2:'103'
            },
            {
                date: 'July 2024',
                value: '109',value2:'97'
            },
            {
                date: 'August 2024',
                value: '112',value2:'88'
            },
        ],
    }
    return (
        <div className="MISDashboard">
            <div className="charts" style={{ marginTop: '20px' }}>
            <h3>{Heading}</h3>
                <div className="chartbar" style={{ height: window?.innerWidth > 1800 ? '730px' : '460px'}}>
                    <ResponsiveContainer width="100%" height="100%">
                        <ComposedChart
                            width={500}
                            height={300}
                            data={data}
                            barSize={ActiveMonthFilter === '3month' ? 40 : ActiveMonthFilter === '6month' ? 30 : 20}
                            margin={{
                                top: 20,
                                right: 0,
                                left: 30,
                                bottom: 5,
                            }}
                        >
                            <CartesianGrid stroke='none' />
                            <XAxis dataKey="date" />
                            <YAxis />
                            <Tooltip />
                            <Bar dataKey="value" fill="#8884d8" />
                            <Line type="monotone" dataKey="value" stroke="#223D64" strokeWidth={2} dot={function customDot() { return null }} />
                        </ComposedChart>
                    </ResponsiveContainer>
                </div>
            </div>
        </div>
    )
}
