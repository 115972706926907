import React  from "react";

const Internal =({matchedSimilarData,similarFilterData,filterType})=>(
    <>
    {matchedSimilarData ? matchedSimilarData.includes('name') ?
        <div className="row">
            <div style={{ border: '0.5px solid #E9ECF5', width: '80%', marginLeft: 'auto', marginRight: 'auto', marginBottom: '10px' }}></div>
            <div className="col-sm-2">
                <div><div style={{ background: '#DBE9FF', width: '2px', marginLeft: 'auto', marginRight: 'auto', height: '40px', marginTop: '-20px' }}>
                </div>
                </div>
            </div>
            <div className="col-sm-5"  onClick={() => similarFilterData('name')} style={{ fontSize: '12px', cursor: 'pointer', color: filterType === 'name' ? '#2B78FF' : '#000', fontWeight: filterType === 'name' ? '700' : '400' }}>
                KYC Match</div>
            <div className="col-sm-5 text-right" style={{ fontSize: '12px', cursor: 'pointer' }} onClick={() => similarFilterData('name')}>

                {matchedSimilarData && matchedSimilarData.length > 0 ? <span style={{ color: '#4CC78C', background: '#E1F6EC', borderRadius: '4px', padding: '2px 10px', marginRight: '5px', fontSize: '10px' }}><i className="fa fa-check-circle"></i>&nbsp;Found</span> :
                    <span style={{ color: '#4CC78C', background: '#FFEFF2', borderRadius: '4px', padding: '2px', fontSize: '10px', marginRight: '5px' }}><i className="fa fa-times-circle"></i>&nbsp;Not Found</span>}
                <i className="fa fa-chevron-right" style={{ color: '#2B78FF', marginRight: '10px' }}></i>
            </div>
        </div>
        : "" : ""}
    {matchedSimilarData ? matchedSimilarData.includes('address') ?
        <div className="row" style={{marginTop:'10px'}}>
            <div style={{ border: '0.5px solid #E9ECF5', width: '80%', marginLeft: 'auto', marginRight: 'auto', marginBottom: '10px' }}></div>
            <div className="col-sm-2">
                <div><div style={{ background: '#DBE9FF', width: '2px', marginLeft: 'auto', marginRight: 'auto', height: '60px', marginTop: '-20px' }}>
                </div>
                </div>
            </div>
            <div className="col-sm-5" onClick={() => similarFilterData('address')} style={{ fontSize: '12px', cursor: 'pointer', color: filterType === 'address' ? '#2B78FF' : '#000', fontWeight: filterType === 'address' ? '700' : '400' }}>
                Address Match</div>
            <div className="col-sm-5 text-right" style={{ fontSize: '12px', cursor: 'pointer' }} onClick={() => similarFilterData('address')}>

                {matchedSimilarData && matchedSimilarData.length > 0 ? <span style={{ color: '#4CC78C', background: '#E1F6EC', borderRadius: '4px', padding: '2px 10px', marginRight: '5px', fontSize: '10px' }}><i className="fa fa-check-circle"></i>&nbsp;Found</span> :
                    <span style={{ color: '#FF5C5D', background: '#FFEFF2', borderRadius: '4px', padding: '2px', fontSize: '10px', marginRight: '5px' }}><i className="fa fa-times-circle"></i>&nbsp;Not Found</span>}
                <i className="fa fa-chevron-right" style={{ color: '#2B78FF', marginRight: '10px' }}></i>
            </div>
        </div>
        : "" : ""}
    {matchedSimilarData ? matchedSimilarData.includes('mobileNo') ?
        <div className="row" style={{marginTop:'15px'}}>
            {/* <div style={{ border: '0.5px solid #E9ECF5', width: '80%', marginLeft: 'auto', marginRight: 'auto', marginBottom: '10px' }}></div> */}
            <div className="col-sm-2">
                <div><div style={{ background: '#DBE9FF', width: '2px', marginLeft: 'auto', marginRight: 'auto', height: '40px', marginTop: '-20px' }}>
                </div>
                </div>
            </div>
            <div className="col-sm-5" onClick={() => similarFilterData('mobileNo')} style={{ fontSize: '12px', cursor: 'pointer', color: filterType === 'mobileNo' ? '#2B78FF' : '#000', fontWeight: filterType === 'mobileNo' ? '700' : '400' }}>
                Mobile Match</div>
            <div className="col-sm-5 text-right" style={{ fontSize: '12px', cursor: 'pointer' }} onClick={() => similarFilterData('mobileNo')}>

                {matchedSimilarData && matchedSimilarData.length > 0 ? <span style={{ color: '#4CC78C', background: '#E1F6EC', borderRadius: '4px', padding: '2px 10px', marginRight: '5px', fontSize: '10px' }}><i className="fa fa-check-circle"></i>&nbsp;Found</span> :
                    <span style={{ color: '#FF5C5D', background: '#FFEFF2', borderRadius: '4px', padding: '2px', fontSize: '10px', marginRight: '5px' }}><i className="fa fa-times-circle"></i>&nbsp;Not Found</span>}
                <i className="fa fa-chevron-right" style={{ color: '#2B78FF', marginRight: '10px' }}></i>
            </div>
        </div>
        : "" : ""}
    {matchedSimilarData ? matchedSimilarData.includes('accountNo') ?
        <div className="row">
            {/* <div style={{ border: '0.5px solid #E9ECF5', width: '80%', marginLeft: 'auto', marginRight: 'auto', marginBottom: '10px' }}></div> */}
            <div className="col-sm-2">
                <div><div style={{ background: '#DBE9FF', width: '2px', marginLeft: 'auto', marginRight: 'auto', height: '40px', marginTop: '-20px' }}>
                </div>
                </div>
            </div>
            <div className="col-sm-5" onClick={() => similarFilterData('accountNo')} style={{ fontSize: '12px', cursor: 'pointer', color: filterType === 'accountNo' ? '#2B78FF' : '#000', fontWeight: filterType === 'accountNo' ? '700' : '400' }}>
                Account Match</div>
            <div className="col-sm-5 text-right" style={{ fontSize: '12px', cursor: 'pointer' }} onClick={() => similarFilterData('accountNo')}>

                {matchedSimilarData && matchedSimilarData.length > 0 ? <span style={{ color: '#4CC78C', background: '#E1F6EC', borderRadius: '4px', padding: '2px 10px', marginRight: '5px', fontSize: '10px' }}><i className="fa fa-check-circle"></i>&nbsp;Found</span> :
                    <span style={{ color: '#FF5C5D', background: '#FFEFF2', borderRadius: '4px', padding: '2px', fontSize: '10px', marginRight: '5px' }}><i className="fa fa-times-circle"></i>&nbsp;Not Found</span>}
                <i className="fa fa-chevron-right" style={{ color: '#2B78FF', marginRight: '10px' }}></i>
            </div>
        </div>
        : "" : ""}
    {matchedSimilarData && matchedSimilarData.length > 0 ?
        <div className="row" >
            <div className="col-sm-2">
                <div><div style={{ background: '#DBE9FF', width: '2px', marginLeft: 'auto', marginRight: 'auto', height: '80px', marginTop: '-20px' }}></div>
                </div>
            </div>

            <div className="col-sm-6" style={{ fontSize: '12px', marginTop: '20px', cursor: 'pointer', color: filterType === '' ? '#2B78FF' : '#000', fontWeight: filterType === '' ? '700' : '400' }} onClick={() => similarFilterData('')} >

                Detailed View</div>

            <div className="col-sm-4 text-right" style={{ fontSize: '12px', marginTop: '20px', cursor: 'pointer' }} onClick={() => similarFilterData('')} >

                <i className="fa fa-chevron-right" style={{ color: '#2B78FF', marginRight: '10px' }}></i></div>
        </div>
        : ""}
</>
)
export default Internal