import React from 'react';
import ReactTable from "react-table";
import { ROLE } from '../../../../utils/Constant'
import { filterCaseInsensitive } from '../../../../utils/commonUtility'

const AmbassadorDetails = ({ allAmbassadorData, admin, ambDetailHandler, refreshpage,
    backpage, forwordpage, ambassadorCount, isDeleteAmb, deleteAmbHandler,
    getFilterValue, FilteredData, getPageValue, pageIndexToShow }) => (
    <div className="container-fluid">
        <div className="row">
            <div className="col-sm-6 col-xs-12">
                <h3 className="text-center blue-text">Ambassador Leads</h3>
            </div>
            <div className="col-sm-6 col-xs-12">
                <h3 className="text-center blue-text">Total Ambassador - {ambassadorCount}</h3>
            </div>
        </div>

        <div className="row">
            <div className="col-xs-12 text-right">
                <button className="admin-pagination-btn" id="forword" onClick={e => refreshpage()}><span className="fa fa-refresh" /></button>
                <button className="admin-pagination-btn" id="backword" onClick={e => backpage()}><span className="fa fa-chevron-left" /></button>
                <button className="admin-pagination-btn" id="forword" onClick={e => forwordpage()}><span className="fa fa-chevron-right" /></button>
            </div>
        </div>

        <div className="row text-center">
            <div className="col-xs-12 overflow-x-auto no-padding">

                {allAmbassadorData != null && allAmbassadorData != '' && allAmbassadorData.length > 0 ?
                    <div className='overflow-auto'>
                        <table className='phocket-table'>
                            <thead>

                                <tr>
                                    <td>Status</td>
                                    <td>Ambassador ID</td>
                                    <td>Apply Date</td>
                                    <td>Email Id</td>
                                    <td>Name</td>
                                    <td>Total Amount</td>
                                    <td>Total Application</td>
                                    <td>Details</td>
                                    <td>Delete Amb</td>
                                </tr>

                            </thead>
                            <tbody>
                                {allAmbassadorData && allAmbassadorData.map((data, i) => {
                                    return (
                                        <tr key={i}>
                                            <td>{data.status}</td>
                                            <td> {data.ambassadorId}</td>
                                            <td>{data.ambApplyDate}</td>
                                            <td>{data.emailId}</td>
                                            <td> {data.name}</td>
                                            <td>{data.totalAmunt}</td>
                                            <td>{data.count}</td>
                                            <td> <div>
                                                {admin.rolelist != undefined ? admin.rolelist.indexOf(ROLE.VIEW) >= 0 ?
                                                    <button onClick={e => ambDetailHandler(data)}><span className="fa fa-info" /></button>
                                                    : "" : ""}
                                            </div></td>
                                            <td><button onClick={e => deleteAmbHandler(data.ambassadorId)}><span className="fa fa-trash" /></button></td>


                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                    : ""}

            </div>
        </div>
    </div>
)

export default AmbassadorDetails;
