import React from 'react'


const ViewDetailPopup = ({ viewPopupDetail,documentsData }) => (
    <div className="container-fluid">
        <div className="row">
            <div className="col-xs-12">
                <h3 className="text-center ">{viewPopupDetail.firstName + ' ' + viewPopupDetail.lastName}</h3>
            </div>
        </div>
        <div className="row form-group">
            <div className="col-xs-12">
                Company Name: <b>{viewPopupDetail.employmentDetailsPO.organizationName}</b>
            </div>
        </div>
        <div className="row form-group">
            <div className="col-xs-12">
                Salary: <b>{viewPopupDetail.employmentDetailsPO.netTakeHomeSalary}</b>
            </div>
        </div>
        {viewPopupDetail != null && viewPopupDetail.rating != null ?
            <div className="row form-group">
                <div className="col-xs-12">
                    Rating: <b>{viewPopupDetail.rating}</b>
                </div>
            </div>
            : ""}
        <div className="row form-group">
            <div className="col-xs-12">
                Work experience in current organisation: <b>{viewPopupDetail.employmentDetailsPO.workExperienceCurrentOrganization}</b>
            </div>
        </div>
        <div className="row form-group">
            <div className="col-xs-12">
                Current Residence Type: <b>{viewPopupDetail.addressDetailsPOList.currentResidenceType}</b>
            </div>
        </div>
        <div className="row form-group">
            <div className="col-xs-12">
                {documentsData.documentList != null ?
                    <ul>
                        <li>{documentsData.documentList.photographUrl != null ? <span className="fa fa-check-circle-o blue-text" >&nbsp;Official Id</span> : <span className="fa fa-times-circle-o orange-text">&nbsp;Official Id</span>}</li>
                        <li>{documentsData.documentList.adharCardUrl != null ? <span className="fa fa-check-circle-o blue-text" >&nbsp;Aadhar Card</span> : <span className="fa fa-times-circle-o orange-text">&nbsp;Aadhar Card</span>}</li>
                        <li>{documentsData.documentList.panCardUrl != null ? <span className="fa fa-check-circle-o blue-text">&nbsp;PanCard</span> : <span className="fa fa-times-circle-o orange-text">&nbsp;PanCard</span>}</li>
                        <li>{documentsData.documentList.bankStatementUrl != null ? <span className="fa fa-check-circle-o blue-text">&nbsp;BankStatement</span> : <span className="fa fa-times-circle-o orange-text">&nbsp;BankStatement</span>}</li>
                        <li>{documentsData.documentList.salaryProofUrl != null ? <span className="fa fa-check-circle-o blue-text">&nbsp;SalarySlip</span> : <span className="fa fa-times-circle-o orange-text">&nbsp;SalarySlip</span>}</li>
                    </ul>
                    :
                    <ul>
                        <li><span className="fa fa-times-circle-o orange-text">&nbsp;Official Id</span></li>
                        <li><span className="fa fa-times-circle-o orange-text">&nbsp;Aadhar Card</span></li>
                        <li><span className="fa fa-times-circle-o orange-text">&nbsp;PanCard</span></li>
                        <li><span className="fa fa-times-circle-o orange-text">&nbsp;BankStatement</span></li>
                        <li><span className="fa fa-times-circle-o orange-text">&nbsp;SalarySlip</span></li>
                    </ul>
                }
            </div>
        </div>
    </div>
)

export default ViewDetailPopup;