import React from 'react'
import {ROLE} from '../../../../utils/Constant'

const AmbAction = ({ ambasadorStatus, ambasadorStatusHandler, defaultStatus, admin,
    addAplicationId, addAplicationIdHandler }) => (
        <div className="container-fluid  max-width-500px">
            <div className="row">
                <h1 className="text-center">Action</h1>
            </div>
            <div className="row">
                <div className="col-xs-12">
                    <select className="form-control" defaultValue={defaultStatus} onChange={e => ambasadorStatus(e)}>
                        <option value="">Select One</option>
                        <option value="Inactive">Inactive</option>
                        <option value="Active">Active</option>
                        <option value="Reject">Reject</option>
                        <option value="Consider Later">Consider Later</option>
                    </select>
                </div>
            </div>
            <div className="row">
                <div className="col-xs-12 text-center">
                    <button className="profile-save-btn" onClick={e => ambasadorStatusHandler()}>
                        <span className="fa fa-rocket" />
                    </button>
                </div>
            </div>
            {admin.rolelist != undefined ? admin.rolelist.indexOf(ROLE.ADD_APPLICATION_IN_AMBASSADOR) >= 0 ?
                <div>
                    <div className="row mt-20px">
                        <h3 className="text-center">Add Application in Ambassador</h3>
                    </div>
                    <div className="row">
                        <div className="col-xs-12">
                            <input type="text" className="form-control" onChange={e => addAplicationId(e)} placeholder="Add Application Id" />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xs-12 text-center">
                            <button className="profile-save-btn" onClick={e => addAplicationIdHandler()}>
                                <span className="fa fa-rocket" />
                            </button>
                        </div>
                    </div>
                </div>
                : null : null}
        </div>
    )

export default AmbAction;