import React, { useEffect } from 'react';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
const CustomTooltip = ({ active, payload, label, areachartData }) => {
    if (active && payload && payload.length) {
        return (
            <div className="custom-tooltip-area">
                {/* <p className="label">{payload[0].value}</p> */}
                {/* <div className="arrow"></div> */}
                <p className='label'>
                    <div style={{ marginTop: '-10px' }} className='text-left'>Date:&nbsp;{payload[0].payload.name}</div>
                    Success Percentage:&nbsp;{payload[0].payload.amt} %<br />
                    Total Loans:&nbsp;{payload[0].payload.uv}
                </p>
            </div>
        );
    }
    return null;

};

const data = [
    {
        name: 'Page A',
        uv: 4000,
        amt: 2400,
    },
    {
        name: 'Page B',
        uv: 3000,
        amt: 2210,
    },
    {
        name: 'Page C',
        uv: 2000,
        amt: 2290,
    },
    {
        name: 'Page D',
        uv: 2780,
        amt: 2000,
    },
    {
        name: 'Page E',
        uv: 1890,
        amt: 2181,
    },
    {
        name: 'Page F',
        uv: 2390,
        amt: 2500,
    },

];


const Areachart = ({ areachartData }) => {
    let datanew = [];

    // Check if areachartData is defined and not null
    if (areachartData && typeof areachartData === 'object') {
        Object.entries(areachartData).map((hb, k) => {
            datanew.push({
                name: hb[1].date,
                uv: hb[1].totalLoans,
                amt: hb[1].successPercentage
            });
        });
    } else {
        console.error('areachartData is not defined or null');
    }


    return (
        <ResponsiveContainer width="100%" height={250}>
            <AreaChart
                width={200}
                height={200}
                data={datanew}
                margin={{
                    top: 20,
                    right: 40,
                    left: 0,
                    bottom: 0,
                }}
            >
                <defs>
                    <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                        <stop offset="5%" stopColor="#496f49" stopOpacity={0.8} />
                        <stop offset="95%" stopColor="#FFFFFF" stopOpacity={0} />
                    </linearGradient>
                </defs>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis style={{ fontSize: '8px' }} dataKey="name" axisLine={false} />
                <YAxis axisLine={false} />
                <Tooltip content={<CustomTooltip />} />
                <Area type="monotone" dataKey="uv" stroke="#496f49" strokeWidth={3} dot={{ stroke: '#496f49', strokeWidth: 2, r: 5, fill: 'white' }} activeDot={{ stroke: '#496f49', strokeWidth: 6, r: 8, fill: 'white' }} fill="url(#colorUv)" />
            </AreaChart>
        </ResponsiveContainer>
    );
};

export default Areachart;
