import React, { useState, useEffect } from 'react'
import Loader from '../../../presentationals/Loader/Loader.component'
import { saveBuisnessDataManually, getBuisnessData, getBuisnessReportList } from '../AdminActionCreator.component'
import Popup from '../../../presentationals/Popup/Popup.component'
import moment from 'moment'

const SaveManualUi = () => {
    const [buisnessData, setBuinessdata] = useState({})
    const [loaderState, setloaderState] = useState(false)
    const [popupState, setpopupState] = useState(false)
    const [popupStatus, setpopupStatus] = useState('')
    const [pageNumber, setpageNumber] = useState(1)
    const [noOfEntry, setnoOfEntry] = useState(10)
    const [tableData, settableData] = useState('')


    useEffect(() => {
        // getBuisnessData(callback => {
        //     setloaderState(true)
        //     let changeData = Object.assign({}, buisnessData)
        //     changeData.addedOn = callback.addedOn
        //     changeData.amountDisbursed = callback.amountDisbursed
        //     changeData.approvedAmount = callback.approvedAmount
        //     changeData.approvedLeads = callback.approvedLeads
        //     changeData.collectionAmount = callback.collectionAmount
        //     changeData.collectionLeads = callback.collectionLeads
        //     changeData.disbursedLeads = callback.disbursedLeads
        //     changeData.id = callback.id
        //     changeData.updatedOn = callback.updatedOn
        //     setBuinessdata(changeData)
        //     setloaderState(false)
        // })
        getData()
    }, [])
    const getData = () => {
        setloaderState(true)
        getBuisnessReportList(pageNumber, noOfEntry, callback => {
            settableData(callback)
            setloaderState(false)
        })
    }
    const changeHandler = (e, type) => {

        let changeData = Object.assign({}, buisnessData)
        if (type !== 'dataDate') {
            changeData[type] = e.target.value
        }
        if (type === 'dataDate') {
            changeData[type] = new Date(e.target.value)
        }
        setBuinessdata(changeData)
    }
    const saveData = () => {
        setloaderState(true)
        saveBuisnessDataManually(buisnessData, callback => {
            if (callback === 'success') {
                getData()
                setloaderState(false)
                setpopupState(true)
                setpopupStatus('Data Saved Successfully !')
                removePopup()

            } else {
                setloaderState(false)
                setpopupState(true)
                setpopupStatus('Please try again later !')
                removePopup()
            }


        })
    }
    const closePopup = () => {
        setpopupState(false)
    }
    const removePopup = () => {
        setTimeout(() => {
            setpopupState(false)

        }, 5000);
    }
    const paginationHandler = (type) => {
        if (type == 'left') {
            if (pageNumber > 1) {
                setpageNumber(pageNumber - 1)
                getData()
            }
        }
        if (type == 'right') {
            if ((Object.entries(tableData).length) > 10) {
                setpageNumber(pageNumber + 1)
                getData()
            }
        }

    }
    return (
        <div className='container-fluid'>
            {loaderState ?
                <Loader />
                : ""}
            {popupState ?
                <Popup closePopup={closePopup} popupStatus={popupStatus} />
                : ""}
            <h3 className='text-center blue-text'>Manual Business Report</h3>
            <div className='row'>
                <div className='col-sm-4 col-xs-12' style={{ marginTop: '15px' }}>
                    <label>Amount Disbursed</label>
                    <input className='form-control' type='number' onChange={(e) => changeHandler(e, 'amountDisbursed')} />
                </div>
                <div className='col-sm-4 col-xs-12' style={{ marginTop: '15px' }}>
                    <label>Collection Amount</label>
                    <input className='form-control' type='number' onChange={(e) => changeHandler(e, 'collectionAmount')} />
                </div>
                <div className='col-sm-4 col-xs-12' style={{ marginTop: '15px' }}>
                    <label>Disbursed Leads</label>
                    <input className='form-control' type='number' onChange={(e) => changeHandler(e, 'disbursedLeads')} />
                </div>

            </div>
            <div className='row'>
          
                {/* <div className='col-sm-4 col-xs-12' style={{ marginTop: '15px' }}>
                    <label>Collection Leads</label>
                    <input className='form-control' type='number' onChange={(e) => changeHandler(e, 'collectionLeads')} />
                </div> */}
                {/* <div className='col-sm-4 col-xs-12' style={{ marginTop: '15px' }}>
                    <label>Approved Leads</label>
                    <input className='form-control' type='number' onChange={(e) => changeHandler(e, 'approvedLeads')} />
                </div> */}
                {/* <div className='col-sm-4 col-xs-12' style={{ marginTop: '15px' }}>
                    <label>Approved Amount</label>
                    <input className='form-control' type='number' onChange={(e) => changeHandler(e, 'approvedAmount')} />
                </div> */}

            </div>
            <div className='row'>
            <div className='col-sm-4 col-xs-12' style={{ marginTop: '15px' }}>
                    <label>No. of closed Loans </label>
                    <input className='form-control' type='number' onChange={(e) => changeHandler(e, 'closedLoan')} />
                </div>
                <div className='col-sm-4 col-xs-12' style={{ marginTop: '15px' }}>
                    <label>Date</label>
                    <input className='form-control' type='date' onChange={(e) => changeHandler(e, 'dataDate')} />
                </div>
            </div>
            <div className='text-center'>
                <button className='profile-save-btn' onClick={() => saveData()}>Save</button>
            </div>
            <div className='container-fluid'>
                <div className="row">
                    <div className="col-xs-12 no-padding overflow-auto">

                        {
                            tableData ?
                                <table className='phocket-table'>
                                    <thead>
                                        <tr>
                                            <td>Date</td>
                                            <td>Amount Disbursed</td>
                                            {/* <td>Approved Amount</td> */}
                                            {/* <td>Approved Leads</td> */}
                                            <td>Collection Amount</td>
                                            {/* <td>Approved Leads</td> */}
                                            <td>Disbursed Leads</td>
                                            <td> Closed Loans</td>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {tableData && Object.entries(tableData).map((data, i) => {
                                            if(data&&data[1]){
                                                return (
                                                    <tr>
                                                        <td>{ data[1].dataDate ? moment(data[1].dataDate).format('DD-MM-YYYY') : ""}</td>
                                                        <td> {data[1].amountDisbursed}</td>
                                                        {/* <td>{data[1].approvedAmount}</td> */}
                                                        {/* <td>{data[1].approvedLeads}</td> */}
                                                        <td>{data[1].collectionAmount}</td>
                                                        {/* <td>{data[1].approvedLeads}</td> */}
                                                        <td>{data[1].disbursedLeads}</td>
                                                        <td>{data[1].closedLoan}</td>
    
                                                    </tr>
                                                )
                                            }
                                           
                                        })}
                                    </tbody>
                                </table>


                                :
                                ""}
                    </div>
                </div>
                {/* {!loadarState ? */}
                { tableData ?
                <div className="">
                    <div className="row">
                        <div className="col-sm-5 col-xs-12 no-padding"> <button type="button" className="btn btn-default fa fa-chevron-left btn-lg btn-block mage" id="backword" onClick={e => paginationHandler('left')}><span className="fa fa-chevron-left" /></button></div>
                        <div className="col-sm-2 col-xs-12  phoc text-center" >Total Count {(Object.entries(tableData).length)} <br />Page Number {pageNumber}</div>
                        <div className="col-sm-5 col-xs-12 no-padding"> <button type="button" className="btn btn-default fa fa-chevron-right btn-lg btn-block mage" id="forword" onClick={e => paginationHandler('right')}><span className="fa fa-chevron-right" /></button></div> </div>
                </div>
                :""}
                {/* : ""} */}
            </div>
        </div>
    )
}
export default SaveManualUi