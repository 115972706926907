import React, { useState, useEffect } from 'react'
import NodataImage from '../../../../../images/nodata.png';
import AddEditExecutor from './AddEditExecutor';
import Loader from '../../../../presentationals/Loader/Loader.component';
import PopUp from "../../../../presentationals/Popup/Popup.component";
import ConfirmationPopup from '../../../../presentationals/Popup/ConfirmationPopup.component'
// import { PlayArrow } from '@mui/icons-material';
import PlayArrowOutlinedIcon from '@mui/icons-material/PlayArrowOutlined';
import { getAllAutomatedProcessApi, UpdatedAutomatedProcessActive, DeleteProcessByProcessName, GetAutomatedProcessByFilter, getAllProcessNames, restartProcessApi, getLogsByExecutorName } from '../../AdminActionCreator.component';
import Detail from './detail'
import { Pagination } from '@mui/material';
export default function ExecutorMain({ admin }) {
    const [loader, setLoader] = useState(false)
    const [popupstate, setPopupState] = useState(false)
    const [confirmPopup, setconfirmPopup] = useState(false)
    const [processNameList, setProcessNameList] = useState([])
    const [searchType, setsearchType] = useState('processName')
    const [popupStatus, setpopupStatus] = useState('')
    const [searchValue, setSearchValue] = useState('')
    const [filteredData, setFilteredData] = useState([])
    const [editMode, setEditMode] = useState('tableView')
    const [editData, setEditData] = useState({})
    const [confirmationText, setconfirmationText] = useState('')
    const [deleteData, setDeleteData] = useState({})
    const [popupType, setPopupType] = useState('')
    const [restartData, setRestartData] = useState('')
    const [viewDetail, setviewDetail] = useState(false)
    const [logsData, setLogsData] = useState('')
    const [processName, setprocessName] = useState('')
    const [totalCount, settotalCount] = useState('')
    const [page, setpage] = useState(1)
    const [pageSize, setpageSize] = useState(15)



    useEffect(() => {
        GetAllAutomatedProcess()
        GetAllProcessNames()
    }, [])

    const GetAllProcessNames = () => {
        getAllProcessNames(callback => {
            if (callback) {
                setProcessNameList(callback)
            } else {
                setProcessNameList([])
            }
        })
    }

    const GetAllAutomatedProcess = () => {
        setLoader(true)
        getAllAutomatedProcessApi((callback) => {
            setLoader(false)
            if (callback) {
                setFilteredData(callback)
            } else {
                setpopupStatus('Error')
                setPopupState(true)
                removePopup()
                setFilteredData([])
            }
        })
    }
    useEffect(() => {
        filterDataBySearch();
    }, [searchValue]);
    const filterDataBySearch = () => {
        if (searchValue.trim()) {
            const filtered = filteredData.filter(item =>
                item.processName.toLowerCase().includes(searchValue.toLowerCase())
            );
            setFilteredData(filtered);
        } else {
            GetAllAutomatedProcess();
        }
    };
    const hanldeSearchchange = (e) => {
        if (e.target.value?.length > 0) {
            setSearchValue(e.target.value)
        } else {
            setSearchValue('')
            GetAllAutomatedProcess()
        }
    }



    const handlebtnclick = (type, data) => {
        if (type == 'search') {
            GetAutomatedProcessByFilter(searchType, searchValue, (callback) => {
                if (callback) {
                    console.log(callback)
                    setFilteredData(callback)
                } else {
                    GetAllAutomatedProcess()
                }
            })

        } else if (type == 'add' || type == 'edit') {
            setEditMode('Editable')
            setEditData(data)
        } else if (type == 'delete') {
            setPopupType('Delete')
            setconfirmPopup(true)
            setconfirmationText('Are You sure You Want To Delete This Process')
            setDeleteData(data)
        }
    }

    const handleSavebtn = () => {
        setEditMode('tableView')
        GetAllAutomatedProcess()
        GetAllProcessNames()
    }

    const handleToggle = (data) => {
        console.log(data)
        let newActive = !data.active;
        setLoader(true)
        UpdatedAutomatedProcessActive(data?.processName, newActive, (callback) => {
            setLoader(false)
            if (callback == 'success') {
                GetAllAutomatedProcess()
            } else {
                GetAllAutomatedProcess()
                setpopupStatus('Error')
                setPopupState(true)
            }
        })
    }

    const closePopup = () => {
        setPopupState(false)
    }

    const handlesearchType = (e) => {
        setsearchType(e.target.value)
        if (e.target.value == 'occurrence') {
            setSearchValue('daily')
        } else if (e.target.value == 'active') {
            setSearchValue('true')
        } else {
            setSearchValue('')
        }
    }

    const handleRefreshbtn = () => {
        GetAllAutomatedProcess()
        GetAllProcessNames()
        setSearchValue('')
        setsearchType('processName')
    }
    const removePopup = () => {
        setTimeout(() => {
            setPopupState(false)
        }, 5000);
    }
    const cancelLoanConfirm = (type) => {
        if (type == 'yes') {
            if (popupType == 'Delete') {
                setLoader(true)
                DeleteProcessByProcessName(deleteData.processName, (callback) => {
                    setLoader(false)
                    if (callback == 'success') {
                        setPopupState(true)
                        setpopupStatus('Deleted Successfully')
                        GetAllAutomatedProcess()
                        setconfirmPopup(false)
                        setDeleteData({})
                        removePopup()
                    } else {
                        GetAllAutomatedProcess()
                        setpopupStatus('Error')
                        setPopupState(true)
                        removePopup()
                    }
                })
                setPopupType('')
                setconfirmationText('')
            } else if (popupType == 'Restart') {
                setLoader(true)
                setconfirmPopup(false)

                restartProcessApi(restartData.processName, admin.emailId, callback => {
                    if (callback === 'success') {
                        GetAllAutomatedProcess()
                        setRestartData('')
                        setLoader(false)
                    } else {
                        setRestartData('')
                        setPopupState(true)
                        setpopupStatus(callback)
                        removePopup()
                        setLoader(false)
                    }
                })
                // setconfirmPopup(false)
                // setPopupType('')
                // setconfirmationText('')
            }
        } else {
            setconfirmPopup(false)
            setDeleteData({})
            setPopupType('')
            setconfirmationText('')
        }
    }

    function millisecondsToMinutes(seconds) {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = Math.floor(seconds % 60);

        const minutesFormatted = String(minutes).padStart(2, '0');
        const secondsFormatted = String(remainingSeconds).padStart(2, '0');

        return `${minutesFormatted}:${secondsFormatted} minutes`;
    }


    const handleRestart = (data) => {
        setRestartData(data)
        setconfirmationText(`Are you Sure You Want To Restart ${data.processName} process`)
        setPopupType('Restart')
        setconfirmPopup(true)
    }
    const view = (data) => {
        setpage(1)
        setprocessName(data)
        setLoader(true)
        getLogsByExecutorName(data, page, pageSize, callback => {
            if (callback.data.length > 0) {
                setLogsData(callback.data)
                settotalCount(callback.count)
                setviewDetail(true)
                setEditMode('')
                setLoader(false)
            } else {
                setPopupState(true)
                setpopupStatus('No Data Found !')
                removePopup()
                setLoader(false)
            }


        })


    }
    const closeDetail = () => {
        setviewDetail(false)
        setEditMode('tableView')
    }
    const handleChangeNewPagination = (e, value) => {
        console.log(value)
        setpage(value)
        getLogsByExecutorName(processName, value, pageSize, callback => {
            if (callback.data.length > 0) {
                setLogsData(callback.data)
                setLoader(false)
            }


        })

    };
    const changeHandler = (e) => {

    }

    return (
        <>
            <div className="container Executor">
                {
                    editMode == 'tableView' ?
                        <>
                            {
                                loader ?
                                    <Loader />
                                    : ''}
                            {
                                popupstate ?
                                    <PopUp closePopup={closePopup} popupStatus={popupStatus} />
                                    : ''}

                            {
                                confirmPopup ?
                                    <ConfirmationPopup confirmationText={confirmationText} cancelLoanConfirm={cancelLoanConfirm} />
                                    : ''}
                            <h3 className='heading'>Executor</h3>
                            <div className="FilterFields" style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div className='inputs'>
                                    <div className="Searchgrp">
                                        <input type='text' style={{width:'250px'}} className='form-control' placeholder='Process Name' value={searchValue} onChange={(e) => hanldeSearchchange(e)} />

                                    </div>
                                    {/* <button className='searchbtn' onClick={() => handlebtnclick('search', '')}><i class="fa fa-search fa-lg" aria-hidden="true"></i></button> */}
                                    <button className='searchbtn' onClick={() => handlebtnclick('add', '')}><i class="fa fa-plus fa-lg" aria-hidden="true"></i></button>
                                </div>
                                <div className="RefreshBtn">
                                    <button className='Refreshbtns' onClick={() => handleRefreshbtn()}>Refresh</button>
                                </div>
                            </div>
                            {
                                filteredData?.length > 0 ?
                                    <div className='' style={{ background: '#f2f2f2', padding: '10px', marginLeft: 'auto', marginRight: 'auto', borderRadius: '5px', overflow: 'auto' }}>
                                        <div className="">
                                            <table className='table-logs' style={{ marginBottom: '10px' }}>
                                                <thead>
                                                    <tr >
                                                        <th>Detail</th>
                                                        <th>Process Name</th>
                                                        {/* <th>Description</th> */}
                                                        <th>Occurence</th>
                                                        {/* <th>Created On</th> */}
                                                        <th style={{ width: '80px' }}>Active</th>
                                                        <th>Last Failure </th>
                                                        <th>Last Success </th>
                                                        <th>Time Taken</th>
                                                        <th>Restart</th>
                                                        <th>Edit</th>
                                                        <th>Delete</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {filteredData.map((data, index) => (
                                                        <>
                                                            <tr style={{ background: '#fff', padding: '5px', borderRadius: '5px', }} key={index}>
                                                                <td className='text-center' style={{ cursor: 'pointer' }} onClick={() => view(data.processName)}><i className='fa fa-info'></i></td>
                                                                <td>{data.processName}</td>
                                                                {/* <td>{data.description}</td> */}
                                                                <td>{data.occurrence}</td>
                                                                {/* <td>{data.createdOn}</td> */}
                                                                <td style={{ width: '80px' }}>
                                                                    <div className={`togglebtn ${data?.active == true ? 'active' : 'inactive'}`} style={{
                                                                        width: '100%', cursor
                                                                            : 'pointer'
                                                                    }} onClick={() => handleToggle(data)}>
                                                                        <button className='btn' style={{ padding: '8px' }} ></button>
                                                                    </div>
                                                                </td>
                                                                <td>{data.lastFailureDate}</td>
                                                                <td>{data.lastSuccessDate}</td>
                                                                <td>{millisecondsToMinutes(data.timeTaken)}</td>
                                                                <td><div onClick={() => handleRestart(data)} style={{ cursor: 'pointer' }}>
                                                                    {/* <i class="fa fa-play fa-lg" aria-hidden="true"></i> */}
                                                                    <PlayArrowOutlinedIcon style={{ fontSize: '30px', color: '#1c9146' }} />

                                                                </div></td>
                                                                <td>
                                                                    {/* <div className='edits'> */}
                                                                    <i class="fa fa-pencil-square-o fa-lg" aria-hidden="true" onClick={() => handlebtnclick('edit', data)}></i>
                                                                    {/* <i class="fa fa-trash-o fa-lg" aria-hidden="true" onClick={() => handlebtnclick('delete', data)}></i>
                                                                </div> */}
                                                                </td>
                                                                <td> <i class="fa fa-trash-o fa-lg" aria-hidden="true" onClick={() => handlebtnclick('delete', data)}></i></td>
                                                            </tr>
                                                            <div style={{ marginTop: '5px' }}></div>
                                                        </>
                                                    ))}
                                                </tbody>
                                            </table>

                                        </div>

                                    </div>
                                    :
                                    <p style={{ marginTop: '100px' }} className="text-center">
                                        <img src={NodataImage} style={{ width: '30%' }} />
                                    </p>
                            }
                        </>
                        : ''}

                {
                    editMode == 'Editable' ?
                        <AddEditExecutor editData={editData} handleSavebtn={handleSavebtn} />
                        : ''}

                {
                    viewDetail ?
                        <>
                            <Detail processName={processName} logsData={logsData} handleSavebtn={closeDetail} />
                            <div style={{ marginTop: '10px', float: 'right', marginBottom: '10px' }}>
                                <Pagination
                                    // count={10}
                                    count={Math.ceil(totalCount / 15 - 1)}
                                    page={page}
                                    onChange={handleChangeNewPagination}
                                    color="primary" />
                            </div>
                        </>

                        : ''}
            </div>
        </>
    )
}
