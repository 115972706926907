import React from 'react'
import DatePicker from 'react-datepicker';
import { STRING } from '../../../../utils/Constant'

const ReportByChoice = ({ initialList, allStatus, listByChoice, listByChoiceHandler,
    applyDateFromState, applyDateToState, listByChoiceSelectAll, excelFileName,
    disburseReport, reprotByChoice, cibilReport }) => (
        <div className="container-fluid Phocket-crm-role-assign">
            <div className="row form-group">
                <h3 className="text-center blue-text">Generate Report</h3>
            </div>
            <div className="row form-group">
                <ul className="loan-lead-search">
                    <li>
                        <label className="font-12px margin-0"><span className="fa fa-search" /> Sheet by</label>
                        <select className="form-control" onChange={(e) => listByChoice(e, 'sheetBy')}>
                            <option value="">Select Category</option>
                            <option value={0}>Apply date</option>
                            <option value={1}>Disburse date</option>
                            <option value={2}>Paid date</option>
                            <option value={3}>Cibil Report</option>
                            <option value={4}>Payback Date</option>
                        </select>
                    </li>
                    <li>
                        <label className="font-12px">Date From</label>
                        <DatePicker
                            value={reprotByChoice.applyDateFromState}
                            selected={applyDateFromState}
                            onChange={(e) => listByChoice(e, 'applyDateFrom')}
                            className="form-control"
                            dateFormat="DD/MM/YYYY"
                        />
                    </li>
                    <li>
                        <label className="font-12px">Date To</label>
                        <DatePicker
                            value={reprotByChoice.applyDateToState}
                            selected={applyDateToState}
                            onChange={(e) => listByChoice(e, 'applyDateTo')}
                            className="form-control"
                            dateFormat="DD/MM/YYYY"
                        />
                    </li>
                    <li>
                        {/* {console.log(reprotByChoice.downloadSheetBy)} */}
                        <label className="font-12px">Select Status</label>
                        {reprotByChoice.downloadSheetBy == 3 ?
                            <select className="form-control" onChange={(e) => listByChoice(e, 'loanStatusForCivil')}>
                                <option value="">Select Status</option>
                                <option value="Disbursed">Disbursed</option>
                                <option value="Paid">Paid</option>
                            </select>
                            :
                            <select multiple className="form-control" defaultValue={STRING.SELECT_ALL} onChange={(e) => listByChoice(e, 'loanStatus')}>
                                {allStatus != null && allStatus != '' && allStatus.length > 0 ?
                                    allStatus.map(status => {
                                        return (
                                            <option value={status.statusId} key={status.statusId}>{status.adminStatus}</option>
                                        )
                                    })
                                    : ""}
                            </select>}

                    </li>

                    <li>
                        <label className="font-12px">Excel File Name</label>
                        <input type="text" className="form-control" onChange={(e) => excelFileName(e)} />
                    </li>
                </ul>
            </div>
            <div className="row form-group">
                <div className="col-sm-4 col-xs-12">
                    <input className="phocket-checkbox" type="checkbox" id="selectAllExcelFields" onChange={(e) => listByChoiceSelectAll(e)} />
                    <label htmlFor="selectAllExcelFields"><span></span></label>&nbsp;&nbsp;
                <span><strong>Select All Fields</strong></span>
                </div>
                <div className="col-sm-5 col-xs-12">
                {reprotByChoice.downloadSheetBy == 3 ?
                    <button className="profile-save-btn pull-right margin-0" onClick={e => cibilReport()}>Generate Cibil Report</button>
               :""}
                 </div>
                <div className="col-sm-3 col-xs-12">
                    <button className="profile-save-btn pull-right margin-0" onClick={e => disburseReport()}>Generate Disburse Report</button>
                </div>
            </div>
            <div className="row form-group">
                {initialList != '' && initialList.length > 0 ?
                    initialList.map((list, i) => {
                        return (
                            <div key={i} className="col-sm-3 col-xs-12">
                                <input className="phocket-checkbox" type="checkbox" id={"listByChoice" + i} value={list} onChange={(e) => listByChoice(e, 'reportFields')} />
                                <label htmlFor={"listByChoice" + i}><span></span></label>&nbsp;&nbsp;
                            <span>{list}</span>
                            </div>
                        )
                    })
                    : ""}
            </div>

            <div className="row text-center">
                <div className="col-xs-12">
                    <button className="profile-save-btn" id="submitDetail" onClick={e => listByChoiceHandler()}>
                        <span className="fa fa-download"></span>&nbsp;&nbsp;Generate Report
                </button>
                </div>
            </div>

        </div>
    )

export default ReportByChoice;