import React from 'react'
import { notificationImageUploadApi, pushNotification } from '../../AdminActionCreator.component';
import Popup from '../../../../presentationals/Popup/Popup.component';

let File = '';

class Notification extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            popupState: false,
            popupStatus: '',
            title: '',
            message: '',
            notificationImage: '',
            url: '',
            typeOfFile: '',
            comment: ''
        }
    }
    removePopup() {
        setTimeout(function () {
            this.setState({ popupState: false });
        }.bind(this), 5000)
    }

    closePopup() {
        this.setState({ popupState: false });
    }
    createLink() {
        var selection = document.getSelection();
        document.execCommand('createLink', true, this.state.url);
        selection.anchorNode.parentElement.target = '_blank';
    }
    uploadNotificationImage(e) {
        this.setState({ typeOfFile: e.target.files[0].type })
        let val = e.target.value.split(/[\\\/]/)[2];
        this.setState({ notificationImage: val })
        File = e.target.files[0];
        let docType = val.split(".");
        let fileType = docType[docType.length - 1];
        // if (fileType == "jpeg" ||fileType == "jpg" || fileType == "png") {
        notificationImageUploadApi(File, val, e.target.files[0].type,
            (callBack) => {
                if (callBack != 'failure') {
                    this.setState({
                        popupState: true, popupStatus: 'File Uploaded',
                        notificationImage: callBack
                    }, () => this.removePopup())
                } else {
                    this.setState({ popupState: true, popupStatus: 'Failed To upload file' }, () => this.removePopup())
                }
            });
        // }

        // else{
        //     this.setState({popupState:true,popupStatus:'File Size should less than 5 Mb'})
        //     this.removePopup()
        // }
    }
    messageHandler(e, type) {
        if (type === "title") {
            this.setState({ title: e.target.value });
        } else if (type === "url") {
            this.setState({ url: e.target.value }, () => this.createLink());
        } else if (type === "body") {
            this.setState({ body: e.target.value });
        }
        // else {
        //     this.setState({ message: e.target.value });
        // }
    }
    sendHandler(type) {
        const { allDetail, admin } = this.props;
        if (type == 'notification') {
            pushNotification(allDetail, this.state.body, this.state.title, admin.emailId, 'notification', '', this.state.notificationImage, '', '', '',
                (callBack) => {
                    this.setState({
                        popupState: true,
                        popupStatus: 'Notification Sent Successfully',
                        notificationImage: ''
                    })
                    this.removePopup();
                })
        }
    }
    render() {
        return (
            <div className='container-fluid details-new'>
                {this.state.popupState == true ?
                    <Popup
                        closePopup={this.closePopup.bind(this)}
                        popupStatus={this.state.popupStatus}
                    />
                    : ""}
                <div className="sms-box">
                    <div>
                        <input onChange={e => this.messageHandler(e, "title")} style={{ background: '#DBE9FF', color: '#000' }} className='form-control' placeholder='Enter Tittle' />
                    </div>
                    <div>
                        <textarea onChange={e => this.messageHandler(e, "body")} style={{ background: '#F1F7FF', color: '#000', borderRadius: '6px', border: 'none', marginTop: '20px', minHeight: '150px' }} className='form-control' placeholder='Enter Body' />
                    </div>
                    <div>
                        <input type='text' onChange={e => this.messageHandler(e, "url")} style={{ background: '#F1F7FF', color: '#000', borderRadius: '6px', border: 'none', marginTop: '20px', outline: 'none' }} className='form-control' placeholder='Link Url' />
                    </div>
                    <div>
                        <input onChange={e => this.uploadNotificationImage(e)} type='file' style={{ background: '#F1F7FF', color: '#000', borderRadius: '6px', border: 'none', marginTop: '20px', outline: 'none' }} className='form-control' />
                    </div>
                    <div>
                        <button onClick={e => this.sendHandler('notification')} className='btn btn-primary' style={{ marginTop: '20px', marginLeft: '10px' }}>Send </button>
                    </div>
                </div>
            </div>
        )
    }
}
export default Notification