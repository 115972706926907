import React, { Component } from 'react'
import InstallmentUi from '../../../presentationals/Admin/CustomerPages/Installment.component'
import { _preFormatDate } from '../../../../utils/validation';
import { APIS } from '../../../../utils/api-factory';
import UpdateTransaction from '../CustomerPages/EditTransactionPopup.container'
import Popup from '../../../presentationals/Popup/Popup.component';
import { saveInstallmentApis } from '../AdminActionCreator.component'
import ConfirmationPopup from '../../../presentationals/Popup/ConfirmationPopup.component'
import moment from 'moment'

class Installment extends Component {
    constructor(props) {
        super(props);
        this.state = {
            installDateState: '',
            installDetails: '',
            confirmationText: '',
            confirmationPopupState: false,
            idForDelete: '',
            installmentDetail: {
                installAmountState: '',
                installDateState: '',
                installId: '',
                addPopup: false
            }
        }
    }
    addPopupOpen() {
        this.setState({ addPopup: true })
    }
    componentWillMount() {
        const { allDetail } = this.props
        fetch(APIS.GET_LOAN_INSTALLMENT + allDetail.loanId)
            .then(res => res.json())
            .then(json => {
                this.setState({ installDetails: json })
            })
    }
    close() {
        this.setState({ addPopup: false })
    }
    render() {
        const { allDetail, userDetail } = this.props
        return (
            <div>
                {this.state.popupState == true ?
                    <Popup
                        closePopup={this.closePopup.bind(this)}
                        popupStatus={this.state.popupStatus}
                    />
                    : ""}
                <InstallmentUi
                    close={this.close.bind(this)}
                    addPopup={this.state.addPopup}
                    editInstallHandler={this.editInstallHandler.bind(this)}
                    saveInstallHandler={this.saveInstallHandler.bind(this)}
                    installmentDetail={this.state.installmentDetail}
                    installDateState={this.state.installDateState}
                    installDetails={this.state.installDetails}
                    editIntallmentDetail={this.editIntallmentDetail.bind(this)}
                    deleteIntallmentDetail={this.deleteIntallmentDetail.bind(this)}
                    addPopupOpen={this.addPopupOpen.bind(this)}
                />
                {this.state.confirmationPopupState == true ?
                    <ConfirmationPopup
                        cancelLoanConfirm={this.cancelLoanConfirm.bind(this)}
                        confirmationText={this.state.confirmationText}
                    />
                    : ""}
            </div>
        )
    }

    cancelLoanConfirm(confirmType) {
        const { allDetail, admin } = this.props
        if (confirmType == 'yes') {
            fetch(APIS.DELETE_LOAN_INSTALLMENT + this.state.idForDelete + '&empId=' + admin.emailId + '&loanId=' + allDetail.loanId)
                .then(res => res.text())
                .then(text => {
                    if (text == 'success') {
                        this.setState({
                            popupState: true,
                            popupStatus: 'Deleted successfully',
                        })
                        this.removePopup()
                        fetch(APIS.GET_LOAN_INSTALLMENT + allDetail.loanId)
                            .then(res => res.json())
                            .then(json => {
                                this.setState({ installDetails: json })
                            })
                    } else {
                        this.setState({
                            popupState: true,
                            popupStatus: 'Please try again',
                        })
                        this.removePopup()
                    }
                })
            this.setState({
                confirmationPopupState: false
            })
        } else if (confirmType == 'no') {
            this.setState({
                confirmationPopupState: false
            })
        }
    }


    editIntallmentDetail(data) {
        let installmentDetail = Object.assign({}, this.state.installmentDetail);
        installmentDetail.installAmountState = data.emiAmount
        installmentDetail.installDateState = moment(new Date(data.date)).format('DD-MM-YYYY')
        installmentDetail.installId = data.id
        this.setState({
            installmentDetail,
            addPopup: true
        })
    }

    deleteIntallmentDetail(data) {
        const { allDetail, admin } = this.props
        this.setState({
            idForDelete: data.id,
            confirmationText: 'Are you sure',
            confirmationPopupState: true,
        })
    }

    removePopup() {
        setTimeout(function () {
            this.setState({ popupState: false });
        }.bind(this), 5000)
    }

    closePopup() {
        this.setState({ popupState: false });
    }

    editInstallHandler(e, transType) {
        let installmentDetail = Object.assign({}, this.state.installmentDetail);
        if (transType == 'installAmount') {
            installmentDetail.installAmountState = e.target.value
        } else if (transType == 'installDate') {
            installmentDetail.installDateState = _preFormatDate(e);
            this.setState({ installDateState: e })
        }
        this.setState({
            installmentDetail
        })
    }
    saveInstallHandler() {
        const { allDetail, admin } = this.props
        if (this.state.installmentDetail.installAmountState != '' && this.state.installmentDetail.installDateState) {
            if (this.state.installmentDetail.installAmountState >= 0) {
                saveInstallmentApis(this.state.installmentDetail, allDetail, admin,
                    (callBack) => {
                        if (callBack == 'success') {
                            this.setState({
                                popupState: true,
                                popupStatus: 'Intallment Details save successfully',
                                addPopup: false
                            })
                            this.removePopup()
                            fetch(APIS.GET_LOAN_INSTALLMENT + allDetail.loanId)
                                .then(res => res.json())
                                .then(json => {
                                    this.setState({ installDetails: json })
                                })
                        } else {
                            this.setState({
                                popupState: true,
                                popupStatus: 'Somthing went wrong, please try again!',
                            })
                            this.removePopup()
                        }
                    });
            } else {
                this.setState({
                    popupState: true,
                    popupStatus: 'Amount should be greater then 0',
                })
                this.removePopup()
            }
        } else {
            this.setState({
                popupState: true,
                popupStatus: 'Please select values',
            })
            this.removePopup()
        }
    }
}

export default Installment;