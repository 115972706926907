import React, { useEffect, useState } from 'react'
import Listing from './allconfiguration'
import ConFig from './addConfiguration'
import { deleteAttributeByCreditAttId, getConfigDataApi } from '../AdminActionCreator.component'
const MainPolicyTab = () => {
    const [editData, saveEditData] = useState('')
    const [viewStateMain, setViewPageMain] = useState('list')
    const [objectData, setObjectData] = useState('')
    const [pageNo, setpageNo] = useState(1)
    const [count, setcount] = useState(200)



    useEffect(() => {
        getData()
    }, [])
    const getData = () => {
        getConfigDataApi(pageNo, count, callback => {
            setObjectData(callback)
        })
    }
    const deleteAttributePoint = (data) => {
        deleteAttributeByCreditAttId(data.creditAttId,callback=>{
            getData()
        })
    }
    const setPage=(data)=>{
        setViewPageMain(data)
        if(data==='list'){
            getData()
        }
    }
    return (
        <div>
            {viewStateMain === 'list' ?
                <Listing  deleteAttributePoint={deleteAttributePoint} objectData={objectData} saveEditData={saveEditData} setViewPageMain={setViewPageMain} />
                : ""}
            {viewStateMain === 'config' ?
                <ConFig  getData={getData} objectData={objectData} editData={editData} setViewPageMain={setPage} />
                : ""}
        </div>
    )
}
export default MainPolicyTab

