import React from 'react'

const UpdatePopup = ({ newAmbData, responseBtnHandler, popupType }) => (
    <div>
        <div className="background-blur"></div>
        <div className="popup-main small-popup container-fluid dbl-border max-width-500px">
            {popupType == 'existingUser' ?
                <div>
                    <h3 className="text-center blue-text">User Details</h3>
                    <div className="row form-group">
                        <div className="col-xs-12 col-sm-6">
                            {newAmbData != null && newAmbData.emailId != null ? 'EmailId = '+newAmbData.emailId : null}
                        </div>
                        <div className="col-xs-12 col-sm-6">
                            {newAmbData != null && newAmbData.firstName != null ? 'Name = '+newAmbData.firstName : null}
                        </div>
                    </div>
                    <div className="row form-group">
                        <div className="col-xs-12 col-sm-6">
                            {newAmbData != null && newAmbData.mobileNumber != null ? 'Mobile = '+newAmbData.mobileNumber : null}
                        </div>
                        <div className="col-xs-12 col-sm-6">
                            {newAmbData != null && newAmbData.alternateNumber != null ? 'Alternate No. = '+newAmbData.alternateNumber : null}
                        </div>
                    </div>
                    <button className="btn profile-save-btn center-block" onClick={e => responseBtnHandler('move')}>Move to ambassador</button>
                </div>
                :popupType == 'newAmbassador'?
                <div>
                    <h3 className="text-center blue-text">Are you sure you want to create AMBASSADOR</h3>
                    <button className="btn profile-save-btn center-block" onClick={e => responseBtnHandler('new')}>Yes</button>
                </div>
            :null}
        </div>
    </div>
)

export default UpdatePopup;