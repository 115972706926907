import React, { useState } from "react";
import MainExpenses from "./main";
import MainCategory from "./category/main";
import MainSubCategory from "./subcategory/main";
import MainDescription from "./description/main";
import MainTarget from "./Targets/main";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

const TabsExpense = ({ pageViewHandler }) => {
    const [viewState, setViewState] = useState('expense')
    return (
        <>
            <div className="row fixed-top" style={{ marginTop: '0px', display: 'flex', width: '101%' }}>
                <div className="" onClick={() => setViewState('expense')} style={{ borderBottom: viewState === 'expense' ? '2px solid #1eb0d2' : '', background: viewState === 'expense' ? '#1eb0d2' : '#DBE9FF', padding: viewState === 'expense' ? '13px' : '12.2px', textAlign: 'center', color: viewState === 'expense' ? '#fff' : '#000', cursor: 'pointer', width: '20%' }}>
                    Expense List

                </div>
                <div className="" onClick={() => setViewState('category')} style={{ borderBottom: viewState === 'category' ? '2px solid #1eb0d2' : '', background: viewState === 'category' ? '#1eb0d2' : '#DBE9FF', padding: viewState === 'category' ? '13px' : '12.2px', textAlign: 'center', color: viewState === 'category' ? '#fff' : '#000', cursor: 'pointer', width: '20%' }}>
                    Expense Category

                </div>
                <div className="" onClick={() => setViewState('subCategory')} style={{ borderBottom: viewState === 'subCategory' ? '2px solid #1eb0d2' : '', background: viewState === 'subCategory' ? '#1eb0d2' : '#DBE9FF', padding: viewState === 'subCategory' ? '13px' : '12.2px', textAlign: 'center', color: viewState === 'subCategory' ? '#fff' : '#000', cursor: 'pointer', width: '20%' }}>
                    Expense  Sub-Category

                </div>
                <div className="" onClick={() => setViewState('description')} style={{ borderBottom: viewState === 'description' ? '2px solid #1eb0d2' : '', background: viewState === 'description' ? '#1eb0d2' : '#DBE9FF', padding: viewState === 'description' ? '13px' : '12.2px', textAlign: 'center', color: viewState === 'description' ? '#fff' : '#000', cursor: 'pointer', width: '20%' }}>
                    Expense Description

                </div>
                <div className="" onClick={() => setViewState('target')} style={{ borderBottom: viewState === 'target' ? '2px solid #1eb0d2' : '', background: viewState === 'target' ? '#1eb0d2' : '#DBE9FF', padding: viewState === 'target' ? '13px' : '12.2px', textAlign: 'center', color: viewState === 'target' ? '#fff' : '#000', cursor: 'pointer', width: '20%' }}>
                    Expense Target

                </div>

            </div>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-4 " style={{ marginTop: window.innerWidth > 1800 ? '0%' : '5%'}}>
                        <button style={{ color: '#223d64', fontWeight: '400', display: 'flex', border: '1px solid #1eb0d2', borderRadius: '6px', padding: '8px 10px' }} onClick={(e) => pageViewHandler(e, 'landingPage')} ><ArrowBackIosNewIcon style={{ marginTop: '1px' }} />&nbsp;Back</button>
                    </div>
                </div>
            </div>
            {viewState === 'expense' ?
                <MainExpenses />
                : ""}
            {viewState === 'category' ?
                <MainCategory />
                : ""}
            {viewState === 'subCategory' ?
                <MainSubCategory />
                : ""}
            {viewState === 'description' ?
                <MainDescription />
                : ""}
            {viewState === 'target' ?
                <MainTarget />
                : ""}
        </>
    )
}
export default TabsExpense