import React from 'react'
import DatePicker from 'react-datepicker';

const Incoming = ({ dateFilter,startDate,startDateToShow,endDate,endDateToShow,downloadFile}) => (
    <div className="container-fluid">
        <div className="row">
            <h3 className="text-center blue-text">Incoming Payment Report DownLoad</h3>
        </div>
        <div className='row'>
            <div className='col-sm-4 col-xs-12'>

            </div>
            <div className='col-sm-4 col-xs-12'>
                <label>Select Start Date</label>
                <DatePicker
                    value={startDate}
                    selected={startDateToShow}
                    onChange={e => dateFilter(e, "start")}
                    className="form-control "
                    dateFormat="DD-MM-YYYY"
                    placeholderText="Select Start Date"
                />
            </div>
            <div className='col-sm-4 col-xs-12'>

            </div>


        </div>
        <div className='row'>
            <div className='col-sm-4 col-xs-12'>

            </div>
            <div className='col-sm-4 col-xs-12'>
                <label>Select End Date</label>
                <DatePicker
                    value={endDate}
                    selected={endDateToShow}
                    onChange={e => dateFilter(e, "end")}
                    className="form-control "
                    dateFormat="DD-MM-YYYY"
                    placeholderText="Select End Date"
                />
            </div>
            <div className='col-sm-4 col-xs-12'>

            </div>


        </div>
        <div className='row'>
            <div className='col-sm-4 col-xs-12'>

            </div>
            <div className='col-sm-4 col-xs-12'>
              <button className='profile-save-btn' onClick={downloadFile}>
                 Click Here To Download
              </button>
            </div>
            <div className='col-sm-4 col-xs-12'>

            </div>


        </div>

    </div>
)

export default Incoming