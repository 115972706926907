import React from "react";

const Datamore = ({ closePopup, popupData }) => (
    <div className="background-blur details-new">

        <div className="popup-main small-popup container-fluid dbl-border" style={{ marginTop: '10px',width:'80%',color:'#000' }}>
            <div style={{ textAlign: 'right', cursor: 'pointer' }}>
                <i className="fa fa-times" onClick={() => closePopup()}></i>
            </div>
            <div
                dangerouslySetInnerHTML={{
                    __html: popupData
                }}
            ></div>
        </div>
    </div>
)
export default Datamore