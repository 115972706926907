import React, { Component } from 'react'
import AmbassadorView from '../../../presentationals/Admin/Loyality/LoyalityList.component'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {  getLoyalityDetailApi } from '../AdminActionCreator.component'
import DotLoader from '../../../presentationals/Loader/DotLoader.component'

class LoyalityContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loadarState: false,
            forwordPageState: 1,
            ambassadorDetailState: false,
            ambassadorLeadState: true,
            FilteredData: [],
            pageIndexToShow: 0,
        }
    }
    componentWillMount() {
        this.props.getLoyalityDetailApi(1,
            (callBack) => {

            })
    }
    render() {
        const {  admin, loyalityDetails } = this.props;
        return (
            <div>
                {this.state.ambassadorLeadState == true ?
                    <div>
                        {this.state.loadarState == true ?
                            <DotLoader />
                            : <AmbassadorView
                                allAmbassadorData={loyalityDetails}
                                admin={admin}
                                ambDetailHandler={this.ambDetailHandler.bind(this)}
                                refreshpage={this.refreshpage.bind(this)}
                                backpage={this.backpage.bind(this)}
                                forwordpage={this.forwordpage.bind(this)}
                                getFilterValue={this.getFilterValue.bind(this)}
                                FilteredData={this.state.FilteredData}
                                getPageValue={this.getPageValue.bind(this)}
                                pageIndexToShow={this.state.pageIndexToShow}
                            />
                        }
                    </div>
                    : ""}
            </div>
        )
    }


    getFilterValue(filtered) {
        this.setState({ FilteredData: filtered })
    }

    getPageValue(pageIndex) {
        this.setState({ pageIndexToShow: pageIndex })
    }

    ambDetailHandler(ambData) {
        this.props.getLoyalityDetailApi(ambData.ambassadorId,
            (callBack) => {
                this.setState({
                    ambassadorDetailState: true,
                    ambassadorLeadState: false
                })
            })
    }
    detailBackHandler() {
        this.setState({
            ambassadorDetailState: false,
            ambassadorLeadState: true
        })
    }
    refreshpage() {
        this.setState({
            loadarState: true
        })
        this.props.getLoyalityDetailApi(1,
            (callBack) => {
                this.setState({
                    loadarState: false
                })
            });
    }
    backpage() {
        if (this.state.forwordPageState > 1) {
            this.setState({
                forwordPageState: this.state.forwordPageState - 1,
                loadarState: true
            })
            this.props.getLoyalityDetailApi(this.state.forwordPageState - 1,
                (callBack) => {
                    this.setState({
                        loadarState: false
                    })
                });
        }
    }
    forwordpage() {
        this.setState({
            loadarState: true,
            forwordPageState: this.state.forwordPageState + 1
        })
        this.props.getLoyalityDetailApi(this.state.forwordPageState + 1,
            (callBack) => {
                this.setState({
                    loadarState: false
                })
            });
    }
}

const mapStateToProps = ({loyalityDetails}) => {
    return {
        loyalityDetails: loyalityDetails,
    };
};

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        getLoyalityDetailApi
    }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(LoyalityContainer);