import React, { Component } from 'react';
import Registration from '../../presentationals/Campaigntrack/Registration.Component';
import { thirdPartyRegistrationValidation } from './validation'
import { thirdPartyRegistration } from './ThirdPartyActionCreater.component'
import PopUp from '../../presentationals/Popup/Popup.component';
import Loader from '../../presentationals/Loader/Loader.component'
export default class ThirdPartyRegistration extends Component {
    constructor(props) {
        super(props)
        this.state = {
            userdetail: {
                companyName: '',
                contactPersonName: '',
                ambassadorName: '',
                contactPersonMobileNumber: '',
                contactPersonEmail: '',
                password: '',
                companyregisteredaddress: '',
                companywebaddress: ''
            }, validationError: "",
            status:true,
            successmsg: "",
            popupState:false,
            popupStatus:'',
            loaderState:false
        }
    }
    closePopup(){
        this.setState({popupState:false})
    }
    removePopup() {
        setTimeout(function () {
            this.setState({ popupState: false });
        }.bind(this), 5000)
    }

    render() {
        return (
            <div>
                <Registration
                status={this.state.status}
                    validationError={this.state.validationError}
                    successmsg={this.state.successmsg}
                    getTextFieldValue={this._getTextFeildValue.bind(this)}
                    submitLoginDetails={this.submitLoginDetails.bind(this)} />
                    {this.state.popupState?
                    <PopUp
                    popupStatus={this.state.popupStatus}
                    closePopup={this.closePopup.bind(this)}
                    />
                :""}
                {this.state.loaderState?
                <Loader/>
            :""}
            </div>
        )
    }
    _getTextFeildValue(e, type) {
        let userdetail = Object.assign({}, this.state.userdetail)

        if (type == "companyName") {
            userdetail.companyName = e.target.value;
        }
        if (type == "contactPersonName") {
            userdetail.contactPersonName = e.target.value;
        }
        if (type == "ambassadorName") {
            userdetail.ambassadorName = e.target.value;
        }
        if (type == "contactPersonMobileNumber") {
            userdetail.contactPersonMobileNumber = e.target.value;
        }
        if (type == "contactPersonEmail") {
            userdetail.contactPersonEmail = e.target.value;
        }

        if (type == "password") {
            userdetail.password = e.target.value;
        }
        if (type == "companywebaddress") {
            userdetail.companywebaddress = e.target.value;
        }
        if (type == "companyregisteredaddress") {
            userdetail.companyregisteredaddress = e.target.value;
        }
        this.setState({ userdetail })
    }
    submitLoginDetails(e) {
        let check = thirdPartyRegistrationValidation(this.state.userdetail)
        if (check.status) {
            this.setState({loaderState:true})

            thirdPartyRegistration(this.state.userdetail,this.state.status, callback => {
                if (callback == "success") {
                    let changeData =Object.assign({},this.state.userdetail)
                    changeData.companyName='',
                    changeData.contactPersonName='',
                    changeData.ambassadorName='',
                    changeData.contactPersonMobileNumber='',
                    changeData.contactPersonEmail='',
                    changeData.password='',
                    changeData.companyregisteredaddress='',
                    changeData.companywebaddress=''
                    this.setState({ popupStatus: "Successfully Registered",popupState:true ,loaderState:false,userdetail:changeData},()=>this.removePopup())
                
                }else{
                    this.setState({ popupStatus: "Please try again later !",popupState:true ,loaderState:false},()=>this.removePopup())

                }

            })
        } else {
            this.setState({ validationError: check })

        }
    }

}