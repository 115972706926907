import React, { Component } from 'react'
import AssignByStateUi from '../../../presentationals/Admin/Settings/AssignByState.component'
import Popup from '../../../presentationals/Popup/Popup.component';
import ConfirmationPopup from '../../../presentationals/Popup/ConfirmationPopup.component'
import { APIS } from '../../../../utils/api-factory'
import { getRoleAssignView, bulkApplicationAssignApi } from '../AdminActionCreator.component'

let alreadyAssigned = []

class AssignByState extends Component {
    constructor(props) {
        super(props);
        this.state = {
            confirmationPopupState: false,
            confirmationText: '',
            popupState: false,
            popupStatus: '',
            allStates: [],
            stateRoleDataState: [],
            roleData: [],
            userStates: [],
            bulkAssignDetail: {
                state: '',
                stateAgent: [],
            },
            btnClickType: '',
            assignIdsWithLeave: {}
        }
    }

    componentWillMount() {
        this.getAllStateMethod()
        this.getUserAssignStateMethod()
        this.getRoleAssignViewMethod()
    }

    getAllStateMethod() {
        fetch(APIS.GET_ALL_STATE)
            .then(res => res.json())
            .then(res => {
                this.setState({
                    allStates: res
                })
            })
    }

    getUserAssignStateMethod() {
        fetch(APIS.GET_USER_ASSIGN_STATE)
            .then(res => res.json())
            .then(res => {
                this.setState({
                    userStates: res
                })
            })
    }

    getRoleAssignViewMethod() {
        const { admin } = this.props
        getRoleAssignView(admin.emailId,
            (callBack) => {
                this.roleDataArray(callBack)
                this.setState({ roleData: callBack })
            })
    }

    roleDataArray(data) {
        let selectData = []
        data.length > 0 ? data.map((data, i) => {
            if (alreadyAssigned.includes(data.emailId)) {
                selectData.push({ label: data.emailId, id: i, value: true })
            } else {
                selectData.push({ label: data.emailId, id: i, value: false })
            }
        }) : null
        this.setState({
            stateRoleDataState: selectData
        })
    }

    render() {
        const { admin } = this.props
        return (
            <div className="full-height">
                {this.state.popupState == true ?
                    <Popup
                        closePopup={this.closePopup.bind(this)}
                        popupStatus={this.state.popupStatus}
                    />
                    : ""}
                <AssignByStateUi
                    allStates={this.state.allStates}
                    stateRoleDataState={this.state.stateRoleDataState}
                    userStates={this.state.userStates}
                    bulkAssignChange={this.bulkAssignChange.bind(this)}
                    bulkAssignHandler={this.bulkAssignHandler.bind(this)}
                    bulkAssignDetail={this.state.bulkAssignDetail}
                />

                {this.state.confirmationPopupState == true ?
                    <ConfirmationPopup
                        cancelLoanConfirm={this.cancelLoanConfirm.bind(this)}
                        confirmationText={this.state.confirmationText}
                    />
                    : ""}
            </div>
        )
    }

    bulkAssignChange(e, type) {
        let bulkAssignDetail = Object.assign({}, this.state.bulkAssignDetail)
        if (type == 'state') {
            bulkAssignDetail.state = e.target.value
            this.state.userStates.length > 0 ?
                this.state.userStates.map(data => {
                    if (data.state == e.target.value) {
                        alreadyAssigned = data.assignIds
                    }
                })
                : null
            this.roleDataArray(this.state.roleData)
        } else if (type == 'stateAgent') {
            bulkAssignDetail.stateAgent = []
            this.setState({ stateRoleDataState: e })
            e.length > 0 ? e.map(data => {
                if (data.value === true) {
                    bulkAssignDetail.stateAgent.push(data.label)
                }
            }) : null
        }
        this.setState({ bulkAssignDetail })
    }

    bulkAssignHandler(type) {
        if (this.state.bulkAssignDetail.state != '') {
            this.setState({
                confirmationPopupState: true,
                confirmationText: "Are you sure you want to assign ?"
            })
        } else {
            this.setState({
                popupState: true,
                popupStatus: "Please select state"
            })
            this.removePopup()
        }
        this.setState({ btnClickType: type })
    }

    removePopup() {
        setTimeout(function () {
            this.setState({ popupState: false });
        }.bind(this), 5000)
    }

    closePopup() {
        this.setState({ popupState: false });
    }


    cancelLoanConfirm(confirmType) {
        if (confirmType == 'yes') {
            this.stateWiseAssign()
        } else if (confirmType == 'no') {
            this.setState({
                confirmationPopupState: false
            })
        }
    }

    stateWiseAssign() {
        const { admin } = this.props
        bulkApplicationAssignApi(this.state.bulkAssignDetail, admin,
            (callBack) => {
                if (callBack == 'success') {
                    this.setState({
                        popupState: true,
                        popupStatus: "Application assigned successfully!",
                        bulkAssignDetail: {
                            state: '',
                            stateAgent: []
                        }
                    })
                    alreadyAssigned = []
                    this.roleDataArray(this.state.roleData)
                    this.removePopup()
                    this.getUserAssignStateMethod()
                } else {
                    this.setState({
                        popupState: true,
                        popupStatus: "Please try again"
                    })
                    this.removePopup()
                }
                this.setState({
                    confirmationPopupState: false
                })
            })
    }

}

export default AssignByState;