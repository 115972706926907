import React, { Component } from 'react'
import BulkCommLog from '../../../presentationals/Admin/BulkActions/BulkCommunicationLogs.component'
import { APIS } from '../../../../utils/api-factory'
import EmailPopup from '../../../presentationals/Admin/BulkActions/newbulkemailpopup.component'
import DotLoader from '../../../presentationals/Loader/layerLoader.component'

class CommunicationBulkLogs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            bulkCommunicationLogs: [],
            pageIndexToShow: 0,
            loadarState:false,
            FilteredData: [],
        }
    }

    componentWillMount() {
        this.setState({loadarState: true})
        fetch(APIS.COMMUNICATE_BY_STATUS_LOGS)
            .then(res => res.json())
            .then(res => {
                this.setState({ bulkCommunicationLogs: res, loadarState: false })
            })
    }

    render() {
        return (
            <div>
                {this.state.loadarState == true ?
                    <DotLoader />
                    :
                    <BulkCommLog
                        bulkCommunicationLogs={this.state.bulkCommunicationLogs}
                        showEmail={this.showEmail.bind(this)}
                        getPageValue={this.getPageValue.bind(this)}
                        pageIndexToShow={this.state.pageIndexToShow}
                        getFilterValue={this.getFilterValue.bind(this)}
                        FilteredData={this.state.FilteredData}
                    />}

                {this.state.bulkEmailPopup == true ?
                    <EmailPopup
                        communicationDetail={this.state.communicationDetail}
                        closeCouponPopup={this.closeCouponPopup.bind(this)}
                        getPageValue={this.getPageValue.bind(this)}
                        pageIndexToShow={this.state.pageIndexToShow}
                        getFilterValue={this.getFilterValue.bind(this)}
                        FilteredData={this.state.FilteredData} />
                    : ""}
            </div>
        )
    }

    getPageValue(index) {
        this.setState({ pageIndexToShow: index })
    }

    getFilterValue(filtered) {
        this.setState({ FilteredData: filtered })
    }

    closeCouponPopup() {
        this.setState({ bulkEmailPopup: false })
    }

    showEmail(data) {
        this.setState({
            bulkEmailPopup: true,
            communicationDetail: data
        })
    }
}

export default CommunicationBulkLogs;