import React from "react";
import { editCalculationApi, getCalculationDoctor, getProductFromNbfcId } from "./actionCreator";
import { useState } from "react";
import { useEffect } from "react";
import Input from "./common/InputMUI";
import { APIS } from "../../../../utils/api-factory";

const Calulation = ({ showCalc, productData, closePopup }) => {
    const [nbfcList, setNbfcs] = useState([])
    const [allProducts, setProducts] = useState([])
    // const [productId, setProductId] = useState('')
    const [loanAmount, setLoanAmount] = useState('')
    const [calculatedData, setCalculatedData] = useState('')

    useEffect(() => {
        getNbfcList()
    }, [])
    const getNbfcList = (data) => {
        fetch(APIS.GET_LIST_OF_NBFC + '')
            .then(res => res.json())
            .then(res => {
                console.log(res)
                const nbfcIdArr = []
                res && res.map((data, i) => {
                    nbfcIdArr.push({ name: data.name, code: data.nbfcId })
                })
                setNbfcs(nbfcIdArr)

            })
    }

    const getProduct = (nbfcId) => {
        console.log(nbfcId)
        getProductFromNbfcId(nbfcId, callBack => {
            const productIdArr = []
            callBack && callBack.map((data, i) => {

                productIdArr.push({ name: data.productName, code: data.productId })
            })
            console.log(productIdArr)
            setProducts(productIdArr)
        })
    }
    const productHandler = (e, type) => {
        if (type === 'productId') {
            setProductId(e)
        }
        if (type === 'loanAmount') {
            setLoanAmount(e)
        }
    }
    const submit = () => {
        getCalculationDoctor(productData.productId, loanAmount, callBack => {
            console.log(callBack)
            setCalculatedData(callBack.data)
        })
    }
    const changeHandler = (e, type) => {
        let chn = Object.assign({}, calculatedData)
        chn[type] = e.target.value
        setCalculatedData(chn)
    }
    const recalculate = () => {
        let data = {
            "totEmi": calculatedData.totEmi,
            "advEmi": calculatedData.advEmi,
            "proFeeRate": calculatedData.proFeeRate,
            "proFeeFixed": calculatedData.proFeeFixed,
            "interest": calculatedData.interest,
            "subvention": calculatedData.subvention,
            "nbfcTakeAmount": calculatedData.nbfcTakeAmount,
            "effectiveTenure": calculatedData.effectiveTenure,
            "advEmiAmount": calculatedData.advEmiAmount,
            "totalProcessingFee": calculatedData.totalProcessingFee,
            "chargedToPatientInitially": calculatedData.chargedToPatientInitially,
            "balRecoveredFromPatientViaEmiPrincipal": calculatedData.balRecoveredFromPatientViaEmiPrincipal,
            "totInterest": calculatedData.totInterest,
            "balRecoveredFromPatientViaEmiTotal": calculatedData.balRecoveredFromPatientViaEmiTotal,
            "monthlyEmi": calculatedData.monthlyEmi,
            "disburseToDoctor": calculatedData.disburseToDoctor,
            "nbfcTakePercent": calculatedData.nbfcTakePercent,
            "cpTakePercentInclGST": calculatedData.cpTakePercentInclGST,
            "cpTakeAmountInclGST": calculatedData.cpTakeAmountInclGST,
            "cpTakePercentExclGST": calculatedData.cpTakePercentExclGST,
            "cpTakeAmountExclGST": calculatedData.cpTakeAmountExclGST,
            "loanAmount": calculatedData.loanAmount,
            "gstRate": calculatedData.gstRate
        }


        editCalculationApi(data, callBack => {
            setCalculatedData(callBack.data)
        })
    }
    return (
        <div className={"product-sidepopup-right"}>
            <div className="container-fluid">
                <div className="row" style={{ background: ' #f8f8f8', zIndex: 9999, position: 'sticky', top: 0 }}>
                    <i className="fa fa-times" onClick={() => closePopup()} style={{ cursor: 'pointer', float: 'right', color: 'red', fontSize: '18px',marginRight:'20px',marginTop:'10px' }}></i>
                    <h4 className="mt-1" style={{marginLeft:'20px',marginTop:'10px'}}><b>Product wise Calculations</b></h4>

                    <hr style={{ width: '97%' }} />
                </div>
                <div className="row">
                    {/* <div className="col-sm-3 col-xs-12 mt-3">
                    <SelectField
                        label={'Select Nbfc'}
                        onChange={e => getProduct(e)}
                        data={nbfcList}
                        size={'small'}
                    />
                </div> */}
                    <div className="col-sm-3 col-xs-12 mt-3">
                        {/* <SelectField
                        label={'Select Product'}
                        onChange={e => productHandler(e, 'productId')}
                        data={allProducts}
                        size={'small'}
                    /> */}
                        <Input
                       
                            label={'Product'}
                            type={'text'}
                            value={productData.productName}
                            name={'productName'}
                            id={'productName'}
                            // onChange={e => productHandler(e, 'productName')}
                            // required={true}
                            readOnly={true}
                        />
                    </div>
                    <div className="col-sm-3 col-xs-12 mt-3">
                        <Input
                            label={'Loan Amount'}
                            type={'text'}
                            value={loanAmount}
                            name={'loanAmount'}
                            id={'loanAmount'}
                            onChange={e => productHandler(e, 'loanAmount')}
                            required={true}
                        />
                    </div>
                    <div className="col-sm-3 col-xs-12 ">
                        <button className="btn btn-primary" style={{ width: '100px', marginTop: '10px', marginLeft: 'auto', marginRight: 'auto', marginBottom: '10px', }} onClick={() => submit()}> Calculate</button>
                    </div>

                </div>
                {/* <div className="row text-center ">
                <button className="btn btn-primary" style={{ width: '100px', marginTop: '30px', marginLeft: 'auto', marginRight: 'auto', marginBottom: '30px', }} onClick={() => submit()}> Calculate</button>
            </div> */}
                {calculatedData ?
                    <>

                        <div className=" calculation-div" style={{ padding: '20px', borderRadius: '5px', minHeight: '400px', marginBottom: '10px',boxShadow:'0 4px 8px 0 rgba(0, 0, 0, 0.2)' }}>
                            <span style={{ display: 'flex' }}><h6 className="product-dec-width">Product description</h6> <div className="margin-des" style={{ background: '#D9D9D9', width: '100%', height: '2px', marginTop: '10px' }}></div></span>
                            <div className="row">
                                <div className="col-sm-3 col-xs-12 mt-3">
                                    <label>Total EMI</label>
                                    <p>{calculatedData ? calculatedData.totEmi : ""}</p>
                                </div>
                                <div className="col-sm-3 col-xs-12 mt-3">
                                    <label>Advance EMI</label>
                                    <p>{calculatedData ? calculatedData.advEmi : ""}</p>
                                    {/* <input className="form-control" value={calculatedData.effectiveTenure} onChange={(e) => changeHandler(e, 'effectiveTenure')} /> */}
                                </div>
                                <div className="col-sm-3 col-xs-12 mt-3">
                                    <label>Loan Amount</label>
                                    {/* <p><i className="fa fa-rupee"></i>&nbsp;{loanAmount ? loanAmount : ""}</p> */}
                                    <input className="form-control" value={calculatedData.loanAmount} onChange={(e) => changeHandler(e, 'loanAmount')} />
                                </div>
                            </div>
                            <span style={{ display: 'flex', marginTop: '20px' }}><h6 className="product-dec-width-second">Payables</h6> <div className="margin-des" style={{ background: '#D9D9D9', width: '100%', height: '2px', marginTop: '10px' }}></div></span>
                            <div className="row">
                                <div className="col-sm-3 col-xs-12 mt-3">
                                    <label>NBFC Amount</label>
                                    <p><i className="fa fa-rupee"></i>&nbsp;{calculatedData ? calculatedData.nbfcTakeAmount : ""}</p>
                                    {/* <input className="form-control" value={calculatedData.nbfcTakeAmount} onChange={(e) => changeHandler(e, 'nbfcTakeAmount')} /> */}

                                </div>
                                <div className="col-sm-3 col-xs-12 mt-3">
                                    <label>NBFC Percentage</label>
                                    <p>{calculatedData ? calculatedData.nbfcTakePercent : ""}</p>
                                    {/* <input className="form-control" value={calculatedData.nbfcTakePercent} onChange={(e) => changeHandler(e, 'nbfcTakePercent')} /> */}

                                </div>
                                <div className="col-sm-3 col-xs-12 mt-3">
                                    <label>Doctor Amount</label>
                                    <p><i className="fa fa-rupee"></i>&nbsp;{calculatedData ? calculatedData.disburseToDoctor : ""}</p>
                                    {/* <input className="form-control" value={calculatedData.disburseToDoctor} onChange={(e) => changeHandler(e, 'disburseToDoctor')} /> */}

                                </div>
                            </div>
                            <span style={{ display: 'flex', marginTop: '20px' }}><h6 className="product-dec-width-third"> Receivables</h6> <div className="margin-des" style={{ background: '#D9D9D9', width: '100%', height: '2px', marginTop: '10px' }}></div></span>

                            <div className="row">
                                <div className="col-sm-3 col-xs-12 mt-3">
                                    <label>Amount (inclusive GST)</label>
                                    <p><i className="fa fa-rupee"></i>&nbsp;{calculatedData ? calculatedData.cpTakeAmountInclGST : ""}</p>
                                    {/* <input className="form-control" value={calculatedData.cpTakeAmountInclGST} onChange={(e) => changeHandler(e, 'cpTakeAmountInclGST')} /> */}

                                </div>
                                <div className="col-sm-3 col-xs-12 mt-3">
                                    <label>Percentage</label>
                                    <p>{calculatedData ? calculatedData.cpTakePercentInclGST : ""}%</p>
                                    {/* <input className="form-control" value={calculatedData.cpTakePercentInclGST} onChange={(e) => changeHandler(e, 'cpTakePercentInclGST')} /> */}

                                </div>
                                <div className="col-sm-3 col-xs-12 mt-3">
                                    <label>GST</label>
                                    {/* <p>{'18%'}</p> */}
                                    <input className="form-control" value={calculatedData.gstRate} onChange={(e) => changeHandler(e, 'gstRate')} />


                                </div>
                            </div>
                            <span style={{ display: 'flex', marginTop: '20px' }}><h6 className="product-dec-width-fourth">Subvention</h6> <div className="margin-des" style={{ background: '#D9D9D9', width: '100%', height: '2px', marginTop: '10px' }}></div></span>

                            <div className="row">
                                <div className="col-sm-3 col-xs-12 mt-3">
                                    <label>Percentage</label>
                                    {/* <p>{calculatedData ? calculatedData.subvention : ""} %</p> */}
                                    <input className="form-control" value={calculatedData.subvention} onChange={(e) => changeHandler(e, 'subvention')} />

                                </div>
                            </div>

                            <span style={{ display: 'flex', marginTop: '20px' }}><h6 className="product-dec-width-fourth">Interest</h6> <div className="margin-des" style={{ background: '#D9D9D9', width: '100%', height: '2px', marginTop: '10px' }}></div></span>

                            <div className="row">
                                <div className="col-sm-3 col-xs-12 mt-3">
                                    <label>Interest %</label>
                                    {/* <p>{calculatedData ? calculatedData.subvention : ""} %</p> */}
                                    <input className="form-control" value={calculatedData.interest} onChange={(e) => changeHandler(e, 'interest')} />

                                </div>
                            </div>
                            <span style={{ display: 'flex', marginTop: '20px' }}><h6 className="product-dec-width-fifth" >Processing fees</h6> <div className="margin-des" style={{ background: '#D9D9D9', width: '100%', height: '2px', marginTop: '10px' }}></div></span>

                            <div className="row">
                                <div className="col-sm-3 col-xs-12 mt-3">
                                    <label>Fixed amount</label>
                                    {/* <p><i className="fa fa-rupee"></i>&nbsp;{calculatedData ? (calculatedData.proFeeFixed) : ""}</p> */}
                                    <input className="form-control" value={calculatedData.proFeeFixed} onChange={(e) => changeHandler(e, 'proFeeFixed')} />

                                </div>
                                <div className="col-sm-3 col-xs-12 mt-3">
                                    <label>Percentage</label>
                                    {/* <p>{calculatedData ? calculatedData.proFeeRate : ""}%</p> */}
                                    <input className="form-control" value={calculatedData.proFeeRate} onChange={(e) => changeHandler(e, 'proFeeRate')} />

                                </div>
                                <div className="col-sm-3 col-xs-12 mt-3">
                                    <label>Total processing fees</label>
                                    <p><i className="fa fa-rupee"></i>&nbsp;{calculatedData ? calculatedData.totalProcessingFee : ""}</p>
                                    {/* <input className="form-control" value={calculatedData.totalProcessingFee} onChange={(e) => changeHandler(e, 'totalProcessingFee')} /> */}

                                </div>
                            </div>
                            <span style={{ display: 'flex', marginTop: '20px' }}><h6 className="product-dec-width-sixth">Advance EMI</h6> <div className="margin-des" style={{ background: '#D9D9D9', width: '100%', height: '2px', marginTop: '10px' }}></div></span>

                            <div className="row">
                                <div className="col-sm-3 col-xs-12 mt-3">
                                    <label>Amount</label>
                                    <p><i className="fa fa-rupee"></i>&nbsp;{calculatedData ? calculatedData.advEmiAmount : ""}</p>
                                    {/* <input className="form-control" value={calculatedData.advEmiAmount} onChange={(e) => changeHandler(e, 'advEmiAmount')} /> */}

                                </div>
                                <div className="col-sm-3 col-xs-12 mt-3">
                                    <label>Count </label>
                                    <p>{calculatedData ? calculatedData.advEmi : ""}</p>
                                    {/* <input className="form-control" value={calculatedData.advEmi} onChange={(e) => changeHandler(e, 'advEmi')} /> */}

                                </div>
                                <div className="col-sm-3 col-xs-12 mt-3">
                                    <label>Amount charged to patient initially</label>
                                    {/* <input className="form-control" value={calculatedData.chargedToPatientInitially} onChange={(e) => changeHandler(e, 'chargedToPatientInitially')} /> */}

                                    <p><i className="fa fa-rupee"></i>&nbsp;{calculatedData ? calculatedData.chargedToPatientInitially : ""}</p>
                                </div>
                            </div>
                            <span style={{ display: 'flex', marginTop: '20px' }}><h6 className="product-dec-width-seventh">Recoverables from borrower</h6> <div className="margin-des" style={{ background: '#D9D9D9', width: '100%', height: '2px', marginTop: '10px' }}></div></span>

                            <div className="row">
                                <div className="col-sm-3 col-xs-12 mt-3">
                                    <label>EMI amount</label>
                                    <p><i className="fa fa-rupee"></i>&nbsp;{calculatedData ? calculatedData.monthlyEmi : ""}</p>
                                    {/* <input className="form-control" value={calculatedData.monthlyEmi} onChange={(e) => changeHandler(e, 'monthlyEmi')} /> */}

                                </div>
                                <div className="col-sm-3 col-xs-12 mt-3">
                                    <label>Balance via principal</label>
                                    <p><i className="fa fa-rupee"></i>&nbsp;{calculatedData ? calculatedData.balRecoveredFromPatientViaEmiPrincipal : ""}</p>
                                    {/* <input className="form-control" value={calculatedData.balRecoveredFromPatientViaEmiPrincipal} onChange={(e) => changeHandler(e, 'balRecoveredFromPatientViaEmiPrincipal')} /> */}

                                </div>
                                <div className="col-sm-3 col-xs-12 mt-3">
                                    <label>Total Interest</label>
                                    <p>{calculatedData ? calculatedData.totInterest : ""}</p>
                                    {/* <input className="form-control" value={calculatedData.totInterest} onChange={(e) => changeHandler(e, 'totInterest')} /> */}

                                </div>

                                <div className="col-sm-3 col-xs-12 mt-3">
                                    <label>Balance via total</label>
                                    <p><i className="fa fa-rupee"></i>&nbsp;{calculatedData ? calculatedData.balRecoveredFromPatientViaEmiTotal : ""}</p>
                                    {/* <input className="form-control" value={calculatedData.balRecoveredFromPatientViaEmiTotal} onChange={(e) => changeHandler(e, 'balRecoveredFromPatientViaEmiTotal')} /> */}

                                </div>

                            </div>
                            <div className="text-center">
                                <button className="btn btn-primary" style={{ width: '150px', marginTop: '30px', marginLeft: 'auto', marginRight: 'auto', marginBottom: '30px', }} onClick={() => recalculate()}>Re-Calculate</button>

                            </div>
                        </div>
                    </>
                    : ""}
            </div >
        </div>
    )
}
export default Calulation