import React, { Component } from 'react'
import AmbassadarDetails from '../../../presentationals/Admin/Ambassador/AmbassadorDetails.component'
import { getAmbassadorDetailApi } from '../AdminActionCreator.component'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

class AMBDashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ambassadorAllData: this.props.ambassadorAllData
        }
    }

    componentWillMount() {
        this.props.getAmbassadorDetailApi(this.state.ambassadorAllData.ambassadorId,
            (callBack) => {
                this.setState({
                    ambassadorAllData: callBack,
                })
            })
    }

    render() {
        return (
            <AmbassadarDetails
                ambassadorAllData={this.state.ambassadorAllData} />
        )
    }
}

const mapStateToProps = ({ ambassadorAllData }) => {
    return {
        ambassadorAllData: ambassadorAllData
    };
};

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        getAmbassadorDetailApi
    }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(AMBDashboard);