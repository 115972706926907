import React, { Component } from 'react'
import AmbassadorAction from '../../../presentationals/Admin/Ambassador/AmbassadorAction.component'
import { APIS } from '../../../../utils/api-factory'
import Popup from '../../../presentationals/Popup/Popup.component';

class AmbsAct extends Component {
    constructor(props) {
        super(props);
        this.state = {
            popupState: false,
            popupStatus: '',
            defaultStatus: '',
            AmbStatus: '',
            applicationId: ''
        }
    }

    componentWillMount() {
        const { ambassadorAllData } = this.props
        this.setState({ defaultStatus: ambassadorAllData.ambassadorStatus })
    }

    render() {
        const { admin } = this.props
        return (
            <div>
                {this.state.popupState == true ?
                    <Popup
                        closePopup={this.closePopup.bind(this)}
                        popupStatus={this.state.popupStatus}
                    />
                    : ""}
                <AmbassadorAction
                    ambasadorStatus={this.ambasadorStatus.bind(this)}
                    ambasadorStatusHandler={this.ambasadorStatusHandler.bind(this)}
                    defaultStatus={this.state.defaultStatus}
                    addAplicationId={this.addAplicationId.bind(this)}
                    addAplicationIdHandler={this.addAplicationIdHandler.bind(this)}
                    admin={admin}
                />
            </div>

        )
    }

    addAplicationId(e) {
        this.setState({
            applicationId: e.target.value
        })
    }

    addAplicationIdHandler() {
        const { admin } = this.props
        if (this.state.applicationId != '') {
            fetch(APIS.ADD_AMBASSADOR_ID_IN_APPLICATION + this.props.ambassadorAllData.ambassadorId + '&applicationId=' + this.state.applicationId + '&empId=' + admin.emailId)
                .then(res => res.text())
                .then(text => {
                    if (text == 'success') {
                        this.setState({
                            popupState: true,
                            popupStatus: "Ambassador Id save successfully",
                            disburseLoanId: ''
                        })
                        this.removePopup();
                    } else {
                        this.setState({
                            popupState: true,
                            popupStatus: "Ambassador Id not save",
                        })
                        this.removePopup();
                    }
                })
        } else {
            this.setState({
                popupState: true,
                popupStatus: "Please enter Cheque Limit",
                disburseLoanId: ''
            })
            this.removePopup();
        }
    }

    removePopup() {
        setTimeout(function () {
            this.setState({ popupState: false });
        }.bind(this), 5000)
    }

    closePopup() {
        this.setState({ popupState: false });
    }

    ambasadorStatus(e) {
        this.setState({ AmbStatus: e.target.value })
    }

    ambasadorStatusHandler() {
        const { ambassadorAllData } = this.props
        fetch(APIS.UPDATE_AMBASSADOR_STATUS + ambassadorAllData.ambassadorId + '&status=' + this.state.AmbStatus)
            .then(res => res.text())
            .then(res => {
                this.setState({
                    popupState: true,
                    popupStatus: 'Now the profile is ' + this.state.AmbStatus,
                })
                this.removePopup();
            })
    }

}

export default AmbsAct;