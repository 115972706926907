import React from 'react'
import { ROLE } from '../../../utils/Constant'
import HomeImage from '../../../images/generate-lead (1).png'
import SecondLogo from '../../../images/Group (5).png'
import ThirdLogo from '../../../images/Vector (25).png'
import FourthLogo from '../../../images/marketing.png'
import FifthLogo from '../../../images/Layer 4.png'
import SixthLogo from '../../../images/access-control.png'
import SeventhLogo from '../../../images/dashboard (1).png'
import EighthLogo from '../../../images/Digital Key.png'
import NinthLogo from '../../../images/Group (6).png'

const SettingsNavOptions = ({
    navItemClick,
    pageViewHandler,
    admin,
    menuOpenState,
    generateReportState,
    subHandleTab,
    losSubHandle, pageViewState, lmsSubTab
}) => (
    <div className='phocket-crm-menu new-menu animate-crm-menu  ' id='CRMMenu'>
        <ul className='mail-list-new'>

            {/* <div className='mail-list'>
                <li
                    className={menuOpenState === 'marketing' ? 'mail-list-item-active' : 'mail-list-item'}
                    onClick={e => navItemClick('marketing')}
                >
                    <img src={FourthLogo} width='20px' style={{ marginTop: '-4px' }} /><span style={{ marginLeft: '10px' }}> Buisness </span>{menuOpenState === 'marketing' ? <i class="fa fa-caret-up" aria-hidden="true" style={{ float: 'right', color: '#fff' }}></i> : <i class="fa fa-caret-down" aria-hidden="true" style={{ float: 'right', color: '#fff' }}></i>}
                </li>
            </div> */}
            {menuOpenState == 'marketing' ?
                <>
                    {/* {admin.rolelist != undefined ?
                        admin.rolelist.indexOf(ROLE.Asset_Quality) >= 0 ? */}
                    {/* <li
                        className={pageViewState === 'aggregatorRegistration' ? 'sub-list-item-active' : 'sub-list-item'}
                        onClick={e => pageViewHandler('aggregatorRegistration')}
                    >
                        Aggregator Registration
                    </li> */}
                    {/* : "" : ""} */}
                    <li
                        className={pageViewState === 'maketingDashboard' ? 'sub-list-item-active' : 'sub-list-item'}
                        onClick={e => pageViewHandler('maketingDashboard')}
                    >
                        Dashboard
                    </li>

                    <li
                        className={pageViewState === 'maketingList' ? 'sub-list-item-active' : 'sub-list-item'}
                        onClick={e => pageViewHandler('maketingList')}
                    >
                        Aggregators
                    </li>
                    {/* <li
                        className={pageViewState === 'budgetPlan' ? 'sub-list-item-active' : 'sub-list-item'}
                        onClick={e => pageViewHandler('budgetPlan')}
                    >
                        Budget Plan
                    </li> */}
                    <li
                        className={pageViewState === 'runCampaign' ? 'sub-list-item-active' : 'sub-list-item'}
                        onClick={e => pageViewHandler('runCampaign')}
                    >
                        Run Campaign
                    </li>
                    <li
                        className={pageViewState === 'deviation' ? 'sub-list-item-active' : 'sub-list-item'}
                        onClick={e => pageViewHandler('deviation')}
                    >
                        Deviation Report
                    </li>
                    <li
                        className={pageViewState === 'buisnessAggregator' ? 'sub-list-item-active' : 'sub-list-item'}
                        onClick={e => pageViewHandler('buisnessAggregator')}
                    >
                        Business Aggregator
                    </li>
                    {admin.rolelist.indexOf(ROLE.Generate_Report_Logs) >= 0 ?

                        <li
                            className={pageViewState === 'branchData' ? 'sub-list-item-active' : 'sub-list-item'}
                            onClick={e => pageViewHandler('branchData')}
                        >
                            Generate Branch Report
                        </li>
                        : ""}
                    {admin.rolelist.indexOf(ROLE.Generate_Buddy_Loan_Report) >= 0 ?

                        <li
                            className={pageViewState === 'buddyLoanReport' ? 'sub-list-item-active' : 'sub-list-item'}
                            onClick={e => pageViewHandler('buddyLoanReport')}
                        >
                            Generate Buddyloan Report
                        </li>
                        : ""}
                    {/* {admin.rolelist != undefined ?
                        admin.rolelist.indexOf(ROLE.MARKETING_TOOL) >= 0 ?
                            <div
                                className={pageViewState === 'BIPage' ? 'sub-list-item-active' : 'sub-list-item'}
                                id='BIPage'
                                onClick={e => pageViewHandler('BIPage')}
                            >
                                Bi
                            </div>
                            : "" : ""} */}

                </>
                : ""}

        </ul>
    </div>
)

export default SettingsNavOptions