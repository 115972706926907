import React from 'react';
import ReactTable from "react-table";

const couponStatistics = ({ couponStatData,
    getFilterValue, FilteredData, getPageValue, pageIndexToShow }) => (
    <div className="container-fluid">
        <div className="row merchant-list">
            <div className="col-xs-12">
                <h2 className="orange-text text-center">Coupon Redemption Statistics</h2>

                {couponStatData != null && couponStatData != '' && couponStatData.length > 0 ?
                    <div className='overflow-auto'>
                        <table className='phocket-table'>
                            <thead>

                                <tr>
                                    <th>Coupon Code</th>
                                    <th>Number Of Redemption</th>
                                    <th>Start Date</th>
                                    <th>End Date</th>

                                </tr>

                            </thead>
                            <tbody>
                                {couponStatData && couponStatData.map((promo, i) => {
                                    return (
                                        <tr key={i}>
                                            <td>{promo.couponCode}</td>
                                            <td>{promo.count}</td>
                                            <td>{promo.startDate}</td>
                                            <td>{promo.endDate}</td>



                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                    : ""}

            </div>
        </div>
    </div>
)

export default couponStatistics;