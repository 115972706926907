import React, { Component } from 'react'
import Crif from '../../../presentationals/Admin/CustomerPages/Crif.component'
import { APIS } from '../../../../utils/api-factory'
import Loader from '../../../presentationals/Loader/DotLoader.component'

class CrifData extends Component {
  constructor(props) {
    super(props)
    this.state = {
      allDetail: this.props.allDetail,
      userCrifData: '',
      loaderState: false,
      regenerate: '0',
      analysisData: ''
    }
  }

  componentDidMount() {
    this.CrifData()
    this.getCrifAnalysisData()
  }
  componentWillReceiveProps() {
    this.setState({
      allDetail: this.props.allDetail
    })
    this.CrifData()
    this.getCrifAnalysisData()
  }
  CrifData() {
    this.setState({ loaderState: true })
    fetch(
      APIS.GET_CRIF_DATA + this.state.allDetail.userId + '&regenerateParam=' + this.state.regenerate + '&loanId=' + this.state.allDetail.loanId)
      .then(res => res.text())
      .then(res => {
        if (res.responseCode == 0) {
        } else {
          this.setState({
            userCrifData: res,
            loaderState: false
          })
        }
      })
  }
  getCrifAnalysisData() {
    fetch(APIS.GET_CRIF_ANALYSIS_DATA + this.state.allDetail.userId)
      .then(res => res.json())
      .then(res => {
        this.setState({
          analysisData: res
        })
      })
  }

  render() {
    return (
      <div className='full-height'>
        {this.state.loaderState == true ? <Loader /> : ''}
        <Crif
          analysisData={this.state.analysisData}
          allDetail={this.state.allDetail}
          userCrifData={this.state.userCrifData}
          regenerateCrif={this.regenerateCrif.bind(this)}
        />
      </div>
    )
  }
  regenerateCrif() {
    this.setState({ loaderState: true })
    fetch(
      APIS.GET_CRIF_DATA +
      this.state.allDetail.userId +
      '&regenerateParam=' +
      '1' + '&loanId=' + this.state.allDetail.loanId
    )
      .then(res => res.text())
      .then(res => {
        if (res.responseCode == 0) {
        } else {
          this.setState({
            userCrifData: res,
            loaderState: false
          })
        }
      })
  }
}

export default CrifData
