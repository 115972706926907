import React from 'react'
import ReactHtmlParser from 'react-html-parser';
import moment from 'moment';
import Skeleton from 'react-loading-skeleton';

const Logs = ({ recoveryComLogs,loaderState }) => (
    <div className="container-fluid">
        {!loaderState ?
            <div className="row">

                <div className="col-xs-12 phocket-table-new">
                    {recoveryComLogs != '' ?
                        <table className="text-center ">
                            <thead>
                                <tr>
                                    <th>Sr. No.</th>
                                    <th>Type</th>
                                    <th>Application Id</th>
                                    <th>Subject</th>
                                    <th>Body</th>
                                    <th>Date</th>
                                    <th>Send By</th>
                                </tr>
                            </thead>
                            <tbody>
                                {recoveryComLogs.map((recData, i) => {
                                    return (
                                        <tr key={i}>
                                            <td>{i + 1}</td>
                                            <td>{recData.type}</td>
                                            <td>{recData.applicationId != null ? recData.applicationId : ""}</td>
                                            <td>{recData.subject}</td>
                                            <td>{ReactHtmlParser(recData.comments)}</td>
                                            <td>{moment(new Date(recData.createdOn)).format('DD-MMM-YYYY')}</td>
                                            <td>{recData.employeeID}</td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                        : ""}
                </div>
            </div> :
            <div className='phocket-table-new' style={{ marginTop: '80px' }}>
                <table className='text-center '  >
                    <thead>
                        <tr>
                            <th>Sr. No.</th>
                            <th>Type</th>
                            <th>Application Id</th>
                            <th>Subject</th>
                            <th>Body</th>
                            <th>Date</th>
                            <th>Send By</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td><Skeleton height={40} /></td>
                            <td><Skeleton height={40} /></td>
                            <td><Skeleton height={40} /></td>
                            <td><Skeleton height={40} /></td>
                              <td><Skeleton height={40} /></td>
                            <td><Skeleton height={40} /></td>
                            <td><Skeleton height={40} /></td>


                        </tr>
                        <tr>
                            <td><Skeleton height={40} /></td>
                            <td><Skeleton height={40} /></td>
                            <td><Skeleton height={40} /></td>
                            <td><Skeleton height={40} /></td>
                              <td><Skeleton height={40} /></td>
                            <td><Skeleton height={40} /></td>
                            <td><Skeleton height={40} /></td>

                        </tr>
                        <tr>
                            <td><Skeleton height={40} /></td>
                            <td><Skeleton height={40} /></td>
                            <td><Skeleton height={40} /></td>
                            <td><Skeleton height={40} /></td>
                              <td><Skeleton height={40} /></td>
                            <td><Skeleton height={40} /></td>
                            <td><Skeleton height={40} /></td>

                        </tr>
                        <tr>
                            <td><Skeleton height={40} /></td>
                            <td><Skeleton height={40} /></td>
                            <td><Skeleton height={40} /></td>
                            <td><Skeleton height={40} /></td>
                              <td><Skeleton height={40} /></td>
                            <td><Skeleton height={40} /></td>
                            <td><Skeleton height={40} /></td>

                        </tr>
                        <tr>
                            <td><Skeleton height={40} /></td>
                            <td><Skeleton height={40} /></td>
                            <td><Skeleton height={40} /></td>
                            <td><Skeleton height={40} /></td>
                              <td><Skeleton height={40} /></td>
                            <td><Skeleton height={40} /></td>
                            <td><Skeleton height={40} /></td>

                        </tr>
                        <tr>
                            <td><Skeleton height={40} /></td>
                            <td><Skeleton height={40} /></td>
                            <td><Skeleton height={40} /></td>
                            <td><Skeleton height={40} /></td>
                              <td><Skeleton height={40} /></td>
                            <td><Skeleton height={40} /></td>
                            <td><Skeleton height={40} /></td>

                        </tr>

                    </tbody>
                </table>
            </div>
        }
    </div>
)

export default Logs;