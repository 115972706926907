import React, { useEffect, useState } from "react";
import KycImageActive from './images/placeholder Fill (4).png'
import messageInctive from './images/placeholder Fill (14).png'
import legalactive from './images/placeholder Fill (15).png'
import SalaryActive from './images/placeholder Fill (16).png'
import legalInactive from './images/placeholder Fill (17).png'
import SalaryInctive from './images/placeholder Fill (18).png'
import EmiActive from './images/placeholder Fill (16).png'
import EmInactive from './images/placeholder Fill (18).png'
import TxnActive from './images/placeholder Fill (4).png'
import TxninActive from './images/placeholder Fill (14).png'
import Nodata from '../../../../images/nodata.png'

import { getNotesnew, getSmsAnalysisStatusWithRegenerate, saveCommentForCreditAssesment } from "../Analysis/analysisactionCreator";
import { APIS } from "../../../../utils/api-factory";
import ALLTxNSms from './allsmstransaction'
import { Pagination } from "@mui/material";
import { saveUnderWriterApi } from "../AdminActionCreator.component";
import moment from "moment";
import RawMsg from "../CustomerPages/RawMsg.container";
import Skeleton from "react-loading-skeleton";
// import Loader from '../../../presentationals/Loader/Loader.component'
let paginationNumber = 10
let jsondata = {};

const SmsAnalysis = ({ allDetail, sendDataToMainPage, smsJson, admin }) => {
    const [filterType, setfilterType] = useState('Overdue')
    const [smsData, setSmsData] = useState('')
    const [tableMinPagination, settableMinPagination] = useState(0)
    const [tableMaxPagination, settableMaxPagination] = useState(paginationNumber)
    const [msgData, setMessageData] = useState('')
    const [pageNumber, setpageNumber] = useState(1)
    const [underWriterStatus, setunderWriterStatus] = useState('')
    const [popupStatus, setpopupStatus] = useState('')
    const [sidePoupState, setsidePoupState] = useState('')
    const [newNotes, setnewNotes] = useState('')
    const [notesType, setNotesType] = useState('')
    const [notesPopup, setNotesPopup] = useState(false)
    const [notesText, setNotesText] = useState('')
    const [loaderState, setloaderState] = useState(false)
    const [regenerate, setregenerate] = useState(1)


    const smsTabHandler = (type) => {
        settableMinPagination(0)
        settableMaxPagination(10)
        setfilterType(type)
    }
    const getSmsAnalysisStatusData = () => {
        setloaderState(true)
        getSmsAnalysisStatusWithRegenerate(allDetail.userId, regenerate, callback => {
            setSmsData(callback)
            setloaderState(false)

        })
    }
    useEffect(() => {
        getSmsAnalysisStatusData()
        jsondata = smsJson

    }, [])
    const regenerateSms = () => {
        setloaderState(true)
        getSmsAnalysisStatusWithRegenerate(allDetail.userId, 1, callback => {
            setSmsData(callback)
            setloaderState(false)

        })
    }
    const phocketTablePagination = (data, type) => {
        if (type == 'back') {
            if (tableMinPagination >= paginationNumber) {
                settableMinPagination(tableMinPagination - paginationNumber)
                settableMaxPagination(tableMaxPagination - paginationNumber)


            }
        } else if (type == 'forward') {
            if (tableMaxPagination < data.length) {
                settableMinPagination(tableMinPagination + paginationNumber)
                settableMaxPagination(tableMaxPagination + paginationNumber)

            }
        }
    }
    const handleChangeNewPagination = (e, value) => {
        setpageNumber(value)
        const newMinPagination = (value - 1) * paginationNumber;
        const newMaxPagination = value * paginationNumber;
        settableMaxPagination(newMaxPagination)
        settableMinPagination(newMinPagination)

    };
    const smsJsonData = (data, value) => {
        if (jsondata.hasOwnProperty(data)) {
            const index = jsondata[data].indexOf(value);
            if (index !== -1) {
                jsondata[data].splice(index, 1);
            } else {
                jsondata[data].push(value);
            }
        } else {
            jsondata[data] = [value];
        }

        sendDataToMainPage(jsondata)

    };

    // const smsJsonData = (data, value) => {
    //     let jsondata = {};

    //     if (jsondata.hasOwnProperty(data)) {
    //         jsondata[data].push(value);
    //     } else {
    //         jsondata[data] = [value];
    //     }

    // }



    const commentChangeHandler = (e, type) => {
        if (e.target.value !== 'add') {
            setNotesText(e.target.value)
        }
        if (e.target.value === 'add') {
            setNotesPopup(true)
            setNotesText(e.target.value)

        }
        setNotesType(type)

    }
    const saveNotesType = () => {
        if (notesText !== '' && notesText !== undefined && notesText !== null) {
            saveCommentForCreditAssesment(allDetail.loanId, allDetail.userId, notesText, admin.emailId, filterType, callBack => {
                setNotesPopup(false)
                if (callBack === 'success') {
                    getNotesnew(allDetail.loanId, allDetail.userId, filterType, callBack => {
                        setnewNotes(callBack)
                    })
                }

            })
        }
    }

    const saveUnderWritter = (type) => {
        let decisionType = 'smsAnalysisDecision'
        if (underWriterStatus !== '' && decisionType !== '') {
            saveUnderWriterApi(decisionType, allDetail, underWriterStatus, admin, callBack => {

                if (callBack === 'success') {
                    setunderWriterStatus('')
                    setpopupStatus('successfully saved !')
                    setsidePoupState(true)
                    removePopup()

                } else {
                    setpopupStatus('please try again later !')
                    setsidePoupState(true)
                    removePopup()
                }
            })
        } else {
            setpopupStatus('Please select value !')
            setsidePoupState(true)
            removePopup()
        }
    }
    const removePopup = () => {
        setTimeout(() => {
            setsidePoupState(false)
        }, 5000);
    }
    const underWriterHandler = (e) => {
        setunderWriterStatus(e.target.value)
    }
    const closepopupSide = () => {
        setsidePoupState(false)

    }
    const openCommentPopup = (data) => {
        setNotesPopup(true)
        setNotesType(data)
        getNotesnew(allDetail.loanId, allDetail.userId, data, callBack => {
            setnewNotes(callBack)
        })

    }
    const closepopup = () => {
        setNotesPopup(false)
        setNotesText('')
        setnewNotes('')
    }
    return (
        <>
            {/* {loaderState ?
                <Loader />
                : ""} */}
            {notesPopup ?
                <div className="background-blur">

                    <div className="popup-main small-popup container-fluid max-width-500px  " style={{ padding: '20px', borderRadius: '10px' }}>
                        <i onClick={() => closepopup()} style={{ float: "right", marginTop: '-18px', marginRight: '-17px', fontSize: '20px', color: 'red' }} className="fa fa-times-circle"></i>
                        <div className="row from-group">
                            <div className="col-xs-12" style={{ padding: '10px' }}>
                                <h5 className="" style={{ marginBottom: '10px', marginTop: '10px', fontWeight: '700' }}><i style={{ fontWeight: '700' }} className='fa fa-commenting-o'></i>&nbsp;&nbsp; Comment</h5>
                            </div>
                        </div>
                        <div style={{ background: '#F1F7FF', border: '1px solid #DBE9FF', padding: '10px', borderRadius: '12px' }}>
                            Status : { }
                            <hr />
                            <textarea className='form-control' placeholder='type here...' onChange={(e) => commentChangeHandler(e, notesType)} />
                            <div className='text-right'>
                                <button className="btn btn-primary" style={{ marginTop: '10px', borderRadius: '5px', border: '1px solid #D3D9E9', boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)', background: '#2B78FF' }} onClick={() => saveNotesType()}>Save</button>
                            </div>
                        </div>
                        {newNotes !== '' && newNotes.length > 0 ? <h5 style={{ fontWeight: '700' }}> Previous Comments</h5> : ""}
                        {newNotes !== '' && newNotes.length > 0 ?
                            <div style={{
                                borderRadius: '10px',
                                border: '1px solid #D1D9E2',
                                background: '#fff',
                                maxHeight: '200px',
                                overflowY: 'scroll'
                            }}>

                                {newNotes.map((data, i) => {
                                    return (
                                        <div className='container-fluid'>
                                            <div className='row' style={{ borderBottom: '1px solid #D1D9E2', padding: '10px' }} key={i}>
                                                <div className='col-sm-6 col-xs-6'>
                                                    {data.notes}<br />
                                                    <span style={{ color: 'rgba(0, 0, 0, 0.45)', fontSize: '10px' }}>{data.empId}</span>
                                                </div>
                                                <div className='col-sm-6 col-xs-6 text-right'>
                                                    <span style={{ color: 'rgba(0, 0, 0, 0.45)', fontSize: '10px' }}>{data.addedOn ? moment(data.addedOn).format('DD-MM-YYYY') : ""}</span>
                                                </div>

                                            </div>
                                            {/* <hr /> */}
                                        </div>
                                    )
                                })}
                            </div>
                            : ""}

                    </div>
                </div>
                : ""}
            <div className=" details-body-credit full-height credit-module">
                <div className="row">
                    <div className="col-sm-3 col-xs-12">
                        <div className="row" style={{ marginTop: '20px' }}>
                            <div className="col-sm-4 text-right">
                                <img src={filterType === 'Overdue' ? KycImageActive : messageInctive} width={'32px'} />
                            </div>
                            <div className="col-sm-8" onClick={() => smsTabHandler('Overdue')} style={{ fontSize: '14px', cursor: 'pointer', color: filterType === 'Overdue' ? '#2B78FF' : '#000', fontWeight: filterType === 'Overdue' ? '700' : '400' }}>
                                <span style={{ color: filterType === 'Overdue' ? '#1eb0d2' : 'rgba(0, 0, 0, 0.45)', fontWeight: filterType === 'Overdue' ? '700' : '400' }}> Overdue SMS</span>
                                <br />
                                {smsData && smsData.overDue ? <div style={{ marginBottom: '3.5px', fontSize: '12px', color: 'rgba(0, 0, 0, 0.45)' }}><b>{smsData.overDueMsg.length}</b> &nbsp;Found </div> :
                                    ""}
                            </div>

                        </div>
                        <div className="row" style={{ marginTop: '20px' }}>
                            <div className="col-sm-4 text-right">
                                <img src={filterType === 'Emi' ? EmiActive : EmInactive} width={'32px'} />
                            </div>
                            <div className="col-sm-8" onClick={() => smsTabHandler('Emi')} style={{ fontSize: '14px', cursor: 'pointer', color: filterType === 'Emi' ? '#2B78FF' : '#000', fontWeight: filterType === 'Emi' ? '700' : '400' }}>
                                <span style={{ color: filterType === 'Emi' ? '#1eb0d2' : 'rgba(0, 0, 0, 0.45)', fontWeight: filterType === 'Emi' ? '700' : '400' }}> EMI Amount</span>
                                <br />
                                {smsData && smsData.totalEmiAmt ? <span style={{ fontSize: '12px', color: '#2B78FF' }}>INR {smsData ? Math.round(smsData.totalEmiAmt) : ''}</span> : ""}
                                {smsData && smsData.emiMsg ? <div style={{ marginBottom: '3.5px', fontSize: '12px', color: 'rgba(0, 0, 0, 0.45)' }}><b>{smsData.emiMsg.length}</b> &nbsp;Found </div> :
                                    ""}
                            </div>

                        </div>
                        <div className="row" style={{ marginTop: '20px' }}>
                            <div className="col-sm-4 text-right">
                                <img src={filterType === 'Legal' ? legalactive : legalInactive} width={'32px'} />
                            </div>
                            <div className="col-sm-8" onClick={() => smsTabHandler('Legal')} style={{ fontSize: '14px', cursor: 'pointer', color: filterType === 'Legal' ? '#2B78FF' : '#000', fontWeight: filterType === 'Legal' ? '700' : '400' }}>
                                <span style={{ color: filterType === 'Legal' ? '#1eb0d2' : 'rgba(0, 0, 0, 0.45)', fontWeight: filterType === 'Legal' ? '700' : '400' }}> Legal SMS</span>
                                <br />
                                {smsData && smsData.legal ? <div style={{ marginBottom: '3.5px', fontSize: '12px', color: 'rgba(0, 0, 0, 0.45)' }}><b>{smsData.legalMsg.length}</b> &nbsp;Found </div> :
                                    ""}            </div>

                        </div>

                        <div className="row" style={{ marginTop: '20px' }}>
                            <div className="col-sm-4 text-right">
                                <img src={filterType === 'Salary' ? SalaryActive : SalaryInctive} width={'32px'} />
                            </div>
                            <div className="col-sm-8" onClick={() => smsTabHandler('Salary')} style={{ fontSize: '14px', cursor: 'pointer', color: filterType === 'Salary' ? '#2B78FF' : '#000', fontWeight: filterType === 'Salary' ? '700' : '400' }}>
                                <span style={{ color: filterType === 'Salary' ? '#1eb0d2' : 'rgba(0, 0, 0, 0.45)', fontWeight: filterType === 'Salary' ? '700' : '400' }}> Salary</span>
                                <br />
                                {smsData && smsData.salary ? <span style={{ fontSize: '12px', color: '#2B78FF' }}>INR {smsData ? Math.round(smsData.salary) : ''}</span> : ""}
                                {smsData && smsData.salaryMsg
                                    ? <div style={{ marginBottom: '3.5px', fontSize: '12px', color: 'rgba(0, 0, 0, 0.45)' }}><b>{smsData.salaryMsg
                                        .length}</b> &nbsp;Found </div> :
                                    ""}
                            </div>

                        </div>
                        <div className="row" style={{ marginTop: '20px' }}>
                            <div className="col-sm-4 text-right">
                                <img src={filterType === 'Transaction' ? TxnActive : TxninActive} width={'32px'} />
                            </div>
                            <div className="col-sm-8" onClick={() => smsTabHandler('Transaction')} style={{ marginTop: '5px', fontSize: '14px', cursor: 'pointer', color: filterType === 'Transaction' ? '#2B78FF' : '#000', fontWeight: filterType === 'Transaction' ? '700' : '400' }}>
                                <span style={{ color: filterType === 'Transaction' ? '#1eb0d2' : 'rgba(0, 0, 0, 0.45)', fontWeight: filterType === 'Transaction' ? '700' : '400' }}>   Transaction Messages</span>

                            </div>

                        </div>
                        <div className="row" style={{ marginTop: '20px' }}>
                            <div className="col-sm-4 text-right">
                                <img src={filterType === 'viewall' ? TxnActive : TxninActive} width={'32px'} />
                            </div>
                            <div className="col-sm-8" onClick={() => smsTabHandler('viewall')} style={{ marginTop: '5px', fontSize: '14px', cursor: 'pointer', color: filterType === 'viewall' ? '#2B78FF' : '#000', fontWeight: filterType === 'viewall' ? '700' : '400' }}>
                                <span style={{ color: filterType === 'viewall' ? '#1eb0d2' : 'rgba(0, 0, 0, 0.45)', fontWeight: filterType === 'viewall' ? '700' : '400' }}>   Detailed View</span>

                            </div>

                        </div>

                    </div>
                    <div className="col-sm-9 col-xs-12" style={{ borderLeft: '1px solid rgba(0, 0, 0, 0.25)', height: '600px', overflow: 'auto' }}>
                        <div className="row" style={{ marginTop: '20px' }}>
                            <div className="col-sm-6 col-xs-12">
                                <h4 style={{ color: 'rgba(0, 0, 0, 0.80)' }}><i class="fa fa-server" aria-hidden="true"></i>&nbsp;&nbsp;{filterType} &nbsp;SMS List</h4>
                            </div>
                            <div className="col-sm-6 col-xs-12 text-right">
                                {/* <button className="reset-btn" style={{ marginTop: '0', marginRight: '10px' }} onClick={() => regenerateSms()}>Regenerate</button> */}
                            </div>
                        </div>
                        <hr />
                        <div className="row">
                            <div className="col-sm-6 col-xs-6"></div>
                            <div className="col-sm-6 col-xs-6">
                                {filterType === 'Transaction' ?
                                    <div style={{ float: 'inline-end', marginTop: '-15px' }} >
                                        <span onClick={() => openCommentPopup(filterType)} style={{ color: '#1eb0d2', fontWeight: '700', cursor: 'pointer' }}><i className='fa fa-commenting-o'></i>&nbsp;&nbsp;Comment&nbsp;&nbsp;</span>

                                        <select className="" style={{ padding: '10px', border: '1px solid #D3D9E9', borderTopLeftRadius: '5px', borderBottomLeftRadius: '5px' }} onChange={(e) => underWriterHandler(e, 'aadhaar')} >
                                            <option value={''}>Select Option</option>
                                            <option value={'Legal SMS Found'}>Legal SMS Found</option>
                                            <option value={'Overdue SMS Found'}>Overdue SMS Found</option>
                                            <option value={'Overdue SMS Not Found'}>Overdue SMS Not Found</option>
                                            <option value={'Legal SMS Not Found'}>Legal SMS Not Found</option>

                                        </select>
                                        <button className="btn btn-primary" style={{ marginTop: '17px', background: '#2B78FF', borderTopLeftRadius: '0', borderBottomLeftRadius: '0' }} onClick={() => saveUnderWritter()}>Save</button>
                                    </div>
                                    : ""}
                            </div>

                        </div>
                        {smsData&&loaderState===false && smsData.overDueMsg && filterType === 'Overdue' ?
                            <div>
                                <div className='phocket-table-new'>
                                    <table>
                                        <thead>
                                            <tr className='thead-class'>
                                                <th>Bank Name</th>
                                                <th className="text-center">Message</th>
                                                <th>Type</th>
                                                <th>Date</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {smsData && smsData.overDueMsg ?
                                                Object.entries(smsData.overDueMsg).slice(tableMinPagination, tableMaxPagination).map((data, i) => {
                                                    const messageType = data[1].message;

                                                    const isEmiIncluded = jsondata['OverDue'] && jsondata['OverDue'].some(item => item.message === messageType);

                                                    return (
                                                        <tr style={{
                                                            background: isEmiIncluded ? 'lightgreen' : 'white', cursor: 'pointer'
                                                        }} key={i} onClick={() => smsJsonData('OverDue', data[1])}>
                                                            <td>{data[1].bankName}</td>
                                                            <td>{data[1].message}</td>
                                                            <td>{data[1].messageType}</td>
                                                            <td>{data[1].messageDate}</td>

                                                        </tr>
                                                    )
                                                })
                                                : ""}

                                        </tbody>
                                    </table>
                                </div>
                                <div className="container-fluid">
                                    {/* <div className="row">
                                    <div className="col-sm-5 col-xs-12 no-padding"><button type="button" className="btn btn-default fa fa-chevron-left btn-lg btn-block   " style={{ background: '#BADBF9', borderRadius: '0' }} onClick={e => phocketTablePagination(smsData.overDueMsg, 'back')}></button></div>
                                    <div className="col-sm-2 col-xs-12  phoc text-center" >
                                        {(tableMinPagination + 1) + ' - ' + (tableMaxPagination) + ' of ' + smsData.overDueMsg.length}
                                    </div>
                                    <div className="col-sm-5 col-xs-12 no-padding" ><button type="button" className="btn btn-default fa fa-chevron-right btn-lg btn-block " o style={{ background: '#BADBF9', borderRadius: '0' }} onClick={e => phocketTablePagination(smsData.overDueMsg, 'forward')}></button></div>
                                </div> */}
                                    <div className="" style={{ float: 'right', marginTop: '10px', marginBottom: '20px' }}>
                                        <Pagination
                                            count={Math.ceil(smsData.overDueMsg.length / 5)}
                                            page={pageNumber}
                                            onChange={handleChangeNewPagination}
                                            color="primary"
                                        // shape="rounded"
                                        />
                                    </div>
                                </div>
                            </div>
                            : ""}
                        {smsData&&loaderState===false && smsData.emiMsg && smsData.emiMsg.length > 0 && filterType === 'Emi' ?
                            <div>
                                <div className='phocket-table-new'>
                                    <table>
                                        <thead>
                                            <tr className='thead-class'>
                                                <th>Bank Name</th>
                                                <th className="text-center">Message</th>
                                                <th>Type</th>
                                                <th>Date</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {smsData && smsData.emiMsg ?
                                                Object.entries(smsData.emiMsg).slice(tableMinPagination, tableMaxPagination).map((data, i) => {
                                                    const messageType = data[1].message;

                                                    const isEmiIncluded = jsondata['Emi'] && jsondata['Emi'].some(item => item.message === messageType);

                                                    return (
                                                        <tr
                                                            style={{
                                                                background: isEmiIncluded ? 'lightgreen' : 'white', cursor: 'pointer'
                                                            }}
                                                            key={i} onClick={() => smsJsonData('Emi', data[1])}>
                                                            <td>{data[1].bankName}</td>
                                                            <td>{data[1].message}</td>
                                                            <td>{data[1].messageType}</td>
                                                            <td>{data[1].messageDate}</td>

                                                        </tr>
                                                    )
                                                })
                                                : ""}

                                        </tbody>
                                    </table>
                                </div>
                                <div className="container-fluid">

                                    <div className="" style={{ float: 'right', marginTop: '10px', marginBottom: '20px' }}>
                                        <Pagination
                                            count={Math.ceil(smsData.emiMsg.length / 5)}
                                            page={pageNumber}
                                            onChange={handleChangeNewPagination}
                                            color="primary"
                                        // shape="rounded"
                                        />
                                    </div>
                                </div>
                            </div>
                            : ''}
                        {smsData &&loaderState===false&& smsData.salaryMsg && smsData.salaryMsg.length > 0 && filterType === 'Salary' ?
                            <div>
                                <div className='phocket-table-new'>
                                    <table>
                                        <thead>
                                            <tr className='thead-class'>
                                                <th>Bank Name</th>
                                                <th className="text-center">Message</th>
                                                <th>Type</th>
                                                <th>Date</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {smsData && smsData.salaryMsg ?
                                                Object.entries(smsData.salaryMsg).slice(tableMinPagination, tableMaxPagination).map((data, i) => {
                                                    const messageType = data[1].message;

                                                    const isEmiIncluded = jsondata['Salary'] && jsondata['Salary'].some(item => item.message === messageType);
                                                    return (
                                                        <tr
                                                            style={{
                                                                background: isEmiIncluded ? 'lightgreen' : 'white', cursor: 'pointer'
                                                            }}
                                                            key={i} onClick={() => smsJsonData('Salary', data[1])}>
                                                            <td>{data[1].bankName}</td>
                                                            <td>{data[1].message}</td>
                                                            <td>{data[1].messageType}</td>
                                                            <td>{data[1].messageDate}</td>

                                                        </tr>
                                                    )
                                                })
                                                : ""}

                                        </tbody>
                                    </table>
                                </div>
                                <div className="container-fluid" style={{ float: 'right', marginTop: '10px', marginBottom: '20px' }}>

                                    <Pagination
                                        count={Math.ceil(smsData.salaryMsg.length / 5)}
                                        page={pageNumber}
                                        onChange={handleChangeNewPagination}
                                        color="primary"
                                    // shape="rounded"
                                    />

                                </div>
                            </div>
                            : ''}
                        {filterType === 'Transaction' ?
                            <ALLTxNSms allDetail={allDetail} msgData={msgData} />
                            : ""}
                        {filterType === 'viewall' ?
                            <RawMsg allDetail={allDetail} smsJsonData={smsJsonData} jsondata={jsondata} />
                            : ""}
                        {
                            loaderState ?
                                <div className='phocket-table-new' style={{ overflow: 'auto' }} >
                                    <table className='text-center' style={{ marginTop: '0px' }}>
                                        <thead>
                                            <tr>
                                                <th><Skeleton height={40} /></th>
                                                <th><Skeleton height={40} /></th>
                                                <th><Skeleton height={40} /></th>
                                                <th><Skeleton height={40} /></th>
                                                <th><Skeleton height={40} /></th>
                                                <th><Skeleton height={40} /></th>
                                                <th><Skeleton height={40} /></th>
                                                <th><Skeleton height={40} /></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td><Skeleton height={40} /></td>
                                                <td><Skeleton height={40} /></td>
                                                <td><Skeleton height={40} /></td>
                                                <td><Skeleton height={40} /></td>
                                                <td><Skeleton height={40} /></td>
                                                <td><Skeleton height={40} /></td>
                                                <td><Skeleton height={40} /></td>
                                                <td><Skeleton height={40} /></td>

                                            </tr>
                                            <tr>
                                                <td><Skeleton height={40} /></td>
                                                <td><Skeleton height={40} /></td>
                                                <td><Skeleton height={40} /></td>
                                                <td><Skeleton height={40} /></td>
                                                <td><Skeleton height={40} /></td>
                                                <td><Skeleton height={40} /></td>
                                                <td><Skeleton height={40} /></td>
                                                <td><Skeleton height={40} /></td>
                                            </tr>
                                            <tr>
                                                <td><Skeleton height={40} /></td>
                                                <td><Skeleton height={40} /></td>
                                                <td><Skeleton height={40} /></td>
                                                <td><Skeleton height={40} /></td>
                                                <td><Skeleton height={40} /></td>
                                                <td><Skeleton height={40} /></td>
                                                <td><Skeleton height={40} /></td>
                                                <td><Skeleton height={40} /></td>
                                            </tr>
                                            <tr>
                                                <td><Skeleton height={40} /></td>
                                                <td><Skeleton height={40} /></td>
                                                <td><Skeleton height={40} /></td>
                                                <td><Skeleton height={40} /></td>
                                                <td><Skeleton height={40} /></td>
                                                <td><Skeleton height={40} /></td>
                                                <td><Skeleton height={40} /></td>
                                                <td><Skeleton height={40} /></td>
                                            </tr>
                                            <tr>
                                                <td><Skeleton height={40} /></td>
                                                <td><Skeleton height={40} /></td>
                                                <td><Skeleton height={40} /></td>
                                                <td><Skeleton height={40} /></td>
                                                <td><Skeleton height={40} /></td>
                                                <td><Skeleton height={40} /></td>
                                                <td><Skeleton height={40} /></td>
                                                <td><Skeleton height={40} /></td>
                                            </tr>
                                            <tr>
                                                <td><Skeleton height={40} /></td>
                                                <td><Skeleton height={40} /></td>
                                                <td><Skeleton height={40} /></td>
                                                <td><Skeleton height={40} /></td>
                                                <td><Skeleton height={40} /></td>
                                                <td><Skeleton height={40} /></td>
                                                <td><Skeleton height={40} /></td>
                                                <td><Skeleton height={40} /></td>
                                            </tr>

                                        </tbody>
                                    </table>
                                </div>
                                : ""
                        }


                    </div>
                </div>
            </div>
        </>
    )
}
export default SmsAnalysis