import React, { useState } from "react";
import EmailCom from "./email";
import SmsCom from "./sms";
import WhatsappCom from "./whatsapp";

const ComTab = ({admin}) => {
    const [viewState, setViewState] = useState('email')

    return (
        <div className="container-fluid marketing-main-css">
            <h3>Configure Communication Templates</h3>
            <div className="row " style={{}}>
                <div className="col-sm-6 col-xs-6" onClick={() => setViewState('email')} style={{ borderBottom: viewState === 'email' ? '3px solid #1eb0d2' : '3px solid rgba(0,0,0,.1)', padding: viewState === 'email' ? '13px' : '12.2px', textAlign: 'center', color: viewState === 'email' ? '#2b78ff' : '#000', cursor: 'pointer' }}>
                    Email
                </div>
                <div className="col-sm-6 col-xs-6" onClick={() => setViewState('sms')} style={{ borderBottom: viewState === 'sms' ? '3px solid #1eb0d2' : '3px solid rgba(0,0,0,.1)', padding: viewState === 'sms' ? '13px' : '12.2px', textAlign: 'center', color: viewState === 'sms' ? '#2b78ff' : '#000', cursor: 'pointer' }}>
                    SMS
                </div>
                {/* <div className="col-sm-4 col-xs-4" onClick={() => setViewState('whatsapp')} style={{ borderBottom: viewState === 'whatsapp' ? '3px solid #1eb0d2' : '3px solid rgba(0,0,0,.1)', padding: viewState === 'whatsapp' ? '13px' : '12.2px', textAlign: 'center', color: viewState === 'whatsapp' ? '#2b78ff' : '#000', cursor: 'pointer' }}>
                    Whatsapp
                </div> */}
            </div>
            <div style={{marginTop:'30px'}}>
                {viewState === 'email' ?
                    <EmailCom admin={admin} />
                    : ""}
                {viewState === 'sms' ?
                    <SmsCom   admin={admin} />
                    : ""}
                {viewState === 'whatsapp' ?
                    <WhatsappCom   admin={admin} />
                    : ""}
            </div>
        </div>
    )
}
export default ComTab
