import React, { useState, useEffect } from "react";
import { salaryRangeWiseCountApi } from "../maketingComponents/actioncreator";
import { firstDateOfMonth, formatDate, lastDateOfMonth, monthStartDate, reverseFormat, subtractOneDay, todayDate } from "../maketingComponents/utils/utils";
import ReactApexChart from "react-apexcharts";
import Skeleton from "react-loading-skeleton";

const SalaryRange = ({ admin }) => {
    const [nameOpportunity, setNameopportunity] = useState([])
    const [dataOpportunity, setDataopportunity] = useState([])

    const [payload, setPayload] = useState({
        fromDate: monthStartDate(),
        toDate: todayDate(),
        minDate: monthStartDate(),
    });
    const [rejectReasonData, setRejectReasonData] = useState([]);
    const [graphData, setGraphData] = useState([]);
    const [optionsData, setOptionsData] = useState('');
    const [today, settoday] = useState(todayDate());

    useEffect(() => {
        getdataapi();
    }, []);

    const getdataapi = () => {
        salaryRangeWiseCountApi(
            payload,
            (callBack) => {

                setRejectReasonData(callBack);
                makegraphDataOpportunity(callBack)


            },
            (error) => {
                console.log(error);
            }
        );
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        const newPayload = {
            ...payload,
            [name]: formatDate(value),
        };
        // if (name === "fromDate") {
        //     newPayload.toDate = lastDateOfMonth(value);
        // } else {
        //     newPayload.fromDate = firstDateOfMonth(value);
        // }
        if (name === "fromDate") {
            newPayload.toDate = payload.toDate;
        } else {
            newPayload.fromDate = payload.fromDate;
        }
        setPayload(newPayload);
    };

    const prepareChartData = (data) => {

        const categories = Object.values(data.xaxis.categories);
        const seriesData = Object.values(data.xaxis.categories);
        const options = {
            series: [{
                data: seriesData
            }],
            chart: {
                type: 'bar',
                height: 350
            },
            plotOptions: {
                bar: {
                    borderRadius: 4,
                    horizontal: true,
                }
            },
            dataLabels: {
                enabled: false
            },
            xaxis: {
                categories: categories
            }
        };
        setOptionsData(options);
        console.log(options)
    };


    const makegraphDataOpportunity = (To) => {
        let name = [];
        let data = [];

        for (const item of To) {
            const { count, range, } = item;

            name.push(range);

            data.push(count);
        }

        setNameopportunity(name);
        setDataopportunity(data);

        console.log(Object.values(nameOpportunity))

    }
    var options = {
        series: [{
            data: Object.values(dataOpportunity),

        }],
        chart: {
            type: 'bar',
            height: 350
        },
        plotOptions: {
            bar: {

                horizontal: true,
                barHeight: '50%',
                barWidth: '90%',

            }

        },
        colors: ['#7978E9'],
        dataLabels: {
            enabled: false
        },
        xaxis: {
            categories: nameOpportunity,
        },


    };

    return (
        <div>
            <div className="col-sm-12 col-xs-12 shadow" style={{ marginBottom: '20px', border: '1px solid rgba(0, 0, 0, 0.25)', borderRadius: '10px',minHeight:'600px' }}>
                <div className="bg-white p-4 " style={{ padding: '15px' }}>
                    <h5 style={{ color: '#0e5768', fontWeight: '700' }}> <strong style={{ paddingTop: '10px' }}>Salary Range</strong></h5>
                    <div className="row">
                        <div className="col-sm-2 col-xs-12" style={{ marginTop: '4px' }}>
                            From
                            <input
                                type="date"
                                id="startDate"
                                placeholder="DD/MM/YY"
                                name="fromDate"
                                value={reverseFormat(payload.fromDate)}
                                onChange={handleChange}
                                className=" form-control"
                                style={{
                                    border: "1px solid gray",
                                    borderRadius: "6px",
                                    padding: "10px",
                                }}
                            />
                        </div>

                        <div className="col-sm-2 col-xs-12">
                            <div className="filterHeaders" style={{ marginTop: "4px" }}>
                                to
                            </div>

                            <input
                                type="date"
                                id="endDate"
                                name="toDate"
                                max={reverseFormat(subtractOneDay(today))}
                                value={reverseFormat(subtractOneDay(payload.toDate))}
                                onChange={handleChange}
                                placeholder="DD/MM/YY"
                                className=" form-control"
                                style={{
                                    border: "1px solid gray",
                                    borderRadius: "6px",
                                    padding: "10px",
                                }}
                            />
                        </div>
                        <div className="col-sm-2 col-xs-12">
                            <button
                                className="btn btn-primary mt-4 "
                                style={{
                                    color: "#2B78FF",
                                    borderColor: "#2B78FF",
                                    backgroundColor: "white",
                                    marginTop: "23px",
                                    padding: "10px",
                                    borderRadius: "5px",
                                }}
                                onClick={getdataapi}
                            >
                                Apply Filter
                            </button>
                        </div>
                    </div>


                    <hr />
                    <div className="row">
                        <div className="col-sm-6 col-xs-12 other-table">
                            <div className='marketing-table' style={{ fontSize: '12px',width:'90%',marginRight:'auto' }} >
                                <table>
                                    <thead style={{ background: '#b9d8f9', color: '#000', borderTopLeftRadius: '10px', borderTopRightRadius: '10px' }}>
                                        <tr className="thead-class">
                                            <th style={{ width: '20%', }} className="">
                                                <b>Salary Range</b>
                                            </th>
                                            <th style={{ width: '20%' }} className="">
                                                <b>Counts</b>
                                            </th>

                                        </tr>
                                    </thead>
                                    {rejectReasonData.length > 0 ?
                                        <tbody style={{ height: '600px', borderBottomLeftRadius: '10px', borderBottomRightRadius: '10px' }}>
                                            {rejectReasonData.map((data1, i) => {

                                                return (
                                                    <tr style={{ borderBottom: "none", fontWeight: '400' }} key={i}>
                                                        <td style={{ width: '20%' }} scope="col">
                                                            {data1.range}
                                                        </td>
                                                        <td style={{ width: '20%' }} scope="col">
                                                            {data1.count}
                                                        </td>

                                                    </tr>
                                                )

                                            })}
                                            <tr className="thead-class" style={{ fontWeight: 'bold', background: '#B9D8F973' }}>
                                                <td style={{ borderBottomLeftRadius: '10px', width: '40%'  }}><b>Total</b></td>
                                                <td style={{ width: '40%' }}>
                                                    <b>{rejectReasonData[0].total}</b>
                                                </td>
                                                <td style={{ borderBottomRightRadius: '10px' }}></td>
                                            </tr>

                                        </tbody>
                                        :
                                        <tbody style={{ height: '600px', borderBottomLeftRadius: '10px', borderBottomRightRadius: '10px' }}>
                                            <tr>
                                                <td><Skeleton height={40} /></td>
                                                <td><Skeleton height={40} /></td>
                                                <td><Skeleton height={40} /></td>

                                            </tr>

                                            <tr>
                                                <td><Skeleton height={40} /></td>
                                                <td><Skeleton height={40} /></td>
                                                <td><Skeleton height={40} /></td>

                                            </tr>
                                            <tr>
                                                <td><Skeleton height={40} /></td>
                                                <td><Skeleton height={40} /></td>
                                                <td><Skeleton height={40} /></td>
                                            </tr>
                                            <tr>
                                                <td><Skeleton height={40} /></td>
                                                <td><Skeleton height={40} /></td>
                                                <td><Skeleton height={40} /></td>
                                            </tr>
                                            <tr>
                                                <td><Skeleton height={40} /></td>
                                                <td><Skeleton height={40} /></td>
                                                <td><Skeleton height={40} /></td>
                                            </tr>
                                            <tr>
                                                <td><Skeleton height={40} /></td>
                                                <td><Skeleton height={40} /></td>
                                                <td><Skeleton height={40} /></td>
                                            </tr>
                                            <tr>
                                                <td><Skeleton height={40} /></td>
                                                <td><Skeleton height={40} /></td>
                                                <td><Skeleton height={40} /></td>
                                            </tr>
                                        </tbody>
                                    }
                                </table>
                            </div>
                        </div>
                        <div className="col-sm-6 col-xs-12">
                            <div style={{ border: '1px solid rgba(0, 0, 0, 0.15)', padding: '10px', borderRadius: '10px', marginTop: '21px' }}>
                                <h5><b>Salary Range</b></h5>
                                <ReactApexChart options={options} series={options.series} type="bar" height={450} width={'100%'} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SalaryRange;
