import React, { Component } from 'react'
import ReportByChoice from '../../../presentationals/Admin/DashboardAndReports/ReportByChoice.component'
import { APIS } from '../../../../utils/api-factory'
import { _preFormatDate } from '../../../../utils/validation'
import { STRING } from '../../../..//utils/Constant'
import { excelSheetByChoice, excelSheetDisbursed ,excelSheetCibil} from '../AdminActionCreator.component'
import Popup from '../../../presentationals/Popup/Popup.component';
import Loader from '../../../presentationals/Loader/Loader.component'

let reportFields = []

class ReportChoice extends Component {
    constructor(props) {
        super(props);
        this.state = {
            popupState: false,
            popupStatus: '',
            initialList: '',
            applyDateFromState: '',
            applyDateToState: '',
            excelFileName: '',
            loaderState: false,
            reprotByChoice: {
                applyDateFromState: '',
                applyDateToState: '',
                loanStatusState: [],
                reportFields: [],
                downloadSheetBy: '',
                loanStatusForCivilState: ''
            }
        }
    }

    componentWillMount() {
        fetch(APIS.LIST_FOR_EXCEL_REPORT)
            .then(res => res.json())
            .then(res => {
                this.setState({ initialList: res })
            })
    }

    render() {
        const { allStatus, admin } = this.props
        return (
            <div className="full-height">
                {this.state.loaderState == true ?
                    <Loader />
                    : ""}
                {this.state.popupState == true ?
                    <Popup
                        closePopup={this.closePopup.bind(this)}
                        popupStatus={this.state.popupStatus}
                    />
                    : ""}
                <ReportByChoice
                    initialList={this.state.initialList}
                    allStatus={allStatus}
                    reprotByChoice={this.state.reprotByChoice}
                    listByChoice={this.listByChoice.bind(this)}
                    listByChoiceHandler={this.listByChoiceHandler.bind(this)}
                    applyDateFromState={this.state.applyDateFromState}
                    applyDateToState={this.state.applyDateToState}
                    listByChoiceSelectAll={this.listByChoiceSelectAll.bind(this)}
                    excelFileName={this.excelFileName.bind(this)}
                    disburseReport={this.disburseReport.bind(this)}
                    cibilReport={this.cibilReport.bind(this)}
                />
            </div>
        )
    }

    removePopup() {
        setTimeout(function () {
            this.setState({ popupState: false });
        }.bind(this), 5000)
    }

    closePopup() {
        this.setState({ popupState: false });
    }

    disburseReport() {
        const { admin } = this.props
        if (this.state.applyDateFromState != '') {
            excelSheetDisbursed(this.state.reprotByChoice, this.state.excelFileName, admin,
                (callBack => {
                    this.setState({ loaderState: false })
                })
            )
        } else {
            this.setState({
                popupState: true,
                popupStatus: 'Please select Start Date',
            })
            this.removePopup();
        }
    }

    cibilReport() {
        if (this.state.reprotByChoice.downloadSheetBy == 3) {
            this.setState({ loaderState: true })
            excelSheetCibil(this.state.reprotByChoice,
                (callBack => {
                    this.setState({ loaderState: false })
                })
            )
        } else {
            this.setState({
                popupState: true,
                popupStatus: 'Please select Sheet by Cibil Report',
            })
            this.removePopup();
        }
    }

    excelFileName(e) {
        this.setState({ excelFileName: e.target.value })
    }

    listByChoiceSelectAll(e) {
        let reprotByChoice = Object.assign({}, this.state.reprotByChoice);
        reportFields = []
        if (e.target.checked == true) {
            for (let i = 0; i < this.state.initialList.length; i++) {
                if (reportFields.includes(this.state.initialList[i])) {

                } else {
                    reportFields.push(this.state.initialList[i])
                }
                document.getElementById("listByChoice" + i).checked = true;
            }
        } else {
            reportFields = []
            for (let i = 0; i < this.state.initialList.length; i++) {
                document.getElementById("listByChoice" + i).checked = false;
            }
        }
        reprotByChoice.reportFields = reportFields
        this.setState({ reprotByChoice })
    }

    listByChoice(e, listType) {
        let reprotByChoice = Object.assign({}, this.state.reprotByChoice);
        if (listType == 'applyDateFrom') {
            this.setState({ applyDateFromState: e })
            reprotByChoice.applyDateFromState = _preFormatDate(e)
        } else if (listType == 'applyDateTo') {
            this.setState({ applyDateToState: e })
            reprotByChoice.applyDateToState = _preFormatDate(e)
        } else if (listType == 'loanStatus') {
            var options = e.target.options;
            var value = [];
            for (var i = 0, l = options.length; i < l; i++) {
                if (options[i].selected) {
                    value.push(options[i].value);
                }
            }
            reprotByChoice.loanStatusState = value
        } else if (listType == 'reportFields') {
            if (e.target.checked == true) {
                if (reportFields.includes(e.target.value)) {

                } else {
                    reportFields.push(e.target.value)
                }
            } else {
                let index = reportFields.indexOf(e.target.value)
                if (index >= 0) {
                    reportFields.splice(index, 1);
                }
                document.getElementById("selectAllExcelFields").checked = false;
            }
            reprotByChoice.reportFields = reportFields
        } else if (listType == 'sheetBy') {
            reprotByChoice.downloadSheetBy = e.target.value
        } else if (listType == 'loanStatusForCivil') {
            reprotByChoice.loanStatusForCivilState = e.target.value
        }
        this.setState({ reprotByChoice })
    }

    listByChoiceHandler() {
        const { admin } = this.props
        if (this.state.reprotByChoice.reportFields.length > 0 && this.state.excelFileName != '') {
            if (this.state.reprotByChoice.downloadSheetBy != '') {
                this.setState({ loaderState: true })
                excelSheetByChoice(this.state.reprotByChoice, this.state.excelFileName, admin,
                    (callBack => {
                        this.setState({ loaderState: false })
                    })
                )
            } else {
                this.setState({
                    popupState: true,
                    popupStatus: 'Please select Sheet download by',
                })
                this.removePopup();
            }
        } else {
            this.setState({
                popupState: true,
                popupStatus: 'Please select Fields & enter file name',
            })
            this.removePopup();
        }
    }

}
export default ReportChoice;