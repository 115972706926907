import React from 'react'

const ConfigCard = ({ }) => (
    <div className='container'>
        <div className='config-box'>
        <div className='row'>
            <div className='col-sm-4 col-xs-12'>
                <label style={{marginTop:'20px'}}>Credit Card Maximum Limit (INR)</label>
                <input className='user-input' placeholder='Credit Card Maximum Limit' />
            </div>
            <div className='col-sm-4 col-xs-12'>
                <label style={{marginTop:'20px'}}>Minimum Limit (INR)</label>
                <input className='user-input' placeholder='Minimum Limit' />


            </div>
            <div className='col-sm-4 col-xs-12'>
                <label style={{marginTop:'20px'}}>Processing Fee in %</label>
                <input className='user-input' placeholder='Processing Fee' />


            </div>

        </div>
        <div className='row'>
            <div className='col-sm-4 col-xs-12'>
                <label style={{marginTop:'20px'}}>Subscription Fee in %</label>
                <input className='user-input' placeholder='Subscription Fee' />


            </div>
            <div className='col-sm-4 col-xs-12'>
                <label style={{marginTop:'20px'}}>Minimum Due in %</label>
                <input className='user-input' placeholder='Minimum Due' />


            </div>
            <div className='col-sm-4 col-xs-12'>
                <label style={{marginTop:'20px'}}>Penalty Fee (INR)</label>
                <input className='user-input' placeholder='Penalty Fee' />


            </div>

        </div>  <div className='row'>
            <div className='col-sm-4 col-xs-12'>
                <label style={{marginTop:'20px'}}>EMI Conversion charges (INR)</label>
                <input className='user-input' placeholder='EMI Conversion charges' />


            </div>
            <div className='col-sm-4 col-xs-12'>
                <label style={{marginTop:'20px'}}>Overdue Charges per Month in %</label>
                <input className='user-input' placeholder='Overdue Charges' />


            </div>
            <div className='col-sm-4 col-xs-12'>
                <label style={{marginTop:'20px'}}>GST (By default set to 18%)</label>
                <input className='user-input' placeholder='GST value' />


            </div>

        </div> 
         {/* <div className='row'>
            <div className='col-sm-4 col-xs-12'>
                <label style={{marginTop:'20px'}}>Name</label>

            </div>
            <div className='col-sm-4 col-xs-12'>
                <label>Name</label>

            </div>
            <div className='col-sm-4 col-xs-12'>
                <label>Name</label>

            </div>

        </div> */}
</div>
    </div>
)
export default ConfigCard