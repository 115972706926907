import React, { Component } from 'react';
import Registereduser from '../../../presentationals/Admin/NbfcManagement/Registered.component';
// import { getCompanyData } from './ThirdPartyActionCreater.component';

let paginationNumber = 10;

export default class ThirdPartyRegistration extends Component {
    constructor(props) {
        super(props)
        this.state = {
            companyList: '',
            tableMinPagination: 0,
            tableMaxPagination: paginationNumber,
        }
    }

    render() {
        return (

            <Registereduser
                // phocketTablePagination={this.phocketTablePagination.bind(this)}
                // tableMinPagination={this.state.tableMinPagination}
                // tableMaxPagination={this.state.tableMaxPagination}
             
            />

        )
    }

    exportExcelHandler(e){
        
    }

    // componentWillMount() {
    //     getCompanyData(callback => {
    //         this.setState({ companyList: callback })
    //     })
    // }
    phocketTablePagination(data, type) {
        if (type == 'back') {
            if (this.state.tableMinPagination >= paginationNumber) {
                this.setState({
                    tableMinPagination: this.state.tableMinPagination - paginationNumber,
                    tableMaxPagination: this.state.tableMaxPagination - paginationNumber,
                })
            }
        } else if (type == 'forward') {
            if (this.state.tableMaxPagination < data.length) {
                this.setState({
                    tableMaxPagination: this.state.tableMaxPagination + paginationNumber,
                    tableMinPagination: this.state.tableMinPagination + paginationNumber
                })
            }
        }
    }
}
