import moment from 'moment'
import React from 'react'
import DatePicker from 'react-datepicker'
const Cards = ({ data, onchangeHandler, state, submit }) => (
    <div>
        <h3 className="text-center blue-text">Cards Utility Check</h3>
        <div className='container-fluid'>
            <div className='row'>
                <div className='col-sm-4 col-xs-12'>
                    <label style={{ marginTop: '20px' }}>Credit Amount</label>
                    <input className='form-control' placeholder='Credit Amount' onChange={(e) => onchangeHandler(e, 'creditAmount')} />
                </div>
                <div className='col-sm-4 col-xs-12'>
                    <label style={{ marginTop: '20px' }}>Used Amount</label>
                    <input className='form-control' placeholder='Used Amount' onChange={(e) => onchangeHandler(e, 'usedAmount')} />
                </div>
                <div className='col-sm-4 col-xs-12'>
                    <label style={{ marginTop: '20px' }}>Credit Provided Date</label>
                    <DatePicker
                        placeholderText='Credit Provided Date'
                        value={data.creditProvidedDate}
                        selected={state.creditProvidedDate}
                        onChange={(e) => onchangeHandler(e, 'creditProvidedDate')}
                        className="form-control"
                        showYearDropdown
                        dateFormatCalendar="MMMM"
                        dateFormat="DD/MM/YYYY"
                        scrollableYearDropdown
                        yearDropdownItemNumber={50}
                    />
                </div>
            </div>
            <div className='row'>
                <div className='col-sm-4 col-xs-12'>
                    <label style={{ marginTop: '20px' }}>Credit Used </label>
                    <DatePicker
                        placeholderText='Credit Used'
                        value={data.creditUsedData}
                        selected={state.creditUsedData}
                        onChange={(e) => onchangeHandler(e, 'creditUsedData')}
                        className="form-control"
                        showYearDropdown
                        dateFormatCalendar="MMMM"
                        dateFormat="DD/MM/YYYY"
                        scrollableYearDropdown
                        yearDropdownItemNumber={50}
                    />                </div>
                <div className='col-sm-4 col-xs-12'>
                    <label style={{ marginTop: '20px' }}>Current Date</label>
                    <DatePicker
                        placeholderText='Current Date'

                        value={data.currentDate}
                        selected={state.currentDate}
                        onChange={(e) => onchangeHandler(e, 'currentDate')}
                        className="form-control"
                        showYearDropdown
                        dateFormatCalendar="MMMM"
                        dateFormat="DD/MM/YYYY"
                        scrollableYearDropdown
                        yearDropdownItemNumber={50}
                    />                </div>
                <div className='col-sm-4 col-xs-12'>
                    <label style={{ marginTop: '20px' }}>Convert To Emi</label>
                    <select className='form-control' onChange={(e) => onchangeHandler(e, 'convertToEmi')} >
                        <option value={false}>No</option>
                        <option value={true}>Yes</option>

                    </select>
                </div>
            </div>
            <div className='row'>
                <div className='col-sm-4 col-xs-12'>
                    <label style={{ marginTop: '20px' }}>Loan Emi</label>
                    <input className='form-control' placeholder='Loan Emi' onChange={(e) => onchangeHandler(e, 'loanEmi')} />
                </div>
                <div className='col-sm-4 col-xs-12'>
                    <label style={{ marginTop: '20px' }}>Emi Conversion Date</label>
                    <DatePicker
                        placeholderText='Emi Conversion Date'
                        value={data.emiConversionDate}
                        selected={state.emiConversionDate}
                        onChange={(e) => onchangeHandler(e, 'emiConversionDate')}
                        className="form-control"
                        showYearDropdown
                        dateFormatCalendar="MMMM"
                        dateFormat="DD/MM/YYYY"
                        scrollableYearDropdown
                        yearDropdownItemNumber={50}
                    />                </div>
                <div className='col-sm-4 col-xs-12'>
                    <label style={{ marginTop: '20px' }}>Is Minimum Due Paid</label>
                    <select className='form-control' onChange={(e) => onchangeHandler(e, 'isMinmunDuePaid')} >
                        <option value={false}>No</option>
                        <option value={true}>Yes</option>

                    </select>
                </div>
            </div>
            <div className='row'>
                <div className='col-sm-12 col-xs-12 text-center'>
                    <button className='profile-save-btn' onClick={() => submit()}>Submit</button>
                </div>
            </div>
            {state.showTable && state.tableData ?
                <div className='row income'>
                    <div className='col-sm-6 col-xs-12'>
                        <h3 className='text-center'>Calculation Details</h3>

                        <table className='income-table' style={{ width: '100%', margin: '15px' }}>
                            <thead>
                                <tr>
                                    <th>Calculate OverDue Amount</th>
                                    <td>{state.tableData.calculateOverDueAmount}</td>
                                </tr>
                                <tr>
                                    <th>Consumed Amount </th>
                                    <td>{state.tableData.consumedAmount}</td>
                                </tr>
                                <tr>
                                    <th>Credit Amount</th>
                                    <td>{state.tableData.creditAmount}</td>
                                </tr>
                                <tr>
                                    <th>Credit Limit Available </th>
                                    <td>{state.tableData.creditLimitAvailable}</td>
                                </tr>
                                <tr>
                                    <th>Emi Conversion Charges </th>
                                    <td>{state.tableData.emiConversionCharges}</td>
                                </tr>

                                <tr>
                                    <th>Minimal Due Amount  </th>
                                    <td>{state.tableData.minimalDueAmount}</td>
                                </tr>
                                <tr>
                                    <th>Penalty Amount </th>
                                    <td>{state.tableData.penaltyAmount}</td>
                                </tr>
                                <tr>
                                    <th>Processing Fee</th>
                                    <td>{state.tableData.processingFee}</td>
                                </tr>
                                <tr>
                                    <th>Subcscription Fee</th>
                                    <td>{state.tableData.subcscriptionFee}</td>
                                </tr>
                                <tr>
                                    <th>Total Payable Amount</th>
                                    <td>{state.tableData.totalPayableAmount}</td>
                                </tr>
                            </thead>
                        </table>

                    </div>
                    <div className='col-sm-6 col-xs-12 '>
                        {state.tableData.emiDetails ?
                            <div className="overflow-x-auto">
                                <h3 className='text-center'>Emi Details</h3>

                                <table className="phocket-table">
                                    <thead>
                                        <tr>
                                            <th>Date</th>
                                            <th>Emi Amount</th>
                                            <th>Emi Paid Amount</th>
                                            <th>Overdue Amount</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {state.tableData.emiDetails.map((data, i) => {
                                            return (
                                                <tr key={i}

                                                >
                                                    <td>{moment(data.date).format('DD-MM-YYYY')}</td>
                                                    <td>{data.emiAmount}</td>
                                                    <td>{data.emiPaidAmount}</td>
                                                    <td>{data.overdueAmount}</td>

                                                </tr>
                                            )
                                        }
                                        )}
                                    </tbody>
                                </table>
                            </div>

                            : ""}
                    </div>


                </div>
                : ""}
        </div>
    </div>
)

export default Cards