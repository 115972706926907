import React from 'react'
import { getIncomeCollection, generateCollectionReport } from '../AdminActionCreator.component'
import IncomeUi from '../../../presentationals/Admin/DashboardAndReports/IncomeCollected.component'
import { _preFormatDate } from '../../../../utils/validation'
import Loader from '../../../presentationals/Loader/graphloader.component'
import { APIS } from '../../../../utils/api-factory'
class Income extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loaderState: false,
      incomeData: '',
      FilteredData: [],
      pageIndexToShow: 0,
      startDate: _preFormatDate(new Date()),
      endDate: _preFormatDate(new Date())
    }
  }
  getFilterValue(filtered) {
    this.setState({ FilteredData: filtered })
  }

  getPageValue(pageIndex) {
    this.setState({ pageIndexToShow: pageIndex })
  }
  componentDidMount() {
    this.setState({ loaderState: true })

    getIncomeCollection(this.state.startDate, this.state.endDate, callback => {
      this.setState({ incomeData: callback, loaderState: false })
    })
  }

  handleChange(e, type) {
    if (type == 'start') {
      this.setState({
        startDate: _preFormatDate(e)
      })
    } else if (type == 'end') {
      this.setState({
        endDate: _preFormatDate(e)
      })
    }
  }
  GenerateHandler(e) {  
    window.open(APIS.GENERATE_COLLECTION_REPORT + this.state.startDate + '&endDate=' + this.state.endDate);
  }
  submitHandler(e) {
    this.setState({ loaderState: true })
    getIncomeCollection(this.state.startDate, this.state.endDate, callback => {
      this.setState({ incomeData: callback, loaderState: false })
    })
  }
  render() {
    return (
      <div>{this.state.loaderState ? <Loader /> : ""}
        <IncomeUi
          GenerateHandler={this.GenerateHandler.bind(this)}
          incomeData={this.state.incomeData}
          getFilterValue={this.getFilterValue.bind(this)}
          FilteredData={this.state.FilteredData}
          getPageValue={this.getPageValue.bind(this)}
          pageIndexToShow={this.state.pageIndexToShow}
          handleChange={this.handleChange.bind(this)}
          submitHandler={this.submitHandler.bind(this)}
          startDate={this.state.startDate}
          endDate={this.state.endDate}
        />
      </div>
    )
  }
}
export default Income
