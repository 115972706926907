import React from 'react'
import MultiSelectStatusReact from 'multi-select-react';

const selectedOptionsStyles = {
    color: "#3c763d",
    backgroundColor: "#dff0d8"
};
const optionsListStyles = {
    backgroundColor: "#dff0d8",
    color: "#3c763d"
};

const CRMBulkAssign = ({ stateRoleDataState, allStates, bulkAssignChange, bulkAssignHandler,
    userStates, bulkAssignDetail }) => (
        <div className="container-fluid full-height max-width-400px">
            <div className="row">
                <div className="col-xs-12">
                    <h3 className="text-center blue-text">State Wise Assign</h3>
                    <div className="row form-group">
                        <div className="col-xs-12">
                            <label className="font-12px margin-0">State</label>
                            <select className="form-control" value={bulkAssignDetail.state} onChange={e => bulkAssignChange(e, 'state')}>
                                <optgroup className="display-none">
                                    <option value="">Select State</option>
                                </optgroup>
                                <optgroup>
                                    {allStates != null && allStates.length > 0 ?
                                        allStates.map((state, i) => {
                                            return (
                                                <option key={i} value={state}>{state}</option>
                                            )
                                        })
                                        : null}
                                </optgroup>
                            </select>
                        </div>
                    </div>
                    {/* <div className="row form-group">
                        <div className="col-xs-12">
                            <label className="font-12px margin-0">Agent</label>
                            <select className="form-control" value={bulkAssignDetail.stateAgent} onChange={e => bulkAssignChange(e, 'stateAgent')}>
                                <optgroup className="display-none">
                                    <option value="">Select Agent</option>
                                </optgroup>
                                <optgroup label="Select Agent">
                                    {stateRoleDataState != null && stateRoleDataState.length > 0 ?
                                        stateRoleDataState.map((data, i) => {
                                            return (
                                                <option value={data.emailId} key={i}>{data.emailId}</option>
                                            )
                                        })
                                        : null}
                                </optgroup>
                            </select>
                        </div>
                    </div> */}
                    <div className="row form-group">
                        <div className="col-xs-12">
                            <label className="font-12px margin-0">Agent</label>
                            {stateRoleDataState.length > 0 ?
                                <MultiSelectStatusReact
                                    options={stateRoleDataState}
                                    optionClicked={e => bulkAssignChange(e, 'stateAgent')}
                                    selectedBadgeClicked={e => bulkAssignChange(e, 'stateAgent')}
                                    selectedOptionsStyles={selectedOptionsStyles}
                                    optionsListStyles={optionsListStyles}
                                    className="form-control" />
                                    
                                : null}
                            

                        </div>
                    </div>
                    <div className="row form-group">
                        <div className="col-xs-12 text-center">
                            <button className="profile-save-btn margin-0" onClick={e => bulkAssignHandler('state')}>Assign</button>
                        </div>
                    </div>

                    <div className="row form-group">
                        <div className="col-xs-12">
                            {userStates != null && userStates.length > 0 ?
                                <table className="text-center phocket-table">
                                    <thead>
                                        <tr>
                                            <th>State</th>
                                            <th>Agent</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {userStates.map((data, i) => {
                                            return (
                                                <tr key={i}>
                                                    <td>{data.state}</td>
                                                    <td>
                                                        {data.assignIds != null && data.assignIds.length > 0 ?
                                                            data.assignIds.map((id, j) => {
                                                                return (
                                                                    <div key={j}>{id}</div>
                                                                )
                                                            })
                                                            : null}
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                                : null}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

export default CRMBulkAssign;