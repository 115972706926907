import React from 'react'
import Ui from '../../../presentationals/Admin/Utility/UploadStam.component'
import { uploadStampDutyApiAll } from '../../../containers/Admin/AdminActionCreator.component'
import Loader from '../../../presentationals/Loader/Loader.component'
import PopUp from '../../../presentationals/Popup/Popup.component';
class StampDuty extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fileToUpload: '',
            nameOfFile: '',
            loaderState: false,
            popupState: '',
            popupStatus: ''
        }
    }
    fileHandler(e) {
        if (e.target.files[0] !== undefined && e.target.files[0] !== '') {
            if (e.target.files[0].type === 'application/pdf') {
                this.setState({ fileToUpload: e.target.files[0], nameOfFile: e.target.files[0].name })
            } else {
                this.setState({ fileToUpload: '', nameOfFile: '' })
                alert('only pdf file is allowed')

            }
        }
    }
    removePopup() {
        setTimeout(function () {
            this.setState({ popupState: false });
        }.bind(this), 5000)
    }
    uploadHandler() {
        const {admin}=this.props

        if (this.state.fileToUpload !== '' && this.state.fileToUpload !== undefined) {
            this.setState({ loaderState: true })
            uploadStampDutyApiAll(this.state.fileToUpload,admin.emailId, callback => {
                if (callback === 'success') {
                    this.setState({ loaderState: false, popupState: true, popupStatus: 'uploaded successfully!', nameOfFile: '', fileToUpload: '' }, () => this.removePopup())
                } else {
                    this.setState({ loaderState: false, popupState: true, popupStatus: 'please try again later!' }, () => this.removePopup())
                }
            })
        }
    }

    closePopup() {
        this.setState({ popupState: false })
    }
    render() {
        return (
            <div>
                {this.state.loaderState ?
                    <Loader />
                    : ""}
                <Ui fileHandler={this.fileHandler.bind(this)}
                    uploadHandler={this.uploadHandler.bind(this)}
                    nameOfFile={this.state.nameOfFile}
                />
                {this.state.popupState ?
                    <PopUp popupStatus={this.state.popupStatus}
                        closePopup={this.closePopup.bind(this)}
                    />
                    : ""}
            </div>
        )
    }
}

export default StampDuty