import React from 'react'
import Nodata from '../../../../images/nodata.png'


const userBankDetails = ({ bankData, phocketTablePagination, tableMinPagination,
    tableMaxPagination, totalCreditAmount, totalDebitAmount, allChannels, selectChannel,
    lastBalance }) => (
        <div className="container-fluid">
            <h3 className="heading-analysis" >Bank Details</h3>
            <div className="row">
                <div className="col-xs-12 col-sm-4"></div>
                <div className="col-xs-12 col-sm-4">
                    <label className="font-12px">Select Transaction Channel</label>
                    <select className="form-control text-capitalize" onChange={e => selectChannel(e)}>
                        <option value="">Select All</option>
                        {allChannels.length > 0 ?
                            allChannels.map((channel, i) => {
                                return (
                                    <option key={i} value={channel}>
                                        {channel.replace(/_/g, ' ')}
                                    </option>
                                )
                            }) : null}
                    </select>
                </div>
            </div>
            <div className="row form-group">
                <div className="col-sm-3 col-xs-12">
                    <div className="bank-card">
                        <div className="heading">Total Credit</div>
                            <div className="card-values">
                                    <span>
                                        <span className="font20px">{totalCreditAmount}</span>
                                    </span>
                               
                            </div>

                    </div>
                </div>
                <div className="col-sm-3 col-xs-12">
                    <div className="bank-card debit">
                        <div className="heading">Total Debit</div>
                            <div className="card-values">
                                    <span>
                                        <span className="font20px text-center ">{totalDebitAmount}</span>
                                    </span>
                               
                            </div>

                    </div>
                </div>
                <div className="col-sm-3 col-xs-12">
                    <div className="bank-card difference">
                        <div className="heading">Difference </div>
                            <div className="card-values">
                                    <span>
                                        <span className="font20px">{totalCreditAmount - totalDebitAmount}</span>
                                    </span>
                               
                            </div>

                    </div>
                </div>
                <div className="col-sm-3 col-xs-12">
                    <div className="bank-card">
                        <div className="heading">Balance</div>
                            <div className="card-values">
                                    <span>
                                        <span className="font20px">{lastBalance}</span>
                                    </span>
                               
                            </div>

                    </div>
                </div>
                </div>
            {/* <div className="row bank-detail-header">
                <div className="col-xs-12 col-sm-3 total-credit">
                    <h5 className="text-center">Total Credit: <br/><b>{totalCreditAmount}</b></h5>
                </div>
                <div className="col-xs-12 col-sm-3 debit">
                    <h5 className="text-center">Total Debit:<br/> <b>{totalDebitAmount}</b></h5>
                </div>
                <div className="col-xs-12 col-sm-3">
                    <h5 className="text-center">CR DR Diff:<br/> <b>{totalCreditAmount - totalDebitAmount}</b></h5>
                </div>
                <div className="col-xs-12 col-sm-3 balance">
                    {lastBalance != '' ? <h5 className="text-center">Balance:<br/> <b>{lastBalance}</b></h5> : null}
                </div>
            </div> */}
            <div className="row text-center">
                <div className="col-xs-12 no-padding">
                    <div className="overflow-x-auto">
                    <table className="text-center blueTable">
                            <thead>
                                <tr>
                                    <th>Date</th>
                                    <th>Transaction Note</th>
                                    <th>Transaction Channel</th>
                                    <th>Credit Amount</th>
                                    <th>Debit Amount</th>
                                    <th>Balance</th>
                                </tr>
                            </thead>
                            <tbody>
                                {Array.isArray(bankData) ? bankData.length > 0 ?
                                    bankData.slice(tableMinPagination, tableMaxPagination).map((bd, i) => {
                                        return (
                                            <tr key={i}>
                                                <td>{bd.date.split(' ')[0]}</td>
                                                <td>{bd.transaction_note}</td>
                                                <td>{bd.transaction_channel}</td>
                                                <td>{bd.transaction_type == 'credit' ? bd.amount : ''}</td>
                                                <td>{bd.transaction_type == 'debit' ? bd.amount : ''}</td>
                                                <td>{bd.balance}</td>
                                            </tr>
                                        )
                                    })
                                    : null : <tr>
                                        <td colSpan="6">
                                            <h3 className="text-center blue-text"><img src={Nodata}width="300px"/></h3>
                                        </td>
                                    </tr>
                                }
                            </tbody>
                        </table>
                        <div className="margin-bottom-6perc">
              <div className=" col-xs-12  tbl-count" ><button type="button" className="pagination-btn blue-text " onClick={e => phocketTablePagination(bankData, 'back')}>Previous</button>{(tableMinPagination + 1) + ' - ' + (tableMaxPagination) + ' of ' + bankData.length}<button type="button" className="pagination-btn tbl-count blue-text " onClick={e => phocketTablePagination(bankData, 'forward')}>Next</button></div>
            </div>
      </div>
                </div>

            </div>

        </div>
    )
export default userBankDetails
