import React from 'react'
import { TYPE } from '../../../utils/Constant'
import DatePicker from 'react-datepicker';

const PaidDataPopup = ({ PaidData, paidDataHandler, paidDataChange, closePaidPopup,
    disabled }) => (
        <div className="container-fluid">
            <div className="row from-group">
                <div className="col-xs-12">
                    {/* <button className="close" onClick={e => closePaidPopup()}>&times;</button> */}
                    {/* <h4 className="text-center">Kindly confirm the details</h4> */}
                </div>
            </div>
            <div className="row form-group">
                <div className="col-sm-6 col-xs-12">
                    <label className="font-12px">Select Date</label>
                    <DatePicker
                        value={PaidData.paidDate}
                        selected={PaidData.paidDateToShow}
                        onChange={(e) => paidDataChange(e, TYPE.LOAN_APPROVE_DATE)}
                        className="form-control"
                        placeholderText="select Date"
                        dateFormat="DD/MM/YYYY"
                    />
                </div>
                <div className="col-sm-6 col-xs-12">
                    <label className="font-12px">Enter Interest</label>
                    <input type="number" className="form-control" value={PaidData.interestAmount} onChange={(e) => paidDataChange(e, TYPE.INTEREST_AMOUNT)} />
                </div>
            </div>
            <div className="row form-group">
                <div className="col-sm-6 col-xs-12">
                    <label className="font-12px">Enter Loan Amount</label>
                    <input type="number" className="form-control" value={PaidData.loanAmount} onChange={(e) => paidDataChange(e, TYPE.LOAN_AMOUNT)} />
                </div>
                <div className="col-sm-6 col-xs-12">
                    <label className="font-12px">Enter Processing Fees</label>
                    <input type="number" className="form-control" value={PaidData.processingFees} onChange={(e) => paidDataChange(e, TYPE.PROCESSING_FEES_AMOUNT)} />
                </div>
            </div>
            <div className="row form-group">
                <div className="col-sm-6 col-xs-12">
                    <label className="font-12px">Enter Penalty</label>
                    <input type="number" className="form-control" value={PaidData.penaltyAmount} onChange={(e) => paidDataChange(e, TYPE.PENALTY_AMOUNT)} />
                </div>
                <div className="col-sm-6 col-xs-12">
                    <label className="font-12px">Nach bounce Amount</label>
                    <input type="number" className="form-control" value={PaidData.nachBounceAmount} onChange={(e) => paidDataChange(e, TYPE.NACH_BOUNCE_AMOUNT)} />
                </div>
            </div>
            <div className="row form-group">
                <div className="col-sm-6 col-xs-12">
                    <label className="font-12px">Cheque Bounce Amount</label>
                    <input type="number" className="form-control" value={PaidData.chequeBounceAmount} onChange={(e) => paidDataChange(e, TYPE.CHEQUE_BOUNCE_AMOUNT)} />
                </div>
                <div className="col-sm-6 col-xs-12">
                    <label className="font-12px">Paid Amount</label>
                    <input type="number" className="form-control" value={PaidData.paidAmount} onChange={(e) => paidDataChange(e, TYPE.PAID_AMOUNT)} />
                </div>
            </div>
            <div className="row " style={{marginTop:'20px'}}>
                <div className="col-xs-12">
                    <button className="btn btn-primary" disabled={disabled} onClick={e => paidDataHandler()}>SAVE</button>
                </div>
            </div>
        </div>
    )

export default PaidDataPopup;