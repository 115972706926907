import React from 'react'
import moment from 'moment'
const Activity = ({activityData }) => (
    <div className='container'>
        {activityData?
        <div className='row' style={{marginTop:'80px'}}>
            
            <div className='col-sm-12 colxs-12'>
                <table className="table">
                    <thead className="thead-dark">
                        <tr>
                            {/* <th>Id</th> */}
                            <th>Date & Time</th>
                            <th>Comment</th>
                            <th>Employee Id</th>

                        </tr>
                    </thead>
                    <tbody>


                        {activityData && activityData.map((data, i) => {
                                return (
                        <tr>
                            {/* <td>{data.id}</td> */}
                            <td>{moment(data.date).format('DD-MM-YYYY')}&nbsp;&nbsp;{moment(data.date).format('hh:mm:ss')} </td>
                            <td>{data.comment}</td>
                            <td>{data.empId}</td>
                            {/* <td>{new Date(data.date)}</td> */}
                        </tr>
                         )
                            })}


                    </tbody>
                </table>
            </div>
        </div>
        :""}

    </div>
)

export default Activity