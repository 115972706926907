import React, { Component } from 'react';
import BulletToEmiUi from '../../presentationals/Popup/BulletToEmi.popup'
import { bulletToEmiConverApi, saveConvertedEmiApi, getUserDetailApi } from './DashboardActionCreator.component'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

class BulletToEmi extends Component {
    constructor(props) {
        super(props);
        this.state = {
            monthForConversion: '',
            emiTableData: []
        }
    }

    render() {
        return (
            <BulletToEmiUi
                emiMonthsChange={this.emiMonthsChange.bind(this)}
                emiHandler={this.emiHandler.bind(this)}
                closeEmiPopup={this.closeEmiPopup.bind(this)}
                emiTableData={this.state.emiTableData}
            />
        )
    }

    emiMonthsChange(e, type) {
        if (type == 'month') {
            this.setState({ monthForConversion: e.target.value })
        }
    }

    emiHandler(type) {
        const { loanDataForEmiChange, userDetail } = this.props
        if (type == 'check') {
            bulletToEmiConverApi(loanDataForEmiChange, userDetail.employmentDetailsPO.salaryDay, this.state.monthForConversion,
                (callBack) => {
                    this.setState({ emiTableData: callBack })
                })
        } else if (type == 'save') {
            saveConvertedEmiApi(loanDataForEmiChange, userDetail.employmentDetailsPO.salaryDay, this.state.monthForConversion,
                (callBack) => {
                    if(callBack == 'success'){
                        this.props.getLoanState(false)
                        this.props.getUserDetailApi()
                    }
                })
        }
    }

    closeEmiPopup() {
        this.props.getLoanState(false)
    }

}

const mapStateToProps = ({  }) => {
    return {
        
    };
};

const mapDispatchToProps = dispatch => {
    return bindActionCreators({ getUserDetailApi }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(BulletToEmi);