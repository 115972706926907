import React from 'react'
import DatePicker from 'react-datepicker';

const ApproveDataPopup = ({ chequeNotice, chequeNoticeHandler, chequeNoticeDateToShow,
    closeNoticePopup, chequeNoticeDate }) => (
        <div className="">
            <div className="background-blur" onClick={e => closeNoticePopup()}></div>
            <div className="popup-main small-popup container-fluid dbl-border">
                <div className="row from-group">
                    <div className="col-xs-12">
                        <h4 className="text-center">Enter Cheque and Notice Date<br />&nbsp;</h4>
                    </div>
                </div>
                <div className="row form-group">
                    <div className="col-xs-4">Enter Type</div>
                    <div className="col-xs-8">
                        <select className="form-control" onChange={e => chequeNotice(e, 'chequeNotice')}>
                            <option value="">Select One</option>
                            <option value="NachBounce">NACH Bounce</option>
                            <option value="ChequeBounce">Cheque Bounce</option>
                            <option value="NoticeDelivered">Notice Delivered</option>
                        </select>
                    </div>
                </div>
                <div className="row form-group">
                    <div className="col-xs-4">Select Date</div>
                    <div className="col-xs-8">
                        <DatePicker
                            value={chequeNoticeDate}
                            selected={chequeNoticeDateToShow}
                            onChange={e => chequeNotice(e, 'chequeNoticeDate')}
                            className="form-control"
                            placeholderText="select Date"
                            dateFormat="DD/MM/YYYY"
                        />
                    </div>
                </div>
                <div className="row from-group text-center">
                    <div className="col-xs-12">
                        <button className="profile-save-btn" onClick={e => chequeNoticeHandler()}>SAVE</button>
                    </div>
                </div>
            </div>
        </div>
    )

export default ApproveDataPopup;