import React from 'react'
import { STRING } from '../../../../utils/Constant'
import DatePicker from 'react-datepicker';

const CustomSearch = ({ allStatus, customExportHandler, customExport, exportApplyDate,
    exportApproveDate, exportDisburseDate, exportPaidDate, exportData }) => (
        <div className="container-fluid">
            <div className="row">
                <h3 className="text-center blue-text">Custom Search</h3>
            </div>
            <div className="row form-group">
                <div className="col-sm-4 col-xs-12">
                    <label className="font-12px">Gender</label>
                    <select className="form-control" onChange={(e) => customExport(e, 'gender')}>
                        <option value="">Select Gender</option>
                        <option value={STRING.MALE}>{STRING.MALE}</option>
                        <option value={STRING.FEMALE}>{STRING.FEMALE}</option>
                    </select>
                </div>
                <div className="col-sm-4 col-xs-12">
                    <label className="font-12px">City</label>
                    <input type="text" className="form-control" onChange={(e) => customExport(e, 'city')} />
                </div>
                <div className="col-sm-4 col-xs-12">
                    <label className="font-12px">Pincode</label>
                    <input type="number" className="form-control" onChange={(e) => customExport(e, 'pincode')} />
                </div>
            </div>

            <div className="row form-group">
                <div className="col-sm-4 col-xs-12">
                    <label className="font-12px">Residence Type</label>
                    <select className="form-control" onChange={(e) => customExport(e, 'resType')}>
                        <option value="">Residence Type</option>
                        <option value={STRING.OWN}>{STRING.OWN}</option>
                        <option value={STRING.RENTED}>{STRING.RENTED}</option>
                        <option value={STRING.HOSTEL}>{STRING.HOSTEL}</option>
                    </select>
                </div>
                <div className="col-sm-4 col-xs-12">
                    <label className="font-12px">Organisation Name</label>
                    <input type="text" className="form-control" onChange={(e) => customExport(e, 'orgName')} />
                </div>
                <div className="col-sm-4 col-xs-12">
                    <label className="font-12px">Designation</label>
                    <input type="text" className="form-control" onChange={(e) => customExport(e, 'designation')} />
                </div>
            </div>

            <div className="row form-group">
                <div className="col-sm-4 col-xs-12">
                    <label className="font-12px">Salary</label>
                    <input type="number" className="form-control" onChange={(e) => customExport(e, 'salary')} />
                </div>
                <div className="col-sm-4 col-xs-12">
                    <label className="font-12px">Loan Amount</label>
                    <input type="number" className="form-control" onChange={(e) => customExport(e, 'amount')} />
                </div>
                <div className="col-sm-4 col-xs-12">
                    <label className="font-12px">Loan Apply Date</label>
                    <DatePicker
                        value={exportData.applyDateState}
                        selected={exportApplyDate}
                        onChange={(e) => customExport(e, 'applyDate')}
                        className="form-control"
                        dateFormat="DD/MM/YYYY"
                    />
                </div>
            </div>

            <div className="row form-group">
                <div className="col-sm-4 col-xs-12">
                    <label className="font-12px">Loan Tenure</label>
                    <input type="number" className="form-control" onChange={(e) => customExport(e, 'tenure')} />
                </div>
                <div className="col-sm-4 col-xs-12">
                    <label className="font-12px">Loan Status</label>
                    <select className="form-control" onChange={(e) => customExport(e, 'loanStatus')} defaultValue={STRING.SELECT_ALL}>
                        <optgroup label="" hidden>
                            <option value="">Select Status</option>
                        </optgroup>
                        <optgroup label="Status">
                            {allStatus != null && allStatus != '' ? allStatus.length > 0 ? allStatus.map((status, i) => {
                                return (
                                    <option key={i} value={status.statusId}>{status.adminStatus}</option>
                                )
                            }) : "" : ""}
                        </optgroup>
                    </select>
                </div>
                <div className="col-sm-4 col-xs-12">
                    <label className="font-12px">Loan Approve Date</label>
                    <DatePicker
                        value={exportData.approveDateState}
                        selected={exportApproveDate}
                        onChange={(e) => customExport(e, 'approveDate')}
                        className="form-control"
                        dateFormat="DD/MM/YYYY"
                    />
                </div>
            </div>

            <div className="row form-group">
                <div className="col-sm-4 col-xs-12">
                    <label className="font-12px">Loan Disburse Date</label>
                    <DatePicker
                        value={exportData.disburseDateState}
                        selected={exportDisburseDate}
                        onChange={(e) => customExport(e, 'disburseDate')}
                        className="form-control"
                        dateFormat="DD/MM/YYYY"
                    />
                </div>
                <div className="col-sm-4 col-xs-12">
                    <label className="font-12px">Loan Paid Date</label>
                    <DatePicker
                        value={exportData.paidDateState}
                        selected={exportPaidDate}
                        onChange={(e) => customExport(e, 'paidDate')}
                        className="form-control"
                        dateFormat="DD/MM/YYYY"
                    />
                </div>
                <div className="col-sm-4 col-xs-12">
                    <label className="font-12px">Device Type</label>
                    <select className="form-control" onChange={(e) => customExport(e, 'device')}>
                        <option value="">Device Type</option>
                        <option value="website">Website</option>
                        <option value="android">Android</option>
                    </select>
                </div>
            </div>

            <div className="row form-group">
                <div className="col-sm-4 col-xs-12">
                    <label className="font-12px">Address Type</label>
                    <select className="form-control" onChange={(e) => customExport(e, 'addressType')}>
                        <option value="">Address Type</option>
                        <option value="current">Current</option>
                        <option value="permanent">Permanent</option>
                    </select>
                </div>
            </div>

            <div className="row text-center">
                <div className="col-xs-12">
                    <button className="profile-save-btn" id="exportExcel" onClick={e => customExportHandler()}>
                        <span className="fa fa-download" />&nbsp;Export
                </button>
                </div>
            </div>
        </div>
    )

export default CustomSearch;