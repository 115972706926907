import React, { useEffect, useState } from "react";
import { saveOrUpdateCreditConfigApi } from "../AdminActionCreator.component";
import SidePopup from "../../Marketing/maketingComponents/screens/modals/sidepopup";

const CreditConfig = ({ setViewPageMain, editData }) => {
    const [configData, setconfigData] = useState({})
    const [popupType, setpopupType] = useState('')
    const [msg, setmsg] = useState('')
    const [popupState, setpopupState] = useState('')


    const savedata = () => {
        saveOrUpdateCreditConfigApi(configData, callBack => {
            console.log(callBack)
            if (callBack === 'success') {
                setpopupState(true)
                setpopupType('success')
                setmsg('Saved Successfully!')
                removePopup()
                setViewPageMain('list')
            } else {
                setpopupState(true)
                setpopupType('error')
                setmsg('Please try again later!')
                removePopup()
            }
        })
    }
    const removePopup = () => {
        setTimeout(() => {
            setpopupState(false)
        }, 5000);
    }
    const changeHandler = (e, type) => {
        let changeData = Object.assign({}, configData)
        if (type === 'name') {
            changeData.name = e.target.value
        }
        if (type === 'categoryId') {
            changeData.categoryId = e.target.value
            if (e.target.value === '9H7K3D5G2T1X8A0P') {
                changeData.categoryName = 'KYC + Organization'
            }
            if (e.target.value === '4F6J9W2S8D1X7Z0Q') {
                changeData.categoryName = 'Financial + Bureau'
            }

        }
        if (type === 'attributeConfigured') {
            changeData.attributeConfigured = e.target.value
        }
        console.log(changeData)
        setconfigData(changeData)


    }
    useEffect(() => {
        console.log(editData)
        if (editData) {
            let changeData = Object.assign({}, editData)
            changeData.id = editData.id
            changeData.name = editData.name
            changeData.categoryId = editData.categoryId
            changeData.addedOn = editData.addedOn
            changeData.updatedOn = editData.updatedOn
            changeData.attributeConfigured = editData.attributeConfigured
            changeData.attributeId = editData.attributeId

            setconfigData(changeData)
        }
    }, [])
    return (
        <div className="container-fluid" style={{ marginTop: '20px' }}>
            <i className='fa fa-long-arrow-left' style={{ color: '#417bf6', fontSize: '20px', marginTop: '20px', cursor: 'pointer' }} onClick={() => setViewPageMain('list')} ></i>

            {popupState ?
                <SidePopup popupType={popupType} msg={msg} />
                : ""}
            <h3><strong>Add Configuration</strong></h3>
            <div className="row">
                <div className="col-sm-6 col-xs-12">
                    <label>Name</label>
                    <input className="form-control" value={configData.name} onChange={(e) => changeHandler(e, 'name')} />
                </div>
                <div className="col-sm-6 col-xs-12">
                    <label>Category</label>
                    <select value={configData.categoryId} className="form-control" onChange={(e) => changeHandler(e, 'categoryId')}>
                        <option value={''}>Select Category</option>
                        <option value={'9H7K3D5G2T1X8A0P'}>KYC + Organization</option>
                        <option value={'4F6J9W2S8D1X7Z0Q'}>Financial + Bureau</option>
                        <option value={'7K9P3R8W2X5Y1Q6Z'}>Non – Financial Criteria</option>
                        <option value={'2S9T5U8V7W4X1Y6'}>NTC Policy</option>
                        <option value={'qXLuTfkomEIdPZz0'}>Auto Reject</option>
                        <option value={'hLZoTRSnE1LMfr7y'}>On Hold</option>
                        <option value={'8B8kT3CMvcBIeZ6d'}>STP</option>


                    </select>
                </div>
            </div>
            <div className="row">
                <div className="col-sm-6 col-xs-12" style={{marginTop:'20px'}}> 
                    <label>Attribute Configured</label>
                    <select value={configData.attributeConfigured} className="form-control" onChange={(e) => changeHandler(e, 'attributeConfigured')}>
                        <option value={''}>Select Value</option>
                        <option value={true}>Yes</option>
                        <option value={false}>No</option>


                    </select>
                </div>
                <div className="col-sm-6 col-xs-12" style={{marginTop:'20px'}}> 
                    <label>Attribute Id</label>
                    <input className="form-control" value={configData.attributeId} readOnly />
                </div>
            </div>
            <div className="text-center" style={{ marginTop: '20px' }}>
                <button className="profile-save-btn" onClick={() => savedata()}>Save</button>
            </div>
        </div >
    )
}
export default CreditConfig