import React from 'react'

const ConfirmationPopup = ({ cancelLoanConfirm, confirmationText, invalidMsg ,completeHandler}) => (
    <div className="background-blur">
        <div className="popup-main small-popup container-fluid max-width-500px dbl-border">
            <div className="row from-group">
                <div className="col-xs-12">
                    <h4 className="text-center">{confirmationText}</h4>
                </div>
            </div>
            <div className="row from-group">
                <div className="col-xs-12 col-sm-2">
                </div>
                <div className="col-xs-12 col-sm-8">
                    <label style={{ color: '#000' }}>Complete Type</label>
                    <select className='form-control' onChange={(e)=>completeHandler(e)}>
                        <option >Select Complete Type</option>
                        <option value='Document on Mail'>Document on Mail</option>
                        <option value='Document on Whatsapp'>Document on Whatsapp</option>
                        <option value='Document on Admin'>Document on Admin</option>

                    </select>
                    <div style={{ color: 'red', textAlign: 'center', fontSize: '14px' }}>{invalidMsg}</div>

                </div>
                <div className="col-xs-12 col-sm-2">
                </div>
            </div>
            {/* <div className="row from-group text-center">
                <div className="col-xs-6">
                    <button className="profile-save-btn display-inline" onClick={(e) => cancelLoanConfirm('yes')}>YES</button>
                </div>
                <div className="col-xs-6">
                    <button className="profile-save-btn display-inline" onClick={(e) => cancelLoanConfirm('no')}>NO</button>
                </div>
            </div> */}
              <div className="row from-group text-center" style={{marginTop:'20px'}}>
                    <button className="btn btn-success" onClick={(e) => cancelLoanConfirm('yes')}>YES</button>&nbsp;&nbsp;
                    <button className="btn btn-danger" onClick={(e) => cancelLoanConfirm('no')}>NO</button>

            </div>
        </div>
    </div>
)

export default ConfirmationPopup;