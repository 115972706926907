import React, { Component } from 'react';
import LoanApprovalLeads from './LoanApprovalLeads.container'
import LoanAddaLeads from './LoanAddaLeads.container'
import ImportantLeads from './ImportantLeads.container'
import CompanyLeads from './CompanyLeads.container'
import LatestDocumentLeads from './LatestDocLead.container'
import NumberLeads from './NumberLeads.container'
import CreditScore from './AllCreditScore.container'
import CreditVidyaLeads from './CreditVidyaMain.container'
import Retra from './Retra.container'
import PersonlLead from './PersonalLeads.container'
import { ROLE } from '../../../../utils/Constant'

class LatestDoc extends Component {
    constructor(props) {
        super(props);
        this.state = {
            leadsViewState: "phocketMini"
        }
    }

    componentWillMount() {


    }

    render() {
        const { allStatus, admin, newDataBackground } = this.props;
        return (
            <div className="full-height">
                <div className="container-fluid">
                    <div className="row form-group margin-top-20px">
                        {/* <div className="col-sm-9 col-xs-12">
                            <ul className="state-color-list">
                                <li><span className="rajasthan-color state-color-box" />Rajasthan</li>
                                <li><span className="west-bengal-color state-color-box" />West Bengal</li>
                                <li><span className="Maharashtra-color state-color-box" />Maharashtra</li>
                                <li><span className="Karnataka-color state-color-box" />Karnataka</li>
                            </ul>
                        </div> */}
                        {admin != undefined && admin.rolelist != undefined &&
                            admin.rolelist.indexOf(ROLE.LEAD_SELECTION) >= 0 ?
                            <div className="col-sm-3 col-xs-12 pull-right">
                                <label className="margin-0 font-12px">Select table type</label>
                                <select className="form-control" onChange={e => this.leadChangeHandler(e)} value={this.state.leadsViewState}>
                                    <option value="Advance">Loan Approval Leads</option>
                                    {/* <option value="Credit Vidya">Credit Vidya Leads</option> */}
                                    {/* <option value="Retra">Retra Leads</option> */}
                                    <option value="Personal">Personal (12 months)</option>
                                    {/* <option value="Loan Adda Leads">Loan Adda Leads</option> */}
                                    <option value="Important Leads">Important Leads</option>
                                    <option value="Company Leads">Company Leads</option>
                                    <option value="Latest Document Leads">Latest Document Leads</option>
                                    {/* <option value="Number Leads">Number Leads</option> */}
                                    <option value="Credit Score">Credit Score</option>
                                </select>
                            </div>
                            : null}
                    </div>
                    <div className="row">
                        {this.state.leadsViewState == "phocketMini" ?
                            <LoanApprovalLeads
                                allStatus={allStatus}
                                admin={admin}
                                newDataBackground={newDataBackground}
                                leadsViewState={this.state.leadsViewState}
                            />
                          
                            : this.state.leadsViewState == "Personal" ?
                                <PersonlLead
                                    allStatus={allStatus}
                                    admin={admin}
                                    newDataBackground={newDataBackground}
                                    leadsViewState={this.state.leadsViewState}
                                />
                                : this.state.leadsViewState == "Credit Vidya" ?
                                    <CreditVidyaLeads
                                        allStatus={allStatus}
                                        admin={admin}
                                    />
                                    : this.state.leadsViewState == "Retra" ?
                                        <Retra
                                            allStatus={allStatus}
                                            admin={admin} />
                                        : this.state.leadsViewState == "Loan Adda Leads" ?
                                            <LoanAddaLeads
                                                allStatus={allStatus}
                                                admin={admin}
                                                newDataBackground={newDataBackground}
                                            />
                                            : this.state.leadsViewState == "Important Leads" ?
                                                <ImportantLeads
                                                    admin={admin}
                                                />
                                                : this.state.leadsViewState == "Company Leads" ?
                                                    <CompanyLeads
                                                        allStatus={allStatus}
                                                        admin={admin}
                                                        newDataBackground={newDataBackground}
                                                    />
                                                    : this.state.leadsViewState == "Latest Document Leads" ?
                                                        <LatestDocumentLeads
                                                            admin={admin}
                                                        />
                                                        : this.state.leadsViewState == "Number Leads" ?
                                                            <NumberLeads
                                                                admin={admin}
                                                            />
                                                            : this.state.leadsViewState == "Credit Score" ?
                                                                <CreditScore
                                                                    admin={admin}
                                                                />
                                                                : null}
                    </div>
                </div>
            </div>
        )
    }

    leadChangeHandler(e) {
        this.setState({
            leadsViewState: e.target.value
        })
    }
}

export default LatestDoc;