import React from 'react'
import DatePicker from 'react-datepicker';
import ReactTable from "react-table";

const PromotionalDetails = ({ promotionalCode, promoHandler, promoStartState,
    promoEndState, promoList, promoDetail,
    getFilterValue, FilteredData, getPageValue, pageIndexToShow }) => (
    <div className="container-fluid Phocket-promotional-details max-width-750px">
        <div className="row">
            <h3 className="text-center blue-text">Promotional Details</h3>
        </div>
        <div className="row form-group">
            <div className="col-sm-6 col-xs-12">
                <label className="font-12px">Promotional Code</label>
                <input type="text" className="form-control" placeholder="Enter Promotional Code" onChange={(e) => promotionalCode(e, 'promoCode')} />
            </div>
            <div className="col-sm-6 col-xs-12">
                <label className="font-12px">Discount Amount</label>
                <input type="number" className="form-control" placeholder="Enter Discount Amount" onChange={(e) => promotionalCode(e, 'promoAmount')} />
            </div>

        </div>

        <div className="row form-group">
            <div className="col-sm-6 col-xs-12">
                <label className="font-12px">Start Date</label>
                <DatePicker
                    value={promoDetail.promoStartState}
                    // selected={promoStartState}
                    onChange={(e) => promotionalCode(e, 'promoStart')}
                    className="date-picker form-control"
                    dateFormat="DD-MM-YYYY"
                    placeholderText="Select Start Date"
                />
            </div>
            <div className="col-sm-6 col-xs-12">
                <label className="font-12px">End Date</label>
                <DatePicker
                    value={promoDetail.promoEndState}
                    // selected={promoEndState}
                    onChange={(e) => promotionalCode(e, 'promoEnd')}
                    className="date-picker form-control"
                    dateFormat="DD-MM-YYYY"
                    placeholderText="Select End Date"
                />
            </div>
        </div>

        <div className="row text-center form-group">
            <button className="profile-save-btn" id="transSubmitBtn" onClick={e => promoHandler()}>
                <span className="fa fa-floppy-o"></span>&nbsp;&nbsp;Save
            </button>
        </div>
        <div className="row form-group">&nbsp;<br /></div>
        <div className="row form-group overflow-auto">
            <h3 className="text-center blue-text">Promotional Code Details</h3>
            
            {promoList != null && promoList != '' && promoList.length > 0 ?
                <div className='overflow-auto'>
                    <table className='phocket-table'>
                        <thead>

                            <tr>
                                <th>Promotional Code</th>
                                <th>Discount Amount</th>
                                <th>Start Date</th>
                                <th>End Date</th>
                            </tr>

                        </thead>
                        <tbody>
                            {promoList && promoList.map((promo, i) => {
                                return (
                                    <tr key={i}>
                                        <td>{promo.promoCode}</td>
                                        <td>{promo.promoAmount}</td>
                                        <td>{promo.promoStartDate}</td>
                                        <td>{promo.promoendDate}</td>


                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
                : ""}
        </div>
    </div>
)

export default PromotionalDetails;