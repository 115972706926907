import React from 'react';
import CSVReader from "react-csv-reader";
import PincodeImg from '../../../../images/pincode.png'
import ReactTable from "react-table";
import { filterCaseInsensitive } from '../../../../utils/commonUtility'

const Pincode = ({ saveCsvData, handleForce, csvViewData,
    getFilterValue, FilteredData, getPageValue, pageIndexToShow }) => (
    <div className="container-fluid">
        <div className="max-width-500px">
            <div className="row">
                <h3 className="text-center blue-text">Upload CSV</h3>
            </div>
            <div className="row form-group">
                <div className="col-xs-12 display-flex">
                    <CSVReader
                        cssClass="react-csv-input"
                        onFileLoaded={handleForce}
                    />
                    <button className="profile-save-btn margin-0" onClick={(e) => saveCsvData()}><span className="fa fa-save" /></button>
                </div>
            </div>
        </div>
        <div className="row">
            <div className="col-sm-7">
                {csvViewData ?
                    <table className='text-center blueTable '>

                        <thead>
                            <tr>
                                <td>Pincode</td>
                                <td>State</td>
                                <td>District</td>
                                <td>Pincode For</td>

                            </tr>
                        </thead>

                        <tbody>
                            {csvViewData && csvViewData.map((data, i) => {
                                return (
                                    <tr>
                                        <td>{data.pincode}</td>
                                        <td>{data.state}</td>
                                        <td>{data.district}</td>
                                        <td>{data.pincodeFor}</td>

                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                    : ""}
             
            </div>
            <div className="col-sm-5">
                <h3 className="text-center">Your CSV file must be in this format.</h3>
                <img className="center-block" src={PincodeImg} />
            </div>
        </div>
    </div >
)

export default Pincode;