import React from 'react';

const PhocketLoader = ({ }) => (
    <div className='justify' >
	<div className="dot-loader">
		<span></span>
		<span></span>
		<span></span>
	</div>
    </div>
)

export default PhocketLoader;
