import React from 'react'
import moment from 'react-moment'
import 'moment-timezone'
import { type } from 'jquery'
import App from './UserLocationNew.component'
import Moment from 'react-moment'


const userFinancial = ({ MobileContacts, AppList }) => (
    <div className="container-fluid crm-user-cibil animated fadeIn "  style={{marginTop:'100px'}}>
        <div className="row form-group">
            <h4 style={{marginLeft:'30px'}}><strong>Contact Information</strong></h4>
        </div>
        <div className="row form-group ">
            <div className="col-sm-6 col-xs-12 overflow-auto" >
                {MobileContacts != '' && MobileContacts != null && MobileContacts != undefined ?
                    <table className="text-center blueTable">
                        <thead>
                            <tr>
                                <th style={{ textAlign: 'center' }}>Name</th>
                                <th style={{ textAlign: 'center' }}>Mobile No</th>
                            </tr>
                        </thead>
                        <tbody>
                            {Object.keys(MobileContacts).map((mainKeys, i) => {
                                return (
                                    <tr key={i}>
                                        <td>{mainKeys}</td>
                                        <td>{MobileContacts[mainKeys]}</td>
                                    </tr>
                                )
                            }
                            )}
                        </tbody>
                    </table>
                    : ""}
            </div>
            <div className="col-sm-6 col-xs-12 bank-analysis-table" style={{borderRadius:'12px'}}>
                {AppList != '' && AppList != null && AppList != undefined ?
                    <table className="text-center blueTablecontact" >
                        <thead>
                            <tr>
                                <th style={{ textAlign: 'center' }}>Applications</th>
                                <th style={{ textAlign: 'center' }}>Installed Date</th>
                            </tr>
                        </thead>
                        <tbody>
                            {/* {AppList.map((mainKeys, i) => {
                                // console.log(mainKeys)
                                if (i == 0) {
                                    return (
                                        <tr key={i}>
                                            <td>{mainKeys.split('[')[1]}</td>
                                        </tr>
                                    )
                                } else if (i == AppList.length - 1) {
                                    return (
                                        <tr key={i}>
                                            <td>{mainKeys.split(']')[0]}</td>
                                        </tr>
                                    )
                                } else {
                                    return (
                                        <tr key={i}>
                                            <td>{mainKeys}</td>
                                        </tr>
                                    )
                                }
                            }
                            )} */}

                            {AppList != 'no data' && AppList != '' && AppList != undefined && AppList != null ? (AppList).map((data, i) => {
                                var newdata = (data.replace(/[,."{}]/g, ''))
                                var strigni = JSON.stringify(newdata)
                                var par = (JSON.parse(strigni))
                                var splits = (par.split(':'))
                                var dates = splits[0] === 'firstInstalledTime' ? splits[1] : " "
                                var NumberDate = (new Date(Number(dates)))
                                var day = NumberDate.getDate()
                                var MONTH = NumberDate.getMonth() + 1
                                var year = NumberDate.getFullYear()


                                return (
                                    <tr key={i}>
                                        <td style={{ padding: splits[0] === 'appName' ? '10px' : '', color: 'black', fontSize: '13px' }}>{splits[0] === 'appName' ? splits[1] : ""}</td>

                                        <td style={{ padding: splits[0] === 'firstInstalledTime' ? '10px' : '', color: 'black', fontSize: '13px', display: splits[0] === 'firstInstalledTime' ? 'block' : 'none',marginTop:'-33px' }}>{splits[0] === 'firstInstalledTime' ? day + '/' + MONTH + '/' + year : ""}</td>

                                    </tr>
                                )
                            })
                                : ''}
                            {/* {AppList.map((mainKeys, i) => {
                                if (i == 0) {
                                    return (
                                        <tr key={i}>
                                            <td>{mainKeys.split('[')[1]}</td>
                                        </tr>
                                    )
                                } else if(i == AppList.length - 1){
                                    return (
                                        <tr key={i}>
                                            <td>{mainKeys.split(']')[0]}</td>
                                        </tr>
                                    )
                                } else {
                                    return (
                                        <tr key={i}>
                                            <td>{mainKeys}</td>
                                        </tr>
                                    )
                                }
                            }
                            )} */}
                        </tbody>
                    </table>
                    : ""}
            </div>
        </div>
    </div>
)

export default userFinancial;