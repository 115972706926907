import React, { useState, useEffect } from 'react'
import Pagination from '@mui/material/Pagination';
import NodataImage from '../../../../../images/nodata.png';
import PopUp from "../../../../presentationals/Popup/Popup.component";
import Loader from '../../../../presentationals/Loader/Loader.component';
import ConfirmationPopup from '../../../../presentationals/Popup/ConfirmationPopup.component'
import { getAllProcessingFee, DeleteProcessingFee, getProcessingFeeByProductId } from '../../AdminActionCreator.component';
import AddEditProcessingFee from './AddEditProcessingFee';
export default function ProcessingFee() {
    const itemsPerPage = 10;
    const [loader, setloader] = useState(false)
    const [currentPage, setCurrentPage] = useState(1)
    const [count, setcount] = useState(1)
    const [searchValue, setSearchValue] = useState('')
    const [searchType, setsearchType] = useState('productId')
    const [filteredData, setFilteredData] = useState([])
    const [editMode, setEditMode] = useState('tableView')
    const [editData, setEditData] = useState({})
    const [popupstate, setPopupState] = useState(false)
    const [popupStatus, setpopupStatus] = useState('')
    const [confirmPopup, setconfirmPopup] = useState(false)
    const [deleteData, setDeleteData] = useState({})
    const [totalCount, settotalCount] = useState(0)


    useEffect(() => {
        GetAllProcessingFee(1)
    }, [])

    const GetAllProcessingFee = (currentPageValue) => {
        setCurrentPage(currentPageValue)
        setloader(true)
        getAllProcessingFee(currentPageValue, itemsPerPage, (callback) => {
            setloader(false)
            if (callback && callback.data?.length > 0) {
                setFilteredData(callback.data)
                settotalCount(callback.count)
            }
        })
    }

    const hanldeSearchchange = (e) => {
        if (e.target.value?.length > 0) {
            setSearchValue(e.target.value)
        } else {
            setSearchValue('')
            GetAllProcessingFee(1)
        }
    }

    const handlePageChange = (event, value) => {
        setCurrentPage(value);
        setloader(true)
        getAllProcessingFee(value, itemsPerPage, (callback) => {
            setloader(false)
            if (callback && callback.data?.length > 0) {
                setFilteredData(callback.data)
                settotalCount(callback.count)
            }
        })
    };

    const handlebtnclick = (type, data) => {
        if (type == 'search') {
            if (searchValue?.length > 0) {
                setloader(true)
                getProcessingFeeByProductId(searchValue, (callback) => {
                    setloader(false)
                    console.log(callback, callback.length)
                    if (callback && callback?.data && callback?.data?.length > 0) {
                        setFilteredData(callback?.data)
                        setcount(callback?.count)
                    } else {
                        setFilteredData([])
                    }
                })
            }
        } else if (type == 'add' || type == 'edit') {
            setEditMode('Editable')
            setEditData(data)
        } else if (type == 'delete') {
            setconfirmPopup(true)
            setDeleteData(data)
        }
    }

    const handleSavebtn = () => {
        setEditMode('tableView')
        GetAllProcessingFee(1)
    }

    const handlesearchType = (e) => {
        setsearchType(e.target.value)
    }

    const tableDatacheck = (data) => {
        if (data == null || data == undefined || data == '') {
            return '-'
        } else {
            return data
        }
    }

    const closePopup = () => {
        setPopupState(false)
    }

    const cancelLoanConfirm = (type) => {
        if (type == 'yes') {
            setloader(true)
            DeleteProcessingFee(deleteData.id, (callback) => {
                setloader(false)
                if (callback == 'success') {
                    setPopupState(true)
                    setpopupStatus('Deleted successfully')
                    GetAllProcessingFee(1)
                    setconfirmPopup(false)
                    setDeleteData({})
                } else {
                    popupStatus('Error')
                    popupstate(true)
                }
            })
        } else {
            setconfirmPopup(false)
            setDeleteData({})
        }
    }

    const handleRefreshbtn = () => {
        GetAllProcessingFee(1)
        setSearchValue('')
    }

    return (
        <>
            <div className="Executor">



                {
                    editMode == 'tableView' ?
                        <>
                            {
                                loader ?
                                    <Loader />
                                    : ''}
                            {
                                popupstate ?
                                    <PopUp closePopup={closePopup} popupStatus={popupStatus} />
                                    : ''}
                            {
                                confirmPopup ?
                                    <ConfirmationPopup confirmationText={'Are You sure You Want To Delete This Processing Fee Data'} cancelLoanConfirm={cancelLoanConfirm} />
                                    : ''}

                            <div className="FilterFields" style={{ display: 'flex', justifyContent: 'space-between' }}>

                                <div className='inputs'>
                                    <div className="Searchgrp">
                                        <select className='searchselect' value={searchType} onChange={(e) => handlesearchType(e)}>
                                            <option value="productId">Product Id</option>
                                        </select>
                                        <input type="text" className='searchInput' value={searchValue} onChange={(e) => hanldeSearchchange(e)} placeholder="Product Id" />
                                    </div>
                                    <button className='searchbtn' onClick={() => handlebtnclick('search', '')}><i class="fa fa-search fa-lg" aria-hidden="true"></i></button>
                                    <button className='searchbtn' onClick={() => handlebtnclick('add', '')}><i class="fa fa-plus fa-lg" aria-hidden="true"></i></button>
                                </div>
                                <div className="RefreshBtn">
                                    <button className='Refreshbtns' onClick={() => handleRefreshbtn()}>Refresh</button>
                                </div>
                            </div>

                            {
                                filteredData && Array.isArray(filteredData) && filteredData?.length > 0 ?
                                    <div className='tableedit'>
                                        <div className="phocket-table-new" style={{ overflowX: 'auto', marginTop: '0px', marginBottom: '30px' }}>
                                            <table style={{ marginBottom: '10px' }}>
                                                <thead>
                                                    <tr>
                                                        <th>NBFC Name</th>
                                                        <th>Processing Fee</th>
                                                        <th>Processing Type</th>
                                                        <th>Min Loan Amount</th>
                                                        <th>Max Loan Amount</th>
                                                        <th>Effecting From</th>
                                                        <th>old Effected</th>
                                                        <th>GST Included</th>
                                                        <th>No Of EMI</th>
                                                        <th>Added On</th>
                                                        <th>Product Id</th>
                                                        <th>Edit</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {filteredData?.map((data, index) => (
                                                        <tr key={index}>
                                                            <td>{tableDatacheck(data.nbfcName)}</td>
                                                            <td>{tableDatacheck(data.processingFee)}</td>
                                                            <td>{tableDatacheck(data.processingType)}</td>
                                                            <td>{tableDatacheck(data.minLoanAmount)}</td>
                                                            <td>{tableDatacheck(data.maxLoanAmount)}</td>
                                                            <td>{tableDatacheck((data.effectingFrom).split(' ')[0])}</td>
                                                            <td>{data.oldEffected == true ? 'Yes' : 'No'}</td>
                                                            <td>{data.gstIncluded == true ? 'Yes' : 'No'}</td>
                                                            <td>{tableDatacheck(data.noOfEmi)}</td>
                                                            <td>{tableDatacheck(data.addedOn)}</td>
                                                            <td>{tableDatacheck(data.productId)}</td>
                                                            <td>
                                                                <div className='edits'>
                                                                    <i class="fa fa-pencil-square-o fa-lg" aria-hidden="true" onClick={() => handlebtnclick('edit', data)}></i>
                                                                    <i class="fa fa-trash-o fa-lg" aria-hidden="true" onClick={() => handlebtnclick('delete', data)}></i>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                        <div style={{ float: 'right', marginTop: '20px' }}>
                                            <Pagination
                                                count={Math.ceil(totalCount / itemsPerPage)}
                                                page={currentPage}
                                                onChange={handlePageChange}
                                                color="primary"
                                            />
                                        </div>
                                    </div>
                                    :
                                    <p style={{ marginTop: '100px' }} className="text-center">
                                        <img src={NodataImage} style={{ width: '30%' }} />
                                    </p>
                            }
                        </>
                        : ''}

                {
                    editMode == 'Editable' ?
                        <AddEditProcessingFee editData={editData} handleSavebtn={handleSavebtn} />
                        : ''}
            </div>
        </>
    )
}

