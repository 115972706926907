import React, { useEffect, useState } from "react";
import List from './listing'
import Add from './addPolicy'

const PolicyTabs = ({ setViewPageMain,saveEditData,objectData,deletePolicy }) => {
    const [viewState, setViewPage] = useState('list')

    return (
        <div className="container-fluid">

            <List deletePolicy={deletePolicy} objectData={objectData} saveEditData={saveEditData} setViewPageMain={setViewPageMain} setViewPage={setViewPage}  />
        </div>

    )
}
export default PolicyTabs