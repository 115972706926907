import React from 'react'
import DatePicker from 'react-datepicker';
const ApproveDataPopup = ({dateHandler, amountForDeduction,startDate, deductionAmountChange, disabled,source,dateTechProcess,dateForDeduction,
    deductionAmountHandler }) => (
        <div className="">
            <div className="background-blur"></div>
            <div className="popup-main small-popup container-fluid dbl-border">
                <div className="row from-group">
                    <div className="col-xs-12">
                        <button className="close" onClick={e => deductionAmountHandler('no')}>&times;</button>
                        <h4 className="text-center" style={{ height: "18px" }}>Amount For Deduction<br />&nbsp;</h4>
                    </div>
                </div>
                <div className="row form-group">
                    {source != null && source !=''?
                    <h3 className="text-center">Selected Payment Mode Is &nbsp;{source}</h3>:null}
                    <div className="col-xs-12">
                        <div className="col-sm-6">
                        <label className="font-12px">Enter Amount</label>
                        <input type="number" className="form-control" value={amountForDeduction} onChange={(e) => deductionAmountChange(e)} />
                        </div>
                        {source == "techProcess"?
                        <div className="col-sm-6">
                        <label className="font-12px">Select date</label>
                      
                        <DatePicker
                            value={dateTechProcess}
                            selected={startDate}
                            onChange={(e) => dateHandler(e)}  
                            className="form-control"
                            placeholderText="select Date"
                            // dateFormat="DD-MM-YYYY"
                        />
                            {/* <DatePicker
                            className="form-control"
                            selected={startDate}
                            // dateFormat="DD/MM/YYYY"
                            onChange={(e) => deductionAmountChange(e,'date')}                        
                        /> */}
                        {/* <input type="number" className="form-control" value={amountForDeduction} onChange={(e) => deductionAmountChange(e)} /> */}
                        </div>
                        :null}
                    </div>
                </div>
                <div className="row text-center">
                    <div className="col-xs-12">
                        <button className="profile-save-btn mt-0px" disabled={disabled} onClick={e => deductionAmountHandler('yes')}>SAVE</button>
                    </div>
                </div>
            </div>
        </div>
    )

export default ApproveDataPopup;