import React from 'react'

const AutomationLogs = ({ mailTypeData, mailType, mailValue, automationData, postData }) => (
    <div className="container-fluid max-width-500px">
        <div className="row">
            <h3 className="text-center blue-text">Automation Logs</h3>
        </div>
        <div className="row center-block form-group">
            <div className="col-xs-12">
                <label className="font-12px">Select Mail Type</label>
                <select className="form-control" onChange={e => mailType(e)}>
                    <optgroup className="display-none">
                        <option value="">Mail Type</option>
                    </optgroup>
                    <optgroup label="Type">
                        {mailTypeData != '' ? (mailTypeData).map((autoData, i) => {
                            // let newdata= autoData.split(',')
                            return (
                                <option key={i} value={autoData[0]}>{autoData[1]}</option>
                            )
                        }) : ""}
                    </optgroup>
                </select>
            </div>
        </div>
        {mailValue != '' ?
            <table className="text-center loan-detail-table" style={{ marginBottom: '30px' }}>
                <thead className="background-blue text-white">
                    <tr>
                        <th colSpan="2">{mailValue}</th>
                    </tr>
                    <tr>
                        <th>DATE</th>
                        <th>COUNT</th>
                    </tr>
                </thead>
                <tbody>
                    {Object.keys(automationData).reverse().map((Data, i) => {
                        return (
                            <tr key={i} className="pointer"   onClick={e=> postData(Data, automationData[Data], mailValue)}>

                                <td>{Data}</td>
                                <td>{automationData[Data].length}</td>

                            </tr>
                        )
                    })}

                    {/* {Object.keys(automationData[mailValue]).reverse().map((Data,i) => {
                        console.log(Data)
                        return (
                            <tr key={i} className="pointer" onClick={e=> postData(Data, automationData[mailValue][Data], mailValue)}>
                                <td>{Data}</td>
                                <td>{automationData[mailValue][Data].length}</td>
                            </tr>
                        )
                    })} */}
                </tbody>
            </table>
            : ""}
    </div>
)

export default AutomationLogs;