// import React from 'react';

// const PhocketLoader = ({ }) => (
// 	<div className="loader loader4">
// 	<div>
// 	  <div>
// 		<div>
// 		  <div>
// 			<div>
// 			  <div>
// 				<div>
// 				  <div>
// 					<div>
// 					  <div></div>
// 					</div>
// 				  </div>
// 				</div>
// 			  </div>
// 			</div>
// 		  </div>
// 		</div>
// 	  </div>
// 	</div>
//   </div>


// )

// export default PhocketLoader;
import React from "react";

const SpinnerLoader = () => {
    return (
        <div className="spinner-loader text-center" style={{marginTop:'5%'}}>
        <span class="loader"></span>
        </div>

    )
}
export default SpinnerLoader