import React, { Component } from 'react'
import IBRUI from '../../../presentationals/Admin/IVR/IBR.component'
import { APIS } from '../../../../utils/api-factory'
import {
  getUserDetailApiAdmin,
  getIvrFilter
} from '../AdminActionCreator.component'
import UserDetail from '../CustomerPages/UserDetailMain.container'
import { _formatDateInYMD, _preFormatDate } from '../../../../utils/validation'
import moment from 'moment'
import Popup from '../../../presentationals/Popup/Popup.component'
let status = ''
let paginationNumber = 10
class IBR extends Component {
  constructor(props) {
    super(props)
    this.state = {
      filter: '',
      isChecked: true,
      loaderState: false,
      IBDData: '',
      tableKey: '',
      FilteredData: [],
      pageIndexToShow: 0,
      menuExtensionResponse: '',
      actionButtonState: false,
      userState: true,
      viewState: false,
      allDetail: null,
      userDetail: null,
      searchByDateShow: '',
      popupState: false,
      popupStatus: '',
      searchByDate: _preFormatDate(new Date()),
      tableMinPagination: 0,
      tableMaxPagination: paginationNumber,
    }
  }
  phocketTablePagination(data, type) {
    if (type == 'back') {
      if (this.state.tableMinPagination >= paginationNumber) {
        this.setState({
          tableMinPagination: this.state.tableMinPagination - paginationNumber,
          tableMaxPagination: this.state.tableMaxPagination - paginationNumber,
        })
      }
    } else if (type == 'forward') {
      if (this.state.tableMaxPagination < data.length) {
        this.setState({
          tableMaxPagination: this.state.tableMaxPagination + paginationNumber,
          tableMinPagination: this.state.tableMinPagination + paginationNumber
        })
      }
    }
  }
  componentWillMount() {
    this.setState({ loaderState: true })
    fetch(APIS.GET_IVR_FILTER_API + this.state.searchByDate + '&status=' + '')
      .then(res => res.json())
      .then(json => {
        this.setState({ IBDData: json, loaderState: false })
      })
    fetch(APIS.GET_IVR_RESPONSE)
      .then(res => res.json())
      .then(res => {
        this.setState({ menuExtensionResponse: res })
      })
  }

  statusFilterHandler(e) {
    this.setState({ filter: e.target.value })
    this.setState({ loaderState: true })
    getIvrFilter(this.state.searchByDate, e.target.value, callBack => {
      this.setState({ IBDData: callBack, loaderState: false })
    })
  }
  callbackStatusChange(e, data) {
    this.setState({ loaderState: true })
    if (data.callbackStatus == true) {
      this.setState(
        {
          loaderState: false,
          popupState: true,
          popupStatus: 'Not Possible'
        },
        () => this.removePopup()
      )
    } else {
      let URL =
        APIS.GET_CALLBACK_STATUS_CHANGE +
        encodeURIComponent(data.callerNumber) +
        '&status=' +
        true

      fetch(URL)
        .then(res => res.text())
        .then(res => {
          if (res == 'success') {
            this.setState(
              {
                popupState: true,
                popupStatus: 'Changes Successfully Saved'
              },
              () => this.removePopup()
            )
            fetch(
              APIS.GET_IVR_FILTER_API +
              this.state.searchByDate +
              '&status=' +
              this.state.filter
            )
              .then(res => res.json())
              .then(json => {
                this.setState({ IBDData: json, loaderState: false })
              })
          } else {
            this.setState(
              {
                popupState: true,
                popupStatus: 'Please Try Again'
              },
              () => this.removePopup()
            )
          }
        })
    }
    // let URL =
    //   APIS.GET_CALLBACK_STATUS_CHANGE +
    //     encodeURIComponent(data.callerNumber) +
    //     '&status=' + this.state.status

    // console.log(URL)
    // fetch(URL)
    //   .then(res => res.text())
    //   .then(res => {
    //     console.log(res)
    //     if (res == 'success') {
    //       this.setState(
    //         {
    //           loaderState: false,
    //           popupState: true,
    //           popupStatus: 'Changes Successfully Saved'
    //         },
    //         () => this.removePopup()
    //       )
    //     } else {
    //       this.setState(
    //         {
    //           popupState: true,
    //           popupStatus: 'Please Try Again'
    //         },
    //         () => this.removePopup()
    //       )
    //     }
    //   })
  }

  render() {
    const { admin } = this.props
    return (
      <div className='full-height'>
        {this.state.popupState == true ? (
          <Popup
            closePopup={this.closePopup.bind(this)}
            popupStatus={this.state.popupStatus}
          />
        ) : (
          ''
        )}

        {this.state.userState == true ? (
          <IBRUI
            tableMinPagination={this.state.tableMinPagination}
            tableMaxPagination={this.state.tableMaxPagination}
            phocketTablePagination={this.phocketTablePagination.bind(this)}
            callbackStatusChange={this.callbackStatusChange.bind(this)}
            statusFilterHandler={this.statusFilterHandler.bind(this)}
            isChecked={this.state.isChecked}
            loaderState={this.state.loaderState}
            IBDData={this.state.IBDData}
            tableKeyHandler={this.tableKeyHandler.bind(this)}
            tableKey={this.state.tableKey}
            getFilterValue={this.getFilterValue.bind(this)}
            FilteredData={this.state.FilteredData}
            getPageValue={this.getPageValue.bind(this)}
            pageIndexToShow={this.state.pageIndexToShow}
            menuExtensionResponse={this.state.menuExtensionResponse}
            getDataByNumber={this.getDataByNumber.bind(this)}
            dateFilter={this.dateFilter.bind(this)}
            searchByDate={this.state.searchByDate}
          />
        ) : null}

        {this.state.viewState == true ? (
          <UserDetail
            allDetail={this.state.allDetail}
            userDetail={this.state.userDetail}
            admin={admin}
            backHandler={this.backHandler.bind(this)}
            actionButtonState={this.state.actionButtonState}
          />
        ) : null}
      </div>
    )
  }

  removePopup() {
    setTimeout(
      function () {
        this.setState({ popupState: false })
      }.bind(this),
      5000
    )
  }

  closePopup() {
    this.setState({ popupState: false })
  }

  backHandler() {
    this.setState({
      userState: true,
      viewState: false
    })
  }

  getDataByNumber(allDetail) {
    this.setState({ loaderState: true })

    if (
      allDetail.userID != '' &&
      allDetail.userID != null &&
      allDetail.userID != undefined
    ) {
      getUserDetailApiAdmin(allDetail.userID, callBack => {
        this.setState({
          loaderState: false,
          userState: false,
          viewState: true,
          allDetail: callBack,
          userDetail: allDetail
        })
      })
    } else {
      this.setState({
        popupState: true,
        popupStatus: 'User not registered',
        loaderState: false
      })
      this.removePopup()
    }
  }

  dateFilter(e) {
    this.setState({
      loaderState: true,
      searchByDateShow: e,
      searchByDate: _preFormatDate(e)
    })
    getIvrFilter(_preFormatDate(e), '', callBack => {
      this.setState({ IBDData: callBack, loaderState: false })
    })
    // fetch(APIS.GET_IBD_DETAILS + _formatDateInYMD(e))
    //     .then(res => res.json())
    //     .then(json => {
    //         this.setState({ IBDData: json ,loaderState:false})
    //     })
  }

  getFilterValue(filtered) {
    this.setState({ FilteredData: filtered })
  }

  getPageValue(pageIndex) {
    this.setState({ pageIndexToShow: pageIndex })
  }

  tableKeyHandler(e) {
    this.setState({ tableKey: e.target.value })
  }
}

export default IBR
