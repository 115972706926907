import React from 'react'
import MultiSelectStatusReact from 'multi-select-react';

const selectedOptionsStyles = {
    color: "#3c763d",
    backgroundColor: "#dff0d8"
};
const optionsListStyles = {
    backgroundColor: "#dff0d8",
    color: "#3c763d"
};

const CRMBulkAssign = ({ bulkAssignChange, bulkAssignHandler, bulkAssignDetail,
    userStatusData, statusRoleDataState }) => (
    <div className="container-fluid full-height ">
        <div className="row">
            {/* <div className="col-xs-12 col-sm-4"> */}
            <h3 className="text-center blue-text">Assign For Collection</h3>
            <div className="row form-group">
                <div className='col-sm-4 col-xs-12'>
                    <div className="col-sm-12 col-xs-12" style={{ margin: '10px' }}>
                        <label className="font-12px margin-0">Collection Level</label>
                        <select className="form-control" value={bulkAssignDetail.status} onChange={e => bulkAssignChange(e, 'status')}>
                            <optgroup className="display-none">
                                <option value="">Select Collection Level</option>
                            </optgroup>
                            <optgroup label="Select Collection Level">
                                <option value={"1"}>{"1"}</option>
                                <option value={"2"}>{"2"}</option>
                                <option value={"3"}>{"3"}</option>
                                <option value={"4"}>{"4"}</option>
                                <option value={"5"}>{"5"}</option>
                            </optgroup>
                        </select>
                    </div>
                    {/* </div>
                    <div className="row form-group"> */}
                    <div className="col-sm-12 col-xs-12" style={{ margin: '10px' }}>
                        <label className="font-12px margin-0">Agent</label>
                        <MultiSelectStatusReact
                            options={statusRoleDataState}
                            optionClicked={e => bulkAssignChange(e, 'statusAgent')}
                            selectedBadgeClicked={e => bulkAssignChange(e, 'statusAgent')}
                            selectedOptionsStyles={selectedOptionsStyles}
                            optionsListStyles={optionsListStyles}
                            className="form-control" />
                    </div>
                    <div className="col-sm-12 col-xs-12" style={{ margin: '10px' }}>
                        <label className="font-12px margin-0">Overdue Limit (Days)</label>
                        <select className="form-control" value={bulkAssignDetail.overdueLimit} onChange={e => bulkAssignChange(e, 'overdueLimit')}>
                            <optgroup className="display-none">
                                <option value="">Overdue Limit</option>
                            </optgroup>
                            <optgroup label="Overdue Limit">
                                <option value="1">0-30 </option>
                                <option value="31">31-90 </option>
                                <option value="91">91-365 </option>
                                <option value="365">Upper Limit 365</option>



                            </optgroup>
                        </select>
                        {/* <input className="form-control" value={bulkAssignDetail.overdueLimit} placeholder='Enter Overdue Limit' onChange={e => bulkAssignChange(e, 'overdueLimit')} /> */}
                    </div>

                    {/* </div> */}
                    {/* <div className="row form-group"> */}



                    <div className="row form-group">
                        <div className="col-xs-12 col-sm-12 text-center">
                            <button className="profile-save-btn margin-0" onClick={e => bulkAssignHandler('status')}>Assign</button>
                        </div>
                    </div>
                </div>
                <div className='col-sm-8 col-xs-12' >

                    <div className="" style={{ marginTop: '10px' }}>
                        <div className="col-xs-12" style={{overflow:'auto'}}>
                            {userStatusData != null && userStatusData.length > 0 ?
                                <table className="phocket-table" >
                                    <thead>
                                        <tr>
                                            <th>Collection Level</th>
                                            <th>Agents</th>
                                            <th>Overdue Limit</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {userStatusData.map((data, i) => {
                                            return (
                                                <tr key={i}>
                                                    <td>{data.collection}</td>
                                                    <td>
                                                        {data.assignIds.length > 0 ?
                                                            data.assignIds.map((id, j) => {
                                                                return (
                                                                    <div key={j}>{id}</div>
                                                                )
                                                            })
                                                            : null}
                                                    </td>
                                                    <td>
                                                        {data.overdueLimit == 1 ? '0-30 Days' : data.overdueLimit == 31 ? '31-90 Days' : data.overdueLimit == 91 ? '91-365 Days' : data.overdueLimit == 91 ? 'Above 365 Days':""}
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                                : null}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
)

export default CRMBulkAssign;