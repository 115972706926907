import React, { Component } from 'react'
import AccountInfoUi from '../../../presentationals/Admin/Utility/UpdateAccountInfo.component'
import { csvReader } from '../../../../utils/commonUtility'
import { UpdateAccountInfoApi } from '../AdminActionCreator.component'
import WarningPopup from '../../../presentationals/Admin/Utility/PincodeWarning.popup'
import Popup from '../../../presentationals/Popup/Popup.component';

let paginationNumber = 5;
let dataForSave = []

class AccountInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            csvDataArray: [],
            warningPopupState: false,
            popupState: false,
            popupStatus: '',
            tableMinPagination: 0,
            tableMaxPagination: paginationNumber,
            search: '',
            searchState: false,
            dataForSave: []
        }
    }

    render() {
        let csvDataArray = this.state.csvDataArray
        if (this.state.search) {
            csvDataArray = csvDataArray.filter(row => {
                if (row.mobileNumber != null && row.mobileNumber != "" && row.emailId != null && row.emailId != "") {
                    return row.mobileNumber.toLowerCase().includes(this.state.search.toLowerCase()) ||
                        row.emailId.toLowerCase().includes(this.state.search.toLowerCase())
                }
            })
        }
        return (
            <div>
                {this.state.popupState == true ?
                    <Popup
                        closePopup={this.closePopup.bind(this)}
                        popupStatus={this.state.popupStatus}
                    />
                    : null}
                <AccountInfoUi
                    handleForce={this.handleForce.bind(this)}
                    saveCsvData={this.saveCsvData.bind(this)}
                    csvDataArray={csvDataArray}
                    phocketTablePagination={this.phocketTablePagination.bind(this)}
                    tableMinPagination={this.state.tableMinPagination}
                    tableMaxPagination={this.state.tableMaxPagination}
                    searchHandler={this.searchHandler.bind(this)}
                    searchState={this.state.searchState}
                    selectCsvData={this.selectCsvData.bind(this)}
                />
                {this.state.warningPopupState ?
                    <WarningPopup
                        cancelLoanConfirm={this.cancelLoanConfirm.bind(this)} />
                    : null}
            </div>
        )
    }

    searchHandler(e) {
        this.setState({ search: e.target.value })
    }

    phocketTablePagination(data, type) {
        if (type == 'back') {
            if (this.state.tableMinPagination >= paginationNumber) {
                this.setState({
                    tableMinPagination: this.state.tableMinPagination - paginationNumber,
                    tableMaxPagination: this.state.tableMaxPagination - paginationNumber,
                })
            }
        } else if (type == 'forward') {
            if (this.state.tableMaxPagination < data.length) {
                this.setState({
                    tableMaxPagination: this.state.tableMaxPagination + paginationNumber,
                    tableMinPagination: this.state.tableMinPagination + paginationNumber
                })
            }
        }
    }

    handleForce(data) {
        this.setState({
            csvDataArray: csvReader(data),
            searchState: true
        })
    };

    saveCsvData() {
        this.setState({ warningPopupState: true })
    }

    selectCsvData(e, data) {
        if (e.target.checked) {
            if (dataForSave.includes(data)) {

            } else {
                dataForSave.push(data)
            }
        } else {
            let index = dataForSave.indexOf(data)
            if (index >= 0) {
                dataForSave.splice(index, 1);
            }
        }
        this.setState({
            dataForSave: dataForSave
        })
    }

    cancelLoanConfirm(type) {
        if (type == 'yes') {
            UpdateAccountInfoApi(this.state.dataForSave,
                callBack => {
                    if (callBack == 'success') {
                        this.setState({
                            warningPopupState: false,
                            popupState: true,
                            popupStatus: 'CSV uploaded successfully',
                            csvDataArray: []
                        })
                        dataForSave = []
                        this.removePopup();
                    } else {
                        this.setState({
                            warningPopupState: false,
                            popupState: true,
                            popupStatus: 'CSV not uploaded',
                        })
                        this.removePopup();
                    }
                })
        } else if (type == 'no') {
            this.setState({ warningPopupState: false })
        }
    }

    removePopup() {
        setTimeout(function () {
            this.setState({ popupState: false });
        }.bind(this), 5000)
    }

    closePopup() {
        this.setState({ popupState: false });
    }
}

export default AccountInfo;