import React, { useEffect, useState } from "react";
import ReportLogo from '../../../../images/reports.png'
import Vector from '../../../../images/dashboard.png'
import { getCrifAnalysisStatus, getSmsAnalysisStatus, getFinbitAnalysis, similarProfileData } from "./analysisactionCreator";
import CreditDetails from './creditDetails'
import { getFinDataDetails, getFraudDetailApiNew } from "../AdminActionCreator.component";
import Loader from '../../../presentationals/Loader/Loader.component'
let paginationNumber = 10

const Credit = ({ userId, loanId,allDetail }) => {
    const [crifData, setCrifAnalysisData] = useState('')
    const [smsData, setSmsData] = useState('')
    const [finbitData, setFinbitData] = useState('')
    const [similarData, setSimilarData] = useState('')
    const [viewPage, setviewPage] = useState('view')
    const [secondTab, setSecondT] = useState('')
    const [fraudData, setFraudData] = useState('')
    const [matchByCategory, setmatchByCategory] = useState('')
    const [filterData, setFilterData] = useState('')
    const [filterType, setFilterType] = useState('')
    const [loaderState, setLoader] = useState(false)
    const [tableMinPagination, settableMinPagination] = useState(0)
    const [tableMaxPagination, settableMaxPagination] = useState(paginationNumber)
    const [finbitTab, setfinbitTab] = useState('')
    useEffect(() => {
        // getFinBitDetails()
    }, [])
    const getCrifAnalysisStatusdata = () => {
        getCrifAnalysisStatus(userId,loanId, callback => {
            setCrifAnalysisData(callback)
        })
    }
    const setSecondTab = (type) => {
        setSecondT(type)
        settableMinPagination(0)
        settableMaxPagination(10)
        if (type === 'internal') {
            if (fraudData === '' && similarData && similarData.matchBy && (similarData.matchBy.includes('name') || similarData.matchBy.includes('address') || similarData.matchBy.includes('accountNo'))) {
                getdata()
            }
        }
    }
    const phocketTablePagination = (data, type) => {
        if (type == 'back') {
            if (tableMinPagination >= paginationNumber) {
                settableMinPagination(tableMinPagination - paginationNumber)
                settableMaxPagination(tableMaxPagination - paginationNumber)


            }
        } else if (type == 'forward') {
            if (tableMaxPagination < data.length) {
                settableMinPagination(tableMinPagination + paginationNumber)
                settableMaxPagination(tableMaxPagination + paginationNumber)

            }
        }
    }
    const getSmsAnalysisStatusData = () => {
        getSmsAnalysisStatus(userId, callback => {
            setSmsData(callback)
        })
    }
    const getFinbitAnalysisData = () => {
        getFinbitAnalysis(userId, callback => {
            setFinbitData(callback)
        })
    }
    const similarProfileDataApiData = () => {
        similarProfileData(loanId, callback => {
            setSimilarData(callback)
        })
    }
    useEffect(() => {

        getCrifAnalysisStatusdata()
        getSmsAnalysisStatusData()
        getFinbitAnalysisData()
        similarProfileDataApiData()
    }, [])
    const tabToShow = (type, second) => {
        setviewPage(type)
        setSecondTab(second)
        if (second === 'internal' && similarData && similarData.matchBy && (similarData.matchBy.includes('name') || similarData.matchBy.includes('address') || similarData.matchBy.includes('accountNo')||similarData.matchBy.includes('mobileNo'))) {
            getdata()
        }


    }
    const getdata = () => {
        let alldata = {
            'userId': userId
        }
        let matchByCategory = []
        setLoader(true)
        getFraudDetailApiNew(alldata,
            (callBack) => {
                let last = []
                Object.keys(callBack).length > 0 ?
                    Object.keys(callBack).map(data => {
                        callBack[data].matchBy.map(data => {
                            if (!matchByCategory.includes(data)) {
                                matchByCategory.push(data)
                                setmatchByCategory(matchByCategory)

                            }
                        })
                        last.push(callBack[data])
                    }) : null

                setFraudData(last)
                setFilterData(last)

              
                setLoader(false)

            })
    }
    const getFinBitDetails = () => {

        // getFinDataDetails(userId, callback => {
        //     if (callback != 'no data') {
        //         setfinbitTab(callback)
        //     }
        // })
    }
    const similarFilterData = (data) => {
        settableMinPagination(0)
        settableMaxPagination(10)

        setFilterType(data)
        let d = []
        let similarProfileData = fraudData
        if (data) {
            similarProfileData = similarProfileData.filter(row => {
                if (row.matchBy != null) {
                    if (row.matchBy.includes(data)) {
                        d.push(row)
                        setFilterData(d)
                      
                    }
                }
            })
        } else {
            setFilterData(fraudData)
        }

    }

    return (
        <>
            {loaderState ?
                <Loader />
                : ""}
            {viewPage === 'view' ?

                <div className="container-fluid">
                    <h4 style={{ fontWeight: '600' }}><img src={ReportLogo} width='33px' />&nbsp;&nbsp;Credit Analysis</h4>
                    <div className="col-sm-3 col-xs-12">
                        <div className="credit-cards-new">
                            <div className="credit-head"><img src={Vector} width='25px' />&nbsp;&nbsp;SMS Analysis</div>
                            <div className="credit-body border-credit">
                                <div className="row">
                                    <div className="col-sm-10 col-xs-10">Overdue SMS</div>
                                    <div className="col-sm-2 col-xs-2 text-right text-right">{smsData && smsData.overDue ? <i class="fa fa-check-circle" style={{ color: '#95C90A', fontSize: '18px' }} aria-hidden="true"></i> : <i class="fa fa-times-circle" style={{ color: 'red', fontSize: '18px' }} aria-hidden="true"></i>}</div>
                                </div>
                            </div>
                            <div className="credit-body border-credit">
                                <div className="row">
                                    <div className="col-sm-10 col-xs-10">Legal SMS</div>
                                    <div className="col-sm-2 col-xs-2 text-right text-right">{smsData && smsData.legal ? <i class="fa fa-check-circle" style={{ color: '#95C90A', fontSize: '18px' }} aria-hidden="true"></i> : <i class="fa fa-times-circle" style={{ color: 'red', fontSize: '18px' }} aria-hidden="true"></i>}</div>
                                </div>
                            </div>
                            <div className="credit-body border-credit">
                                <div className="row">
                                    <div className="col-sm-7 col-xs-7">EMI Amount</div>
                                    <div className="col-sm-5 col-xs-5 text-blue">{smsData && smsData.totalEmiAmt ? 'INR' : ""}  {smsData && smsData.totalEmiAmt ? smsData && Math.round(smsData.totalEmiAmt) : 'N/A'}</div>
                                </div>
                            </div>
                            <div className="credit-body border-credit">
                                <div className="row">
                                    <div className="col-sm-7 col-xs-7">Salary</div>
                                    <div className="col-sm-5 col-xs-5 text-blue">{smsData && smsData.salary ? 'INR' : ""} {smsData && smsData.salary ? smsData && Math.round(smsData.salary) : 'N/A'}</div>
                                </div>
                            </div>

                            <div className="fixed-btn" onClick={() => tabToShow('details', 'sms', smsData)}>
                                <div className="credit-footer">
                                    <i class="fa fa-eye" aria-hidden="true"></i>&nbsp;&nbsp;View
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="col-sm-3 col-xs-12">
                        <div className="credit-cards-new">
                            <div className="credit-head"><img src={Vector} width='25px' />&nbsp;&nbsp;Bank Statement</div>
                            <div className="credit-body border-credit">
                                <div className="row">
                                    <div className="col-sm-7 col-xs-7">Salary</div>
                                    <div className="col-sm-5 col-xs-5 text-blue">{finbitData.salary ? 'INR' : ""} {finbitData && finbitData.salary ? Math.round(finbitData.salary) : 'N/A'}</div>
                                </div>
                            </div>

                            <div className="credit-body border-credit">
                                <div className="row">
                                    <div className="col-sm-10 col-xs-10">Fraud Record</div>
                                    <div className="col-sm-2 col-xs-2 text-blue text-right">  {finbitData && finbitData.decision === 'Approved' ? <i class="fa fa-check-circle" style={{ color: '#95C90A', fontSize: '18px' }} aria-hidden="true"></i> : <i class="fa fa-times-circle" style={{ color: 'red', fontSize: '18px' }} aria-hidden="true"></i>}</div>
                                </div>
                            </div>
                            {/* {finbitTab && finbitTab.accountUUID ? */}
                            <div className="fixed-btn" onClick={() => tabToShow('details', 'bank')}>
                                <div className="credit-footer"><i class="fa fa-eye" aria-hidden="true"></i>&nbsp;&nbsp;View </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-3 col-xs-12">
                        <div className="credit-cards-new">
                            <div className="credit-head"><img src={Vector} width='25px' />&nbsp;&nbsp;Internal Dedupe</div>
                            <div className="credit-body border-credit">
                                <div className="row">
                                    <div className="col-sm-10 col-xs-10">KYC Data Match</div>
                                    <div className="col-sm-2 col-xs-2 text-blue text-right">{similarData && similarData.matchBy && (similarData.matchBy.includes('name') || similarData.matchBy.includes('pan_card')) ? <i class="fa fa-check-circle" style={{ color: '#95C90A', fontSize: '18px' }} aria-hidden="true"></i> : <i class="fa fa-times-circle" style={{ color: 'red', fontSize: '18px' }} aria-hidden="true"></i>}</div>
                                </div>
                            </div>
                            <div className="credit-body border-credit">
                                <div className="row">
                                    <div className="col-sm-10 col-xs-10">Address Match</div>
                                    <div className="col-sm-2 col-xs-2 text-blue text-right">{similarData && similarData.matchBy && similarData.matchBy.includes('address') ? <i class="fa fa-check-circle" style={{ color: '#95C90A', fontSize: '18px' }} aria-hidden="true"></i> : <i class="fa fa-times-circle" style={{ color: 'red', fontSize: '18px' }} aria-hidden="true"></i>}</div>
                                </div>
                            </div>
                            <div className="credit-body border-credit">
                                <div className="row">
                                    <div className="col-sm-10 col-xs-10">Mobile Match</div>
                                    <div className="col-sm-2 col-xs-2 text-blue text-right">{similarData && similarData.matchBy && similarData.matchBy.includes('mobileNo') ? <i class="fa fa-check-circle" style={{ color: '#95C90A', fontSize: '18px' }} aria-hidden="true"></i> : <i class="fa fa-times-circle" style={{ color: 'red', fontSize: '18px' }} aria-hidden="true"></i>}</div>
                                </div>
                            </div>
                            {/* <div className="credit-body border-credit">
                        <div className="row">
                            <div className="col-sm-10 col-xs-10">Device ID</div>
                            <div className="col-sm-2 col-xs-2 text-blue text-right">{similarData &&similarData.matchBy&& similarData.matchBy.includes('device') ? <i class="fa fa-check-circle" style={{ color: '#95C90A', fontSize: '18px' }} aria-hidden="true"></i> : <i class="fa fa-times-circle" style={{ color: 'red', fontSize: '18px' }} aria-hidden="true"></i>}</div>
                        </div>
                    </div> */}
                            <div className="credit-body">
                                <div className="row">
                                    <div className="col-sm-10 col-xs-10">Account Number</div>
                                    <div className="col-sm-2 col-xs-2 text-blue text-right">{similarData && similarData.matchBy && similarData.matchBy.includes('accountNo') ? <i class="fa fa-check-circle" style={{ color: '#95C90A', fontSize: '18px' }} aria-hidden="true"></i> : <i class="fa fa-times-circle" style={{ color: 'red', fontSize: '18px' }} aria-hidden="true"></i>}</div>
                                </div>
                            </div>
                            {/* {similarData && similarData.matchBy && (similarData.matchBy.includes('name') || similarData.matchBy.includes('address') || similarData.matchBy.includes('accountNo')) ? */}
                            <div className="fixed-btn" onClick={() => tabToShow('details', 'internal')}>
                                <div className="credit-footer" ><i class="fa fa-eye" aria-hidden="true"></i>&nbsp;&nbsp;View </div>
                            </div>
                            {/* : ""} */}
                        </div>
                    </div>
                    <div className="col-sm-3 col-xs-12">
                        <div className="credit-cards-new">
                            <div className="credit-head"><img src={Vector} width='25px' />&nbsp;&nbsp;CRIF Analysis</div>
                            <div className="credit-body border-credit">
                                <div className="row">
                                    <div className="col-sm-7 col-xs-7">Obligation Amount</div>
                                    {crifData && crifData.obligationData && crifData.obligationData.emiSum ? <div className="col-sm-5 col-xs-5 text-blue">INR {crifData ? Math.round(crifData.obligationData.emiSum) : 0}</div> : <div className="col-sm-5 col-xs-5 text-right text-blue">N/A</div>}
                                </div>
                            </div>
                            <div className="credit-body border-credit">
                                <div className="row">
                                    <div className="col-sm-10 col-xs-10">Final Decision</div>
                                    {crifData && crifData.finBoxDecision && crifData.finBoxDecision ? <div className="col-sm-2 col-xs-2 text-blue text-right">{crifData ? crifData.finBoxDecision.finalDecision == 'Approved' ? <i class="fa fa-check-circle" style={{ color: '#95C90A', fontSize: '18px' }} aria-hidden="true"></i> : <i class="fa fa-times-circle" style={{ color: 'red', fontSize: '18px' }} aria-hidden="true"></i> : ""}</div> : <div className="text-blue" style={{ marginRight: '18px' }}> N/A</div>}
                                </div>
                            </div>

                            <div className="fixed-btn" onClick={() => tabToShow('details', 'crif')}>
                                <div className="credit-footer"><i class="fa fa-eye" aria-hidden="true"></i>&nbsp;&nbsp;View </div>
                            </div>
                        </div>
                    </div>

                </div>
                : ""}
            {viewPage === 'details' ?
                <CreditDetails
                    smsData={smsData}
                    fraudData={filterData}
                    matchByCategory={matchByCategory}
                    tabToShow={tabToShow}
                    secondTab={secondTab}
                    setSecondTab={setSecondTab}
                    userId={userId}
                    similarFilterData={similarFilterData}
                    filterType={filterType}
                    phocketTablePagination={phocketTablePagination}
                    tableMinPagination={tableMinPagination}
                    tableMaxPagination={tableMaxPagination}
                    similarData={similarData}
                    crifData={crifData}
                    finbitData={finbitData}
                    allDetail={allDetail}
                // finbitTab={finbitTab}


                />
                : ""}
        </>
    )
}
export default Credit