import React from 'react'

const PasswordPopup = ({ checkStatusChange, checkStatusHandler, markAsImportant, commentHandler, isStatusChange,
    allDetail, closeStatusPopup }) => (
    <div className="background-blur">
        <div className="popup-main small-popup container-fluid max-width-500px dbl-border">
            <div className="row from-group">
                <div className="col-xs-12">
                    <button className="close" onClick={e => closeStatusPopup()}>&times;</button>
                    <h4 className="text-center">Do You Want to Change The Status?</h4>
                </div>
            </div>
            <div className="row form-group" style={{ marginTop: '20px' }}>
                <div className="col-xs-12 text-center">

                    <select className="form-control" onChange={e => checkStatusChange(e)}>
                        <option value="">Select One</option>
                        <option value="true">Yes</option>
                        <option value="false">No</option>
                    </select>
                </div>
            </div>

            <div className="row form-group">

                <div className="col-xs-12 ">
                    <label>Select Comment</label>
                    <select className="form-control" onChange={e => commentHandler(e)}>
                        <option value=''>Select Comment</option>
                        <option value=' Not Answering/Disconnecting'> Not Answering/Disconnecting</option>
                        <option value='Not reachable/Switched off'>Not reachable/Switched off</option>
                        <option value='KYC pending'>KYC pending</option>
                        <option value='Bank statement/Finbit'>Bank statement/Finbit</option>
                        <option value='Salary Slip/Employment Proof'>Salary Slip/Employment Proof</option>
                        <option value='Current Address'>Current Address</option>
                        {localStorage.getItem('showComment') === 'yes' ?
                            <option value='About to Share Doc'>About to Share Doc</option>
                            : ""}
                        {localStorage.getItem('showComment') === 'yes' ?

                            <option value='Need to Call Back'>Need to Call Back</option>
                            : ""}
                        <option value='Others'>Others</option>
                    </select>
                </div>

            </div>
            <div className="row text-center">
                <div className="col-xs-12">Mark As Important&nbsp;&nbsp;
                    {allDetail != null && allDetail != '' ? allDetail.important == true ?
                        <span className="fa fa-star orange-text" onClick={e => markAsImportant(false)} />
                        :
                        <span className="fa fa-star-o" onClick={e => markAsImportant(true)} />
                        : ""}
                </div>
            </div>
            <div className="row from-group">
                <div className="col-xs-12">
                    <button className="profile-save-btn center-block" onClick={(e) => checkStatusHandler(allDetail)}>SAVE</button>
                </div>
            </div>
        </div>
    </div>
)

export default PasswordPopup;