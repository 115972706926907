import React from "react";
import { Slider, styled } from "@mui/material";

function ConversionRatio({ data }) {
  const CustomSlider = styled(Slider)(({ theme }) => ({
    height: "10px",
    color: "#697BE9", //color of the slider between thumbs
    boxShadow: "inset -9px 7px 2px rgba(255, 255, 255, 0.25)",

    "& .MuiSlider-thumb": {
      width: "10px",
      height: "10px",
      backgroundColor: "white", //color of thumbs
    },

    "& .MuiSlider-rail": {
      // color: "#03001663", ////color of the slider outside  teh area between thumbs
      background: "#F6CF7D",
    },
  }));
  return (
    <>
      <div className="shadow card-hight" style={{padding:'10px',marginTop:'20px',minHeight:'330px'}}>
        <div className="d-flex flex-column justify-content-around">
          <div className="d-flex flex-row justify-content-between">
            <div className="d-flex align-item-center">
              <h5 className="totalcount1 card-heading">
                <b>Conversion Ratio</b>
              </h5>
            </div>
           
          </div>
        </div>

        <div className="d-inline-flex justify-content-center" style={{display:'inline-flex',marginTop:'20px',marginLeft:'10px'}}>
          <p className="ms-2 mb-0" style={{ fontSize: "35px" }}>
            <b>{data.conversionRatio?.toFixed(2)}%</b>
          </p>
          <span className="ms-2" style={{ color: "#377E36" }}>
            <p
              style={{
                backgroundColor: "#E0F0E4",
                fontSize: '12px',
                marginTop:'7px',
                borderRadius: "5px",
                marginLeft:'3px',
                padding:'3px'
              }}
              className="mb-0 mt-4"
            >
              +3.4%
            </p>
          </span>
        </div>

      
        <div style={{marginTop:'30px'}}>
          <CustomSlider
            min={0}
            max={100}
            value={data.conversionRatio?.toFixed(2)}
            valueLabelDisplay="auto"
          />
       </div>
   

        <div className="row" style={{marginTop:'20px'}}>
          <div className="col-sm-6 col-xs-12">
            <div
              className="p-3 shadow1"
              style={{
                // height: "93px",
                borderRadius: "8px",
              }}
            >
              <div className="d-inline-flex align-items-center" style={{display:'inline-flex'}}>
                <div className="totalloan5" style={{marginTop:'10px',marginLeft:'10px'}}></div>
                <p
                  className="mb-0"
                  style={{
                    padding: "5px",
                  }}
                >
                  <b>Good Leads</b>
                </p>
              </div>
              <br/>
              <div className="d-flex d-inline-flex  w-100"  style={{display:'inline-flex',marginLeft:'20px'}}>
                {" "}
                <p style={{ fontSize: "24px" }}>
                  <b>{data.goodRatio?.toFixed(2)}%</b>
                </p>
                <div style={{ color: "#377E36" }}>
                  <p
                    style={{
                      backgroundColor: "#E0F0E4",
                      fontSize: '12px',
                      marginTop:'3px',
                      borderRadius: "5px",
                      padding:'3px',
                      marginLeft:'3px'
                    }}
                    className="mb-0"
                  >
                    +3.4%
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-xs-12">
            <div
              className="p-3 shadow1"
              style={{
                // height: "93px",
                borderRadius: "8px",
              }}
            >
              <div className="d-inline-flex align-items-center" style={{display:'inline-flex'}}>
                <div className="totalloan5"  style={{marginTop:'10px',marginLeft:'10px'}}></div>
                <p
                  className="mb-0 "
                  style={{
                    padding: "5px",
                  }}
                >
                  <b> Cold Leads</b>
                </p>
              </div>
              <br/>
              <div className="d-flex d-inline-flex w-100" style={{display:'inline-flex',marginLeft:'20px'}}>
                {" "}
                <p style={{ fontSize: "24px" }}>
                  <b>{data.badRatio?.toFixed(2)}%</b>
                </p>
                <div style={{ color: "#B12F30" }}>
                  <p
                    style={{
                      backgroundColor: "#FEECEB",
                      fontSize: '12px',
                      marginTop:'4px',
                      borderRadius: "5px",
                      padding:'3px',
                      marginLeft:'3px'
                    }}
                    className="mb-0"
                  >
                    -1,2%
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ConversionRatio;
