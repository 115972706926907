import React, { Component } from 'react'
import Similar from './ConfigurationSimilarProfile.container'
import Config from './config.container'
import Collection from './CollectionNotificationMedium.container'


class Communication extends Component {
    constructor(props) {
        super(props);
        this.state = {
            viewPageState: 'similar'
        }
    }

    render() {
        const { allDetail, admin, creditScoringPopup } = this.props;
        return (
            <div className="full-height container-fluid white-bg">
                <div className="row">
                    {/* <div className="btn-group-tab">
                            <button className={this.state.viewPageState == 'similar' ? 'active' : null} onClick={(e) => this.sendHandler('similar')}>similar</button>
                            <button className={this.state.viewPageState == 'configuration' ? 'active' : null} onClick={(e) => this.sendHandler('configuration')}>configuration</button>
                            <button className={this.state.viewPageState == 'collection' ? 'active' : null} onClick={(e) => this.sendHandler('collection')}>collection</button>
                       
                        </div> */}

                    {/* <ul className="nav nav-tabs nav-justified" role="tablist">
                            <li className={this.state.viewPageState == 'similar' ? 'active' : null} onClick={(e) => this.sendHandler('similar')}>similar</li>
                            <li className={this.state.viewPageState == 'configuration' ? 'active' : null} onClick={(e) => this.sendHandler('configuration')}>configuration></li>
                            <li className={this.state.viewPageState == 'collection' ? 'active' : null} onClick={(e) => this.sendHandler('collection')}>collection></li>
                        </ul> */}
                        <h4 className="text-center">CONFIGURATION</h4>
                    <div className="col-xs-12">
                        <ul className="tabs group">
                            <li><a className={this.state.viewPageState == 'similar' ? 'active' : null} onClick={(e) => this.sendHandler('similar')}>pH Scoring </a></li>
                            <li><a className={this.state.viewPageState == 'configuration' ? 'active' : null} onClick={(e) => this.sendHandler('configuration')}>Similar Profile Parameters</a></li>
                            <li><a className={this.state.viewPageState == 'collection' ? 'active' : null} onClick={(e) => this.sendHandler('collection')}>Collection Notification</a></li>
                        </ul>
                    </div>
                </div>
                <div className="row overflow-auto">
                    {this.state.viewPageState == 'similar' ?
                        <Config

                        />
                        : this.state.viewPageState == 'configuration' ?
                            <Similar
                            />
                            : this.state.viewPageState == 'collection' ?
                                <Collection
                                />
                                : null}
                </div>
            </div>
        )
    }

    sendHandler(type) {
        this.setState({
            viewPageState: type,
        })
    }
}

export default Communication;
