import React, { Component } from 'react';
import BulletNotice from './NoticeBullet.container';
import EmiNotice from './NoticeEmi.container';

class IncomingContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            incomingPageToShow: 'bullet',
        }
    }

    render() {
        const { admin } = this.props
        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-xs-12 recovery-tabs">
                        <div className="dashboard-tabs">
                            <button className={this.state.incomingPageToShow == 'bullet' ? 'active' : ''} onClick={e => this.buttonActionHandler("bullet")}>Bullet</button>
                            <button className={this.state.incomingPageToShow == 'emi' ? 'active' : ''} onClick={e => this.buttonActionHandler("emi")}>Emi</button>
                        </div>
                    </div>
                </div>
                {this.state.incomingPageToShow == 'bullet' ?
                    <BulletNotice
                    admin={admin} />
                    : this.state.incomingPageToShow == 'emi' ?
                        <EmiNotice
                        admin={admin} />
                        : null}
            </div>
        )
    }

    buttonActionHandler(type) {
        this.setState({
            incomingPageToShow: type
        })
    }

}


export default IncomingContainer;