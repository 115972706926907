import React, { Component } from 'react'
import PriorityCompanyUi from '../../../presentationals/Admin/Utility/PriorityCompany.component';
import { APIS } from '../../../../utils/api-factory'
import { addPriorityCompanyApi } from '../AdminActionCreator.component'
import Popup from '../../../presentationals/Popup/Popup.component';

let paginationNumber = 10;
let companytoadd = []
class PriorityCompany extends Component {
    constructor(props) {
        super(props);
        this.state = {
            allCompanyList: [],
            searchState: '',
            tableMinPagination: 0,
            tableMaxPagination: paginationNumber,
            popupState: false,
            popupStatus: '',
            newCompanies: []
        }
    }

    componentDidMount() {
        this.getPriorityCompany()
    }

    getPriorityCompany() {
        fetch(APIS.GET_PRIORITY_COMPANY)
            .then(res => res.json())
            .then(res => {
                this.setState({
                    allCompanyList: res,
                })
            })
    }

    render() {
        let allCompanyList = this.state.allCompanyList
        if (this.state.searchState) {
            allCompanyList = allCompanyList.filter(row => {
                if (row != null) {
                    return row.toLowerCase().includes(this.state.searchState.toLowerCase())
                }
            })
        }
        return (
            <div>
                <PriorityCompanyUi
                    allCompanyList={allCompanyList}
                    searchHandler={this.searchHandler.bind(this)}
                    phocketTablePagination={this.phocketTablePagination.bind(this)}
                    tableMinPagination={this.state.tableMinPagination}
                    tableMaxPagination={this.state.tableMaxPagination}
                    addCompany={this.addCompany.bind(this)}
                    addCompanyChange={this.addCompanyChange.bind(this)}
                    newCompanies={this.state.newCompanies} />
                {this.state.popupState == true ?
                    <Popup
                        closePopup={this.closePopup.bind(this)}
                        popupStatus={this.state.popupStatus}
                    />
                    : ""}
            </div>
        )
    }

    addCompanyChange(e) {
        companytoadd.push(e.target.value)
        this.setState({ newCompanies: e });
    }

    addCompany() {
        addPriorityCompanyApi(this.state.newCompanies, callBack => {
            if (callBack == 'success') {
                this.setState({
                    popupState: true,
                    popupStatus: "Companies added successfully!",
                    newCompanies: []
                })
                this.getPriorityCompany()
                this.removePopup();
            } else {
                this.setState({
                    popupState: true,
                    popupStatus: "Please try again",
                })
                this.removePopup();
            }
        })
    }


    phocketTablePagination(data, type) {
        if (type == 'back') {
            if (this.state.tableMinPagination >= paginationNumber) {
                this.setState({
                    tableMinPagination: this.state.tableMinPagination - paginationNumber,
                    tableMaxPagination: this.state.tableMaxPagination - paginationNumber,
                })
            }
        } else if (type == 'forward') {
            if (this.state.tableMaxPagination < data.length) {
                this.setState({
                    tableMaxPagination: this.state.tableMaxPagination + paginationNumber,
                    tableMinPagination: this.state.tableMinPagination + paginationNumber
                })
            }
        }
    }

    searchHandler(e) {
        this.setState({
            searchState: e.target.value,
            tableMinPagination: 0,
            tableMaxPagination: paginationNumber,
        })
    }

    removePopup() {
        setTimeout(function () {
            this.setState({ popupState: false });
        }.bind(this), 5000)
    }

    closePopup() {
        this.setState({ popupState: false });
    }
}

export default PriorityCompany;