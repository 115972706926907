import React, { Component } from 'react'
import { APIS } from '../../../../utils/api-factory';
import RegistrationUi from '../../../presentationals/Admin/FieldBoyApp/Registration.component';
import Popup from '../../../presentationals/Popup/Popup.component';
import { saveFieldBoyApi } from '../AdminActionCreator.component'
import moment from 'moment';
import Confirmation from '../../../presentationals/Popup/ConfirmationPopup.component'

class DocumentsCollection extends Component {
    constructor(props) {
        super(props);
        this.state = {
            confirmationPopup: false,
            confirmationText: '',
            popupState: false,
            popupStatus: '',
            fieldBoyData: '',
            fieldBoyDetail: {
                FBFullNameState: '',
                FBMobileState: '',
                FBAddressState: '',
                FBEmailState: '',
                FBPasswordState: '',
                id: 0
            },
            fieldBoyDIstance: '',
            distancePopup: false,
            idForDelete: ''
        }
    }

    componentWillMount() {
        fetch(APIS.GET_FIELDBOY_DETAILS)
            .then(res => res.json())
            .then(res => {
                this.setState({ fieldBoyData: res })
            })
    }

    render() {
        const {admin} = this.props
        return (
            <div>
                {this.state.popupState == true ?
                    <Popup
                        closePopup={this.closePopup.bind(this)}
                        popupStatus={this.state.popupStatus}
                    />
                    : ""}
                <RegistrationUi
                    FBRegistrationHandler={this.FBRegistrationHandler.bind(this)}
                    FBRegistration={this.FBRegistration.bind(this)}
                    fieldBoyDetail={this.state.fieldBoyDetail}
                    fieldBoyData={this.state.fieldBoyData}
                    updateData={this.updateData.bind(this)}
                    deleteData={this.deleteData.bind(this)}
                    ViewDistance={this.ViewDistance.bind(this)}
                    fieldBoyDIstance={this.state.fieldBoyDIstance}
                    distancePopup={this.state.distancePopup}
                    closeDistancePopup={this.closeDistancePopup.bind(this)}
                    startTimeHandler={this.startTimeHandler.bind(this)}
                    admin={admin}
                />
                {this.state.confirmationPopup == true ?
                    <Confirmation
                        cancelLoanConfirm={this.cancelLoanConfirm.bind(this)}
                        confirmationText={this.state.confirmationText} />
                    : ""}
            </div>
        )
    }

    closeDistancePopup() {
        this.setState({
            distancePopup: false
        })
    }

    startTimeHandler(data) {
        fetch(APIS.START_FIELD_BOY_TIMING + moment(new Date()).format('HH:mm') + '&empId=' + data.userid + '&type=startTime')
            .then(res => res.text())
            .then(text => {
                if (text == 'success') {
                    this.setState({
                        popupState: true,
                        popupStatus: 'Time Started',
                    })
                    this.removePopup();
                } else {
                    this.setState({
                        popupState: true,
                        popupStatus: 'Please try again',
                    })
                    this.removePopup();
                }
            })
    }

    ViewDistance(data) {
        fetch(APIS.DAILY_DISTANCE_COVERED + data.userid)
            .then(res => res.json())
            .then(json => {
                this.setState({
                    fieldBoyDIstance: json,
                    distancePopup: true
                })
            })
    }

    updateData(data) {
        let fieldBoyDetail = Object.assign({}, this.state.fieldBoyDetail);
        fieldBoyDetail.FBFullNameState = data.name
        fieldBoyDetail.FBMobileState = data.phonenumber
        fieldBoyDetail.FBAddressState = data.address
        fieldBoyDetail.FBEmailState = data.userid
        fieldBoyDetail.FBPasswordState = data.password
        fieldBoyDetail.id = data.id
        this.setState({ fieldBoyDetail })
    }

    deleteData(data) {
        this.setState({
            confirmationPopup: true,
            idForDelete: data.userid,
            confirmationText: 'Are you sure to want to delete'
        })
    }

    cancelLoanConfirm(conType) {
        if (conType == 'yes') {
            fetch(APIS.DELETE_FIELD_BOY + this.state.idForDelete)
                .then(res => res.text())
                .then(text => {
                    this.setState({ confirmationPopup: false, idForDelete: '' })
                    fetch(APIS.GET_FIELDBOY_DETAILS)
                        .then(res => res.json())
                        .then(res => {
                            this.setState({ fieldBoyData: res })
                        })
                })
        } else if (conType == 'no') {
            this.setState({ confirmationPopup: false })
        }
    }

    FBRegistration(e, regType) {
        let fieldBoyDetail = Object.assign({}, this.state.fieldBoyDetail);
        if (regType == 'FBFullName') {
            fieldBoyDetail.FBFullNameState = e.target.value
        } else if (regType == 'FBMobile') {
            fieldBoyDetail.FBMobileState = e.target.value
        } else if (regType == 'FBAddress') {
            fieldBoyDetail.FBAddressState = e.target.value
        } else if (regType == 'FBEmail') {
            fieldBoyDetail.FBEmailState = e.target.value
        } else if (regType == 'FBPassword') {
            fieldBoyDetail.FBPasswordState = e.target.value
        }
        this.setState({ fieldBoyDetail })
    }

    FBRegistrationHandler() {
        const { admin } = this.props
        saveFieldBoyApi(this.state.fieldBoyDetail, admin,
            (callBack) => {
                if (callBack == 'success') {
                    this.setState({
                        popupState: true,
                        popupStatus: 'Data Save Successfully',
                        fieldBoyDetail: {
                            FBFullNameState: '',
                            FBMobileState: '',
                            FBAddressState: '',
                            FBEmailState: '',
                            FBPasswordState: '',
                        },
                    })
                    this.removePopup();
                    fetch(APIS.GET_FIELDBOY_DETAILS)
                        .then(res => res.json())
                        .then(res => {
                            this.setState({ fieldBoyData: res })
                        })
                } else {
                    this.setState({
                        popupState: true,
                        popupStatus: 'Please try again',
                    })
                    this.removePopup();
                }
            })
    }

    removePopup() {
        setTimeout(function () {
            this.setState({ popupState: false });
        }.bind(this), 5000)
    }

    closePopup() {
        this.setState({ popupState: false });
    }

}

export default DocumentsCollection;