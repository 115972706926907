import React from 'react'

const DBR = ({ internalScoring, internalHandler, submit, validationMessages }) => (
    <div  style={{padding:'0px 12px'}}>
          <h5 ><b>Scorecard</b></h5>
                 <div style={{borderBottom:'1.5px solid #7D6FD9',marginBottom:'10px'}}></div>

        <div className="row">
            <div className="col-sm-4 col-xs-12 form-group">
                <b style={{fontSize:'10px',color:' rgba(0, 0, 0, 0.60)'}}>Stability&nbsp;</b>
                <h5 style={{fontWeight:'700'}}>{internalScoring.stability} </h5>
                <span style={{fontSize:'10px',color:'red'}}>{validationMessages && validationMessages.field === 'stability' ? validationMessages.msg : ""}</span>
            </div>
            <div className="col-sm-4 col-xs-12 form-group">
                <b style={{fontSize:'10px',color:' rgba(0, 0, 0, 0.60)'}}>Overdue Msg&nbsp;</b>
                <h5 style={{fontWeight:'700'}}>{internalScoring.overDueMsgsTrend} </h5>
                <span style={{fontSize:'10px',color:'red'}}>{validationMessages && validationMessages.field === 'overDueMsgsTrend' ? validationMessages.msg : ""}</span>

            </div>
            <div className="col-sm-4 col-xs-12 form-group">
                <b style={{fontSize:'10px',color:' rgba(0, 0, 0, 0.60)'}}>Bureau&nbsp;</b>
                <h5 style={{fontWeight:'700'}}>{internalScoring.bureau} </h5>
                <span style={{fontSize:'10px',color:'red'}}>{validationMessages && validationMessages.field === 'bureau' ? validationMessages.msg : ""}</span>

            </div>

        </div>
        <div className='row'>
            <div className="col-sm-4 col-xs-12 form-group">
                <b style={{fontSize:'10px',color:' rgba(0, 0, 0, 0.60)'}}>Residence&nbsp;</b>
                <br />
                <h5 style={{fontWeight:'700'}}>{internalScoring.residence} </h5>
                <span style={{fontSize:'10px',color:'red'}}>{validationMessages && validationMessages.field === 'residence' ? validationMessages.msg : ""}</span>

            </div>
            <div className="col-sm-4 col-xs-12 form-group">
                <b style={{fontSize:'10px',color:' rgba(0, 0, 0, 0.60)'}}>Debts&nbsp;</b>
                <br />
                <h5 style={{fontWeight:'700'}}>{internalScoring.debts}</h5>
                <span style={{fontSize:'10px',color:'red'}}>{validationMessages && validationMessages.field === 'debts' ? validationMessages.msg : ""}</span>

            </div>
            <div className="col-sm-4 col-xs-12 form-group">
                <b style={{fontSize:'10px',color:' rgba(0, 0, 0, 0.60)'}}>NTC&nbsp;</b>
                <h5 style={{fontWeight:'700'}}>{internalScoring.ntc} </h5>
                <span style={{fontSize:'10px',color:'red'}}>{validationMessages && validationMessages.field === 'ntc' ? validationMessages.msg : ""}</span>

            </div>
        </div>
        <div className='row'>
            <div className="col-sm-4 col-xs-12 form-group">
                <b style={{fontSize:'10px',color:' rgba(0, 0, 0, 0.60)'}}>Corporate&nbsp;</b>
                <br />
                <h5 style={{fontWeight:'700'}}>{internalScoring.corporate} </h5>
                <span style={{fontSize:'10px',color:'red'}}>{validationMessages && validationMessages.field === 'corporate' ? validationMessages.msg : ""}</span>

            </div>
            <div className="col-sm-4 col-xs-12 form-group">
                <b style={{fontSize:'10px',color:' rgba(0, 0, 0, 0.60)'}}>Access&nbsp;</b>
                <br />
                <h5 style={{fontWeight:'700'}}>{internalScoring.access} </h5>
                <span style={{fontSize:'10px',color:'red'}}>{validationMessages && validationMessages.field === 'access' ? validationMessages.msg : ""}</span>

            </div>
            <div className="col-sm-4 col-xs-12 form-group">
            <b style={{fontSize:'10px',color:' rgba(0, 0, 0, 0.60)'}}>kyc&nbsp;</b>
                <br />
                <h5 style={{fontWeight:'700'}}>{internalScoring.kyc} </h5>
                <span style={{fontSize:'10px',color:'red'}}>{validationMessages && validationMessages.field === 'kyc' ? validationMessages.msg : ""}</span>

            </div>
        </div>
        <div className='row'>
            <div className="col-sm-6 col-xs-12 form-group">
            <b style={{fontSize:'10px',color:' rgba(0, 0, 0, 0.60)'}}>Multiple Pay day&nbsp;</b>
                <h5 style={{fontWeight:'700'}}>{internalScoring.multiplePayday} </h5>
                <span style={{fontSize:'10px',color:'red'}}>{validationMessages && validationMessages.field === 'multiplePayday' ? validationMessages.msg : ""}</span>
              

            </div>
          
        </div>
        
    </div>
)

export default DBR