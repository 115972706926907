import React from 'react'

const OutletLocationAdd = ({ merchantData, postDataHandler, ulSubLocationData,
    listingOfCategory, getDataHandler, addressData, MerchantValidationMsg,
    deleteOutlet }) => (
        <div className="container-fluid">
            <div className="row">
                <h3 className="text-center">Add Outlet Location</h3>
            </div>
            <div className="row form-group">
                <div className="col-sm-4 col-xs-12">
                    <label className="font-12px">Select Location</label>
                    <select className="form-control" id="selectLocationId" value={merchantData.locationId} onChange={e => getDataHandler(e, "selectLocationId")}>
                        <optgroup className="display-none">
                            <option value="">Select Location</option>
                        </optgroup>
                        {listingOfCategory != null && listingOfCategory != '' && listingOfCategory.length>0?
                            listingOfCategory.map((list,i) => {
                                return (
                                    <optgroup key={i} label="Location">
                                        {list.Location != null && list.Location.length > 0 ?
                                            list.Location.map((location, i) => {
                                                return (
                                                    <option key={i} value={location.locationid}>{location.location}</option>
                                                )
                                            })
                                            : ""}
                                    </optgroup>
                                )
                            })
                            : ""}
                    </select>
                    <div className="font-12px margin-left-25px height-25px validation-color">
                        {MerchantValidationMsg.field == "locationId" ? MerchantValidationMsg.msg : ""}
                    </div>
                </div>
                <div className="col-sm-4 col-xs-12">
                    <label className="font-12px">Select Merchant</label>
                    <select className="form-control" id="selectMerchantId" value={merchantData.merchantId} onChange={e => getDataHandler(e, "selectMerchantId")}>
                        <optgroup className="display-none">
                            <option value="">Select Merchant</option>
                        </optgroup>
                        {listingOfCategory != null && listingOfCategory != '' && listingOfCategory.length>0?
                            listingOfCategory.map((list,i) => {
                                return (
                                    <optgroup key={i} label="Location">
                                        {list.Merchant != null && list.Merchant.length > 0 ?
                                            list.Merchant.map((Merchant, i) => {
                                                return (
                                                    <option key={i} value={Merchant.merchantid}>{Merchant.merchantname}</option>
                                                )
                                            })
                                            : ""}
                                    </optgroup>
                                )
                            })
                            : ""}
                    </select>
                    <div className="font-12px margin-left-25px height-25px validation-color">
                        {MerchantValidationMsg.field == "merchantId" ? MerchantValidationMsg.msg : ""}
                    </div>
                </div>
                <div className="col-sm-4 col-xs-12">
                    <label className="font-12px">Outlet Address</label>
                    <input type="text" className="form-control" value={merchantData.subLocationName} placeholder="Enter Address" id="subCatName" onChange={e => getDataHandler(e, "outAddName")} />
                    <div className="font-12px margin-left-25px height-25px validation-color">
                        {MerchantValidationMsg.field == "subLocationName" ? MerchantValidationMsg.msg : ""}
                    </div>
                </div>
            </div>
            <div className="row">
                <button className="profile-save-btn center-block" id="subLocationAdd" onClick={e => postDataHandler()}>
                    <span className="fa fa-plus"></span>&nbsp;&nbsp;ADD
                        </button>
            </div>
            {addressData != null && addressData != '' && addressData.length > 0 ?
                <table className="text-center loan-detail-table max-width-500px">
                    <thead>
                        <tr className="background-blue text-white">
                            <th>ID</th>
                            <th>City</th>
                            <th>Address</th>
                            <th>Delete</th>
                        </tr>
                    </thead>
                    <tbody>
                        {addressData.map((data, i) => {
                            return (
                                <tr key={i} className="pointer text-black" onClick={e => ulSubLocationData(e, data)}>
                                    <td>{data.outletid}</td>
                                    <td>{data.city}</td>
                                    <td>{data.address}</td>
                                    <td><span className="fa fa-trash" onClick={e => deleteOutlet(data)} /></td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
                : ""}
        </div>
    )

export default OutletLocationAdd;