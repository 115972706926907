import axios from "axios";
import { APIS } from "../../../utils/api-factory";

export const getFunnelGraphData = ( callBack, error) => {
  const headerConfig = {
    headers: {},
  };
  axios
    .get(APIS.GET_LOS_APPLICATION , headerConfig)
    .then((res) => {
      callBack(res.data);
    })
    .catch((e) => {
      error(e.res.data);
    });
};
export const getFunnelGraphDataOpportunity = ( callBack, error) => {
  const headerConfig = {
    headers: {},
  };
  axios
    .get(APIS.GET_LOS_APPLICATION_OPPORTUNITY , headerConfig)
    .then((res) => {
      callBack(res.data);
    })
    .catch((e) => {
      error(e.res.data);
    });
};
export const getGenderData = ( callBack, error) => {
  const headerConfig = {
    headers: {},
  };
  axios
    .get(APIS.GET_GENDER , headerConfig)
    .then((res) => {
      callBack(res.data);
    })
    .catch((e) => {
      error(e.res.data);
    });
};
export const getDeviceData = ( callBack, error) => {
  const headerConfig = {
    headers: {},
  };
  axios
    .get(APIS.GET_DEVICE , headerConfig)
    .then((res) => {
      callBack(res.data);
    })
    .catch((e) => {
      error(e.res.data);
    });
};
export const getAgeData = ( callBack, error) => {
  const headerConfig = {
    headers: {},
  };
  axios
    .get(APIS.GET_AGE_ANALYSIS , headerConfig)
    .then((res) => {
      callBack(res.data);
    })
    .catch((e) => {
      error(e.res.data);
    });
};
export const getGroupSalaryData = (callBack) => {
  fetch(APIS.GET_GROUP_SALARY)
    .then(res => res.json())
    .then(json => {
      callBack(json)
    })
};
export const getOccupationData = (callBack) => {
  fetch(APIS.GET_OCCUPATION_ANALYSIS)
    .then(res => res.json())
    .then(json => {
      callBack(json)
    })
};
export const getAgeCountData = (callBack) => {
  fetch(APIS.GET_AGE_COUNT)
    .then(res => res.json())
    .then(json => {
      callBack(json)
    })
};
export const getStaeCountData = (callBack) => {
  fetch(APIS.GET_STATE_COUNT)
    .then(res => res.json())
    .then(json => {
      callBack(json)
    })
};
export const getAverageLoanData = (callBack) => {
  fetch(APIS.GET_AVERAGE_LOAN_DETAILS)
    .then(res => res.json())
    .then(json => {
      callBack(json)
    })
};
export const getApplyVsApproveData = (callBack) => {
  fetch(APIS.GET_APPLY_VS_APPROVE)
    .then(res => res.json())
    .then(json => {
      callBack(json)
    })
};
export const getApplyVsRejectData = (callBack) => {
  fetch(APIS.GET_APPLY_VS_REJECT)
    .then(res => res.json())
    .then(json => {
      callBack(json)
    })
};