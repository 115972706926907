import React, { Component } from 'react'
import AssignByStatusUi from '../../../presentationals/Admin/Settings/AssignForCollection.component'
import Popup from '../../../presentationals/Popup/Popup.component';
import ConfirmationPopup from '../../../presentationals/Popup/ConfirmationPopup.component'
import { APIS } from '../../../../utils/api-factory'
import { getRoleAssignView, saveAssigForCollectionApi } from '../AdminActionCreator.component'

let alreadyAssigned = []

class AssignByStatus extends Component {
    constructor(props) {
        super(props);
        this.state = {
            confirmationPopupState: false,
            confirmationText: '',
            popupState: false,
            popupStatus: '',
            statusRoleDataState: [],
            roleData: [],
            userStatusData: [],
            bulkAssignDetail: {
                status: '',
                statusAgent: [],
                overdueLimit:''
            },
            btnClickType: ''
        }
    }

    componentWillMount() {
        this.getAssignedByStatus()
        this.getRoleAssignViewMethod()
    }

    getAssignedByStatus() {
        fetch(APIS.GET_ASSIGN_FOR_COLLECTION)
            .then(res => res.json())
            .then(res => {
                this.setState({
                    userStatusData: res
                })
            
            })
    }

    getRoleAssignViewMethod() {
        const { admin } = this.props
        getRoleAssignView(admin.emailId,
            (callBack) => {
                this.roleDataArray(callBack)
              
                this.setState({ roleData: callBack })
                
            });
    }

    roleDataArray(data) {
        let selectData = []
        data.length > 0 ? data.map((data, i) => {
            if (alreadyAssigned.includes(data.emailId)) {
                selectData.push({ label: data.emailId, id: i, value: true })
            } else {
                selectData.push({ label: data.emailId, id: i, value: false })
            }
        }) : null
        this.setState({
            statusRoleDataState: selectData
        })
    }

    render() {
        const { admin } = this.props
        return (
            <div className="container-fluid full-height">
                {this.state.popupState == true ?
                    <Popup
                        closePopup={this.closePopup.bind(this)}
                        popupStatus={this.state.popupStatus}
                    />
                    : ""}
                <AssignByStatusUi
                    bulkAssignChange={this.bulkAssignChange.bind(this)}
                    bulkAssignHandler={this.bulkAssignHandler.bind(this)}
                    userStatusData={this.state.userStatusData}
                    statusRoleDataState={this.state.statusRoleDataState}
                    bulkAssignDetail={this.state.bulkAssignDetail}
                />

                {this.state.confirmationPopupState == true ?
                    <ConfirmationPopup
                        cancelLoanConfirm={this.cancelLoanConfirm.bind(this)}
                        confirmationText={this.state.confirmationText}
                    />
                    : ""}
            </div>
        )
    }

    bulkAssignChange(e, type) {
        let bulkAssignDetail = Object.assign({}, this.state.bulkAssignDetail)
        if (type == 'status') {
            bulkAssignDetail.status = e.target.value
            this.state.userStatusData.length > 0 ?
                this.state.userStatusData.map(data => {
                    if (data.status == e.target.value) {
                        alreadyAssigned = data.assignIds
                    }
                })
                : null
            this.roleDataArray(this.state.roleData)
        } else if (type == 'statusAgent') {
            bulkAssignDetail.statusAgent = []
            this.setState({ statusRoleDataState: e })
            e.length > 0 ? e.map(data => {
                if (data.value === true) {
                    bulkAssignDetail.statusAgent.push(data.label)
                }
            }) : null
        }
        else if (type == 'overdueLimit') {
            bulkAssignDetail.overdueLimit = e.target.value
        }
        this.setState({ bulkAssignDetail })
    }

    bulkAssignHandler(type) {
        if (this.state.bulkAssignDetail.status != '' && this.state.bulkAssignDetail.overdueLimit != '' && this.state.bulkAssignDetail.statusAgent.length > 0) {
            this.setState({
                confirmationPopupState: true,
                confirmationText: "Are you sure you want to assign ?"
            })
        } else {
            this.setState({
                popupState: true,
                popupStatus: "Please select status and agent and Overdue Limit"
            })
            this.removePopup()
        }
        this.setState({ btnClickType: type })
    }

    removePopup() {
        setTimeout(function () {
            this.setState({ popupState: false });
        }.bind(this), 5000)
    }

    closePopup() {
        this.setState({ popupState: false });
    }


    cancelLoanConfirm(confirmType) {
        if (confirmType == 'yes') {
            this.statusWiseAssign()
        } else if (confirmType == 'no') {
            this.setState({
                confirmationPopupState: false
            })
        }
    }

    statusWiseAssign() {
        const { admin } = this.props
        saveAssigForCollectionApi(this.state.bulkAssignDetail, admin,
            (callBack) => {
                if (callBack == 'success') {
                    this.setState({
                        popupState: true,
                        popupStatus: "Application assigned successfully!",
                        bulkAssignDetail: {
                            status: '',
                            statusAgent: [],
                            overdueLimit:''
                        }
                    })
                    alreadyAssigned = []
                    this.removePopup()
                    this.roleDataArray(this.state.roleData)
                    this.getAssignedByStatus()
                } else {
                    this.setState({
                        popupState: true,
                        popupStatus: "Please try again"
                    })
                    this.removePopup()
                }
                this.setState({
                    confirmationPopupState: false
                })
            })
    }

}

export default AssignByStatus;