import React, { useEffect, useState } from "react";
import "./../compo.scss";
import List from "../../maketingimages/list.png";
import Filter from "../../maketingimages/filter.png";
import communication from "../../maketingimages/communication.png";
import { getBranchStatsByPartnerId } from "../actioncreator";
import { Pagination } from "@mui/material";
import {
  formatDate,
  monthStartDate,
  reverseFormat,
  todayDate,
} from "../utils/utils";
import BulkCommunicationModal from "./modals/bulkcommbyeventName";

const LeadBreakDown = ({
  aggregator,
  admin,
  showBulkCommunicationEvent,
  showBulkCommunicationLeadName }) => {
  const [bulkPopup, setBulkPopup] = useState(false);
  const [detailsData, setDetails] = useState(false);
  const [leads, setLeads] = useState([]);
  const [payload, setPayload] = useState({
    partnerId: aggregator.partnerId,
    fromDate: monthStartDate(),
    toDate: todayDate(),
  });
  const handleChange = (e) => {
    console.log(e.target.type);

    setPayload({
      ...payload,
      [e.target.name]:
        e.target.type === "date" ? formatDate(e.target.value) : e.target.value,
    });

  };

  const handleRefr = (item) => {
    item = item || {};
    setDetails(item)
    setBulkPopup(true)
  };

  const getBranchStats = () => {
    getBranchStatsByPartnerId(
      payload,
      (callback) => {
        setLeads(callback);
      },
      (error) => {
        console.log(error);
      }
    );
  };
  const close = () => {
    setBulkPopup(false)
  }
  useEffect(() => {
    getBranchStats();
  }, []);
  return (
    <>
      {bulkPopup ?
        <BulkCommunicationModal
          detailsData={detailsData}
          admin={admin}
          aggregator={aggregator}
          payload={payload}
          showBulkCommunicationEvent={showBulkCommunicationEvent}
          showBulkCommunicationLeadName={showBulkCommunicationLeadName}
          setShowBulkcommunication={close}
        />
        : ""}

      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-2 col-xs-12">
            <img src={Filter} width={20} height={20} alt="" />
            <span className="ms-3 filterHeaders">Filter</span>
            <select
              name=""
              id=""
              value={payload.filter}
              className="form-select"
              style={{ width: '100%', padding: '7px' }}
            >
              <option value="daily">Daily</option>
            </select>
          </div>
          <div style={{ position: 'absolute', marginLeft: '15.5%', marginTop: '30px' }}>::</div>
          <div className="col-sm-2 col-xs-12">
            From
            <input
              type="date"
              id="startDate"
              placeholder="DD/MM/YY"
              name="fromDate"
              value={reverseFormat(payload.fromDate)}
              onChange={handleChange}
              className=" form-control"
              style={{
                border: "1px solid gray",
                borderRadius: "6px",
                padding: "10px",
              }}
            />

          </div>
          <div style={{ position: 'absolute', marginLeft: '31%', marginTop: '30px' }}>-</div>

          <div className="col-sm-2 col-xs-12">
            <div className="filterHeaders" style={{ marginTop: '4px' }}>to</div>
            <input
              type="date"
              id="endDate"
              name="toDate"
              max={new Date()}
              value={reverseFormat(payload.toDate)}
              onChange={handleChange}
              placeholder="DD/MM/YY"
              className=" form-control"
              style={{
                border: "1px solid gray",
                borderRadius: "6px",
                padding: "10px",
              }}
            />
          </div>
          <div className="col-sm-2 col-xs-12">
            <button
              className="btn btn-primary mt-4 "
              style={{
                color: "#2B78FF",
                borderColor: "#2B78FF",
                backgroundColor: "white",
                marginTop: '27px',
                padding: '5px',
                borderRadius: '5px'
              }}
              onClick={() => getBranchStats()}
            >
              Apply Filter
            </button>
          </div>

        </div>
        <hr />

        <div className="mt-5 d-inline-flex" style={{ display: 'inline-flex' }}>
          <img src={List} alt="" width={18} height={18} style={{ marginTop: '10px' }} />&nbsp;&nbsp;
          <h4 className="ms-3 leadBreakDown_Header">
            Lead Breakdown List&nbsp;
            <span className="branch_Social_text">
              {"(Branch/Social Media)"}
            </span>
          </h4>
        </div>
        <div className='credit-table'>
          <table>
            <thead style={{ background: '#b9d8f9', color: '#000' }}>
              <tr className="thead-class">
                <th className="">Event Name</th>
                <th className="">Lead Counts</th>
                <th className="">Bulk Communication</th>
              </tr>
            </thead>
            <tbody>
              {leads.length > 0 &&
                leads.map((item) => {
                  return (
                    <tr style={{ borderBottom: "none" }}>
                      <td scope="col">
                        <span id="comment">
                          <b>{item.eventName}</b>
                        </span>
                      </td>
                      <td scope="col">
                        <span id="comment">
                          <b>{item.count}</b>
                        </span>
                      </td>
                      <td>
                        <img
                          onClick={() => {
                            handleRefr(item);
                          }}
                          className="hoverCusrsor"
                          src={communication}
                          width={30}
                          height={30}
                          alt=""
                        />
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>

    </>
  );
};

export default LeadBreakDown;
