import React from "react";
import { pushNotification } from "../../AdminActionCreator.component";
import PopUp from "../../../../presentationals/Popup/Popup.component";

class Comments extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            comment: '',
            popupState: false,
            popupStatus: '',
        }
    }
    messageHandler(e, body) {
        this.setState({ comment: e.target.value })
    }
    sendHandler() {
        const { admin, allDetail } = this.props
        if (this.state.comment !== '') {
            pushNotification(allDetail, this.state.comment, '', admin.emailId, "comment", '', '', '', '', '',
                (callBack) => {
                    this.setState({
                        popupState: true,
                        popupStatus: 'Comment Saved Successfully',
                    })
                    this.removePopup();
                })
        } else {
            this.setState({
                popupState: true,
                popupStatus: 'Please enter comment!',
            })
            this.removePopup();
        }
    }
    removePopup() {
        setTimeout(function () {
            this.setState({ popupState: false });
        }.bind(this), 5000)
    }
    closePopup(){
        this.setState({popupState:false})
    }
    render() {
        return (
            <>
            <div className="container-fluid details-new">
                <div className="sms-box">

                    <div>
                        <label>Comment</label>
                        <textarea onChange={e => this.messageHandler(e, "body")} style={{ background: '#F1F7FF', color: '#000', borderRadius: '6px', border: 'none', marginTop: '20px', minHeight: '150px' }} className='form-control' placeholder='Enter Comment' />
                    </div>

                    <div>
                        <button onClick={e => this.sendHandler('notification')} className='btn btn-primary' style={{ marginTop: '20px', marginLeft: '10px' }}>Send </button>
                    </div>

                </div >
            </div>
            {this.state.popupState == true ?
                    <PopUp
                        closePopup={this.closePopup.bind(this)}
                        popupStatus={this.state.popupStatus}
                    />
                    : ""}
            </>
        )
    }

}
export default Comments