import React, { useEffect, useState } from "react";
import Popup from '../../../../presentationals/Popup/Popup.component';
import { saveBusinessPlanData,updateBusinessPlanData } from "../targetactioncreator";
const validationRules = [
    {
        key: 'numberOfApplicationTarget',
        label: 'Number Of Application Target',
        required: true
    },
    {
        key: 'numberOfApprovalTarget',
        label: 'Number Of Approval Target',
        required: true
    },
    {
        key: 'numberOfDisbursementTarget',
        label: 'Number Of Disbursement Target',
        required: true
    },
    {
        key: 'aumAmountTarget',
        label: 'AUM Amount Target',
        required: true
    },
    {
        key: 'disbursementAmountTarget',
        label: 'Disbursement Amount Target',
        required: true
    },
    {
        key: 'averageTicketSize3MonthsTarget',
        label: 'Average Ticket Size 3 Months Target',
        required: true
    },
    {
        key: 'averageTicketSizeMoreThan3MonthsTarget',
        label: 'Average Ticket Size More Than 3 Months Target',
        required: true
    },
    {
        key: 'averageTicketSizeTarget',
        label: 'Average Ticket Size Target',
        required: true
    },
    {
        key: 'month',
        label: 'Date',
        required: true
    },
    {
        key: 'numberOfApplicationAchieved',
        label: 'Number Of Application Achieved',
        required: true
    },
    {
        key: 'numberOfApprovalAchieved',
        label: 'Number Of Approval Achieved',
        required: true
    },
    {
        key: 'numberOfDisbursementAchieved',
        label: 'Number Of Disbursement Achieved',
        required: true
    },
    {
        key: 'aumAmountAchieved',
        label: 'AUM Amount Achieved',
        required: true
    },
    {
        key: 'disbursementAmountAchieved',
        label: 'Disbursement Amount Achieved',
        required: true
    },
    {
        key: 'averageTicketSize3MonthsAchieved',
        label: 'Average Ticket Size 3 Months Achieved',
        required: true
    },
    {
        key: 'averageTicketSizeMoreThan3MonthsAchieved',
        label: 'Average Ticket Size More Than 3 Months Achieved',
        required: true
    },
    {
        key: 'averageTicketSizeAchieved',
        label: 'Average Ticket Size Achieved',
        required: true
    },
    {
        key: 'revenueExpected',
        label: 'Revenue Expected',
        required: true
    },
    {
        key: 'revenueAchieved',
        label: 'Revenue Achieved',
        required: true
    },
    {
        key: 'collectionExpected',
        label: 'Collection Expected',
        required: true
    },
    {
        key: 'collectionAchieved',
        label: 'Collection Achieved',
        required: true
    }
];
const EditExpence = ({ editData }) => {
    const InputLeftdata = [
        {
            name: "numberOfApplicationTarget",
            value: "Number Of Application Target"
        },
        {
            name: "numberOfApprovalTarget",
            value: "Number Of Approval Target"
        },
        {
            name: "numberOfDisbursementTarget",
            value: "Number Of Disbursement Target"
        },
        {
            name: "aumAmountTarget",
            value: "AUM Amount Target"
        },
        {
            name: "disbursementAmountTarget",
            value: "Disbursement Amount Target"
        },
        {
            name: "averageTicketSize3MonthsTarget",
            value: "Average Ticket Size 3 Months Target"
        },
        {
            name: "averageTicketSizeMoreThan3MonthsTarget",
            value: "Average Ticket Size More Than 3 Months Target"
        },
        {
            name: "averageTicketSizeTarget",
            value: "Average Ticket Size Target"
        },
        {
            name: "month",
            value: "Date"
        },
        {
            name: "numberOfApplicationAchieved",
            value: "Number Of Application Achieved"
        },
        {
            name: "numberOfApprovalAchieved",
            value: "Number Of Approval Achieved"
        },
    ];
    const inputRightData = [
        {
            name: "numberOfDisbursementAchieved",
            value: "Number Of Disbursement Achieved"
        },
        {
            name: "aumAmountAchieved",
            value: "AUM Amount Achieved"
        },
        {
            name: "disbursementAmountAchieved",
            value: "Disbursement Amount Achieved"
        },
        {
            name: "averageTicketSize3MonthsAchieved",
            value: "Average Ticket Size 3 Months Achieved"
        },
        {
            name: "averageTicketSizeMoreThan3MonthsAchieved",
            value: "Average Ticket Size More Than 3 Months Achieved"
        },
        {
            name: "averageTicketSizeAchieved",
            value: "Average Ticket Size Achieved"
        },
        {
            name: "revenueExpected",
            value: "Revenue Expected"
        },
        {
            name: "revenueAchieved",
            value: "Revenue Achieved"
        },
        {
            name: "collectionExpected",
            value: "Collection Expected"
        },
        {
            name: "collectionAchieved",
            value: "Collection Achieved"
        }
    ]

    const [employeeData, setEmployeeData] = useState({
        numberOfApplicationTarget: '',
        numberOfApprovalTarget: '',
        numberOfDisbursementTarget: '',
        aumAmountTarget: '',
        disbursementAmountTarget: '',
        averageTicketSize3MonthsTarget: '',
        averageTicketSizeMoreThan3MonthsTarget: '',
        averageTicketSizeTarget: '',
        month: '',
        year: '',
        numberOfApplicationAchieved: '',
        numberOfApprovalAchieved: '',
        numberOfDisbursementAchieved: '',
        aumAmountAchieved: '',
        disbursementAmountAchieved: '',
        averageTicketSize3MonthsAchieved: '',
        averageTicketSizeMoreThan3MonthsAchieved: '',
        averageTicketSizeAchieved: '',
        revenueExpected: '',
        revenueAchieved: '',
        collectionExpected: '',
        collectionAchieved: ''
    });

    const [sidePopup, setPopup] = useState(false);
    const [Dates, setDates] = useState('');
    const [popupStatus, setPopupStatus] = useState('');
    const [errors, setErrors] = useState({});

    const closePopup = () => {
        setPopup(false);
    };

    const handleChange = (e) => {
        const { name, value, type } = e.target;
        if (name === 'Date') {
            const [beforehyphen, afterhyphen] = value.split('-')
            setEmployeeData((prevData) => ({
                ...prevData,
                month: afterhyphen,
                year: beforehyphen
            }));
            setDates(value)
        } else {
            setEmployeeData((prevData) => ({
                ...prevData,
                [name]: value,
            }));
        }
    };

    const validateForm = () => {
        let formErrors = {};
        let isValid = true;

        validationRules.forEach(rule => {
            if (!employeeData[rule.key]) {
                formErrors[rule.key] = `${rule.label} is required`;
                isValid = false;
            }
        });

        setErrors(formErrors);
        return isValid;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log("employeeData", employeeData)
        if (validateForm()) {
            console.log("Expense Data Saved", employeeData);
            if (editData && editData.id) {
                employeeData.id = editData.id;
                updateBusinessPlanData(employeeData, (callback) => {
                    if (callback.message === 'success') {
                        setPopup(true);
                        setPopupStatus('Data Saved Successfully!');
                    } else {
                        setPopup(true);
                        setPopupStatus('Please try again later!');
                    }
                    removePopup();
                })
            } else {

                saveBusinessPlanData(employeeData, (callback) => {
                    if (callback.message === 'success') {
                        setPopup(true);
                        setPopupStatus('Data Saved Successfully!');
                    } else {
                        setPopup(true);
                        setPopupStatus('Please try again later!');
                    }
                    removePopup();
                })
            }
            // saveEmpDataApi(employeeData, callback => {
            //     if (callback === 'success') {
            //         setPopup(true);
            //         setPopupStatus('Data Saved Successfully!');
            //     } else {
            //         setPopup(true);
            //         setPopupStatus('Please try again later!');
            //     }
            //     removePopup();
            // });
        }
    };

    const removePopup = () => {
        setTimeout(() => {
            closePopup();
        }, 5000);
    };

    useEffect(() => {
        if (editData.id) {
            setEmployeeData({
                numberOfApplicationTarget: editData.numberOfApplicationTarget || "",
                numberOfApprovalTarget: editData.numberOfApprovalTarget || "",
                numberOfDisbursementTarget: editData.numberOfDisbursementTarget || "",
                aumAmountTarget: editData.aumAmountTarget || "",
                disbursementAmountTarget: editData.disbursementAmountTarget || "",
                averageTicketSize3MonthsTarget: editData.averageTicketSize3MonthsTarget || "",
                averageTicketSizeMoreThan3MonthsTarget: editData.averageTicketSizeMoreThan3MonthsTarget || "",
                averageTicketSizeTarget: editData.averageTicketSizeTarget || "",
                month: editData.month || "",
                year: editData.year || "",

                numberOfApplicationAchieved: editData.numberOfApplicationAchieved || "",
                numberOfApprovalAchieved: editData.numberOfApprovalAchieved || "",
                numberOfDisbursementAchieved: editData.numberOfDisbursementAchieved || "",
                aumAmountAchieved: editData.aumAmountAchieved || "",
                disbursementAmountAchieved: editData.disbursementAmountAchieved || "",
                averageTicketSize3MonthsAchieved: editData.averageTicketSize3MonthsAchieved || "",
                averageTicketSizeMoreThan3MonthsAchieved: editData.averageTicketSizeMoreThan3MonthsAchieved || "",
                averageTicketSizeAchieved: editData.averageTicketSizeAchieved || "",

                revenueExpected: editData.revenueExpected || "",
                revenueAchieved: editData.revenueAchieved || "",
                collectionExpected: editData.collectionExpected || "",
                collectionAchieved: editData.collectionAchieved || "",

            });

            if (employeeData.month !== '' && employeeData.year !== '') {
                setDates(employeeData.year + '-' + employeeData.month)
            }
        }
    }, [editData]);



    return (
        <div className="container">
            {sidePopup && <Popup popupStatus={popupStatus} closePopup={closePopup} />}
            <div className="target-box" style={{ width: '100%' }}>
                <h3>Add/Update Business Plan</h3>
                <hr style={{ borderTop: '1px solid #000' }} />
                <form onSubmit={handleSubmit}>
                    <div className="row">
                        <div className="col-sm-6 col-xs-12">
                            {
                                InputLeftdata?.map((Value, index) => {
                                    return (
                                        <div style={{ display: 'flex', marginTop: '20px' }} key={index}>
                                            <div style={{ width: '50%' }}>
                                                <label style={{ marginTop: '10px' }}>{Value.value}</label>
                                            </div>
                                            <div style={{ width: '50%' }}>
                                                <input
                                                    style={{ background: '#fff', border: '1px solid' }}
                                                    className="form-control"
                                                    type={Value.value === 'Date' ? 'month' : "number"}
                                                    name={Value.value === 'Date' ? 'Date' : Value.name}
                                                    value={Value.value === 'Date' ? Dates : employeeData[Value.name]}
                                                    onChange={handleChange}
                                                />
                                                {errors[Value.name] && (
                                                    <span style={{ color: 'red', fontSize: '10px', marginTop: '5px' }}>{errors[Value.name]}</span>
                                                )}
                                            </div>
                                        </div>
                                    )
                                })
                            }

                        </div>
                        <div className="col-sm-6 col-xs-12">
                            {
                                inputRightData?.map((Value, index) => {
                                    return (
                                        <div style={{ display: 'flex', marginTop: '20px' }} key={index}>
                                            <div style={{ width: '50%' }}>
                                                <label style={{ marginTop: '10px' }}>{Value.value}</label>
                                            </div>
                                            <div style={{ width: '50%' }}>
                                                <input
                                                    style={{ background: '#fff', border: '1px solid' }}
                                                    className="form-control"
                                                    type="number"
                                                    name={Value.name}
                                                    value={employeeData[Value.name]}
                                                    onChange={handleChange}
                                                />
                                                {errors[Value.name] && (
                                                    <span style={{ color: 'red', fontSize: '10px', marginTop: '5px' }}>{errors[Value.name]}</span>
                                                )}
                                            </div>
                                        </div>
                                    )
                                })
                            }

                        </div>
                    </div>
                    <div className="text-center" style={{ marginTop: '40px' }}>
                        <button type="submit" className="btn btn-primary">Save Business Plan Data</button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default EditExpence;
