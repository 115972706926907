import React from 'react'
import Nodata from '../../../../images/nodata.png'

const Table = ({ tableMinPagination, tableMaxPagination, phocketTablePagination, msgData }) => (
    <div>
        {msgData && msgData ?
            <div>
                <div className='credit-table'>
                    <table>
                        <thead>
                            <tr className='thead-class'>
                                <th>Amount</th>
                                <th>Balance After Transaction</th>
                                <th>Type</th>
                                <th>Date</th>
                                <th>Description</th>
                                <th>Category</th>
                            </tr>
                        </thead>
                        <tbody>
                            {msgData && msgData ?
                                Object.entries(msgData).slice(tableMinPagination, tableMaxPagination).map((data, i) => {
                                    return (
                                        <tr key={i}>
                                            <td>{data[1].amount}</td>
                                            <td>{data[1].balanceAfterTransaction}</td>
                                            <td style={{ color: data[1].type === 'CREDIT' ? 'green' : 'red' }} >{data[1].type}</td>
                                            <td>{data[1].valueDate}</td>
                                            <td>{data[1].description}</td>
                                            <td>{data[1].secondLevelCategory}</td>

                                        </tr>
                                    )
                                })
                                : ""}

                        </tbody>
                    </table>
                </div>
                <div className="container-fluid">
                    <div className="row">
                        {/* <div className="col-sm-2 col-xs-12 no-padding"> <button type="button" className="btn btn-default fa fa-chevron-left btn-lg btn-block mage" id="backword" onClick={e => phocketTablePagination(msgData.overDueMsg, 'back')}><span className="fa fa-chevron-left" /></button></div> */}
                        <div className="col-sm-5 col-xs-12 no-padding"><button type="button" className="btn btn-default fa fa-chevron-left btn-lg btn-block   " style={{ background: '#BADBF9', borderRadius: '0' }} onClick={e => phocketTablePagination(msgData, 'back')}></button></div>
                        <div className="col-sm-2 col-xs-12  phoc text-center" >
                            {(tableMinPagination + 1) + ' - ' + (tableMaxPagination) + ' of ' + msgData.length}
                        </div>
                        <div className="col-sm-5 col-xs-12 no-padding" ><button type="button" className="btn btn-default fa fa-chevron-right btn-lg btn-block " o style={{ background: '#BADBF9', borderRadius: '0' }} onClick={e => phocketTablePagination(msgData, 'forward')}></button></div>
                        {/* <div className="col-sm-2 col-xs-12 no-padding"> <button type="button" className="btn btn-default fa fa-chevron-right btn-lg btn-block mage" id="forword" onClick={e => phocketTablePagination(msgData.salaryMsg, 'forward')}><span className="fa fa-chevron-right" /></button></div> </div> */}
                    </div>
                </div>
            </div>
            : <div className='col-sm-12 col-xs-12 text-center'><img src={Nodata} style={{ width: '30%', marginTop: '20px' }} /></div>}
    </div >
)
export default Table