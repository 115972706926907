import React, { useEffect, useState } from "react";
import { getConfigDataApi, saveMappingOfPolicy, getMappedDataApi, deleteMappingapi } from "../AdminActionCreator.component";

const MappingPolicy = ({ editData }) => {
    const [pointsData, setPointsData] = useState('')
    const [mappingData, setMappingData] = useState({})
    const [listingData, setListData] = useState('')
    const save = () => {
        saveMappingOfPolicy(mappingData, callback => {
            console.log(callback)
            if (callback === 'success') {
                getlisting()
            }
        })
    }
    useEffect(() => {
        getlisting()
        getData()
        if (editData && editData.policyId) {
            let changeData = Object.assign({}, mappingData)
            changeData.policyId = editData.policyId

            setMappingData(changeData)
        }
    }, [])
    const getData = () => {
        getConfigDataApi(1, 200, callback => {
            setPointsData(callback)

        })
    }
    const changehandler = (e, type) => {
        console.log(typeof (e.target.value))
        let changeData = Object.assign({}, mappingData)
        if (type !== 'mandatory') {
            changeData[type] = e.target.value
            setMappingData(changeData)
        }
        if (type === 'mandatory') {
            changeData[type] = JSON.parse(e.target.value)
            setMappingData(changeData)
        }
        console.log(changeData)
    }
    const getlisting = () => {
        getMappedDataApi(1, 200,editData.policyId, callback => {
            setListData(callback)
        })
    }
    const deleteData = (data) => {
        console.log(data.creditAttId)
        deleteMappingapi(data.creditAttId, callback => {
            console.log(callback)
            getlisting()
        })
    }
    return (
        <div className="container">
            <h3>Map Policy Points To Policy</h3>
            <>
                <div className="col-sm-4 col-xs-12">
                    <label>Policy Points</label>
                    <select className="form-control" onChange={(e) => changehandler(e, 'creditAttId')}>
                        <option value={''}>Select Policy Point</option>
                        {pointsData && pointsData.map((data, i) => {
                            if (data.creditAttId) {
                                return (
                                    <option value={data.creditAttId}>{data.name}</option>
                                )
                            }
                        })}

                    </select>
                </div>
                <div className="col-sm-4 col-xs-12">
                    <label>Mandatory ?</label>
                    <select className="form-control" onChange={(e) => changehandler(e, 'mandatory')}>
                        <option value={''}>Select Mandatory or Not</option>
                        <option value={true}>Yes</option>
                        <option value={false}>No</option>
                    </select>
                </div>
                <div className="col-sm-4 col-xs-12">
                    <label>Weightage</label>
                    <input className="form-control" type='number' placeholder="Weightage" onChange={(e) => changehandler(e, 'weightage')} />
                </div>
                <div className="text-center">
                    <button className="profile-save-btn" onClick={() => save()}> Save </button>
                </div>
            </>
            {listingData ?
                <>
                    <div className='credit-table'>
                        <table>
                            <thead style={{ background: '#dde9fa', color: '#000' }}>
                                <tr className='thead-class'>
                                    <th>Policy Name</th>
                                    <th>Credit Attribute Name</th>
                                    <th>Category</th>
                                    <th>Mandatory</th>
                                    <th>Weightage</th>
                                    <th>Delete</th>
                                </tr>
                            </thead>
                            <tbody>
                                {listingData && listingData.map((data, i) => {

                                    return (
                                        <tr key={i}>
                                            <td>{data.policyName}</td>
                                            <td>{data.creditAttName}</td>
                                            <td>{data.categoryName}</td>
                                            <td>{data.mandatory === true ? 'Yes' : 'No'}</td>
                                            <td>{data.weightage}</td>
                                            <td><i className="fa fa-trash" onClick={() => deleteData(data)}></i></td>
                                        </tr>
                                    )
                                })}

                            </tbody>
                        </table>
                    </div>

                </>
                : ""}
        </div>
    )
}
export default MappingPolicy