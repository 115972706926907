import React from 'react'
import ReactTable from "react-table";
import { filterCaseInsensitive } from '../../../../utils/commonUtility'

const List = ({ automationDetail, closeCouponPopup,
    getFilterValue, FilteredData, getPageValue, pageIndexToShow }) => (
    <div className="">

        <div className=" container-fluid dbl-border border-grey" style={{ overflowX: 'scroll', marginTop: '10px' }}>
            <div className="text-right-imp">


                <i className="fa fa-times" aria-hidden="true" style={{ fontSize: '18px', color: 'red', cursor: 'pointer', zIndex: 1, position: 'fixed', background: 'black' }} onClick={e => closeCouponPopup()}></i>

            </div>
            <div className="row">

                {automationDetail != null && automationDetail != '' && automationDetail.length > 0 ?
                    <div className='overflow-auto'>
                        <table className='phocket-table'>
                            <thead>

                                <tr>
                                    <th> Date</th>
                                    <th>Email Id</th>
                                    <th>Subject</th>
                                    <th>Mail Type</th>
                                </tr>

                            </thead>
                            <tbody>
                                {automationDetail && automationDetail.map((promo, i) => {
                                    return (
                                        <tr key={i}>
                                            <td>{promo.date}</td>
                                            <td>{promo.mails}</td>
                                            <td>{promo.subject}</td>
                                            <td>{promo.mailType}</td>


                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                    : ""}
            </div>
        </div>
    </div>
)

export default List;