import React, { Component } from 'react'

import { getFinDataDetails } from '../AdminActionCreator.component'
import { APIS } from '../../../../utils/api-factory'

import AnalysisNew from '../Analysis/NewMainPageAnalysis'
class Analysis extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pageViewState: 'msgPage',
            finbitTab: '',
            msgData: '',
            loaderState: false,
            deviceData: '',
            repeatUserData: false,
            checkAccessCrif: ''
        }
    }
    componentDidMount() {
        // console.log(this.props,'analysis')
        this.getFinBitDetails()
        const { allDetail, userDetail } = this.props;
        {
            userDetail.loanFrom === 'bazarBussiness' ?
                this.setState({ pageViewState: 'popUpCrif' })

                : ""
        }
        this.setState({ loaderState: true })
        fetch(APIS.USER_MSG_ANALYSIS + allDetail.userId)
            .then(json => json.json())
            .then(res => {
                this.setState({ msgData: res, loaderState: false })
            })
        fetch(APIS.GET_CRIF_ACCESS_CHECK + allDetail.userId)
            .then(json => json.json())
            .then(res => {
                this.setState({ checkAccessCrif: res })
            })
        // fetch(APIS.GET_USER_DEVICE_DATA + allDetail.userId)
        // .then(res => res.json())
        // .then(json => {

        //     this.setState({
        //         deviceData: json,
        //         loaderState: false
        //     })
        // })
        // if (allDetail != null && allDetail.appData != null && typeof allDetail.appData.deviceSMS === 'string' && allDetail.appData.deviceSMS != '') {
        //     this.setState({ rawMsgData: JSON.parse(allDetail.appData.deviceSMS) },
        //         () => {
        //             if (this.state.rawMsgData.length > 0) {
        //                 fetch(APIS.USER_MSG_ANALYSIS + allDetail.userId)
        //                     .then(json => json.json())
        //                     .then(res => {
        //                         this.setState({ msgData: res, loaderState: false })
        //                     })
        //             } else {
        //                 this.setState({ loaderState: false })
        //             }
        //         })
        // }
        fetch(APIS.REPEAT_USER_CHECK + allDetail.userId)
            .then(res => res.json())
            .then(json => {
                //   console.log(json.repeatUser)
                this.setState({ repeatUserData: json.repeatUser })
            })
    }
    getFinBitDetails() {

        getFinDataDetails(this.props.allDetail.userId, callback => {
            if (callback != 'no data') {
                this.setState({ finbitTab: callback })
            }
        })
    }

    ConfirmCrifDataGenerate(dataType) {
        if (dataType == 'yes') {
            this.setState({
                pageViewState: 'crifData'
            })

        } if (dataType == 'no') {
            this.setState({
                pageViewState: 'basicPage'
            })
        }
    }
    ConfirmCrifDataGeneratenew(dataType) {
        if (dataType == 'yes') {
            this.setState({
                pageViewState: 'crifDataNew'
            })

        } if (dataType == 'no') {
            this.setState({
                pageViewState: 'basicPage'
            })
        }
    }
    render() {
        const { allDetail, admin, msgData, loadarState, deviceData, deviceDataLoader, userDetail } = this.props;
        return (
            <div className="full-height container-fluid">



                <div className="row full-height">

                    <AnalysisNew
                        admin={admin}
                        allDetail={allDetail}
                        userDetails={userDetail}
                    />

                </div>
               
            </div>
        )
    }


    sendHandler(type) {
        this.setState({
            pageViewState: type,
        })
    }
}

export default Analysis;
