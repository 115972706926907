import React from 'react';

const AssignUserPopup = ({ closeTaskAssignPopup, taskAssignHandler, allAdminList,changeHandler,emailToAssign,
    emailIdForAssign }) => (
    <div className="">
        <div className="background-blur" onClick={e => closeTaskAssignPopup()}></div>
        <div className="popup-main small-popup container-fluid dbl-border">
            <h3 className="text-center blue-text">Select User</h3>
            <select className="form-control form-group" onChange={e => changeHandler(e)} value={emailToAssign}>
                <optgroup className="display-none">
                    <option value="">Select User</option>
                </optgroup>
                <optgroup label="Users">
                    {allAdminList != null && allAdminList.length > 0 ?
                        allAdminList.map((users, i) => {
                            return (
                                <option value={users.emailId} key={i}>{users.emailId}</option>
                            )
                        })
                        : null}
                </optgroup>
            </select>
            <div className='text-center'>
                <button className='profile-save-btn' onClick={(e)=>taskAssignHandler(e)}>Save</button>
            </div>
        </div>
    </div>
)

export default AssignUserPopup;