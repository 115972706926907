import React, { useState } from "react";
import Smspage from "./sms";
import EmailPage from "./email";
import Notification from './notification'
import { formatDate, monthStartDate, reverseFormat, todayDate } from "../../utils/utils";
import { communicateViaFilterApi } from "../../actioncreator";
import { FORM_STATUS } from "../../../../../../utils/Constant";
import Loader from '../../../../../presentationals/Loader/Loader.component'
const CommunicateViaSelection = ({ allStatus, admin, agre }) => {
    const [commTab, setCommunicationPage] = useState('sms')
    const [communicateViaFilter, setcommunicateViaFilter] = useState('')
    const [status, setstatus] = useState('')
    const [userIdList, setuserIdList] = useState([])
    const [loaderState, setloaderState] = useState(false)

    const [payload, setPayload] = useState({
        fromDate: monthStartDate(),
        toDate: todayDate(),
    });
    const communicationTab = (type) => {
        setCommunicationPage(type)
    }
    const handleChange = (e) => {

        setPayload({
            ...payload,
            [e.target.name]:
                e.target.type === "date" ? formatDate(e.target.value) : e.target.value,
        });

    };
    const applyFilter = () => {
        setloaderState(true)
        communicateViaFilterApi(payload, communicateViaFilter, status, callback => {
            setuserIdList(callback)
            setloaderState(false)

        })
    }
    const changeHandler = (e, type) => {
        if (type === 'communicateViaFilter') {
            setcommunicateViaFilter(e.target.value)
        }
        if (type === 'status') {
            setstatus(e.target.value)
        }
    }
    return (
        <>
            {loaderState ?
                <Loader />
                : ""}
            <div className="container-fluid marketing-css">

                <div className="row" style={{ marginTop: '20px' }}>
                    <div className="col-sm-3 col-xs-12">
                        <label className="form-label">Communicate Via </label>
                        <select className="form-select" onChange={(e) => changeHandler(e, 'communicateViaFilter')} >
                            <option value="" >Select From Below Options</option>
                            <option value={'formStatus'}>Form Status</option>
                            <option value={'loanStatus'}>Loan Status</option>
                            <option value={'newLead'}>New Leads</option>
                            <option value={'repeat'}>Repeat Leads</option>
                        </select>
                    </div>
                    {communicateViaFilter !== 'formStatus' ?
                        <div className="col-sm-3 col-xs-12">
                            <label className="form-label">Form Status</label>
                            <select className="form-select" onChange={(e) => changeHandler(e, 'status')}  >
                                <option value="" >Select From Below Options</option>
                                <option value="Address">Address</option>
                                <option value="Basic">Basic</option>
                                <option value="Bank">Bank</option>
                                <option value="Documents">Documents</option>
                                <option value="NotApproved">Not Approved</option>
                                <option value="Loan">Loan</option>
                                <option value="Thanks">Thanks</option>
                                <option value="Occupation">Occupation</option>
                                {/* {allStatus != null && allStatus.length > 0 ?
                                allStatus.map((status, i) => {
                                    return (
                                        <option value={status.statusId} key={i}>{status.adminStatus}</option>
                                    )
                                })
                                : null} */}
                            </select>
                        </div>
                        : <div className="col-sm-3 col-xs-12">
                            <label className="form-label">Form Status</label>
                            <select className="form-select" onChange={(e) => changeHandler(e, 'status')}  >
                                <option value="" >Select From Below Options</option>
                                <option value="Address">Address</option>
                                <option value="Basic">Basic</option>
                                <option value="Bank">Bank</option>
                                <option value="Documents">Documents</option>
                                <option value="NotApproved">Not Approved</option>
                                <option value="Loan">Loan</option>
                                <option value="Thanks">Thanks</option>
                                <option value="Occupation">Occupation</option>

                            </select>
                        </div>}
                    <div className="col-sm-3 col-xs-12">
                        <label className="form-label">Start Date</label>
                        <input type="date"
                            id="startDate"
                            placeholder="DD/MM/YY"
                            name="fromDate"
                            value={reverseFormat(payload.fromDate)}
                            onChange={handleChange}
                            className=" form-control"
                            style={{
                                border: "1px solid gray",
                                borderRadius: "6px",
                                padding: "10px"
                            }} />
                    </div>
                    <div className="col-sm-3 col-xs-12">
                        <label className="form-label">End Date </label>
                        <input
                            type="date"
                            id="endDate"
                            name="toDate"
                            max={new Date()}
                            value={reverseFormat(payload.toDate)}
                            onChange={handleChange}
                            placeholder="DD/MM/YY"
                            className=" form-control"
                            style={{
                                border: "1px solid gray",
                                borderRadius: "6px",
                                padding: "10px",
                            }}
                        />
                    </div>

                </div>
                <div className="text-center">
                    <button className="btn btn-primary" style={{ marginTop: '20px', background: '#2B78FF' }} onClick={() => applyFilter()}>Apply</button>
                    {
                        userIdList && userIdList.length > 0 ? <div><h5>Total Found: <b>{userIdList.length}</b></h5></div> : ""
                    }                </div>
                <hr />
                <div className="" style={{ marginTop: '30px' }}>
                    <div style={{ marginLeft: '10px', marginBottom: '10px' }}>
                        Bulk Communicate Via
                    </div>
                    <div onClick={() => communicationTab('email')} className={commTab === 'email' ? "col-sm-2 col-xs-4 communication-tab-active-left" : "col-sm-2 col-xs-4 communication-tab-left"}>Email</div>
                    <div onClick={() => communicationTab('sms')} className={commTab === 'sms' ? "col-sm-2 col-xs-4 communication-tab-active-center" : "col-sm-2 col-xs-4 communication-tab-center"}>SMS</div>
                    <div onClick={() => communicationTab('notification')} className={commTab === 'notification' ? "col-sm-2 col-xs-4 communication-tab-active-right" : "col-sm-2 col-xs-4 communication-tab-right"}>Notification</div>
                </div>
                {commTab === 'sms' ?
                    <Smspage userIdList={userIdList} admin={admin} payload={payload} communicateViaFilter={communicateViaFilter} status={status} />
                    : ""}
                {commTab === 'email' ?
                    <EmailPage userIdList={userIdList} admin={admin} payload={payload} communicateViaFilter={communicateViaFilter} status={status} />
                    : ""}

                {commTab === 'notification' ?
                    <Notification userIdList={userIdList} admin={admin} payload={payload} communicateViaFilter={communicateViaFilter} status={status} />
                    : ""}
            </div>
        </>

    )
}
export default CommunicateViaSelection