import React from 'react';
import DatePicker from 'react-datepicker';
import { ROLE, STATE_COLOR } from '../../../../utils/Constant'
import DotLoader from '../../Loader/layerLoader.component'

const PhocketCustomerDetails = ({ userCommentChange, userDetailHandler, userViewHandler, admin,
    allUserDetail, statusHandler, newDataBackground, todaysApplicationNo, isDeleteUser,
    allStatus, loadarState, markAsImportant, paginationHandler, phocketTablePagination,
    columnHide, deleteUserHandler, userAssignPopup, searchByDate, commonSearch, nashHandler,
    showRepeateApplications, followUpStatus, commonSearchHandler, tableMinPagination,
    searchByDateShow, nachCheckbox, markImportant, tableMaxPagination, showWhatsappMsg,
    userCommentHandler, pushToRetraHandler, chechRetraHandler, checkedForRetraArray,
    singleLeadPushHandler, dataFilter }) => (
        <div className="container-fluid">
            <div className="row form-group">
                <div className="col-sm-4 col-xs-12">
                    {admin.rolelist != undefined && admin.rolelist.indexOf(ROLE.Lead_Count) >= 0 ?
                        <div className="btn-group">
                            {todaysApplicationNo != null ?
                                <button className="btn-bg-new" onClick={e => showRepeateApplications()}><strong>REPEAT APPLICATIONS- {todaysApplicationNo.repeatUserCount}</strong></button>
                                : ""}
                            {todaysApplicationNo != null ?
                                <button className="btn-bg-new" onClick={e => paginationHandler('all')}><strong>NEW APPLICATIONS- {todaysApplicationNo.newUserCount}</strong></button>
                                : ""}
                        </div> : null}
                </div>
                {admin != null && admin.nbfcName == null || admin.nbfcName == "" ?
                    <div className="col-sm-2 col-xs-12">
                        <select className="form-control " value={followUpStatus} onChange={(e) => statusHandler(e)}>
                            <optgroup className="display-none">
                                <option value="">Select Status</option>
                            </optgroup>
                            <optgroup label="Status">
                                {allStatus != null && allStatus != undefined && allStatus != '' ?
                                    allStatus.map((status, i) => {
                                        return (
                                            <option value={status.statusId} key={i}>{status.adminStatus}</option>
                                        );
                                    }) : ""}
                            </optgroup>
                        </select>
                    </div>
                    : null}
                {admin != null && admin.nbfcName == null || admin.nbfcName == "" ?
                    <div className="col-sm-2 col-xs-12">
                        <DatePicker
                            value={searchByDate}
                            selected={searchByDateShow}
                            onChange={e => dataFilter(e, "date")}
                            className="form-control "
                            dateFormat="DD/MM/YYYY"
                            placeholderText="Filter By Date"
                        />
                    </div>
                    : null}
                <div className="col-sm-2 col-xs-12">
                    <input type="text" className="form-control" onChange={e => commonSearchHandler(e)} value={commonSearch} placeholder="Name, State or device" />
                </div>
                <div className="col-sm-2 col-xs-12 btn-group">
                    <button className="btn-bg-new center-block margin-left-25px " onClick={e => paginationHandler('refresh')}>Refresh</button>
                </div>



            </div>


            <div className="row text-center">
                <div className="col-xs-12 no-padding">
                    {loadarState == false ? allUserDetail != null && allUserDetail != '' && allUserDetail.length > 0 ?
                        <div>
                            <div className="overflow-x-auto">
                                <table className="phocket-table">
                                    <thead>
                                        <tr>
                                            {/* <th></th> */}
                                            {admin.rolelist != undefined ? admin.rolelist.indexOf(ROLE.Manual_Lead_Assignment) >= 0  ? <th>Assign To</th> : null:""}
                                            {admin.rolelist != undefined ? admin.rolelist.indexOf(ROLE.VIEW) >= 0 ?
 <th>Details</th>:"":""}
                                            {admin.rolelist != undefined ? admin.rolelist.indexOf(ROLE.Birds_Eye_View) >= 0 ?
<th>View</th>:"":""}
                                            {markImportant ? <th><i className="fa fa-star" /></th> : null}
                                            {nachCheckbox ? <th>Nach</th> : null}
                                            <th>Comment</th>
                                            <th>eSign</th>
                                            <th><i className="fa fa-whatsapp whats-app-approval whatsapp-icon" /></th>
                                            <th>Application Id</th>
                                            <th>Loan Id</th>
                                            <th>Name</th>
                                            <th>Mobile No.</th>
                                            <th>Apply Date</th>
                                            <th>Status</th>
                                            <th>Amount</th>
                                            <th>Installment</th>
                                            <th>Tenure</th>
                                            <th>Reason</th>
                                            <th>State</th>
                                            <th>IVR Status</th>
                                            <th>Device</th>
                                            {admin.rolelist != undefined && admin.rolelist.indexOf(ROLE.ACTION) >= 0 ?
                                                <th>Push Retra</th>
                                                : null}
                                            {isDeleteUser ? <th>Delete User</th> : null}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {allUserDetail.slice(tableMinPagination, tableMaxPagination).map((data, i) => {
                                            return (
                                                <tr key={i}
                                                    // style={{
                                                    //     backgroundColor: newDataBackground != undefined ? data.loanId == newDataBackground.id ? newDataBackground.color : "" : "",
                                                    //     background: data.loginDevice != '' && data.loginDevice != null ?
                                                    //         data.state == 'Rajasthan' ? STATE_COLOR.RAJASTHAN
                                                    //             : data.state == 'West Bengal' ? STATE_COLOR.WEST_BENGAL
                                                    //                 : data.state == 'Maharashtra' ? STATE_COLOR.MAHARASHTRA
                                                    //                     : data.state == 'Karnataka' ? STATE_COLOR.KARNATAKA
                                                    //                         : "" : STATE_COLOR.LOAN_ADDA,
                                                    // }}
                                                >
                                                    {/* <td>
                                                        <input className="phocket-checkbox" checked={checkedForRetraArray.includes(data.loanId) ? true : false} name="bulkStatusChange" type="checkbox" id={"checkedForRetra" + data.loanId} onChange={(e) => chechRetraHandler(e, data)} />
                                                        <label htmlFor={"checkedForRetra" + data.loanId}><span></span></label>
                                                    </td> */}
                                                    {admin.rolelist != undefined ? admin.rolelist.indexOf(ROLE.Manual_Lead_Assignment) >= 0 ?
                                                        <td>
                                                            <a className="pointer" onClick={e => userAssignPopup(data.loanId, data.assignUser)}>{data.assignUser != null ? data.assignUser : 'UnAssigned'}</a>
                                                        </td> : null:""}
                                                    <td>
                                                        {admin.rolelist != undefined ? admin.rolelist.indexOf(ROLE.VIEW) >= 0 ?
                                                            <button className="background-none-btn" onClick={e => userDetailHandler(data)}><span className="fa fa-info" /></button>
                                                            : "" : ""}
                                                    </td>
                                                    {admin.rolelist != undefined ? admin.rolelist.indexOf(ROLE.Birds_Eye_View) >= 0 ?

                                                    <td>
                                                        <button className="background-none-btn" onClick={e => userViewHandler(data)}><span className="fa fa-eye" /></button>
                                                    </td>
                                                    :"":""}
                                                    {markImportant ? <td>
                                                        {data.important == true ?
                                                            <span className="fa fa-star orange-text" onClick={e => markAsImportant(data, false)} />
                                                            :
                                                            <span className="fa fa-star-o" onClick={e => markAsImportant(data, true)} />
                                                        }
                                                    </td> : null}
                                                    {nachCheckbox ? <td>
                                                        <input type="checkbox" onChange={(e) => nashHandler(e, data)} checked={data.nachStatus} />
                                                    </td> : null}
                                                    <td>
                                                        <div className="display-flex">
                                                            <input type="text" className="app-comment-box" id={"comment" + data.loanId} onChange={e => userCommentChange(e)} placeholder="type here..." />
                                                            <span className="fa fa-save loan-id-save-btn" onClick={e => userCommentHandler(data)} />
                                                        </div>
                                                    </td>
                                                    <td>{data.eSignStatus != undefined ? data.eSignStatus ? <span>&#10003;</span> : <span>&#10005;</span> : null}</td>
                                                    <td>
                                                        <span className={"fa fa-whatsapp whats-app-approval relative_position whatsapp-icon" + (data.whatAppUnreadCount > 0 ? " fa-lg pointer" : '')} onClick={e => showWhatsappMsg(data)}>
                                                            <a className="blink">{data.whatAppUnreadCount > 0 ? <div className="not-count-circle" >{data.whatAppUnreadCount}</div> : null}</a>
                                                        </span>
                                                    </td>
                                                    <td>{data.applicationId}</td>
                                                    <td>{data.applicationLoanId}</td>
                                                    <td style={{whiteSpace:'break-spaces'}}>{data.userName}</td>
                                                    <td>{data.mobileNumber}</td>
                                                    <td>{data.loanApplyDate != null ? data.loanApplyDate.split(" ")[0] : null}</td>
                                                    <td className="light-dark-background">{data.applicationStatus}</td>
                                                    <td>{data.loanAmount}</td>
                                                    <td>{data.loanEmi}</td>
                                                    <td>{data.loanDuration}</td>
                                                    <td>{data.loanReason}</td>
                                                    <td>{data.state}</td>
                                                    <td>{data.ivrStatus == 1 ? 'Confirmed' : data.ivrStatus == 2 ? 'Need Call' : null}</td>
                                                    <td>{data.loginDevice}</td>
                                                    {admin.rolelist != undefined && admin.rolelist.indexOf(ROLE.ACTION) >= 0 ?
                                                        <td><button className="background-none-btn" onClick={e => singleLeadPushHandler(data)}><span className="fa fa-rub" /></button></td>
                                                        : null}
                                                    {isDeleteUser ?
                                                        <td><button className="background-none-btn" onClick={e => deleteUserHandler(data.userId)}><span className="fa fa-trash" /></button></td>
                                                        : null}
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>

                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-sm-2 col-xs-12 no-padding"> <button type="button" className="btn btn-default fa fa-chevron-left btn-lg btn-block mage" id="backword" onClick={e => paginationHandler('left')}><span className="fa fa-chevron-left" /></button></div>
                                    <div className="col-sm-3 col-xs-12 no-padding"><button type="button" className="btn btn-default fa fa-chevron-left btn-lg btn-block red  " onClick={e => phocketTablePagination(allUserDetail, 'back')}></button></div>
                                    <div className="col-sm-2 col-xs-12 btn-group phoc" >
                                        {checkedForRetraArray.length > 0 ?
                                            <button className="btn-bg-new center-block margin-left-25px " onClick={e => pushToRetraHandler()}>Push Leads</button>
                                            :
                                            <span>{(tableMinPagination + 1) + ' - ' + (tableMaxPagination) + ' of ' + allUserDetail.length}</span>
                                        }
                                    </div>
                                    <div className="col-sm-3 col-xs-12 no-padding" ><button type="button" className="btn btn-default fa fa-chevron-right btn-lg btn-block red" onClick={e => phocketTablePagination(allUserDetail, 'forward')}></button></div>
                                    <div className="col-sm-2 col-xs-12 no-padding"> <button type="button" className="btn btn-default fa fa-chevron-right btn-lg btn-block mage" id="forword" onClick={e => paginationHandler('right')}><span className="fa fa-chevron-right" /></button></div> </div>
                            </div>
                        </div>
                        : null : <DotLoader />}
                </div>
            </div>
        </div>
    )

export default PhocketCustomerDetails;
