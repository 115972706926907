import React from 'react'

const WebContent = ({ webContentChange, webContentHandler, webContent }) => (
    <div className="container-fluid phocket-milestone">
        <div className="max-width-500px">
            <div className="row">
                <h3 className="text-center blue-text">PHOCKET MILESTONE</h3>
            </div>
            <br />
            <div className="row form-group">
                <div className="col-sm-6 col-xs-12">
                    <label className="font-12px">LOAN PROCESSED</label>
                    <input type="text" className="form-control" onChange={e => webContentChange(e, 'loan')} id="loanProcessed" value={webContent.loanProcessed} />
                </div>

                <div className="col-sm-6 col-xs-12">
                    <label className="font-12px">AMOUNT DISBURSED</label>
                    <input type="text" className="form-control" onChange={e => webContentChange(e, 'amount')} id="amountDisbursed" value={webContent.amountDisbursed} />
                </div>
            </div>
            <div className="row form-group">
                <div className="col-sm-6 col-xs-12">
                    <label className="font-12px">APP DOWNLOADS</label>
                    <input type="text" className="form-control" onChange={e => webContentChange(e, 'apps')} id="appDownloads" value={webContent.appDownloads} />
                </div>

                <div className="col-sm-6 col-xs-12">
                    <label className="font-12px">REPEAT USER</label>
                    <input type="text" className="form-control" onChange={e => webContentChange(e, 'repeat')} id="repeatUser" value={webContent.repeatUser} />
                </div>
            </div>
            <div className="row text-center form-group">
                <button className="profile-save-btn" id="webContentSubmitBtn" onClick={e => webContentHandler()}>
                    <span className="fa fa-floppy-o"></span>&nbsp;&nbsp;Save
            </button>
            </div>
        </div>
    </div>
)

export default WebContent;