import React, { Component } from 'react'
import ChangePasswd from '../../../presentationals/Admin/Settings/ChangePassword.component'
import { TYPE } from '../../../../utils/Constant'
import Popup from '../../../presentationals/Popup/Popup.component';
import { APIS } from '../../../../utils/api-factory';
import localStorageUtil from '../../../../utils/localStorageUtil.js';
import { chngPasswordValidation } from '../../../../utils/validation'
import { changePasswordApi } from '../AdminActionCreator.component';

class ChangePassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            popupState: false,
            popupStatus: '',
            OldPassword: "",
            roleDetail: {
                emailIdState: '',
                nameState: '',
                permissionState: [],
                passwordState: "",
                roleState: ""
            },
            chngPasswordValidationMsg: ''
        }
    }

    render() {
        return (
            <div className="full-height">
                {this.state.popupState == true ?
                    <Popup
                        closePopup={this.closePopup.bind(this)}
                        popupStatus={this.state.popupStatus}
                    />
                    : ""}
                <ChangePasswd
                    changePass={this.changePass.bind(this)}
                    ChangePasswordHandler={this.ChangePasswordHandler.bind(this)}
                    viewPassHandler={this.viewPassHandler.bind(this)}
                    chngPasswordValidationMsg={this.state.chngPasswordValidationMsg}
                />
            </div>
        )
    }

    removePopup() {
        setTimeout(function () {
            this.setState({ popupState: false });
        }.bind(this), 5000)
    }

    closePopup() {
        this.setState({ popupState: false });
    }

    viewPassHandler(e, type) {
        if (type == 'viewPrevPass') {
            if (document.getElementById("oldPassword") != null) {
                var x = document.getElementById("oldPassword");
                if (x.type === "password") {
                    x.type = "text";
                } else {
                    x.type = "password";
                }
            }
        } else if (type == 'viewNewPass') {
            if (document.getElementById("newPassword") != null) {
                var x = document.getElementById("newPassword");
                if (x.type === "password") {
                    x.type = "text";
                } else {
                    x.type = "password";
                }
            }
        } else if (type == 'viewConfirmPass') {
            if (document.getElementById("confirmPassword") != null) {
                var x = document.getElementById("confirmPassword");
                if (x.type === "password") {
                    x.type = "text";
                } else {
                    x.type = "password";
                }
            }
        }

    }
    changePass(e, type) {
        let roleDetail = Object.assign({}, this.state.roleDetail);
        if (type == TYPE.OLD_PASS) {
            this.setState({ OldPassword: e.target.value })
        } else if (type == TYPE.NEW_PASS) {
            roleDetail.passwordState = e.target.value
        } else if (type == TYPE.CONFIRM_PASS) {
            this.setState({ confirmPassword: e.target.value })
        }
        this.setState({
            roleDetail,
            chngPasswordValidationMsg: ''
        })
    }
    ChangePasswordHandler() {
        
        const { admin } = this.props
        // if (localStorageUtil.getFromLocalStorage("password") == this.state.OldPassword) {
            if (this.state.roleDetail.passwordState === this.state.confirmPassword) {
                if (this.state.OldPassword != this.state.roleDetail.passwordState) {
                    let chngPasswordValidate = chngPasswordValidation(
                        this.state.roleDetail
                    )
                    if (chngPasswordValidate.status) {
                        this.setState({
                            chngPasswordValidationMsg: chngPasswordValidate,
                        })
                        // fetch(APIS.CHANGE_ADMIN_PASSWORD + admin.emailId + '&password=' + this.state.confirmPassword)
                        //     .then(res => res.text())
                        //     .then(text => {
                            changePasswordApi(admin.emailId,this.state.confirmPassword,this.state.OldPassword,(callback)=>{
                                // console.log(callback)
                                if (callback == "success") {
                                    this.setState({
                                        popupState: true,
                                        popupStatus: 'Password Changed Successfully',
                                    })
                                    this.removePopup();
                                    setTimeout(() => {
                                        this.props.signOutHandler()
                                    }, 1500);
                                }else{
                                    this.setState({
                                        popupState: true,
                                        popupStatus: 'Please try again later',
                                    })
                                    this.removePopup();
                                }
                            })
                                // if (text == "success") {
                                //     this.setState({
                                //         popupState: true,
                                //         popupStatus: 'Password Changed Successfully',
                                //     })
                                //     this.removePopup();
                                //     setTimeout(() => {
                                //         this.props.signOutHandler()
                                //     }, 1500);
                                // }
                            
                    } else {
                        this.setState({
                            chngPasswordValidationMsg: chngPasswordValidate,
                        })
                    }
                } else {
                    this.setState({
                        popupState: true,
                        popupStatus: 'You can not set same password again',
                    })
                    this.removePopup();
                }
            } else {
                this.setState({
                    popupState: true,
                    popupStatus: 'your confirm password does not match with new password ',
                })
                this.removePopup();
            }
        }
    //      else {
    //         this.setState({
    //             popupState: true,
    //             popupStatus: 'Your Current Password is Incorrect',
    //         })
    //         this.removePopup();
    //     }
    // }
}

export default ChangePassword;