import React, { Component } from 'react'
import AddOutletsUi from '../../../presentationals/Admin/AdminCoupons/MapOutlets.component'
import {
    getMerchantOutLets, saveOutLet, getCouponDataForMerchants,
    getMappedOutletList, deleteMappedCoupon
} from './AddMerchentActionCreator.Component'
import Popup from '../../../presentationals/Popup/Popup.component'
import Confirmation from '../../../presentationals/Popup/ConfirmationPopup.component'

class AddOutlets extends Component {
    constructor(props) {
        super(props);
        this.state = {
            confirmationPopup: false,
            confirmationText: '',
            idForDelete: '',
            popupState: false,
            popupStatus: '',
            listingOfCategory: '',
            outLetAddressList: '',
            couponList: '',
            couponid: '',
            mappedList: '',
            merchantData: {
                merchantId: '',
                couponId: '',
                outletId: '',
                id: ''
            }
        }
    }

    render() {
        const { listingOfCategory } = this.props
        return (
            <div>
                {this.state.popupState ?
                    <Popup
                        popupStatus={this.state.popupStatus}
                        closePopup={this.closePopup.bind(this)}
                    />
                    : ""}
                <AddOutletsUi
                    outLetAddressList={this.state.outLetAddressList}
                    saveOutLetHandler={this.saveOutLetHandler.bind(this)}
                    popupCloseHandler={this.popupCloseHandler.bind(this)}
                    listingOfCategory={listingOfCategory}
                    getDataHandler={this.getDataHandler.bind(this)}
                    couponList={this.state.couponList}
                    mappedList={this.state.mappedList}
                    updateOutlet={this.updateOutlet.bind(this)}
                    merchantData={this.state.merchantData}
                    deleteMappedCoupon={this.deleteMappedCoupon.bind(this)}
                />
                {this.state.confirmationPopup == true ?
                    <Confirmation
                        cancelLoanConfirm={this.cancelLoanConfirm.bind(this)}
                        confirmationText={this.state.confirmationText} />
                    : ""}
            </div>
        )
    }

    cancelLoanConfirm(conType) {
        if (conType == 'yes') {
            deleteMappedCoupon(this.state.merchantData, (callBack) => {
                if (callBack == 'success') {
                    this.setState({
                        confirmationPopup: false,
                    })
                    getMappedOutletList(this.state.merchantData.merchantId, (callBack) => {
                        this.setState({ mappedList: callBack })
                    })
                }
            })
        } else if (conType == 'no') {
            this.setState({ confirmationPopup: false })
        }
    }

    deleteMappedCoupon(data) {
        let merchantData = Object.assign({}, this.state.merchantData);
        merchantData.couponId = data.couponid
        merchantData.outletId = data.outletid
        this.setState({
            idForDelete: data.categoryid,
            confirmationPopup: true,
            confirmationText: 'Are you sure to Delete ' + data.title,
            merchantData
        })

    }

    updateOutlet(data) {
        let merchantData = Object.assign({}, this.state.merchantData);
        merchantData.couponId = data.couponid
        merchantData.outletId = data.outletid
        merchantData.id = data.id
        this.setState({ merchantData })
    }

    popupCloseHandler() {
        this.setState({ popupStatevisible: false })
    }

    removePopup() {
        setTimeout(function () {
            this.setState({ popupState: false });
        }.bind(this), 5000)
    }

    closePopup() {
        this.setState({ popupState: false });
    }

    getDataHandler(e, type) {
        let merchantData = Object.assign({}, this.state.merchantData);
        if (type == 'selectMerchant') {
            merchantData.merchantId = e.target.value
            getMerchantOutLets(e.target.value, (callBack) => {
                this.setState({ outLetAddressList: callBack })
            })
            getCouponDataForMerchants(e.target.value, (callBack) => {
                this.setState({ couponList: callBack })
            })
            getMappedOutletList(e.target.value, (callBack) => {
                this.setState({ mappedList: callBack })
            })
        } else if (type == 'selectCoupon') {
            merchantData.couponId = e.target.value
        } else if (type == 'selectLocation') {
            merchantData.outletId = e.target.value
        }
        this.setState({ merchantData })
    }

    saveOutLetHandler() {
        if (this.state.merchantData.couponId != '' && this.state.merchantData.outletId != '') {
            saveOutLet(this.state.merchantData,
                (callBack => {
                    this.setState({
                        popupState: true,
                        popupStatus: 'Outlet Save Successfully',
                    })
                    this.removePopup()
                    getMappedOutletList(this.state.merchantData.merchantId, (callBack) => {
                        this.setState({ mappedList: callBack })
                    })
                })
            )
        } else {
            this.setState({
                popupState: true,
                popupStatus: 'Please select Coupon',
            })
            this.removePopup()
        }
    }
}

export default AddOutlets;
