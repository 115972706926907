import React, { Component } from 'react'
import Logs from '../../../presentationals/Admin/CustomerPages/Logs.component'
import Details from '../../../presentationals/Admin/CustomerPages/LoanApprovalPopup.component'
import ActivityPapup from './UserStatusActivity.container'
import Popup from '../../../presentationals/Popup/Popup.component';
import { getCommentAdmin } from '../AdminActionCreator.component'
import SendNotification from './CommentAndNotification.container'
import SendMail from './Mail.container'
import MessageUi from './SendMsg.container'
import WhatsappUi from './Whatsapp.container'
import { ROLE } from '../../../../utils/Constant'
import { APIS } from '../../../../utils/api-factory'
import Communication from './Communication.container';
import CommTab from './CommunicationNew/mainTabsCommunicate';
import LogsMain from './LogsMainPage';

var sortJsonArray = require('sort-json-array');

class MainDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            viewPageState: 'userDetails',
            detailPage: true,
            logsPage: false,
            activityPage: false,
            commentDataState: '',
            popupData: '',
            companyPoint: '',
            popupState: false,
            popupStatus: '',
            documentsData: ''
        }
    }


    render() {
        const { admin, allDetail, userDetails } = this.props
        return (
            <div className="eye-popup-menu">
                {this.state.popupState == true ?
                    <Popup
                        closePopup={this.closePopup.bind(this)}
                        popupStatus={this.state.popupStatus}
                    />
                    : ""}
                <div className="background-blur" onClick={(e) => this.close()}></div>
                <div className="popup-main container-fluid overflow-auto" style={{height:'659px'}}>
                    <a className="close" onClick={(e) => this.close()}>&times;</a>
                    <div className="row">
                        <div className="edit-profile-menu col-xs-12">
                            <div className="btn-group-tab">
                                <button className={this.state.viewPageState == 'userDetails' ? 'active' : null} onClick={(e) => this.sendHandler('userDetails')}>Details</button>
                                {admin.rolelist != undefined && admin.rolelist.indexOf(ROLE.COMMUNICATION) >= 0 ?
                                    <button className={this.state.viewPageState == 'mail' ? 'active' : null} onClick={(e) => this.sendHandler('mail')}>Communication</button>
                                    : null}
                                {/* {admin.rolelist != undefined && admin.rolelist.indexOf(ROLE.COMMUNICATION) >= 0 ?
                                    <button className={this.state.viewPageState == 'whatsapp' ? 'active' : null} onClick={(e) => this.sendHandler('whatsapp')}>Whatsapp</button>
                                    : null}
                                {admin.rolelist != undefined && admin.rolelist.indexOf(ROLE.COMMUNICATION) >= 0 ?
                                    <button className={this.state.viewPageState == 'msg' ? 'active' : null} onClick={(e) => this.sendHandler('msg')}>Msg</button>
                                    : null}
                                {admin.rolelist != undefined && admin.rolelist.indexOf(ROLE.COMMUNICATION) >= 0 ?
                                    <button className={this.state.viewPageState == 'notification' ? 'active' : null} onClick={(e) => this.sendHandler('notification')}>Comment and Notification</button>
                                    : null} */}
                                <button className={this.state.viewPageState == 'userLogs' ? 'active' : null} onClick={(e) => this.sendHandler('userLogs')}>Logs</button>
                                {/* <button className={this.state.viewPageState == 'activity' ? 'active' : null} onClick={(e) => this.sendHandler('activity')}>Activity</button> */}
                            </div>
                        </div>
                    </div>
                    {this.state.viewPageState == 'userDetails' ?
                        <Details
                            documentsData={this.state.documentsData}
                            viewPopupDetail={allDetail}
                        />
                        : this.state.viewPageState == 'userLogs' ?
                            <div className="row details-new" style={{marginTop:'-80px'}}>
                                <LogsMain
                                allDetail={allDetail}
                                    // communicationPopup={this.communicationPopup.bind(this)}
                                    // popupData={this.state.popupData}
                                    // commentDataState={this.state.commentDataState != '' && this.state.commentDataState != null ? sortJsonArray(this.state.commentDataState, 'date', 'des') : ""}
                                />
                            </div>
                            : this.state.viewPageState == 'activity' ?
                                <div className="row max-height-370px">
                                    <ActivityPapup
                                        allDetail={allDetail}
                                        userDetail={userDetails}
                                    />
                                </div>
                                : this.state.viewPageState === 'mail' ?
                                    <div className='details-new' style={{width:'80%',marginLeft:'auto',marginRight:'auto'}}>
                                        <CommTab admin={admin} userDetail={userDetails} allDetail={allDetail} />
                                    </div>
                                    // : this.state.viewPageState == 'mail' ?
                                    //     <SendMail
                                    //         getData={this.props.getData.bind(this)}
                                    //         admin={admin}
                                    //         allDetail={allDetail}
                                    //         userDetail={userDetails}
                                    //     />
                                    //     : this.state.viewPageState == 'notification' ?
                                    //         <SendNotification
                                    //             admin={admin}
                                    //             allDetail={allDetail}
                                    //         />
                                    //         : this.state.viewPageState == 'msg' ?
                                    //             <MessageUi
                                    //                 admin={admin}
                                    //                 allDetail={allDetail}
                                    //             />
                                    //             : this.state.viewPageState == 'whatsapp' ?
                                    //                 <WhatsappUi
                                    //                     getData={this.props.getData.bind(this)}
                                    //                     admin={admin}
                                    //                     userDetail={userDetails != undefined ? userDetails : this.props.leadManagementData != undefined ? this.props.leadManagementData : userDetails}
                                    //                     allDetail={allDetail}
                                    //                 />
                                    : ""}
                </div>
            </div>
        )
    }



    removePopup() {
        setTimeout(function () {
            this.setState({ popupState: false });
        }.bind(this), 5000)
    }

    closePopup() {
        this.setState({ popupState: false });
    }

    sendHandler(listType) {
        this.setState({ viewPageState: listType })
        // if (listType == 'userDetails') {
        //     this.setState({
        //         detailPage: true,
        //         logsPage: false,
        //         activityPage: false
        //     })
        //     document.getElementById("userDetails").classList.add("active")
        //     document.getElementById("userLogs").classList.remove("active")
        //     document.getElementById("userActivityPopup").classList.remove("active")
        // } else if (listType == 'userLogs') {
        //     this.setState({
        //         detailPage: false,
        //         logsPage: true,
        //         activityPage: false
        //     })
        //     document.getElementById("userDetails").classList.remove("active")
        //     document.getElementById("userLogs").classList.add("active")
        //     document.getElementById("userActivityPopup").classList.remove("active")
        // } else if (listType == 'activity') {
        //     this.setState({
        //         detailPage: false,
        //         logsPage: false,
        //         activityPage: true
        //     })
        //     document.getElementById("userDetails").classList.remove("active")
        //     document.getElementById("userLogs").classList.remove("active")
        //     document.getElementById("userActivityPopup").classList.add("active")
        // }
    }

    close() {
        this.props.getviewDetailPopupState(false)
    }

    componentDidMount() {
        const { allDetail } = this.props;
        getCommentAdmin(allDetail.userId,
            (callBack) => {
                this.setState({
                    commentDataState: callBack
                })
            })
        fetch(APIS.GET_USER_DOCUMENTS_BY_USER_ID + allDetail.userId)
            .then(res => res.json())
            .then(res => {
                this.setState({ documentsData: res, loaderState: false })
            })

    }

    communicationPopup(commentData) {
        this.setState({
            popupData: commentData
        })
    }
}

export default MainDetail;