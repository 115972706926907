import React, { Component } from 'react'
import UserLocation from '../../../presentationals/Admin/CustomerPages/UserLocationNew.component'
import Geocode from "react-geocode";
import DotLoader from '../../../presentationals/Loader/DotLoader.component'
var sortJsonArray = require('sort-json-array');
let paginationNumber = 5;
import { getRendomNumber } from '../../../../utils/commonUtility';
import { APIS } from '../../../../utils/api-factory'
import Loader from '../../../presentationals/Loader/DotLoader.component'

class ApplicantLocation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            location: [],
            loadarState: false,
            FilteredData: [],
            pageIndexToShow: 0,
            tableMinPagination: 0,
            tableMaxPagination: paginationNumber,
            finalData: [],
            allDetail: ''
        }
    }

    render() {
        return (
            <div>
               

                    <UserLocation
                    loadarState={this.state.loadarState}
                        location={sortJsonArray(this.state.location, 'date', 'des')}
                        getFilterValue={this.getFilterValue.bind(this)}
                        FilteredData={this.state.FilteredData}
                        getPageValue={this.getPageValue.bind(this)}
                        pageIndexToShow={this.state.pageIndexToShow}
                        tableMinPagination={this.state.tableMinPagination}
                        tableMaxPagination={this.state.tableMaxPagination}
                        tablePagination={this.tablePagination.bind(this)}
                        finalData={this.state.finalData}
                    />
                   {this.state.loadarState ?
                    <Loader />
                    : ""}

            </div>
        )
    }
    tablePagination(data, type) {
        if (type == 'back') {
            if (this.state.tableMinPagination >= paginationNumber) {
                this.setState({
                    tableMinPagination: this.state.tableMinPagination - paginationNumber,
                    tableMaxPagination: this.state.tableMaxPagination - paginationNumber,
                })
            }
        } else if (type == 'forward') {
            if (this.state.tableMaxPagination < data.length) {
                this.setState({
                    tableMaxPagination: this.state.tableMaxPagination + paginationNumber,
                    tableMinPagination: this.state.tableMinPagination + paginationNumber
                })
            }
        }
    }
    componentDidMount() {
        const { allDetail } = this.props;
        this.setState({ loadarState: true })
        fetch(APIS.GET_USER_DEVICE_DATA + allDetail.userId)
            .then(res => res.json())
            .then(json => {
                    this.setState({
                        allDetail: json,
                        loadarState: false
                    }, () => this.dataFunction())
                
            })

    }
    dataFunction() {
        this.getLocation()
        this.getFormatedDataForMap()
    }
    componentWillMount() {
        // if(this.state.deviceData !==''){
        // this.getLocation()
        // this.getFormatedDataForMap()
        // }
    }

    // componentWillReceiveProps(newProps) {
    //     this.setState({ loaderState: true })
    //     if (this.props.allDetail != "" && newProps.allDetail != this.props.allDetail) {
    //         this.getLocation(), () => this.setState({ loaderState: false })

    //     }
    // }

    getLocation() {
        const { allDetail } = this.props;
        // this.setState({ loadarState: true })
        // fetch(APIS.VIEW_USER_LOCATION + userDetail.userId)
        //     .then(res => res.json())
        //     .then(res => {
        //         this.setState({ loadarState: false })
        if (this.state.allDetail != null && this.state.allDetail != '' && this.state.allDetail.userLocationPOList != '' && this.state.allDetail.userLocationPOList != null && this.state.allDetail.userLocationPOList.length !== undefined) {
            // if (this.state.allDetail.userLocationPOList.length !== undefined) {

            let userLocation = (this.state.allDetail.userLocationPOList)
      
            for (var i = 0; i < userLocation.length; i++) {
                let GetLocation = userLocation[i];
         
                if (GetLocation.longitude != "" && isNaN(GetLocation.longitude)) {
                    const address = GetLocation.longitude
                  this.userLocation(address, GetLocation.date)
                } else {
                    if (GetLocation.latitude != "" && GetLocation.longitude != "") {
                        Geocode(GetLocation.latitude, GetLocation.longitude).then(
                            response => {
                                const address = response.results[0].formatted_address;
                                this.userLocation(address, GetLocation.date)
                            }
                        );
                    }
                }
            }
            // }
        }else{
            this.setState({loadarState:false})
        }
    }

    getFilterValue(filtered) {
        this.setState({ FilteredData: filtered })
    }

    getPageValue(pageIndex) {
        this.setState({ pageIndexToShow: pageIndex })
    }

    getFormatedDataForMap() {

        const { allDetail } = this.props;
       
        if (this.state.allDetail != null && this.state.allDetail != '' && this.state.allDetail.userLocationPOList != '' && this.state.allDetail.userLocationPOList != null && this.state.allDetail.userLocationPOList.length !== undefined) {
            let location = (this.state.allDetail.userLocationPOList)
            var internalData = {
                category: "anti-social-behaviour",
                location_type: "Force",
                id: getRendomNumber(8),
                context: "",
                outcome_status: null,
                persistent_id: "",
                location_subtype: ""
            }

            for (var i = 0; i < location.length; i++) {
                let GetLocation = location[i];
                if (GetLocation.longitude != "" && isNaN(GetLocation.longitude)) {
                    const address = GetLocation.longitude

                    if (address != null && address != '' && address != undefined && address.includes('hasLatitude=true') && address.includes('hasLongitude=true') && address.includes('longitude=') && address.includes('latitude=')) {
                        var interLatitude = address.split('latitude=')[1]
                        var interLongitude = address.split('longitude=')[1]
                        if (interLatitude.includes(",") && interLatitude.includes(",")) {
                            var latitude = interLatitude.split(",")[0]
                            var longitude = interLongitude.split(",")[0]
                            var street = { "id": getRendomNumber(6), "name": "On or near Tudor Close" }
                            location.latitude = latitude
                            location.longitude = longitude
                            location.street = street
                            internalData.location = location
                            this.state.finalData.push(internalData)
                            this.setState({
                                finalData: this.state.finalData
                            })
                        }
                    }
                }
            }

        }else{
            this.setState({loadarState:false})
        }
    }


    userLocation(location, date) {
        this.state.location.push({ location: location, date: date })
        this.setState({ location: this.state.location })
    }
}


export default ApplicantLocation;