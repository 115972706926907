import React, { Component } from 'react'
import CibilInfoUi from '../../../presentationals/Admin/Utility/UploadCibilInfo.component'
import { csvReader } from '../../../../utils/commonUtility'
import { uploadCSVFileApi } from '../AdminActionCreator.component'
import WarningPopup from '../../../presentationals/Admin/Utility/PincodeWarning.popup'
import Popup from '../../../presentationals/Popup/Popup.component';
import MainLoader from '../../../presentationals/Loader/Loader.component'

let paginationNumber = 10;
let dataForSave = []

class CibilInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            csvDataArray: [],
            warningPopupState: false,
            popupState: false,
            popupStatus: '',
            tableMinPagination: 0,
            tableMaxPagination: paginationNumber,
            search: '',
            searchState: false,
            dataForSave: [],
            csvFile: '',
            mainLoaderState: false
        }
    }

    render() {
        let csvDataArray = this.state.csvDataArray
        if (this.state.search) {
            csvDataArray = csvDataArray.filter(row => {
                if (row.mobileNumber != null && row.mobileNumber != "" && row.emailId != null && row.emailId != "") {
                    return row.mobileNumber.toLowerCase().includes(this.state.search.toLowerCase()) ||
                        row.emailId.toLowerCase().includes(this.state.search.toLowerCase())
                }
            })
        }
        return (
            <div>
                {this.state.popupState == true ?
                    <Popup
                        closePopup={this.closePopup.bind(this)}
                        popupStatus={this.state.popupStatus}
                    />
                    : null}
                <CibilInfoUi
                    handleForce={this.handleForce.bind(this)}
                    saveCsvData={this.saveCsvData.bind(this)}
                    csvDataArray={csvDataArray}
                    phocketTablePagination={this.phocketTablePagination.bind(this)}
                    tableMinPagination={this.state.tableMinPagination}
                    tableMaxPagination={this.state.tableMaxPagination}
                    searchHandler={this.searchHandler.bind(this)}
                    searchState={this.state.searchState}
                    uploadcsvHandler={this.uploadcsvHandler.bind(this)}
                    csvFile={this.state.csvFile}
                />
                {this.state.warningPopupState ?
                    <WarningPopup
                        cancelLoanConfirm={this.cancelLoanConfirm.bind(this)} />
                    : null}
                {this.state.mainLoaderState ?
                    <MainLoader />
                    : null}
            </div>
        )
    }

    uploadcsvHandler(e) {
        let val = e.target.value.split(/[\\\/]/)[2];
        let docType = val.split(".");
        let fileType = docType[docType.length - 1];
        if (fileType == 'csv') {
            this.setState({
                csvFile: e.target.files[0]
            })
        }
    }

    searchHandler(e) {
        this.setState({ search: e.target.value })
    }

    phocketTablePagination(data, type) {
        if (type == 'back') {
            if (this.state.tableMinPagination >= paginationNumber) {
                this.setState({
                    tableMinPagination: this.state.tableMinPagination - paginationNumber,
                    tableMaxPagination: this.state.tableMaxPagination - paginationNumber,
                })
            }
        } else if (type == 'forward') {
            if (this.state.tableMaxPagination < data.length) {
                this.setState({
                    tableMaxPagination: this.state.tableMaxPagination + paginationNumber,
                    tableMinPagination: this.state.tableMinPagination + paginationNumber
                })
            }
        }
    }

    handleForce(data) {
        this.setState({
            csvDataArray: csvReader(data),
            searchState: true
        })
    };

    saveCsvData() {
        if (this.state.csvFile != '') {
            this.setState({ warningPopupState: true })
        } else {
            this.setState({
                popupState: true,
                popupStatus: 'Please select CSV file',
            })
            this.removePopup();
        }
    }

    cancelLoanConfirm(type) {
        if (type == 'yes') {
            this.setState({ mainLoaderState: true })
            uploadCSVFileApi(
                this.state.csvFile,
                'cibilReport',
                this.props.admin,
                callBack => {
                    if (callBack == 'success') {
                        this.setState({
                            warningPopupState: false,
                            popupState: true,
                            popupStatus: 'CSV uploaded successfully',
                            csvFile: '',
                            mainLoaderState: false
                        })
                        this.removePopup();
                    } else {
                        this.setState({
                            warningPopupState: false,
                            popupState: true,
                            popupStatus: 'CSV not uploaded',
                            mainLoaderState: false
                        })
                        this.removePopup();
                    }
                })
        } else if (type == 'no') {
            this.setState({ warningPopupState: false })
        }
    }

    removePopup() {
        setTimeout(function () {
            this.setState({ popupState: false });
        }.bind(this), 5000)
    }

    closePopup() {
        this.setState({ popupState: false });
    }
}

export default CibilInfo;