import React, { Component } from 'react';
// import './thirdparty.scss';

const Registration = ({ submitLoginDetails, validationError, getTextFieldValue, successmsg }) => (
	<div className="container-fluid full-height">
		<div className="background-img"></div>
		<div className="row">
			<div className="well center-block">
				<div className="well-header">
					<h3 className="text-center text-success"> Register Here! </h3>
					<div className="text-center ">
				
				</div>
				<div className="row">
					<div className="col-md-12 col-sm-12 col-xs-12">
						<div className="form-group">
							<div className="input-group">
								<div className="input-group-addon">
									<i className="glyphicon glyphicon-user"></i>
								</div>
								<input type="text" placeholder="nbfc name" name="name" className="form-control"  />

							</div>
						</div>
					</div>
				</div>

				<div className="row">
					<div className="col-xs-12 col-sm-12 col-md-12">
						<div className="form-group">
							<div className="input-group">
								<div className="input-group-addon">
									<i className="glyphicon glyphicon-user"></i>
								</div>
								<input type="text" placeholder="Contact person name" name="name" className="form-control" />
							</div>
						</div>
					</div>
				</div>

				<div className="row">
					<div className="col-md-12 col-sm-12 col-xs-12">
						<div className="form-group">
							<div className="input-group">
								<div className="input-group-addon">
									<i className="glyphicon glyphicon-lock"></i>
								</div>
								<input type="password" minLength="8" maxLength="20" placeholder="Password" name="txtpass" className="form-control" />
							</div>
						</div>
					</div>
				</div>

				<div className="row">
					<div className="col-md-12 col-xs-12 col-sm-12">
						<div className="form-group">
							<div className="input-group">
								<div className="input-group-addon">
									<i className="glyphicon glyphicon-phone"></i>
								</div>
								<input type="number" minLength="10" maxLength="12" className="form-control" placeholder="Mobile No." />
							</div>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-md-12 col-sm-12 col-xs-12">
						<div className="form-group">
							<div className="input-group">
								<div className="input-group-addon">
									<i className="glyphicon glyphicon-user"></i>
								</div>
								<input type="text" placeholder="Contact Person E-Mail-Id" className="form-control" />

							</div>
						</div>
					</div>
				</div>
				<div className="row widget">
					<div className="col-md-12 col-xs-12 col-sm-12">
						<button className="btn btn-primary btn-lg btn-block" > Submit </button>
					</div>
				</div>

			</div>
		</div>
	</div>

	 </div>
)
export default Registration;