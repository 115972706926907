import React from 'react'

const ConfirmationPopup = ({  submit, statusHandler,close }) => (
    <div className="background-blur">
        <div className="popup-main small-popup container-fluid max-width-500px dbl-border">
            <i className='fa fa-close' style={{color:'red',fontSize:'18px',float:'right'}} onClick={()=>close()}></i>
            <div className="row from-group">
                <div className="col-xs-12">
                    <h4 className="text-center">Enabled Or Disable Card</h4>
                </div>
            </div>
            <div className="row from-group text-center">
                <div className='col-sm-12 col-xs-12'>
                    <label>Select Card Status</label>
                    <select className='form-control' onClick={(e) => statusHandler(e)}>
                        <option value=''> Select Status</option>
                        <option value='ENABLED'>Enabled</option>
                        <option value='DISABLED'>Disabled</option>
                    </select>
                </div>
            </div>
            <div className='row'>
                <div className='text-center'>
                    <button className='profile-save-btn' onClick={submit}>SAVE</button>
                </div>
            </div>
        </div>
    </div>
)

export default ConfirmationPopup;