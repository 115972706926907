import React from 'react'
import Ui from '../../../presentationals/CardsUtility/activity.component'
import { getActivityDetails } from './actionCreator'
import Loader from '../../../presentationals/Loader/Loader.component'
class Activity extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activityData:'',
            loaderState:false
        }
    }
    componentDidMount() {
        this.setState({loaderState:true})
        getActivityDetails(this.props.userDetailsByUserId.userId, '', '', callback => {
            this.setState({activityData:callback,loaderState:false})
        })
    }
    render() {
        return (
            <div>

                <Ui activityData={this.state.activityData} />
                {this.state.loaderState?
                <Loader/>
            :""}
            </div>
        )
    }
}
export default Activity