import React, { Component } from 'react'
import Collection from '../../../presentationals/Admin/Configuration/CollectionNotificationMedium.component'



class Config extends Component {
    constructor(props) {
        super(props);
        this.state = {
         
            MSG:""


        }

    }

        render() {
        const { admin } = this.props
        return (
            <div>
                <Collection
                  sendHandler={this.sendHandler.bind(this)}
                  MSG={this.state.MSG}/>
                    
              
            </div>
        )
    }
    removePopup() {
        setTimeout(function () {
            this.setState({ MSG: false });
        }.bind(this), 3000)
    }
    sendHandler() {
        {
            this.setState({ MSG: "saved successfully" })
        }
        this.removePopup();
    }

}

export default Config;