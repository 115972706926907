import React, { Component } from 'react'
import CommunicationUi from './Communication.container'

class CreditScorePopup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            creditScoringPopup: true
        }
    }
    render() {
        const { allDetail, admin,userDetail } = this.props
        return (
            <div className="credit-detail-popup" id="creditScorePopup">
                <div id="creditScorePopupHeader">
                    <span className="white-text font-25px">{allDetail.firstName}</span>
                    <button className="close" onClick={this.closeCreditPopup.bind(this)}>&times;</button>
                </div>
                <div className="Credit-detail-body">
                    <CommunicationUi
                    userDetails={userDetail}
                        allDetail={allDetail}
                        creditScoringPopup={this.state.creditScoringPopup}
                        admin={admin}
                        getData={this.props.getData.bind(this)}
                    />
                </div>
            </div>
        )
    }

    closeCreditPopup() {
        this.props.getCreditPopupState(false)
    }

    componentDidMount() {
        dragElement(document.getElementById("creditScorePopup"));

        function dragElement(elmnt) {
            var pos1 = 0, pos2 = 0, pos3 = 0, pos4 = 0;
            if (document.getElementById(elmnt.id + "Header")) {
                /* if present, the header is where you move the DIV from:*/
                document.getElementById(elmnt.id + "Header").onmousedown = dragMouseDown;
            } else {
                /* otherwise, move the DIV from anywhere inside the DIV:*/
                elmnt.onmousedown = dragMouseDown;
            }

            function dragMouseDown(e) {
                e = e || window.event;
                e.preventDefault();
                // get the mouse cursor position at startup:
                pos3 = e.clientX;
                pos4 = e.clientY;
                document.onmouseup = closeDragElement;
                // call a function whenever the cursor moves:
                document.onmousemove = elementDrag;
            }

            function elementDrag(e) {
                e = e || window.event;
                e.preventDefault();
                // calculate the new cursor position:
                pos1 = pos3 - e.clientX;
                pos2 = pos4 - e.clientY;
                pos3 = e.clientX;
                pos4 = e.clientY;
                // set the element's new position:
                if (elmnt.offsetTop - pos2 >= 0) {
                    elmnt.style.top = (elmnt.offsetTop - pos2) + "px";
                } else {
                    elmnt.style.top = "0px";
                }
                elmnt.style.left = (elmnt.offsetLeft - pos1) + "px";
                elmnt.style.bottom = 'auto';
                elmnt.style.right = 'auto';
            }

            function closeDragElement() {
                /* stop moving when mouse button is released:*/
                document.onmouseup = null;
                document.onmousemove = null;
            }
        }
    }

}

export default CreditScorePopup;