import React,{useState} from 'react'
import LoanUtilityMain from './newloanutility'
import ProcessingFee from './ProcessingFee'
export default function configMain() {
const [tabs,setabs]=useState('loan')
    const handletabs =(type)=>{
        setabs(type)
    }
  return (
    <>
    <div className="LoanUtility">
        
        <div className="tabs">
            <div className={`tab ${tabs === 'loan' ? 'active' : ''}`} onClick={()=>handletabs('loan')}>
                <h5>Loan Utility</h5>
            </div>
            <div className={`tab ${tabs === 'processing' ? 'active' : ''}`} onClick={()=>handletabs('processing')}>
                <h5>Processing Fee</h5>
            </div>
        </div>

        {
            tabs === 'loan' ?
            <LoanUtilityMain />
        :''}
        {
            tabs === 'processing' ?
            <ProcessingFee />
        :''}
    </div>
    </>
  )
}
