import React from "react";
import { getDBRcalculationApi, saveDbrDataApi, getallDBRdataApi } from '../../AdminActionCreator.component'
import Ui from './dbrDataViewDesign'
import { APIS } from '../../../../../utils/api-factory'
import Popup from '../../../../presentationals/Popup/Popup.component';
import Loader from '../../../../presentationals/Loader/Loader.component'
import { dbrValidation } from "../../../../../utils/validation";
class DBR extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            internalScoring: {
                stability: '',
                residence: '',
                debts: '',
                multiplePayday: '',
                overDueMsgsTrend: '',
                bureau: '',
                corporate: '',
                access: '',
                ntc: '',
                kyc: '',
                obligations: '',
                salary: ''
            },
            flagData: {
                dbrpercentage: '',
                scoring: '',
                decision: '',
                emi: ''
            },
            popupState: false,
            popupStatus: '',
            loaderState: false,
            validationMessages: '',
            finalScore:''
        }
    }
    componentDidMount() {

        this.getAlldata()

    }
    getDbrCalculations() {
        const { allDetail } = this.props
        let internalScoring = Object.assign({}, this.state.internalScoring)

        getDBRcalculationApi(allDetail.userId,allDetail.loanId, callback => {
            if (callback) {
                internalScoring.access = callback.access
                internalScoring.kyc = callback.kyc
                internalScoring.corporate = callback.corporate
                internalScoring.bureau = callback.bureau
                internalScoring.ntc = callback.ntc
            }
            this.setState({ internalScoring: internalScoring })

        })
    }
    internalHandler(e, type) {
        let internalScoring = Object.assign({}, this.state.internalScoring)
        if (type === 'stability') {
            if (e.target.value === '0' || e.target.value === '1') {
                internalScoring.stability = e.target.value
            } else {
                internalScoring.stability = ''
            }
        }
        if (type === 'residence') {
            if (e.target.value === '0' || e.target.value === '1') {
                internalScoring.residence = e.target.value
            } else {
                internalScoring.residence = ''
            }
        }
        if (type === 'debts') {
            if (e.target.value === '0' || e.target.value === '1') {
                internalScoring.debts = e.target.value
            } else {
                internalScoring.debts = ''
            }
        }
        if (type === 'multiplePayday') {
            if (e.target.value === '0' || e.target.value === '1') {
                internalScoring.multiplePayday = e.target.value
            } else {
                internalScoring.multiplePayday = ''
            }
        }
        if (type === 'overDueMsgsTrend') {
            if (e.target.value === '0' || e.target.value === '1') {
                internalScoring.overDueMsgsTrend = e.target.value
            } else {
                internalScoring.overDueMsgsTrend = ''
            }
        }
        if (type === 'bureau') {
            if (e.target.value === '0' || e.target.value === '1') {
                internalScoring.bureau = e.target.value
            } else {
                internalScoring.bureau = ''
            }
        }
        if (type === 'corporate') {
            if (e.target.value === '0' || e.target.value === '1') {
                internalScoring.corporate = e.target.value
            } else {
                internalScoring.corporate = ''
            }
        }
        if (type === 'access') {
            if (e.target.value === '0' || e.target.value === '1') {
                internalScoring.access = e.target.value
            } else {
                internalScoring.access = ''
            }
        }
        if (type === 'ntc') {
            if (e.target.value === '0' || e.target.value === '1') {
                internalScoring.ntc = e.target.value
            } else {
                internalScoring.ntc = ''
            }
        }
        if (type === 'kyc') {
            if (e.target.value === '0' || e.target.value === '1') {
                internalScoring.kyc = e.target.value
            } else {
                internalScoring.kyc = ''
            }
        }
        if (type === 'salary') {
            internalScoring.salary = e.target.value
        }
        if (type === 'obligations') {
            internalScoring.obligations = e.target.value
        }
        this.setState({ internalScoring: internalScoring })


    }
    getUserDetailsByUserId() {
        const { allDetail } = this.props
        let internalScoring = Object.assign({}, this.state.internalScoring)
        fetch(APIS.GET_CIBIL_SCORE_BY_USERID + allDetail.userId +'&loanId='+allDetail.loanId)
            .then(res => res.json())
            .then(res => {
                if (res.responseCode == 0) {
                } else {
                    if (res.income != null) {
                        internalScoring.salary = res.income
                    }
                    if (res.obligations != null) {
                        internalScoring.obligations = res.obligations
                    }
                    this.setState({ internalScoring })
                }
            })
    }
    getAlldata() {
        const { allDetail } = this.props
        let internalScoring = Object.assign({}, this.state.internalScoring)
        let flagData = Object.assign({}, this.state.flagData)

        getallDBRdataApi(allDetail.userId, callback => {
            if (callback !== '' && callback !== null && callback.decision !== '' && callback.decision !== undefined) {
                this.setState({finalScore:callback.finalScore})
                internalScoring.stability = callback.stability
                internalScoring.residence = callback.residence
                internalScoring.debts = callback.debts
                internalScoring.multiplePayday = callback.multiplePayday
                internalScoring.overDueMsgsTrend = callback.overDueMsgsTrend
                internalScoring.bureau = callback.bureau
                internalScoring.corporate = callback.corporate
                internalScoring.access = callback.access
                internalScoring.ntc = callback.ntc
                internalScoring.kyc = callback.kyc
                internalScoring.salary = callback.salary
                internalScoring.obligations = callback.obligations
                flagData.decision = callback.decision
                flagData.dbrpercentage = callback.dbrpercentage
                flagData.scoring = callback.scoring
                flagData.emi = callback.emi
            } else {
                this.getUserDetailsByUserId()
                this.getDbrCalculations()
            }
            this.setState({ internalScoring, flagData })
        })
    }
    submit() {
        let validation = dbrValidation(this.state.internalScoring)
        if (validation.status) {
            this.setState({ validationMessages: '' })
            const { allDetail } = this.props
            let internalScoring = Object.assign({}, this.state.internalScoring)
            this.setState({ loaderState: true })
            saveDbrDataApi(this.state.internalScoring, allDetail, callback => {
                if (callback.apiResponseMsg == 'success') {
                    this.setState({ loaderState: false, popupState: true, popupStatus: 'Data saved successfully!' }, () => this.removePopup())
                    this.getAlldata()
                    this.props.getFlagData()
                } else {
                    this.setState({ loaderState: false, popupState: true, popupStatus: 'please try againlater!' }, () => this.removePopup())

                }
            })
        } else {
            this.setState({ validationMessages: validation })
        }
    }
    closePopup() {
        this.setState({ popupState: false });
    }

    removePopup() {
        setTimeout(function () {
            this.setState({ popupState: false });
        }.bind(this), 5000)
    }

    render() {
        const {setcategoriesData}=this.props
        return (
            <div className=''  >
                {this.state.popupState == true ?
                    <Popup
                        closePopup={this.closePopup.bind(this)}
                        popupStatus={this.state.popupStatus}
                    />
                    : ""}
                <Ui
                finalScore={this.state.finalScore}
                setcategoriesData={setcategoriesData}
                    validationMessages={this.state.validationMessages}
                    submit={this.submit.bind(this)}
                    internalHandler={this.internalHandler.bind(this)}
                    internalScoring={this.state.internalScoring}
                />
                {this.state.loaderState ?
                    <Loader />
                    : ""}
            </div>
        )
    }
}
export default DBR