import React from 'react'

const crifData = ({
  allDetail,
  userCrifData,
  regenerateCrif,
  tableMaxPagination,
  tableMinPagination,
  tablePagination,
  analysisData
}) => (
  <div className='container-fluid crm-user-cibil'>
    <h3 className='heading-analysis'> CRIF Analysis</h3>
    {analysisData != null &&
    analysisData.rejectNote != '' &&
    analysisData.rejectNote != null &&
    analysisData.rejectNote != undefined ? (
      <h4 className='text-center'>
        <b>
          <u>Reject Notes</u>
        </b>
      </h4>
    ) : (
      ''
    )}
    {analysisData != null &&
    analysisData != undefined &&
    analysisData != '' &&
    analysisData.rejectNote != null &&
    analysisData.rejectNote != '' &&
    analysisData.rejectNote.length > 0
      ? analysisData.rejectNote.map((data, i) => {
          return (
            <div key={i}>
              <h5 className='reject-note'>{data}</h5>
            </div>
          )
        })
      : ''}

    <div className='overflow-x-auto'>
      {analysisData != null &&
      analysisData != undefined &&
      analysisData != '' &&
      analysisData.approve != null &&
      analysisData.approve != '' &&
      analysisData.approve != undefined ? (
        <table className='text-center blueTable green-bg positive-box'>
          <thead>
            <tr>
              <td>Account Type</td>
              <td>OverDue Amount</td>
              <td>Disburse Amount</td>
              <td>Disburse Date</td>
              <td>Account Status</td>
              <td>Current Balance</td>
              <td>Status</td>
            </tr>
          </thead>
          <tbody>
            {analysisData != null &&
            analysisData != undefined &&
            analysisData != ''
              ? analysisData.approve.map((data, i) => {
                  return (
                    <tr key={i}>
                      <td>{data.accountType}</td>
                      <td>{data.overDueAmount}</td>
                      <td>{data.disburseAmount}</td>
                      <td>{data.disburseDate}</td>
                      <td>{data.accountStatus}</td>
                      <td>{data.currentBalance}</td>
                      <td>{data.status}</td>
                    </tr>
                  )
                })
              : ''}
          </tbody>
        </table>
      ) : (
        ''
      )}
      {analysisData != null &&
      analysisData != undefined &&
      analysisData != '' &&
      analysisData.rejected != null &&
      analysisData.rejected != '' &&
      analysisData.rejected != undefined ? (
        <table className='text-center blueTable red-bg negative-box'>
          <thead>
            <tr>
              <td>Account Type</td>
              <td>OverDue Amount</td>
              <td>Disburse Amount</td>
              <td>Disburse Date</td>
              <td>Account Status</td>
              <td>Current Balance</td>
              <td>Status</td>
            </tr>
          </thead>
          <tbody>
            {analysisData != null &&
            analysisData != undefined &&
            analysisData != ''
              ? analysisData.rejected.map((data, i) => {
                  return (
                    <tr key={i}>
                      <td>{data.accountType}</td>
                      <td>{data.overDueAmount}</td>
                      <td>{data.disburseAmount}</td>
                      <td>{data.disburseDate}</td>
                      <td>{data.accountStatus}</td>
                      <td>{data.currentBalance}</td>
                      <td>{data.status}</td>
                    </tr>
                  )
                })
              : ''}
          </tbody>
        </table>
      ) : (
        ''
      )}
      {analysisData != null &&
      analysisData != undefined &&
      analysisData != '' &&
      analysisData.exceptionCase != null &&
      analysisData.exceptionCase != '' &&
      analysisData.exceptionCase != undefined ? (
        <table className='text-center blueTable yellow-bg positive-box'>
          <thead>
            <tr>
              <td>Account Type</td>
              <td>OverDue Amount</td>
              <td>Disburse Amount</td>
              <td>Disburse Date</td>
              <td>Account Status</td>
              <td>Current Balance</td>
              <td>Status</td>
            </tr>
          </thead>
          <tbody>
            {analysisData != null &&
            analysisData != undefined &&
            analysisData != ''
              ? analysisData.exceptionCase.map((data, i) => {
                  return (
                    <tr key={i}>
                      <td>{data.accountType}</td>
                      <td>{data.overDueAmount}</td>
                      <td>{data.disburseAmount}</td>
                      <td>{data.disburseDate}</td>
                      <td>{data.accountStatus}</td>
                      <td>{data.currentBalance}</td>
                      <td>{data.status}</td>
                    </tr>
                  )
                })
              : ''}
          </tbody>
        </table>
      ) : (
        ''
      )}

      <div className='col-xs-12'>
        {/* <button className='profile-save-btn' onClick={e => regenerateCrif()}>
          Regenerate Crif
        </button> */}
      </div>
      <br />
      {userCrifData != null ? (
        <div
          dangerouslySetInnerHTML={{
            __html: userCrifData
          }}
        ></div>
      ) : (
        ''
      )}
    </div>
  </div>
)

export default crifData
