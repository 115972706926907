import React from 'react'

const Stamp = ({close,url }) => (
    <div>
        <div className="background-blur" onClick={(e) => close()}></div>
        <div className="popup-main container-fluid overflow-auto">
            <a className="close" onClick={(e) => close()}>&times;</a>
            <div className="row">
                <div className='col-sm-12 col-xs-12'>
                    <iframe width='98%'  title="loading..." loading="lazy" height='450px' style={{marginLeft:'auto',marginRight:'auto'}} src={url}></iframe>
                </div>
            </div>
        </div>
    </div>
)
export default Stamp