import React from 'react'

const creditScorePopup = ({ creditexportHandler, creditexport, creditDetail,
    creditValidationMsg, admin }) => (
    <div className="container-fluid ">
        <div className="new-basic-head">
            CREDIT Scoring
        </div>

        <br />
        <div className="row">
            <div className="col-sm-4 col-xs-12 form-group">
                <b>Current Address Stay > 1 Year (MAX-0.5) &nbsp;</b>
                <input type="number" className="form-control white-bg-transparent" onChange={(e) => creditexport(e, 'Camorethanoneyear')} value={creditDetail.CamorethanoneyearState != null && creditDetail.CamorethanoneyearState != "" ? creditDetail.CamorethanoneyearState : ""} />
                <div className="font-12px height-15px validation-color">
                    {creditValidationMsg.field == "Camorethanoneyear" ? creditValidationMsg.msg : ""}
                </div>
            </div>
            <div className="col-sm-4 col-xs-12 form-group">
                <b>Current Address Owned (MAX-0.5)&nbsp;</b>
                <input type="number" className="form-control white-bg-transparent" onChange={(e) => creditexport(e, 'AddressOwn')} value={creditDetail.AddressOwnState != null && creditDetail.AddressOwnState != "" ? creditDetail.AddressOwnState : ""} />
                <div className="font-12px height-15px validation-color">
                    {creditValidationMsg.field == "AddressOwn" ? creditValidationMsg.msg : ""}
                </div>
            </div>
            <div className="col-sm-4 col-xs-12 form-group">
                <b>Current Address Owned (MAX-0.5)&nbsp;</b>
                <input type="number" className="form-control white-bg-transparent" onChange={(e) => creditexport(e, 'AddressOwn')} value={creditDetail.AddressOwnState != null && creditDetail.AddressOwnState != "" ? creditDetail.AddressOwnState : ""} />
                <div className="font-12px height-15px validation-color">
                    {creditValidationMsg.field == "AddressOwn" ? creditValidationMsg.msg : ""}
                </div>
            </div>
        </div>
        <div className="row">
            <div className="col-sm-4 col-xs-12 form-group">
                <b>Current Address same as Permanent Address (MAX-0.5) &nbsp;</b>
                <input type="number" className="form-control white-bg-transparent" onChange={(e) => creditexport(e, 'CAsameAsPa')} value={creditDetail.CAsameAsPaState != null && creditDetail.CAsameAsPaState != "" ? creditDetail.CAsameAsPaState : ""} />
                <div className="font-12px height-15px validation-color">
                    {creditValidationMsg.field == "CAsameAsPa" ? creditValidationMsg.msg : ""}
                </div>
            </div>
            <div className="col-sm-4 col-xs-12 form-group">
                <b>Current Address same as Aadhar Address (MAX-0.5)&nbsp;</b>
                <input type="number" className="form-control white-bg-transparent" onChange={(e) => creditexport(e, 'CAsameAdhaarAddress')} value={creditDetail.CAsameAdhaarAddressState != null && creditDetail.CAsameAdhaarAddressState != "" ? creditDetail.CAsameAdhaarAddressState : ""} />
                <div className="font-12px height-15px validation-color">
                    {creditValidationMsg.field == "CAsameAdhaarAddress" ? creditValidationMsg.msg : ""}
                </div>
            </div>
            <div className="col-sm-4 col-xs-12 form-group">
                <b>Total Work Exp. > 3 Year (MAX-0.5)&nbsp;</b>
                <input type="number" className="form-control white-bg-transparent" onChange={(e) => creditexport(e, 'EXPMorethanthreeyear')} value={creditDetail.EXPMorethanthreeyearState != null && creditDetail.EXPMorethanthreeyearState != "" ? creditDetail.EXPMorethanthreeyearState : ""} />
                <div className="font-12px height-15px validation-color">
                    {creditValidationMsg.field == "EXPMorethanthreeyear" ? creditValidationMsg.msg : ""}
                </div>
            </div>
        </div>
        <div className="row">
            <div className="col-sm-4 col-xs-12 form-group">
                <b>Current Work Exp. > 1 Year (MAX-0.5) &nbsp;</b>
                <input type="number" className="form-control white-bg-transparent" onChange={(e) => creditexport(e, 'CurrentAddressmorethanoneyear')} value={creditDetail.CurrentAddressmorethanoneyearState != null && creditDetail.CurrentAddressmorethanoneyearState != "" ? creditDetail.CurrentAddressmorethanoneyearState : ""} />
                <div className="font-12px height-15px validation-color">
                    {creditValidationMsg.field == "CurrentAddressmorethanoneyear" ? creditValidationMsg.msg : ""}
                </div>
            </div>
            <div className="col-sm-4 col-xs-12 form-group">
                <b>Company Category (MAX-2)&nbsp;</b>
                <input type="number" className="form-control white-bg-transparent" onChange={(e) => creditexport(e, 'companyCategory')} value={creditDetail.companyCategoryState != null && creditDetail.companyCategoryState != "" ? creditDetail.companyCategoryState : ""} />
                <div className="font-12px height-15px validation-color">
                    {creditValidationMsg.field == "companyCategory" ? creditValidationMsg.msg : ""}
                </div>
            </div>
            <div className="col-sm-4 col-xs-12 form-group">
                <b>Salary credit is on time (MAX-1)&nbsp;</b>
                <input type="number" className="form-control white-bg-transparent" onChange={(e) => creditexport(e, 'salaryOnTime')} value={creditDetail.salaryOnTimeState != null && creditDetail.salaryOnTimeState != "" ? creditDetail.salaryOnTimeState : ""} />
                <div className="font-12px height-15px validation-color">
                    {creditValidationMsg.field == "salaryOnTime" ? creditValidationMsg.msg : ""}
                </div>
            </div>
        </div>

        <div className="row">
            <div className="col-sm-4 col-xs-12 form-group">
                <b>EMI / Income Ratio (MAX-1) &nbsp;</b>
                <input type="number" className="form-control white-bg-transparent" onChange={(e) => creditexport(e, 'EMIRatio')} value={creditDetail.EMIRatioState != null && creditDetail.EMIRatioState != "" ? creditDetail.EMIRatioState : ""} />
                <div className="font-12px height-15px validation-color">
                    {creditValidationMsg.field == "EMIRatio" ? creditValidationMsg.msg : ""}
                </div>
            </div>
            <div className="col-sm-4 col-xs-12 form-group">
                <b>Presence of existing loan (MAX-1)&nbsp;</b>
                <input type="number" className="form-control white-bg-transparent" onChange={(e) => creditexport(e, 'existingLoan')} value={creditDetail.existingLoanState != null && creditDetail.existingLoanState != "" ? creditDetail.existingLoanState : ""} />
                <div className="font-12px height-15px validation-color">
                    {creditValidationMsg.field == "existingLoan" ? creditValidationMsg.msg : ""}
                </div>
            </div>
            <div className="col-sm-4 col-xs-12 form-group">
                <b>No EMI default evidents (MAX-1)&nbsp;</b>
                <input type="number" className="form-control white-bg-transparent" onChange={(e) => creditexport(e, 'defaultEvident')} value={creditDetail.defaultEvidentState != null && creditDetail.defaultEvidentState != "" ? creditDetail.defaultEvidentState : ""} />
                <div className="font-12px height-15px validation-color">
                    {creditValidationMsg.field == "defaultEvident" ? creditValidationMsg.msg : ""}
                </div>
            </div>
        </div>


        <div className="row">
            <div className="col-sm-4 col-xs-12 form-group">
                <b>Ability to save / Opening and closing balance (MAX-0.5) &nbsp;</b>
                <input type="number" className="form-control white-bg-transparent" onChange={(e) => creditexport(e, 'openingClosingBalance')} value={creditDetail.openingClosingBalanceState != null && creditDetail.openingClosingBalanceState != "" ? creditDetail.openingClosingBalanceState : ""} />
                <div className="font-12px height-15px validation-color">
                    {creditValidationMsg.field == "openingClosingBalance" ? creditValidationMsg.msg : ""}
                </div>
            </div>
            <div className="col-sm-4 col-xs-12 form-group">
                <b>Presence of high value transaction (MAX-0.5)&nbsp;</b>
                <input type="number" className="form-control white-bg-transparent" onChange={(e) => creditexport(e, 'highValueTransaction')} value={creditDetail.highValueTransactionState != null && creditDetail.highValueTransactionState != "" ? creditDetail.highValueTransactionState : ""} />
                <div className="font-12px height-15px validation-color">
                    {creditValidationMsg.field == "highValueTransaction" ? creditValidationMsg.msg : ""}
                </div>
            </div>
            <div className="col-sm-4 col-xs-12 form-group">
                <b>Overall Person Info Score (MAX-10)&nbsp;</b>
                <div className="blue-text" style={{marginTop:'10px'}}>
                    {creditDetail.totalScore != null && creditDetail.totalScore != "" ? <b>{creditDetail.totalScore}</b> : ""}
                </div>
            </div>
        </div>









        <div className="row form-group">
            <button className="profile-save-btn center-block margin-0-auto" onClick={e => creditexportHandler()}>SAVE</button>
        </div>
    </div>
)

export default creditScorePopup;