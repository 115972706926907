export const bulkCommTabNames = {
  email: "mail",
  sms: "sms",
  notification: "notification",
};
export const aggregatorDetailsTabName = {
  Aggregator_Data: "data",
  Aggregator_Dashboard: "dashboard",
  Lead_Breakdown: "leadBreakdown",
  Pushed_Lead: "pushLead",
  Applied_Lead: "applyLead",
  Disbursed_Lead: "disbursedLead",
};
