import React from 'react'
import Ui from '../../../presentationals/CardsUtility/cardLeads.component'
import { getAllCardUsersApi, getAllStatus, getUserDetailsByUserIdApi, saveFollowUpStatus } from './actionCreator'
import UserProfilePage from './profileDetails.container'
import Loader from '../../../presentationals/Loader/Loader.component'
import { _formatDate, _formatDateInDash, _preFormatDate } from '../../../../utils/validation'
import SidePopup from '../../../presentationals/Popup/Popup.component'
import { STRING } from '../../../../utils/Constant'
import { APIS } from '../../../../utils/api-factory'
let paginationNumber = 10;


class CardUser extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            forwordPageState: 1,

            tableMinPagination: 0,
            tableMaxPagination: paginationNumber,
            loaderState: false,
            mainPage: true,
            profileState: false,
            pageNumber: 1,
            noOfEntry: 100,
            filterData: {
                date: "",
                cardStatus: "",
                email: "",
                mobileNo: "",
            },

            userData: '',
            allStatus: '',
            userDetailsByUserId: '',
            searchByDateShow: '',
            dataFromAllLeads: '',
            popupState: false,
            popupStatus: '',
            type:'card'
        }
    }
    phocketTablePagination(data, type) {
        if (type == 'back') {
            if (this.state.tableMinPagination >= paginationNumber) {
                this.setState({
                    tableMinPagination: this.state.tableMinPagination - paginationNumber,
                    tableMaxPagination: this.state.tableMaxPagination - paginationNumber,
                })
            }
        } else if (type == 'forward') {
            if (this.state.tableMaxPagination < data.length) {
                this.setState({
                    tableMaxPagination: this.state.tableMaxPagination + paginationNumber,
                    tableMinPagination: this.state.tableMinPagination + paginationNumber
                })
            }
        }
    }
    paginationHandler(type) {
        if (type == 'refresh') {
            this.getAllUsers()
     
        } else if (type == 'all') {
            this.setState({
                tableMinPagination: 0,
                tableMaxPagination: paginationNumber,
                followUpStatus: STRING.SELECT_ALL
            }, () => this.getAllUsers())
        } else if (type == 'cache') {
            this.setState({
                loadarState: true
            })
            fetch(APIS.LOAD_CACHE + this.state.searchByDate)
                .then(res => res.text())
                .then(json => {
                    if (json == 'success') {
                        this.getAllUsers()
                        this.setState({
                            tableMinPagination: 0,
                            tableMaxPagination: paginationNumber,
                        })
                    }
                })

        } else if (type == 'left') {
            if (this.state.forwordPageState > 1) {
                this.setState({
                    forwordPageState: this.state.forwordPageState - 1,
                    tableMinPagination: 0,
                    tableMaxPagination: paginationNumber,
                }, () => this.getAllUsers())
            }
        } else if (type == 'right') {
            this.setState({
                forwordPageState: this.state.forwordPageState + 1,
                tableMinPagination: 0,
                tableMaxPagination: paginationNumber,
            }, () => this.getAllUsers())
        }
    }
    dataFilter(e, type) {
        let filterData = Object.assign({}, this.state.filterData)
        if (type === 'date') {
            filterData.date = _preFormatDate(e)
            this.setState({ searchByDateShow: e })
        }
        if (type === "email") {
            filterData.email = e.target.value

            // if (e.target.value.includes("@")) {

            // }
        }
        if (type === "mobileNo") {
            // if (e.target.value.length > 9) {
            filterData.mobileNo = e.target.value
        }
        if (type === "status") {
            filterData.cardStatus = e.target.value
        }
        if (type === 'date-test') {
            filterData.date = (e.target.value)
        }
        this.setState({ filterData }, () => this.getAllUsers())
        // if (!e.target.value) {
        //     this.getAllUsers()
        // }
    }


    getAllStatusApi() {
        getAllStatus(this.state.type,callBack => {
            this.setState({ allStatus: callBack })
        })
    }
    getAllUsers() {
        this.setState({ loaderState: true })
        getAllCardUsersApi(this.state.pageNumber, this.state.noOfEntry, this.state.filterData.date !== '' ? _formatDateInDash(this.state.filterData.date) : "", this.state.filterData.cardStatus, this.state.filterData.email, this.state.filterData.mobileNo, callBack => {
            this.setState({ userData: callBack, loaderState: false })
        })
    }
    getuserDetailsByUserId(data) {
        this.setState({ mainPage: false })
        getUserDetailsByUserIdApi(data, callBack => {
            this.setState({ loaderState: false, profileState: true, userDetailsByUserId: callBack })
        })
    }
    componentDidMount() {
        this.getAllUsers()
        this.getAllStatusApi()
    }
    userDetailsinfo(data) {
        this.setState({ dataFromAllLeads: data })
        this.setState({ loaderState: true })
        this.getuserDetailsByUserId(data.userId)
    }
    back() {
        this.setState({ mainPage: true, profileState: false })
    }
    removePopup() {
        setTimeout(function () {
            this.setState({ popupState: false });
        }.bind(this), 5000)
    }
    statusHandler(e) {
        const { admin } = this.props
        this.setState({ loaderState: true })
        saveFollowUpStatus(this.state.dataFromAllLeads.cardId, e.target.value, admin, this.state.dataFromAllLeads.userId,
            (callBack) => {
                if (callBack == 'success') {
                    this.setState({
                        popupState: true,
                        popupStatus: 'Follow-up status save Successfully',
                        loaderState: false
                    })
                    this.removePopup();
                    // this.getLoanLeads()
                } else {
                    this.setState({
                        popupState: true,
                        popupStatus: 'Please try again later!',
                        loaderState: false
                    })
                    this.removePopup();
                }
            })



    }
    closePopup() {
        this.setState({ popupState: false })
    }
    render() {
        const{admin}=this.props
        return (
            <div className='container-fluid'>
                {this.state.profileState ?
                    <div className='row'>
                        <div className='col-sm-4 col-xs-12'>
                            <div className='back-card-btn'>
                                <i className='fa fa-arrow-circle-o-left' onClick={() => this.back()}></i>
                            </div>
                        </div>
                        <div className='col-sm-4 col-xs-12' style={{ marginTop: '25px' }}>
                            {this.state.userDetailsByUserId.firstName && this.state.userDetailsByUserId.lastName ?
                                <h3 className=''>
                                    <b>{this.state.userDetailsByUserId.firstName}&nbsp;{this.state.userDetailsByUserId.lastName}</b>
                                </h3>
                                : ""}
                        </div>
                        <div className='col-sm-4 col-xs-12' style={{ marginTop: '25px' }}>
                            <label className="">Set Status</label>
                            <select className='form-control' onChange={(e) => this.statusHandler(e)}>
                                <option value=''>Select Status</option>
                                {this.state.allStatus && this.state.allStatus.map((data, i) => {
                                    return (
                                        <option value={data.statusId}>{data.adminStatus}</option>
                                    )
                                })}
                            </select>
                            {/* <select className="form-control" onChange={(e) => statusHandler(e)} defaultValue={userDetails.statusId}>
                                <option value='' >Change Status</option> */}
                            {/* {allStatus != null && allStatus != undefined && allStatus != '' ?
                                    allStatus.map((status, i) => {
                                        if (status.statusId != STRING.SELECT_ALL && status.statusId != STRING.SOFT_APPROVAL) {
                                            if (allDetail.statusId == STRING.PAYMENT_OVERDUE && status.adminStatus != 'Cancelled' && status.adminStatus != 'Rejected' && status.adminStatus != 'Approved' && status.adminStatus != 'Disbursed' && status.adminStatus != 'Paid' && status.adminStatus != 'Permanent Reject' && status.adminStatus != 'Incomplete Application' && status.adminStatus != 'Documents Collected') {
                                                return (

                                                    <option value={status.statusId} key={i}>{status.adminStatus}</option>
                                                );
                                            } else {
                                                if (status.statusId != STRING.CHEQUE_BOUNCE && status.adminStatus != 'Cancelled' && status.adminStatus != 'Rejected' && status.adminStatus != 'Approved' && status.adminStatus != 'Disbursed' && status.adminStatus != 'Paid' && status.adminStatus != 'Permanent Reject' && status.adminStatus != 'Incomplete Application' && status.adminStatus != 'Documents Collected') {
                                                    return (
                                                        <option value={status.statusId} key={i}>{status.adminStatus}</option>
                                                    );
                                                }
                                            }
                                        }
                                    }) : ""} */}
                            {/* </select> */}
                        </div>
                    </div>

                    : ""}
                {this.state.profileState ?
                    <UserProfilePage  admin={admin} userDetailsByUserId={this.state.userDetailsByUserId} dataFromAllLeads={this.state.dataFromAllLeads} />
                    : ""}
                {this.state.mainPage ?
                    <Ui
                   
                        paginationHandler={this.paginationHandler.bind(this)}
                        phocketTablePagination={this.phocketTablePagination.bind(this)}
                        tableMinPagination={this.state.tableMinPagination}
                        tableMaxPagination={this.state.tableMaxPagination}
                        date={this.state.filterData.date}
                        filterData={this.state.filterData}
                        dataFilter={this.dataFilter.bind(this)}
                        searchByDateShow={this.state.searchByDateShow}
                        userDetailsinfo={this.userDetailsinfo.bind(this)}
                        userData={this.state.userData}
                        allStatus={this.state.allStatus} />
                    : ""}
                {this.state.loaderState ?
                    <Loader />
                    : ""}
                {this.state.popupState ?

                    <SidePopup popupStatus={this.state.popupStatus} closePopup={this.closePopup.bind(this)} />
                    : ""}
            </div>
        )
    }
}
export default CardUser