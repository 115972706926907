import React, { Component } from 'react'
import { TYPE, STRING } from '../../../../../utils/Constant'
import { APIS } from '../../../../../utils/api-factory';
import { calculateEmiNbfcApi } from '../../AdminActionCreator.component'
import { approveLoanApi } from '../../AdminActionCreator.component'
import ApproveDataPopup from '../../../../presentationals/Popup/SoftApproveData.component';
import { _formatDateInDash, _preFormatDate } from '../../../../../utils/validation';
import moment from 'moment'

class ApproveData extends Component {
    constructor(props) {
        super(props);
        this.state = {
            approveData: {
                approveDate: _formatDateInDash(new Date()),
                approveDateToShow: new Date(),
                salaryDate: '',
                salaryDateToShow: '',
                selectEMI: '',
                ApproveAmount: '',
                errorMsg: ''
            },
            emiTableData: [],
            disabled: false,
            nbfcEmis: 12
        }
    }

    componentWillMount() {
        const { allDetail, admin, nbfcNameForApprove } = this.props
        let approveData = Object.assign({}, this.state.approveData)
        approveData.selectEMI = this.state.nbfcEmis
        approveData.salaryDate = allDetail != null && allDetail.employmentDetailsPO != null && allDetail.employmentDetailsPO.salaryDay != null ? allDetail.employmentDetailsPO.salaryDay + '-' + moment(new Date()).add(1, 'months').format("MM-YYYY") : null
        approveData.ApproveAmount = allDetail != null && allDetail.loanAmount != null ? allDetail.loanAmount : null
        calculateEmiNbfcApi(allDetail.loanAmount, this.state.nbfcEmis, allDetail.employmentDetailsPO.salaryDay, nbfcNameForApprove,
            (callBack) => {
                callBack.length > 0 ?
                    callBack.map((emi, i) => {
                        if (emi.Date != undefined) {
                            approveData['repaymentDate' + i] = emi.Date
                            approveData['repaymentDateToShow' + i] = moment(emi.Date, "DD-MM-YYYY")._d
                        }
                    }) : null

                this.setState({ emiTableData: callBack, approveData })
            })
        this.setState({
            approveData
        })
    }

    render() {
        const { admin } = this.props
        return (
            <ApproveDataPopup
                approveData={this.state.approveData}
                closeApprovePopup={this.closeApprovePopup.bind(this)}
                approveDataHandler={this.approveDataHandler.bind(this)}
                approveDataChange={this.approveDataChange.bind(this)}
                emiTableData={this.state.emiTableData}
                errorMsg={this.state.errorMsg}
                disabled={this.state.disabled}
                admin={admin}
            />
        )
    }

    approveDataChange(e, dataType, index) {
        const { allDetail, admin, nbfcNameForApprove } = this.props
        let approveData = Object.assign({}, this.state.approveData)
        if (dataType == TYPE.LOAN_APPROVE_DATE) {
            approveData.approveDate = _formatDateInDash(e)
            approveData.approveDateToShow = e
        } else if (dataType == TYPE.LOAN_AMOUNT) {
            approveData.ApproveAmount = e.target.value
        } else if (dataType == TYPE.SELECT_EMI) {
            approveData.selectEMI = e.target.value
            calculateEmiNbfcApi(allDetail.loanAmount, e.target.value, allDetail.employmentDetailsPO.salaryDay, nbfcNameForApprove,
                (callBack) => {
                    callBack.length > 0 ?
                        callBack.map((emi, i) => {
                            if (emi.Date != undefined) {
                                approveData['repaymentDate' + i] = emi.Date
                                approveData['repaymentDateToShow' + i] = moment(emi.Date, "DD-MM-YYYY")._d
                            }
                        }) : null

                    this.setState({ emiTableData: callBack, approveData })
                })
        } else if (dataType == TYPE.FIRST_REPAYMENT_DATE) {
            this.state.emiTableData.length > 0 ?
                this.state.emiTableData.map((emi, i) => {
                    if (i == index) {
                        if (index == 0) {
                            approveData['repaymentDateToShow' + i] = e
                            approveData['repaymentDate' + i] = _formatDateInDash(e)
                        } else if (index > 0) {
                            let previousEmi = moment(approveData['repaymentDate' + (index - 1)], "DD-MM-YYYY");
                            let currentEmi = moment(new Date(e))
                            let emiDateDiff = currentEmi.diff(previousEmi, 'days')
                            if (emiDateDiff >= 25 && emiDateDiff <= 35) {
                                approveData['repaymentDateToShow' + i] = e
                                approveData['repaymentDate' + i] = _formatDateInDash(e)
                                this.setState({
                                    errorMsg: ''
                                })
                            } else {
                                this.setState({
                                    errorMsg: "Difference between 2 emis should be 25-35 days and your date difference is " + Number(emiDateDiff) + " days."
                                })
                            }
                        }
                    }
                })
                : null
        }
        this.setState({
            approveData
        })
    }

    approveDataHandler() {
        const { allDetail, admin, reason, mailState, score, nbfcNameForApprove } = this.props;
        let paybackDates = []
        this.state.emiTableData != null && this.state.emiTableData.length > 0 ?
            this.state.emiTableData.map((emi, i) => {
                let dateValue = document.getElementById("RepaymentDate_" + i).value
                paybackDates.push(dateValue)
            })
            : null
        this.setState({ disabled: true })
        approveLoanApi(allDetail, STRING.SOFT_APPROVAL, reason, this.state.approveData, mailState, score, admin.emailId, paybackDates, nbfcNameForApprove,
            (callBack) => {
                this.closeApprovePopup(callBack)
                this.setState({ disabled: false })
            })
    }

    closeApprovePopup(res) {
        this.props.closeNbfcApprovePopup(res)
    }
}

export default ApproveData;