import { APIS } from '../../../../utils/api-factory'

import { POST_HEADER } from '../../../../utils/apiHeaders'




export const uploadByCsv = (
    csvFile,
    teaGardenId,
    isStaff,
    callBack
) => {
    return new Promise((resolve, reject) => {
        let imageFormData = new FormData()
        imageFormData.append('csvFile', csvFile)
        imageFormData.append('teaGardenId', teaGardenId)
        imageFormData.append('isStaff',isStaff)
        var xhr = new XMLHttpRequest()
        xhr.open('post', APIS.UPLOAD_TEA_GARDEN_USERS, true)
        xhr.onload = function () {
            if (this.status == 200) {
                resolve(this.response)
                callBack(this.response)
            } else {
                reject(this.statusText)
            }
        }
        xhr.send(imageFormData)
    })
}

// export const addAmbassadorTeaGarden = (
//     userDetail,
//     admin,
//     callBack
// ) => {
//     var data = {
//         ambassadorCode: "",
//         // empId:admin.emailId,
//         emailId: userDetail.emailId,
//         firstName: userDetail.firstName,
//         lastName: userDetail.lastName,
//         mobileNumber: userDetail.mobileNumber
//     }
//     fetch(APIS.ADD_AMBASSADOR_TEA_GARDEN + '&empId=' + admin.emailId, {
//         method: 'POST',
//         headers: POST_HEADER,
//         body: JSON.stringify(data)
//     })
//         .then(res => res.text())
//         .then(json => {
//             callBack(json)
//         })
// }
export const addOrUpdateAddress = (
    userAddressDetail,
    userId,
    admin,
    formStatus,
    callBack
) => {
    var data = {
        userId: userId,
        currentAddress: userAddressDetail.address,
        currentPincode: userAddressDetail.pinCode,
        currentState: userAddressDetail.state,
        currentCity: userAddressDetail.city,
        empId: admin.emailId,
        formStatus: formStatus


    }
    fetch(APIS.USER_ADDRESS_DETAIL, {
        method: 'POST',
        headers: POST_HEADER,
        body: JSON.stringify(data)
    })
        .then(res => res.text())
        .then(json => {
            callBack(json)
        })
}

export const addAmbassadorTeaGarden = (userDetail,
    admin,
    callBack) => {
    var data = {
        ambassadorCode: "",
        empId: admin.emailId,
        emailId: userDetail.emailId,
        firstName: userDetail.firstName,
        lastName: userDetail.lastName,
        mobileNumber: userDetail.mobileNumber
    }
    fetch(APIS.ADD_AMBASSADOR_TEA_GARDEN + '?empId=' + admin.emailId, {
        method: 'POST',
        headers: POST_HEADER,
        body: JSON.stringify(data)
    })
        .then(res => res.json())
        .then(json => {
            callBack(json)
        })
}
export const addOrUpDateBankDetails = (bankDetail, userId,admin, callBack) => {
    var data = {
        userId: userId,
        bankName: bankDetail.bankName,
        nameAsBankAccount: bankDetail.nameAsBankAccount,
        accountNumber: bankDetail.accountNumber,
        ifscCode: bankDetail.ifscCode,
        bankBranch: bankDetail.bankBranch,
        ifSalariedAccount: '',
        panNo: null,
        aadhaarNo: null,
        formStatus: bankDetail.formStatus
    }
    // console.log(data)

    fetch(APIS.FORM_BANK_DETAIL, {
        method: 'POST',
        headers: POST_HEADER,
        body: JSON.stringify(data)
    })
        .then(res => res.text())
        .then(json => {
            callBack(json)
        })
}

export const getAllTeaAmbassador = (pageNumber, noOfEntry, date, mobileNo, email, callBack) => {
    fetch(APIS.GET_ALL_TEA_GARDEN_AMBASADOR_LIST + pageNumber + '&noOfEntry=' + noOfEntry + '&date=' + date + '&mobileNo=' + mobileNo + '&email=' + email)
        .then(res => res.json())
        .then(json => {
            callBack(json)
        })
}

export const uploadAttendance = (
    csvFile,
    // teaGardenId,
    callBack
) => {
    return new Promise((resolve, reject) => {
        let imageFormData = new FormData()
        imageFormData.append('csvFile', csvFile)
        // imageFormData.append('teaGardenId', teaGardenId)
        var xhr = new XMLHttpRequest()
        xhr.open('post', APIS.UPLOAD_TEA_GARDEN_ATTENDANCE, true)
        xhr.onload = function () {
            if (this.status == 200) {
                resolve(this.response)
                callBack(this.response)
            } else {
                reject(this.statusText)
            }
        }
        xhr.send(imageFormData)
    })
}

export const uploadByCsvWages = (
    csvFile,
    teaGardenId,
    callBack
) => {
    return new Promise((resolve, reject) => {
        let imageFormData = new FormData()
        imageFormData.append('file', csvFile)
        imageFormData.append('teaGardenId', teaGardenId)
        var xhr = new XMLHttpRequest()
        xhr.open('post', APIS.UPLOAD_TEA_GARDEN_WAGES, true)
        xhr.onload = function () {
            if (this.status == 200) {
                resolve(this.response)
                callBack(this.response)
            } else {
                reject(this.statusText)
            }
        }
        xhr.send(imageFormData)
    })
}