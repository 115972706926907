import React from 'react'

const physicalCardPopup = ({  physicalCardHandler, submitPhysical ,close}) => (
    <div className="background-blur">
        <div className="popup-main small-popup container-fluid max-width-500px dbl-border">
        <i className='fa fa-close' style={{color:'red',fontSize:'18px',float:'right'}} onClick={()=>close()}></i>

           
            <div className="row from-group ">
                <div className='col-sm-12 col-xs-12'>
                    <label>Proxy Number</label>
                    <input className='form-control' placeholder='Enter Proxy Number' name='proxyNumber' onChange={(e)=>physicalCardHandler(e)}/>
                   
                </div>
            </div>
            <div className="row from-group ">
                <div className='col-sm-12 col-xs-12'>
                    <label>bin</label>
                    <input className='form-control' placeholder='Enter Bin' name='bin' onChange={(e)=>physicalCardHandler(e)}/>
                   
                </div>
            </div>
            <div className="row from-group ">
                <div className='col-sm-12 col-xs-12'>
                    <label>vpan</label>
                    <input className='form-control' placeholder='Enter vpan' name='vpan' onChange={(e)=>physicalCardHandler(e)}/>
                   
                </div>
            </div>
            <div className='row'>
                <div className='text-center'>
                    <button className='profile-save-btn' onClick={()=>submitPhysical()}>SAVE</button>
                </div>
            </div>
        </div>
    </div>
)

export default physicalCardPopup;