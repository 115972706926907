import React from 'react'
import Finbitui from './finbit.component'
import { getFinBitData, getFinBitDataNew, saveUnderWriterApi } from '../../../containers/Admin/AdminActionCreator.component'
import Loader from '../../../presentationals/Loader/layerLoader.component'
import { _preFormatDate, _formatDateInDash } from '../../../../utils/validation'
import { APIS } from '../../../../utils/api-factory'
import PopupSuccess from '../../../presentationals/Popup/Successpopup.component'
import PopUp from '../../../presentationals/Popup/ErrorPopUp.component'
import CheckReport from '../../../presentationals/Popup/finbitgeneratepopup'
import PopUpNew from '../../../presentationals/Popup/Popup.component'
import { getNotesnew, saveCommentForCreditAssesment } from '../Analysis/analysisactionCreator'
import moment from 'moment'
let paginationNumber = 20;
let jsondata = {}
class FinBit extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            finBitData: '',
            tableMinPagination: 0,
            tableMaxPagination: paginationNumber,
            loaderState: false,
            type: 'transactionsDetail',
            viewFalse: true,
            finBitDataNew: '',
            datePickerState: false,
            endDate: _preFormatDate(new Date()),
            startDate: _preFormatDate(new Date()),
            bankData: '',
            popupStatus: '',
            popupStateSuccess: false,
            popupStateSuccess: false,
            selectedFilter: '',
            bankDataFibit: '',
            fraudData: '',
            analysisData: '',
            finbitPopup: false,
            regenerate: '',
            pageNumber: 1,
            viewPage: 'info',
            popupstate: false,
            underWriterStatus: '',
            newNotes: '',
            notesPopup: false,
            notesType: '',
            notesText: '',
            filterType: 'financialDecision',
            suffixDate: '',
            zoomState: false

        }
    }
    componentDidMount() {
        const {popup,popupvalue} = this.props;
        jsondata = this.props.finbitJson
        this.ConfirmCrifDataGenerate('no')

        if(popup == true){
            this.setState({popupstate: true,popupStatus: popupvalue})
        }
    }
    selectData(data, value) {
        if (jsondata.hasOwnProperty(data)) {
            const index = jsondata[data].indexOf(value);
            if (index !== -1) {
                jsondata[data].splice(index, 1);
            } else {
                jsondata[data].push(value);
            }
        } else {
            jsondata[data] = [value];
        }

        this.props.finbitJsonData(jsondata)
    }
    getAnalysisData() {
        const { allDetail } = this.props
        fetch(APIS.FINBIT_ANALYSIS_API + allDetail.userId)
            .then(res => res.json())
            .then(json => {
                this.setState({ analysisData: json })
                if (json.salaryDayBankStatement) {
                    let date = this.addSuffixToDay(json.salaryDayBankStatement)
                    this.setState({ suffixDate: date })
                }
            })
    }
    getData() {

        this.setState({ loaderState: true, finbitPopup: false })
        const { allDetail } = this.props
        fetch(APIS.GET_ACCOUNT_DETAILS_USER_ID + allDetail.userId)
            .then(res => res.json())
            .then(json => {

                this.setState({ bankData: json }, () => this.getAnalysisData())
            })

        getFinBitData(allDetail.userId, 'transactionsDetail', this.state.regenerate, callBack => {
            if (callBack != 'no data' && callBack.responseCode != 0) {
                var x = callBack.transactions
                var y = [...x].reverse();
                this.setState({
                    finBitDataNew: y,
                    finBitData: callBack,
                    viewFalse: true,
                    loaderState: false,
                    bankDataFibit: callBack.bank_account

                })

            }
            else {
                this.setState({ loaderState: false })
            }
            if (this.state.finBitData && this.state.bankData) {
                if (this.state.finBitData.bank_account.accountNo === this.state.bankData.accountNumber) {
                    this.setState({ popupStatus: 'Only Account Number Matched', popupStateSuccess: true })
                }
                if (this.state.finBitData.bank_account.ifsCode === this.state.bankData.ifscCode) {
                    this.setState({ popupStatus: 'Only IFSC Matched', popupStateSuccess: true })
                }
                if (this.state.finBitData.bank_account.accountNo === this.state.bankData.accountNumber && this.state.finBitData.bank_account.ifsCode === this.state.bankData.ifscCode) {
                    this.setState({ popupStatus: 'Acount Details Matched', popupStateSuccess: true })

                }

                if (this.state.finBitData.bank_account.accountNo !== this.state.bankData.accountNumber && this.state.finBitData.bank_account.ifsCode !== this.state.bankData.ifscCode) {
                    this.setState({ popupStatus: 'Account Details Not Matched', popupStateError: true })

                }
            }


        })
    }
    closePopup() {
        this.setState({ popupStateSuccess: false, popupStateError: false, popupstate: false, popupStatus: '' })
    }
    searchHandler() {
        const { allDetail } = this.props

        this.setState({ loaderState: true })
        getFinBitDataNew(allDetail.userId, 'bankAccountSummary', this.state.startDate, this.state.endDate, this.state.regenerate, callBack => {
            if (callBack != 'no data') {
                var x = callBack.transactions
                var y = [...x].reverse();
                this.setState({
                    type: 'bankAccountSummary',
                    finBitData: y,
                    viewFalse: true,
                    loaderState: false,

                })
            } else {
                this.setState({ loaderState: false })
            }
        })
    }
    onChangeHandlerDate(e, type) {
        if (type == 'startDate') {
            this.setState({ startDate: _formatDateInDash(e) })
        }
        if (type == 'endDate') {
            this.setState({ endDate: _formatDateInDash(e) })


        }

    }
    phocketTablePagination(data, type) {
        if (type == 'back') {
            if (this.state.tableMinPagination >= paginationNumber) {
                this.setState({
                    tableMinPagination: this.state.tableMinPagination - paginationNumber,
                    tableMaxPagination: this.state.tableMaxPagination - paginationNumber,
                })
            }
        } else if (type == 'forward') {
            if (this.state.tableMaxPagination < data.length) {
                this.setState({
                    tableMaxPagination: this.state.tableMaxPagination + paginationNumber,
                    tableMinPagination: this.state.tableMinPagination + paginationNumber
                })
            }
        }
    }
    typeHandler(data) {
        const { allDetail } = this.props
        this.setState({ tableMinPagination: 0, tableMaxPagination: 20, selectedFilter: '' })
        if (data.target.value == 'transactionsDetail') {
            this.setState({ loaderState: true, datePickerState: false, })

            getFinBitData(allDetail.userId, 'transactionsDetail', this.state.regenerate, callBack => {
                if (callBack != 'no data') {
                    var x = callBack.transactions
                    var y = [...x].reverse();
                    this.setState({
                        finBitDataNew: y,
                        finBitData: callBack,
                        viewFalse: true,
                        type: 'transactionsDetail',
                        loaderState: false,
                    })
                } else {
                    this.setState({ loaderState: false })
                }
            })
        }
        if (data.target.value == 'averageMonthlyBalance') {
            this.setState({ loaderState: true, datePickerState: false })


            getFinBitData(allDetail.userId, 'averageMonthlyBalance', this.state.regenerate, callBack => {
                if (callBack != 'no data') {
                    var x = callBack
                    var y = [...x].reverse();
                    this.setState({
                        loaderState: false,
                        type: 'averageMonthlyBalance',
                        finBitData: y
                    })
                } else {
                    this.setState({ loaderState: false })
                }
            })
        }
        if (data.target.value == 'negativeBalanceTransactions') {
            this.setState({ loaderState: true, datePickerState: false })

            getFinBitData(allDetail.userId, 'negativeBalanceTransactions', this.state.regenerate, callBack => {
                if (callBack != 'no data') {
                    var x = callBack
                    var y = [...x].reverse();
                    this.setState({
                        finBitData: y,
                        viewFalse: true,
                        type: 'negativeBalanceTransactions',
                        loaderState: false,
                    })
                } else {
                    this.setState({ loaderState: false })
                }
            })
        }
        if (data.target.value == 'income') {
            this.setState({ loaderState: true, datePickerState: false })

            getFinBitData(allDetail.userId, 'income', this.state.regenerate, callBack => {

                if (callBack != 'no data') {
                    var x = callBack
                    var y = [...x].reverse();
                    this.setState({
                        finBitDataNew: y,
                        viewFalse: true,
                        type: 'income',
                        loaderState: false,
                    })
                } else {
                    this.setState({ loaderState: false })
                }
            })
        }
        if (data.target.value == 'expenses') {
            this.setState({ loaderState: true, datePickerState: false })

            getFinBitData(allDetail.userId, 'expenses', this.state.regenerate, callBack => {
                if (callBack != 'no data') {
                    var x = callBack
                    var y = [...x].reverse();
                    this.setState({
                        finBitDataNew: y,
                        viewFalse: true,
                        type: 'expenses',
                        loaderState: false,
                    })
                } else {
                    this.setState({ loaderState: false })
                }
            })
        }
        if (data.target.value == 'creditCard') {
            this.setState({ loaderState: true, datePickerState: false })

            getFinBitData(allDetail.userId, 'creditCard', this.state.regenerate, callBack => {
                if (callBack != 'no data') {
                    var x = callBack.monthlyDetails
                    var y = [...x].reverse();
                    this.setState({
                        finBitDataNew: y,
                        viewFalse: true,
                        type: 'creditCard',

                        loaderState: false,

                    })
                } else {
                    this.setState({ loaderState: false })
                }
            })
        }
        if (data.target.value == 'highValueTransactions') {
            this.setState({ loaderState: true, datePickerState: false })

            getFinBitData(allDetail.userId, 'highValueTransactions', this.state.regenerate, callBack => {
                if (callBack != 'no data') {
                    var x = callBack
                    var y = [...x].reverse();
                    this.setState({
                        finBitDataNew: y,
                        viewFalse: true,
                        type: 'highValueTransactions',
                        loaderState: false,
                    })
                } else {
                    this.setState({ loaderState: false })
                }
            })
        }
        if (data.target.value == 'moneyReceived') {
            this.setState({ loaderState: true, datePickerState: false })

            getFinBitData(allDetail.userId, 'moneyReceived', this.state.regenerate, callBack => {
                if (callBack != 'no data') {
                    var x = callBack
                    var y = [...x].reverse();
                    this.setState({
                        finBitDataNew: y,
                        viewFalse: true,
                        type: 'moneyReceived',
                        loaderState: false,
                    })
                } else {
                    this.setState({ loaderState: false })
                }
            })
        }
        if (data.target.value == 'moneyReceivedAnalysis') {
            this.setState({ loaderState: true, datePickerState: false })

            getFinBitData(allDetail.userId, 'moneyReceivedAnalysis', this.state.regenerate, callBack => {
                if (callBack != 'no data') {
                    var x = callBack
                    var y = [...x].reverse();
                    this.setState({
                        finBitData: y,
                        finBitDataNew: y,
                        viewFalse: true,
                        type: 'moneyReceivedAnalysis',
                        loaderState: false,
                    })
                } else {
                    this.setState({ loaderState: false })
                }
            })
        }
        if (data.target.value == 'moneyPaidAnalysis') {
            this.setState({ loaderState: true, datePickerState: false })

            getFinBitData(allDetail.userId, 'moneyPaidAnalysis', this.state.regenerate, callBack => {
                if (callBack != 'no data') {
                    var x = callBack
                    var y = [...x].reverse();
                    this.setState({
                        finBitData: y,
                        finBitDataNew: y,
                        viewFalse: true,
                        type: 'moneyPaidAnalysis',
                        loaderState: false,
                    })
                } else {
                    this.setState({ loaderState: false })
                }
            })
        }
        if (data.target.value == 'penaltyAnalysis') {
            this.setState({ loaderState: true, datePickerState: false })

            getFinBitData(allDetail.userId, 'penaltyAnalysis', this.state.regenerate, callBack => {
                if (callBack != 'no data') {
                    var x = callBack
                    var y = [...x].reverse();
                    this.setState({
                        finBitDataNew: y,
                        finBitDataNew: y,
                        viewFalse: true,
                        type: 'penaltyAnalysis',
                        loaderState: false,
                    })
                } else {
                    this.setState({ loaderState: false })
                }
            })
        }
        if (data.target.value == 'loanRepayment') {
            this.setState({ loaderState: true, datePickerState: false })

            getFinBitData(allDetail.userId, 'loanRepayment', this.state.regenerate, callBack => {
                if (callBack != 'no data') {
                    var x = callBack
                    var y = [...x].reverse();

                    this.setState({
                        finBitDataNew: y,
                        viewFalse: true,
                        type: 'loanRepayment',
                        loaderState: false,
                    })
                } else {
                    this.setState({ loaderState: false })
                }
            })
        }
        if (data.target.value == 'averageUtilizationOcNCc') {
            this.setState({ loaderState: true, datePickerState: false })

            getFinBitData(allDetail.userId, 'averageUtilizationOcNCc', this.state.regenerate, callBack => {
                if (callBack != 'no data') {
                    var x = callBack
                    var y = [...x].reverse();
                    this.setState({
                        finBitData: y,
                        viewFalse: true,
                        type: 'averageUtilizationOcNCc',
                        loaderState: false,
                    })
                } else {
                    this.setState({ loaderState: false })
                }
            })
        }
        if (data.target.value == 'loanReceived') {
            this.setState({ loaderState: true, datePickerState: false })

            getFinBitData(allDetail.userId, 'loanReceived', this.state.regenerate, callBack => {
                if (callBack != 'no data') {
                    var x = callBack
                    var y = [...x].reverse();
                    this.setState({
                        finBitDataNew: y,
                        viewFalse: true,
                        type: 'loanReceived',
                        loaderState: false,
                    })
                } else {
                    this.setState({ loaderState: false })
                }
            })
        }
        if (data.target.value == 'frequentTransaction') {
            this.setState({ loaderState: true, datePickerState: false })

            getFinBitData(allDetail.userId, 'frequentTransaction', this.state.regenerate, callBack => {
                if (callBack != 'no data') {
                    var x = callBack
                    var y = [...x].reverse();
                    this.setState({
                        finBitDataNew: y,
                        viewFalse: true,
                        type: 'frequentTransaction',
                        loaderState: false,
                    })
                } else {
                    this.setState({ loaderState: false })
                }
            })
        }
        if (data.target.value == 'bounceTransactions') {
            this.setState({ loaderState: true, datePickerState: false })

            getFinBitData(allDetail.userId, 'bounceTransactions', this.state.regenerate, callBack => {
                if (callBack != 'no data') {
                    var x = callBack
                    var y = [...x].reverse();
                    this.setState({
                        finBitData: y,
                        viewFalse: true,
                        type: 'bounceTransactions',
                        loaderState: false,
                    })
                } else {
                    this.setState({ loaderState: false })
                }
            })
        }
        if (data.target.value == 'bounceTransactionsByDateRange') {
            this.setState({ loaderState: true, datePickerState: false })

            getFinBitData(allDetail.userId, 'bounceTransactionsByDateRange', this.state.regenerate, callBack => {
                if (callBack != 'no data') {
                    var x = callBack
                    var y = [...x].reverse();
                    this.setState({
                        finBitData: y,
                        viewFalse: true,
                        type: 'bounceTransactionsByDateRange',
                        loaderState: false,
                    })
                } else {
                    this.setState({ loaderState: false })
                }
            })
        }
        if (data.target.value == 'sanctionedAmount') {
            this.setState({ loaderState: true, datePickerState: false })

            getFinBitData(allDetail.userId, 'sanctionedAmount', this.state.regenerate, callBack => {
                if (callBack != 'no data') {
                    var x = callBack
                    var y = [...x].reverse();
                    this.setState({
                        finBitData: y,
                        finBitDataNew: y,
                        viewFalse: true,
                        type: 'sanctionedAmount',
                        loaderState: false,
                    })
                } else {
                    this.setState({ loaderState: false })
                }
            })
        }
        if (data.target.value == 'ecsNNach') {
            this.setState({ loaderState: true, datePickerState: false })

            getFinBitData(allDetail.userId, 'ecsNNach', this.state.regenerate, callBack => {
                if (callBack != 'no data') {
                    var x = callBack
                    var y = [...x].reverse();
                    this.setState({
                        finBitDataNew: y,
                        viewFalse: true,
                        type: 'ecsNNach',
                        loaderState: false,
                    })
                } else {
                    this.setState({ loaderState: false })
                }
            })
        }
        if (data.target.value == 'creditCardPayment') {
            this.setState({ loaderState: true, datePickerState: false })

            getFinBitData(allDetail.userId, 'creditCardPayment', this.state.regenerate, callBack => {
                if (callBack != 'no data') {
                    var x = callBack
                    var y = [...x].reverse();
                    this.setState({
                        finBitDataNew: y,
                        viewFalse: true,
                        type: 'creditCardPayment',
                        loaderState: false,
                    })
                } else {
                    this.setState({ loaderState: false })
                }
            })
        }
        if (data.target.value == 'averageBankBalancePerMonth') {
            this.setState({ loaderState: true, datePickerState: false })

            getFinBitData(allDetail.userId, 'averageBankBalancePerMonth', this.state.regenerate, callBack => {
                if (callBack != 'no data') {
                    var x = callBack
                    var y = [...x].reverse();
                    this.setState({
                        finBitData: y,
                        viewFalse: true,
                        type: 'averageBankBalancePerMonth',
                        loaderState: false,
                    })
                } else {
                    this.setState({ loaderState: false })
                }
            })
        }
        if (data.target.value == 'monthSinceLastBounce') {
            this.setState({ loaderState: true, datePickerState: false })

            getFinBitData(allDetail.userId, 'monthSinceLastBounce', this.state.regenerate, callBack => {
                if (callBack != 'no data') {
                    var x = callBack
                    var y = [...x].reverse();
                    this.setState({
                        finBitData: y,
                        viewFalse: true,
                        type: 'monthSinceLastBounce',
                        loaderState: false,
                    })
                } else {
                    this.setState({ loaderState: false })
                }
            })
        }
        if (data.target.value == 'checkInCompleteBankStatementDateDuration') {
            this.setState({ loaderState: true, datePickerState: false })

            getFinBitData(allDetail.userId, 'checkInCompleteBankStatementDateDuration', this.state.regenerate, callBack => {
                if (callBack != 'no data') {
                    var x = callBack
                    var y = [...x].reverse();
                    this.setState({
                        finBitData: y,
                        viewFalse: true,
                        type: 'checkInCompleteBankStatementDateDuration',
                        loaderState: false,
                    })
                } else {
                    this.setState({ loaderState: false })
                }
            })
        }
        if (data.target.value == 'summaryForSavingAccount') {
            this.setState({ loaderState: true, datePickerState: false })

            getFinBitData(allDetail.userId, 'summaryForSavingAccount', this.state.regenerate, callBack => {
                if (callBack != 'no data') {
                    var x = callBack.transactions
                    var y = [...x].reverse();
                    this.setState({
                        fraudData: callBack,
                        finBitDataNew: y,
                        viewFalse: true,
                        type: 'summaryForSavingAccount',
                        loaderState: false,
                    })
                } else {
                    this.setState({ loaderState: false })
                }
            })
        }
        if (data.target.value == 'bankAccountSummary') {
            this.setState({ loaderState: true, datePickerState: true })


            getFinBitDataNew(allDetail.userId, 'bankAccountSummary', this.state.startDate, this.state.endDate, this.state.regenerate, callBack => {
                if (callBack != 'no data') {
                    var x = callBack
                    var y = [...x].reverse();
                    this.setState({
                        type: 'bankAccountSummary',
                        finBitData: y,
                        viewFalse: true,
                        loaderState: false,
                    })
                } else {
                    this.setState({ loaderState: false })
                }
            })
        }
    }
    finterChangeHandler(e) {
        this.setState({ selectedFilter: e.target.value })
    }
    ConfirmCrifDataGenerate(type) {
        if (type === 'yes') {
            this.setState({ regenerate: 1 }, () => this.getData())
        }
        if (type === 'no') {
            this.setState({ regenerate: 0 }, () => this.getData())
        }

    }
    handleChangeNewPagination(e, value) {
        this.setState({ pageNumber: value })
        const newMinPagination = (value - 1) * paginationNumber;
        const newMaxPagination = value * paginationNumber;
        this.setState({ tableMaxPagination: newMaxPagination, tableMinPagination: newMinPagination })


    };
    setViewPage(data) {
        this.setState({ viewPage: data })
    }
    saveUnderWritter = (type) => {
        const { allDetail, admin } = this.props

        let decisionType = 'financialDecision'
        if (this.state.underWriterStatus !== '' && decisionType !== '') {
            saveUnderWriterApi(decisionType, allDetail, this.state.underWriterStatus, admin, callBack => {
                if (callBack === 'success') {
                    this.setState({ underWriterStatus: '', popupstate: true, popupStatus: 'successfully saved !' }, () => this.removePopup())
                } else {
                    this.setState({ underWriterStatus: '', popupstate: true, popupStatus: 'please try again later !' }, () => this.removePopup())
                }
            })
        } else {
            this.setState({ underWriterStatus: '', popupstate: true, popupStatus: 'Please select value !' }, () => this.removePopup())
        }
    }
    removePopup = () => {
        setTimeout(() => {
            this.setState({ popupstate: false })
        }, 5000);
    }
    underWriterHandler = (e) => {
        this.setState({ underWriterStatus: e.target.value })
    }
    closepopupSide = () => {
        this.setState({ popupstate: false })

    }
    openCommentPopup = (data) => {
        const { allDetail } = this.props
        this.setState({ notesPopup: true, notesType: this.state.filterType })
        getNotesnew(allDetail.loanId, allDetail.userId, this.state.filterType, callBack => {
            this.setState({ newNotes: callBack })
        })

    }
    closepopup = () => {

        this.setState({ notesPopup: false, notesText: '', newNotes: '' })
    }
    saveNotesType = () => {
        const { allDetail, admin } = this.props
        if (this.state.notesText !== '' && this.state.notesText !== undefined && this.state.notesText !== null) {
            saveCommentForCreditAssesment(allDetail.loanId, allDetail.userId, this.state.notesText, admin.emailId, this.state.filterType, callBack => {
                this.setState({ notesPopup: false })
                if (callBack === 'success') {
                    getNotesnew(allDetail.loanId, allDetail.userId, this.state.filterType, callBack => {
                        this.setState({ newNotes: callBack })
                    })
                }

            })
        }
    }
    commentChangeHandler = (e, type) => {
        this.setState({ notesText: e.target.value, notesType: type })


    }
    addSuffixToDay(date) {
        const day = date;
        let suffix;

        if (day >= 11 && day <= 13) {
            suffix = "th";
        } else {
            switch (day % 10) {
                case 1:
                    suffix = "st";
                    break;
                case 2:
                    suffix = "nd";
                    break;
                case 3:
                    suffix = "rd";
                    break;
                default:
                    suffix = "th";
            }
        }

        return day + suffix;
    }
    zoomPage() {
        if (this.state.zoomState) {
            this.setState({ zoomState: false })
        }
        if (this.state.zoomState === false) {
            this.setState({ zoomState: true })
        }
    }
    render() {
        const { admin, allDetail } = this.props
        let filterData = this.state.finBitDataNew
        if (this.state.selectedFilter && this.state.selectedFilter != '' && filterData != null && filterData != '' && filterData.length > 0) {
            filterData = filterData.filter((row) => {
                if (row.description != null) {
                    return row.description.toLowerCase().includes(this.state.selectedFilter.toLowerCase())
                }
            })
        }
        return (
            <div>
                {this.state.notesPopup ?
                    <div className="background-blur">

                        <div className="popup-main small-popup container-fluid max-width-500px   " style={{ padding: '20px', borderRadius: '10px' }}>
                            <i onClick={() => this.closepopup()} style={{ float: "right", marginTop: '-18px', marginRight: '-17px', fontSize: '20px', color: 'red' }} className="fa fa-times-circle"></i>
                            <div className="row from-group">
                                <div className="col-xs-12" style={{ padding: '10px' }}>
                                    <h5 className="" style={{ marginBottom: '10px', marginTop: '10px', fontWeight: '700' }}><i style={{ fontWeight: '700' }} className='fa fa-commenting-o'></i>&nbsp;&nbsp; Comment</h5>
                                </div>
                            </div>
                            <div style={{ background: '#F1F7FF', border: '1px solid #DBE9FF', padding: '10px', borderRadius: '12px' }}>

                                <textarea className='form-control' placeholder='type here...' onChange={(e) => this.commentChangeHandler(e, this.state.notesType)} />
                                <div className='text-right'>
                                    <button className="btn btn-primary" style={{ marginTop: '10px', borderRadius: '5px', border: '1px solid #D3D9E9', boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)', background: '#2B78FF' }} onClick={() => this.saveNotesType()}>Save</button>
                                </div>
                            </div>
                            {this.state.newNotes !== '' && this.state.newNotes.length > 0 ? <h5 style={{ fontWeight: '700' }}> Previous Comments</h5> : ""}
                            {this.state.newNotes !== '' && this.state.newNotes.length > 0 ?
                                <div style={{
                                    borderRadius: '10px',
                                    border: '1px solid #D1D9E2',
                                    background: '#fff',
                                    maxHeight: '200px',
                                    overflowY: 'scroll'
                                }}>

                                    {this.state.newNotes.map((data, i) => {
                                        return (
                                            <div className='container-fluid'>
                                                <div className='row' style={{ borderBottom: '1px solid #D1D9E2', padding: '10px' }} key={i}>
                                                    <div className='col-sm-6 col-xs-6'>
                                                        {data.notes}<br />
                                                        <span style={{ color: 'rgba(0, 0, 0, 0.45)', fontSize: '10px' }}>{data.empId}</span>
                                                    </div>
                                                    <div className='col-sm-6 col-xs-6 text-right'>
                                                        <span style={{ color: 'rgba(0, 0, 0, 0.45)', fontSize: '10px' }}>{data.addedOn ? moment(data.addedOn).format('DD-MM-YYYY') : ""}</span>
                                                    </div>

                                                </div>
                                                {/* <hr /> */}
                                            </div>
                                        )
                                    })}
                                </div>
                                : ""}

                        </div>
                    </div>
                    : ""}
                {this.state.popupstate ?
                    <PopUpNew popupStatus={this.state.popupStatus} closePopup={this.closepopupSide.bind(this)} />
                    : ""}
                {this.state.finbitPopup ?
                    <CheckReport admin={admin} ConfirmCrifDataGenerate={this.ConfirmCrifDataGenerate.bind(this)} />
                    : ""}
                {this.state.loaderState ?
                    <Loader />
                    : ""}
                {this.state.popupStateError ?
                    <PopUp
                        popupStatus={this.state.popupStatus}
                        closePopup={this.closePopup.bind(this)} />
                    : ""}
                {this.state.popupStateSuccess ?
                    <PopupSuccess
                        popupStatus={this.state.popupStatus}
                        closePopup={this.closePopup.bind(this)} />
                    : ""}
                {this.state.finbitPopup === false ?
                    <Finbitui
                    ConfirmCrifDataGenerate={this.ConfirmCrifDataGenerate.bind(this)}
                        zoomPage={this.zoomPage.bind(this)}
                        zoomState={this.state.zoomState}
                        allDetail={allDetail}
                        jsondata={jsondata}
                        selectData={this.selectData.bind(this)}
                        suffixDate={this.state.suffixDate}
                        notesPopup={this.state.notesPopup}
                        openCommentPopup={this.openCommentPopup}
                        underWriterHandler={this.underWriterHandler}
                        saveUnderWritter={this.saveUnderWritter}
                        newNotes={this.state.newNotes}
                        setViewPage={this.setViewPage.bind(this)}
                        viewPage={this.state.viewPage}
                        pageNumber={this.state.pageNumber}
                        handleChangeNewPagination={this.handleChangeNewPagination.bind(this)}
                        analysisData={this.state.analysisData}
                        fraudData={this.state.fraudData}
                        bankDataFibit={this.state.bankDataFibit}
                        finterChangeHandler={this.finterChangeHandler.bind(this)}
                        datePickerState={this.state.datePickerState}
                        searchHandler={this.searchHandler.bind(this)}
                        onChangeHandlerDate={this.onChangeHandlerDate.bind(this)}
                        startDate={this.state.startDate}
                        endDate={this.state.endDate}
                        finBitDataNew={filterData}
                        // finBitDataNew={this.state.finBitDataNew}
                        type={this.state.type}
                        viewFalse={this.state.viewFalse}
                        typeHandler={this.typeHandler.bind(this)}
                        phocketTablePagination={this.phocketTablePagination.bind(this)}
                        tableMinPagination={this.state.tableMinPagination}
                        tableMaxPagination={this.state.tableMaxPagination}
                        finBitData={this.state.finBitData}
                    />
                    : ""}
            </div>
        )
    }
}
export default FinBit