import React from 'react'

const SoftApprovePopup = ({ closePopup, dataChange, disabled, dataHandler, nbfcState,
    nbfcList }) => (
        <div className="">
            <div className="background-blur"></div>
            <div className="popup-main small-popup container-fluid dbl-border overflow-auto">
                <div className="row from-group">
                    <div className="col-xs-12">
                        <button className="close" onClick={e => closePopup()}>&times;</button>
                        <h4 className="text-center">Soft Approve</h4>
                    </div>
                </div>
                <div className="row form-group">
                    <div className="col-xs-12">
                        <label className="font-12px">Select NBFC</label>
                        <select className="form-control" value={nbfcState} onChange={(e) => dataChange(e)}>
                            <optgroup label="NBFC">
                                <option value="">Select NBFC</option>
                                {nbfcList != null && nbfcList.length > 0 ?
                                    nbfcList.map((nbfc, i) => {
                                        return (
                                            <option key={i} value={nbfc.name}>{nbfc.name}</option>
                                        )
                                    })
                                    : null}
                            </optgroup>
                        </select>
                    </div>
                </div>
                <div className="row from-group text-center">
                    <div className="col-xs-12">
                        <button className="profile-save-btn" disabled={disabled} onClick={e => dataHandler()}>SAVE</button>
                    </div>
                </div>
            </div>
        </div>
    )

export default SoftApprovePopup;