import React, { Component } from 'react'
import BulkStatusUi from '../../../presentationals/Admin/Utility/BulkStatusUpdate.component'
import { csvReader } from '../../../../utils/commonUtility'
import { uploadCSVFileApi } from '../AdminActionCreator.component'
import Popup from '../../../presentationals/Popup/Popup.component';
import PaidPopup from '../CustomerPages/Paid.popup'
import { APIS } from '../../../../utils/api-factory';

let paginationNumber = 10;
let dataForSave = []

class BulkStatus extends Component {
    constructor(props) {
        super(props);
        this.state = {
            csvDataArray: [],
            popupState: false,
            popupStatus: '',
            tableMinPagination: 0,
            tableMaxPagination: paginationNumber,
            search: '',
            dataForSave: [],
            csvFile: '',
            paidPopup: false,
            dataIndex: 0,
            automationData: [],
            automationState: true
        }
    }

    render() {
        const { admin } = this.props
        let csvDataArray = this.state.csvDataArray
        if (this.state.search) {
            csvDataArray = csvDataArray.filter(row => {
                if (row.mobileNumber != null && row.mobileNumber != "" && row.emailId != null && row.emailId != "") {
                    return row.mobileNumber.toLowerCase().includes(this.state.search.toLowerCase()) ||
                        row.emailId.toLowerCase().includes(this.state.search.toLowerCase())
                }
            })
        }
        return (
            <div>
                {this.state.popupState == true ?
                    <Popup
                        closePopup={this.closePopup.bind(this)}
                        popupStatus={this.state.popupStatus}
                    />
                    : null}
                <BulkStatusUi
                    handleForce={this.handleForce.bind(this)}
                    csvDataArray={csvDataArray}
                    saveCsvData={this.saveCsvData.bind(this)}
                    phocketTablePagination={this.phocketTablePagination.bind(this)}
                    tableMinPagination={this.state.tableMinPagination}
                    tableMaxPagination={this.state.tableMaxPagination}
                />
                {this.state.paidPopup ?
                    <PaidPopup
                        admin={admin}
                        allDetail={this.state.allDetail}
                        closePaidPopup={this.closePaidPopup.bind(this)}
                        automationState={this.state.automationState}
                        automationData={this.state.automationData} />
                    : null}
            </div>
        )
    }

    phocketTablePagination(data, type) {
        if (type == 'back') {
            if (this.state.tableMinPagination >= paginationNumber) {
                this.setState({
                    tableMinPagination: this.state.tableMinPagination - paginationNumber,
                    tableMaxPagination: this.state.tableMaxPagination - paginationNumber,
                })
            }
        } else if (type == 'forward') {
            if (this.state.tableMaxPagination < data.length) {
                this.setState({
                    tableMaxPagination: this.state.tableMaxPagination + paginationNumber,
                    tableMinPagination: this.state.tableMinPagination + paginationNumber
                })
            }
        }
    }

    handleForce(data) {
        this.setState({
            csvDataArray: csvReader(data),
        })
    };

    saveCsvData() {
        if (Object.keys(this.state.csvDataArray[0])[0] == 'applicationId') {
            if (this.state.dataIndex < this.state.csvDataArray.length) {
                fetch(APIS.GET_DATA_FOR_BULK_UPDATE + this.state.csvDataArray[this.state.dataIndex].applicationId)
                    .then(res => res.json())
                    .then(res => {
                        this.setState({
                            paidPopup: true,
                            automationData: this.state.csvDataArray[this.state.dataIndex],
                            allDetail: res,
                        })
                    })
            }
        } else {
            this.setState({
                popupState: true,
                popupStatus: 'CSV first column must be applicationId',
            })
            this.removePopup()
        }
    }

    closePaidPopup(callBack) {
        this.setState({
            paidPopup: false,
            dataIndex: this.state.dataIndex + 1,
        }, () => this.saveCsvData())
    }

    removePopup() {
        setTimeout(function () {
            this.setState({ popupState: false });
        }.bind(this), 5000)
    }

    closePopup() {
        this.setState({ popupState: false });
    }
}

export default BulkStatus;