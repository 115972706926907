import React from 'react'
import DotLoader from '../../Loader/layerLoader.component'
import Chips, { Chip } from 'react-chips'

const PriorityCompany = ({ allCompanyList, searchHandler, phocketTablePagination,
    tableMinPagination, tableMaxPagination, addCompany, addCompanyChange, newCompanies }) => (
        <div className="container-fluid">
            <div className="row">
                <h3 className="text-center blue-text">PRIORITY COMPANY</h3>
            </div>
            <div className="row">
                <div className="col-sm-6 col-xs-12">
                    <label className="font-12px">Add Company</label>
                    {/* <input className='form-control' onChange={e => addCompanyChange(e)}/> */}
                    <Chips
                        value={newCompanies}
                        onChange={e => addCompanyChange(e)}
                        suggestions={[]}
                        createChipKeys={[13]}
                    />
                    <button className="btn profile-save-btn center-block" onClick={e => addCompany()}>Save</button>
                </div>
                <div className="col-sm-6 col-xs-12">
                    <span className="pull-right">
                        <input type="text" className="form-control" onChange={e => searchHandler(e)} placeholder="Search" />
                    </span>
                    {allCompanyList != null && allCompanyList.length > 0 ?
                        <div>
                            <table className="phocket-table">
                                <thead>
                                    <tr>
                                        <th>Company Name</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {allCompanyList.slice(tableMinPagination, tableMaxPagination).map((company, i) => {
                                        return (
                                            <tr key={i}>
                                                <td>{company}</td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-sm-4 col-xs-12 no-padding"><button type="button" className="btn btn-default fa fa-chevron-left btn-lg btn-block grey  " onClick={e => phocketTablePagination(allCompanyList, 'back')}></button></div>
                                    <div className="col-sm-4 col-xs-12 text-center phoc" >{(tableMinPagination + 1) + ' - ' + (tableMaxPagination) + ' of ' + allCompanyList.length}</div>
                                    <div className="col-sm-4 col-xs-12 no-padding" ><button type="button" className="btn btn-default fa fa-chevron-right btn-lg btn-block grey" onClick={e => phocketTablePagination(allCompanyList, 'forward')}></button></div>
                                </div>
                            </div>
                        </div>
                        : <DotLoader />}
                </div>
            </div>
        </div>
    )

export default PriorityCompany;