import React, { Component } from 'react'
import AppStatus from '../../../presentationals/Admin/ApplicationLeads/ApplicationStatus.component';
import { connect } from 'react-redux';
import { APIS } from '../../../../utils/api-factory';
import ConfirmationPopup from '../../../presentationals/Popup/ConfirmationPopup.component'
import EditUserDetail from '../CustomerPages/EditUserDetails.container'
import Popup from '../../../presentationals/Popup/Popup.component'
import { bindActionCreators } from 'redux';
import { getAllDetail, getAllDetailByMail, getAllDetailByMobile, mobileVerifyApi, getAllTeaGardenApi } from '../AdminActionCreator.component';
var sortJsonArray = require('sort-json-array');


let isDeleteUser = false
let paginationNumber=10
class ApplicationStatus extends Component {
    constructor(props) {
        super(props);
        this.state = {
            forwordPageState: 1,
            confirmationPopup: false,
            confirmationText: '',
            userIdForDelete: '',
            loadarState: false,
            FilteredData: [],
            pageIndexToShow: 0,
            allDetail: '',
            userDetails: '',
            viewState: "applicationStatus",
            userOtherInfo: false,
            isError: false,
            confirmationPopupType: '',
            userIdForUnsubscribe: '',
            dataChange: '',
            newData: '',
            popupStatus: '',
            popupState: false,
            teaGardenId: '',
            employeeId:'',
            teaGardenData: [
                // {
                //     "id": 1,
                //     "teaGardenId": "kCV6VBiWe9FlUQTuiBuBoASA7NsgkRAs",
                //     "addedOn": 1653211520747,
                //     "updatedOn": 1653211520747,
                //     "tea_garden_name": "Tea Garden NE",
                //     "emailId": "teagardenne@phocket.in",
                //     "pwd": "teagardenne@123"
                // }
            ],
            tableMinPagination: 0,
            tableMaxPagination: paginationNumber,

        }
    }
    teaChangeHandler(e,type) {
        if(type==='teaGardenId'){
        this.setState({ teaGardenId: e.target.value }, () => this.getUpdatedApplications())
        }
        if(type==='employeeId'){
            this.setState({ employeeId: e.target.value }, () => this.getUpdatedApplications())
            }
    }
    getTeaGardens() {
        getAllTeaGardenApi(1, 500, callBack => {
            this.setState({ teaGardenData: callBack })
        })
    }
    getUpdatedApplications() {
        this.setState({ loadarState: true })
        fetch(APIS.UPDATED_APPLICATION_STATUS + this.state.forwordPageState + '&teaGardenId=' + this.state.teaGardenId +'&teaGardenEmpId='+this.state.employeeId)
            .then(res => res.json())
            .then(res => {
                this.setState({ newData: res, loadarState: false })
            })
    }
    componentDidMount() {
        this.getUpdatedApplications()

        this.getTeaGardens()
    }

    componentWillMount() {
        // this.getAllDetail()
    }

    getAllDetail() {
        this.setState({ loadarState: true })
        this.props.getAllDetail(this.state.forwordPageState,
            (callBack) => {
                if (callBack == "error") {
                    this.setState({
                        isError: true
                    })
                } else {
                    this.setState({ loadarState: false })
                }
            });
    }

    forwordpage() {
        this.setState({ forwordPageState: this.state.forwordPageState + 1 }, () => this.getAllDetail())
    }

    backpage() {
        if (this.state.forwordPageState > 1) {
            this.setState({ forwordPageState: this.state.forwordPageState - 1 }, () => this.getAllDetail())
        }
    }
    phocketTablePagination(data, type) {
        if (type == 'back') {
            if (this.state.tableMinPagination >= paginationNumber) {
                this.setState({
                    tableMinPagination: this.state.tableMinPagination - paginationNumber,
                    tableMaxPagination: this.state.tableMaxPagination - paginationNumber,
                })
            }
        } else if (type == 'forward') {
            if (this.state.tableMaxPagination < data.length) {
                this.setState({
                    tableMaxPagination: this.state.tableMaxPagination + paginationNumber,
                    tableMinPagination: this.state.tableMinPagination + paginationNumber
                })
            }
        }
    }
    paginationHandler(type) {
        if (type == 'left') {
            if (this.state.forwordPageState > 1) {
                this.setState({
                    forwordPageState: this.state.forwordPageState - 1,
                    tableMinPagination: 0,
                    tableMaxPagination: paginationNumber,
                }, () => this.getUpdatedApplications())
            }
        } 
         if (type == 'right') {
            this.setState({
                forwordPageState: this.state.forwordPageState + 1,
                tableMinPagination: 0,
                tableMaxPagination: paginationNumber,
            }, () => this.getUpdatedApplications())
        }
    }

    componentDidUpdate() {
        const { allUserDetail } = this.props;
        if (allUserDetail.length > 0) {
            if (document.getElementById("forword") != null)
                document.getElementById("forword").disabled = false;
        } else {
            if (document.getElementById("forword") != null)
                document.getElementById("forword").disabled = true;
        }
    }
    closePopup() {
        this.setState({ popupState: false })
    }
    removePopup() {
        setTimeout(function () {
            this.setState({ popupState: false });
        }.bind(this), 5000)
    }

    render() {
        const { allUserDetail, admin } = this.props;
        if (admin != null) {
            if (admin.role != null && admin.role == 'superuser') {
                isDeleteUser = true
            }
        }
        return (
            <div className="full-height">
                {this.state.viewState == 'applicationStatus' ?
                    <div>
                        {this.state.isError ?
                            <h3 className="text-center blue-text">Something Went Wrong</h3>
                            : <AppStatus
                            paginationHandler={this.paginationHandler.bind(this)}
                            phocketTablePagination={this.phocketTablePagination.bind(this)}
                            tableMaxPagination={this.state.tableMaxPagination}
                            tableMinPagination={this.state.tableMinPagination}
                            admin={admin}
                                teaChangeHandler={this.teaChangeHandler.bind(this)}
                                teaGardenId={this.state.teaGardenId}
                                teaGardenData={this.state.teaGardenData}
                                varifyMobile={this.varifyMobile.bind(this)}
                                forwordpage={this.forwordpage.bind(this)}
                                backpage={this.backpage.bind(this)}
                                allUserDetail={this.state.newData}
                                // allUserDetail={allUserDetail != null ? sortJsonArray(allUserDetail, 'loginDate', 'des') : ""}
                                dataFilter={this.dataFilter.bind(this)}
                                deleteUserHandler={this.deleteUserHandler.bind(this)}
                                isDeleteUser={isDeleteUser}
                                getFilterValue={this.getFilterValue.bind(this)}
                                FilteredData={this.state.FilteredData}
                                getPageValue={this.getPageValue.bind(this)}
                                pageIndexToShow={this.state.pageIndexToShow}
                                editUserHandler={this.editUserHandler.bind(this)}
                                loadarState={this.state.loadarState}
                                unsubscribeHandler={this.unsubscribeHandler.bind(this)}
                            />}
                    </div>
                    : this.state.viewState == 'editDetails' ?
                        <div>
                            <h3 className="text-center blue-text">
                                <span className="fa fa-chevron-left pull-left pointer" onClick={(e) => this.ediDetailBackHandler()} />
                                <strong className="teal-text text-uppercase">{this.state.userDetails.firstName}  ({this.state.userDetails.applicationId})</strong>
                            </h3>
                            <EditUserDetail
                                getData={this.getData.bind(this)}
                                admin={admin}
                                allDetail={this.state.allDetail}
                                userDetails={this.state.userDetails}
                                userOtherInfo={this.state.userOtherInfo}
                            />
                        </div>
                        : null}
                {this.state.popupState ?
                    <Popup popupStatus={this.state.popupStatus} />
                    : ""}
                {this.state.confirmationPopup == true ?
                    <ConfirmationPopup
                        cancelLoanConfirm={this.cancelLoanConfirm.bind(this)}
                        confirmationText={this.state.confirmationText} />
                    : ""}
            </div>
        )
    }
    getData(val) {
        this.setState({ dataChange: val })
    }
    unsubscribeHandler(userId) {
        this.setState({
            userIdForUnsubscribe: userId,
            confirmationPopupType: 'unsubscribe',
            confirmationPopup: true,
            confirmationText: 'Are you sure to Unsubscribe this user'
        })
    }

    editUserHandler(userDetails) {
        this.setState({ loadarState: true })
        fetch(APIS.APPLICATION_STATUS_DETAIL_USER + userDetails.userId)


            .then(res => res.json())
            .then(res => {
                this.setState({
                    loadarState: false,
                    allDetail: res,
                    userDetails: userDetails,
                    viewState: 'editDetails'
                })
            })
    }

    ediDetailBackHandler() {
        this.setState({
            viewState: 'applicationStatus'
        })
    }

    getFilterValue(filtered) {
        this.setState({ FilteredData: filtered })
    }

    getPageValue(pageIndex) {
        this.setState({ pageIndexToShow: pageIndex })
    }

    deleteUserHandler(userId) {
        this.setState({
            userIdForDelete: userId,
            confirmationPopupType: 'delete',
            confirmationPopup: true,
            confirmationText: 'Are you sure to Delete this user'
        })
    }
    varifyMobile(userId) {
        this.setState({
            userIdForDelete: userId,
            confirmationPopupType: 'Verify',
            confirmationPopup: true,
            confirmationText: 'Are you sure to Verify Mobile Number'
        })
    }

    cancelLoanConfirm(conType) {
        if (conType == 'yes') {
            if (this.state.confirmationPopupType == 'delete') {
                fetch(APIS.DELETE_COMPLETE_USER + this.state.userIdForDelete)
                    .then(res => res.text())
                    .then(text => {
                        if (text == 'success') {
                            this.setState({ confirmationPopup: false })
                            this.getAllDetail()
                        }
                    })
            } else if (this.state.confirmationPopupType == 'unsubscribe') {
                fetch(APIS.UNSUBSCRIBE_MAIL + this.state.userIdForUnsubscribe)
                    .then(res => res.text())
                    .then(text => {
                        if (text == 'success') {
                            this.setState({ confirmationPopup: false })
                        }
                    })
            }
            else if (this.state.confirmationPopupType == 'Verify') {
                this.setState({ loadarState: true })
                mobileVerifyApi(this.state.userIdForDelete, callBack => {
                    if (callBack === 'success') {
                        this.setState({ loadarState: false, confirmationPopup: false, popupState: true, popupStatus: 'Successfully Verified!' }, () => this.removePopup())
                    } else {
                        this.setState({ loadarState: false, confirmationPopup: false, popupState: true, popupStatus: 'Not Verified!' }, () => this.removePopup())

                    }
                })
              
            }
        } else if (conType == 'no') {
            this.setState({ confirmationPopup: false })
        }
    }

    dataFilter(e, filterType) {
        if (filterType == 'email') {
            let email = e.target.value
            this.setState({ loadarState: true })
            if (email.length > 4) {
                this.props.getAllDetailByMail(email, this.state.forwordPageState,
                    (callBack) => {
                        this.setState({ loadarState: false, newData: callBack })
                    });
            }
        } else if (filterType == 'mobileNo') {
            let mobile = e.target.value
            this.setState({ loadarState: true })
            if (mobile.length == 10) {
                this.props.getAllDetailByMobile(mobile, this.state.forwordPageState,
                    (callBack) => {
                        this.setState({ loadarState: false, newData: callBack })
                    });
            }
        }
        if (e.target.value == "") {
            this.getUpdatedApplications()
        }
    }
}

const mapStateToProps = ({ allUserDetail }) => {
    return {
        allUserDetail: allUserDetail,
    };
};

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        getAllDetail,
        getAllDetailByMail,
        getAllDetailByMobile
    }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(ApplicationStatus);

