import React, { useEffect, useState } from "react";
import "./../compo.scss";
import List from "../../maketingimages/list.png";
import communication from "../../maketingimages/communication.png";
import Filter from "../../maketingimages/filter.png";
import {
  formatDate,
  monthLastDate,
  monthStartDate,
  reverseFormat,
  todayDate,
} from "../utils/utils";
import { Pagination } from "@mui/material";
import {
  getDisbursedCountStatsByPartnerId,
  getAppliedDataByPartnerIdAndLoanStatus,
} from "../actioncreator";

const DisbursedLeadList = ({aggregator}) => {
  const [disbursedLeads, setDisbursedLeads] = useState([]);
  const [totalDisbursedLeads, setTotalDisbursedLeads] = useState('');
  const [page, setPage] = useState(1);
  const [count, setcount] = useState(10);
  const [objectData, setObjectData] = useState('')

  const [payload, setPayload] = useState({
    partnerId: aggregator.partnerId,
    filter: "Daily",
    fromDate: monthStartDate(),
    toDate: todayDate(),
    loanStatus: 107
  });
  const handleChange = (e) => {
    console.log(e.target.type);

    setPayload({
      ...payload,
      [e.target.name]:
        e.target.type === "date" ? formatDate(e.target.value) : e.target.value,
    });

    console.log(payload);
  };

  const getDisbursedCountStats = () => {
    getDisbursedCountStatsByPartnerId(
      payload,
      (callBack) => {
        
        setTotalDisbursedLeads(callBack.count);
      },
      (error) => {
        console.log(error);
      }
    );
  };
  const fetchAppliedDataWithLoanStatus = () => {
    getAppliedDataByPartnerIdAndLoanStatus(
      payload,
      page,
      count,

      (callBack) => {
        setObjectData(callBack)

      },
      (error) => {
        console.log(error);
      }
    );
  };

  useEffect(() => {
    getDisbursedCountStats();
    fetchAppliedDataWithLoanStatus();
  }, []);
  const changeHandler = (e,value) => {
    getAppliedDataByPartnerIdAndLoanStatus(
      payload,
      value,
      count,

      (callBack) => {
        setObjectData(callBack)

      },
      (error) => {
        console.log(error);
      }
    );
  }
  const applyFilter=()=>{
    getDisbursedCountStats();
    fetchAppliedDataWithLoanStatus();
  }

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          {/* <div className="col-sm-2 col-xs-12">
            <img src={Filter} width={20} height={20} alt="" />
            <span className="ms-3 filterHeaders">Filter</span>
            <select
              name=""
              id=""
              value={payload.filter}
              className="form-select"
              style={{ width: '100%', padding: '7px' }}
            >
              <option value="daily">Daily</option>
            </select>
          </div>
          <div style={{ position: 'absolute', marginLeft: '15.5%', marginTop: '30px' }}>::</div> */}
          <div className="col-sm-2 col-xs-12">
            From
            <input
              type="date"
              id="startDate"
              placeholder="DD/MM/YY"
              name="fromDate"
              value={reverseFormat(payload.fromDate)}
              onChange={handleChange}
              className=" form-control"
              style={{
                border: "1px solid gray",
                borderRadius: "6px",
                padding: "10px",
              }}
            />

          </div>
          <div style={{ position: 'absolute', marginLeft: '31%', marginTop: '30px' }}>-</div>

          <div className="col-sm-2 col-xs-12">
            <div className="filterHeaders" style={{ marginTop: '4px' }}>to</div>
            <input
              type="date"
              id="endDate"
              name="toDate"
              value={reverseFormat(payload.toDate)}
              onChange={handleChange}
              placeholder="DD/MM/YY"
              className=" form-control"
              style={{
                border: "1px solid gray",
                borderRadius: "6px",
                padding: "10px",
              }}
            />
          </div>
          <div className="col-sm-2 col-xs-12">
            <button
              className="btn btn-primary"
              style={{
                color: "#2B78FF",
                borderColor: "#2B78FF",
                backgroundColor: "white",
                marginTop: '27px',
                padding: '5px',
                borderRadius: '5px'

              }}
              onClick={()=>applyFilter()}
            >
              Apply Filter
            </button>
          </div>

        </div>
        <hr />

        <div
          className="mt-2"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div className="d-inline-flex" style={{ display: 'flex' }}>
            <img src={List} alt="" width={18} height={18} style={{ marginTop: '10px' }} />{" "} &nbsp;&nbsp;
            <h4 className="ms-3 leadBreakDown_Header">Disbursed Lead List</h4>
          </div>
          <h6 className="disbursedHeader">
            Total Disbursed Leads :{" "}
            <span className="disbursedAmountHeader">
              {totalDisbursedLeads || "  "}
            </span>
          </h6>
        </div>
        <div className='credit-table'>
          <table>
            <thead style={{ background: '#b9d8f9', color: '#000' }}>
              <tr className="thead-class">
                <th className="">Lead Name</th>
                <th className="">Application ID</th>
                <th className="">Mobile Number</th>
                <th className="">Approved Amount</th>
                <th className="">Loan Status</th>
              </tr>
            </thead>
            <tbody>
              {objectData !== '' && objectData !== undefined ? objectData.map((data, i) => {
                return (
                  <tr style={{ borderBottom: "none" }}>
                    <td scope="col">
                      {data.leadName}
                    </td>
                    <td>{data.applicationId}</td>
                    <td>{data.mobileNumber}</td>
                    <td>{data.loanApproveAmount}</td>
                    <td>{data.adminStatus}</td>

                  </tr>
                )
              }) : ""}

            </tbody>
          </table>
        </div>
        <div className="row">

          {/* <div className='col-sm-1 ' style={{ marginTop: '14px', textAlign: 'end' }} >
            <b > Page :</b>
          </div>
          <div className="col-sm-1">
            <select className="form-control">
              <option>1</option>
            </select>
          </div>
          <div className='col-sm-1 ' style={{ marginTop: '14px', textAlign: 'start' }} >
            <b > of &nbsp; {totalDisbursedLeads !== '' ? totalDisbursedLeads : ""}</b>
          </div> */}
          <div className="col-sm-8"></div>

          <div className="col-sm-4"> <div style={{ float: 'right', marginTop: '10px', marginBottom: '10px', marginRight: '40px' }}>
            <Pagination count={Math.ceil(totalDisbursedLeads / count)} color="primary"
              shape="circle"
              onChange={changeHandler}
            />
          </div></div>
        </div>

      </div>


    </>
  );
};

export default DisbursedLeadList;
