import React from 'react'

const AddressPage = ({ addressData }) => (
    <div className='container-fluid '>
        <div className='row' >
            <div className='col-sm-3 col-xs-12'></div>
            <div className='col-sm-6 col-xs-12 card-profile'>
                <h3 className='text-center'><b>Address Details</b></h3>
            <div className='col-sm-6 col-xs-12' style={{marginTop:'30px'}}>
                <div>  
                    <b style={{color:'gray'}}>Address 1</b>
                    <span style={{ float: 'right',color:'blue' }}> {addressData.address1}</span></div>
            </div>
            <div className='col-sm-6 col-xs-12' style={{marginTop:'30px'}}>
                <div >
                    <b style={{color:'gray'}}>Address 2</b>
                    <span style={{ float: 'right',color:'blue' }}>{addressData.address2}</span></div>
            </div>
            <div className='col-sm-6 col-xs-12' style={{marginTop:'30px'}}>
                <div >
                    <b style={{color:'gray'}}>Address 3</b>
                    <span style={{ float: 'right',color:'blue' }}>{addressData.address3}</span></div>
            </div>
            <div className='col-sm-6 col-xs-12' style={{marginTop:'30px'}}>
                <div >
                    <b style={{color:'gray'}}>City</b>
                    <span style={{ float: 'right',color:'blue' }}>{addressData.city}</span></div>
            </div> <div className='col-sm-6 col-xs-12' style={{marginTop:'30px'}}>
                <div >
                    <b style={{color:'gray'}}>State</b>
                    <span style={{ float: 'right',color:'blue' }}>{addressData.state}</span></div>
            </div> <div className='col-sm-6 col-xs-12' style={{marginTop:'30px'}}>
                <div >
                    <b style={{color:'gray'}}>Pincode</b>
                    <span style={{ float: 'right',color:'blue' }}>{addressData.pinCode}</span></div>
            </div> <div className='col-sm-6 col-xs-12' style={{marginTop:'30px'}}>
                <div >
                    <b style={{color:'gray'}}>Delivery Address Id</b>
                    <span style={{ float: 'right',color:'blue' }}>{addressData.deliveryAddressId}</span></div>
            </div> <div className='col-sm-6 col-xs-12' style={{marginTop:'30px'}}>
                <div >
                    <b style={{color:'gray'}}>Delivery Address 1</b>
                    <span style={{ float: 'right',color:'blue' }}>{addressData.deliveryAddress1}</span></div>
            </div> <div className='col-sm-6 col-xs-12' style={{marginTop:'30px'}}>
                <div >
                    <b style={{color:'gray'}}>Delivery Address 2</b>
                    <span style={{ float: 'right',color:'blue' }}>{addressData.deliveryAddress2}</span></div>
            </div> <div className='col-sm-6 col-xs-12' style={{marginTop:'30px'}}>
                <div >
                    <b style={{color:'gray'}}> Delivery Address 3</b>
                    <span style={{ float: 'right',color:'blue' }}>{addressData.deliveryAddress3}</span></div>
            </div> <div className='col-sm-6 col-xs-12' style={{marginTop:'30px'}}>
                <div >
                    <b style={{color:'gray'}}>Delivery City</b>
                    <span style={{ float: 'right',color:'blue' }}>{addressData.deliveryCity}</span></div>
            </div> 
            <div className='col-sm-6 col-xs-12' style={{marginTop:'30px'}}>
                <div >
                    <b style={{color:'gray'}}>Delivery State</b>
                    <span style={{ float: 'right',color:'blue' }}>{addressData.deliveryState}</span></div>
            </div>
            <div className='col-sm-6 col-xs-12' style={{marginTop:'30px'}}>
                <div >
                    <b style={{color:'gray'}}>Delivery PinCode</b>
                    <span style={{ float: 'right',color:'blue' }}>{addressData.deliveryPinCode}</span></div>
            </div>
            
           
          </div>
          </div>
    </div>
)


export default AddressPage