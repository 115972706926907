var filter = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
export const AddMerchantValidation = (merchantDetail) => {
    if (merchantDetail.merchantName === "" || merchantDetail.merchantName === null) {
        return { status: false, msg: 'Please enter Outlet Name', field: "merchantName" };
    }
    if (merchantDetail.contactPersonName === "" || merchantDetail.contactPersonName === null) {
        return { status: false, msg: 'Please enter Contact Person Name', field: "contactPersonName" };
    }
    if (merchantDetail.merchantMobile === "" || merchantDetail.merchantMobile === null) {
        return { status: false, msg: 'Please enter Contact Person Mobile No.', field: "merchantMobile" };
    }  else if (merchantDetail.merchantMobile.length > 10 || merchantDetail.merchantMobile.length < 10) {
        return { status: false, msg: 'Please enter a valid 10 digit Mobile No.', field: 'merchantMobile' };
    }
    if (merchantDetail.merchantEmail == '') {
        return { status: false, msg: 'Please Contact Person Email', field: 'merchantEmail' };
    } else if (!filter.test(merchantDetail.merchantEmail)) {
        return { status: false, msg: 'Please enter a valid Email', field: 'merchantEmail' };
    }
    if (merchantDetail.companyAddress === "" || merchantDetail.companyAddress === null) {
        return { status: false, msg: 'Please enter Comapny Address', field: "companyAddress" };
    }
    if (merchantDetail.SelectMerchentStatus === "" || merchantDetail.SelectMerchentStatus === null) {
        return { status: false, msg: 'Please select Status', field: "SelectMerchentStatus" };
    }
    if (merchantDetail.discountPriority === "" || merchantDetail.discountPriority === null) {
        return { status: false, msg: 'Please set Priority', field: "discountPriority" };
    }

    if (merchantDetail.location === "" || merchantDetail.location === null) {
        return { status: false, msg: 'Please select Status', field: "location" };
    }
    return { status: true, msg: ' ' };
}

export const AddLocationValidation = (merchantDetail) => {
    if (merchantDetail.location === "" || merchantDetail.location === null) {
        return { status: false, msg: 'Please Enter Location', field: "location" };
    }
    return { status: true, msg: ' ' };
}

export const AddAddressValidation = (merchantDetail) => {
    if (merchantDetail.locationId === "" || merchantDetail.locationId === null) {
        return { status: false, msg: 'Please Enter Location', field: "locationId" };
    }
    if (merchantDetail.merchantId === "" || merchantDetail.merchantId === null) {
        return { status: false, msg: 'Please Enter Outlet', field: "merchantId" };
    }
    if (merchantDetail.subLocationName === "" || merchantDetail.subLocationName === null) {
        return { status: false, msg: 'Please Enter Address', field: "subLocationName" };
    }
    return { status: true, msg: ' ' };
}

export const AddCategoryValidation = (merchantDetail) => {
    if (merchantDetail.promoCatName === "" || merchantDetail.promoCatName === null) {
        return { status: false, msg: 'Please Enter Category', field: "promoCatName" };
    }
    if (merchantDetail.promoCatStatus === "" || merchantDetail.promoCatStatus === null) {
        return { status: false, msg: 'Please Select Status', field: "promoCatStatus" };
    }
    return { status: true, msg: ' ' };
}

export const AddSubCategoryValidation = (merchantDetail) => {
    if (merchantDetail.categoryId === "" || merchantDetail.categoryId === null) {
        return { status: false, msg: 'Please Select Category', field: "categoryId" };
    }
    if (merchantDetail.subCatName === "" || merchantDetail.subCatName === null) {
        return { status: false, msg: 'Please Enter Sub Category', field: "subCatName" };
    }
    if (merchantDetail.selectSubCatStatus === "" || merchantDetail.selectSubCatStatus === null) {
        return { status: false, msg: 'Please Select Status', field: "selectSubCatStatus" };
    }
    return { status: true, msg: ' ' };
}

export const AddCouponValidation = (merchantDetail) => {
    if (merchantDetail.merchantId === "" || merchantDetail.merchantId === null) {
        return { status: false, msg: 'Please Select Merchant', field: "merchantId" };
    }
    if (merchantDetail.categoryId === "" || merchantDetail.categoryId === null) {
        return { status: false, msg: 'Please Select Category', field: "categoryId" };
    }
    if (merchantDetail.subCatId === "" || merchantDetail.subCatId === null) {
        return { status: false, msg: 'Please Select Sub Category', field: "subCatId" };
    }
    if (merchantDetail.locationId === "" || merchantDetail.locationId === null) {
        return { status: false, msg: 'Please Select Location', field: "locationId" };
    }
    if (merchantDetail.discountTitle === "" || merchantDetail.discountTitle === null) {
        return { status: false, msg: 'Please Enter Title', field: "discountTitle" };
    }
    if (merchantDetail.discountAmount === "" || merchantDetail.discountAmount === null) {
        return { status: false, msg: 'Please Enter Amount', field: "discountAmount" };
    }
    if (merchantDetail.selectDate === "" || merchantDetail.selectDate === null) {
        return { status: false, msg: 'Please Enter Date', field: "selectDate" };
    }
    if (merchantDetail.discountStatus === "" || merchantDetail.discountStatus === null) {
        return { status: false, msg: 'Please Select Status', field: "discountStatus" };
    }
    return { status: true, msg: ' ' };
}