import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { getAgeCountData, getAgeData, getApplyVsApproveData, getApplyVsRejectData, getAverageLoanData, getDeviceData, getFunnelGraphData, getFunnelGraphDataOpportunity, getGenderData, getGroupSalaryData, getOccupationData, getStaeCountData } from './actioncreator';
import './bi.scss'
const FunnelChart = () => {
    const [name, setName] = useState([])
    const [data, setData] = useState([])
    const [nameOpportunity, setNameopportunity] = useState([])
    const [dataOpportunity, setDataopportunity] = useState([])
    const [nameGender, setNameGender] = useState([])
    const [dataGender, setDataGender] = useState([])
    const [nameDevice, setNameDevice] = useState([])
    const [dataDevice, setDataDevice] = useState([])
    const [nameAge, setNameAge] = useState([])
    const [dataAge, setDataAge] = useState([])
    const [nameSalary, setNameSalary] = useState([])
    const [dataSalary, setDataSalary] = useState([])
    const [nameOccupation, setNameOccupation] = useState([])
    const [dataOccupation, setDataOccupation] = useState([])
    const [nameAgeCount, setNameAgeCount] = useState([])
    const [dataAgeCount, setDataAgeCount] = useState([])
    const [stateWiseData, setStateWiseData] = useState([])
    const [heatmapData, setHeatMapData] = useState([])
    const [nameLoanData, setNameLoanData] = useState([])
    const [dataLoanData, setDataLoanData] = useState([])
    const [nameApplyVsApprove, setNameApplyVsApprove] = useState([])
    const [dataApplyVsApprove, setDataApplyVsApprove] = useState([])
    const [nameApplyVsReject, setNameApplyVsReject] = useState([])
    const [dataApplyVsReject, setDataApplyVsReject] = useState([])

    const colorScale = ['#026E78', '#31BAAE', '#31BAAE', '#C8F9E8', '#C8F9E8'];
    const colorsRejected = ['#2244BF', '#6278dc',];
    const colorsApproved = ['#5eb7ae', '#026E78',];
    const colorsRadial = ['#163b4f', '#2f6c76', '#5eb7ae', '#86bbb6'];
    const colorsGender = ['#3f58cc', '#6278dc', '#a7b4ef'];


    useEffect(() => {
        getData()
        getOpportunityData()
        getGender()
        getdevice()
        getAge()
        groupSalary()
        getOccupation()
        getAgeCount()
        getState()
        getLoanData()
        getapplyVsApprove()
        getapplyVsReject()
    }, [])
    const getapplyVsReject = () => {
        getApplyVsRejectData(callback => {
            if (callback && callback.approveVSreject_amount !== undefined) {
                let name = Object.keys(callback.approveVSreject_amount);
                let data = Object.values(callback.approveVSreject_amount);

                const numberArray = data.map((str) => parseFloat(str));

                setNameApplyVsReject(name);
                setDataApplyVsReject(numberArray);
            }
        })
    }
    const getapplyVsApprove = () => {
        getApplyVsApproveData(callback => {
            if (callback && callback.applyVSapprove_amount !== undefined) {
                let name = Object.keys(callback.applyVSapprove_amount);
                let data = Object.values(callback.applyVSapprove_amount);

                const numberArray = data.map((str) => parseFloat(str));

                setNameApplyVsApprove(name);
                setDataApplyVsApprove(numberArray);
            }
        })
    }
    const getLoanData = () => {
        getAverageLoanData(callback => {
            if (callback && callback.average_loan_details !== undefined) {
                let name = Object.keys(callback.average_loan_details);
                let data = Object.values(callback.average_loan_details);
                setNameLoanData(name);
                setDataLoanData(data);
            }
        })
    }
    const getState = () => {
        getStaeCountData(callback => {
            setStateWiseData(callback)
            // let dataSet = (Object.keys(callback.State).map((state) => ({
            //     x: state,
            //     y: callback.State[state],
            // })))
            const convertedData = {
                name: "Series 2",
                data: Object.entries(callback.State).map(([state, value]) => ({
                    x: state,
                    y: value,
                    fillColor: colorScale[value % colorScale.length]

                }))
            };

            // console.log(callback)
            setHeatMapData(convertedData)
        })
    }
    const getAgeCount = () => {
        getAgeCountData(callback => {
            if (callback && callback.Age_count !== undefined) {
                let name = Object.keys(callback.Age_count);
                let data = Object.values(callback.Age_count);
                setNameAgeCount(name);
                setDataAgeCount(data);
            }
        })
    }
    const groupSalary = () => {
        getGroupSalaryData(callback => {
            if (callback && callback.GroupSalary !== undefined && callback.GroupSalary.salary_amounts !== undefined) {
                let name = Object.keys(callback.GroupSalary.salary_amounts);
                let data = Object.values(callback.GroupSalary.salary_amounts);
                setNameSalary(name);
                setDataSalary(data);
            }
        })
    }
    const getOccupation = () => {
        getOccupationData(callback => {
            if (callback && callback.occupation !== undefined) {
                let name = Object.keys(callback.occupation);
                let data = Object.values(callback.occupation);
                setNameOccupation(name);
                setDataOccupation(data);
            }
        })
    }
    const getAge = () => {
        getAgeData(callback => {
            let name = []
            let data = []
            name = Object.keys(callback.Age_count)
            setNameAge(name)
            data = Object.values(callback.Age_count)
            setDataAge(data)
        })
    }
    const getdevice = () => {
        getDeviceData(callback => {
            let name = []
            let data = []
            name = Object.keys(callback.device)

            setNameDevice(name)
            data = Object.values(callback.device)
            setDataDevice(data)
        })
    }
    const getData = () => {
        getFunnelGraphData(callback => {
            makegraphData(callback.application)
        })
    }
    const getOpportunityData = () => {
        getFunnelGraphDataOpportunity(callback => {
            makegraphDataOpportunity(callback.opportunity)
        })
    }
    const getGender = () => {
        getGenderData(callback => {
            let name = []
            let data = []
            name = Object.keys(callback.gender)
            setNameGender(name)
            data = Object.values(callback.gender)
            setDataGender(data)
        })
    }
    const makegraphData = (To) => {
        let name = []
        let data = []
        name = Object.keys(To)

        setName(name)
        data = Object.values(To)
        setData(data)
    }
    const makegraphDataOpportunity = (To) => {
        let name = []
        let data = []
        name = Object.keys(To)
        data = Object.values(To)
        setNameopportunity(name)
        setDataopportunity(data)

    }

    const optionsFirst = {
        series: [
            {
                name: name,
                data: data,
            },
        ],

        chart: {
            type: 'bar',
            height: 350,
        },

        plotOptions: {
            bar: {
              
                horizontal: true,
                barHeight: '90%',
                isFunnel: true,
                barWidth: '90%',
                borderRadius: 0,
                orientation:'vertical',
                radius: 2,
                enableShades: true,
                shadeIntensity: 0.5,
                reverseNegativeShade: true,
                distributed: true,
                useFillColorAsStroke: false,
                colorScale: {
                    ranges: [{
                        from: 0,
                        to: 0,
                        color: undefined,
                        foreColor: undefined,
                        name: undefined,
                    }],
                    inverse: false,
                    min: undefined,
                    max: undefined
                },
              
            
            },
        },
        colors: ['#F66', '#db6a66', '#ef8b88', '#ee8481', '#ef8b88','#f0928f','#f09997'],
        fill: {
            colors: ['#F66', '#db6a66', '#ef8b88', '#ee8481', '#ef8b88','#f0928f','#f09997'],
            opacity: 0.3,
            type: 'gradient',
            gradient: {
                shade: 'light',
                type: "vertical",
                shadeIntensity: 0.5,
                gradientToColors: false,
                inverseColors: false,
                opacityFrom: 1,
                opacityTo: 1,
                stops: data,
                colorStops: []
            },
        },
        dataLabels: {
            enabled: true,
            formatter: function (val, opt) {
                return opt.w.globals.labels[opt.dataPointIndex] + ': ' + val;
            },
            dropShadow: {
                enabled: true,
            },
        },
        title: {
            text: 'Application Pipeline Analysis',
            align: 'left',
        },
        xaxis: {
            categories: name,
        },
        // legend: {
        //     show: false,
        // },
        labels:name

    };
    const optionsOpportunity = {
        series: [
            {
                name: nameOpportunity,
                data: dataOpportunity,
            },
        ],
        chart: {
            type: 'bar',
            height: 350,
          
        },

        plotOptions: {
            bar: {
                borderRadius: 0,
                horizontal: true,
                barHeight: '90%',
                isFunnel: true,
                barWidth: '90%',
                borderRadius: 0,
                orientation:'vertical',
                radius: 2,
                enableShades: true,
                shadeIntensity: 0.5,
                reverseNegativeShade: true,
                distributed: true,
                useFillColorAsStroke: false,
                colorScale: {
                    ranges: [{
                        from: 0,
                        to: 0,
                        color: undefined,
                        foreColor: undefined,
                        name: undefined,
                    }],
                    inverse: false,
                    min: undefined,
                    max: undefined
                },
              
                
            },
          
        },
        colors: ['#003C51', '#026E78', '#038C8C', '#31BAAE', '#569e96','#96d4ce','#d2f8e9'],
        dataLabels: {

            enabled: true,
            formatter: function (val, opt) {
                return opt.w.globals.labels[opt.dataPointIndex] + ': ' + val;
            },
            dropShadow: {
                enabled: true,
            },
        },
        title: {
            text: 'Opportunity Pipeline Analysis',
            align: 'left',
        },
        xaxis: {
            categories: nameOpportunity,
        },
        // legend: {
        //     show: false,
        // },
        labels: nameOpportunity,
      


    };
    const optionsLosRate = {
        series: [
            {
                name: nameOpportunity,
                data: dataOpportunity,
            },
        ],

        chart: {
            type: 'bar',
            height: 350,
        },

        plotOptions: {
            bar: {
                borderRadius: 0,
                horizontal: true,
                barHeight: '90%',
                isFunnel: true,
                barWidth: '90%',
                borderRadius: 0,
            },
        },
        // colors: ['#026E78', '#33FF57', '#5733FF', '#FF5733', '#33FF57'],
        dataLabels: {

            enabled: true,
            formatter: function (val, opt) {
                return opt.w.globals.labels[opt.dataPointIndex] + ': ' + val;
            },
            dropShadow: {
                enabled: true,
            },
        },
        title: {
            text: 'Loan Origination Rate',
            align: 'left',
        },
        xaxis: {
            categories: nameOpportunity,
        },
        legend: {
            show: false,
        },

    };
    const chartOptions = {
        annotations: {
            yaxis: [
              {
                y: 8800,
                borderColor: '#00E396',
                label: {
                  borderColor: '#00E396',
                  style: {
                    color: '#fff',
                    background: '#00E396'
                  },
                  text: 'Y-axis annotation on 8800'
                }
              }
            ]
          }
,          
        series: dataGender,
        chart: {
            width: '100%',
            type: 'pie',
        },
        labels: nameGender,
        theme: {
            monochrome: {
                enabled: true,

            }
        },
        plotOptions: {
            pie: {
                dataLabels: {
                    offset: -5

                },

            }
        },
        title: {
            text: "Gender wise Analysis"
        },
        fill: {
            colors: ['#3859D3', '#5C79E3', '#A4B5F4'],
            opacity: 0.9,
            type: 'solid',
            gradient: {
                shade: 'dark',
                type: "horizontal",
                shadeIntensity: 0.5,
                gradientToColors: undefined,
                inverseColors: true,
                opacityFrom: 1,
                opacityTo: 1,
                stops: [0, 50, 100],
                colorStops: []
            },
        },
        dataLabels: {
            formatter(val, opts) {
                const name = opts.w.globals.labels[opts.seriesIndex];
                return [name, val.toFixed(1) + '%'];
            }
        },
        legend: {
            show: false
        }
    }
    const optionsRadial = {
        series: dataDevice,
        chart: {

            height: 390,
            type: 'radialBar',
        },
        colors: colorsRadial,
        plotOptions: {
            radialBar: {

                offsetY: 0,
                offsetX: -70,
                startAngle: 0,
                endAngle: 270,
                hollow: {
                    margin: 5,
                    size: '40%',
                    background: 'transparent',
                    image: undefined,
                },
                dataLabels: {
                    name: {
                        show: false,
                    },
                    value: {
                        show: false,
                    }
                }
            }
        },
        // colors: ['#1ab7ea', '#0084ff', '#39539E', '#0077B5'],
        labels: nameDevice,
        legend: {
            show: true,
            floating: true,
            fontSize: '12px',
            position: 'right',
            offsetX: 0,
            offsetY: 180,
            labels: {
                useSeriesColors: true,
            },
            markers: {
                size: 0
            },
            formatter: function (seriesName, opts) {
                return seriesName + ":  " + opts.w.globals.series[opts.seriesIndex]
            },
            itemMargin: {
                vertical: 3
            }
        },
        responsive: [{
            breakpoint: 480,
            options: {
                legend: {
                    show: false
                }
            }
        }],
        title: {
            text: "Device wise Analysis"
        },
        stroke: {
            lineCap: "round",
            width: 5
        },
    };
    const optionsAge = {
        series: dataAge,
        chart: {
            type: 'donut',

        },
        colors: ['#163b4f', '#5eb7ae', '#2f6c76', '#3d8a8b', '#d2f8e9', '#32739a'],
        responsive: [{
            breakpoint: 480,
            options: {
                chart: {

                    // width: 200,
                    // height: 400
                },
                legend: {
                    position: 'bottom'
                }
            }
        }],
        labels: nameAge,
        legend: {
            offsetX: 0,
            offsetY: 170,
        },
        title: {
            text: "Age wise Analysis"
        },
        plotOptions: {
            pie: {

                //    offsetX:400,
                offsetY: 40


            }
        }


    };
    const optionsSalary = {
        title: {
            text: 'Group Salary Analysis'
        },
        chart: {
            type: 'bar',
        },

        colors: ['#FD8383'],
        plotOptions: {
            bar: {
                horizontal: true

            },
        },
        series: [
            {
                name: nameSalary,
                data: dataSalary,
            },
        ],
        labels: nameSalary,

    }

    const optionOccupation = {
        series: dataOccupation,
        chart: {
            width: '100%',
            type: 'pie',
        },
        labels: nameOccupation,
        theme: {
            monochrome: {
                enabled: true
            }

        },
        plotOptions: {
            pie: {
                dataLabels: {
                    offset: -5
                }
            },

        },
        fill: {
            colors: colorsApproved,
            opacity: 0.9,
            type: 'solid',
            gradient: {
                shade: 'dark',
                type: "horizontal",
                shadeIntensity: 0.5,
                gradientToColors: undefined,
                inverseColors: true,
                opacityFrom: 1,
                opacityTo: 1,
                stops: [0, 50, 100],
                colorStops: []
            },
        },

        title: {
            text: "Occupation Analysis"
        },
        dataLabels: {
            formatter(val, opts) {
                const name = opts.w.globals.labels[opts.seriesIndex];
                return [name, val.toFixed(1) + '%'];
            }
        },
        legend: {
            show: false
        },

    };


    // Configuration options for the heatmap chart
    const heatmapOptionsState = {
        chart: {
            type: 'heatmap'
        },
        plotOptions: {
            heatmap: {
                orientation:'vertical',
                radius: 2,
                enableShades: true,
                shadeIntensity: 0.5,
                reverseNegativeShade: true,
                distributed: true,
                useFillColorAsStroke: false,
                colorScale: {
                    ranges: [{
                        from: 0,
                        to: 0,
                        color: undefined,
                        foreColor: undefined,
                        name: undefined,
                    }],
                    inverse: false,
                    min: undefined,
                    max: undefined
                },
            }


        },
        xaxis: {
            type: 'category',
            labels: {
                rotate: -90
            }
        },
        yaxis: {
            type: 'category',
            categories: Object.keys(stateWiseData),
            labels: {
                show: true
            }
        },

        dataLabels: {
            enabled: false
        },
        title: {
            text: "State wise Analysis"
        },
        colors: colorScale


    };
    const optionsLoanData = {
        chart: {
            type: 'line'
        },
        plotOptions: {
            bar: {
                horizontal: true
            },
            fillColor: '#5C79E3'
        },
        series: [
            {
                name: nameLoanData,
                data: dataLoanData,
            },
        ],
        labels: nameLoanData,
        title: {
            text: 'Average Loan Size'
        },

    }

    const applyVsApproveOption = {
        // series: [
        //     {
        //         name: nameApplyVsApprove,
        //         data: dataApplyVsApprove,
        //     },
        // ],
        series: dataApplyVsApprove,
        chart: {
            type: 'donut',
        },
        colors: colorsApproved,
        responsive: [{
            breakpoint: 480,
            options: {
                chart: {

                    // width: 200,
                    // height: 400
                },
                legend: {
                    position: 'bottom'
                }
            }
        }],
        labels: nameApplyVsApprove,
        legend: {
            offsetX: 0,
            offsetY: 170,
        },
        title: {
            text: "Age wise Analysis"
        },
        plotOptions: {
            pie: {

                //    offsetX:400,
                offsetY: 40


            }
        }
    };
    const applyVsRejectOption = {
        // series: [
        //     {
        //         name: nameApplyVsApprove,
        //         data: dataApplyVsApprove,
        //     },
        // ],
        series: dataApplyVsReject,
        chart: {
            type: 'donut',
        },
        colors: colorsRejected,
        responsive: [{
            breakpoint: 480,
            options: {
                chart: {

                    // width: 200,
                    // height: 400
                },
                legend: {
                    position: 'bottom'
                }
            }
        }],
        labels: nameApplyVsReject,
        legend: {
            offsetX: 0,
            offsetY: 170,
        },
        title: {
            text: "Applied to  Approve or Reject - Ratio Analysis"
        },
        plotOptions: {
            pie: {

                //    offsetX:400,
                offsetY: 40


            }
        }
    };
    

    return (
        <div className='container-fluid main-bi'>
            <div className='row'>
                <div className='col-sm-4 col-xs-12' style={{ marginTop: '40px' }}>
                    <div className='bi-card'>
                        <div >
                            <ReactApexChart options={optionsFirst} series={optionsFirst.series} type="bar" height={350} width={'100%'} />
                        </div>
                    </div>
                </div>
                <div className='col-sm-4 col-xs-12' style={{ marginTop: '40px' }}>
                    <div className='bi-card'>
                        <div >
                            <ReactApexChart options={optionsOpportunity} series={optionsOpportunity.series} type="bar" height={350} width={'100%'} />
                        </div>
                    </div>
                </div>
                <div className='col-sm-4 col-xs-12' style={{ marginTop: '40px' }}>
                    <div className='bi-card'>
                        <ReactApexChart options={applyVsRejectOption} series={applyVsRejectOption.series} type="donut" height={350} width="100%" />
                    </div>
                </div>

            </div>
            <div className='row'>
                <div className='col-sm-4 col-xs-12' style={{ marginTop: '40px' }} >
                    <div className='bi-card'>
                        <ReactApexChart options={chartOptions} series={chartOptions.series} type="pie" height={350} width="100%" />
                    </div>
                </div>
                <div className='col-sm-4 col-xs-12' style={{ marginTop: '40px' }} >
                    <div className='bi-card'>
                        <ReactApexChart options={optionsRadial} series={optionsRadial.series} type="radialBar" height={350} width="100%" />
                    </div>

                </div>
                <div className='col-sm-4 col-xs-12' style={{ marginTop: '40px' }}>
                    <div className='bi-card'>

                        <ReactApexChart options={optionsAge} series={optionsAge.series} type="donut" height={350} width="100%" />
                    </div>

                </div>
            </div>
            <div className='row'>
                <div className='col-sm-4 col-xs-12' style={{ marginTop: '40px' }}>
                    <div className='bi-card'>

                        <ReactApexChart options={optionsSalary} series={optionsSalary.series} type="bar" width="100%" height={335} />

                    </div>
                </div>
                <div className='col-sm-4 col-xs-12' style={{ marginTop: '40px' }} >
                    <div className='bi-card'>
                        <ReactApexChart options={optionOccupation} series={optionOccupation.series} type="pie" height={350} width="100%" />
                    </div>
                </div>
                <div className='col-sm-4 col-xs-12' style={{ marginTop: '40px' }} >
                    <div className='bi-card'>

                        {/* {stateWiseData ? */}
                        <ReactApexChart
                            options={heatmapOptionsState}
                            series={[
                                {
                                    name: 'Count',
                                    data: heatmapData.data
                                }
                            ]}
                            type="heatmap"
                            height="350"
                        />

                    </div>
                </div>
            </div>
            <div className='row'>
                <div className='col-sm-4 col-xs-12' style={{ marginTop: '40px' }}>
                    <div className='bi-card'>
                        <ReactApexChart options={optionsLoanData} series={optionsLoanData.series} type="line" width="100%" height={350} />

                    </div>
                </div>
                <div className='col-sm-4 col-xs-12' style={{ marginTop: '40px' }} >

                    <div className='bi-card'>
                        <ReactApexChart options={applyVsApproveOption} series={applyVsApproveOption.series} type="donut" height={350} width="100%" />
                    </div>
                </div>
                <div className='col-sm-4 col-xs-12' style={{ marginTop: '40px' }} >


                </div>
            </div>
        </div>
    );
};

export default FunnelChart;
