import React, { Component } from 'react';
import UpdateTransaction from '../../../presentationals/Admin/CustomerPages/EditTransactionPopup.component'
import { _preFormatDate } from '../../../../utils/validation';
import { updateTransactionDetail, getTransaction } from '../AdminActionCreator.component'

class TransactionDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            transDetail: {
                trnsIdState: '',
                trnsDateState: '',
                trnsModeState: '',
                trnsAmountState: '',
            }
        }
    }
    render() {
        const { userDetail } = this.props
        return (
            <UpdateTransaction
                closeTransaction={this.closeTransaction.bind(this)}
                editTransHandler={this.editTransHandler.bind(this)}
                saveTransHandler={this.saveTransHandler.bind(this)}
                trnsDateState={this.state.trnsDateState}
                transDetail={this.state.transDetail}
            />
        )
    }

    componentWillMount() {
        let transDetail = Object.assign({}, this.state.transDetail);
        const { userDetail } = this.props
        getTransaction(userDetail.userId,
            (callBack) => {
                this.setState({
                    details: callBack
                })
                for (var i = 0; i < callBack.length; i++) {
                    let singleTransDetail = callBack[i]
                    if (singleTransDetail.transactionId == this.props.transactionIdUpdate) {
                        transDetail.trnsIdState = singleTransDetail.transactionId
                        transDetail.trnsDateState = singleTransDetail.prepayDate.split(' ')[0]
                        transDetail.trnsModeState = singleTransDetail.paymentMode
                        transDetail.trnsAmountState = singleTransDetail.prepayAmount
                        transDetail.userId = singleTransDetail.userId
                        transDetail.loanId = singleTransDetail.loanId
                    }
                }
                this.setState({
                    transDetail,
                    details: callBack
                })
            })
    }

    closeTransaction() {
        this.props.getPopupState(false, this.state.details)
    }

    editTransHandler(e, transType) {
        let transDetail = Object.assign({}, this.state.transDetail);
        if (transType == 'trnsId') {
            transDetail.trnsIdState = e.target.value
        } else if (transType == 'trnsDate') {
            transDetail.trnsDateState = _preFormatDate(e)
            this.setState({ trnsDateState: e })
        } else if (transType == 'trnsMode') {
            transDetail.trnsModeState = e.target.value
        } else if (transType == 'trnsAmount') {
            transDetail.trnsAmountState = e.target.value
        }
        this.setState({ transDetail })
    }

    saveTransHandler(e) {
        updateTransactionDetail(this.state.transDetail,
            (callBack) => {
                getTransaction(this.state.transDetail.userId,
                    (callBack) => {
                        this.props.getPopupState(false, callBack)
                        this.props.getConfirmPopupState(true, 'Details updated successfully')
                    })
            })
    }
}

export default TransactionDetail;