import React from 'react'

const PopUp = ({popupStatus,closePopup})=>(
    <div className="overlay-error ">

    <div className="popup-error ">
        <div className="content">
           <h3 className="text-center">{popupStatus}</h3>
           <div className="text-center" >
            <button className="error-btn " onClick={e => closePopup()}>Ok</button>
        </div>
        
    </div>
</div> 
</div>
)
export default PopUp