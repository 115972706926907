import React, { Component } from 'react';
import ChequeBounceUi from '../../../presentationals/Admin/Recovery/ChequeBounce.component';
import { APIS } from '../../../../utils/api-factory'

class ChequeBounce extends Component {
    constructor(props) {
        super(props);
        this.state = {
            chequeBounceData: '',
            FilteredData: [],
            pageIndexToShow: 0,
        }
    }

    componentWillMount() {
        fetch(APIS.GET_CHEQUE_DATA + 1 + '&type=bullet')
            .then(res => res.json())
            .then(json => {
                this.setState({ chequeBounceData: json })
            })
    }

    render() {
        return (
            <div className="full-height">
                <ChequeBounceUi
                    chequeBounceData={this.state.chequeBounceData}
                    NachBounceData={this.state.NachBounceData}
                    getFilterValue={this.getFilterValue.bind(this)}
                    FilteredData={this.state.FilteredData}
                    getPageValue={this.getPageValue.bind(this)}
                    pageIndexToShow={this.state.pageIndexToShow}
                />
            </div>
        )
    }

    getFilterValue(filtered) {
        this.setState({ FilteredData: filtered })
    }

    getPageValue(pageIndex) {
        this.setState({ pageIndexToShow: pageIndex })
    }

}

export default ChequeBounce;