import React, { useEffect, useState } from "react";
import Popup from '../../../../../presentationals/Popup/Popup.component';
import { saveBusinessReturnData, updateBusinessReturnData } from "../../targetactioncreator";

const validationRules = [
    { name: 'startDate', value: 'Start Date is required' },
    { name: 'endDate', value: 'End Date is required' },
    { name: 'targetReturn', value: 'Target Return is required' },
    { name: 'currentReturn', value: 'Current Return is required' },
    { name: 'targetProfit', value: 'Target Profit is required' },
    { name: 'currentProfit', value: 'Current Profit is required' },
    { name: 'targetExpenses', value: 'Target Expenses are required' },
    { name: 'currentExpenses', value: 'Current Expenses are required' },
    { name: 'targetCollection', value: 'Target Collection is required' },
    { name: 'currentCollection', value: 'Current Collection is required' }
];

const LeftInputArray = [
    { name: 'startDate', value: 'Start Date' },
    { name: 'targetReturn', value: 'Target Return' },
    { name: 'currentReturn', value: 'Current Return' },
    { name: 'targetProfit', value: 'Target Profit' },
    { name: 'currentProfit', value: 'Current Profit' },
];
const RightInputArray = [
    { name: 'endDate', value: 'End Date' },
    { name: 'targetExpenses', value: 'Target Expenses' },
    { name: 'currentExpenses', value: 'Current Expenses' },
    { name: 'targetCollection', value: 'Target Collection' },
    { name: 'currentCollection', value: 'Current Collection' }
];


const EditCategory = ({ editData }) => {
    const [employeeData, setEmployeeData] = useState({
        startDate: '',
        endDate: '',
        targetReturn: '',
        currentReturn: '',
        targetProfit: '',
        currentProfit: '',
        targetExpenses: '',
        currentExpenses: '',
        targetCollection: '',
        currentCollection: ''
    });

    const [sidePopup, setPopup] = useState(false);
    const [popupStatus, setPopupStatus] = useState('');
    const [errors, setErrors] = useState({});

    const closePopup = () => {
        setPopup(false);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setEmployeeData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const validateForm = () => {
        let formErrors = {};
        let isValid = true;

        validationRules.forEach(({ name, value }) => {
            if (!employeeData[name]) {
                formErrors[name] = `${value} is required`;
                isValid = false;
            }
        });

        setErrors(formErrors);
        return isValid;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validateForm()) {
            console.log("Category Data Saved", employeeData);
            if (editData && editData.id) {
                employeeData.id = editData.id
                updateBusinessReturnData(employeeData, callback => {
                    if (callback.message === 'success') {

                        setPopup(true);
                        setPopupStatus('Data Saved Successfully!');
                    } else {
                        setPopup(true);
                        setPopupStatus('Please try again later!');
                    }
                    removePopup();
                });
            } else {
                saveBusinessReturnData(employeeData, callback => {
                    if (callback.message === 'success') {

                        setPopup(true);
                        setPopupStatus('Data Saved Successfully!');
                    } else {
                        setPopup(true);
                        setPopupStatus('Please try again later!');
                    }
                    removePopup();
                });
            }
        }
    };

    const removePopup = () => {
        setTimeout(() => {
            closePopup();
        }, 5000);
    };

    useEffect(() => {
        if (editData && editData.id) {
            setEmployeeData({
                startDate: editData.startDate || "",
                endDate: editData.endDate || "",
                targetReturn: editData.targetReturn || "",
                currentReturn: editData.currentReturn || "",
                targetProfit: editData.targetProfit || "",
                currentProfit: editData.currentProfit || "",
                targetExpenses: editData.targetExpenses || "",
                currentExpenses: editData.currentExpenses || "",
                targetCollection: editData.targetCollection || "",
                currentCollection: editData.currentCollection || "",
            });
        }
    }, [editData]);

    return (
        <div className="container">
            {sidePopup && <Popup popupStatus={popupStatus} closePopup={closePopup} />}
            <div className="target-box">
                <h3>Add/Update Business Return Information</h3>
                <hr style={{ borderTop: '1px solid #000' }} />
                <form onSubmit={handleSubmit}>
                    <div className="row">
                        <div className="col-sm-6 col-xs-12">
                            {
                                LeftInputArray?.map((Value, index) => {
                                    return (
                                        <div style={{ display: 'flex', marginTop: '20px' }} key={index}>
                                            <div style={{ width: '50%' }}>
                                                <label style={{ marginTop: '10px' }}>{Value.value}</label>
                                            </div>
                                            <div style={{ width: '50%' }}>
                                                <input
                                                    style={{ background: '#fff', border: '1px solid' }}
                                                    className="form-control"
                                                    type={Value.name === 'startDate' ? 'date' : "number"}
                                                    name={Value.name}
                                                    value={employeeData[Value.name]}
                                                    onChange={handleChange}
                                                />
                                                {errors[Value.name] && (
                                                    <span style={{ color: 'red', fontSize: '10px', marginTop: '5px' }}>{errors[Value.name]}</span>
                                                )}
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        <div className="col-sm-6 col-xs-12">
                            {
                                RightInputArray?.map((Value, index) => {
                                    return (
                                        <div style={{ display: 'flex', marginTop: '20px' }} key={index}>
                                            <div style={{ width: '50%' }}>
                                                <label style={{ marginTop: '10px' }}>{Value.value}</label>
                                            </div>
                                            <div style={{ width: '50%' }}>
                                                <input
                                                    style={{ background: '#fff', border: '1px solid' }}
                                                    className="form-control"
                                                    type={Value.name === 'endDate' ? 'date' : "number"}
                                                    name={Value.name}
                                                    value={employeeData[Value.name]}
                                                    onChange={handleChange}
                                                />
                                                {errors[Value.name] && (
                                                    <span style={{ color: 'red', fontSize: '10px', marginTop: '5px' }}>{errors[Value.name]}</span>
                                                )}
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>

                    </div>
                    <div className="text-center" style={{ marginTop: '40px' }}>
                        <button type="submit" className="btn btn-primary">Save Business Return Data</button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default EditCategory;
