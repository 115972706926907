import React from 'react'
import { MAIL_STRING } from '../../../utils/Constant'

// subject: Phocket: NACH Bounced

const ChequeBounced = ({ allDetail }) => (
    <div>
        <div>Dear {allDetail != null && allDetail.firstName != null ? allDetail.firstName : ""},</div>
        <br />
        <div>We have noticed that your loan application process isn't complete yet and is missing a few documents.</div>
        <br />
        <div>Below are the essential documents required:</div>
        <br />
        <div>1) Up to date bank statement and latest salary slip </div>
        <div>Kindly provide the documents via the App or send it via email.</div>
        <br />
        <div>You can also upload the documents here: <a href={MAIL_STRING.WEB_URL + allDetail.userId} target="_blank">click here</a></div>
        <br />
        <div>For Approval confirmation we will send an App notification, kindly acknowledge it.</div>
        <br />
        <div>Feel free to contact us, if you have any queries.</div>
        <br />
        <div>Best Regards,</div>
        <div>Team Micromoney</div>
        <div>{MAIL_STRING.MOBILE_NO}</div>
    </div>
)

export default ChequeBounced