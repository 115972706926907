import React from "react";
import moment from "moment";
function leadParticularsAggregator({ data }) {
 
  return (
    <>
      <div className="col-lg-12 col-xl-12">
        <div className="bg-white p-4 shadow" style={{ padding: '15px' }}>
         <h5 style={{color:'#0e5768',fontWeight:'700'}}> <strong style={{ paddingTop: '10px' }}>Leads Particulars</strong></h5>

          <div className='credit-table' style={{fontSize:'12px'}} >
            <table>
              <thead style={{ background: '#b9d8f9', color: '#000' }}>
                <tr className="thead-class">
                  <th className="">
                    <b>
                      <b>Date</b>
                    </b>
                  </th>
                  <th className="">
                    <b>Fresh Leads</b>
                  </th>
                  <th className="">
                    <b>Fresh Approval</b>
                  </th>
                  <th className="">
                    <b>Fresh Disbursement</b>
                  </th>
                  
                  <th className="">
                    <b>Repeat Leads</b>
                  </th>
                  <th className="">
                    <b>Repeat Approval</b>
                  </th>
                  <th className="">
                    <b>Repeat Disbursement</b>
                  </th>
                  <th className="">
                    <b>Total Leads</b>
                  </th>
                  <th className="">
                    <b>Amount Fresh Disbursed</b>
                  </th>
                  <th className="">
                    <b>Repeat Amount  Disbursed</b>
                  </th>
                  <th className="">
                    <b>Rejection Ratio</b>
                  </th>
                  
                </tr>
              </thead>
              <tbody>
                {data !== '' && data !== undefined ? data.map((data1, i) => {
                  return (
                    <tr style={{ borderBottom: "none",fontWeight:'400'}}>
                      <td scope="col" style={{whiteSpace:"nowrap"}}>
                      {data1? moment(data1.date).format('DD-MM-YYYY') :''}
                      </td>
                      <td scope="col">
                        {data1.totalFreshLeads}
                      </td>
                      <td scope="col">
                        {data1.approvedFreshLeads}
                      </td>
                      <td scope="col">
                        {data1.disburseFreshLeads}
                      </td>
                    
                      <td scope="col">
                      {data1.totalRepeatLeads}
                      </td>
                      <td scope="col">
                        {data1.approvedRepeatLeads}
                      </td>
                      <td scope="col">
                        {data1.disburseRepeatLeads}
                      </td>
                      <td scope="col">
                        {data1.totalCount}
                      </td>
                      <td scope="col">
                        {data1.disburseFreshAmount}
                      </td>
                      <td scope="col">
                        {data1.disburseRepeatAmount}
                      </td>
                      <td scope="col">
                        {data1.rejectionRatio?.toFixed(2)+"%"}
                      </td>
                      
                      {/* <td scope="col">
                          {data1.repeatDisbursal}
                        </td>
                        <td scope="col">
                          {data1.repeatDisbursal}
                        </td>
                        <td scope="col">
                          {data1.repeatDisbursal}
                        </td>
                        <td scope="col">
                          {data1.repeatDisbursal}
                        </td> */}
                    </tr>
                  )
                }) : ""}

              </tbody>
            </table>
          </div>
        </div>
      </div>

    </>
  );
}

export default leadParticularsAggregator;
