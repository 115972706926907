import React, { Component } from 'react'
import SendNotification from './CommentAndNotification.container'
import SendMail from './Mail.container'
import Logs from './Logs.container'
import RecoveryLogs from '../Recovery/RecoveryComLogs.container'
import CreditScorePage from './scoringNewMain'
// import InternalScoring from './i'
import ActivityUi from './UserStatusActivity.container'
import MessageUi from './SendMsg.container'
import WhatsappUi from './Whatsapp.container'
import EmiCalc from '../Utility/EMICalculator.container'
import { ROLE } from '../../../../utils/Constant'

class Communication extends Component {
    constructor(props) {
        super(props);
        this.state = {
            viewPageState: 'mail',
            approveAmount: '',
            userId: ''
        }
    }
    componentDidMount() {
        const { allDetail } = this.props
        this.setState({ approveAmount: allDetail.loanAmount ? allDetail.loanAmount  : "", userId: allDetail.userId })
    }

    render() {
        const { allDetail, admin, creditScoringPopup, userDetail, userDetails } = this.props;
        return (
            <div className="full-height container-fluid white-bg">
                <div className="row">
                    <div className="edit-profile-menu col-xs-12">
                        <div className="btn-group-tab">
                            <button className={this.state.viewPageState == 'mail' ? 'active' : null} onClick={(e) => this.sendHandler('mail')}>Mail</button>
                            <button className={this.state.viewPageState == 'whatsapp' ? 'active' : null} onClick={(e) => this.sendHandler('whatsapp')}>Whatsapp</button>
                            <button className={this.state.viewPageState == 'msg' ? 'active' : null} onClick={(e) => this.sendHandler('msg')}>Msg</button>
                            <button className={this.state.viewPageState == 'notification' ? 'active' : null} onClick={(e) => this.sendHandler('notification')}>Comment and Notification</button>
                            <button className={this.state.viewPageState == 'comments' ? 'active' : null} onClick={(e) => this.sendHandler('comments')}>Logs</button>
                            <button className={this.state.viewPageState == 'recoveryLogs' ? 'active' : null} onClick={(e) => this.sendHandler('recoveryLogs')}>Recovery Logs</button>
                            {/* {creditScoringPopup == true ? */}
                            {admin != null && admin.rolelist.indexOf(ROLE.Cibil_Scoring) >= 0 ?  <button className={this.state.viewPageState == 'creditScore' ? 'active' : null} onClick={(e) => this.sendHandler('creditScore')}>Credit Score</button>:""}
                                {/* // : ""} */}
                            {creditScoringPopup == true ?
                                <button className={this.state.viewPageState == 'activity' ? 'active' : null} onClick={(e) => this.sendHandler('activity')}>Activity</button>
                                : ""}
                            {creditScoringPopup == true ?
                                <button className={this.state.viewPageState == 'emiCalc' ? 'active' : null} onClick={(e) => this.sendHandler('emiCalc')}>Calculator</button>
                                : ""}
                        </div>
                    </div>
                </div>
                <div className="row overflow-auto">
                    {this.state.viewPageState == 'mail' ?
                        <SendMail
                            approveAmount={this.state.approveAmount}
                            userId={this.state.userId}
                            getData={this.props.getData.bind(this)}
                            admin={admin}
                            allDetail={allDetail}
                            userDetail={userDetails != null && userDetails != '' ? userDetails : userDetail}
                        />
                        : this.state.viewPageState == 'notification' ?
                            <SendNotification
                                admin={admin}
                                allDetail={allDetail} />
                            : this.state.viewPageState == 'msg' ?
                                <MessageUi admin={admin}
                                    allDetail={allDetail}
                                />
                                : this.state.viewPageState == 'whatsapp' ?
                                    <WhatsappUi admin={admin}
                                        approveAmount={this.state.approveAmount}
                                        userId={this.state.userId}
                                        userDetail={userDetails != null && userDetails != '' ? userDetails : userDetail}
                                        // userDetail={userDetail}
                                        allDetail={allDetail} />
                                    : this.state.viewPageState == 'comments' ?
                                        <Logs
                                            allDetail={allDetail}
                                            admin={admin} />
                                        : this.state.viewPageState == 'recoveryLogs' ?
                                            <RecoveryLogs
                                                dataForCommunication={allDetail}
                                                admin={admin} />
                                            : this.state.viewPageState == 'creditScore' ?
                                                <CreditScorePage
                                                admin={admin}
                                                    creditScoringPopup={creditScoringPopup}
                                                    allDetail={allDetail} />
                                                : this.state.viewPageState == 'activity' ?
                                                    <ActivityUi
                                                        userDetail={userDetails}
                                                        creditScoringPopup={creditScoringPopup}
                                                        allDetail={allDetail} />
                                                    : this.state.viewPageState == 'emiCalc' ?
                                                        <EmiCalc />
                                                        : ""}
                </div>
            </div>
        )
    }

    sendHandler(type) {
        this.setState({
            viewPageState: type,
        })
    }
}

export default Communication;
