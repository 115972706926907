import React from "react";
import ApproveData from "../Approve.popup";
import TentativeApproval from "../tentativeApproval";
import { checkUserDoc } from "../../AdminActionCreator.component";

class ApprovalTab extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tabType: '',
            showButton: false,
            errorMessage: ''
        }
    }
    tabHandler(e, type) {
        this.setState({ tabType: type })

    }
    closePopup() {


    }
    checkdoc() {
        const { allDetail } = this.props

        checkUserDoc(allDetail.userId, callBack => {
            if (callBack === 'success') {
                this.setState({ errorMessage: '' })
                this.setState({ showButton: true })
            } else {
                this.setState({ showButton: false })

            }
            if (callBack === 'failure') {
                this.setState({ errorMessage: 'Contact Tech Admin' })

            }
            if (callBack === 'At least 1 document is required') {
                this.setState({ errorMessage: 'Atleast 1 doc required, please upload it' })
            }
        })
    }
    componentDidMount() {

        const { allDetail } = this.props

        checkUserDoc(allDetail.userId, callBack => {
            if (callBack === 'success') {
                this.setState({ errorMessage: '' })
                this.setState({ showButton: true })
            } else {
                this.setState({ showButton: false })

            }
            if (callBack === 'failure') {
                this.setState({ errorMessage: 'Contact Tech Admin' })

            }
            if (callBack === 'At least 1 document is required') {
                this.setState({ errorMessage: 'Atleast 1 doc required, please upload it' })
            }
        })

    }
    render() {
        const { userDetails, admin, allDetail } = this.props
        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-6 col-xs-6 text-right">
                        <input type="radio" checked={this.state.tabType === 'approve'} onChange={(e) => this.tabHandler(e, 'approve')} />&nbsp;&nbsp;Approval
                    </div>
                    <div className="col-sm-6 col-xs-6 text-left">
                        <input type="radio" checked={this.state.tabType === 'tentatve'} onChange={(e) => this.tabHandler(e, 'tentatve')} />&nbsp;&nbsp;Tentative Approval
                    </div>

                </div>
                {this.state.tabType === 'approve' ?
                    <ApproveData userDetails={userDetails} admin={admin} allDetail={allDetail} closePopup={this.closePopup.bind(this)} />
                    : ""}
                {this.state.tabType === 'tentatve' ?
                    <TentativeApproval showButton={this.state.showButton} errorMessage={this.state.errorMessage} userDetails={userDetails} admin={admin} allDetail={allDetail} closePopup={this.closePopup.bind(this)} />
                    : ""}


            </div>
        )
    }

}
export default ApprovalTab