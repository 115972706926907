import React from "react";
import { getCommentAdmin } from "../AdminActionCreator.component";
import { Tooltip } from "@mui/material";
import PopUp from "../../../presentationals/Popup/moreDataPopup.js";
import Skeleton from "react-loading-skeleton";
class CommunicationLogs extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            notifications: '',
            popupData: '',
            dataPopup: false,
            loaderState: false
        }
    }
    componentDidMount() {
        this.logsData()
    }
    logsData() {
        const { allDetail } = this.props
        this.setState({ loaderState: true })
        getCommentAdmin(allDetail.userId, callback => {
            this.setState({ notifications: callback, loaderState: false })
        })
    }
    moreData(data) {
        this.setState({ popupData: data, dataPopup: true })
    }
    closePopup() {
        this.setState({ popupData: '', dataPopup: false })

    }
    render() {
        return (
            <>
                {this.state.dataPopup ?
                    <PopUp popupData={this.state.popupData} closePopup={this.closePopup.bind(this)} />
                    : ""}
                    <div className="animated fadeIn">
                {!this.state.loaderState ?
                    < div className="container-fluid details-new  " style={{ marginTop: '80px' }}>
                        <div className="phocket-table-new" >
                            <table>
                                <thead>
                                    <tr>
                                        <th>Date</th>
                                        <th>Fulfilled by</th>

                                        <th style={{ maxWidth: '400px' }}>Title & Body</th>
                                        <th>Communication Type</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.notifications !== '' ? this.state.notifications.map((notification, index) => (
                                        <tr key={index}>
                                            <td>{notification.date}</td>
                                            <td>{notification.empId}</td>

                                            <td style={{ maxWidth: '400px' }}>{<td>
                                                <strong>{notification.type === 'mail' ? 'Subject :' : ''}&nbsp;{notification.title}</strong>
                                                <br />

                                                {notification.body ?
                                                    notification.body.length < 70 ?
                                                        <td style={{ whiteSpace: 'break-spaces' }}>{notification.body !== null ? notification.body : ""}</td>
                                                        :
                                                        <td style={{ whiteSpace: 'break-spaces', cursor: 'pointer' }}>{notification.body !== null ? <div
                                                            dangerouslySetInnerHTML={{
                                                                __html: notification.body.slice(0, 120)
                                                            }}
                                                        ></div> : ""}<a onClick={() => this.moreData(notification.body)}>more...</a></td>
                                                    : <td></td>}
                                            </td>}</td>
                                            <td>{notification.type}</td>
                                        </tr>
                                    )) : ""}
                                </tbody>
                            </table>
                        </div>
                    </div >
                    :

                    <div className='phocket-table-new'  style={{ marginTop: '80px' }}>
                        <table className='text-center '  >
                            <thead>
                                <tr>
                                    <th>Date</th>
                                    <th>Fulfilled by</th>
                                    <th style={{ maxWidth: '400px' }}>Title & Body</th>
                                    <th>Communication Type</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td><Skeleton height={40} /></td>
                                    <td><Skeleton height={40} /></td>
                                    <td><Skeleton height={40} /></td>
                                    <td><Skeleton height={40} /></td>
                                    

                                </tr>
                                <tr>
                                    <td><Skeleton height={40} /></td>
                                    <td><Skeleton height={40} /></td>
                                    <td><Skeleton height={40} /></td>
                                    <td><Skeleton height={40} /></td>
                                    
                                </tr>
                                <tr>
                                    <td><Skeleton height={40} /></td>
                                    <td><Skeleton height={40} /></td>
                                    <td><Skeleton height={40} /></td>
                                    <td><Skeleton height={40} /></td>
                                    
                                </tr>
                                <tr>
                                    <td><Skeleton height={40} /></td>
                                    <td><Skeleton height={40} /></td>
                                    <td><Skeleton height={40} /></td>
                                    <td><Skeleton height={40} /></td>
                                    
                                </tr>
                                <tr>
                                    <td><Skeleton height={40} /></td>
                                    <td><Skeleton height={40} /></td>
                                    <td><Skeleton height={40} /></td>
                                    <td><Skeleton height={40} /></td>
                                    
                                </tr>
                                <tr>
                                    <td><Skeleton height={40} /></td>
                                    <td><Skeleton height={40} /></td>
                                    <td><Skeleton height={40} /></td>
                                    <td><Skeleton height={40} /></td>
                                    
                                </tr>

                            </tbody>
                        </table>
                    </div>}
                    </div>
            </>
        )
    }
}
export default CommunicationLogs