import React from 'react'
import ConfirmationPopup from '../../../presentationals/Popup/ConfirmationPopup.component';
import TransferPay from '../../../presentationals/Admin/Utility/RazorpayTransfer.component';
import { razorpayTransferApi, getRazorpayTransaction } from '../AdminActionCreator.component';
import PopUp from '../../../presentationals/Popup/Popup.component';
import Loader from '../../../presentationals/Loader/Loader.component'
class Transfer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            confirmationPopup: false,
            amount: 0,
            confirmationText: 'Are you sure to transfer amount?',
            razorPayTransactionData: '',
            pageNumber: 1,
            noOfEntry: 10,
            popupState: false,
            popupStatus: '',
            loaderState:false
        }
    }
    removePopup() {
        setTimeout(
            function () {
                this.setState({ popupState: false })
            }.bind(this),
            5000
        )
    }
    componentDidMount() {
        this.setState({loaderState:true})
       this.getData()
    }
    getData(){
        getRazorpayTransaction(this.state.noOfEntry, this.state.pageNumber, callback => {
            this.setState({razorPayTransactionData:callback,loaderState:false})
        })
    }
    cancelLoanConfirm(type) {
        if (type === 'yes') {
            console.log(this.state.amount)
            if (this.state.amount != 0) {
                this.setState({loaderState:true})
                razorpayTransferApi(this.state.amount, callback => {
                    if (callback === 'success') {
                        this.getData()
                        this.setState({ popupState: true, popupStatus: 'Amount Successfully Transfered !',confirmationPopup:false ,loaderState:false}, () => this.removePopup())
                    } else {
                        this.setState({ popupState: true, popupStatus: 'Plaese try again later !',confirmationPopup:false,loaderState:false }, () => this.removePopup())
                    }
                })
            } else {
                this.setState({ popupState: true, popupStatus: 'Amount Should be greater then 0!' }, () => this.removePopup())

            }
        }
        if (type === 'no') {
            this.setState({ confirmationPopup: false })
        }
    }
    changeHandler(e) {
        this.setState({
            amount: e.target.value
        })
    }
    popupHandle() {
        this.setState({ confirmationPopup: true })
    }
    closePopup() {
        this.setState({ popupState: false })
    }
    render() {
        return (
            <div>
                {this.state.confirmationPopup ?
                    <ConfirmationPopup
                        cancelLoanConfirm={this.cancelLoanConfirm.bind(this)}
                        confirmationText={this.state.confirmationText}
                    />
                    : ""}
                <TransferPay
                    razorPayTransactionData={this.state.razorPayTransactionData}
                    changeHandler={this.changeHandler.bind(this)}
                    popupHandle={this.popupHandle.bind(this)}
                />
                {this.state.popupState == true ? (
                    <PopUp
                        closePopup={this.closePopup.bind(this)}
                        popupStatus={this.state.popupStatus}
                    />
                ) : (
                    ''
                )}
                {this.state.loaderState?
                <Loader/>
            :""}
            </div>
        )
    }
}
export default Transfer