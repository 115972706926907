import React from 'react'
import NodataImage from '../../../images/nodata.png';
export default function BankNameList({ BankPopupclose, bankNameList }) {
    return (
        <div className='background-blur income'>
            <div className='popup-main small-popup container-fluid dbl-border'>
                <button className='close' onClick={e => BankPopupclose()}>
                    &times;
                </button>
                <div className='col-xs-12 col-sm-12' style={{ marginBottom: '20px' }}>
                    {
                        bankNameList?.length > 0 ?
                        <div style={{height:'322px',overflowY:'auto'}}>
                            < table className='income-table' >
                                <thead style={{position:'sticky',top:'0'}}>
                                    <tr>
                                        <th>
                                            Bank Name
                                        </th>
                                    </tr>

                                </thead>
                                <tbody >
                                    {
                                        bankNameList?.map((value, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>
                                                        {value?.name}
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                            </div>
                            :
                            <p style={{ marginTop: '100px' }} className="text-center">
                                <img src={NodataImage} style={{ width: '30%' }} />
                            </p>
                    }

                </div>
            </div>


        </div >
    )
}
