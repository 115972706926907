import React from 'react';
import { ROLE, STRING } from '../../../../utils/Constant'
import BackLogo from '../../../../images/rewampimages/Chevron.png'
import InactiveDetails from '../../../../images/rewampimages/Profile (2).png'
import ActiveDetails from '../../../../images/rewampimages/loan (1).png'
import LoanActive from '../../../../images/rewampimages/loanactive.png'
import LoanInActive from '../../../../images/rewampimages/loaninactive.png'
import AnalaysisInactive from '../../../../images/rewampimages/analysisinactive.png'
import Analaysisactive from '../../../../images/rewampimages/analysisactive.png'
import Logsactive from '../../../../images/rewampimages/logsactive.png'
import LogsInactive from '../../../../images/rewampimages/logsinactive.png'
import OPSInactive from '../../../../images/rewampimages/execution.png'
import OPSactive from '../../../../images/rewampimages/loan.png'
import OpsActiveImage from '../../../../images/rewampimages/Frame 1000001163.png'
import OpsInactiveimg from '../../../../images/rewampimages/Frame 1000001160.png'
import appActiveImage from '../../../../images/rewampimages/Frame 1000001164.png'
import appInactiveimg from '../../../../images/rewampimages/Frame 1000001156 (1).png'
const PhocketCustomerDetailForm = ({ allDetail, repeatUserData, UserDetailTabsHandler, navItemsArray, selectedIndex, teaGardenId, detailBackHandler, dataChange,
    admin, actionButtonState, userDetail }) => (
    <div className="container-fluid details-new "  >
        <div className="row details-new-tab-div">
            <div className="col-sm-4 " style={{ marginTop: '15px' }}>
                <img onClick={(e) => detailBackHandler(dataChange)} style={{ cursor: 'pointer' }} src={BackLogo} width={'30px'} /> <button style={{ color: '#1D3557', fontWeight: '700' }} onClick={(e) => detailBackHandler(dataChange)} >Back to all leads</button>
            </div>
            <div className='col-sm-4 text-center'>
                {/* ///ihavechange */}
                <span className='text-center'><div><b>{userDetail.applicationId ? userDetail.applicationId : allDetail.applicationId}</b></div></span>
                <span style={{marginTop:'10px'}} className='text-center'>{allDetail.firstName} </span>
                <div style={{marginTop:'10px'}}><b>{repeatUserData && repeatUserData.leadType ? <mark style={{  marginLeft: '10px',backgroundColor:'#fff4b7' }}>{repeatUserData ? repeatUserData.leadType : ""}</mark> : ""}</b></div>
            </div>
            {userDetail.loanFrom !== 'bazarBussiness' && admin.rolelist != undefined && userDetail != null ?

                <div className=" col-sm-4" style={{ fontSize: '10px', display: 'flex' }}>

                    {navItemsArray.map((item, index) => (
                        <div className="btn-group-new" key={index}>{item === 'BASIC' ?
                            <button id="basicTab" className={(index === selectedIndex) ? "active" : ""} onClick={(e) => UserDetailTabsHandler(e, 0)}><img src={index === selectedIndex ? ActiveDetails : InactiveDetails} style={{ width: '27px' }} /><br />Detailing</button>
                            : item === 'FINANCE' ?
                                <button id="FinancialTab" className={(index === selectedIndex) ? "active" : ""} onClick={(e) => UserDetailTabsHandler(e, 1)}><img src={index === selectedIndex ? LoanActive : LoanInActive} style={{ width: '27px' }} /><br />Loan & EMI</button>
                                : item === 'ANALYSIS' && admin.rolelist.indexOf(ROLE.CREDIT_ASSESSMENT) >= 0 ?
                                    <button id="ANALYSIS" className={(index === selectedIndex) ? "active" : ""} onClick={(e) => UserDetailTabsHandler(e, 2)}><img src={index === selectedIndex ? Analaysisactive : AnalaysisInactive} style={{ width: '27px' }} /><br />Analysis
                                    </button>

                                    // : item === 'ANALYSIS NEW' && admin.rolelist.indexOf(ROLE.CREDIT_ASSESSMENT) >= 0 ?
                                    //     <button id="ANALYSIS NEW" className={(index === selectedIndex) ? "active" : ""} onClick={(e) => UserDetailTabsHandler(e, 6)}><img src={index === selectedIndex ? Analaysisactive : AnalaysisInactive} style={{ width: '27px' }} /><br />Analysis
                                    //     </button>
                                        : item === 'LOGS' && admin.rolelist.indexOf(ROLE.STATUS_ACTIVITY) >= 0 ?
                                            <button id="logs" className={(index === selectedIndex) ? "active" : ""} onClick={(e) => UserDetailTabsHandler(e, 3)}><img src={index === selectedIndex ? Logsactive : LogsInactive} style={{ width: '27px' }} /><br />Logs</button> :
                                            item === 'OPS' && admin.rolelist.indexOf(ROLE.OPERATIONS_JOURNEY) >= 0 ?
                                                <button id="Operations" className={(index === selectedIndex) ? "active" : ""} onClick={(e) => UserDetailTabsHandler(e, 4)}><img src={index === selectedIndex ? OpsActiveImage : OpsInactiveimg} style={{ width: '27px' }} /><br />Operations<br /> Journey</button> :
                                                item === 'CONTACTS APP' && admin.rolelist.indexOf(ROLE.CONTACT_APP) >= 0 ?
                                                    <button id="contactsApps" className={(index === selectedIndex) ? "active" : ""} onClick={(e) => UserDetailTabsHandler(e, 5)}><img src={index === selectedIndex ? appActiveImage : appInactiveimg} style={{ width: '27px' }} /><br />Apps & <br/>Contacts</button> :
                                                item === 'API TRACKER'  && admin.rolelist.indexOf(ROLE.API_TRACKER) >= 0 ?
                                                    <button id="apiTracker" className={(index === selectedIndex) ? "active" : ""} onClick={(e) => UserDetailTabsHandler(e, 7)}><img src={index === selectedIndex ? appActiveImage : appInactiveimg} style={{ width: '27px' }} /><br />API <br/>Tracker</button> :

                                                    null


                            // : item === 'ACTION' && actionButtonState == true && (teaGardenId !== '' ? admin.rolelist.indexOf(ROLE.TE_Action) >= 0 : (admin.rolelist.indexOf(ROLE.ACTION) >= 0 || admin.rolelist.indexOf(ROLE.ACTION_AND_DISBURSE) >= 0)) ?

                            //     userDetail.followUpStatusCode != null && userDetail.followUpStatusCode != STRING.CANCEL_BY_USER ?
                            //         <button id="actionTab" className={(index === selectedIndex) ? "active" : ""} onClick={(e) => UserDetailTabsHandler(e, 5)}>Action</button>
                            //         : admin.role == 'superuser' ? <button id="actionTab" className={(index === selectedIndex) ? "selected" : ""} onClick={(e) => UserDetailTabsHandler(e, 5)}>Action</button>
                            //             : ""
                            // : item === 'MESSAGE' && admin.rolelist.indexOf(ROLE.COMMUNICATION) >= 0 ?
                            //     <button id="messageTab" className={(index === selectedIndex) ? "active" : ""} onClick={(e) => UserDetailTabsHandler(e, 6)}>Communication</button>
                            //     : item === 'STATUS ACTIVITY' && admin.rolelist.indexOf(ROLE.STATUS_ACTIVITY) >= 0 ?
                            //         <button id="StatusActivityTab" className={(index === selectedIndex) ? "active" : ""} onClick={(e) => UserDetailTabsHandler(e, 9)}>Activity</button>
                            //         : item === 'AGREEMENT' && teaGardenId === '' && userDetail.followUpStatusCode != null ? userDetail.followUpStatusCode == STRING.APPROVED_STATUS || userDetail.followUpStatusCode == STRING.DISBURSED ?
                            //             <button id="agreementTab" className={(index === selectedIndex) ? "active" : ""} onClick={(e) => UserDetailTabsHandler(e, 10)}>Agreement</button> : ""
                            //             : item === 'CONTACTS APP' && admin.rolelist.indexOf(ROLE.CONTACT_APP) >= 0 ?

                            //                 <button id="contactsApps" className={(index === selectedIndex) ? "active" : ""} onClick={(e) => UserDetailTabsHandler(e, 15)}>Contacts</button>
                            //                 : null

                        } </div>
                    ))}
                </div>

                : ""}
            {userDetail.loanFrom === 'bazarBussiness' && admin.rolelist != undefined && userDetail != null ?
                <div className="edit-profile-menu col-xs-12">
                    {navItemsArray.map((item, index) => (
                        <div className="btn-group" key={index}>{item === 'BASIC' ?
                            <button id="basicTab" className={(index === selectedIndex) ? "active" : ""} onClick={(e) => UserDetailTabsHandler(e, 0)}>Profile</button>
                            : item === 'STATEMENT' && admin.rolelist.indexOf(ROLE.PROFILE_ANALYSIS) >= 0 ?
                                <button id="statementTab" className={(index === selectedIndex) ? "active" : ""} onClick={(e) => UserDetailTabsHandler(e, 1)}>Analysis</button>
                                : item === 'FINANCE' ?
                                    <button id="FinancialTab" className={(index === selectedIndex) ? "active" : ""} onClick={(e) => UserDetailTabsHandler(e, 3)}>Financial</button>

                                    : item === 'ACTION' && actionButtonState == true && (admin.rolelist.indexOf(ROLE.ACTION) >= 0 || admin.rolelist.indexOf(ROLE.ACTION_AND_DISBURSE) >= 0) ?
                                        userDetail.followUpStatusCode != null && userDetail.followUpStatusCode != STRING.CANCEL_BY_USER ?
                                            <button id="actionTab" className={(index === selectedIndex) ? "active" : ""} onClick={(e) => UserDetailTabsHandler(e, 5)}>Action</button>
                                            : admin.role == 'superuser' ? <button id="actionTab" className={(index === selectedIndex) ? "selected" : ""} onClick={(e) => UserDetailTabsHandler(e, 5)}>Action</button>
                                                : ""
                                        : item === 'MESSAGE' && admin.rolelist.indexOf(ROLE.COMMUNICATION) >= 0 ?
                                            <button id="messageTab" className={(index === selectedIndex) ? "active" : ""} onClick={(e) => UserDetailTabsHandler(e, 6)}>Communication</button>
                                            : item === 'STATUS ACTIVITY' && admin.rolelist.indexOf(ROLE.STATUS_ACTIVITY) >= 0 ?
                                                <button id="StatusActivityTab" className={(index === selectedIndex) ? "active" : ""} onClick={(e) => UserDetailTabsHandler(e, 9)}>Activity</button>

                                                : ""} </div>
                    ))}
                </div>

                : ""}
        </div>
    </div>
)

export default PhocketCustomerDetailForm;