import React from 'react'

const VarificationPopup = ({ userDetail, varificationPopupViewHandler, verificationType,
    verificationSend }) => (
    <div>
        <div className="background-blur" onClick={e => varificationPopupViewHandler()}></div>
        <div className="popup-main small-popup container-fluid max-width-500px dbl-border">
            <h3 className="blue-text text-center">Verification</h3>
            <div className="row form-group">
                <div className="col-xs-12">
                    <select className="form-control" onChange={e => verificationType(e)}>
                        <option value="">Select One</option>
                        <option value="First Physical Approval">First Physical Approval</option>
                        <option value="Approve with eNACH BillDesk">Approve with eNACH - Billdesk</option>

                        <option value="Approve with eSign">Approve with eSign</option>
                        <option value="Approve with eNACH">Approve with eNACH - Razorpay</option>
                        <option value="Approve with eSign and eNACH">Approve with eSign and eNACH - Razorpay</option>
                        {userDetail.typeOfProduct == 'Loan' ?
                            <option value="Approve with eNACH TechPrcess">Approve with eNACH - Techprocess</option>
                            : ""}

                        {userDetail.typeOfProduct == 'Loan' ?
                            <option value="Approve with eSign and eNACH TechPrcess">Approve with eSign and eNACH -Techprocess </option>
                            : ""}
                        <option value="Second Approval">Second Approval</option>
                    </select>
                </div>
            </div>
            <div className="row">
                <div className="col-xs-12">
                    <button className="profile-save-btn center-block" onClick={e => verificationSend()}>SEND</button>
                </div>
            </div>
        </div>
    </div>
)

export default VarificationPopup;