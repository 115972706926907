import React, { useRef, useEffect, useState } from "react";
import "./../compo.scss";
import List from "../../maketingimages/list.png";
import communication from "../../maketingimages/communication.png";
import Filter from "../../maketingimages/filter.png";
import {
  formatDate,
  monthStartDate,
  reverseFormat,
  todayDate,
} from "../utils/utils";
import { Pagination } from "@mui/material";
import AppliedLeadListTable from "./leadsTables/appliedLeadListTable";
import {
  getAppliedCountStatsByPartnerId,
  getAppliedDataByPartnerId,
} from "../actioncreator";
import BulkCommunicationModal from "./modals/appliedLeadBulkCommunication";

const AppliedLeadList = ({
  setShowBulkcommunication,
  setShowAllLeads,
  showAllLeads,
  aggregator,
  admin,
  showBulkCommunicationEvent,
  showBulkCommunicationLeadName

}) => {
  const [bulkPopup, setBulkPopup] = useState(false);
  const [pageNo, setpageNo] = useState(1);
  const [noOfEntry, setnoOfEntry] = useState(10);

  const [detailsData, setDetails] = useState(false);

  const [appliedLeads, setAppliedLeads] = useState('');
  const [appliedAllLeads, setAppliedAllLeads] = useState([]);
  const [payload, setPayload] = useState({
    partnerId: aggregator.partnerId,
    filter: "Daily",
    fromDate: monthStartDate(),
    toDate: todayDate(),
  });
  const handleChange = (e) => {
    console.log(e.target.type);
    console.log(formatDate(e.target.value));
    setPayload({
      ...payload,
      [e.target.name]:
        e.target.type === "date" ? formatDate(e.target.value) : e.target.value,
    });

    console.log(payload);
  };
  const handleRefr = (type, item) => {
    console.log(item,'kjhgfddfghj')
    item = item || {};
    if (type === "td") {
      setPayload({
        partnerId: aggregator.partnerId,
        filter: "Daily",
        fromDate: monthStartDate(),
        toDate: todayDate(),
      });
      fetchAppliedData(item.loan_status_code);
    } else if (type === "img") {
      console.log(item)
      // setShowBulkcommunication(item, true);
      setDetails(item)
      setBulkPopup(true)
    }
  };

  const fetchAppliedCountStats = () => {
    getAppliedCountStatsByPartnerId(
      payload,
      pageNo, noOfEntry,
      (callBack) => {
        console.log(callBack)
        setAppliedLeads(callBack);
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const fetchAppliedData = (loanStatus) => {
    getAppliedDataByPartnerId(payload, pageNo, noOfEntry,loanStatus, callBack => {
      setAppliedAllLeads(callBack);
      setShowAllLeads(true);
    })
    
  }
  const close = () => {
    setBulkPopup(false)
  }
  useEffect(() => {
    fetchAppliedCountStats();
  }, []);
  return (
    <>
      {bulkPopup ?
        <BulkCommunicationModal
          detailsData={detailsData}
          admin={admin}
          aggregator={aggregator} payload={payload}
          showBulkCommunicationEvent={showBulkCommunicationEvent}
          showBulkCommunicationLeadName={showBulkCommunicationLeadName}
          setShowBulkcommunication={close}
        />
        : ""}
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-2 col-xs-12">
            <img src={Filter} width={20} height={20} alt="" />
            <span className="ms-3 filterHeaders">Filter</span>
            <select
              name=""
              id=""
              value={payload.filter}
              className="form-select"
              style={{ width: '100%', padding: '7px' }}
            >
              <option value="daily">Daily</option>
            </select>
          </div>
          <div style={{ position: 'absolute', marginLeft: '15.5%', marginTop: '30px' }}>::</div>
          <div className="col-sm-2 col-xs-12">
            From
            <input
              type="date"
              id="startDate"
              placeholder="DD/MM/YY"
              name="fromDate"
              value={reverseFormat(payload.fromDate)}
              onChange={handleChange}
              className=" form-control"
              style={{
                border: "1px solid gray",
                borderRadius: "6px",
                padding: "10px",
              }}
            />

          </div>
          <div style={{ position: 'absolute', marginLeft: '31%', marginTop: '30px' }}>-</div>

          <div className="col-sm-2 col-xs-12">
            <div className="filterHeaders" style={{ marginTop: '4px' }}>to</div>
            <input
              type="date"
              id="endDate"
              name="toDate"
              max={new Date()}
              value={reverseFormat(payload.toDate)}
              onChange={handleChange}
              placeholder="DD/MM/YY"
              className=" form-control"
              style={{
                border: "1px solid gray",
                borderRadius: "6px",
                padding: "10px",
              }}
            />
          </div>
          <div className="col-sm-2 col-xs-12">
            <button
              className="btn btn-primary mt-4 "
              style={{
                color: "#2B78FF",
                borderColor: "#2B78FF",
                backgroundColor: "white",
                marginTop: '27px',
                padding: '5px',
                borderRadius: '5px'
              }}
              onClick={() =>
                showAllLeads ? fetchAppliedData() : fetchAppliedCountStats()
              }
            >
              Apply Filter
            </button>
          </div>

        </div>
        <hr />
        <div
          className="mt-2"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div className="d-inline-flex" style={{ display: 'inline-flex' }}>
            <img src={List} alt="" style={{ marginTop: '10px' }} width={18} height={18} />{" "} &nbsp;&nbsp;
            <h4 className="ms-3 leadBreakDown_Header">Applied Lead List</h4>
          </div>
          {/* {!showAllLeads ? (
            <h6 className="disbursedHeader">
              Total Pushed Leads :{" "}
              <span className="disbursedAmountHeader">750</span>
            </h6>
          ) : (
            ""
          )} */}
        </div>
        {showAllLeads ? (
          <AppliedLeadListTable appliedAllLeads={appliedAllLeads} />
        ) : (
          <div className='credit-table'>
            <table>
              <thead style={{ background: '#b9d8f9', color: '#000' }}>
                <tr className="thead-class">
                  <th className="">Loan Status</th>
                  <th className="">Lead Counts</th>
                  <th className="">Bulk Communication</th>
                </tr>
              </thead>
              <tbody>
                {console.log('appliedLeads', appliedLeads)}
                {appliedLeads !== '' &&
                  appliedLeads.map((item) => {
                    return (
                      <tr style={{ borderBottom: "none" }}   onClick={() => {
                        handleRefr("td",item);
                      }}>
                        <td
                          scope="col"
                        
                        >
                          <span id="comment">
                            <b>{item.loan_status}</b>
                          </span>
                        </td>
                        <td
                          scope="col"
                        
                        >
                          <span id="comment">
                            <b>{item.count}</b>
                          </span>
                        </td>
                        <td>
                          <img
                            onClick={() => {
                              handleRefr("img", item);
                            }}
                            className="hoverCusrsor"
                            src={communication}
                            width={30}
                            height={30}
                            alt=""
                          />
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        )}
      </div>

    </>
  );
};

export default AppliedLeadList;
