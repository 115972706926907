import React, { useState } from 'react'
import moment from 'moment'

const differenceDate = () => {
  let d = new Date()
  let todaysDate = d.getDate();
  return todaysDate - 1
}

const InitalDate = (type) => {
  const FirstDay = moment().subtract(differenceDate(), 'days');
  if (type === 'fromDate') {
    const fromdate = FirstDay.format('YYYY-MM-DD');
    return fromdate
  } else if (type === 'toDate') {
    const todate = moment(new Date).format('YYYY-MM-DD')
    return todate
  }
}

export default function LoanApproval() {
  const itemsPerPage = 10;
  const [pageNumberCreditReporTable, setpageNumberCreditReporTable] = useState(1)
  const [pageNumberqualifiedLoansTable, setpageNumberqualifiedLoansTable] = useState(1)
  const [fromDate, setFromDate] = useState(InitalDate('fromDate'))
  const [toDate, setToDate] = useState(InitalDate('toDate'))
  const [clearbtnShow, setClearBtnShow] = useState(false);
  const [selectedcreditReport, setSelectedCreditReport] = useState({})
  const [selectedcreditReportIndex, setSelectedCreditReportIndex] = useState(-1)
  const handleDate = (e, type) => {
    if (type === 'fromDate') {
      setFromDate(e.target.value)
      setClearBtnShow(true)

    } else if (type === 'toDate') {
      setToDate(e.target.value)
      setClearBtnShow(true)

    } else if (type === 'clearFilter') {
      setClearBtnShow(false)
      setFromDate(InitalDate('fromDate'))
      setToDate(InitalDate('toDate'))

    } else if (type === 'applyFilter') {

    }
  }

  const leadcreditReportData = [
    {
      fName: 'ghhj',
      lName: 'ghhj',
      email: 'cfghwe333@gmail.com',
      phone: '34567890',
      creditScore: 632,
      loanAmount: 2321,
      monthlyIncome: 1
    },
    {
      fName: 'ghhj',
      lName: 'ghhj',
      email: 'cfghwe333@gmail.com',
      phone: '34567890',
      creditScore: 632,
      loanAmount: 2321,
      monthlyIncome: 1
    },
    {
      fName: 'ghhj',
      lName: 'ghhj',
      email: 'cfghwe333@gmail.com',
      phone: '34567890',
      creditScore: 632,
      loanAmount: 2321,
      monthlyIncome: 1
    },
    {
      fName: 'ghhj',
      lName: 'ghhj',
      email: 'cfghwe333@gmail.com',
      phone: '34567890',
      creditScore: 632,
      loanAmount: 2321,
      monthlyIncome: 1
    },

  ]

  const qualifiedLoansData = [
    {
      id: '100',
      fName: 'ghhj',
      lName: 'ghhj',
      email: 'cfghwe333@gmail.com',
      phone: '34567890',
      loanAmount: 2321,
      status: 'Accespted',
      stageName: 'Qualified'
    },
    {
      id: '100',
      fName: 'ghhj',
      lName: 'ghhj',
      email: 'cfghwe333@gmail.com',
      phone: '34567890',
      loanAmount: 2321,
      status: 'Accespted',
      stageName: 'Qualified'
    },
    {
      id: '100',
      fName: 'ghhj',
      lName: 'ghhj',
      email: 'cfghwe333@gmail.com',
      phone: '34567890',
      loanAmount: 2321,
      status: 'Accespted',
      stageName: 'Qualified'
    },

  ]

  const handleRefresh = (type) => {
    if (type === 'leadCreditReport') {
      setSelectedCreditReport({})
      setSelectedCreditReportIndex(-1)

    } else if (type === 'qualifiedLoans') {

    }
  }

  const paginationfn = (tabletype, paginationtype) => {
    if (paginationtype === 'left') {
      if (tabletype === 'leadCreditReport') {
        setpageNumberCreditReporTable(pageNumberCreditReporTable > 1 ? (prev) => prev - 1 : 1)
      } else if (tabletype === 'qualifiedLoans') {
        setpageNumberqualifiedLoansTable(pageNumberqualifiedLoansTable > 1 ? (prev) => prev - 1 : 1)

      }
    } else if (paginationtype === 'right') {
      if (tabletype === 'leadCreditReport') {
        setpageNumberCreditReporTable(pageNumberCreditReporTable < Math.ceil(leadcreditReportData?.length / itemsPerPage) ? (prev) => prev + 1 : Math.ceil(leadcreditReportData?.length / itemsPerPage))
      } else if (tabletype === 'qualifiedLoans') {
        setpageNumberqualifiedLoansTable(pageNumberqualifiedLoansTable < Math.ceil(qualifiedLoansData?.length / itemsPerPage) ? (prev) => prev - 1 : Math.ceil(qualifiedLoansData?.length / itemsPerPage))

      }
    }
  }

  const handleapproveReject = (type) => {
    if (type === 'approve') {

    } else if (type === 'reject') {

    }
  }

  const handleleadCreditReport = (index, data) => {
    if (index === selectedcreditReportIndex) {
      setSelectedCreditReport({})
      setSelectedCreditReportIndex(-1)

    } else {
      setSelectedCreditReport(data)
      setSelectedCreditReportIndex(index)
    }
  }


  return (
    <div className="LoanApproval">
      <div className="Filterations">
        <div className="row">
          <div className="col-lg-2 col-md-4 col-sm-6 col-xs-12">
            <div className="inputgrp">
              <label htmlFor="fromDate" className='inputlabel'>From</label>
              <input type="date" id='fromDate' className='inputtag' value={fromDate} onChange={(e) => handleDate(e, 'fromDate')} />
            </div>
          </div>
          <div className="col-lg-2 col-md-4 col-sm-6 col-xs-12">
            <div className="inputgrp">
              <label htmlFor="toDate" className='inputlabel'>To</label>
              <input type="date" id='toDate' className='inputtag' value={toDate} min={fromDate} onChange={(e) => handleDate(e, 'toDate')} />
            </div>
          </div>
          <div className="col-lg-3 col-md-4 col-sm-6 col-xs-12">
            <div style={{ display: 'flex' }}>
              <button className='filterbtn' style={{ marginTop: '38px', marginRight: '20px' }} onClick={(e) => handleDate(e, 'applyFilter')}>Apply Filter</button>
              {
                clearbtnShow ?
                  <button className='filterbtnReverse' style={{ marginTop: '38px' }} onClick={(e) => handleDate(e, 'clearFilter')}>Clear Filter</button>
                  : ''}
            </div>
          </div>
        </div>
      </div>
      <div className="sectionss">
        <div className="heading">
          <h5>View & Edit Lead Credit Report</h5>
        </div>
        <div className="row">
          <div className={selectedcreditReportIndex == '-1' ? 'col-xs-12' :"col-lg-5 col-md-6 col-xs-12"} style={{transition: 'all 0.2s ease-in-out'}}>
            <div className="table" style={{ marginTop: '20px' }}>
              <div className="phocket-table-new newTable" style={{ overflowY: 'auto', height: window.innerWidth > 1800 ? '362px':'215px', marginTop: '0px' }}>
                <table>
                  <thead style={{ position: 'sticky', top: 0 }}>
                    <tr>
                      <th>First Name</th>
                      <th>Last Name</th>
                      <th>Phone</th>
                      <th>Credit Score</th>
                    </tr>
                  </thead>
                  <tbody>
                    {leadcreditReportData.map((data, index) => (
                      <tr className={selectedcreditReportIndex === index ? 'activeTr' : ''} key={index} style={{ cursor: 'pointer' }} onClick={() => handleleadCreditReport(index, data)}>
                        <td>{data.fName}</td>
                        <td>{data.lName}</td>
                        <td>{data.phone}</td>
                        <td>{data.creditScore}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className='table-footer'>
                <div>
                  <h5>{leadcreditReportData?.length + ' results'}</h5>
                </div>
                <div className="paginationBox">
                  <h5 className="LeftArrow" onClick={() => paginationfn('leadCreditReport', 'left')}>{'<'}</h5>
                  <h5 className='pagenumber'>Page {pageNumberCreditReporTable} of {Math.ceil(leadcreditReportData?.length / itemsPerPage)}</h5>
                  <h5 className="LeftArrow" onClick={() => paginationfn('leadCreditReport', 'right')}>{'>'}</h5>
                </div>
                <div>
                  <h5><i class="fa fa-refresh" aria-hidden="true" onClick={() => handleRefresh('leadCreditReport')}></i></h5>
                </div>
              </div>
            </div>

            {
              Object.keys(selectedcreditReport)?.length > 0 ?
                <div className="qualifyloanbtn">
                  <div className="txt" style={{ paddingLeft: '20px' }}>
                    <h5>{'Qualify Loans >> '}</h5>
                  </div>
                  <div className="btn" style={{boxShadow:'none'}}>
                    <button className='qualifytbtn' style={{ backgroundColor: '#800080cc' }} onClick={() => handleapproveReject('approve')}>Approve</button>
                    <button className='qualifytbtn' style={{ backgroundColor: '#0d49a3cc' }} onClick={() => handleapproveReject('reject')}>Reject</button>
                  </div>
                </div>
                : ''}
          </div>
          {
            Object.keys(selectedcreditReport)?.length > 0 ?
              <div className="col-lg-7 col-md-6 col-xs-12">
                <div className="dataSection" style={{ marginTop: '20px' }}>
                  <div className="block">
                    <h3>Personal Information</h3>
                    <div className="row">
                      <div className="col-lg-6 col-xs-12">
                        <div className="info">
                          <h4>First Name:</h4>
                          <h5>{selectedcreditReport?.fName}</h5>
                        </div>
                      </div>
                      <div className="col-lg-6 col-xs-12">
                        <div className="info">
                          <h4>Last Name:</h4>
                          <h5>{selectedcreditReport?.lName}</h5>
                        </div>
                      </div>
                      <div className="col-lg-6 col-xs-12">
                        <div className="info">
                          <h4>Email Id:</h4>
                          <h5>{selectedcreditReport?.email}</h5>
                        </div>
                      </div>
                      <div className="col-lg-6 col-xs-12">
                        <div className="info">
                          <h4>Phone:</h4>
                          <h5>{selectedcreditReport?.phone}</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="block" style={{ border: 'none' }}>
                    <h3>Financial Information</h3>
                    <div className="row">
                      <div className="col-lg-6 col-xs-12">
                        <div className="info">
                          <h4>Loan Amount:</h4>
                          <h5>{selectedcreditReport?.loanAmount}</h5>
                        </div>
                      </div>
                      <div className="col-lg-6 col-xs-12">
                        <div className="info">
                          <h4>Monthly Income:</h4>
                          <h5>{selectedcreditReport?.monthlyIncome}</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              : ''}
        </div>
      </div>
      <div className="sectionss">
        <div className="heading">
          <h5>Qualified Loans</h5>
        </div>
        <div className="row">
          <div className="col-xs-12">
            <div className="table" style={{ marginTop: '20px' }}>
              <div className="phocket-table-new newTable" style={{ overflowY: 'auto', height: window.innerWidth > 1800 ? '285px':'175px', marginTop: '0px' }}>
                <table>
                  <thead style={{ position: 'sticky', top: 0 }}>
                    <tr>
                      <th>ID</th>
                      <th>First Name</th>
                      <th>Last Name</th>
                      <th>Email</th>
                      <th>Status</th>
                      <th>Stage Name</th>
                      <th>Loan Amount</th>
                      <th>Phone</th>
                    </tr>
                  </thead>
                  <tbody>
                    {qualifiedLoansData.map((data, index) => (
                      <tr key={index}>
                        <td>{data.id}</td>
                        <td>{data.fName}</td>
                        <td>{data.lName}</td>
                        <td>{data.email}</td>
                        <td>{data.status}</td>
                        <td>{data.stageName}</td>
                        <td>{data.loanAmount}</td>
                        <td>{data.phone}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className='table-footer'>
                <div>
                  <h5>{qualifiedLoansData?.length + ' results'}</h5>
                </div>
                <div className="paginationBox">
                  <h5 className="LeftArrow" onClick={() => paginationfn('qualifiedLoans', 'left')}>{'<'}</h5>
                  <h5 className='pagenumber'>Page {pageNumberqualifiedLoansTable} of {Math.ceil(qualifiedLoansData?.length / itemsPerPage)}</h5>
                  <h5 className="LeftArrow" onClick={() => paginationfn('qualifiedLoans', 'right')}>{'>'}</h5>
                </div>
                <div>
                  <h5><i class="fa fa-refresh" aria-hidden="true" onClick={() => handleRefresh('qualifiedLoans')}></i></h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
