import React, { useEffect, useState } from 'react'
import uploadicon from './images/uploadicon.png'
import eyeicon from './images/eyeicon.png'
import successicon from './images/successicon.png'
import Failure from '../../../../images/rewampimages/unverified.png'

import Loader from '../../../presentationals/Loader/DotLoader.component';
import ReconcileTabs from './ReconcileTabs';
import { getCountsApi, getDataForReconciliation, getTypeDataApi, uploadRazorpayReport } from './actionCreator';
import { formatDate, todayDate } from '../../Marketing/maketingComponents/utils/utils';
import moment from 'moment';
import PopUp from '../../../presentationals/Popup/Popup.component'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
let dataTosend = []
let ApplicationId = []
let newdataToCheck = []
export default function AccountReconciliaitonPage({pageViewHandler}) {
    const [loaderState, setLoader] = useState(false);
    const [fromdate, setfromdate] = useState(new Date());
    const [todate, settodate] = useState(new Date());
    const [reconcilemodal, setreconcilemodal] = useState(false);
    const [ARPagetab, setARPagetab] = useState('MainPage');
    const [phocketData, setPhocketData] = useState([]);
    const [razorpayData, setRazorPayData] = useState([]);
    const [countData, setCountData] = useState([]);
    const [csvFile, setCsvFile] = useState('');
    const [typeData, setTypeData] = useState([]);
    const [sidePopUp, setsidePopUp] = useState(false);
    const [popupStatus, setPopupStatus] = useState(false);
    const [selectedEntries, setSelectedEntries] = useState([]);
    const [selectAll, setSelectAll] = useState(false);
    const [paymentList, setpaymentList] = useState([]);

    let data = 'Approve';

    const handleSubPagechange = (type) => {
        getTypeData(type)
        setARPagetab(type);
        setreconcilemodal(false);
    }

    const handleChange = (e) => {
        if (e.target.name === "fromDate") {
            setfromdate(e.target.value);
        } else {
            settodate(e.target.value);

        }
    };
    const getData = () => {
        setLoader(true)

        getDataForReconciliation(callback => {
            setPhocketData(callback)
            setLoader(false)

        })

    }

    const handlereconcilebtn = () => {
        getCountsApi(csvFile, dataTosend, callback => {
            setCountData(JSON.parse(callback))
            setLoader(false)
            setreconcilemodal(true);

        })


    }
    useEffect(() => {
        // getData()
    }, [])
    const uploadReport = (e) => {
        setCsvFile(e.target.files[0])

    }
    const uploadFile = () => {
        setLoader(true)

        uploadRazorpayReport(csvFile, callback => {
            setRazorPayData(JSON.parse(callback))
            setLoader(false)
            if (JSON.parse(callback) && JSON.parse(callback).error.status === 'failure') {
                setPopupStatus(JSON.parse(callback).error.errorMessage)
                setsidePopUp(true)
                removePopup()
                setLoader(false)
            }

        })
    }
    const removePopup = () => {
        setTimeout(() => {
            setsidePopUp(false)
        }, 5000);
    }
    const getTypeData = (type) => {
        setLoader(true)
        getTypeDataApi(csvFile, dataTosend, type, callback => {
            setTypeData(JSON.parse(callback))
            setLoader(false)

        })
    }
    const closePopup = () => {
        setsidePopUp(false)
    }

    const handleSelectAll = (e) => {
        dataTosend = []
        if (razorpayData && e.target.checked) {
            razorpayData.dbData.forEach((dataNew, i) => {
                dataTosend.push(dataNew);
                newdataToCheck.push(i);
                document.getElementById('accountreconc' + i).checked = true;
            });
        } else {
            dataTosend = []
            newdataToCheck.forEach((newl, i) => {
                document.getElementById('accountreconc' + newl).checked = false;
            });
        }
        console.log(dataTosend, 'handleSelectAll')
    };



    const changehandler = (e, data) => {
        if (e.target.checked) {
            dataTosend.push(data);
        } else {
            const index = dataTosend.findIndex(item => item.loanId === data.loanId);
            if (index !== -1) {
                dataTosend.splice(index, 1);
            }
        }
        console.log(dataTosend, 'changehandler')

    }



    return (
        <>
            {sidePopUp ? <PopUp popupStatus={popupStatus} closePopup={closePopup} /> : ""}
            {loaderState ? <Loader /> : ""}
            <div className="AccountReconciliaitonPage AccountReconciliaiton-CSS container-fluid full-height">
                {
                    ARPagetab === 'MainPage' ?
                        (
                            <>
                                <div className="row" style={{ marginTop: '20px', paddingLeft: '15px' }}>
                                    <h3 className="mb-0"><ArrowBackIosNewIcon onClick={(e) => pageViewHandler(e, 'landingPage')} style={{ cursor: 'pointer',fontSize:'19px' }}/>&nbsp; <b onClick={(e) => pageViewHandler(e, 'landingPage')}>Account Reconciliation</b></h3>
                                </div>
                                <div className="row" style={{ marginTop: '20px' }}>
                                    <div className="col-sm-3 col-xs-12">
                                        <div className="filterHeaders" style={{ marginTop: "4px" }}> Upload Razorpay Report</div>
                                        <input
                                            className="btn btn-primary mt-4 "
                                            type='file'
                                            accept='.csv'
                                            onChange={(e) => uploadReport(e)}
                                            style={{

                                                color: "white",
                                                borderColor: "#2B78FF",
                                                backgroundColor: "#2B78FF",
                                                marginTop: "9px",
                                                padding: "10px",
                                                borderRadius: "5px",
                                            }}

                                        >
                                            {/* <img src={uploadicon} width={'12%'} alt="" />&nbsp;
                                            Upload Razorpay Report */}
                                        </input>

                                    </div>
                                    {csvFile !== '' ?
                                        <div className='col-sm-1 col-xs-12'>
                                            <button className='btn btn-primary' style={{ backgroundColor: "#2B78FF", marginTop: '30px', padding: '10px' }} onClick={() => uploadFile()}>Upload</button>
                                        </div>
                                        : ""}
                                </div>

                                <div className="ARTable">
                                    <div className="row">
                                        <div className="col-lg-6 col-md-12" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.25)' }}>
                                            <div className="tablecol">
                                                <div className="heading" style={{ marginTop: '20px' }}>
                                                    <h5>Our Database Info</h5>
                                                </div>
                                                <div className='credit-table tablefirst' style={{ fontSize: '12px' }} >
                                                    <table className='table1'>
                                                        <thead style={{ background: '#b9d8f9', color: '#000' }}>
                                                            <tr className="thead-class">
                                                                <th className="">
                                                                    <input
                                                                        type="checkbox"
                                                                        onChange={(e) => handleSelectAll(e)}
                                                                    // checked={selectAll}
                                                                    />
                                                                </th>
                                                                <th className="" style={{ width: '20px' }}>
                                                                    Payment Id
                                                                </th>
                                                                <th className="">
                                                                    Amount
                                                                </th>
                                                                <th className="">
                                                                    Application Id
                                                                </th>
                                                                <th className="">
                                                                    Phone Number
                                                                </th>
                                                                <th className="">
                                                                    Paid Date
                                                                </th>
                                                                <th className="">
                                                                    Emi Status
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {razorpayData && razorpayData.dbData && Object.entries(razorpayData.dbData).map((data, i) => {

                                                                return (
                                                                    <tr style={{ borderBottom: "none", fontWeight: '400' }}>
                                                                        <td scope="col">
                                                                            <td><input id={'accountreconc' + i} type='checkbox' onChange={(e) => changehandler(e, data[1])} /></td>

                                                                        </td>

                                                                        <td scope="col" >
                                                                            <div style={{ width: '', overflow: 'auto' }}>{data[1].paymentId}</div>
                                                                        </td>
                                                                        <td scope="col">
                                                                            ₹ {data[1].amount}
                                                                        </td>

                                                                        <td scope="col">
                                                                            {data[1].appId}
                                                                        </td>
                                                                        <td scope="col">
                                                                            {data[1].phoneNo}
                                                                        </td>
                                                                        <td scope="col">
                                                                            {data[1].paidDate ? <>{moment(data[1].paidDate).format('DD-MM-YYYY')}<br />{moment(data[1].paidDate).format('HH:MM:SS')}</> : ""}
                                                                        </td>
                                                                        {
                                                                            data[1].status === 'Approve' ?
                                                                                <td scope="col" className='status' style={{ color: 'rgba(0, 200, 81, 1)' }}>
                                                                                    Approve
                                                                                </td>
                                                                                :
                                                                                data[1].status === 'Received' ?
                                                                                    <td scope="col" className='status' style={{ color: 'rgba(0, 200, 81, 1)' }}>
                                                                                        Received
                                                                                    </td>
                                                                                    :
                                                                                    data[1].status === 'Reject' ?
                                                                                        <td scope="col" className='status' style={{ color: 'rgba(177, 47, 48, 1)' }}>
                                                                                            Reject
                                                                                        </td>
                                                                                        :
                                                                                        data[1].status === 'Expire' ?
                                                                                            <td scope="col" className='status' style={{ color: 'rgba(255, 68, 68, 1)' }}>
                                                                                                Expire
                                                                                            </td>
                                                                                            :
                                                                                            data[1].status === 'Overdue' ?
                                                                                                <td scope="col" className='status' style={{ color: 'rgba(255, 119, 43, 1)' }}>
                                                                                                    Overdue
                                                                                                </td>
                                                                                                :
                                                                                                data[1].status === 'Paid' ?
                                                                                                    <td scope="col" className='status' style={{ color: 'rgba(38, 125, 255, 1)' }}>
                                                                                                        Paid
                                                                                                    </td>
                                                                                                    : ''}
                                                                    </tr>

                                                                )
                                                            })}


                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-12">
                                            <div className="tablecol">
                                                <div className="heading secondary">
                                                    <h5>Razorpay Report</h5>
                                                    <button
                                                        className="btn btn-primary mt-4 "
                                                        style={{
                                                            color: "white",
                                                            borderColor: "#2B78FF",
                                                            backgroundColor: "#2B78FF",

                                                            padding: "3px 15px",
                                                            borderRadius: "5px",
                                                        }}
                                                        onClick={() => handlereconcilebtn()}
                                                    >
                                                        Reconcile
                                                    </button>
                                                </div>
                                                <div className='credit-table tablesecond' style={{ fontSize: '12px' }} >
                                                    <table className=' table2'>
                                                        <thead style={{ background: '#b9d8f9', color: '#000' }}>
                                                            <tr className="thead-class">

                                                                <th className="">
                                                                    Payment Id
                                                                </th>
                                                                <th className="">
                                                                    Amount
                                                                </th>
                                                                <th className="">
                                                                    Phone Number
                                                                </th>
                                                                <th className="">
                                                                    Paid Date
                                                                </th>
                                                                <th className="">
                                                                    Status
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {razorpayData && razorpayData.csv
                                                                && Object.entries(razorpayData.csv
                                                                ).map((data, i) => {
                                                                    return (
                                                                        <tr style={{ borderBottom: "none", fontWeight: '400' }}>

                                                                            <td scope="col">
                                                                                {data[0]}
                                                                            </td>
                                                                            <td scope="col">
                                                                                ₹ {data[1].amount}
                                                                            </td>

                                                                            <td scope="col">
                                                                                {data[1].mobileNumber}
                                                                            </td>
                                                                            <td scope="col">
                                                                                {data[1].paidDate ? <>{moment(data[1].paidDate).format('DD-MM-YYYY')}<br />{moment(data[1].paidDate).format('HH:MM:SS')}</> : ""}
                                                                            </td>
                                                                            <td scope="col">

                                                                                {data[1].status === 'captured' ? <h5 className='text-success fw-bold'><img src={successicon} width={'10% '} alt="" /> &nbsp; Captured</h5> : ""}
                                                                                {data[1].status === 'failed' ? <h5 className='fw-bold' style={{ color: 'red' }}><img src={Failure} width={'10% '} alt="" /> &nbsp; Failed</h5> : ""}

                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                })}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {
                                    reconcilemodal && (
                                        <>
                                            <div className="background-blur"></div>
                                            < div class={`modal ${reconcilemodal === true ? 'animation-top' : ''}`}>
                                                <div class="modal-dialog">
                                                    <div class="modal-content">
                                                        <div class="modalheader">
                                                            <h4 class="">Reconcile Information</h4>
                                                            <button type="button" onClick={() => setreconcilemodal(false)}>
                                                                <i class="fa fa-times fs-1"></i>
                                                            </button>
                                                        </div>
                                                        <div class="modal-body">
                                                            <div className='credit-table tablesecond' >
                                                                <table className=' table2'>
                                                                    <thead style={{ background: '#b9d8f9', color: '#000' }}>
                                                                        <tr className="thead-class">

                                                                            <th className="">
                                                                                Name
                                                                            </th>
                                                                            <th className="">
                                                                                Numbers
                                                                            </th>

                                                                            <th className="">
                                                                                Button
                                                                            </th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody style={{ fontWeight: '400' }}>
                                                                        <tr>

                                                                            <td scope="col">
                                                                                Match
                                                                            </td>
                                                                            <td scope="col">
                                                                                {countData && countData.data ? countData.data.match : ""}
                                                                            </td>

                                                                            <td scope="col" className='buttoncolor'>
                                                                                <h5 onClick={() => handleSubPagechange('match')}>
                                                                                    Reconcile
                                                                                </h5>
                                                                            </td>

                                                                        </tr>
                                                                        <tr>

                                                                            <td scope="col">
                                                                                Not Match
                                                                            </td>
                                                                            <td scope="col">
                                                                                {countData && countData.data ? countData.data.notMatch : ""}

                                                                            </td>

                                                                            <td scope="col" className='buttoncolor'>
                                                                                <h5 onClick={() => handleSubPagechange('notMatch')}>
                                                                                    <img src={eyeicon} width={'10%'} alt="" /> View More
                                                                                </h5>
                                                                            </td>

                                                                        </tr>
                                                                        <tr>

                                                                            <td scope="col">
                                                                                Not Found in Razorpay
                                                                            </td>
                                                                            <td scope="col">
                                                                                {countData && countData.data ? countData.data.notInRZPReport : ""}

                                                                            </td>

                                                                            <td scope="col" className='buttoncolor'>
                                                                                <h5 onClick={() => handleSubPagechange('notInReport')}>
                                                                                    <img src={eyeicon} width={'10%'} alt="" /> View More
                                                                                </h5>
                                                                            </td>

                                                                        </tr>
                                                                        <tr>

                                                                            <td scope="col">
                                                                                Not Found in Micromoney
                                                                            </td>
                                                                            <td scope="col">
                                                                                {countData && countData.data ? countData.data.notInDB : ""}

                                                                            </td>

                                                                            <td scope="col" className='buttoncolor'>
                                                                                <h5 onClick={() => handleSubPagechange('notInDB')} >
                                                                                    <img src={eyeicon} width={'10%'} alt="" /> View More
                                                                                </h5>
                                                                            </td>

                                                                        </tr>
                                                                        <tr>

                                                                            <td scope="col">
                                                                                Not Found in Both
                                                                            </td>
                                                                            <td scope="col">
                                                                                {countData && countData.data ? countData.data.notInBoth : ""}

                                                                            </td>

                                                                            <td scope="col" className='buttoncolor'>
                                                                                <h5 onClick={() => handleSubPagechange('notInBoth')}>
                                                                                    <img src={eyeicon} width={'10%'} alt="" /> View More
                                                                                </h5>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    )
                                }
                            </>
                        )
                        :
                        ARPagetab === 'match' || ARPagetab === 'notMatch' || ARPagetab === 'notInDB' || ARPagetab === 'notInReport' || ARPagetab === 'notInBoth' ?
                            <ReconcileTabs typeData={typeData} countData={countData} handlePagechange={handleSubPagechange} Pagetabvalue={ARPagetab} />
                            : ''}

            </div>
        </>
    )
}
