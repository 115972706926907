import React from 'react'
import ProfilePage from '../../../presentationals/CardsUtility/profileDetails.component'
import BankPage from '../../../presentationals/CardsUtility/bankdetails.component'
import TransactionPage from './transactions.container'
import AddressPage from '../../../presentationals/CardsUtility/addressDetails.component'
import CommunicationPage from './Communication.container'
import EDitpage from './edit.container'
import Activity from './activity.container'
import FundDetailspage from './Funddetails.container'
import OtpPopup from '../../../presentationals/Popup/otpPopup.component'
import Loader from '../../../presentationals/Loader/Loader.component'
import SidePopup from '../../../presentationals/Popup/Popup.component'
import { getTransactionDetailsByUserIdApi, getBankDetailsByUserIdApi, getAddressDetailsByUserIdApi, addFalconUser, completeZeroTouchKyc } from './actionCreator'
class Details extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            editState: false,
            profileState: true,
            transactionState: false,
            bankState: false,
            transactionData: '',
            bankData: '',
            addressState: false,
            addressData: '',
            communicationState: false,
            fundDetailsState: false,
            cardId: this.props.dataFromAllLeads.cardId !== undefined ? this.props.dataFromAllLeads.cardId : '',
            otpPoup: false,
            otpValue: '',
            loaderState: false,
            popupState: false,
            popupStatus: '',
            allStatus: '',
            activityState: false
        }
    }

    otpHandler(e) {
        this.setState({ otpValue: e.target.value })
    }
    OtpSubmit() {
        const {admin}=this.props
        this.setState({ loaderState: true })
        completeZeroTouchKyc(this.props.userDetailsByUserId, this.state.otpValue,admin.emailId, callback => {
            if (callback === 'success') {
                this.setState({ otpPoup: false, popupState: true, popupStatus: 'Added Successfully !', loaderState: false }, () => this.removePopup())
            } else {
                this.setState({ otpPoup: false, popupState: true, popupStatus: 'Please Try Again Later !', loaderState: false }, () => this.removePopup())

            }
        })
    }
    componentDidMount() {
        // console.log(this.props, 'falconUser')
        this.bankDetails()
        this.addressDetails()
    }

    bankDetails() {
        getBankDetailsByUserIdApi(this.props.userDetailsByUserId.userId, callback => {
            this.setState({ bankData: callback })
        })
    }
    addressDetails() {
        getAddressDetailsByUserIdApi(this.props.userDetailsByUserId.userId, callback => {
            this.setState({ addressData: callback })

        })
    }
    tabHandler(data) {
        if (data === 'profile') {
            this.setState({ activityState: false, fundDetailsState: false, editState: false, communicationState: false, addressState: false, profileState: true, transactionState: false, bankState: false })
        }
        if (data === 'transaction') {
            this.setState({ activityState: false, fundDetailsState: false, editState: false, communicationState: false, addressState: false, profileState: false, transactionState: true, bankState: false })
            // this.transactionDetails()
        }
        if (data === 'communication') {
            this.setState({ activityState: false, fundDetailsState: false, editState: false, communicationState: true, addressState: false, profileState: false, transactionState: false, bankState: false })
            // this.bankDetails()
        }
        if (data === 'edit') {
            this.setState({ activityState: false, fundDetailsState: false, editState: true, communicationState: false, addressState: false, profileState: false, transactionState: false, bankState: false })
        }
        if (data === 'fundDetails') {
            this.setState({ activityState: false, fundDetailsState: true, editState: false, communicationState: false, addressState: false, profileState: false, transactionState: false, bankState: false })
        }
        if (data === 'activity') {
            this.setState({ activityState: true, fundDetailsState: false, editState: false, communicationState: false, addressState: false, profileState: false, transactionState: false, bankState: false })

        }
    }
    addFalconUser() {
        const{admin}=this.props
        this.setState({ loaderState: true })
        addFalconUser(this.props.userDetailsByUserId,admin.emailId, callback => {
            if (callback === 'success') {
                this.setState({ otpPoup: true, loaderState: false })

            } else {
                this.setState({ popupState: true, popupStatus: 'Please Try Again Later !', otpPoup: false, loaderState: false }, () => this.removePopup())

            }
        })
    }

    removePopup() {
        setTimeout(function () {
            this.setState({ popupState: false });
        }.bind(this), 5000)
    }
    closePopup() {
        this.setState({ popupState: false })
    }
    backtoallleads() {
        this.setState({ profileState: true, editState: false })
    }
    render() {
        const {admin}=this.props

        return (
            <React.Fragment>
                <div className="col-xs-12 col-sm-12">
                    <div className='row'>
                        <div id="material-tabs">
                            <a onClick={() => this.tabHandler('profile')} className={this.state.profileState ? 'card-tab-active' : ''}>User Details</a>
                            <a onClick={() => this.tabHandler('edit')} className={this.state.editState ? 'card-tab-active' : ''} >Edit</a>
                            <a onClick={() => this.tabHandler('transaction')} className={this.state.transactionState ? 'card-tab-active' : ''}>Transaction Details</a>
                            <a onClick={() => this.tabHandler('communication')} className={this.state.communicationState ? 'card-tab-active' : ''} >Communication</a>
                            <a onClick={() => this.tabHandler('fundDetails')} className={this.state.fundDetailsState ? 'card-tab-active' : ''} >Fund Details</a>
                            <a onClick={() => this.tabHandler('activity')} className={this.state.activityState ? 'card-tab-active' : ''} >Activity</a>
                            <span class="yellow-bar"></span>
                        </div>

                    </div>
                </div>
                <br />

                {this.state.profileState ?
                    <ProfilePage addFalconUser={this.addFalconUser.bind(this)}
                        falconUser={this.props.userDetailsByUserId.falconUser}
                        bankData={this.state.bankData}
                        addressData={this.state.addressData}
                        userDetailsByUserId={this.props.userDetailsByUserId} />
                    : ""}
                {this.state.bankState ?
                    <BankPage bankData={this.state.bankData} userDetailsByUserId={this.props.userDetailsByUserId} />
                    : ""}
                {this.state.transactionState ?
                    <TransactionPage admin={admin} cardId={this.state.cardId} transactionData={this.state.transactionData} userDetailsByUserId={this.props.userDetailsByUserId} />
                    : ""}
                {this.state.addressState ?
                    <AddressPage addressData={this.state.addressData} userDetailsByUserId={this.props.userDetailsByUserId} />
                    : ""}
                {this.state.communicationState ?
                    <CommunicationPage allDetail={this.props.userDetailsByUserId} />
                    : ""}
                {this.state.editState ?
                    <EDitpage userDetailsByUserId={this.props.userDetailsByUserId} />
                    : ""}
                {this.state.fundDetailsState ?
                    <FundDetailspage cardId={this.state.cardId} userDetailsByUserId={this.props.userDetailsByUserId} />
                    : ""}
                {this.state.otpPoup ?
                    <OtpPopup OtpSubmit={this.OtpSubmit.bind(this)} otpHandler={this.otpHandler.bind(this)} />
                    : ""}
                {this.state.loaderState ?
                    <Loader />
                    : ""}
                {this.state.popupState ?
                    <SidePopup popupStatus={this.state.popupStatus} closePopup={this.closePopup.bind(this)} />
                    : ""}
                    {this.state.activityState?
                    <Activity userDetailsByUserId={this.props.userDetailsByUserId}/>
                :""}
            </React.Fragment>

        )
    }
}
export default Details