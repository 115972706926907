import React, { Component } from 'react'
import Comment from '../../../presentationals/Admin/CustomerPages/Logs.component'
import { getCommentAdmin } from '../AdminActionCreator.component'
class CommentContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            commentDataState: '',
            popupData: '',
        }
    }

    render() {
        return (
            <Comment
                communicationPopup={this.communicationPopup.bind(this)}
                popupData={this.state.popupData}
                commentDataState={this.state.commentDataState}
            />
        )
    }

    componentDidMount() {
        const { allDetail } = this.props;
        getCommentAdmin(allDetail.userId,
            (callBack) => {
                this.setState({
                    commentDataState: callBack
                })
            })
    }

    communicationPopup(commentData) {
        this.setState({
            popupData: commentData
        })
    }

}

export default CommentContainer;