import React from 'react'

const AMBDetail = ({ ambassadorAllData }) => (
    <div className="container-fluid crm-user-detail">
        <div className="row">
            <div className="crm-user-head">Personal Information</div>
        </div>
        <div className="row">
            <div className="col-sm-6 col-xs-12">
                <p className="word-break"><b>Name  </b>{ambassadorAllData != null ? ambassadorAllData.fullName != null ? ambassadorAllData.fullName : "" : ""}</p>
            </div>
            <div className="col-sm-6 col-xs-12">
                <p><b>D.O.B  </b>
                    {ambassadorAllData != null ? ambassadorAllData.dateOfBirth != null ? " - " + ambassadorAllData.dateOfBirth.split("T")[0] : "" : ""}</p>
            </div>
        </div>
        <div className="row">
            <div className="col-sm-6 col-xs-12 ">
                <p><b>Gender  </b>{ambassadorAllData != null ? ambassadorAllData.gender != null ? " - " + ambassadorAllData.gender : "" : ""}</p>
            </div>
            <div className="col-sm-6 col-xs-12 ">
                <p className="word-break"><b>Email Id  </b>{ambassadorAllData != null ? ambassadorAllData.emailId != null ? " - " + ambassadorAllData.emailId : "" : ""}</p>
            </div>
        </div>
        <div className="row">
            <div className="col-sm-6 col-xs-12">
                <p><b>Mobile Number</b>{ambassadorAllData != null ? ambassadorAllData.mobileNumber != null ? " - " + ambassadorAllData.mobileNumber : "" : ""}</p>
            </div>
            <div className="col-sm-6 col-xs-12">
                <p><b>Highest Oualification - </b>{ambassadorAllData != null ? ambassadorAllData.qualification != null ? ambassadorAllData.qualification : "" : ""}</p>
            </div>
        </div>
        <div className="row">
            <div className="col-sm-6 col-xs-12">
                <p><b>Address</b>
                    <span>
                        {ambassadorAllData.address != null ? " - " + ambassadorAllData.address + ", " : ""}
                        {ambassadorAllData.city != null ? ambassadorAllData.city + ", " : ""}
                        {ambassadorAllData.state != null ? ambassadorAllData.state + ", - " : ""}
                        {ambassadorAllData.pincode != 0 && ambassadorAllData.pincode != null ? ambassadorAllData.pincode : ""}
                    </span>
                </p>
            </div>
        </div>
        <div className="row">
            <div className="crm-user-head">Documents</div>
        </div>
        <div className="row">
        {ambassadorAllData != null ? ambassadorAllData.ambassadorDocumentDO != null ? ambassadorAllData.ambassadorDocumentDO.addressImageUrl != null ?
                <div className="upload-docs col-sm-6 col-xs-12">
                    <h6>
                        <a target="_blank" href={ambassadorAllData.ambassadorDocumentDO.addressImageUrl}>Address Proof</a>
                    </h6>
                    {ambassadorAllData.ambassadorDocumentDO.addressImageUrl.split("type=")[1] == "img" ?
                        <img className="" src={ambassadorAllData.ambassadorDocumentDO.addressImageUrl} />
                        : ""}
                    {ambassadorAllData.ambassadorDocumentDO.addressImageUrl.split("type=")[1] == "pdf" ?
                        <object data={ambassadorAllData.ambassadorDocumentDO.addressImageUrl} type="application/pdf"></object>
                        : ""}
                    <div>
                    </div>
                </div>
                : "" : "" : ""}
            {ambassadorAllData != null ? ambassadorAllData.ambassadorDocumentDO != null ? ambassadorAllData.ambassadorDocumentDO.idImageUrl != null ?
                <div className="upload-docs col-sm-6 col-xs-12">
                    <h6>
                        <a target="_blank" href={ambassadorAllData.ambassadorDocumentDO.idImageUrl}>Id Card</a>
                    </h6>
                    {ambassadorAllData.ambassadorDocumentDO.idImageUrl.split("type=")[1] == "img" ?
                        <img className="" src={ambassadorAllData.ambassadorDocumentDO.idImageUrl} />
                        : ""}
                    {ambassadorAllData.ambassadorDocumentDO.idImageUrl.split("type=")[1] == "pdf" ?
                        <object data={ambassadorAllData.ambassadorDocumentDO.idImageUrl} type="application/pdf"></object>
                        : ""}
                </div>
                : "" : "" : ""}
            {ambassadorAllData != null ? ambassadorAllData.ambassadorDocumentDO != null ? ambassadorAllData.ambassadorDocumentDO.panImageUrl != null ?
                <div className="upload-docs col-sm-6 col-xs-12">
                    <h6>
                        <a target="_blank" href={ambassadorAllData.ambassadorDocumentDO.panImageUrl}>Pan Card</a>
                    </h6>
                    {ambassadorAllData.ambassadorDocumentDO.panImageUrl.split("type=")[1] == "img" ?
                        <img className="" src={ambassadorAllData.ambassadorDocumentDO.panImageUrl} />
                        : ""}
                    {ambassadorAllData.ambassadorDocumentDO.panImageUrl.split("type=")[1] == "pdf" ?
                        <object data={ambassadorAllData.ambassadorDocumentDO.panImageUrl} type="application/pdf"></object>
                        : ""}
                </div>
                : "" : "" : ""}
        </div>
    </div>
)

export default AMBDetail;