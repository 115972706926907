import React from 'react'
import CSVReader from 'react-csv-reader'
import ReactTable from 'react-table'
import PaidCsvImage from '../../../../images/paidcsv.png'
import SampleCV from '../../../../images/convertToPaid.csv'
const ConvertToPaid = ({
  handleForce,
  csvDataArray,
  saveCsvData,
  handleForceNew
}) => (
  <div className='container-fluid convertPaid'>
    <div className=''>
      <div className='row'>
        <h3 className='text-center blue-text'>
          Convert status to paid in bulk
        </h3>
      </div>
      <div className='row '>

        <div className='col-xs-12 col-sm-6 '>
          <h4 >
            <b>Note:-</b>
          </h4>
          <p>1. To close only current EMI Enter 0 in the paidType column.</p>
          <p>2. To Foreclose the total EMI's Enter 1 in the paidType column.</p>
          <p>3. For Foreclose, do not mention the emiDate.</p>
          <p>4. Use dd-mm-yyyy date format only.</p>
        </div>
        <div className='col-xs-12 col-sm-6 ' style={{ overflow: 'auto' }}>
          <h4 >
            <b>CSV must be in this format</b>
          </h4>
          <img src={PaidCsvImage} />
          <a href={SampleCV}>
            <button className='btn btn-primary' style={{ background: '#2B78FF', marginBottom: '20px', marginTop: '50px', padding: '15px' }}>
              <i className='fa fa-cloud-download'></i>&nbsp;&nbsp;Download CSV Sample File{' '}
            </button>
          </a>
        </div>
      </div>
      <div
        className='row '
      // onChange={e => handleForceNew(e)}
      // style={{ marginBottom: '10px', marginTop: '10px' }}
      >
        <input type='file' onChange={e => handleForceNew(e)} style={{ marginLeft: '10px', marginBottom: '10px', marginTop: '10px' }} />
        <div className='col-xs-12 display-flex  '>
          {/* <CSVReader cssClass='react-csv-input' onFileLoaded={handleForce} /> */}
          <button
            className='profile-save-btn margin-0'
            onClick={e => saveCsvData()}
          >
            Submit
          </button>
        </div>
      </div>
    </div>
    {/* <div className='row'>
      <div className='col-12'>
        {csvDataArray != null &&
        csvDataArray != '' &&
        csvDataArray.length > 0 ? (
          <div className='overflow-auto'>
          <table className='phocket-table'>
              <thead>
              
                      <tr>
                          <td>Application Id</td>
                          <td>Paid Date</td>
                          <td>Paid Amount</td>
                          <td>Paid Type</td>
                          <td> Emi Date</td>
  

                      </tr>
             
              </thead>
              <tbody>
                  {csvDataArray && csvDataArray.slice(tableMinPagination, tableMaxPagination).map((data, i) => {
                      return (
                          <tr key={i}>
                              <td>{data.applicationId}</td>
                              <td> {data.paidDate}</td>
                              <td> {data.paidAmount}</td>
                              <td>{data.paidType}</td>
                              <td>{data.emiDate}</td>
                             

                          </tr>
                      )
                  })}
              </tbody>
          </table>
      </div>

        ) : (
          ''
        )}
      </div>
    </div> */}
  </div>
)

export default ConvertToPaid
