import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import LoanApply from '../../../presentationals/Admin/ApplicationLeads/LoanApprovalLeads.component';
import {
    pushNotification, saveImportantLoans, getUserDetailByLoanId, getWhatsappMsgApi,
    getLoanApprovalLead, saveNachDataApi, getRoleAssignView, pushLeadToRetraApi
} from '../AdminActionCreator.component';
import UserDetail from '../CustomerPages/UserDetailMain.container';
import { APIS } from '../../../../utils/api-factory';
import { STRING, ROLE } from '../../../../utils/Constant'
import { _preFormatDate } from '../../../../utils/validation'
import MainDetailsPopup from '../CustomerPages/MainDetailPopup.container'
import localStorageUtil from '../../../../utils/localStorageUtil';
import Popup from '../../../presentationals/Popup/Popup.component';
import UsersForAssignUi from '../../../presentationals/Popup/AssignUserPopup.component'
import DeletePopup from '../../../presentationals/Popup/Delete.component'
import ConfirmationPopup from '../../../presentationals/Popup/ConfirmationPopup.component'
import SearchSelect from './SelectSearch.container'
import WhatsAppPopup from '../../../presentationals/Popup/WhatsApp.popup'
import FixedLoader from '../../../presentationals/Loader/Loader.component'
import moment from 'moment'
let columnHide = true;
let isDeleteUser = false
let nachCheckbox = false
let markImportant = false
let interval;
let paginationNumber = 10;
let commentColumn = false

class LoanApplyContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userState: true,
            viewState: false,
            allDetail: "",
            userDetail: "",
            followUpStatus: STRING.SELECT_ALL,
            forwordPageState: 1,
            loadarState: false,
            viewDetailPopupState: false,
            viewPopupDetail: '',
            logsUserId: '',
            todaysApplicationNo: '',
            actionButtonState: true,
            allAdminList: '',
            confirmationPopup: false,
            confirmationText: '',
            userIdForDelete: '',
            userIdForDeactivate: '',
            searchByDate: '',
            searchByDateShow: '',
            searchByEndDate: '',
            searchByEndDateTOShow: '',
            popupState: false,
            popupStatus: '',
            assignPopupState: false,
            loanIdForAssign: '',
            emailIdForAssign: '',
            tableMinPagination: 0,
            tableMaxPagination: paginationNumber,
            commonSearch: '',
            markCompleteBtn: true,
            whatsappPopupState: false,
            whatsapMsgToShow: '',
            whatsappPopupIndex: -1,
            mainLoaderState: false,
            btnForAction: '',
            loanIdForPush: '',
            tabType: this.props.leadsViewState,
            dataChange: '',
            nameOfUser: '',
            DeletePopupState: false,
            productType: '',
            assignToUserId: '',
            dateType: ''
        }
    }

    componentWillMount() {
        localStorage.setItem('showComment', 'no')

        getRoleAssignView(localStorageUtil.getFromLocalStorage("AdminemailId"), (callBack) => {
            this.setState({ allAdminList: callBack })
        })
        this.getLoanLead()
    }
    deactivateNachHandler(data) {
        this.setState({
            userIdForDeactivate: data.userId,
            DeletePopupState: true,
            confirmationText: 'Are you sure to Deactivate Nach',
            btnForAction: 'nach',
            nameOfUser: data
        })
    }

    getLoanLead() {
        let adminEmail = localStorageUtil.getFromLocalStorage("AdminemailId")
        let nbfcName = localStorageUtil.getFromLocalStorage("nbfcName")
        this.setState({
            loadarState: true
        })
        if (this.state.tabType == 'Advance') {
            this.props.getLoanApprovalLead(this.state.followUpStatus, this.state.forwordPageState, adminEmail, this.state.searchByDate, nbfcName, this.state.searchByEndDate, this.state.tabType, '', '', '', '', this.state.dateType,
                (callBack) => {

                    fetch(APIS.TODAYS_LOAN_COUNT + this.state.searchByDate)
                        .then(res => res.json())
                        .then(json => {
                            this.setState({ todaysApplicationNo: json })
                        })
                    this.setState({
                        loadarState: false,
                    })
                });
        } else if (this.state.tabType == 'phocketMini') {
            // console.log("phocketmini")

        }
    }

    componentDidMount() {
        if (this.state.viewState === false) {

            interval = setInterval(function () {
                let adminEmail = localStorageUtil.getFromLocalStorage("AdminemailId")
                let nbfcName = localStorageUtil.getFromLocalStorage("nbfcName")

                this.props.getLoanApprovalLead(this.state.followUpStatus, this.state.forwordPageState, adminEmail, this.state.searchByDate, nbfcName, this.state.searchByEndDate, this.state.tabType, '', '', '', '', this.state.dateType,
                    (callBack) => {
                        fetch(APIS.TODAYS_LOAN_COUNT + this.state.searchByDate)
                            .then(res => res.json())
                            .then(json => {
                                this.setState({ todaysApplicationNo: json, canCallApi: true })
                            })
                    });
            }.bind(this), 300000) // 5 min
        }
    }
    newFilterHandler(e, type) {
        if (type === 'dateType') {
            this.setState({ dateType: e.target.value }, () => this.dateFilterFunction())
        }
        if (type === 'startDate') {
            this.setState({ searchByDate: moment(e.target.value).format('DD-MM-YYYY') })
            this.setState({ searchByDateShow: e.target.value }, () => this.dateFilterFunction())
        }
        if (type === 'endDate') {
            this.setState({ searchByEndDate: moment(e.target.value).format('DD-MM-YYYY') })
            this.setState({ searchByEndDateTOShow: e.target.value }, () => this.dateFilterFunction())
        }
        if (e.target.value === '' || e.target.value === 'Invalid date') {
            this.setState({ dateType: '', searchByDateShow: '', searchByEndDateTOShow: '', searchByDate: '', searchByEndDate: '' })
            let adminEmail = localStorageUtil.getFromLocalStorage("AdminemailId")
            let nbfcName = localStorageUtil.getFromLocalStorage("nbfcName")
            this.props.getLoanApprovalLead(this.state.followUpStatus, this.state.forwordPageState, adminEmail, '', nbfcName, '', this.state.tabType, '', '', '', '', '',
                (callBack) => {
                    fetch(APIS.TODAYS_LOAN_COUNT + '')
                        .then(res => res.json())
                        .then(json => {
                            this.setState({ todaysApplicationNo: json })
                        })
                });
        }
    }
    dateFilterFunction() {
        if (this.state.dateType && this.state.searchByDate && this.state.searchByEndDate) {
            this.setState({
                loadarState: true
            })
            let adminEmail = localStorageUtil.getFromLocalStorage("AdminemailId")
            let nbfcName = localStorageUtil.getFromLocalStorage("nbfcName")
            this.props.getLoanApprovalLead(this.state.followUpStatus, this.state.forwordPageState, adminEmail, this.state.searchByDate, nbfcName, this.state.searchByEndDate, this.state.tabType, '', '', '', '', this.state.dateType,
                (callBack) => {
                    this.setState({ loadarState: false })
                    fetch(APIS.TODAYS_LOAN_COUNT + this.state.searchByDate + '&eDate=' + this.state.searchByEndDate)
                        .then(res => res.json())
                        .then(json => {
                            this.setState({ todaysApplicationNo: json })
                        })
                });
        }
    }
    render() {
        const { loanApprovalLead, admin, allStatus, newDataBackground } = this.props;
        if (admin != null) {
            if (admin.role != undefined && (admin.role == 'member' || admin.role == '' || admin.role == null)) {
                columnHide = false;
            }
            if (admin.role != null && admin.emailId == 'SuperUser') {
                isDeleteUser = true
            }
        }
        if (admin.rolelist != undefined) {
            if (admin.rolelist.indexOf(ROLE.NACH_CHANGE) >= 0) {
                nachCheckbox = true
            }
            if (admin.rolelist.indexOf(ROLE.MARK_AS_IMPORTANT) >= 0) {
                markImportant = true
            } if (admin.rolelist.indexOf(ROLE.COMMUNICATION) >= 0) {
                commentColumn = true
            }
        }

        let allAgentData = loanApprovalLead
        if (this.state.commonSearch) {
            allAgentData = allAgentData.filter(row => {
                if (row.userName != null && row.state != null && row.loginDevice != null) {
                    return row.userName.toLowerCase().includes(this.state.commonSearch.toLowerCase()) ||
                        row.state.toLowerCase().includes(this.state.commonSearch.toLowerCase()) ||
                        row.loginDevice.toLowerCase().includes(this.state.commonSearch.toLowerCase())
                }
            })
        }


        return (
            <>
                {this.state.userState == true ?
                    <div className="container-fluid">
                        <div className='row'>
                            <div className='col-sm-4'>
                                {admin != null && admin.nbfcName == null || admin.nbfcName == "" ?
                                    <SearchSelect
                                        admin={admin}
                                        getLoanLead={this.getLoanLead.bind(this)}
                                        getLoaderState={this.getLoaderState.bind(this)}
                                        commonSearchHandler={this.commonSearchHandler.bind(this)}

                                    />
                                    : null}
                            </div>
                            <div className='col-sm-2 col-xs-12'></div>

                            <div className="col-sm-4 col-xs-12">
                                {admin.rolelist != undefined && admin.rolelist.indexOf(ROLE.Lead_Count) >= 0 ?
                                    <div className="btn-group">
                                        {this.state.todaysApplicationNo != null && admin.emailId !== 'collection5@micromoney.in' ?
                                            <button className="btn-bg-new" onClick={e => this.showRepeateApplications()}><strong>REPEAT APPLICATIONS- {this.state.todaysApplicationNo.repeatUserCount}</strong></button>
                                            : ""}
                                        {this.state.todaysApplicationNo != null && admin.emailId !== 'collection5@micromoney.in' ?
                                            <button className="btn-bg-new" onClick={e => this.paginationHandler('all')}><strong>NEW APPLICATIONS- {this.state.todaysApplicationNo.newUserCount}</strong></button>
                                            : ""}
                                    </div>
                                    : null}
                                {/* </div> */}
                            </div>
                            {/* <div className='col-sm-2 col-xs-12'></div> */}

                            <div className='col-sm-2'>

                                {admin != undefined && admin.rolelist != undefined && admin.rolelist.indexOf(ROLE.LEAD_SELECTION) >= 0 ?
                                    <div>
                                        {/* <label className="font-12px">Select table type</label> */}
                                        <select className="form-control" onChange={e => this.props.leadChangeHandler(e)} value={this.state.leadsViewState}>
                                            <option value="Advance">Loan Approval Leads</option>

                                            <option value="Important Leads">Important Leads</option>

                                        </select>
                                    </div>
                                    : null}
                            </div>
                        </div>
                        <br />
                        <LoanApply
                            dateType={this.state.dateType}
                            newFilterHandler={this.newFilterHandler.bind(this)}
                            paginationNumber={this.state.paginationNumber}
                            commentColumn={commentColumn}
                            deactivateNachHandler={this.deactivateNachHandler.bind(this)}
                            userDetailHandler={this.userDetailHandler.bind(this)}
                            allUserDetail={allAgentData}
                            statusHandler={this.statusHandler.bind(this)}
                            dataFilter={this.dataFilter.bind(this)}
                            userViewHandler={this.userViewHandler.bind(this)}
                            admin={admin}
                            loadarState={this.state.loadarState}
                            allStatus={allStatus}
                            newDataBackground={newDataBackground}
                            todaysApplicationNo={this.state.todaysApplicationNo}
                            columnHide={columnHide}
                            isDeleteUser={isDeleteUser}
                            markAsImportant={this.markAsImportant.bind(this)}
                            showRepeateApplications={this.showRepeateApplications.bind(this)}
                            followUpStatus={this.state.followUpStatus}
                            deleteUserHandler={this.deleteUserHandler.bind(this)}
                            userCommentHandler={this.userCommentHandler.bind(this)}
                            searchByDate={this.state.searchByDate}
                            searchByEndDate={this.state.searchByEndDate}
                            searchByDateShow={this.state.searchByDateShow}
                            searchByEndDateTOShow={this.state.searchByEndDateTOShow}
                            userCommentChange={this.userCommentChange.bind(this)}
                            nachCheckbox={nachCheckbox}
                            markImportant={markImportant}
                            nashHandler={this.nashHandler.bind(this)}
                            userAssignPopup={this.userAssignPopup.bind(this)}
                            paginationHandler={this.paginationHandler.bind(this)}
                            phocketTablePagination={this.phocketTablePagination.bind(this)}
                            tableMinPagination={this.state.tableMinPagination}
                            tableMaxPagination={this.state.tableMaxPagination}
                            commonSearch={this.state.commonSearch}
                            commonSearchHandler={this.commonSearchHandler.bind(this)}
                            showWhatsappMsg={this.showWhatsappMsg.bind(this)}
                            pushToRetraHandler={this.pushToRetraHandler.bind(this)}
                        />
                    </div>
                    // </div>
                    : ""}
                {this.state.whatsappPopupState ?
                    <WhatsAppPopup
                        messages={this.state.whatsapMsgToShow}
                        closeWhatsappPopup={this.showWhatsappMsg.bind(this)} />
                    : null}
                <div className={this.state.viewState === true ? 'full-height' : ''}>
                    {this.state.viewState == true ?
                        <UserDetail
                            dataChange={this.state.dataChange}
                            allDetail={this.state.allDetail}
                            userDetails={this.state.userDetail}
                            admin={admin}
                            getData={this.getData.bind(this)}
                            backHandler={this.backHandler.bind(this)}
                            actionButtonState={this.state.actionButtonState}
                            markCompleteBtn={this.state.markCompleteBtn}
                        />
                        : ""}
                </div>
                {this.state.viewDetailPopupState == true ?
                    <MainDetailsPopup
                        getData={this.getData.bind(this)}
                        userDetails={this.state.userDetail}
                        getviewDetailPopupState={this.getviewDetailPopupState.bind(this)}
                        admin={admin}
                        allDetail={this.state.viewPopupDetail}
                    />
                    : ""}
                {this.state.confirmationPopup == true ?
                    <ConfirmationPopup
                        cancelLoanConfirm={this.cancelLoanConfirm.bind(this)}
                        confirmationText={this.state.confirmationText} />
                    : ""}
                {this.state.DeletePopupState == true ?
                    <DeletePopup
                        nameOfUser={this.state.nameOfUser}
                        cancelLoanConfirm={this.cancelLoanConfirm.bind(this)}
                        confirmationText={this.state.confirmationText} />
                    : ""}
                {this.state.popupState == true ?
                    <Popup
                        closePopup={this.closePopup.bind(this)}
                        popupStatus={this.state.popupStatus}
                    />
                    : ""}
                {this.state.assignPopupState ?
                    <UsersForAssignUi
                        closeTaskAssignPopup={this.closeTaskAssignPopup.bind(this)}
                        taskAssignHandler={this.taskAssignHandler.bind(this)}
                        emailIdForAssign={this.state.emailIdForAssign}
                        allAdminList={this.state.allAdminList} />
                    : null}

                {this.state.mainLoaderState ?
                    <FixedLoader />
                    : null}
            </>

        )
    }

    pushToRetraHandler(loanId) {
        this.setState({
            btnForAction: 'pushRetra',
            loanIdForPush: loanId,
            confirmationPopup: true,
            confirmationText: 'Are you sure to push this user to Retra',
        })
    }

    userCommentChange(e) {
        this.setState({ commentState: e.target.value })
    }

    userCommentHandler(data) {
        const { admin } = this.props
        pushNotification(data, this.state.commentState, '', admin.emailId, "comment", '', '', '', '', '',
            (callBack) => {
                if (document.getElementById("comment" + data.loanId) != null) {
                    document.getElementById("comment" + data.loanId).value = ""
                }
                this.setState({
                    popupState: true,
                    popupStatus: 'Comment Saved Successfully',
                    commentState: ''
                })
                this.removePopup();
            })
    }

    getLoaderState(state) {
        this.setState({
            loadarState: state,
            tableMinPagination: 0,
            tableMaxPagination: paginationNumber,
        })
    }

    showWhatsappMsg(data) {
        if (data != '') {
            getWhatsappMsgApi(data.mobileNumber, 'communication', 'unread',
                (callBack) => {
                    this.setState({
                        whatsappPopupState: !this.state.whatsappPopupState,
                        whatsapMsgToShow: callBack
                    })
                })
        } else {
            this.setState({
                whatsappPopupState: false,
                whatsapMsgToShow: ''
            })
        }

    }

    commonSearchHandler(e) {
        this.setState({
            commonSearch: e.target.value,
            tableMinPagination: 0,
            tableMaxPagination: paginationNumber,
        })
    }

    phocketTablePagination(data, type) {
        if (type == 'back') {
            if (this.state.tableMinPagination >= paginationNumber) {
                this.setState({
                    tableMinPagination: this.state.tableMinPagination - paginationNumber,
                    tableMaxPagination: this.state.tableMaxPagination - paginationNumber,
                })
            }
        } else if (type == 'forward') {
            if (this.state.tableMaxPagination < data.length) {
                this.setState({
                    tableMaxPagination: this.state.tableMaxPagination + paginationNumber,
                    tableMinPagination: this.state.tableMinPagination + paginationNumber
                })
            }
        }
    }
    paginationHandler(type) {
        if (type == 'refresh') {
            this.getLoanLead()
            fetch(APIS.TODAYS_LOAN_COUNT + this.state.searchByDate + '&eDate=' + this.state.searchByEndDate)
                .then(res => res.json())
                .then(json => {
                    this.setState({
                        todaysApplicationNo: json,
                        tableMinPagination: 0,
                        tableMaxPagination: paginationNumber,
                    })
                })

        } else if (type == 'all') {
            this.setState({
                tableMinPagination: 0,
                tableMaxPagination: paginationNumber,
                followUpStatus: STRING.SELECT_ALL
            }, () => this.getLoanLead())
        } else if (type == 'cache') {
            this.setState({
                loadarState: true
            })
            fetch(APIS.LOAD_CACHE + this.state.searchByDate)
                .then(res => res.text())
                .then(json => {
                    if (json == 'success') {
                        this.getLoanLead()
                        this.setState({
                            tableMinPagination: 0,
                            tableMaxPagination: paginationNumber,
                        })
                    }
                })

        } else if (type == 'left') {
            if (this.state.forwordPageState > 1) {
                this.setState({
                    forwordPageState: this.state.forwordPageState - 1,
                    tableMinPagination: 0,
                    tableMaxPagination: paginationNumber,
                }, () => this.getLoanLead())
            }
        } else if (type == 'right') {
            this.setState({
                forwordPageState: this.state.forwordPageState + 1,
                tableMinPagination: 0,
                tableMaxPagination: paginationNumber,
            }, () => this.getLoanLead())
        }
    }

    userAssignPopup(data) {
        this.setState({
            assignPopupState: true,
            loanIdForAssign: data.loanId !== null ? data.loanId : data.giftCardId,
            emailIdForAssign: data.assignUser,
            productType: data.typeOfProduct,
            assignToUserId: data.userId
        })
    }

    nashHandler(e, incomingData) {
        const { admin } = this.props;
        saveNachDataApi(e.target.checked, incomingData.userId, admin,
            (callBack) => {
                if (callBack == 'success') {
                    this.getLoanLead()
                }
            })
    }

    removePopup() {
        setTimeout(function () {
            this.setState({ popupState: false });
        }.bind(this), 5000)
    }

    closePopup() {
        this.setState({ popupState: false });
    }

    deleteUserHandler(data) {
        this.setState({
            userIdForDelete: data.userId,
            DeletePopupState: true,
            confirmationText: 'Are you sure to Delete this user',
            btnForAction: 'delete',
            nameOfUser: data
        })
    }

    cancelLoanConfirm(conType) {
        if (conType == 'yes') {
            if (this.state.btnForAction == 'delete') {
                fetch(APIS.DELETE_COMPLETE_USER + this.state.userIdForDelete)
                    .then(res => res.text())
                    .then(text => {
                        if (text == 'success') {
                            this.setState({ DeletePopupState: false })
                            this.getLoanLead()
                        }
                    })
            } else if (this.state.btnForAction == 'nach') {
                fetch(APIS.DEACTIVATE_NACH + this.state.userIdForDeactivate)
                    .then(res => res.text())
                    .then(text => {
                        if (text == 'success') {
                            this.setState({
                                confirmationPopup: false, popupState: true,
                                DeletePopupState: false,
                                popupStatus: 'Deactivated Successfully'
                            })
                            this.removePopup();
                        } else {
                            this.setState({
                                confirmationPopup: false, popupState: true,
                                DeletePopupState: false,
                                popupStatus: 'Already Deactivated'
                            })
                        }
                        this.removePopup();

                    })
            }
            else if (this.state.btnForAction == 'pushRetra') {
                pushLeadToRetraApi(this.state.loanIdForPush, callBack => {
                    if (callBack == 'success') {
                        this.setState({
                            confirmationPopup: false,
                            popupState: true,
                            popupStatus: 'PUsh successfully'
                        })
                        this.removePopup();
                    } else {
                        this.setState({
                            popupState: true,
                            popupStatus: 'Please try again'
                        })
                        this.removePopup();
                    }
                })
            }
        } else if (conType == 'no') {
            this.setState({ confirmationPopup: false, DeletePopupState: false })
        }
    }

    showRepeateApplications() {
        this.setState({
            followUpStatus: 'repeate'
        }, () => this.getLoanLead())
    }

    markAsImportant(allDetail, isImportant) {
        const { admin } = this.props
        if (allDetail.loanId != null && allDetail.loanId != '') {
            saveImportantLoans(allDetail, allDetail.loanId, isImportant, admin.emailId,
                (callBack) => {
                    this.getLoanLead()
                })
        } else {
            saveImportantLoans(allDetail, allDetail.giftCardId, isImportant, admin.emailId,
                (callBack) => {
                    this.getLoanLead()
                })
        }
    }

    taskAssignHandler(e) {
        this.setState({ emailIdForAssign: e.target.value })
        fetch(APIS.ASSIGN_LOAN + this.state.loanIdForAssign + "&empId=" + e.target.value + '&type=' + this.state.productType + '&userId=' + this.state.assignToUserId)
            .then(res => res.text())
            .then(text => {
                if (text == 'success') {
                    this.getLoanLead()
                }
            })
        this.setState({ assignPopupState: false })
    }

    closeTaskAssignPopup() {
        this.setState({ assignPopupState: false })
    }

    dataFilter(e) {
        this.setState({
            searchByDate: _preFormatDate(e),
            searchByDateShow: e,
            tableMinPagination: 0,
            tableMaxPagination: paginationNumber,
        }, () => this.getLoanLead())
    }

    statusHandler(e) {
        this.setState({
            followUpStatus: e.target.value,
            tableMinPagination: 0,
            tableMaxPagination: paginationNumber,
        }, () => this.getLoanLead())
    }

    componentDidUpdate() {
        const { loanApprovalLead } = this.props;
        if (loanApprovalLead.length > 0) {
            if (document.getElementById("forword") != null)
                document.getElementById("forword").disabled = false;
        } else {
            if (document.getElementById("forword") != null)
                document.getElementById("forword").disabled = true;
        }

    }
    getData(val) {
        this.setState({ dataChange: val })
    }
    backHandler(data) {
        const { admin } = this.props
        this.setState({
            userState: true,
            viewState: false
        })
        if (this.state.dataChange == 'yes') {
            this.getLoanLead()
            this.setState({ dataChange: '' })
        }
        // this.getLoanLead()
    }

    userDetailHandler(data) {
        this.setState({ mainLoaderState: true })
        if (data.loanId != '' && data.loanId != null) {
            getUserDetailByLoanId(data.loanId,
                (callBack) => {

                    this.setState({
                        userState: false,
                        viewState: true,
                        allDetail: callBack,
                        userDetail: data,
                        mainLoaderState: false
                    })
                })
        } else {
            //    console.log(APIS.USER_DETAIL_BY_ID + data.userId)
            fetch(APIS.USER_DETAIL_BY_ID + data.userId)
                .then(res => res.json())
                .then(res => {
                    this.setState({
                        userState: false,
                        viewState: true,
                        allDetail: res,
                        userDetail: data,
                        mainLoaderState: false
                    })
                })


        }
    }

    userViewHandler(data) {
        this.setState({ mainLoaderState: true })
        if (data.loanId != null && data.loanId != '') {
            getUserDetailByLoanId(data.loanId,
                (callBack) => {
                    this.setState({
                        viewDetailPopupState: true,
                        viewPopupDetail: callBack,
                        userDetail: data,
                        mainLoaderState: false
                    })
                })
        } else {

            fetch(APIS.USER_DETAIL_BY_ID + data.userId)
                .then(res => res.json())
                .then(res => {
                    this.setState({
                        viewDetailPopupState: true,
                        viewPopupDetail: res,
                        userDetail: data,
                        mainLoaderState: false
                    })
                })


        }
    }

    getviewDetailPopupState(state) {
        this.setState({
            viewDetailPopupState: state,
        })
    }

    componentWillUnmount() {
        // console.log('unmount', interval)
        clearInterval(interval);
    }

}

const mapStateToProps = ({ allUserDetail, loanApprovalLead }) => {
    return {
        allUserDetail: allUserDetail,
        loanApprovalLead: loanApprovalLead,
    };
};

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        getLoanApprovalLead
    }, dispatch);
};


export default connect(mapStateToProps, mapDispatchToProps)(LoanApplyContainer);