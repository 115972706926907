import React, { Component } from 'react';
import RecoveryUi from '../../../presentationals/Admin/DashboardAndReports/ShowData.component';
import Popup from '../../../presentationals/Popup/Popup.component';

let paginationNumber = 10

class DueContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            popupState: false,
            popupStatus: '',
            tableMinPagination: 0,
            tableMaxPagination: paginationNumber,
            forwordPageState: 1
        }
    }

    componentDidMount() {

    }

    render() {
        const { loanApprovalLead } = this.props
        return (
            <div className="full-height">
                {this.state.popupState == true ?
                    <Popup
                        closePopup={this.closePopup.bind(this)}
                        popupStatus={this.state.popupStatus}
                    />
                    : ""}
                <RecoveryUi
                    forwordpage={this.forwordpage.bind(this)}
                    backpage={this.backpage.bind(this)}
                    allUserDetail={loanApprovalLead}
                    detailBackHandler={this.detailBackHandler.bind(this)}
                    tableMinPagination={this.state.tableMinPagination}
                    tableMaxPagination={this.state.tableMaxPagination}
                    phocketTablePagination={this.phocketTablePagination.bind(this)}
                />
            </div>
        )
    }

    detailBackHandler() {
        this.props.backHandler()
    }

    phocketTablePagination(data, type) {
        if (type == 'back') {
            if (this.state.tableMinPagination >= paginationNumber) {
                this.setState({
                    tableMinPagination: this.state.tableMinPagination - paginationNumber,
                    tableMaxPagination: this.state.tableMaxPagination - paginationNumber,
                })
            }
        } else if (type == 'forward') {
            if (this.state.tableMaxPagination < data.length) {
                this.setState({
                    tableMaxPagination: this.state.tableMaxPagination + paginationNumber,
                    tableMinPagination: this.state.tableMinPagination + paginationNumber
                })
            }
        }
    }

    removePopup() {
        setTimeout(function () {
            this.setState({ popupState: false });
        }.bind(this), 5000)
    }

    closePopup() {
        this.setState({ popupState: false });
    }

    forwordpage() {
        const { dataCount } = this.props
        if (dataCount / 100 > this.state.forwordPageState) {
            this.props.getPageState(this.state.forwordPageState + 1)
            this.setState({
                forwordPageState: this.state.forwordPageState + 1,
            })
        }

    }

    backpage() {
        if (this.state.forwordPageState > 1) {
            this.props.getPageState(this.state.forwordPageState - 1)
            this.setState({
                forwordPageState: this.state.forwordPageState - 1,
            })
        }
    }

}

export default DueContainer;