import React from "react";
import { EMPLOYER_TYPE, FORM_STATUS, STRING, TYPE } from "../../../../../utils/Constant";
import { fetchOccupationAndEmployment } from "../../AdminActionCreator.component";
import { APIS } from "../../../../../utils/api-factory";
import { occupationFormValidation } from "../../../../../utils/validation";
import PopUp from "../../../../presentationals/Popup/Popup.component";


class EmploymentEdit extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            salaryCheck: '',
            userDetail: {
                HighQualificationState: '',
                fullPartTimeState: '',
                EmployeeTypeValue: '',
                OrganizationNameState: '',
                HomeSalaryState: '',
                WorkExperienceCurrentState: '',
                IsFirstJobState: '',
                WorkExperiencePreviousState: '',
                WorkExperienceTotalState: '',
                EmployeeTypeState: '',
                IndustrialTypeState: '',
                otherIndustrialTypeState: '',
                MonthlyIncomeState: '',
                designationState: '',
                officeAddressState: '',
                officeMailState: '',
            },
            popupState: false,
            popupStatus: '',
            occupationValidationMsg: {},
            suggestedCompanyName: '',
            autoSuggestionState: false,
        }
    }
    componentDidMount() {
        const { userdata } = this.props
        console.log(userdata)
        fetch(APIS.CHECK_REPEAT_USER + userdata.userId)
            .then(res => res.json())
            .then(res => {
                this.setState({ salaryCheck: res })
            })
        let userDetail = Object.assign({}, this.state.userDetail)
        if (
            userdata.employmentDetailsPO.highestQualification != null &&
            userdata.employmentDetailsPO.highestQualification != ''
          ) {
            userDetail.HighQualificationState = userdata.employmentDetailsPO.highestQualification
          }
          if (
            userdata.employmentDetailsPO.fullPartTime != null &&
            userdata.employmentDetailsPO.fullPartTime != ''
          ) {
            userDetail.fullPartTimeState = userdata.employmentDetailsPO.fullPartTime
          }
          if (
            userdata.employmentDetailsPO.employerType != null &&
            userdata.employmentDetailsPO.employerType != ''
          ) {
            userDetail.EmployeeTypeValue = userdata.employmentDetailsPO.employerType
          }
          if (
            userdata.employmentDetailsPO.organizationName != null &&
            userdata.employmentDetailsPO.organizationName != ''
          ) {
            userDetail.OrganizationNameState = userdata.employmentDetailsPO.organizationName
          }
          if (
            userdata.employmentDetailsPO.netTakeHomeSalary != null &&
            userdata.employmentDetailsPO.netTakeHomeSalary != ''
          ) {
            userDetail.HomeSalaryState = userdata.employmentDetailsPO.netTakeHomeSalary
          }
          if (
            userdata.employmentDetailsPO.officeAddress != null &&
            userdata.employmentDetailsPO.officeAddress != ''
          ) {
            userDetail.officeAddressState = userdata.employmentDetailsPO.officeAddress
          }
          if (
            userdata.employmentDetailsPO.optionalEmail != null &&
            userdata.employmentDetailsPO.optionalEmail != ''
          ) {
            userDetail.officeMailState = userdata.employmentDetailsPO.optionalEmail
          }
          if (
            userdata.employmentDetailsPO.designation != null &&
            userdata.employmentDetailsPO.designation != ''
          ) {
            userDetail.designationState = userdata.employmentDetailsPO.designation
          }
          if (
            userdata.employmentDetailsPO
              .workExperienceCurrentOrganization != null &&
            userdata.employmentDetailsPO
              .workExperienceCurrentOrganization != ''
          ) {
            userDetail.WorkExperienceCurrentState = userdata.employmentDetailsPO.workExperienceCurrentOrganization
          }
          if (
            userdata.employmentDetailsPO.isThisFirstJob != null &&
            userdata.employmentDetailsPO.isThisFirstJob != ''
          ) {
            userDetail.IsFirstJobState = userdata.employmentDetailsPO.isThisFirstJob
          }
          if (
            userdata.employmentDetailsPO
              .workExperiencePreviousOrganization != null &&
            userdata.employmentDetailsPO
              .workExperiencePreviousOrganization != ''
          ) {
            userDetail.WorkExperiencePreviousState = userdata.employmentDetailsPO.workExperiencePreviousOrganization
          }
          if (
            userdata.employmentDetailsPO.totalExperience != null &&
            userdata.employmentDetailsPO.totalExperience != ''
          ) {
            userDetail.WorkExperienceTotalState = userdata.employmentDetailsPO.totalExperience
          }
          if (
            userdata.employmentDetailsPO.selfEmployeeType != null &&
            userdata.employmentDetailsPO.selfEmployeeType != ''
          ) {
            userDetail.EmployeeTypeState = userdata.employmentDetailsPO.selfEmployeeType
          }
          if (
            userdata.employmentDetailsPO.businessIndustryType != null &&
            userdata.employmentDetailsPO.businessIndustryType != ''
          ) {
            userDetail.IndustrialTypeState = userdata.employmentDetailsPO.businessIndustryType
            userDetail.otherIndustrialTypeState = userdata.employmentDetailsPO.businessIndustryType
          }
          if (
            userdata.employmentDetailsPO.monthlyIncome != null &&
            userdata.employmentDetailsPO.monthlyIncome != ''
          ) {
            userDetail.MonthlyIncomeState = userdata.employmentDetailsPO.monthlyIncome
          }
          if (
            userdata.employmentDetailsPO.noOfEmployees != null &&
            userdata.employmentDetailsPO.noOfEmployees != ''
          ) {
            userDetail.noOfEmployeeState = userdata.employmentDetailsPO.noOfEmployees
          }
          if (
            userdata.userId != null &&
            userdata.userId != ''
          ) {
            userDetail.userId = userdata.userId
          }
          if (
            userdata.loanId != null &&
            userdata.loanId != ''
          ) {
            userDetail.loanId = userdata.loanId
          }
          userDetail.formStatus = FORM_STATUS.UPDATE
          this.setState({ userDetail })
        

    }
    removePopup() {
        setTimeout(
            function () {
                this.setState({ popupState: false })
            }.bind(this),
            5000
        )
    }
    closePopup() {
        this.setState({ popupState: false })
    }
    onSubmitHandler(e) {
        const {admin}=this.props
        let occupationValidation = occupationFormValidation(
            this.state.userDetail,
            this.state.salaryCheck
        )
        if (occupationValidation.status) {
            this.setState({
                occupationValidationMsg: occupationValidation
            })
            fetchOccupationAndEmployment(
                this.state.userDetail,
                this.state.userDetail,
                admin,
                callBack => {
                    this.setState({
                        popupState: true,
                        popupStatus: 'Occupational Details Changed Successfully'
                    }, ()=>this.props.userDetailHandler(this.state.userDetail))
                    this.removePopup()
                }
            )
        } else {
            this.setState({
                occupationValidationMsg: occupationValidation
            })
        }
    }
    onChangeHandler(e, type) {
        let userDetail = Object.assign({}, this.state.userDetail)

        if (type == TYPE.HIGHEST_QUALIFICATION) {
            userDetail.HighQualificationState = e.target.value
        } else if (type == TYPE.FULL_PART_TIME) {
            userDetail.fullPartTimeState = e.target.value
        } else if (type == TYPE.EMPLOYER_TYPE) {
            userDetail.EmployeeTypeValue = e.target.value
        } else if (type === TYPE.DESIGNATION) {
            userDetail.designationState = e.target.value
        } else if (type === TYPE.NO_OF_EMPLOYEE) {
            userDetail.noOfEmployeeState = e.target.value
        } else if (type == TYPE.ORGANIZATION_NAME) {
            let orgName = e.target.value
            userDetail.OrganizationNameState = orgName
            if (orgName.length > 3) {
                fetch(APIS.GET_COMPANY_NAME + orgName)
                    .then(res => res.json())
                    .then(json => {
                        this.setState({
                            suggestedCompanyName: json,
                            autoSuggestionState: true
                        })
                    })
            } else if (orgName.length < 1) {
                this.setState({ autoSuggestionState: false })
            }
        } else if (type == TYPE.OFFICE_ADDRESS) {
            userDetail.officeAddressState = e.target.value
        } else if (type == TYPE.OFFICE_EMAIL) {
            userDetail.officeMailState = e.target.value
        } else if (type == TYPE.SALARY) {
            userDetail.HomeSalaryState = e.target.value
        } else if (type == TYPE.CURRENT_WORK_EXPERIENCE) {
            userDetail.WorkExperienceCurrentState = e.target.value
        } else if (type == TYPE.IS_FIRST_JOB) {
            userDetail.IsFirstJobState = e.target.value
        } else if (type == TYPE.PREVIOUS_WORK) {
            userDetail.WorkExperiencePreviousState = e.target.value
        } else if (type == TYPE.TOTAL_EXPERIENCE) {
            userDetail.WorkExperienceTotalState = e.target.value
        } else if (type == TYPE.SELF_EMPLOYEE_TYPE) {
            userDetail.EmployeeTypeState = e.target.value
        } else if (type == TYPE.INDUSTRY_TYPE) {
            userDetail.IndustrialTypeState = e.target.value
        } else if (type == TYPE.OTHER_INDUSTRY_TYPE) {
            userDetail.otherIndustrialTypeState = e.target.value
            userDetail.IndustrialTypeState = e.target.value
        } else if (type == TYPE.MONTHLY_INCOME) {
            userDetail.MonthlyIncomeState = e.target.value
        }

        this.setState({ userDetail: userDetail })
    }
    setCompnyValue(companyName) {
        let userDetail = Object.assign({}, this.state.userDetail)
        userDetail.OrganizationNameState = companyName
        this.setState({ userDetail, autoSuggestionState: false })
      }
      autoSuggestionClose() {
        this.setState({ autoSuggestionState: false })
      }

    render() {
        return (
            <>
                {this.state.popupState == true ? (
                    <PopUp
                        closePopup={this.closePopup.bind(this)}
                        popupStatus={this.state.popupStatus}
                    />
                ) : (
                    ''
                )}
                <div className="details-new background-blur" style={{ background: 'rgb(5 5 5 / 20%)', color: '#000' }}>
                    <div className="edit-box ">
                    <div className="text-right" style={{ color: 'red' }}><i onClick={() => this.props.editHandler('')} style={{ fontSize: '20px', cursor: 'pointer' }} className="fa fa-times"></i></div>

                    <h4><b>Edit Employment Details</b></h4>

                        <div className="row">
                            <div className="col-sm-4 col-xs-12">
                                <label>Highest Oualification</label>
                                <select className="form-control" value={this.state.userDetail.HighQualificationState} onChange={(e) => this.onChangeHandler(e, TYPE.HIGHEST_QUALIFICATION)}>
                                    <option value=""></option>
                                    <option value={STRING.GRADUATE}>{STRING.GRADUATE}</option>
                                    <option value={STRING.POST_GRADUATE}>{STRING.POST_GRADUATE}</option>
                                    <option value={STRING.OTHERS}>{STRING.OTHERS}</option>
                                </select>
                                <div className="font-12px margin-left-25px height-25px validation-color">
                                    {this.state.occupationValidationMsg.field == "HighQualification" ? this.state.occupationValidationMsg.msg : ""}
                                </div>
                            </div>

                            <div className="col-sm-4 col-xs-12">
                                <label>Employer Type</label>
                                <select className="form-control" value={this.state.userDetail.EmployeeTypeValue} onChange={(e) => this.onChangeHandler(e, TYPE.EMPLOYER_TYPE)}>
                                    <option value=""></option>
                                    <option value={EMPLOYER_TYPE.SALARY}>{EMPLOYER_TYPE.SALARY}</option>
                                    <option value={EMPLOYER_TYPE.SELF_EMPLOYED}>{EMPLOYER_TYPE.SELF_EMPLOYED}</option>
                                </select>
                                <div className="font-12px margin-left-25px height-25px validation-color">
                                    {this.state.occupationValidationMsg.field == "EmployeeTypeValue" ? this.state.occupationValidationMsg.msg : ""}
                                </div>
                            </div>
                        </div>
                        {this.state.userDetail.EmployeeTypeValue == EMPLOYER_TYPE.SALARY ?
                            <div className="sal-emp">
                                <div className="row">
                                    <div className="col-sm-4 col-xs-12">
                                        <label>Organisation Name</label>
                                        <input type="text" className="form-control" value={this.state.userDetail.OrganizationNameState} onChange={(e) => this.onChangeHandler(e, TYPE.ORGANIZATION_NAME)} />
                                        {this.state.autoSuggestionState == true && this.state.suggestedCompanyName.length>0?
                                            <div className="auto-suggestion">
                                                <button className="auto-suggestion-close" onClick={e => this.autoSuggestionClose()}>&times;</button>
                                                {this.state.suggestedCompanyName != null && this.state.suggestedCompanyName != "" ?
                                                    this.state.suggestedCompanyName.map((name, i) => {
                                                        return (
                                                            <p key={i} onClick={e => this.setCompnyValue(name)}>{name}</p>
                                                        )
                                                    }) : ""}
                                            </div>
                                            : ""}
                                        <div className="font-12px margin-left-25px height-25px validation-color">
                                            {this.state.occupationValidationMsg.field == "OrganizationName" ? this.state.occupationValidationMsg.msg : ""}
                                        </div>
                                    </div>
                                    <div className="col-sm-4 col-xs-12">
                                        <label>Office Address</label>
                                        <input type="text" className="form-control" value={this.state.userDetail.officeAddressState} onChange={(e) => this.onChangeHandler(e, TYPE.OFFICE_ADDRESS)} />
                                        <div className="font-12px margin-left-25px height-25px validation-color">

                                        </div>
                                    </div>
                                    <div className="col-sm-4 col-xs-12">
                                        <label>Office Email Id</label>
                                        <input type="email" className="form-control" value={this.state.userDetail.officeMailState} onChange={(e) => this.onChangeHandler(e, TYPE.OFFICE_EMAIL)} />
                                        <div className="font-12px margin-left-25px height-25px validation-color">

                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-4 col-xs-12">
                                        <label>Designation</label>
                                        <input type="text" className="form-control" value={this.state.userDetail.designationState} onChange={(e) => this.onChangeHandler(e, TYPE.DESIGNATION)} />
                                        <div className="font-12px margin-left-25px height-25px validation-color">
                                            {this.state.occupationValidationMsg.field == "designation" ? this.state.occupationValidationMsg.msg : ""}
                                        </div>
                                    </div>

                                    <div className="col-sm-4 col-xs-12">
                                        <label>Total Experience</label>
                                        <select className="form-control" value={this.state.userDetail.WorkExperienceTotalState} onChange={(e) => this.onChangeHandler(e, TYPE.TOTAL_EXPERIENCE)}>
                                            <option value=""></option>
                                            <option value={STRING.EXP_1}>{STRING.EXP_1}</option>
                                            <option value={STRING.EXP_2}>{STRING.EXP_2}</option>
                                            <option value={STRING.EXP_3}>{STRING.EXP_3}</option>
                                            <option value={STRING.EXP_4}>{STRING.EXP_4}</option>
                                        </select>
                                        <div className="font-12px margin-left-25px height-25px validation-color">
                                            {this.state.occupationValidationMsg.field == "WorkExperienceTotal" ? this.state.occupationValidationMsg.msg : ""}
                                        </div>
                                    </div>
                                    <div className="col-sm-4 col-xs-12">
                                        <label>Net Take Home Salary</label>
                                        <input type="number" className="form-control" value={this.state.userDetail.HomeSalaryState} onChange={(e) => this.onChangeHandler(e, TYPE.SALARY)} />
                                        <div className="font-12px margin-left-25px height-25px validation-color">
                                            {this.state.occupationValidationMsg.field == "HomeSalary" ? this.state.occupationValidationMsg.msg : ""}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            : ""}
                        {this.state.userDetail.EmployeeTypeValue == EMPLOYER_TYPE.SELF_EMPLOYED ?
                            <div className="self-emp">
                                <div className="row">
                                    <div className="col-sm-4 col-xs-12">
                                        <label>Self-Employee Type</label>
                                        <select className="form-control" value={this.state.userDetail.EmployeeTypeState} onChange={(e) => this.onChangeHandler(e, TYPE.SELF_EMPLOYEE_TYPE)}>
                                            <option value=""></option>
                                            <option value={STRING.BUSINESS}>{STRING.BUSINESS}</option>
                                            <option value={STRING.PROFESSIONAL}>{STRING.PROFESSIONAL}</option>
                                        </select>
                                        <div className="font-12px margin-left-25px height-25px validation-color">
                                            {this.state.occupationValidationMsg.field == "SelfEmployeeType" ? this.state.occupationValidationMsg.msg : ""}
                                        </div>
                                    </div>
                                    <div className="col-sm-4 col-xs-12">
                                        <label>Industry Type</label>
                                        <select className="form-control" value={this.state.userDetail.IndustrialTypeState} onChange={(e) => this.onChangeHandler(e, TYPE.INDUSTRY_TYPE)}>
                                            <option value=""></option>
                                            <option value={STRING.MANUFACTURING}>{STRING.MANUFACTURING}</option>
                                            <option value={STRING.TRADING}>{STRING.TRADING}</option>
                                            <option value={STRING.SERVICES}>{STRING.SERVICES}</option>
                                            <option value={STRING.OTHERS}>{STRING.OTHERS}</option>
                                        </select>
                                        <div className="font-12px margin-left-25px height-25px validation-color">
                                            {this.state.occupationValidationMsg.field == "IndustrialType" ? this.state.occupationValidationMsg.msg : ""}
                                        </div>
                                    </div>
                                    <div className="col-sm-4 col-xs-12">
                                        <label>Monthly Income</label>
                                        <input type="number" className="form-control" value={this.state.userDetail.MonthlyIncomeState} onChange={(e) => this.onChangeHandler(e, TYPE.MONTHLY_INCOME)} />
                                        <div className="font-12px margin-left-25px height-25px validation-color">
                                            {this.state.occupationValidationMsg.field == "MonthlyIncome" ? this.state.occupationValidationMsg.msg : ""}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            : ""}
                        <div className="row text-center">
                           
                        </div>
                        <div className="row ">
                            <div className="col-xs-12">
                                <button className="btn btn-primary" id="" onClick={(e) => this.onSubmitHandler(e)}>
                                Save Employment Details
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </>

        )
    }
}
export default EmploymentEdit
