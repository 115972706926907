import React from 'react'

const FundsPage = ({ bankData, state ,onChangeHandler,submit}) => (
    <div className='container-fluid '>
        <div className='row' >
            <div className='col-sm-3 col-xs-12'></div>
            {state.listState ?
                <div className='col-sm-6 col-xs-12 card-profile' style={{minHeight:'auto'}}>
                    <h3 className='text-center'><b>Fund Details</b></h3>
                    <table className="table">
                        <thead className="thead-dark">
                            <tr>
                                <th>Amount</th>
                               <th>Date</th>
                            </tr>
                        </thead>
                        <tbody>


                            {state.fundDetails && state.fundDetails.map((data, i) => {
                                return (
                                    <tr>
                                        <td>{data.amount}</td>
                                      <td>{data.date}</td>
                                    </tr>
                                )
                            })}


                        </tbody>
                    </table>

                    {/* <div className='col-sm-6 col-xs-12' style={{ marginTop: '30px' }}>
                        <div>
                            <b style={{ color: 'gray' }}>Account Number</b>
                            <span style={{ float: 'right', color: 'blue' }}> {bankData.accountNo}</span></div>
                    </div>
                    <div className='col-sm-6 col-xs-12' style={{ marginTop: '30px' }}>
                        <div >
                            <b style={{ color: 'gray' }}>IFSC Code</b>
                            <span style={{ float: 'right', color: 'blue' }}>{bankData.ifscCode}</span></div>
                    </div>
                    <div className='col-sm-6 col-xs-12' style={{ marginTop: '30px' }}>
                        <div >
                            <b style={{ color: 'gray' }}>Name On Bank</b>
                            <span style={{ float: 'right', color: 'blue' }}>{bankData.nameOnBank}</span></div>
                    </div> */}

                </div>
                : ""}
            {state.addState ?
                <div className='col-sm-6 col-xs-12 card-profile'  style={{minHeight:'auto'}}>
                    <label>Amount</label>
                    <input className='form-control' onChange={(e) => onChangeHandler(e,'amount')}  placeholder='Enter Amount'/>
                    <label style={{marginTop:'20px'}}>Source Of Funds</label>
                    {/* <input className='form-control' onChange={(e) => onChangeHandler(e)}  placeholder='Enter Amount'/> */}
                <select className='form-control' onChange={(e) => onChangeHandler(e,'source')}>
                <option value=''>Select Source</option>

                    <option value='CASH'>CASH</option>
                    <option value='NET_BANKING'>NET_BANKING</option>
                    <option value='CASHBACK'>CASHBACK</option>
                    <option value='DEBIT_CARD'>DEBIT_CARD</option>
                    <option value='CREDIT_CARD'>CREDIT_CARD</option>
                    <option value='BANK_ACCOUNT'>BANK_ACCOUNT</option>
                    <option value='UPI'>UPI</option>
                    <option value='POOL_ACCOUNT'>POOL_ACCOUNT</option>
                    <option value='OTHER'>OTHER</option>

                </select>
                    <div className='text-center' style={{marginTop:'30px'}}>
                       <button className='profile-save-btn' onClick={()=>submit()}>ADD Funds</button>
           
                    </div>
                </div>

                : ""}
        </div>
    </div>
)


export default FundsPage