import React from 'react'
import Nodata from '../../../../images/nodata.png'
let totalAmount = 0
let totalCount = 0
const Quality = ({ allData }) => (
  <div className='container-fluid'>
    <h3 className='heading-analysis text-center'>Assets Quality </h3>
    {/* <div className="row">
                <h3 className="text-center blue-text">Similar Profiles</h3>
            </div> */}
    {allData != null && allData != '' ? (
      <div className='row form-group'>
        <div className='col-xs-12 col-sm-2'></div>
        <div className='col-xs-12 col-sm-8'>
          <div className='overflow-x-auto'>
            <table className='text-center blueTable'>
              <thead>
                <tr>
                  <th>Overdue Days</th>
                  <th>Total Loan Count</th>
                  <th>Percentage Count</th>
                  <th>Total Approve Amount</th>
                  <th>Percentage Amount</th>

                </tr>
              </thead>
              <tbody>
                {Object.keys(allData).map((data, i) => {
                  var amount = ''
                  if (allData[data] != null && allData[data].totalApproveAmount != null) {
                    var amount = Number(parseFloat(allData[data].totalApproveAmount).toFixed(2)).toLocaleString('en-IN');

                    // for (const [key, value] of Object.entries(allData)) {
                    //       totalAmount=totalAmount+value.totalApproveAmount

                    // }
                    //  console.log(totalAmount)
                    let abcd = []
                    for (const [key, value] of Object.entries(allData)) {
                      if(value!==null&& value.totalAmount !==null){
                      abcd.push(value.totalApproveAmount)
                      }
                    }
                    totalAmount=(abcd.reduce((accumulator, currentValue) => accumulator + currentValue));

                    let abcde = []
                    for (const [key, value] of Object.entries(allData)) {
                      if(value !==null && value.totalLoanCount !==null){
                      abcde.push(value.totalLoanCount)
                      }
                    }
                    totalCount=(abcde.reduce((accumulator, currentValue) => accumulator + currentValue));
                  }
                  return (
                    <tr key={i}>
                      <td>{data}</td>
                      <td>{allData[data] != null && allData[data].totalLoanCount != null ? allData[data].totalLoanCount : ""}</td>
                      <td>{allData[data] != null && allData[data].percentageCount != null ? allData[data].percentageCount : ""}</td>
                      <td>{amount}</td>
                      <td>{allData[data] != null && allData[data].percentageAmount != null ? allData[data].percentageAmount : ""}</td>

                    </tr>

                  )
                })}
              </tbody>
            </table>
            <br/>
            <div className='text-center' style={{fontSize:'18px'}}>
            <div className=' col-xs-12'>
                Total Loan Count:-<b>{totalCount}</b>
              </div>
              <div className=' col-xs-12'>
                Total Approved Amount:-<b>&nbsp;<i class="fa fa-rupee" ></i>&nbsp;
{Number(parseFloat(totalAmount).toFixed(2)).toLocaleString('en-IN')}</b>
              </div>
             
            </div>
            <div className='margin-bottom-6perc text-center'>
              {/* <div className=" col-xs-12  tbl-count" ><button type="button" className="pagination-btn blue-text " onClick={e => phocketTablePagination(similarProfileData, 'back')}>Previous</button>{(tableMinPagination + 1) + ' - ' + (tableMaxPagination) + ' of ' + similarProfileData.length}<button type="button" className="pagination-btn tbl-count blue-text " onClick={e => phocketTablePagination(similarProfileData, 'forward')}>Next</button></div> */}
            </div>
          </div>
        </div>
        <div className='col-xs-12 col-sm-2'></div>
      </div>
    ) : (
        ''
      )}
  </div>
)

export default Quality
