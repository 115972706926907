import React from 'react'

const Notification = ({ notificationData, deleteNotificationOne, deleteNotificationAll,
    notificationType }) => (
    <div>
        {notificationData != null && notificationData != "" ?
            <div className="customer-notification">
                <div className="row border-bottom-blue">
                    <div className="col-xs-8">
                        <div className="customer-notification-heading">Notifications</div>
                    </div>
                    <div className="col-xs-4 text-right">
                        <span className="fa fa-trash-o" onClick={e => deleteNotificationAll()} />
                    </div>
                </div>
                <ul>
                    {notificationData.map((notification, i) => {
                        return (
                            <li key={i}>
                                {notification.notificationType != "Doc Type" && notificationType == 'notDocs' ?
                                    <div style={{ background: '#F1F7FF', padding: '4px',borderRadius:'12px' }}>
                                        <label className="block relative_position">


                                            <button className="close" onClick={(e) => deleteNotificationOne(notification.id)}>&times;</button>{notification.notificationType}
                                        </label>
                                        <p className="relative_position">

                                            {notification.name} ({notification.applicationId})

                                        </p>
                                        <div style={{ textAlign: 'right', color: 'rgba(0, 0, 0, 0.25)', fontSize: '10px' }}>{notification.date}</div>
                                    </div>
                                    : notification.notificationType == "Doc Type" && notificationType == 'docs' ?
                                        <div style={{ background: '#F1F7FF', padding: '4px',borderRadius:'12px' }}>
                                            <label className="block relative_position">
                                                <button className="close" onClick={(e) => deleteNotificationOne(notification.id)}>&times;</button>
                                                {notification.notificationType}
                                            </label>
                                            <p className="relative_position">
                                                {notification.name} ({notification.applicationId})
                                            </p>
                                            <div style={{ textAlign: 'right', color: 'rgba(0, 0, 0, 0.25)', fontSize: '10px' }}>{notification.date}</div>
                                        </div>
                                        : ""}
                            </li>
                        )
                    })
                    }
                </ul>
            </div>
            : ""}
    </div>
)

export default Notification;