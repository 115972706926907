import React, { Component } from 'react'
import AllStats from './OverallStats.container'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import DatePicker from 'react-datepicker';
import { getAllStatusByBetweenDate, getAllStatusBulletEmi } from '../AdminActionCreator.component';
import { _preFormatDate } from '../../../../utils/validation'


class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            statusDiv: false,
            applyDiv: false,
            pageViewState: 'All',
            startDate: '',
            startDateToShow: '',
            endDate: '',
            endDateToShow: '',
            statusData: [],
            loaderState: false,
            csvData: '',
            csvJsonData: '',
        }
    }
    getcsvdata() {
        // fetch('https://test.phocket.in/generateCollectionReport?startDate=20-02-2021&endDate=28-02-2021')
        //     .then(res =>
        //         res.text())
        //     .then(res => {
        //         console.log((res));
        //         // callBack(res)
        //         this.setState({ res: csvData })
        //     })
        // console.log(this.state.csvData, 'csvdata')

        //     readRemoteFile('https://test.phocket.in/generateCollectionReport?startDate=20-02-2021&endDate=28-02-2021', {
        //   complete: (results) => {
        //     console.log('Results:', results.data);
        //     this.setState({results:csvData})
        //   },
        // });

    }
  
    componentDidMount() {
        // this.getcsvdata()
        this.getAllStatus()
    }
    searchSelect(Type) {
        if (Type == 'status') {
            this.setState({
                statusDiv: true,
                applyDiv: false
            })
        }
    }
    searchSelect(e, Type) {
        // console.log(e.target.value)
        if (e.target.value == "apply") {
            this.setState({
                applyDiv: true,
                statusDiv: false
            })
        } if (e.target.value == "status") {
            this.setState({
                applyDiv: false,
                statusDiv: true
            })
        } if (e.target.value == "noSelect") {
            this.setState({
                applyDiv: false,
                statusDiv: false
            })
        }
    }


    render() {
        const { admin } = this.props;
        return (
            <div className="full-height container-fluid white-bg-transparent">

                <div className="row">
                    <div className="cal-xs-12 col-sm-4">
                        <div className="dashboard-tabs">
                            <button className={this.state.pageViewState == 'All' ? 'active' : ''} onClick={e => this.buttonActionHandler("All")}>All</button>
                            <button className={this.state.pageViewState == 'Bullet' ? 'active' : ''} onClick={e => this.buttonActionHandler("Bullet")}>Bullet</button>
                            <button className={this.state.pageViewState == 'EMI' ? 'active' : ''} onClick={e => this.buttonActionHandler("EMI")}>EMI</button>
                        </div>
                    </div>
                    <div className="col-sm-3 col-xs-12">
                        {/* <label className="font-12px">Select Start Date</label>
                        <DatePicker
                            selected={this.state.startDateToShow}
                            value={this.state.startDate}
                            onChange={(e) => this.filterDateChange("start", e)}
                            className="date-picker form-control"
                            dateFormat="DD-MM-YYYY"
                            placeholderText="Select Date"
                            
                        /> */}

                    </div>
                    <div className="col-sm-4 col-xs-12">
                        <select placeholder="select filter " className="form-control search-fil" onChange={(e) => this.searchSelect(e)}>
                            <option value="noSelect">select filter</option>
                            {/* <option value="apply" >Apply Date</option> */}
                            <option value="status"> Status Change</option>
                        </select>
                    </div>
                    <div className="col-sm-1 col-xs-12">

                        <button className="dashbpard-reload pull-right" onClick={e => this.reloadDataHandler()}>Reload</button>

                    </div>
                </div>

                <br />
                <div className="row">
                    {this.state.statusDiv == true ?
                        <div>
                            <div className="col-sm-3 col-xs-12">
                                <label className="font-12px">Select Status Change Start Date</label>
                                <DatePicker
                                    selected={this.state.startDateToShow}
                                    value={this.state.startDate}
                                    onChange={(e) => this.filterDateChange("start", e)}
                                    className="date-picker form-control"
                                    dateFormat="DD-MM-YYYY"
                                    placeholderText="Select Date"

                                />

                            </div>
                            <div className="col-sm-3 col-xs-12">
                                <label className="font-12px">Select Status Change End Date</label>
                                <DatePicker
                                    selected={this.state.endDateToShow}
                                    value={this.state.endDate}
                                    onChange={(e) => this.filterDateChange("end", e)}
                                    className="date-picker form-control"
                                    dateFormat="DD-MM-YYYY"
                                    placeholderText="Select End Date"
                                />
                            </div>
                        </div>
                        : ""}
                    {this.state.applyDiv == true ?
                        <div>
                            <div className="col-sm-3 col-xs-12">
                                <label className="font-12px">Select Apply Date Start Date</label>
                                <DatePicker
                                    selected={this.state.startDateToShow}
                                    value={this.state.startDate}
                                    onChange={(e) => this.filterDateChange("start", e)}
                                    className="date-picker form-control"
                                    dateFormat="DD-MM-YYYY"
                                    placeholderText="Select Date"

                                />

                            </div>
                            <div className="col-sm-3 col-xs-12">
                                <label className="font-12px">Select Apply Date End Date</label>
                                <DatePicker
                                    selected={this.state.endDateToShow}
                                    value={this.state.endDate}
                                    onChange={(e) => this.filterDateChange("end", e)}
                                    className="date-picker form-control"
                                    dateFormat="DD-MM-YYYY"
                                    placeholderText="Select End Date"
                                />
                            </div>
                        </div>
                        : ""}
                </div>
                <div className="row">
                    {/* {this.state.pageViewState == 'All' ? */}
                    <AllStats
                        admin={admin}
                        allStatus={this.state.statusData}
                        startDate={this.state.startDate}
                        endDate={this.state.endDate}
                        loaderState={this.state.loaderState}
                    />
                    {/* : ""} */}
                </div>
            </div>
        )
    }

    filterDateChange(fType, e) {
        if (fType == "start") {
            this.setState({
                startDate: _preFormatDate(e),
                startDateToShow: e
            }, () => this.getAllStatus())
        } else if (fType == 'end') {
            this.setState({
                endDate: _preFormatDate(e),
                endDateToShow: e
            }, () => this.getAllStatus())
        }
    }

    reloadDataHandler() {
        this.setState({
            startDate: '',
            endDate: '',
        }, () => this.getAllStatus())
    }

    buttonActionHandler(type) {
        this.setState({
            pageViewState: type
        }, () => this.getAllStatus())
    }

    getAllStatus() {
        this.setState({
            statusData: [],
            loaderState: true
        })
        if (this.state.pageViewState == 'All') {
            this.props.getAllStatusByBetweenDate(this.state.startDate, this.state.endDate,
                callBack => {
                    this.setState({
                        statusData: callBack,
                        loaderState: false
                    })
                })
        } else {
            getAllStatusBulletEmi(this.state.startDate, this.state.endDate,
                callBack => {
                    if (this.state.pageViewState == 'Bullet') {
                        this.setState({
                            statusData: callBack.Bullet,
                            loaderState: false
                        })
                    } else if (this.state.pageViewState == 'EMI') {
                        this.setState({
                            statusData: callBack.Emi,
                            loaderState: false
                        })
                    }
                })
        }
    }
}

const mapStateToProps = ({ allStatus, loanApprovalLead }) => {
    return {
        allStatus: allStatus,
        loanApprovalLead: loanApprovalLead
    };
};

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        getAllStatusByBetweenDate
    }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
