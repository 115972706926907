import React from 'react'
import ProductConfigUi from '../../../presentationals/Admin/NbfcManagementNew/Charges.component';
import PopUp from '../../../presentationals/Popup/Popup.component';
import { saveChargesConfigNbfc } from '../AdminActionCreator.component';
import { nbfcChargesConfigurationValidation } from '../../../../utils/validation';
import Loader from '../../../presentationals/Loader/Loader.component'
import moment from 'moment';

class ProductConfig extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            productConfig: {
                id: '',
                nbfcId: '',
                processingFee: '',
                minLoanAmount: '',
                maxLoanAmount: '',
                effectingFrom: '',
                processingType: '',
                oldEffected: false,
                gstIncluded: false,
                noOfEmi:''

            },
            validationMsg: '',
            popupState: false,
            popupStatus: '',
            loaderState: false

        }
    }
    componentDidMount() {
        if (this.props && this.props.nbfcId !== null && this.props.nbfcId !== undefined) {
            let changeData = Object.assign({}, this.state.productConfig)
            changeData.nbfcId = this.props.nbfcId
            this.setState({ productConfig: changeData })
        }
        if (this.props && this.props.nbfcData !== undefined && this.props.nbfcData !== '') {
            let changeData = Object.assign({}, this.state.productConfig)
            changeData.processingFee = this.props.nbfcData.processingFee
            changeData.minLoanAmount = this.props.nbfcData.minLoanAmount
            changeData.maxLoanAmount = this.props.nbfcData.maxLoanAmount
            changeData.effectingFrom = moment(new Date(this.props.nbfcData.effectingFrom)).format('yyyy-MM-DD')
            changeData.processingType = this.props.nbfcData.processingType
            changeData.oldEffected = this.props.nbfcData.oldEffected
            changeData.gstIncluded = this.props.nbfcData.gstIncluded
            changeData.nbfcId = this.props.nbfcData.nbfcId
            changeData.id = this.props.nbfcData.id
            changeData.noOfEmi=this.props.nbfcData.noOfEmi
            this.setState({ productConfig: changeData })
        }
    }
    removePopup() {
        setTimeout(function () {
            this.setState({ popupState: false });
        }.bind(this), 5000)
    }

    closePopup() {
        this.setState({ popupState: false });
    }
    changeHandler(e, type) {
        let changeData = Object.assign({}, this.state.productConfig)

        if (type === 'processingFee') {
          
            changeData.processingFee = e.target.value
        }
        if (type === 'minLoanAmount') {
            changeData.minLoanAmount = e.target.value
        }
        if (type === 'maxLoanAmount') {
            changeData.maxLoanAmount = e.target.value
        }
        if (type === 'effectingFrom') {
            changeData.effectingFrom = e.target.value
        }
        if (type === 'processingType') {
            changeData.processingType = e.target.value
        }
        if (type === 'oldEffected') {
            changeData.oldEffected = e.target.value
        }
        if (type === 'gstIncluded') {
            changeData.gstIncluded = e.target.value
        }
        if (type === 'noOfEmi') {
            changeData.noOfEmi = e.target.value
        }

        this.setState({ productConfig: changeData })
    }
    submit() {
        let validation = nbfcChargesConfigurationValidation(this.state.productConfig)
        if (validation.status) {
            this.setState({ loaderState: true })
            saveChargesConfigNbfc(this.state.productConfig, callback => {
                if (callback === 'success') {
                    this.setState({ loaderState: false, popupStatus: 'Successfully Saved !', popupState: true }, () => this.removePopup())
                } else {
                    this.setState({ loaderState: false, popupStatus: 'Please try again later !', popupState: true }, () => this.removePopup())

                }
            })
        } else {
            this.setState({ validationMsg: validation })
        }
    }
    render() {
        return (
            <div>
                <ProductConfigUi
                    validationMsg={this.state.validationMsg}
                    submit={this.submit.bind(this)}
                    productConfig={this.state.productConfig}
                    changeHandler={this.changeHandler.bind(this)}
                />
                {this.state.popupState == true ?
                    <PopUp
                        closePopup={this.closePopup.bind(this)}
                        popupStatus={this.state.popupStatus}
                    />
                    : ""}
                {this.state.loaderState ?
                    <Loader />
                    : ""}
            </div>
        )
    }
}
export default ProductConfig