import React from 'react'
import { TYPE } from '../../../utils/Constant'
import DatePicker from 'react-datepicker'

const ApproveDataPopup = ({
  userDetails,
  closeDisbursePopup,
  disburseDataChange,
  disburseData,
  disburseDataHandler,
  errorMsg,
  disabled,
  expiryDate
}) => (
  <div className=''>
    <div className='background-blur'></div>
    <div className='popup-main small-popup container-fluid dbl-border'>
      <div className='row from-group'>
        <div className='col-xs-12'>
          <button className='close' onClick={e => closeDisbursePopup()}>
            &times;
          </button>
          <h4 className='text-center'>
            Kindly confirm the details
            <br />
            &nbsp;
          </h4>
        </div>
      </div>
      <div className='row form-group'>
        <div className='col-sm-6 col-xs-12'>
          <label className='font-12px'>Select Disburse Date</label>
          <DatePicker
            value={disburseData.disburseDate}
            selected={disburseData.disburseDateToShow}
            onChange={e => disburseDataChange(e, TYPE.LOAN_APPROVE_DATE)}
            className='form-control'
            placeholderText='select Date'
            dateFormat='DD/MM/YYYY'
          />
        </div>
        {userDetails.loanFrom === "vreedhi"?
        <div className='col-sm-6 col-xs-12'>
        <label className='font-12px'>Insurance Amount</label>
            <input
              className='form-control'
              placeholder='Insurance Amount'
              onChange={e => disburseDataChange(e, 'insuranceAmount')}
            />
        </div>
        :""}
        {/* {userDetails.giftCardType == 'Amazon' ? (
          <div className='col-sm-6 col-xs-12'>
            <label className='font-12px'>Voucher Code</label>
            <input
              className='form-control'
              placeholder='Voucher Code'
              onChange={e => disburseDataChange(e, 'code')}
            />
          </div>
        ) : (
          ''
        )} */}
      </div>
      {/* {userDetails.giftCardType == 'Amazon' ? (
        <div className='row'>
          <div className='col-sm-6 col-xs-12'>
            <label className='font-12px'>Voucher Pin</label>
            <input
              className='form-control'
              placeholder='Voucher Pin'
              onChange={e => disburseDataChange(e, 'pin')}
            />
          </div>
          <div className='col-sm-6 col-xs-12'>
            <label className='font-12px'>Expiry Date</label>
            <input readOnly value={expiryDate} placeholder='expiry Date' className='form-control'/>
           
          </div>
        </div>
      ) : (
        ''
      )} */}
      <br/>
         {/* {userDetails.giftCardType == 'Amazon' ? (
        <div className='row'>
          <div className='col-sm-6 col-xs-12'>
            <label className='font-12px'>SKU</label>
          <select className='form-control' onChange={e => disburseDataChange(e, 'sku')}>
            <option>Select Sku</option>
            <option value='PROCESSINGSTS'>PROCESSINGSTS</option>
            <option value='testsuccess001'>testsuccess001</option>
            <option value='APITESTTIMFAIL'>APITESTTIMFAIL</option>
            <option value='DISABLEDSTS'>DISABLEDSTS</option>
            <option value='EGCGBAMZ001'>EGCGBAMZ001</option>

          </select>
          </div>
         
        </div>
      ) : (
        ''
      )} */}
      <p className='red-text text-center margin-0 height-20px font-12px'>
        {errorMsg}
      </p>
      <div className='row from-group text-center'>
        <div className='col-xs-12'>
          <button
            className='profile-save-btn'
            disabled={disabled}
            onClick={e => disburseDataHandler()}
          >
            SAVE
          </button>
        </div>
      </div>
    </div>
  </div>
)

export default ApproveDataPopup
