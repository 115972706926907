import React, { useEffect, useState } from "react";
import Piechart1 from "./Piechart1";

import "../../compo.scss";

export default function Stats({ data }) {
  const [leadRatio, setLeadRatio] = useState({
    leadToFreshApprovalRatio: 0.0,
    leadToFreshDisbursalRatio: 0.0,
    leadToRepeatApprovalRatio: 0.0,
  });
  useEffect(() => {
    setLeadRatio({
      leadToFreshApprovalRatio: data.leadToFreshApprovalRatio,
      leadToFreshDisbursalRatio: data.leadToFreshDisbursalRatio,
      leadToRepeatApprovalRatio: data.leadToRepeatApprovalRatio,
    });
  }, []);

  var a = [1, 2, 3];
  return (
    <>
      <div class="contain text-start marketing-main-css">
        <div class="row" style={{ marginLeft: "0px", marginRight: "0px" }}>
          <div
            class="col-12"
            style={{
              marginTop: "15px",
              paddingLeft: "0px",
              paddingRight: "0px",
            }}
          >
            <div
              className="column1disbursement"
              style={{
                minHeight: "260px",
                paddingLeft: "8px",
                paddingTop: "10px",
                paddingRight: "8px",
                paddingBottom: "10",
              }}
            >
              <h5 className="" style={{ color: '#0e5768' }}><b>Disbursement Stats</b></h5>
              <div className="column2disbursement">
                <div className="row disbursementCard">
                  {leadRatio ? Object.keys(leadRatio).map((item, index) => {
                    return (
                      Object.keys(data).length &&
                     
                        (
                          <div
                            className="col-sm-2 col-xs-12"
                            style={{
                              // width: "11rem",
                              // minWidth: "11rem",
                              // maxWidth: "11rem",
                              // marginRight: "0.5rem",
                              // maxHeight: "13rem",
                              // minHeight: "13rem",
                              // paddingLeft: "0px",
                              // paddingRight: "0px",
                            }}
                          >
                            <div className="cards-shadow text-center" style={{ marginBottom: '10px' }}>
                              <h6
                                style={{ fontWeight: '600', paddingTop: '15px' }}
                              >
                                {index === 0
                                  ? "Lead to Fresh Approval Ratio"
                                  : index === 1
                                    ? "Lead to Fresh Disbursal Ratio"
                                    : "Lead to Repeat Approval Ratio "}
                              </h6>
                              <div className="remove-rechart-line" style={{ marginTop: '30px' }}>
                               {leadRatio[item]?
                                <Piechart1
                                  leadValue={leadRatio[item]}
                                  chartNum={index * 2}

                                />
                                :  <h1 className=" text-center"  style={{fontWeight:'600',marginTop:'88px'}}>{0}</h1>
                              }
                              </div>
                              {/* <h6 className="percentage">
                                {leadRatio[item]?.toFixed(2)}%
                              </h6> */}
                              {/* <div className="green-box">+2.5%</div> */}
                            </div>
                          </div>
                        )
                    );
                  }) : ""}
                  <div className="col-sm-2 col-xs-12">
                    <div className="cards-shadow">

                      <h6
                        style={{ fontWeight: '600', paddingTop: '15px' ,textAlign:'center'}}
                      >
                        Customer Acquisition Cost
                      </h6>
                      <h1 className=" text-center"  style={{fontWeight:'600',marginTop:'88px'}}>{0}</h1>

                    </div>
                  </div>
                  <div className="col-sm-2 col-xs-12">
                    <div className="cards-shadow">

                      <h6
                        style={{ fontWeight: '600', paddingTop: '15px' ,textAlign:'center'}}
                      >
                        Total Disbursed Leads
                      </h6>

                      <h1 className=" text-center"  style={{fontWeight:'600',marginTop:'88px'}}>{data.totalDisbursedLeads}</h1>

                    </div>
                  </div>
                  <div className="col-sm-2 col-xs-12">
                    <div className="cards-shadow">

                      <h6
                        style={{ fontWeight: '600', paddingTop: '15px' ,textAlign:'center'}}
                      >
                        Total Disbursed Amount
                      </h6>
                   
                      <h1 className=" text-center"  style={{fontWeight:'600',marginTop:'88px'}}>{data.disbursmentAmt}</h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
