import React from 'react';

const FieldBoy = ({ referData, referChange, referHandler }) => (
    <div className="container-fluid">
        <div className="row max-width-500px">
            <h3 className="text-center blue-text">Refer Company</h3>
            <div className="col-xs-12 form-group">
                <label className="font-12px">Company Name</label>
                <input type="text" value={referData.companyName} className="form-control" onChange={e => referChange(e, 'companyName')} />
            </div>
            <div className="col-xs-12 form-group">
                <label className="font-12px">Referral Code</label>
                <input type="text" value={referData.referralCode} className="form-control" onChange={e => referChange(e, 'referralCode')} />
            </div>
            <div className="col-xs-12 form-group">
                <button className="btn profile-save-btn center-block" onClick={e => referHandler()}>SAVE</button>
            </div>
        </div>
    </div>
)

export default FieldBoy;