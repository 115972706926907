import React from "react";

class IndentityEdit extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }
    render() {
        return (
            <div></div>
        )
    }
}
export default IndentityEdit