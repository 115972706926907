import React, { Component } from 'react'
import CompleteLeadsUi from './CompleteLeads.container'
import IncompleteLeadsUi from './IncompleteLeads.container'
import PartialLeadsUi from './PartialLeads.container'
import RepeateLeadsUi from './RepeateLeads.container'
import CollectionLeads from './CollectionLeads.container'
import DashboardUi from './LeadsDashboard.container'
import LegalLeads from './legalLeads.container'
import { APIS } from '../../../../utils/api-factory'
let assignuser = []
class LeadManagement extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pageToShow: '',
            userStatusData: [],
            adminMail: '',
            detailpage: false
        }
    }
    getAssignedByStatus() {
        const { admin } = this.props
        if (admin !== undefined) {
            if (admin.department === 'Recovery Team') {
                this.setState({
                    // userStatusData: status,
                    pageToShow: 'overdue',
                })
            } else {
                fetch(APIS.GET_ASSIGN_BY_STATUS)
                    .then(res => res.json())
                    .then(res => {
                        res.map((status, i) => {
                            assignuser.push(status.emailId + status.status)
                            this.setState({
                                userStatusData: status,
                                pageToShow: assignuser.includes(admin.emailId + 'complete') ? 'complete' : assignuser.includes(admin.emailId + 'incomplete') ? 'incomplete' : assignuser.includes(admin.emailId + 'repeat') ? 'repeate' : assignuser.includes(admin.emailId + 'partialcomplete') ? 'partial' : assignuser.includes(admin.emailId + 'legal') ? 'legal' : assignuser.includes(admin.emailId + 'overdue') ? 'overdue' : admin.role === 'superuser' ? 'complete' : "",
                            })

                        })

                    })
            }
        }
    }



    componentDidMount() {
        const { admin } = this.props
        this.getAssignedByStatus()
    }
    setDetailsPage(data) {
        this.setState({ detailpage: data })
        console.log(data, 'datadata')
    }
    render() {
        const { admin, allStatus, newDataBackground } = this.props
        return (
            <div className="full-height">
                <div className="edit-profile-menu col-xs-12">
                    {/* {admin.role !== 'superuser' ?
                        <div className="btn-group-own">
                            {assignuser.includes(admin.emailId + 'complete') || admin.department === 'Customer Service' ?
                                <button className={this.state.pageToShow == 'complete' ? 'active' : ''} onClick={e => this.buttonActionHandler("complete")}>Complete</button>
                                : ""}
                            {assignuser.includes(admin.emailId + 'partialcomplete') ?

                                <button className={this.state.pageToShow == 'partial' ? 'active' : ''} onClick={e => this.buttonActionHandler("partial")}>Partial</button>
                                : ""}
                            {assignuser.includes(admin.emailId + 'incomplete') ?

                                <button className={this.state.pageToShow == 'incomplete' ? 'active' : ''} onClick={e => this.buttonActionHandler("incomplete")}>Incomplete</button>
                                : ""}
                            {assignuser.includes(admin.emailId + 'repeat') ?
                                <button className={this.state.pageToShow == 'repeate' ? 'active' : ''} onClick={e => this.buttonActionHandler("repeate")}>Repeat</button>
                                : ""}
                            {assignuser.includes(admin.emailId + 'legal') ?
                                <button className={this.state.pageToShow == 'legal' ? 'active' : ''} onClick={e => this.buttonActionHandler("legal")}>Legal</button>
                                : ""}

                            <button className={this.state.pageToShow == 'dashboard' ? 'active' : ''} onClick={e => this.buttonActionHandler("dashboard")}>Dashboard</button>
                        </div>
                        : ""
                    } */}
                    {admin.role !== 'superuser' && this.state.detailpage === false ?
                        <div className="btn-grp-own-lead-manage" role="group" aria-label="onboard-btn" style={{ padding: '10px' }}>
                            {assignuser.includes(admin.emailId + 'complete') || admin.department === 'Customer Service' ?
                                <button className={this.state.pageToShow == 'complete' ? 'btn-active' : 'btn'} onClick={e => this.buttonActionHandler("complete")}>Complete</button>
                                : ""}
                            {assignuser.includes(admin.emailId + 'partialcomplete') ?

                                <button className={this.state.pageToShow == 'partial' ? 'btn-active' : 'btn'} onClick={e => this.buttonActionHandler("partial")}>Partial</button>
                                : ""}
                            {assignuser.includes(admin.emailId + 'incomplete') ?

                                <button className={this.state.pageToShow == 'incomplete' ? 'btn-active' : 'btn'} onClick={e => this.buttonActionHandler("incomplete")}>Incomplete</button>
                                : ""}
                            {assignuser.includes(admin.emailId + 'legal') ?
                                <button className={this.state.pageToShow == 'legal' ? 'btn-active' : 'btn'} onClick={e => this.buttonActionHandler("legal")}>Operation</button>
                                : ""}
                            {assignuser.includes(admin.emailId + 'repeat') ?
                                <button className={this.state.pageToShow == 'repeate' ? 'btn-active' : 'btn'} onClick={e => this.buttonActionHandler("repeate")}>Repeat</button>
                                : ""}
                            {/* {admin.emailId === 'priyanka.kumari@phocket.in' ?
                                <button className={this.state.pageToShow == 'repeate' ? 'btn-active' : 'btn'} onClick={e => this.buttonActionHandler("repeate")}>Repeat</button>
                                : ""}
                            {admin.emailId === 'sarita.negi@phocket.in' ?
                                <button className={this.state.pageToShow == 'legal' ? 'btn-active' : 'btn'} onClick={e => this.buttonActionHandler("legal")}>Operation</button>
                                : ""} */}
                            {/* {assignuser.includes(admin.emailId + 'repeat') ?

                                                                <button className={this.state.pageToShow == 'overdue' ? 'btn-active' : 'btn'} onClick={e => this.buttonActionHandler("overdue")}>Collection</button>
:""} */}
                            <button className={this.state.pageToShow == 'dashboard' ? 'btn-active' : 'btn'} onClick={e => this.buttonActionHandler("dashboard")}>Dashboard</button>
                        </div>
                        : ""
                    }



                    {/* {
                        admin.department === 'Recovery Team' && admin.role !== 'superuser' ?
                            <div className="btn-group">
                                <button className={this.state.pageToShow == 'overdue' ? 'active' : ''} onClick={e => this.buttonActionHandler("overdue")}>Collection Leads</button>

                            </div>
                            : ""
                    } */}
                    {/* {
                        admin.role === 'superuser' ?
                            <div className="btn-group">
                                <button className={this.state.pageToShow == 'complete' ? 'active' : ''} onClick={e => this.buttonActionHandler("complete")}>Complete</button>
                                <button className={this.state.pageToShow == 'partial' ? 'active' : ''} onClick={e => this.buttonActionHandler("partial")}>Partial</button>
                                <button className={this.state.pageToShow == 'incomplete' ? 'active' : ''} onClick={e => this.buttonActionHandler("incomplete")}>Incomplete</button>
                                <button className={this.state.pageToShow == 'repeate' ? 'active' : ''} onClick={e => this.buttonActionHandler("repeate")}>Repeat</button>
                                <button className={this.state.pageToShow == 'legal' ? 'active' : ''} onClick={e => this.buttonActionHandler("legal")}>Legal</button>
                                <button className={this.state.pageToShow == 'dashboard' ? 'active' : ''} onClick={e => this.buttonActionHandler("dashboard")}>Dashboard</button>
                            </div>
                            : ""
                    } */}
                    {
                        admin.role === 'superuser' && this.state.detailpage === false ?
                            <div className="btn-grp-own-lead-manage" role="group" aria-label="onboard-btn" style={{ padding: '10px' }}>
                                <button className={this.state.pageToShow == 'complete' ? 'btn-active' : 'btn'} onClick={e => this.buttonActionHandler("complete")}>Complete<span style={{ fontSize: '10px' }}>(Credit)</span></button>
                                {/* <button className={this.state.pageToShow == 'partial' ? 'btn-active' : 'btn'} onClick={e => this.buttonActionHandler("partial")}>Partial</button> */}
                                <button className={this.state.pageToShow == 'incomplete' ? 'btn-active' : 'btn'} onClick={e => this.buttonActionHandler("incomplete")}>Incomplete<span style={{ fontSize: '10px' }}> (Customer Service)</span></button>
                                <button className={this.state.pageToShow == 'legal' ? 'btn-active' : 'btn'} onClick={e => this.buttonActionHandler("legal")}>Operation</button>
                                {/* <button className={this.state.pageToShow == 'overdue' ? 'btn-active' : 'btn'} onClick={e => this.buttonActionHandler("overdue")}>Collection</button> */}

                                <button className={this.state.pageToShow == 'repeate' ? 'btn-active' : 'btn'} onClick={e => this.buttonActionHandler("repeate")}>Repeat</button>
                                <button className={this.state.pageToShow == 'dashboard' ? 'btn-active' : 'btn'} onClick={e => this.buttonActionHandler("dashboard")}>Dashboard</button>

                            </div>
                            : ""}
                </div >
                {
                    this.state.pageToShow == 'complete' ?
                        <CompleteLeadsUi
                            setDetailsPage={this.setDetailsPage.bind(this)}
                            admin={admin}
                            allStatus={allStatus}
                            newDataBackground={newDataBackground} />
                        : this.state.pageToShow == 'incomplete' ?
                            <IncompleteLeadsUi
                                setDetailsPage={this.setDetailsPage.bind(this)}

                                admin={admin}
                                allStatus={allStatus}
                                newDataBackground={newDataBackground} />
                            : this.state.pageToShow == 'partial' ?
                                <PartialLeadsUi
                                    setDetailsPage={this.setDetailsPage.bind(this)}

                                    admin={admin}
                                    allStatus={allStatus}
                                    newDataBackground={newDataBackground} />
                                : this.state.pageToShow == 'repeate' ?
                                    <RepeateLeadsUi
                                        setDetailsPage={this.setDetailsPage.bind(this)}

                                        admin={admin}
                                        allStatus={allStatus}
                                        newDataBackground={newDataBackground} />
                                    : this.state.pageToShow == 'overdue' ?
                                        <CollectionLeads
                                            setDetailsPage={this.setDetailsPage.bind(this)}

                                            admin={admin}
                                            allStatus={allStatus}
                                            newDataBackground={newDataBackground} />
                                        : this.state.pageToShow == 'legal' ?
                                            <LegalLeads
                                                setDetailsPage={this.setDetailsPage.bind(this)}

                                                pageToShow={this.state.pageToShow}
                                                admin={admin}
                                                allStatus={allStatus}
                                                newDataBackground={newDataBackground} />
                                            : this.state.pageToShow == 'dashboard' ?
                                                <DashboardUi
                                                    setDetailsPage={this.setDetailsPage.bind(this)}

                                                    admin={admin}
                                                    allStatus={allStatus} />
                                                : null
                }
            </div >
        )
    }

    buttonActionHandler(type) {

        this.setState({
            pageToShow: type
        })
    }
}

export default LeadManagement;