import React from 'react';
import CSVReader from "react-csv-reader";
import DisburseToPaid from '../../../../images/croppesDisburseToPaid.png'

const AccountInfo = ({ handleForce, csvDataArray, saveCsvData,
    tableMinPagination, tableMaxPagination, phocketTablePagination }) => (
        <div className="container-fluid">
            <div className="max-width-500px">
                <div className="row">
                    <h3 className="text-center blue-text">Upload Status CSV</h3>
                </div>
                <div className="row">
                    <div className="col-xs-12 display-flex">
                        <CSVReader
                            cssClass="react-csv-input"
                            onFileLoaded={handleForce}
                        />
                        <button className="profile-save-btn margin-0" onClick={(e) => saveCsvData()}><span className="fa fa-save" /></button>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-xs-12">
                    {csvDataArray.length > 0 ?
                        <div>
                            <h3 className="text-center">Check Data from here.</h3>
                            <div className="overflow-auto">
                                <table className="phocket-table">
                                    <thead>
                                        <tr>
                                            {Object.keys(csvDataArray[0]).map((header, i) => {
                                                return (
                                                    <th key={i}>{header}</th>
                                                )
                                            })}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {csvDataArray.slice(tableMinPagination, tableMaxPagination).map((data, i) => {
                                            // {csvDataArray.map((data, i) => {
                                            return (
                                                <tr key={i}>
                                                    {Object.keys(data).map((value, j) => {
                                                        return (
                                                            <td key={j}>{data[value]}</td>
                                                        )
                                                    })}
                                                </tr>

                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-sm-5 col-xs-12 no-padding"><button type="button" className="btn btn-default fa fa-chevron-left btn-lg btn-block grey  " onClick={e => phocketTablePagination(csvDataArray, 'back')}></button></div>
                                    <div className="col-sm-2 col-xs-12  phoc" >{(tableMinPagination + 1) + ' - ' + (tableMaxPagination) + ' of ' + csvDataArray.length}</div>
                                    <div className="col-sm-5 col-xs-12 no-padding" ><button type="button" className="btn btn-default fa fa-chevron-right btn-lg btn-block grey" onClick={e => phocketTablePagination(csvDataArray, 'forward')}></button></div>
                                </div>
                            </div>
                        </div>
                        :
                        <div className="">
                            <h3 className="text-center">Your CSV file must be in this format.</h3>
                            <img className="center-block area-100" src={DisburseToPaid} />
                        </div>}
                </div>
            </div>
        </div>
    )

export default AccountInfo;