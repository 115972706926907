import React from 'react'
import ReactTable from "react-table";
import { filterCaseInsensitive } from '../../../../utils/commonUtility'

const List = ({ usedCouponData, closeCouponPopup,
    getFilterValue, FilteredData, getPageValue, pageIndexToShow }) => (
    <div className="">
        <div className="background-blur" onClick={e => closeCouponPopup()}></div>
        <div className="popup-main small-popup small-popup container-fluid dbl-border overflow-auto">
            <div className="row">

                {usedCouponData != null && usedCouponData != '' && usedCouponData.length > 0 ?
                    <div className='overflow-auto'>
                        <table className='phocket-table'>
                            <thead>

                                <tr>
                                    <td>User Name</td>
                                    <td>Email ID</td>
                                    <td>Coupon Cod</td>



                                </tr>

                            </thead>
                            <tbody>
                                {usedCouponData && usedCouponData.map((data, i) => {
                                    return (
                                        <tr key={i}>
                                            <td>{data.name}</td>
                                            <td> {data.email}</td>

                                            <td>{data.code}</td>


                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                    : ""}
            </div>
        </div>
    </div>
)

export default List;