import React, { useState, useEffect } from 'react'
import { APIS } from '../../../../utils/api-factory'
import LoaderUi from '../../../presentationals/Loader/Loader.component'
const Report = ({ userId, crifTab, crifData, crifTabHandler, allDetail }) => {
    const [userCrifData, setuserCrifData] = useState('')
    const [regenerate, setregenerate] = useState(0)
    const [loaderState, setloaderState] = useState(false)
    const [analysisData, setanalysisData] = useState('')

    const getCrifReport = () => {
        setloaderState(true)
        fetch(
            APIS.GET_CRIF_DATA + userId + '&regenerateParam=' + regenerate + '&loanId=' + allDetail.loanId)
            .then(res => res.text())
            .then(res => {
                if (res.responseCode == 0) {
                } else {
                    setuserCrifData(res)
                    setloaderState(false)
                }
            })
    }
    useEffect(() => {
        // if (crifTab === 'crifreport') {
        getCrifReport()
        // } else {
        getAnalysisData()
        // }
    }, [])
    const getAnalysisData = () => {
        setloaderState(true)
        fetch(APIS.GET_CRIF_ANALYSIS_DATA_NEW + userId + '&loanId=' + allDetail.loanId)
            .then(res => res.json())
            .then(res => {
                setanalysisData(res)
                setloaderState(false)

            })
    }
    const regenerateCrif = () => {
        setloaderState(true)
        fetch(
            APIS.GET_CRIF_DATA +
            userId +
            '&regenerateParam=' +
            '1' + '&loanId=' + allDetail.loanId
        )
            .then(res => res.text())
            .then(res => {
                if (res.responseCode == 0) {
                } else {
                    setuserCrifData(res)
                    setloaderState(false)
                }
            })
    }
    return (
        <div className=''>
            {loaderState ?
                <LoaderUi />
                : ""}
            <div className='text-center'>
                <ul className="credit-head-tab" style={{ display: 'flex', marginBottom: '0px' }}>

                    <li>
                        <div className={crifTab === 'obligation' ? 'credit-sub-tab-active' : 'credit-sub-tab'} onClick={() => crifTabHandler('obligation')}>
                            <div className='' style={{ fontSize: '14px', fontWeight: '600' }}>
                                Obligation Amount
                                <br />
                                {crifData && crifData.obligationData && crifData.obligationData.emiSum ? <div className=" text-blue" style={{ color: crifTab === 'obligation' ? '#fff' : '#2B78FF' }}>INR {crifData ? Math.round(crifData.obligationData.emiSum) : 0}</div> : <div className=" text-right text-blue" style={{ color: '#2B78FF' }}>N/A</div>}

                            </div>
                        </div>
                    </li>
                    <li>
                        <div className={crifTab === 'greenflag' ? 'credit-sub-tab-active' : 'credit-sub-tab'} onClick={() => crifTabHandler('greenflag')}>
                            <div className='' style={{ fontSize: '14px', fontWeight: '600' }}>
                                Green Flag<br />
                                {analysisData != null &&
                                    analysisData != undefined &&
                                    analysisData != '' &&
                                    analysisData.approve != null &&
                                    analysisData.approve != '' &&
                                    analysisData.approve != undefined && analysisData.finalDecision != 'Rejected' ? <div style={{ marginBottom: '3.5px', fontSize: '12px', color: '#4CC78C' }}>Found &nbsp;<i className='fa fa-check-circle'></i></div> :
                                    <div style={{ marginBottom: '3.5px', fontSize: '12px', color: '#FF5C5D' }}>Not Found &nbsp;<i className='fa fa-times-circle'></i></div>}
                            </div>
                        </div>
                    </li>

                    <li>
                        <div className={crifTab === 'exceptional' ? 'credit-sub-tab-active' : 'credit-sub-tab'} onClick={() => crifTabHandler('exceptional')} >
                            <div className='' style={{ fontSize: '14px', fontWeight: '600' }}>
                                Exceptional Case
                                <br />
                                {analysisData != null &&
                                    analysisData != undefined &&
                                    analysisData != '' &&
                                    analysisData.exceptionCase != null &&
                                    analysisData.exceptionCase != '' &&
                                    analysisData.exceptionCase != undefined ?
                                    <div style={{ marginBottom: '3.5px', fontSize: '12px', color: '#4CC78C' }}>Found &nbsp;<i className='fa fa-check-circle'></i></div> :
                                    <div style={{ marginBottom: '3.5px', fontSize: '12px', color: '#FF5C5D' }}>Not Found &nbsp;<i className='fa fa-times-circle'></i></div>

                                }
                            </div>
                        </div>
                    </li>
                    <li>
                        <div className={crifTab === 'clearrejection' ? 'credit-sub-tab-active' : 'credit-sub-tab'} onClick={() => crifTabHandler('clearrejection')}>
                            <div className='' style={{ fontSize: '14px', fontWeight: '600' }}>
                                Clear Rejection
                                <br />
                                {analysisData != null &&
                                    analysisData != undefined &&
                                    analysisData != '' &&
                                    analysisData.rejected != null &&
                                    analysisData.rejected != '' &&
                                    analysisData.rejected != undefined && analysisData.finalDecision == 'Rejected' ? <div style={{ marginBottom: '3.5px', fontSize: '12px', color: '#4CC78C' }}>Found &nbsp;<i className='fa fa-check-circle'></i></div> :
                                    <div style={{ marginBottom: '3.5px', fontSize: '12px', color: '#FF5C5D' }}>Not Found &nbsp;<i className='fa fa-times-circle'></i></div>}
                            </div>
                        </div>
                    </li>
                    <li>
                        <div className={crifTab === 'crifreport' ? 'credit-sub-tab-active' : 'credit-sub-tab'} onClick={() => crifTabHandler('crifreport')}>
                            <div className='' style={{
                                fontSize: '14px', fontWeight: '600', padding: '10.5px', color: crifTab !== 'crifreport' ? '#2B78FF' : '#fff'
                            }}>
                                {crifTab === 'crifreport' ? <i class="fa fa-eye-slash" aria-hidden="true"></i> : <i class="fa fa-eye" aria-hidden="true"></i>} &nbsp;&nbsp; View CRIF Report

                            </div>
                        </div>
                    </li>
                </ul>
            </div>
            <div className='container-fluid'>
                {crifTab === 'crifreport' ?
                    <div className='row overflow-x-auto' style={{ marginTop: '100px', marginBottom: '20px' }}>
                        <div className='col-xs-12 ' style={{ marginTop: '20px', marginBottom: '20px' }}>
                            {/* <button className='btn btn-primary' onClick={e => regenerateCrif()}>
                                Regenerate Crif
                            </button> */}
                        </div>
                        <div className='col-sm-12-col-xs-12'>
                            {userCrifData != null ? (
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: userCrifData
                                    }}
                                ></div>
                            ) : (
                                ''
                            )}
                        </div>
                    </div>
                    : ""}
                {crifTab === 'clearrejection' ?
                    <div className='overflow-x-auto' >

                        {analysisData != null &&
                            analysisData != undefined &&
                            analysisData != '' &&
                            analysisData.rejected != null &&
                            analysisData.rejected != '' &&
                            analysisData.rejected != undefined && analysisData.finalDecision == 'Rejected' ? (
                            <div className='red-table-new'>
                                <table>
                                    <thead>
                                        <tr className='thead-class'>
                                            <th>Account Type</th>
                                            <th>OverDue Amount</th>
                                            <th>Disburse Amount</th>
                                            <th>Disburse Date</th>
                                            <th>Account Status</th>
                                            <th>Current Balance</th>
                                            <th>Status</th>
                                            <th>Matched Condition</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {analysisData != null &&
                                            analysisData != undefined &&
                                            analysisData != ''
                                            ? analysisData.rejected.map((data, i) => {
                                                return (
                                                    <tr key={i}>
                                                        <td>{data.accountType}</td>
                                                        <td>{data.overDueAmount}</td>
                                                        <td>{data.disburseAmount}</td>
                                                        <td>{data.disburseDate}</td>
                                                        <td>{data.accountStatus}</td>
                                                        <td>{data.currentBalance}</td>
                                                        <td>{data.status}</td>
                                                        {/* <td>{data.matchedCondition}</td> */}
                                                        <td>{data.matchedCondition == 'Case 1' ? 'Suit-Filed/Willful/DBT/LSS/SMA & no sanctioning in last 3 Years' : data.matchedCondition == 'Case 2' ? 'Write-off/Settled, DBT, LSS & SMA with amount > INR 5000 in last 2 years' : data.matchedCondition == 'Case 3' ? 'Max DPD >= 90 in last 24 months with Balance > INR 5,000' : data.matchedCondition == 'Case 4' ? 'DPD >= 5 in last 6 months with Balance/Overdue amount >INR 5,000' : data.matchedCondition == 'Case 5' ? 'Current Sanctioning Exception Case & previous loan overdue' : ""}</td>

                                                    </tr>
                                                )
                                            })
                                            : ""}

                                    </tbody>
                                </table>
                            </div>

                        ) : (
                            ''
                        )}
                    </div>
                    : ""}
                {crifTab === 'greenflag' ?
                    <div className='overflow-x-auto' >
                        {analysisData != null &&
                            analysisData != undefined &&
                            analysisData != '' &&
                            analysisData.approve != null &&
                            analysisData.approve != '' &&
                            analysisData.approve != undefined && analysisData.finalDecision != 'Rejected' ? (
                            <div className='green-table'>
                                <table className='text-center '>
                                    <thead>
                                        <tr>
                                            <th>Account Type</th>
                                            <th>OverDue Amount</th>
                                            <th>Disburse Amount</th>
                                            <th>Disburse Date</th>
                                            <th>Account Status</th>
                                            <th>Current Balance</th>
                                            <th>Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {analysisData != null &&
                                            analysisData != undefined &&
                                            analysisData != ''
                                            ? analysisData.approve.map((data, i) => {
                                                return (
                                                    <tr key={i}>
                                                        <td>{data.accountType}</td>
                                                        <td>{data.overDueAmount}</td>
                                                        <td>{data.disburseAmount}</td>
                                                        <td>{data.disburseDate}</td>
                                                        <td>{data.accountStatus}</td>
                                                        <td>{data.currentBalance}</td>
                                                        <td>{data.status}</td>
                                                    </tr>
                                                )
                                            })
                                            : ''}
                                    </tbody>
                                </table>
                            </div>
                        ) : (
                            ''
                        )}
                    </div>
                    : ""}
                {crifTab === 'exceptional' ?
                    <div className='overflow-x-auto' >

                        {analysisData != null &&
                            analysisData != undefined &&
                            analysisData != '' &&
                            analysisData.exceptionCase != null &&
                            analysisData.exceptionCase != '' &&
                            analysisData.exceptionCase != undefined ? (
                            <div className='yellow-table'>
                                <table className='text-center '>
                                    <thead>
                                        <tr>
                                            <th>Account Type</th>
                                            <th>OverDue Amount</th>
                                            <th>Disburse Amount</th>
                                            <th>Disburse Date</th>
                                            <th>Account Status</th>
                                            <th>Current Balance</th>
                                            <th>Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {analysisData != null &&
                                            analysisData != undefined &&
                                            analysisData != ''
                                            ? analysisData.exceptionCase.map((data, i) => {
                                                return (
                                                    <tr key={i}>
                                                        <td>{data.accountType}</td>
                                                        <td>{data.overDueAmount}</td>
                                                        <td>{data.disburseAmount}</td>
                                                        <td>{data.disburseDate}</td>
                                                        <td>{data.accountStatus}</td>
                                                        <td>{data.currentBalance}</td>
                                                        <td>{data.status}</td>
                                                    </tr>
                                                )
                                            })
                                            : ''}
                                    </tbody>
                                </table>
                            </div>
                        ) : (
                            ''
                        )}
                    </div>
                    : ""}
                {crifTab === 'obligation' && crifData.obligationData && crifData.obligationData.loanReportAnalysed ?
                    <div className='overflow-x-auto' >

                        {crifData != null &&
                            crifData != undefined &&
                            crifData != '' &&
                            crifData.obligationData.loanReportAnalysed != null &&
                            crifData.obligationData.loanReportAnalysed != '' &&
                            crifData.obligationData.loanReportAnalysed != undefined ? (
                            <div className='credit-table'>
                                <table className='text-center '>
                                    <thead>
                                        <tr>
                                            <th>Account Type</th>
                                            <th>OverDue Amount</th>
                                            <th>Disburse Amount</th>
                                            <th>Disburse Date</th>
                                            <th>Account Status</th>
                                            <th>Current Balance</th>
                                            <th style={{ width: '10px' }}>Combined Payment History</th>
                                            <th>Ownership</th>
                                            <th>Security Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {crifData.obligationData != null &&
                                            crifData.obligationData != undefined &&
                                            crifData.obligationData != ''
                                            ? crifData.obligationData.loanReportAnalysed.map((data, i) => {
                                                return (
                                                    <tr key={i}>
                                                        <td>{data.accType}</td>
                                                        <td>{data.overDueAmt}</td>
                                                        <td>{data.disbursedAmt}</td>
                                                        <td>{data.disburseDate}</td>
                                                        <td>{data.accountStatus}</td>
                                                        <td>{data.currentBal}</td>
                                                        <td style={{ width: '10px' }}>{data.combinedPaymentHistory}</td>
                                                        <td>{data.ownershipInd}</td>
                                                        <td>{data.securityStatus}</td>
                                                    </tr>
                                                )
                                            })
                                            : ''}
                                    </tbody>
                                </table>
                            </div>
                        ) : (
                            ''
                        )}
                    </div>
                    : ""}
            </div>
        </div>
    )
}
export default Report