import React from 'react'

const ConfirmationPopup = ({ cancelLoanConfirm, confirmationText, invalidMsg ,completeHandler}) => (
    <div className="background-blur">
        <div className="popup-main small-popup container-fluid max-width-500px dbl-border">
            <div className="row from-group">
                <div className="col-xs-12">
                    <h4 className="text-center">{confirmationText}</h4>
                </div>
            </div>
            <div className="row from-group">
                <div className="col-xs-12 col-sm-2">
                </div>
                <div className="col-xs-12 col-sm-8">
                    <label style={{ color: '#000' }}>InComplete Type</label>
                    <select className='form-control' onChange={(e)=>completeHandler(e)}>
                    <option value=''>Select InComplete Type</option>
                        <option value=' Not Answering/Disconnecting'> Not Answering/Disconnecting</option>
                        <option value='Not reachable/Switched off'>Not reachable/Switched off</option>
                        <option value='KYC pending'>KYC pending</option>
                        <option value='Bank statement/Finbit'>Bank statement/Finbit</option>
                        <option value='Salary Slip/Employment Proof'>Salary Slip/Employment Proof</option>
                        <option value='Current Address'>Current Address</option>
                        <option value='Others'>Others</option>

                    </select>
                    <div style={{ color: 'red', textAlign: 'center', fontSize: '14px' }}>{invalidMsg}</div>

                </div>
                <div className="col-xs-12 col-sm-2">
                </div>
            </div>
            <div className="row from-group text-center" style={{marginTop:'20px'}}>
                    <button className="btn btn-success" onClick={(e) => cancelLoanConfirm('yes')}>YES</button>&nbsp;&nbsp;
                    <button className="btn btn-danger" onClick={(e) => cancelLoanConfirm('no')}>NO</button>

            </div>
        </div>
    </div>
)

export default ConfirmationPopup;