import React from 'react'
import { STRING } from '../../../utils/Constant'

const GiftPopup = ({closePopup,giftCardData }) => (
    <div className="">
        <div className="background-blur" onClick={e => closePopup()}></div>
        <div className="popup-main small-popup container-fluid dbl-border">
            <h3 className="text-center blue-text">Gift Card Details</h3>
            {giftCardData != null && giftCardData != '' ? (
            <table className='text-center phocket-table '>
              <thead>
                <tr>
                  <th>Amount</th>
                  <th>Voucher Code</th>
                  <th>Voucher Pin</th>
                  <th>Expiry Date</th>
                  
                  
                </tr>
              </thead>
              <tbody>
                
                {giftCardData.map((loan, i) => {
                  return (
                    <tr key={i}>
                   <td>{loan.amount}</td>
                  <td>{loan.voucherCode}</td>
                  <td>{loan.voucherPin}</td>
                  <td>{loan.expiryDate}</td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          ) : (
            <h3 className='text-center'>No Data</h3>
       
        )}
        </div>
    </div>
)

export default GiftPopup;