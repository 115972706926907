import React, { useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { statusWiseCommunicationMarketSms } from "../../actioncreator";
import { notificationImageUploadApi } from "../../../../Admin/AdminActionCreator.component";
const Email = ({ admin, userIdList,payload, communicateViaFilter, status }) => {
    const [typeList, setTypeList] = useState(["notification"])
    const [mailBody, setmailBody] = useState('')
    const [imageUrl, setimageUrl] = useState('')
    const [subject, setsubject] = useState('')
    const getBranchCommunicateForBreakDown = () => {
        statusWiseCommunicationMarketSms(typeList, mailBody, subject, admin.emailId, imageUrl, userIdList, '', '',payload, communicateViaFilter, status, callback => {

        })
    };
    const sendHandlerMsg = (type) => {
        getBranchCommunicateForBreakDown()
    }
    const mailHandler = (e, type) => {
        if (type === 'subject') {
            setsubject(e.target.value)
        }
        if (type === 'body') {
            setmailBody(e)
        }
    }
    const uploadNotificationImage = (e) => {
        let val = e.target.value.split(/[\\\/]/)[2];
        File = e.target.files[0];
        let docType = val.split(".");
        let fileType = docType[docType.length - 1];
        notificationImageUploadApi(File, val, e.target.files[0].type,
            (callBack) => {
                if (callBack != 'failure') {
                    setimageUrl(callBack)
                    // this.setState({
                    //     popupState: true, popupStatus: 'File Uploaded',
                    //     notificationImage: callBack
                    // }, () => this.removePopup())
                } else {
                    // this.setState({ popupState: true, popupStatus: 'Failed To upload file' }, () => this.removePopup())
                }
            });

    }

    return (
        <div className=' marketing-main-css '>
            <div className='row'>
                <div className='col-sm-9'>
                    <div className='cards-shadow ' style={{ padding: '15px', marginTop: '20px' }}>
                        <label className="form-label">Select Notification Template </label>
                        <select className="form-select"  >
                            <option value="" >Select From Below Options</option>
                        </select>
                        <label style={{ color: 'rgba(0, 0, 0, 0.45', marginTop: '10px' }}>Notification Body</label>
                        <ReactQuill
                            style={{
                                backgroundColor: "#FFFFFF",
                                marginTop: "0rem",
                            }}
                            name="body"
                            theme="snow"
                            value={mailBody}
                            onChange={(value) => mailHandler(value, 'body')}
                        />
                        <div style={{ width: "100%", marginTop: "1rem" }}>

                            <div className="image-upload-div" style={{ borderRadius: '5px' }} >
                                <input
                                    onChange={(value) => uploadNotificationImage(value)}
                                    accept={['.png', '.jpeg', '.jpg']}
                                    className="file-upload-input" type='file' />
                                <div className="drag-text-new">
                                    <h5 className="upload-icon"> Click here to Upload Image </h5>
                                </div>
                            </div>
                        </div>
                        <button
                            onClick={() => sendHandlerMsg()}
                            title="SEND"

                            type="button"
                            className="bulkCommSendBtn"
                            style={{ width: '100px', marginTop: '20px' }}
                        >
                            Send
                        </button>
                    </div>
                </div>
            </div>
        </div>

    )
}
export default Email