import React from 'react'
import Logo from '../../../../images/initiatepaymentn.png'
import CSVReader from "react-csv-reader";

const BulkPayment = ({ submit,csvHandler,fileName}) => (
    <div className="container">
    <h3 className="text-center blue-text">Initiate Payment By Razor Pay</h3>
    <div className="max-width-500px">
        <b>Sample Csv</b>
        <div><img src={Logo} width="100%"/></div>
        <br/>
        <div className="row">
            <div className='col-xs-12'>
                <div className="display-flex">
                        <input type="text" readOnly value={fileName} id="uploadCSVFile" className="form-control height-42px" />
                        <div className="fileUpload btn btn--browse">
                            <span>Browse</span>
                            <input  id="uploadCSVBtn" type="file" className="upload" onChange={e => csvHandler(e)} />
                        </div>
                    </div>
                    </div>

                </div>
                <div className='col-xs-12 text-center'>
                    <button className='profile-save-btn' onClick={submit}>Save</button>
                </div>
                
            </div>
           
        </div>
    )

export default BulkPayment;