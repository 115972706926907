import React, { Component } from 'react'
import AddLocationUi from '../../../presentationals/Admin/AdminCoupons/AddOutletLocation.component'
import { saveOutletAddressAPI, getMerchantOutLets, deleteOutlet } from './AddMerchentActionCreator.Component'
import Popup from '../../../presentationals/Popup/Popup.component';
import { AddAddressValidation } from './Validation'
import Confirmation from '../../../presentationals/Popup/ConfirmationPopup.component'

class SubLocationAdd extends Component {
    constructor(props) {
        super(props);
        this.state = {
            confirmationPopup: false,
            confirmationText: '',
            idForDelete: '',
            popupState: false,
            popupStatus: '',
            addressData: '',
            MerchantValidationMsg: '',
            merchantData: {
                locationId: '',
                merchantId: -1,
                subLocationId: '',
                subLocationName: '',
            }
        }
    }

    componentWillMount() {
        getMerchantOutLets(this.state.merchantData,
            (callBack) => {
                this.setState({ addressData: callBack })
            })
    }

    render() {
        const { listingOfCategory } = this.props
        return (
            <div>
                {this.state.popupState == true ?
                    <Popup
                        closePopup={this.closePopup.bind(this)}
                        popupStatus={this.state.popupStatus}
                    />
                    : ""}
                <AddLocationUi
                    merchantData={this.state.merchantData}
                    getDataHandler={this.getDataHandler.bind(this)}
                    postDataHandler={this.postDataHandler.bind(this)}
                    listingOfCategory={listingOfCategory}
                    ulSubLocationData={this.ulSubLocationData.bind(this)}
                    addressData={this.state.addressData}
                    MerchantValidationMsg={this.state.MerchantValidationMsg}
                    deleteOutlet={this.deleteOutlet.bind(this)}
                />
                {this.state.confirmationPopup == true ?
                    <Confirmation
                        cancelLoanConfirm={this.cancelLoanConfirm.bind(this)}
                        confirmationText={this.state.confirmationText} />
                    : ""}
            </div>
        )
    }

    cancelLoanConfirm(conType) {
        if (conType == 'yes') {
            deleteOutlet(this.state.idForDelete, (callBack) => {
                if (callBack == 'success') {
                    this.setState({
                        confirmationPopup: false,
                        merchantData: {
                            locationId: '',
                            merchantId: -1,
                            subLocationId: '',
                            subLocationName: '',
                        }
                    })
                    getMerchantOutLets(this.state.merchantData,
                        (callBack) => {
                            this.setState({ addressData: callBack })
                        })
                }
            })
        } else if (conType == 'no') {
            this.setState({ confirmationPopup: false })
        }
    }

    deleteOutlet(data){
        this.setState({
            idForDelete: data.outletid,
            confirmationPopup: true,
            confirmationText: 'Are you sure to Delete ' + data.address
        })
    }

    removePopup() {
        setTimeout(function () {
            this.setState({ popupState: false });
        }.bind(this), 5000)
    }

    closePopup() {
        this.setState({ popupState: false });
    }

    ulSubLocationData(e, data) {
        let merchantData = Object.assign({}, this.state.merchantData);
        merchantData.locationId = data.locationid;
        merchantData.merchantId = data.merchantid;
        merchantData.subLocationId = data.outletid;
        merchantData.subLocationName = data.address;
        this.setState({ merchantData })
    }

    getDataHandler(e, type) {
        let merchantData = Object.assign({}, this.state.merchantData);
        if (type == "selectLocationId") {
            merchantData.locationId = e.target.value;
        } else if (type == "selectMerchantId") {
            merchantData.merchantId = e.target.value;
        } else if (type == "outAddName") {
            merchantData.subLocationName = e.target.value;
        }
        this.setState({ merchantData, MerchantValidationMsg: '' })
    }

    postDataHandler() {
        let MerchantValidation = AddAddressValidation(
            this.state.merchantData
        )
        if (MerchantValidation.status) {
            this.setState({
                MerchantValidationMsg: MerchantValidation,
            })
            saveOutletAddressAPI(this.state.merchantData, (callBack) => {
                if (callBack == 'success') {
                    this.setState({
                        popupState: true,
                        popupStatus: 'Outlet Location saved Successfully',
                        merchantData: {
                            locationId: '',
                            merchantId: -1,
                            subLocationId: '',
                            subLocationName: '',
                        }
                    })
                    this.removePopup();
                    getMerchantOutLets(this.state.merchantData,
                        (callBack) => {
                            this.setState({ addressData: callBack })
                        })
                    this.props.getAllData()
                } else {
                    this.setState({
                        popupState: true,
                        popupStatus: 'Please try again',
                    })
                    this.removePopup();
                }
            })
        } else {
            this.setState({
                MerchantValidationMsg: MerchantValidation,
            })
        }
    }
}

export default SubLocationAdd;