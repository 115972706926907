import React from 'react'

const NewAmbassador = ({ ambRegDetail, ambRegDetailChange, ambRegDetailHandler, amShowPass, eyeType }) => (
    <div className="container-fluid max-width-500px">
        <h3 className="text-center blue-text">Ambassador Registration</h3>
        <div className="row form-group">
            <div className="col-xs-12 col-sm-6">
                <label className="font-12px">Full Name</label>
                <input type="text" className="form-control" placeholder="Enter Ambassador Name" value={ambRegDetail.ambNameState} onChange={e => ambRegDetailChange(e, 'name')} />
            </div>
            <div className="col-xs-12 col-sm-6">
                <label className="font-12px">Email Id</label>
                <input type="email" className="form-control" placeholder="Enter Ambassador Email Id" value={ambRegDetail.ambEmailState} onChange={e => ambRegDetailChange(e, 'email')} />
            </div>
        </div>
        <div className="row form-group">
            <div className="col-xs-12 col-sm-6">
                <label className="font-12px">Contact No.</label>
                <input type="number" className="form-control" placeholder="Enter Ambassador Contact No." value={ambRegDetail.ambMobileState} onChange={e => ambRegDetailChange(e, 'mobile')} />
            </div>
            <div className="col-xs-12 col-sm-6">
                <label className="font-12px">Password</label>
                {eyeType == true ?
                    <i className="fa fa-eye-slash pull-right pointer" onClick={e => amShowPass(false)} />
                    :
                    <i className="fa fa-eye pull-right pointer" onClick={e => amShowPass(true)} />
                }
                <input type="password" id="ambPassword" className="form-control" placeholder="Enter Password" value={ambRegDetail.ambPasswordState} onChange={e => ambRegDetailChange(e, 'password')} />
            </div>
        </div>
        <div className="row form-group">
            <div className="col-xs-12">
                <button className="btn center-block profile-save-btn" onClick={e => ambRegDetailHandler()}>Save</button>
            </div>
        </div>
    </div>
)

export default NewAmbassador;