import React from 'react';
import CSVReader from "react-csv-reader";
import PincodeImg from '../../../../images/bankAccInfo.png'

const AccountInfo = ({ selectCsvData, handleForce, csvDataArray, searchState, saveCsvData,
    tableMinPagination, tableMaxPagination, phocketTablePagination, searchHandler }) => (
        <div className="container-fluid">
            <div className="max-width-500px">
                <div className="row">
                    <h3 className="text-center blue-text">Upload Account CSV</h3>
                </div>
                <div className="row form-group">
                    <div className="col-xs-12 display-flex">
                        <CSVReader
                            cssClass="react-csv-input"
                            onFileLoaded={handleForce}
                        />
                        {/* <button className="profile-save-btn margin-0" onClick={(e) => saveCsvData()}><span className="fa fa-save" /></button> */}
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-xs-12 col-sm-8">
                    {searchState ?
                        <div>
                            <h3 className="text-center">Update Data from here.</h3>
                            <input type="text" className="center-block" onChange={e => searchHandler(e)} placeholder="Mobile or email" />
                        </div>
                        : null}
                    {csvDataArray.length > 0 ?
                        <div>
                            <div className="overflow-auto">
                                <table className="phocket-table">
                                    <thead>
                                        <tr>
                                            <th>Mobile &amp; email</th>
                                            <th>Bank Name &amp; IFSC</th>
                                            <th>User Name &amp; Account No.</th>
                                            <th>Update</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {/* {csvDataArray.slice(tableMinPagination, tableMaxPagination).map((data, i) => { */}
                                        {csvDataArray.map((data, i) => {
                                            return (
                                                <tr key={i}>
                                                    <td>
                                                        <div className="">{data.mobileNumber}</div>
                                                        <div>{data.emailId}</div>
                                                    </td>
                                                    <td>
                                                        <div className="">{data.bankName}</div>
                                                        <div className="font-12px">{data.ifscCode}</div>
                                                    </td>
                                                    <td>
                                                        <div className="">{data.nameAsBankAccount}</div>
                                                        <div className="font-12px">{data.accountNumber}</div>
                                                    </td>
                                                    <td>
                                                        <div>
                                                            <input className="phocket-checkbox" type="checkbox" id={"accountDetailUpdate" + i} onChange={(e) => selectCsvData(e, data)} />
                                                            <label htmlFor={"accountDetailUpdate" + i}><span></span></label>
                                                        </div>
                                                        {/* <button className="profile-save-btn margin-0" onClick={(e) => saveCsvData(data)}><span className="fa fa-save" /></button> */}
                                                    </td>
                                                </tr>

                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>
                            {/* <div className="container-fluid">
                                <div className="row">
                                    <div className="col-sm-5 col-xs-12 no-padding"><button type="button" className="btn btn-default fa fa-chevron-left btn-lg btn-block grey  " onClick={e => phocketTablePagination(csvDataArray, 'back')}></button></div>
                                    <div className="col-sm-2 col-xs-12  phoc" >{(tableMinPagination + 1) + ' - ' + (tableMaxPagination) + ' of ' + csvDataArray.length}</div>
                                    <div className="col-sm-5 col-xs-12 no-padding" ><button type="button" className="btn btn-default fa fa-chevron-right btn-lg btn-block grey" onClick={e => phocketTablePagination(csvDataArray, 'forward')}></button></div>
                                </div>
                            </div> */}
                        </div>
                        : null}
                    {searchState ?
                        <button className="profile-save-btn center-block" onClick={(e) => saveCsvData()}><span className="fa fa-save" /></button>
                        : null}
                </div>
                <div className="col-xs-12 col-sm-4">
                    <h3 className="text-center">Your CSV file must be in this format.</h3>
                    <a href={PincodeImg} target="_blank"><img className="center-block area-100" src={PincodeImg} /></a>
                </div>
            </div>
        </div>
    )

export default AccountInfo;