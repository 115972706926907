import React from 'react'
import { STRING } from '../../../../utils/Constant'
import { ROLE } from '../../../../utils/Constant'
const ActionPage = ({ userDetails, alldetails, reason, score, actionChangeHandler,
    actionSubmitHandler, varificationMailPopupHandler, actionReason, otherReason,
    OtherInput, admin, removeFromNBFCHandler }) => (
    <div className="container-fluid max-width-500px">
        {/* <div className="row">
            <div className="col-xs-12">
                <h3 className="text-center blue-text">Action for Loan</h3>
            </div>
        </div> */}
        {/* <div className="row form-group">
            <div className="col-xs-12">
                <label className="font-12px">Score</label>
                <input type="text" className="form-control" onChange={e => score(e)} />
            </div>
        </div> */}
        {admin != null && admin.nbfcName == null || admin.nbfcName == "" ?
            <div className="row form-group">
                <div className="col-xs-12">
                    <label className="font-12px">Action</label>
                    <select className="form-control" onChange={(e) => actionChangeHandler(e)}>
                        <option value="">Select Action</option>
                        {admin.rolelist.indexOf(ROLE.ACTION) >= 0 ?
                            <option value="approve">Approve</option>
                            : ""}
                        {admin.rolelist.indexOf(ROLE.ACTION) >= 0 ?
                            <option value="reject">Reject</option>
                            : ""}
                        {admin.rolelist.indexOf(ROLE.ACTION_AND_DISBURSE) >= 0 ?
                            <option value="Disbursed">Disbursed</option>
                            : ""}
                        {admin.rolelist.indexOf(ROLE.ACTION) >= 0 ?
                            <option value="Approve Without Mail">Approve Without Mail</option>
                            : ""}
                        {admin.rolelist.indexOf(ROLE.ACTION) >= 0 ?
                            <option value="Reject Without Mail">Reject Without Mail</option>
                            : ""}
                        {admin.rolelist.indexOf(ROLE.ACTION_AND_DISBURSE) >= 0 ?
                            <option value="Disbursed Without Mail">Disbursed Without Mail</option>
                            : ""}
                        {admin.rolelist.indexOf(ROLE.ACTION_AND_DISBURSE) >= 0 ?
                            <option value="Paid">Paid</option>
                            : ""}
                        {admin.rolelist.indexOf(ROLE.ACTION) >= 0 ?
                            <option value={STRING.PERMANENT_REJECT}>Permanent Reject</option>
                            : ""}
                        {admin.rolelist.indexOf(ROLE.ACTION) >= 0 ?
                            <option value={"softApproval"}>Soft Approval</option>
                            : ""}
                    </select>
                </div>
            </div>
            :
            <div className="row form-group">
                <div className="col-xs-12">
                    <label className="font-12px">Action</label>
                    <select className="form-control" onChange={(e) => actionChangeHandler(e)}>
                        <option value="">Select Action</option>
                        <option value="approve">Approve</option>
                        <option value="Disbursed">Disbursed</option>
                    </select>
                </div>
            </div>
        }
        {actionReason != null && actionReason != '' && actionReason.length > 0 ?
            <div className="row form-group">
                <div className="col-xs-12">
                    <label className="font-12px">Reason</label>
                    <select className="form-control" onChange={e => reason(e)}>
                        <optgroup className="display-none">
                            <option value="">Select Reason</option>
                        </optgroup>
                        <optgroup label="reason">
                            {actionReason.map((reason, i) => {
                                return (
                                    <option key={i} value={reason.message}>{reason.message}</option>
                                )
                            })}
                        </optgroup>
                        {/* <optgroup>
                            <option value="Other">Other</option>
                        </optgroup> */}
                    </select>
                    {/* <textarea className="form-control" rows="2" onChange={e => reason(e)} placeholder="Reason"></textarea> */}
                </div>
            </div>
            : ""}

        {OtherInput == true ?
            <div className="row form-group">
                <div className="col-xs-12">
                    <label className="font-12px">Other Reason</label>
                    <input type="text" className="form-control" onChange={e => otherReason(e)} />
                </div>
            </div>
            : ""}
        <div className="row form-group text-center">
            <div className="col-xs-12">
                <button className="profile-save-btn" onClick={e => actionSubmitHandler()}><span className="fa fa-rocket" /></button>
                {userDetails.applicationStatusForDetail == "Approved" || userDetails.applicationStatusForDetail == "Disbursed" ?
                    <button className="profile-save-btn margin-left-25px" onClick={e => varificationMailPopupHandler(alldetails.loanId)} data-toggle="tooltip" data-placement="bottom" title="VERIFICATION">
                        <span className="fa fa-envelope" />
                    </button>
                    : ""}
                <button className="profile-save-btn margin-left-25px" onClick={e => removeFromNBFCHandler(alldetails)} data-toggle="tooltip" data-placement="bottom" title="Remove From NBFC">Remove From NBFC</button>
            </div>
        </div>
    </div>
)

export default ActionPage;