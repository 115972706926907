import React, { useEffect, useState } from "react";
import { createInvoiceBilldeskApi, getBillDeskApi } from "../AdminActionCreator.component";
import { Pagination } from "@mui/material";
import Loader from '../../../presentationals/Loader/layerLoader.component';
import Popup from '../../../presentationals/Popup/Popup.component';
import ConfirmationPopup from "../../../presentationals/Popup/billdeskpopup";
import moment from "moment";

const BillDeskMandate = ({ allStatus }) => {
    const [loanStatus, setLoanStatus] = useState('109');
    const [emiStatus, setEmiStatus] = useState('');
    const [emiStatusOptions, setEmiStatusOptions] = useState([]);
    const [pageNo, setPageNo] = useState(1);
    const [allUserDetail, setAllUserDetail] = useState([]);
    const [filteredUserDetail, setFilteredUserDetail] = useState([]);
    const [dataToSend, setDataToSend] = useState([]);
    const [loaderState, setLoaderState] = useState(false);
    const [totalPages, setTotalPages] = useState(0);
    const [popupState, setPopupState] = useState(false);
    const [popupStatus, setPopupStatus] = useState('');
    const [paymentData, setPaymentData] = useState('');
    const [deductPaymentPopup, setDeductPopup] = useState(false);
    const [userData, setUserData] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    const [PaymentDate, setPaymentDate] = useState(moment(new Date()).format('DD-MM-YYYY'))
    useEffect(() => {
        getData(loanStatus, pageNo);
        fetchEmiStatusOptions();
    }, [loanStatus, pageNo]);

    useEffect(() => {
        filterUserDetails();

    }, [searchTerm, emiStatus, allUserDetail]);

    const getData = (loanStatus, pageNo) => {
        setLoaderState(true);
        getBillDeskApi(callBack => {
            setAllUserDetail(callBack);
            setDataToSend([]);
            setLoaderState(false);
        });
    };

    const fetchEmiStatusOptions = () => {

    };

    const changeHandler = (e) => {
        setLoanStatus(e.target.value);
    };

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };

    const handleEmiStatusChange = (e) => {
        setEmiStatus(e.target.value);
    };

    const selectAll = (e) => {
        if (e.target.checked) {
            const allUserIds = allUserDetail.map(dataNew => dataNew.userId);
            setDataToSend(allUserIds);
            allUserDetail.forEach((_, i) => {
                document.getElementById('disburseIcici' + i).checked = true;
            });
        } else {
            setDataToSend([]);
            allUserDetail.forEach((_, i) => {
                document.getElementById('disburseIcici' + i).checked = false;
            });
        }
    };

    const changeHandlerCheckbox = (e, data) => {
        if (e.target.checked) {
            setDataToSend(prev => [...prev, data.userId]);
        } else {
            setDataToSend(prev => prev.filter(id => id !== data.userId));
        }
    };

    const filterUserDetails = () => {
        setFilteredUserDetail(
            allUserDetail.filter(user =>
                user.applicationId.toLowerCase().includes(searchTerm.toLowerCase()) &&
                (emiStatus === '' || user.emiStatus === emiStatus)
            )
        );
    };

    const removerPopup = () => {
        setTimeout(() => {
            closePopup();
        }, 5000);
    };

    const handlePageChange = (direction) => {
        if (direction === 'prev' && pageNo > 1) {
            setPageNo(prev => prev - 1);
        } else if (direction === 'next' && pageNo < totalPages) {
            setPageNo(prev => prev + 1);
        }
    };

    const handleChangeNewPagination = (e, value) => {
        setPageNo(value);
        getData(loanStatus, value);
    };

    const closePopup = () => {
        setPopupState(false);
    };

    const deduct = (data) => {
        console.log(data);
        setUserData(data);
        setDeductPopup(!deductPaymentPopup);
    };

    const cancelLoanConfirm = (type) => {
        if (type === 'yes') {
            setDeductPopup(false);
            if (userData.loanId && userData.emiAmt && userData.userId && userData.emiId) {
                console.log(userData.loanId, userData.emiAmt, userData.userId);
                createInvoiceBilldeskApi(userData,PaymentDate, callBack => {
                    console.log(callBack, 'callBack');
                    if (callBack === 'success') {
                        setPopupState(true);
                        setPopupStatus('Done Successfully!');
                        removerPopup();
                    } else {
                        setPopupState(true);
                        setPopupStatus('please try again later!');
                        removerPopup();
                    }
                });
            }
        }
        if (type === 'no') {
            setDeductPopup(false);
        }
    };
    const uniqueStatuses = allUserDetail !== '' ? Array.from(new Set(allUserDetail.map(status => status.emiStatus))) : ""



    return (
        <div className="container-fluid">
            <h3><b>Billdesk Mandate Hit</b></h3>
            {loaderState ? <Loader /> : ""}
            {popupState ?
                <Popup
                    closePopup={closePopup}
                    popupStatus={popupStatus}
                />
                : ""}
            {deductPaymentPopup ?
                <ConfirmationPopup userData={userData} cancelLoanConfirm={cancelLoanConfirm} confirmationText={'Are You Sure to deduct Payment?'} />
                : ""}
            {!loaderState ?
                <>
                    <div className="row" style={{ marginTop: '12px', marginBottom: '12px' }}>
                        <div className="col-sm-4 col-xs-12">
                            <label>Search by Application ID</label>
                            <input
                                type="text"
                                value={searchTerm}
                                onChange={handleSearchChange}
                                className="form-control"
                                placeholder="Search..."
                            />
                        </div>
                        <div className="col-sm-4 col-xs-12">
                            <label>Filter by EMI Status</label>
                            <select value={emiStatus} className="form-control" onChange={handleEmiStatusChange}>
                                <option value=''>All</option>
                                {uniqueStatuses.map((emiStatus, i) => (
                                    <option key={i} value={emiStatus}>{emiStatus}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <div className="phocket-table-new" style={{ overflow: 'auto' }}>
                        <table className="">
                            <thead>
                                <tr>
                                    <th>Application ID</th>
                                    <th>EMI Status</th>
                                    <th>EMI Repayment Amount</th>
                                    <th>Name</th>
                                    <th>Application Id</th>
                                    <th>EMI Amount </th>
                                    <th> Due Date</th>
                                    <th>Loan Id </th>
                                    <th>Disburse Date</th>
                                    <th>Deduct Payment</th>
                                </tr>
                            </thead>
                            <tbody>
                                {filteredUserDetail.map((data, i) => (
                                    <tr key={i}>
                                        <td>{data.applicationId}</td>
                                        <td>{data.emiStatus}</td>
                                        <td>{data.emiRepaymentAmt}</td>
                                        <td>{data.name}</td>
                                        <td>{data.applicationId}</td>
                                        <td>{data.emiAmt}</td>
                                        <td>{data.dueDate}</td>
                                        <td>{data.applicationLoanId}</td>
                                        <td>{data.disburseDate}</td>
                                        <td><button className="btn btn-primary" onClick={() => deduct(data)}>Deduct Payment</button></td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    {/* <div className="row">
                        <div style={{ marginTop: '10px', float: 'right', marginBottom: '10px' }}>
                            <Pagination
                                count={Math.ceil(totalPages / 15 - 1)}
                                page={pageNo}
                                onChange={handleChangeNewPagination}
                                color="primary" />
                        </div>
                    </div> */}
                </>
                : ""}
        </div>
    );
};

export default BillDeskMandate;
