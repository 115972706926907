import React, { Component } from 'react';
import LoanApply from '../../../presentationals/Admin/ApplicationLeads/CreditVidyaLeads.component';
import { APIS } from '../../../../utils/api-factory'
import UserDetail from '../CustomerPages/UserDetailMain.container';
import { getUserDetailByLoanId, getUserDetailApiAdmin } from '../AdminActionCreator.component';
import Popup from '../../../presentationals/Popup/Popup.component';
let paginationNumber = 10;

class LoanApplyContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            forwordPageState: 1,
            loadarState: false,
            popupState: false,
            popupStatus: '',
            creditVidyaData: '',
            tableMinPagination: 0,
            tableMaxPagination: paginationNumber,
            pageViewState: 'leads',
            allDetail: '',
            userDetail: '',
            actionButtonState: false,
            markCompleteBtn: false
        }
    }

    componentWillMount() {

    }

    getLoanLead() {
        this.setState({ loadarState: true })
        fetch(APIS.CREDIT_VIDYA_LEADS + this.state.forwordPageState + '&salary=40000'+'&nbfcName=creditvidya')
            .then(res => res.json())
            .then(res => {
                this.setState({
                    creditVidyaData: res,
                    loadarState: false
                })
            })
    }

    componentDidMount() {
        this.getLoanLead()
    }

    render() {
        const { admin } = this.props
        return (
            <div className="full-height">
                {this.state.pageViewState == 'leads' ?
                    <LoanApply
                        creditVidyaData={this.state.creditVidyaData}
                        loadarState={this.state.loadarState}
                        paginationHandler={this.paginationHandler.bind(this)}
                        userDetailHandler={this.userDetailHandler.bind(this)}
                        phocketTablePagination={this.phocketTablePagination.bind(this)}
                        tableMinPagination={this.state.tableMinPagination}
                        tableMaxPagination={this.state.tableMaxPagination}
                        refreshLeads={this.refreshLeads.bind(this)}
                    />
                    : this.state.pageViewState == 'details' ?
                        <UserDetail
                            allDetail={this.state.allDetail}
                            userDetail={this.state.userDetail}
                            admin={admin}
                            backHandler={this.backHandler.bind(this)}
                            actionButtonState={this.state.actionButtonState}
                            markCompleteBtn={this.state.markCompleteBtn}
                        />
                        : null}

                {this.state.popupState == true ?
                    <Popup
                        closePopup={this.closePopup.bind(this)}
                        popupStatus={this.state.popupStatus}
                    />
                    : ""}
            </div>
        )
    }

    refreshLeads() {
        this.getLoanLead()
    }

    userDetailHandler(data) {
        if (data.loanId != null && data.loanId != '') {
            getUserDetailByLoanId(data.loanId,
                (callBack) => {
                    this.setState({
                        pageViewState: 'details',
                        allDetail: callBack,
                        userDetail: data,
                    })
                })
        } else {
            getUserDetailApiAdmin(data.userId,
                (callBack) => {
                    this.setState({
                        pageViewState: 'details',
                        allDetail: callBack,
                        userDetail: data,
                    })
                })
        }
    }

    backHandler() {
        this.setState({
            pageViewState: 'leads'
        })
    }

    paginationHandler(type) {
        if (type == 'left') {
            if (this.state.forwordPageState > 1) {
                this.setState({
                    forwordPageState: this.state.forwordPageState - 1,
                    tableMinPagination: 0,
                    tableMaxPagination: paginationNumber,
                }, () => this.getLoanLead())
            }
        } else if (type == 'right') {
            this.setState({
                forwordPageState: this.state.forwordPageState + 1,
                tableMinPagination: 0,
                tableMaxPagination: paginationNumber,
            }, () => this.getLoanLead())
        }
    }

    phocketTablePagination(data, type) {
        if (type == 'back') {
            if (this.state.tableMinPagination >= paginationNumber) {
                this.setState({
                    tableMinPagination: this.state.tableMinPagination - paginationNumber,
                    tableMaxPagination: this.state.tableMaxPagination - paginationNumber,
                })
            }
        } else if (type == 'forward') {
            if (this.state.tableMaxPagination < data.length) {
                this.setState({
                    tableMaxPagination: this.state.tableMaxPagination + paginationNumber,
                    tableMinPagination: this.state.tableMinPagination + paginationNumber
                })
            }
        }
    }

    removePopup() {
        setTimeout(function () {
            this.setState({ popupState: false });
        }.bind(this), 5000)
    }

    closePopup() {
        this.setState({ popupState: false });
    }

}

export default LoanApplyContainer