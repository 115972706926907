import moment from 'moment'
import React from 'react'
import DatePicker from 'react-datepicker'
const Edit = ({ saveHandler, state, onChangeHandlerBank, onChangeHandlerAddress, onChangeHandlerBasic, dobState }) => {

    return (
        <div className='container-fluid'>
            <div className='row'>
                <div className='col-sm-12 col-xs-12' >
                    <h3 style={{ marginTop: '80px' }}>Edit Basic Details</h3>
                    <div className='row'>

                        <div className='col-sm-4 col-xs-12' style={{ marginTop: '20px' }}>
                            <label>First Name</label>
                            <input placeholder='Enter First Name' className='form-control' value={state.basicData.firstName} onChange={(e) => onChangeHandlerBasic(e, 'firstName')} />
                            <div className="font-12px margin-left-25px height-25px validation-color">
                                {state.basicValidationMsg.field == "firstName" ? state.basicValidationMsg.msg : ""}
                            </div>
                        </div>
                        <div className='col-sm-4 col-xs-12' style={{ marginTop: '20px' }}>
                            <label>Last Name</label>
                            <input placeholder='Enter Last Name' className='form-control' value={state.basicData.lastName} onChange={(e) => onChangeHandlerBasic(e, 'lastName')} />
                            <div className="font-12px margin-left-25px height-25px validation-color">
                                {state.basicValidationMsg.field == "lastName" ? state.basicValidationMsg.msg : ""}
                            </div>
                        </div>
                        <div className='col-sm-4 col-xs-12' style={{ marginTop: '20px' }}>
                            <label>Gender</label>
                            <select placeholder='Select Gender' className='form-control' value={state.basicData.gender} onChange={(e) => onChangeHandlerBasic(e, 'gender')}>
                                <option value=''>Select Gender</option>
                                <option value='male'>Male</option>
                                <option value='female'>Female</option>

                            </select>
                            <div className="font-12px margin-left-25px height-25px validation-color">
                                {state.basicValidationMsg.field == "gender" ? state.basicValidationMsg.msg : ""}
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-sm-4 col-xs-12' style={{ marginTop: '20px' }}>
                            <label>Mobile Number</label>
                            <input placeholder='Enter Mobile Number' className='form-control' value={state.basicData.mobileNumber} onChange={(e) => onChangeHandlerBasic(e, 'mobileNumber')} />
                            <div className="font-12px margin-left-25px height-25px validation-color">
                                {state.basicValidationMsg.field == "mobileNumber" ? state.basicValidationMsg.msg : ""}
                            </div>
                        </div>
                        {/* {console.log(state.basicData.dateOfBirth !== undefined ? moment(state.basicData.dateOfBirth).date : "")} */}
                        <div className='col-sm-4 col-xs-12' style={{ marginTop: '20px' }}>
                            <label>Date Of Birth</label>
                            <DatePicker
                                value={state.basicData.dateOfBirth}
                                selected={dobState}
                                onChange={(e) => onChangeHandlerBasic(e, 'dateOfBirth')}
                                className="form-control "
                                dateFormat="DD/MM/YYYY"
                                placeholderText="DOB"
                                showYearDropdown

                            />

                            <div className="font-12px margin-left-25px height-25px validation-color">
                                {state.basicValidationMsg.field == "dateOfBirth" ? state.basicValidationMsg.msg : ""}
                            </div>
                            {/* {console.log(state.basicData.dateOfBirth)} */}
                            {/* <input type='date' placeholder='Select Date Of Birth' className='form-control' defaultValue={support_date} onChange={(e) => onChangeHandlerBasic(e, 'dateOfBirth')} /> */}
                        </div>
                        <div className='col-sm-4 col-xs-12' style={{ marginTop: '20px' }}>
                            <label>PAN Number</label>
                            <input placeholder='Enter PAN Number' className='form-control' value={state.basicData.panNo} onChange={(e) => onChangeHandlerBasic(e, 'panNo')} />
                        </div>
                        <div className="font-12px margin-left-25px height-25px validation-color">
                            {state.basicValidationMsg.field == "PanNo" ? state.basicValidationMsg.msg : ""}
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-sm-4 col-xs-12' style={{ marginTop: '20px' }}>
                            <label>Aadhaar Number</label>
                            <input placeholder='Enter Aadhaar Number' className='form-control' value={state.basicData.aadhaarNo} onChange={(e) => onChangeHandlerBasic(e, 'aadhaarNo')} />
                            <div className="font-12px margin-left-25px height-25px validation-color">
                                {state.basicValidationMsg.field == "aadharNo" ? state.basicValidationMsg.msg : ""}
                            </div>
                        </div>
                        <div className='col-sm-4 col-xs-12' style={{ marginTop: '20px' }}>
                            <label>Email</label>
                            <input placeholder='Enter Email' className='form-control' value={state.basicData.email} onChange={(e) => onChangeHandlerBasic(e, 'email')} />
                            <div className="font-12px margin-left-25px height-25px validation-color">
                                {state.basicValidationMsg.field == "email" ? state.basicValidationMsg.msg : ""}
                            </div>
                        </div>

                    </div>
                    <div className='row text-center'>
                        <button className='profile-save-btn' onClick={(e) => saveHandler(state.bankData, 'basic')}>Save</button>
                    </div>
                    <hr />
                    <h3>Edit Address Details</h3>
                    <div className='row'>
                        <div className='col-sm-4 col-xs-12' style={{ marginTop: '20px' }}>
                            <label>Address 1</label>
                            <input placeholder='Address 1' className='form-control' value={state.addressData['address1']} onChange={(e) => onChangeHandlerAddress(e, 'address1')} />
                            <div className="font-12px margin-left-25px height-25px validation-color">
                                {state.addressValidationMsg.field == "address1" ? state.addressValidationMsg.msg : ""}
                            </div>
                        </div>
                        <div className='col-sm-4 col-xs-12' style={{ marginTop: '20px' }}>
                            <label>Address 2</label>
                            <input placeholder='Address 2' className='form-control' value={state.addressData['address2']} onChange={(e) => onChangeHandlerAddress(e, 'address2')} />
                            <div className="font-12px margin-left-25px height-25px validation-color">
                                {state.addressValidationMsg.field == "address2" ? state.addressValidationMsg.msg : ""}
                            </div>
                        </div>
                        <div className='col-sm-4 col-xs-12' style={{ marginTop: '20px' }}>
                            <label>Address 3</label>
                            <input placeholder='Address 3' className='form-control' value={state.addressData['address3']} onChange={(e) => onChangeHandlerAddress(e, 'address3')} />
                            <div className="font-12px margin-left-25px height-25px validation-color">
                                {state.addressValidationMsg.field == "address3" ? state.addressValidationMsg.msg : ""}
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-sm-4 col-xs-12' style={{ marginTop: '20px' }}>
                            <label>City</label>
                            <input placeholder='City' className='form-control' value={state.addressData['city']} onChange={(e) => onChangeHandlerAddress(e, 'city')} />
                            <div className="font-12px margin-left-25px height-25px validation-color">
                                {state.addressValidationMsg.field == "city" ? state.addressValidationMsg.msg : ""}
                            </div>
                        </div>
                        <div className='col-sm-4 col-xs-12' style={{ marginTop: '20px' }}>
                            <label>State</label>
                            <input placeholder='State' className='form-control' value={state.addressData['state']} onChange={(e) => onChangeHandlerAddress(e, 'state')} />
                            <div className="font-12px margin-left-25px height-25px validation-color">
                                {state.addressValidationMsg.field == "state" ? state.addressValidationMsg.msg : ""}
                            </div>
                        </div>
                        <div className='col-sm-4 col-xs-12' style={{ marginTop: '20px' }}>
                            <label>Pincode</label>
                            <input placeholder='Pincode' className='form-control' value={state.addressData['pinCode']} onChange={(e) => onChangeHandlerAddress(e, 'pinCode')} />
                            <div className="font-12px margin-left-25px height-25px validation-color">
                                {state.addressValidationMsg.field == "pinCode" ? state.addressValidationMsg.msg : ""}
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-sm-4 col-xs-12' style={{ marginTop: '20px' }}>
                            <label>Delivery Address 1</label>
                            <input placeholder='Delivery Address 1' className='form-control' value={state.addressData['deliveryAddress1']} onChange={(e) => onChangeHandlerAddress(e, 'deliveryAddress1')} />
                            <div className="font-12px margin-left-25px height-25px validation-color">
                                {state.addressValidationMsg.field == "deliveryAddress1" ? state.addressValidationMsg.msg : ""}
                            </div>
                        </div>
                        <div className='col-sm-4 col-xs-12' style={{ marginTop: '20px' }}>
                            <label>Delivery Address 2</label>
                            <input placeholder='Delivery Address 2' className='form-control' value={state.addressData['deliveryAddress2']} onChange={(e) => onChangeHandlerAddress(e, 'deliveryAddress2')} />
                            <div className="font-12px margin-left-25px height-25px validation-color">
                                {state.addressValidationMsg.field == "deliveryAddress2" ? state.addressValidationMsg.msg : ""}
                            </div>
                        </div>
                        <div className='col-sm-4 col-xs-12' style={{ marginTop: '20px' }}>
                            <label>Delivery Address 3</label>
                            <input placeholder='Delivery Address 3' className='form-control' value={state.addressData['deliveryAddress3']} onChange={(e) => onChangeHandlerAddress(e, 'deliveryAddress3')} />
                            <div className="font-12px margin-left-25px height-25px validation-color">
                                {state.addressValidationMsg.field == "deliveryAddress3" ? state.addressValidationMsg.msg : ""}
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-sm-4 col-xs-12' style={{ marginTop: '20px' }}>
                            <label>Delivery City</label>
                            <input placeholder='Delivery City' className='form-control' value={state.addressData['deliveryCity']} onChange={(e) => onChangeHandlerAddress(e, 'deliveryCity')} />
                            <div className="font-12px margin-left-25px height-25px validation-color">
                                {state.addressValidationMsg.field == "deliveryCity" ? state.addressValidationMsg.msg : ""}
                            </div>
                        </div>
                        <div className='col-sm-4 col-xs-12' style={{ marginTop: '20px' }}>
                            <label>Delivery State</label>
                            <input placeholder='deliveryState' className='form-control' value={state.addressData['deliveryState']} onChange={(e) => onChangeHandlerAddress(e, 'deliveryState')} />
                            <div className="font-12px margin-left-25px height-25px validation-color">
                                {state.addressValidationMsg.field == "deliveryState" ? state.addressValidationMsg.msg : ""}
                            </div>
                        </div>
                        <div className='col-sm-4 col-xs-12' style={{ marginTop: '20px' }}>
                            <label>Delivery PinCode</label>
                            <input placeholder='deliveryPinCode' className='form-control' value={state.addressData['deliveryPinCode']} onChange={(e) => onChangeHandlerAddress(e, 'deliveryPinCode')} />
                            <div className="font-12px margin-left-25px height-25px validation-color">
                                {state.addressValidationMsg.field == "deliveryPinCode" ? state.addressValidationMsg.msg : ""}
                            </div>
                        </div>
                    </div>
                    <div className='row'>


                        <div className='col-sm-12 col-xs-12 text-center' style={{ marginTop: '20px' }}>
                            <button className='profile-save-btn' onClick={(e) => saveHandler(state.addressData, 'address')}>Save</button>
                        </div>
                    </div>
                    <hr />
                    <h3>Edit Bank Details</h3>
                    <div className='row'>
                        <div className='col-sm-4 col-xs-12'>
                            <label>IFSC Code</label>
                            <input className='form-control' placeholder='IFSC code' value={state.bankData['ifscCode']} onChange={(e) => onChangeHandlerBank(e, 'ifscCode')} />
                            <div className="font-12px margin-left-25px height-25px validation-color">
                                {state.bankValidationMsg.field == "ifscCode" ? state.bankValidationMsg.msg : ""}
                            </div>
                        </div>
                        <div className='col-sm-4 col-xs-12'>
                            <label>Account Number</label>
                            <input className='form-control' placeholder='Account Number' value={state.bankData['accountNo']} onChange={(e) => onChangeHandlerBank(e, 'accountNo')} />
                            <div className="font-12px margin-left-25px height-25px validation-color">
                                {state.bankValidationMsg.field == "accountNo" ? state.bankValidationMsg.msg : ""}
                            </div>
                        </div>

                        <div className='col-sm-4 col-xs-12'>
                            <label>Name On Bank</label>
                            <input className='form-control' placeholder='Name on bank' value={state.bankData['nameOnBank']} onChange={(e) => onChangeHandlerBank(e, 'nameOnBank')} />
                            <div className="font-12px margin-left-25px height-25px validation-color">
                                {state.bankValidationMsg.field == "nameOnBank" ? state.bankValidationMsg.msg : ""}
                            </div>
                        </div>
                        <div className='col-sm-4 col-xs-12'>
                            <label>Bank Name</label>
                            <input className='form-control' id='bankName' placeholder='Bank Name' value={state.bankData['bankName']} onChange={(e) => onChangeHandlerBank(e, 'bankName')} />
                            <div className="font-12px margin-left-25px height-25px validation-color">
                                {state.bankValidationMsg.field == "bankName" ? state.bankValidationMsg.msg : ""}
                            </div>
                        </div>
                        <div className='col-sm-4 col-xs-12'>
                            <label>Branch</label>
                            <input  className='form-control' id='branch' placeholder='Branch' value={state.bankData['branch']} onChange={(e) => onChangeHandlerBank(e, 'branch')} />
                            <div className="font-12px margin-left-25px height-25px validation-color">
                                {state.bankValidationMsg.field == "branch" ? state.bankValidationMsg.msg : ""}
                            </div>
                        </div>


                    </div>
                    <div className='row text-center' style={{ marginBottom: '20px' }}>
                        <button className='profile-save-btn' onClick={(e) => saveHandler(state.bankData, 'bank')}>Save</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Edit