import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import NachReportUi from '../../../presentationals/Admin/Recovery/NachReport.component';
import {
    nachReportDataApi, sendWhatsappMsgesApi,
    mandateVarificationApi, sendMailToNachFailApi, nachCommentApi
} from '../AdminActionCreator.component';
import { STRING, emandateEsignMsg } from '../../../../utils/Constant'
import { _preFormatDate } from '../../../../utils/validation'
import Popup from '../../../presentationals/Popup/Popup.component';
import { WEB_URL } from '../../../../utils/api-factory';
import moment from 'moment'

let paginationNumber = 10

class DueContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            paymentId: '',
            commentState: '',
            popupState: false,
            popupStatus: '',
            recoveryPageState: true,
            allDetail: "",
            userDetail: "",
            followUpStatus: STRING.SELECT_ALL,
            forwordPageState: 1,
            loadarState: false,
            viewPopupDetail: '',
            logsUserId: '',
            dateToShow: '',
            dateToSend: '',
            actionButtonState: false,
            loadarState: false,
            dataForCommunication: '',
            chequeNoticeData: '',
            chequeNoticeType: '',
            searchByAppNo: '',
            searchByemail: '',
            searchBymobile: '',
            disburseLoanId: '',
            tableMinPagination: 0,
            tableMaxPagination: paginationNumber,
            commentState: '',
            nachUserDetail: [],
            nachType: 'success',
            mainLoaderState: false,
            rajorPayMail: 'razorPay',
            techProcessMail: 'techProcess',
            selectedFilter: '',
            applicationId: ''

        }
    }

    componentWillMount() {
        this.nachReport()
    }
    searchHandler(e) {
        if ((e.target.value).length > 6) {
            this.setState({ loadarState: true })
            nachReportDataApi(this.state.forwordPageState, this.state.dateToSend, this.state.nachType, e.target.value,
                (callBack) => {
                    // console.log(callBack)
                    this.setState({
                        nachUserDetail: callBack,
                        loadarState: false
                    })
                })
        }
        if ((e.target.value) === '') {
            this.setState({ loadarState: true })
            nachReportDataApi(this.state.forwordPageState, this.state.dateToSend, this.state.nachType, e.target.value,
                (callBack) => {
                    // console.log(callBack)
                    this.setState({
                        nachUserDetail: callBack,
                        loadarState: false
                    })
                })
        }
        // this.setState({ applicationId: e.target.value }, () => this.nachReport())
    }

    nachReport() {
        this.setState({
            loadarState: true
        })
        nachReportDataApi(this.state.forwordPageState, this.state.dateToSend, this.state.nachType, this.state.applicationId,
            (callBack) => {
                // console.log(callBack)
                this.setState({
                    nachUserDetail: callBack,
                    loadarState: false
                })
            })
    }
    finterChangeHandler(e) {
        this.setState({ selectedFilter: e.target.value })
    }
    render() {
        let filterData = this.state.nachUserDetail
        if (this.state.selectedFilter && this.state.selectedFilter != '' && filterData != null && filterData != '' && filterData.length > 0) {
            filterData = filterData.filter((row) => {
                console.l
                if (row.applicationId != null) {
                    return row.applicationId.toLowerCase().includes(this.state.selectedFilter.toLowerCase())
                }
            })
        }
        const { admin } = this.props;
        return (
            <div className="full-height">
                <div className="edit-profile-menu col-xs-12">
                    <div className="dashboard-tabs">
                        <button className={this.state.nachType == 'success' ? 'active' : ''} onClick={e => this.buttonActionHandler("success")}>Success</button>
                        <button className={this.state.nachType == 'ongoing' ? 'active' : ''} onClick={e => this.buttonActionHandler("ongoing")}>Ongoing</button>
                        <button className={this.state.nachType == 'failure' ? 'active' : ''} onClick={e => this.buttonActionHandler("failure")}>Failure</button>
                    </div>
                </div>
                {this.state.popupState == true ?
                    <Popup
                        closePopup={this.closePopup.bind(this)}
                        popupStatus={this.state.popupStatus}
                    />
                    : ""}
                {this.state.recoveryPageState == true ?
                    <NachReportUi
                        searchHandler={this.searchHandler.bind(this)}
                        finterChangeHandler={this.finterChangeHandler.bind(this)}
                        CommentChange={this.CommentChange.bind(this)}
                        CommentHandler={this.CommentHandler.bind(this)}
                        dataFilter={this.dataFilter.bind(this)}
                        tableMaxPagination={this.state.tableMaxPagination}
                        tableMinPagination={this.state.tableMinPagination}
                        forwordpage={this.forwordpage.bind(this)}
                        backpage={this.backpage.bind(this)}
                        allUserDetail={filterData}
                        loadarState={this.state.loadarState}
                        refreshpage={this.refreshpage.bind(this)}
                        searchByAppNo={this.state.searchByAppNo}
                        searchByemail={this.state.searchByemail}
                        searchBymobile={this.state.searchBymobile}
                        phocketTablePagination={this.phocketTablePagination.bind(this)}
                        dateToShow={this.state.dateToShow}
                        dateToSend={this.state.dateToSend}
                        nachType={this.state.nachType}
                        sendNachMail={this.sendNachMail.bind(this)}
                        mainLoaderState={this.state.mainLoaderState}
                        mendateVerification={this.mendateVerification.bind(this)}
                    /> : ""}
            </div>
        )
    }

    sendNachMail(data, type) {
        const { admin } = this.props
        this.setState({ mainLoaderState: true })
        let userName = data != null && data.firstName != null ? data.firstName : ""
        let eMandateLink = data != null ? data.loanId != null ? WEB_URL + "enach/" + data.loanId : "" : ""
        let writeWhatsappMsg = 'Dear ' + userName + '\n\n' + emandateEsignMsg.HopeLine + '\n\n' + emandateEsignMsg.mendateRejected + '\n\n' + emandateEsignMsg.continueNach + '\n\n' + emandateEsignMsg.salaryRecord + '\n\n' + emandateEsignMsg.cooperation + '\n\n' + emandateEsignMsg.clickNach + eMandateLink + '\n\n' + emandateEsignMsg.footer
        if (type == 'razorPay') {
            sendMailToNachFailApi(data, this.state.rajorPayMail, (callBack) => {
                if (callBack == 'success') {
                    sendWhatsappMsgesApi(data, writeWhatsappMsg, admin, 'communication',
                        (callBack) => {
                            if (callBack == 'success') {
                                this.setState({
                                    mainLoaderState: false,
                                    popupState: true,
                                    popupStatus: 'Send successfully',
                                })
                                this.removePopup();
                            } else {
                                this.setState({
                                    mainLoaderState: false,
                                    popupState: true,
                                    popupStatus: 'Mail sent but Whatsapp not send',
                                })
                                this.removePopup();
                            }
                        })
                } else {
                    this.setState({
                        mainLoaderState: false,
                        popupState: true,
                        popupStatus: 'Not sent',
                    })
                    this.removePopup();
                }
            })
        } else if (type == "techProcess") {

            sendMailToNachFailApi(data, this.state.techProcessMail, (callBack) => {
                if (callBack == 'success') {
                    sendWhatsappMsgesApi(data, writeWhatsappMsg, admin, 'communication',
                        (callBack) => {
                            if (callBack == 'success') {
                                this.setState({
                                    mainLoaderState: false,
                                    popupState: true,
                                    popupStatus: 'Send successfully',
                                })
                                this.removePopup();
                            } else {
                                this.setState({
                                    mainLoaderState: false,
                                    popupState: true,
                                    popupStatus: 'Mail sent but Whatsapp not send',
                                })
                                this.removePopup();
                            }
                        })
                } else {
                    this.setState({
                        mainLoaderState: false,
                        popupState: true,
                        popupStatus: 'Not sent',
                    })
                    this.removePopup();
                }
            })
        }
    }

    mendateVerification(data, type) {
        if (type == 'rajzorPay') {
            mandateVarificationApi(data.userId, "", this.state.rajorPayMail,
                (callBack) => {
                    if (callBack == 'success') {
                        this.setState({
                            popupState: true,
                            popupStatus: 'Verified Successfully'
                        })
                        this.removePopup();
                    } else {
                        this.setState({
                            popupState: true,
                            popupStatus: 'Still not captured by bank'
                        })
                        this.removePopup();
                    }
                })
        } else if (type == 'techprocess') {
            mandateVarificationApi(data.userId, data.loanId, this.state.techProcessMail,
                (callBack) => {
                    if (callBack == 'success') {
                        this.setState({
                            popupState: true,
                            popupStatus: 'Verified Successfully'
                        })
                        this.removePopup();
                    } else {
                        this.setState({
                            popupState: true,
                            popupStatus: 'Still not captured by bank'
                        })
                        this.removePopup();
                    }
                })
        }
    }

    buttonActionHandler(type) {
        this.setState({
            nachType: type,
            nachUserDetail: [],
            tableMinPagination: 0,
            tableMaxPagination: paginationNumber,
        }, () => this.nachReport())
    }

    phocketTablePagination(data, type) {
        if (type == 'back') {
            if (this.state.tableMinPagination >= paginationNumber) {
                this.setState({
                    tableMinPagination: this.state.tableMinPagination - paginationNumber,
                    tableMaxPagination: this.state.tableMaxPagination - paginationNumber,
                })
            }
        } else if (type == 'forward') {
            if (this.state.tableMaxPagination < data.length) {
                this.setState({
                    tableMaxPagination: this.state.tableMaxPagination + paginationNumber,
                    tableMinPagination: this.state.tableMinPagination + paginationNumber
                })
            }
        }
    }

    removePopup() {
        setTimeout(function () {
            this.setState({ popupState: false });
        }.bind(this), 5000)
    }

    closePopup() {
        this.setState({ popupState: false });
    }

    recoveryComunicateHandler(data) {
        this.setState({
            recoveryPageState: false,
            communicationPageState: true,
            dataForCommunication: data
        })
    }

    refreshpage() {
        this.nachReport()
    }

    dataFilter(e, type) {
        if (type == 'date') {
            this.setState({
                dateToShow: e,
                dateToSend: moment(e).format("DD-MM-YYYY"),
                nachUserDetail: [],
            }, () => this.nachReport())
        }
    }

    forwordpage() {
        this.setState({
            forwordPageState: this.state.forwordPageState + 1,
        }, () => this.nachReport())
    }

    backpage() {
        if (this.state.forwordPageState > 1) {
            this.setState({
                forwordPageState: this.state.forwordPageState - 1,
            }, () => this.nachReport())
        }
    }
    CommentChange(e) {
        this.setState({ paymentId: e.target.value })
    }

    CommentHandler(e) {
        const { admin } = this.props
        nachCommentApi(this.state.paymentId, e, (callBack) => {
            if (callBack == 'success') {
                this.setState({
                    popupState: true,
                    popupStatus: 'Saved Successfully',
                })
                this.removePopup()
            } else {
                this.setState({
                    popupState: true,
                    popupStatus: 'please try again'
                })
                this.removePopup()
            }
        })
    }

}

const mapStateToProps = ({ }) => {
    return {

    };
};

const mapDispatchToProps = dispatch => {
    return bindActionCreators({

    }, dispatch);
};


export default connect(mapStateToProps, mapDispatchToProps)(DueContainer);