import React, { useState } from 'react'
import moment from "moment";
import { PieChart, Pie, Cell, ComposedChart, Scatter, BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Label } from 'recharts';
const getMonth = (type) => {
    const date = new Date();
    const month = date.getMonth()
    const year = date.getFullYear()
    let newmonth = Number(month);
    if (type == 'current') {
        newmonth = ++newmonth;
    }
    const currentMonth = `${year}-${newmonth < 10 ? '0' + newmonth : newmonth}`
    return currentMonth

}

export default function KPIDebtManagementDashboard() {
    const [fromDate, setFromDate] = useState(getMonth('prev'))
    const [toDate, setToDate] = useState(getMonth('current'))
    const [nbfcFilter,setnbfcFilter] =useState('all')

    const [data, setdata] = useState(
        {
            "currentBankBalance": 457000,
            "totalAmountDue": 567802,
            "totalSuppliers": 7,
            "overdueSuppliers": 7,
            "overdueAmountBySupplier": 45678,
            "creditorDays": 23,
            "creditorTarget": '36-3',
            "creditorTargetPercentage": '+4.85',
            "bankBalanceAmountData": [{
                Balance: 40000,
                Due: 24000,
            }],
            "invoicedPaidData":[
                {
                    name: 'January',
                    Invoiced: 4000,
                    Paid: 4500,
                    creditorDays: 200,
                },
                {
                    name: 'February',
                    Invoiced: 3000,
                    Paid: 3200,
                    creditorDays: 9200,
                },
                {
                    name: 'March',
                    Invoiced: 2000,
                    Paid: 2100,
                    creditorDays: 1220,
                },
                {
                    name: 'April',
                    Invoiced: 2780,
                    Paid: 2900,
                    creditorDays: 2200,
                },
                {
                    name: 'May',
                    Invoiced: 1890,
                    Paid: 2000,
                    creditorDays: 100,
                },
                {
                    name: 'June',
                    Invoiced: 2390,
                    Paid: 2600,
                    creditorDays: 3200,
                },
                {
                    name: 'July',
                    Invoiced: 3490,
                    Paid: 3600,
                    creditorDays: 4200,
                },
                {
                    name: 'August',
                    Invoiced: 2000,
                    Paid: 2200,
                    creditorDays: 2200,
                },
                {
                    name: 'September',
                    Invoiced: 2780,
                    Paid: 2900,
                    creditorDays: 500,
                },
                {
                    name: 'October',
                    Invoiced: 1890,
                    Paid: 2000,
                    creditorDays: 1000,
                },
                {
                    name: 'November',
                    Invoiced: 2390,
                    Paid: 2500,
                    creditorDays: 1400,
                },
                {
                    name: 'December',
                    Invoiced: 3490,
                    Paid: 3700,
                    creditorDays: 1100,
                },
            ],
            "creditorsData": [
                {
                    name: 'creditorName',
                    due: 1200,
                    overdue: 1500,
                },
                {
                    name: 'creditorName',
                    due: 1400,
                    overdue: 2500,
                }
            ],
            "dueOverdueCreditorData":{
                due:4567,
               overdue:56789,
               amount:567,
               totalAmount:5678
            },
            "ageSummary":[
                {
                    name: '120 Days',
                    due: 1200,
                    overdue: 1500,
                }
            ]
        }
    )
    const handleChange = (e) => {
        console.log(e.target.value)
        if (e.target.name === "fromDate") {
            setFromDate(e.target.value)
        } else if(e.target.name === "toDate") {
            setToDate(e.target.value)
        }else{
            setnbfcFilter(e.target.value)
        }
    };


    const BankBalanceamountDue = [
        {
            name: '',
            Balance: 4000,
            Due: 2400,
            amt: 2400,
        },

    ];
    const InvoicedPaid = [
        {
            name: 'January',
            Invoiced: 4000,
            Paid: 4500,
            creditorDays: 200,
            amt: 2400,
        },
        {
            name: 'February',
            Invoiced: 3000,
            Paid: 3200,
            creditorDays: 9200,
            amt: 2210,
        },
        {
            name: 'March',
            Invoiced: 2000,
            Paid: 2100,
            creditorDays: 1220,
            amt: 2290,
        },
        {
            name: 'April',
            Invoiced: 2780,
            Paid: 2900,
            creditorDays: 2200,
            amt: 2000,
        },
        {
            name: 'May',
            Invoiced: 1890,
            Paid: 2000,
            creditorDays: 100,
            amt: 2181,
        },
        {
            name: 'June',
            Invoiced: 2390,
            Paid: 2600,
            creditorDays: 3200,
            amt: 2500,
        },
        {
            name: 'July',
            Invoiced: 3490,
            Paid: 3600,
            creditorDays: 4200,
            amt: 2100,
        },
        {
            name: 'August',
            Invoiced: 2000,
            Paid: 2200,
            creditorDays: 2200,
            amt: 2300,
        },
        {
            name: 'September',
            Invoiced: 2780,
            Paid: 2900,
            creditorDays: 500,
            amt: 2000,
        },
        {
            name: 'October',
            Invoiced: 1890,
            Paid: 2000,
            creditorDays: 1000,
            amt: 2181,
        },
        {
            name: 'November',
            Invoiced: 2390,
            Paid: 2500,
            creditorDays: 1400,
            amt: 2500,
        },
        {
            name: 'December',
            Invoiced: 3490,
            Paid: 3700,
            creditorDays: 1100,
            amt: 2100,
        },
    ];

    const creditorsData = [
        {
            name: 'Tax',
            due: 1200,
            overdue: 1500,
            amt: 1200
        },
        {
            name: 'Leave Creative',
            due: 1400,
            overdue: 2500,
            amt: 1200
        },
        {
            name: 'Rastor Bike comp',
            due: 1600,
            overdue: 4500,
            amt: 1230
        },
        {
            name: 'Cylearlope',
            due: 2600,
            overdue: 5500,
            amt: 1230
        },
        {
            name: 'Cyleardlope',
            due: 2600,
            overdue: 5500,
            amt: 1230
        }
    ];
    const AgesummaryData = [
        {
            name: '120 Days',
            due: 1200,
            overdue: 1500,
            amt: 1200
        }
    ];

    const OverdueDueCreditorsData = [
        { name: 'A', value: 80, color: '#232F63' },
        { name: 'B', value: 45, color: '#DBE9FFB2' },
    ];
    return (
        <>
            <div className="KPIDebtManagementDashboard">
                <div className="MonthDateFilter" style={{ marginTop: '20px' }}>
                    <div className="row">
                        <div className="col-lg-2 col-md-3 col-xs-12">
                            <div className="inputGrp">
                                <label htmlFor="from" className='labeltag'>From</label>
                                <input type="month" id='from' className='MonthinputTag' name="fromDate" value={fromDate} onChange={handleChange} />
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-3 col-xs-12">
                            <div className="inputGrp">
                                <label htmlFor="from" className='labeltag'>To</label>
                                <input type="month" min={fromDate} id='from' className='MonthinputTag' name="toDate" value={toDate} onChange={handleChange} />
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-3 col-xs-12">
                            <button className='FilterApply'>Apply Filter</button>
                        </div>
                        <div className="col-lg-2 col-md-3 col-xs-12" style={{float:'inline-end'}}>
                            <div className="inputGrp">
                                <label htmlFor="nbfc" className='labeltag'>NBFC</label>
                                <select className='MonthinputTag' id="nbfc" name="nbfc" value={nbfcFilter} onChange={handleChange} style={{width:'100%'}} >
                                    <option value="all">ALL</option>
                                    <option value="yashikfinlease">Yashik Finlease</option>
                                    {/* <option value="citra">Citra</option> */}
                                </select>
                                 </div>
                        </div>
                    </div>
                </div>

                <div className="DebtDashboardBlock">
                    <div className="row">
                        <div className="col-lg-5 ">
                            <div className="row">
                                <div className="col-md-6 col-xs-12 newPadding">
                                    <div className="Datacard">
                                        <div className="Topsection">
                                            <h4>Current Loan Amount</h4>
                                        </div>
                                        <div className="bottomsection" style={{ padding: '10px' }}>
                                            <h5>457.24K</h5>
                                        </div>
                                    </div>
                                    <div className="Datacard">
                                        <div className="Topsection">
                                            <h4>Total Amount OverDue</h4>
                                        </div>
                                        <div className="bottomsection" style={{ padding: '10px' }}>
                                            <h5>457.24K</h5>
                                        </div>
                                    </div>
                                    <div className="Datacard" style={{ height: '165px' }}>
                                        <div className="Topsection">
                                            <h4>Overdue Suppliers</h4>
                                        </div>
                                        <div className="bottomsection" style={{ padding: '10px' }}>
                                            <h5 style={{ marginBottom: '20px' }}><b className='fs-inc'>7</b> out of <b className='fs-inc'>7</b></h5>
                                            <h5 className='fs-inc'><b>457.24K</b></h5>
                                        </div>
                                    </div>
                                    {/* <div className="Datacard" style={{ height: '147px' }}>
                                        <div className="Topsection">
                                            <h4>Creditor Days vs Target</h4>
                                        </div>
                                        <div className="bottomsection" style={{ padding: '10px' }}>
                                            <h5 className='fs-inc'><b>34</b></h5>
                                            <h5>Goal 36-3 (+ 4.85%)</h5>
                                        </div>
                                    </div> */}
                                </div>
                                <div className="col-md-6 col-xs-12 newPadding">
                                    
                                    <div className="Datacard">
                                        <div className="Topsection">
                                            <h4>Loan vs OverDue Amount</h4>
                                        </div>
                                        <div className="bottomsection" style={{ padding: '10px' }}>
                                            <div className="charts" style={{ height: '316px' }}>
                                                <ResponsiveContainer width="100%" height="100%">
                                                    <BarChart
                                                        width={500}
                                                        height={300}
                                                        data={BankBalanceamountDue}
                                                        barSize={60}
                                                        margin={{
                                                            top: 20,
                                                            right: 30,
                                                            left: 20,
                                                            bottom: 5,
                                                        }}
                                                    >
                                                        <CartesianGrid strokeDasharray="3 3" />
                                                        <XAxis dataKey="name" />
                                                        <YAxis />
                                                        <Tooltip />
                                                        <Bar dataKey="Balance" stackId="a" fill="#EE7F41" label={<BarchartCustomLabel />} />
                                                        <Bar dataKey="Due" stackId="a" fill="#4481d5" label={<BarchartCustomLabel />} />
                                                    </BarChart>
                                                </ResponsiveContainer>
                                            </div>
                                            <div className="customLegend">
                                                <div className='legendbox'>
                                                    <div className="square" style={{ backgroundColor: '#EE7F41' }}></div>
                                                    <h6>Balance</h6>
                                                </div>
                                                <div className='legendbox'>
                                                    <div className="square" style={{ backgroundColor: '#4481d5' }}></div>
                                                    <h6>Due</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-7 newPadding">
                            <div className="Datacard">
                                <div className="Topsection">
                                    <h4>Invoiced vs Paid</h4>
                                </div>
                                <div className="bottomsection" style={{ padding: '10px' }}>
                                    <div className="charts" style={{ height: '316px' }}>
                                        <ResponsiveContainer width="100%" height="100%">
                                            <ComposedChart
                                                width={500}
                                                height={300}
                                                data={InvoicedPaid}
                                                barSize={20}
                                                margin={{
                                                    top: 20,
                                                    right: 0,
                                                    left: 0,
                                                    bottom: 5,
                                                }}
                                            >
                                                <CartesianGrid vertical={false} />
                                                <XAxis dataKey="name" />
                                                <YAxis yAxisId="left" orientation="left" />
                                                <YAxis yAxisId="right" orientation="right" />
                                                <Tooltip />
                                                <Bar yAxisId="left" dataKey="Invoiced" fill="#EE7F41" />
                                                <Bar yAxisId="right" dataKey="Paid" fill="#4481d5" />
                                                <Scatter yAxisId="left" dataKey="creditorDays" fill="#223d64" strokeWidth={2} line shape="dot" />
                                            </ComposedChart>
                                        </ResponsiveContainer>
                                    </div>
                                    <div className="customLegend" style={{ flexWrap: 'wrap' }}>
                                        <div className='legendbox'>
                                            <div className="square newone" style={{ backgroundColor: '#EE7F41' }}></div>
                                            <h6>Invoiced</h6>
                                        </div>
                                        <div className='legendbox'>
                                            <div className="square newone" style={{ backgroundColor: '#4481d5' }}></div>
                                            <h6>Paid</h6>
                                        </div>
                                        <div className='legendbox'>
                                            <div className="square newone" style={{ backgroundColor: '#223d64' }}></div>
                                            <h6>Average Creditor Days</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 col-md-6 col-xs-12 newPadding">
                            <div className="Datacard">
                                <div className="Topsection">
                                    <h4>Creditors As Per Product</h4>
                                </div>
                                <div className="bottomsection" style={{ padding: '10px' }}>
                                    <div className="charts" style={{ height: '351px' }}>
                                        <ResponsiveContainer width="100%" height="100%">
                                            <BarChart
                                                width={560}
                                                height={300}
                                                data={creditorsData}
                                                barSize={20}
                                                layout="vertical"
                                                margin={{
                                                    top: 20, right: 10, left: 10, bottom: 10,
                                                }}
                                            >
                                                <CartesianGrid vertical={false} horizontal={false} />
                                                <XAxis type="number" />
                                                <YAxis type="category" dataKey="name" />
                                                <Tooltip />
                                                <Bar type="monotone" dataKey="due" fill="#EE7F41" />
                                                <Bar type="monotone" dataKey="overdue" fill="#4481d5" />
                                            </BarChart>
                                        </ResponsiveContainer>
                                    </div>
                                    <div className="customLegend">
                                        <div className='legendbox'>
                                            <div className="square" style={{ backgroundColor: '#EE7F41' }}></div>
                                            <h6>Due</h6>
                                        </div>
                                        <div className='legendbox'>
                                            <div className="square" style={{ backgroundColor: '#4481d5' }}></div>
                                            <h6>Overdue</h6>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-xs-12 newPadding">
                            <div className="Datacard">
                                <div className="Topsection">
                                    <h4>Overdue & Due Creditors</h4>
                                </div>
                                <div className="bottomsection" style={{ padding: '10px' }}>
                                    <div className="charts" style={{ height: '389px' }}>
                                        <ResponsiveContainer width="100%" height="100%">
                                            <PieChart width={400} height={400}>
                                                <Pie
                                                    dataKey="value"
                                                    startAngle={180}
                                                    endAngle={0}
                                                    cy="60%"
                                                    cornerRadius={3}
                                                    data={OverdueDueCreditorsData}
                                                    width={560}
                                                    height={300}
                                                    innerRadius={100}
                                                    outerRadius={150}
                                                    fill="#0e5768"
                                                    stroke="none"

                                                >
                                                    {OverdueDueCreditorsData.map((entry, index) => (
                                                        <Cell key={`cell-${index}`} fill={entry.color} />
                                                    ))}
                                                </Pie>
                                                <text
                                                    x="50%"
                                                    y='40%'
                                                    textAnchor="middle"
                                                    dominantBaseline="middle"
                                                    fill="#000"
                                                    style={{ fontSize: '18px', fontWeight: 'bold' }}
                                                >
                                                    471K
                                                </text>
                                                <text
                                                    x="50%"
                                                    y='46%'
                                                    textAnchor="middle"
                                                    dominantBaseline="middle"
                                                    fill="#000"
                                                    style={{ fontSize: '16px' }}
                                                >
                                                    Due
                                                </text>
                                                <text
                                                    x="50%"
                                                    y='54%'
                                                    textAnchor="middle"
                                                    dominantBaseline="middle"
                                                    fill="#000"
                                                    style={{ fontSize: '18px', fontWeight: 'bold' }}
                                                >
                                                    471K
                                                </text>
                                                <text
                                                    x="50%"
                                                    y='60%'
                                                    textAnchor="middle"
                                                    dominantBaseline="middle"
                                                    fill="#000"
                                                    style={{ fontSize: '16px' }}
                                                >
                                                    Overdue
                                                </text>

                                            </PieChart>
                                        </ResponsiveContainer>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-xs-12 newPadding">
                            <div className="Datacard">
                                <div className="Topsection">
                                    <h4>Age Summary</h4>
                                </div>
                                <div className="bottomsection" style={{ padding: '10px' }}>
                                    <div className="charts" style={{ height: '351px' }}>
                                        <ResponsiveContainer width="100%" height="100%">
                                            <BarChart
                                                width={560}
                                                height={300}
                                                data={AgesummaryData}
                                                barSize={20}
                                                layout="vertical"
                                                margin={{
                                                    top: 20, right: 10, left: 10, bottom: 10,
                                                }}
                                            >
                                                <CartesianGrid vertical={false} horizontal={false} />
                                                <XAxis type="number" />
                                                <YAxis type="category" dataKey="name" />
                                                <Tooltip />
                                                <Bar type="monotone" dataKey="due" fill="#EE7F41" />
                                                <Bar type="monotone" dataKey="overdue" fill="#4481d5" />
                                            </BarChart>
                                        </ResponsiveContainer>
                                    </div>
                                    <div className="customLegend">
                                        <div className='legendbox'>
                                            <div className="square" style={{ backgroundColor: '#EE7F41' }}></div>
                                            <h6>Due</h6>
                                        </div>
                                        <div className='legendbox'>
                                            <div className="square" style={{ backgroundColor: '#4481d5' }}></div>
                                            <h6>Overdue</h6>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}


const BarchartCustomLabel = (props) => {
    const { x, y, width, height, value } = props;
    return (
        <text x={x + width / 2} y={y + height / 2} fill="white" textAnchor="middle" dominantBaseline="central">
            {value}
        </text>
    );
};
