import React, { useState } from 'react'
import moment from "moment";
import TablePopup from './TablePopup';
import {
    XAxis, YAxis, CartesianGrid, Tooltip, LineChart, Line, ResponsiveContainer, BarChart, Bar, PieChart, Pie, Cell, Label, ScatterChart,
    Scatter
} from 'recharts';
export default function KPILoanManagementDashboard() {

    const [tablePopupData,setTablePopupData] = useState([]);
    const [tablePopup,setTablePopup] = useState(false);
    const [nbfcFilter, setnbfcFilter] = useState('all')
    const [data, setData] = useState({
        existingCustomers: 567,
        ongoingLoans: 678,
        paymentRecivedToday: 567,
        overduePayment: 678,
        loanTrend: {
            loanAmountOverTime: 456789,
            data: [
                {
                    name: 'last 30-Days',
                    value: 4000,
                },
                {
                    name: 'last 30-Days',
                    value: 3000,
                },
                {
                    name: 'last 30-Days',
                    value: 2000,
                },
                {
                    name: 'last 30-Days',
                    value: 2780,
                },
                {
                    name: 'last 30-Days',
                    value: 1890,
                }
            ]
        },
        yearWiseComparison: {
            loanAmountOverTime: 456789,
            sinceLastYear: '+112',
            data: [
                {
                    name: 'Month-Name',
                    LastYear: 4000,
                    ThisYear: 4500,
                },
                {
                    name: 'Month-Name',
                    LastYear: 3000,
                    ThisYear: 3200,
                },
                {
                    name: 'Month-Name',
                    LastYear: 2000,
                    ThisYear: 2100,
                },
                {
                    name: 'Month-Name',
                    LastYear: 2780,
                    ThisYear: 2900,
                }
            ]
        },
        paymentDueNext15Days: [
            {
                "referenceNumber": "#4567890",
                "name": 'dsfgh',
                "date": 'DD-MM-YYYY',
                "amount": '150.00',
            },
            {
                "referenceNumber": "#4567890",
                "name": 'dsfgh',
                "date": 'DD-MM-YYYY',
                "amount": '150.00',
            }
        ],
        product: {
            highestPersonalLoanPercentage: 56.78,
            data: [
                { name: 'Product_Name', value: 400 },
            ]
        },
        loanStatusOverview: [
            { name: 'Pending', value: 400 },
            { name: 'Approved', value: 300 },
            { name: 'Disbursed', value: 300 },
            { name: 'Rescheduled', value: 300 },
            { name: 'Written Off', value: 300 },
        ]
    })

    const Piecolors = ['#0e5768', '#f24839', '#1eb0d2'];

    const [cardData, setcardData] = useState({
        'Exisiting Customers': '5177',
        'Ongoing Loans': '1404',
        'Today\'s Payment Received': '$243',
        'Overdue Payments': '$30,74,640'
    })

    const [tableData, setTableData] = useState([
        {
            "referenceNumber": "#4567890",
            "name": 'dsfgh',
            "date": '2024-01-31',
            "amount": '150.00',
        },
        {
            "referenceNumber": "#4567890",
            "name": 'dsfgh',
            "date": '2024-01-31',
            "amount": '150.00',
        },
        {
            "referenceNumber": "#4567890",
            "name": 'dsfgh',
            "date": '2024-01-31',
            "amount": '150.00',
        },
        {
            "referenceNumber": "#4567890",
            "name": 'dsfgh',
            "date": '2024-01-31',
            "amount": '150.00',
        },
        {
            "referenceNumber": "#4567890",
            "name": 'dsfgh',
            "date": '2024-01-31',
            "amount": '150.00',
        },
        {
            "referenceNumber": "#4567890",
            "name": 'dsfgh',
            "date": '2024-01-31',
            "amount": '150.00',
        },
        {
            "referenceNumber": "#4567890",
            "name": 'dsfgh',
            "date": '2024-01-31',
            "amount": '150.00',
        },
        {
            "referenceNumber": "#4567890",
            "name": 'dsfgh',
            "date": '2024-01-31',
            "amount": '150.00',
        },
        {
            "referenceNumber": "#4567890",
            "name": 'dsfgh',
            "date": '2024-01-31',
            "amount": '150.00',
        },
    ]);


    const scatterchartdata = [
        {
            name: '1-Dec',
            uv: 4000,
            amt: 2400,
        },
        {
            name: '2-Dec',
            uv: 3000,
            amt: 2210,
        },
        {
            name: '3-Dec',
            uv: 2000,
            amt: 2290,
        },
        {
            name: '4-Dec',
            uv: 2780,
            amt: 2000,
        },
        {
            name: '5-Dec',
            uv: 1890,
            amt: 2181,
        },
        {
            name: '6-Dec',
            uv: 2390,
            amt: 2500,
        },
        {
            name: '7-Dec',
            uv: 3490,
            amt: 2100,
        },
        {
            name: '8-Dec',
            uv: 4000,
            amt: 2400,
        },
        {
            name: '9-Dec',
            uv: 3000,
            amt: 2210,
        },
        {
            name: '10-Dec',
            uv: 2000,
            amt: 2290,
        },
        {
            name: '11-Dec',
            uv: 2780,
            amt: 2000,
        },
        {
            name: '12-Dec',
            uv: 1890,
            amt: 2181,
        },
        {
            name: '13-Dec',
            uv: 2390,
            amt: 2500,
        },
        {
            name: '14-Dec',
            uv: 3490,
            amt: 2100,
        },
        {
            name: '15-Dec',
            uv: 4000,
            amt: 2400,
        },
        {
            name: '16-Dec',
            uv: 3000,
            amt: 2210,
        },
        {
            name: '17-Dec',
            uv: 2000,
            amt: 2290,
        },
        {
            name: '18-Dec',
            uv: 2780,
            amt: 2000,
        },
        {
            name: '19-Dec',
            uv: 1890,
            amt: 2181,
        },
        {
            name: '20-Dec',
            uv: 2390,
            amt: 2500,
        },
        {
            name: '21-Dec',
            uv: 3490,
            amt: 2100,
        },
        {
            name: '22-Dec',
            uv: 4000,
            amt: 2400,
        },
        {
            name: '23-Dec',
            uv: 3000,
            amt: 2210,
        },
        {
            name: '24-Dec',
            uv: 2000,
            amt: 2290,
        },
        {
            name: '25-Dec',
            uv: 2780,
            amt: 2000,
        },
        {
            name: '26-Dec',
            uv: 1890,
            amt: 2181,
        },
        {
            name: '27-Dec',
            uv: 2390,
            amt: 2500,
        },
        {
            name: '28-Dec',
            uv: 3490,
            amt: 2100,
        },
        {
            name: '29-Dec',
            uv: 3490,
            amt: 2100,
        },
        {
            name: '30-Dec',
            uv: 3490,
            amt: 2100,
        },
    ];

    const YearWiseComparisonData = [
        {
            name: 'January',
            LastYear: 4000,
            ThisYear: 4500,
            amt: 2400,
        },
        {
            name: 'February',
            LastYear: 3000,
            ThisYear: 3200,
            amt: 2210,
        },
        {
            name: 'March',
            LastYear: 2000,
            ThisYear: 2100,
            amt: 2290,
        },
        {
            name: 'April',
            LastYear: 2780,
            ThisYear: 2900,
            amt: 2000,
        },
        {
            name: 'May',
            LastYear: 1890,
            ThisYear: 2000,
            amt: 2181,
        },
        {
            name: 'June',
            LastYear: 2390,
            ThisYear: 2600,
            amt: 2500,
        },
        {
            name: 'July',
            LastYear: 3490,
            ThisYear: 3600,
            amt: 2100,
        },
        {
            name: 'August',
            LastYear: 2000,
            ThisYear: 2200,
            amt: 2300,
        },
        {
            name: 'September',
            LastYear: 2780,
            ThisYear: 2900,
            amt: 2000,
        },
        {
            name: 'October',
            LastYear: 1890,
            ThisYear: 2000,
            amt: 2181,
        },
        {
            name: 'November',
            LastYear: 2390,
            ThisYear: 2500,
            amt: 2500,
        },
        {
            name: 'December',
            LastYear: 3490,
            ThisYear: 3700,
            amt: 2100,
        },
    ];

    const ProductPiedata = [
        { name: 'Personal', value: 400 },
    ];

    const LoanStatusPieData = [
        { name: 'Pending', value: 400 },
        { name: 'Approved', value: 300 },
        { name: 'Disbursed', value: 300 },
        { name: 'Rescheduled', value: 300 },
        { name: 'Written Off', value: 300 },
    ];

    const handlePopup =(data)=>{
        setTablePopupData(data)
        setTablePopup(true)
    }
    const closePopup = () => {
        setTablePopup(false)
        setTablePopupData([])
    }
    const handleChange = (e) => {

        setnbfcFilter(e.target.value)

    };

    return (
        <div className="DebtManagementDashboard">
            <div className="MonthDateFilter" style={{marginBottom:'30px'}}>
                    <div className="row">

                        <div className="col-lg-2 col-md-3 col-xs-12">
                            <div className="inputGrp">
                                <label htmlFor="nbfc" className='labeltag'>NBFC</label>
                                <select className='MonthinputTag' id="nbfc" name="nbfc" value={nbfcFilter} onChange={handleChange} style={{ width: '100%' }} >
                                    <option value="all">ALL</option>
                                    <option value="yashikfinlease">Yashik Finlease</option>
                                    {/* <option value="citra">Citra</option> */}
                                </select>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-3 col-xs-12">
                            <button className='FilterApply'>Apply Filter</button>
                        </div>

                    </div>
                </div>
            <div className="LinechartBlock LoanManagementBlock">
                <div className="row">
                    {
                        Object.entries(cardData)?.map(([keys, value], index) => {
                            return (
                                <div className="col-lg-3 col-md-6 col-xs-12 newPadding" key={index}>
                                    <div className="outerBox">
                                        <div className="innerBox">
                                            <div className="HeadingBox" style={{ backgroundColor: index % 2 == 0 ? '#0e5768' : '#4481d5' }}>
                                                <h5>{keys}</h5>
                                            </div>
                                            <div className="Linecharts">
                                                <h4>{value}</h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }

                    <div className="col-md-6 col-xs-12 newPadding">
                        <div className="outerBox">
                            <div className="innerBox" style={{ height: '480px' }}>
                                <div className="HeadingBox WidthInc" style={{ backgroundColor: '#0e5768' }}>
                                    <h5>Loan Trends</h5>
                                </div>
                                <div className="Linecharts">
                                    <h6 style={{ color: '#0e5768' }}>14,000.00</h6>
                                    <h6 className='text'>Loan Amount Over Time</h6>

                                    <div className="charthead Barchart">
                                        <ResponsiveContainer width="100%" height="100%">
                                            <ScatterChart
                                                width={560}
                                                height={300}
                                                data={scatterchartdata}
                                                margin={{
                                                    top: 20, right: 10, left: -5, bottom: 10,
                                                }}
                                            >
                                                <CartesianGrid vertical={false} />
                                                <XAxis dataKey="name" />
                                                <YAxis />
                                                <Tooltip />
                                                <Scatter type="monotone" dataKey="uv" fill="#0e5768" line shape="dot" />
                                            </ScatterChart>
                                        </ResponsiveContainer>
                                    </div>
                                    <div className="Legend Scatterchart">
                                        <div className="square"></div>
                                        <p>Last 30 Days</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-xs-12 newPadding">
                        <div className="outerBox">
                            <div className="innerBox" style={{ height: '480px' }}>
                                <div className="HeadingBox WidthInc" style={{ backgroundColor: '#4481d5' }}>
                                    <h5>Year Wise Comparison</h5>
                                </div>
                                <div className="Linecharts">
                                    <div className="txts" style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <div className="lfttxt">
                                            <h6 style={{ color: '#000' }}>14,000.00</h6>
                                            <h6 className='text'>Loan Amount Over Time</h6>
                                        </div>
                                        <div className="Rttxt">

                                            <h6 style={{ color: '#10d910' }}>
                                                <i class="fa fa-long-arrow-up" aria-hidden="true"></i> &nbsp;
                                                115.43</h6>
                                            {/* <h6 style={{color:'#f24839'}}>
                                            <i class="fa fa-long-arrow-down" aria-hidden="true"></i> &nbsp;
                                                115.43</h6> */}

                                            <h6 className='text'>
                                                Since Last Year
                                            </h6>
                                        </div>
                                    </div>

                                    <div className="charthead Barchart">
                                        <ResponsiveContainer width="100%" height="100%">
                                            <BarChart
                                                width={560}
                                                height={300}
                                                data={YearWiseComparisonData}
                                                barSize={15}
                                                margin={{
                                                    top: 20, right: 0, left: -5, bottom: 10,
                                                }}
                                            >
                                                <CartesianGrid vertical={false} />
                                                <XAxis dataKey="name" />
                                                <YAxis />
                                                <Tooltip />
                                                <Bar type="monotone" dataKey="LastYear" fill="#4481d5" />
                                                <Bar type="monotone" dataKey="ThisYear" fill="#0e5768" />
                                            </BarChart>
                                        </ResponsiveContainer>
                                    </div>
                                    <div className="Legend Barchart">
                                        <div className='legendbox'>
                                            <div className="square" style={{ backgroundColor: '#4481d5' }}></div>
                                            <p>Last Year</p>
                                        </div>
                                        <div className='legendbox'>
                                            <div className="square" style={{ backgroundColor: '#0e5768' }}></div>
                                            <p>This Year</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-xs-12 newPadding">
                        <div className="outerBox">
                            <div className="innerBox padding-zero" style={{ height: '360px' }}>
                                <div className="HeadingBox WidthInc" style={{ backgroundColor: '#0e5768' }}>
                                    <h5>Payment Due in Next 15 Days </h5>
                                </div>
                                <div className="Linecharts" style={{ marginTop: '50px' }}>
                                    <div className="tables">
                                        {
                                            tableData?.length > 0 ?
                                                <div className="phocket-table-new newTable subnewTable">
                                                    <table>
                                                        <thead>
                                                            <tr>
                                                                <th>View</th>
                                                                <th>Reference Number</th>
                                                                <th>Customer Name</th>
                                                                <th>Date</th>
                                                                <th>Amount</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {tableData.map((data, index) => (
                                                                <tr key={index}>
                                                                    <td><h6 onClick={()=>handlePopup(data)}><b>i</b></h6></td>
                                                                    <td>{data.referenceNumber}</td>
                                                                    <td>{data.name}</td>
                                                                    <td>{moment(data.date).format('DD-MM-YYYY')}</td>
                                                                    <td>{data.amount}</td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                                : ''}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-xs-12 newPadding">
                        <div className="outerBox">
                            <div className="innerBox" style={{ height: '360px' }}>
                                <div className="Linecharts">
                                    <h6 style={{ color: '#000', textAlign: 'center', marginTop: '0px' }}>Products</h6>
                                    <div className="charthead " style={{ height: '200px' }}>
                                        <ResponsiveContainer width="100%" height="100%">
                                            <PieChart
                                                width={300}
                                                height={300}
                                            >
                                                <Pie data={ProductPiedata} dataKey="value" cx="50%" cy="50%" innerRadius={60} outerRadius={80} fill="#82ca9d">
                                                    {ProductPiedata.map((entry, index) => (
                                                        <Cell key={`cell-${index}`} fill={Piecolors[index % Piecolors.length]} />
                                                    ))}
                                                </Pie>
                                            </PieChart>
                                        </ResponsiveContainer>
                                    </div>
                                    <div className="Legend Barchart">
                                        <div className='legendbox' style={{ alignItems: 'baseline' }}>
                                            <div className="square small-size" style={{ backgroundColor: '#0e5768' }}></div>
                                            <p>Personal</p>
                                        </div>
                                    </div>
                                    <div className="sub-legend">
                                        <h4>Highest: Personal Loan</h4>
                                        <h4>55.30%</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-xs-12 newPadding">
                        <div className="outerBox">
                            <div className="innerBox" style={{ height: '360px' }}>
                                <div className="Linecharts">
                                    <h6 style={{ color: '#000', textAlign: 'center', marginTop: '0px' }}>Loan Status Overview</h6>
                                    <div className="charthead " style={{ height: '200px' }}>
                                        <ResponsiveContainer width="100%" height="100%">
                                            <PieChart
                                                width={300}
                                                height={300}
                                            >
                                                <Pie data={LoanStatusPieData} dataKey="value" cx="50%" cy="50%" radius={80} fill="#82ca9d">
                                                    {LoanStatusPieData.map((entry, index) => (
                                                        <Cell key={`cell-${index}`} fill={Piecolors[index % Piecolors.length]} />
                                                    ))}
                                                </Pie>
                                                <Tooltip />
                                            </PieChart>
                                        </ResponsiveContainer>
                                    </div>
                                    <div className="Legend Barchart" style={{ flexWrap: 'wrap' }}>
                                        <div className='legendbox' style={{ alignItems: 'baseline' }}>
                                            <div className="square small-size circular" style={{ backgroundColor: '#f24839' }}></div>
                                            <p>Approved</p>
                                        </div>
                                        <div className='legendbox' style={{ alignItems: 'baseline' }}>
                                            <div className="square small-size circular" style={{ backgroundColor: '#223d64' }}></div>
                                            <p>Pending</p>
                                        </div>
                                        <div className='legendbox' style={{ alignItems: 'baseline' }}>
                                            <div className="square small-size circular" style={{ backgroundColor: '#267dff' }}></div>
                                            <p>Disbursed</p>
                                        </div>
                                        <div className='legendbox' style={{ alignItems: 'baseline' }}>
                                            <div className="square small-size circular" style={{ backgroundColor: '#223d64' }}></div>
                                            <p>Total Rejected</p>
                                        </div>
                                        <div className='legendbox' style={{ alignItems: 'baseline' }}>
                                            <div className="square small-size circular" style={{ backgroundColor: '#f24839' }}></div>
                                            <p>Written Off</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {
                    tablePopup ?
                    <TablePopup tablePopupData={tablePopupData} close={closePopup} tabName={'KPILoanManagementDashboard'}/>
                :''}
        </div>
    )
}
