import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { APIS } from '../../../../utils/api-factory';

const SaveEmailForm = ({setabs,getData, editData }) => {
    const [key, setKey] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [isEdit, setIsEdit] = useState(false); 

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            if (editData&&editData.key) {
                await axios.get(`${APIS.UPDATE_EMAIL_KEY}${key}&email=${email}`);
                setMessage('Email updated successfully!');
                setabs('list')
                getData()

            } else {
                await axios.post(`${APIS.SAVE_EMAIL_KEY}${key}&email=${email}`);
                setMessage('Email saved successfully!');
                setabs('list')
                getData()
            }
        } catch (error) {
            setMessage('Error saving or updating email.');
        }
    };

    useEffect(() => {
        if (editData) {
            setKey(editData.key || ''); 
            setEmail(editData.email || ''); 
            // setIsEdit(true); 
        }
    }, []);

    return (
        <div className='container'>
            <div className='shadow-card'>
                <h2>{editData&&editData.key ? 'Update Data' : 'Add Data'}</h2>
                <form onSubmit={handleSubmit}>
                    <div className='row'>
                        <div className='col-sm-6 col-xs-12'>
                            <label>Key:</label>
                            <input
                                className='form-control'
                                type="text"
                                value={key}
                                onChange={(e) => setKey(e.target.value)}
                                required
                                readOnly={key?true:false}
                            />
                        </div>
                        <div className='col-sm-6 col-xs-12'>
                            <label>Email:</label>
                            <input
                                className='form-control'
                                type="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                            />
                        </div>
                    </div>
                    <div className='text-center' style={{ marginTop: '50px' }}>
                    {message && <p>{message}</p>}
                        <button type="submit" className='btn btn-primary'>
                            {editData&&editData.key ? 'Update Email' : 'Save Email'}
                        </button>
                    </div>
                </form>
            </div>
          
        </div>
    );
};

export default SaveEmailForm;
