import { APIS } from "../../../../../utils/api-factory"
import { POST_HEADER } from "../../../../../utils/apiHeaders"

export const addEmailTemplateApI = (data, callBack) => {

    fetch(APIS.ADD_OR_UPDATE_EMAIL_TEMPLATE, {
        method: 'POST',
        headers: POST_HEADER,
        body: JSON.stringify(data)
    })
        .then(res => res.text())
        .then(json => {
            callBack(json)
        })
}
export const getSaveEmailTemplates = (callBack) => {
    fetch(APIS.GET_EMAIL_TEMPLATES)
        .then(res => {
            if (!res.ok) {
                throw new Error(`HTTP error! Status: ${res.status}`);
            }
            return res.json();
        })
        .then(json => {
            callBack(json);
        })
        .catch(error => {
            console.error('Error fetching email templates:', error);
            callBack(''); 
        });
};

export const deleteEmailTemplateApi = (templateName, callBack) => {
    fetch(APIS.DELETE_TEMPLATE_EMAIL + templateName, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
        },
    })
        .then(res => res.json())
        .then(json => {
            callBack(json);
        })
        .catch(error => {
            console.error('Error deleting template:', error);
            callBack({ success: false, error: 'Error deleting template' });
        });
};
/////SMS////
export const getSaveSmsTemplates = (callBack) => {
    fetch(APIS.GET_SMS_TEMPLATES)
        .then(res => {
            if (!res.ok) {
                throw new Error(`HTTP error! Status: ${res.status}`);
            }
            return res.json();
        })
        .then(json => {
            callBack(json);
        })
        .catch(error => {
            console.error('Error fetching email templates:', error);
            callBack(''); 
        });
};
export const addSmsTemplateApI = (data, callBack) => {

    fetch(APIS.ADD_OR_UPDATE_SMS_TEMPLATE, {
        method: 'POST',
        headers: POST_HEADER,
        body: JSON.stringify(data)
    })
        .then(res => res.text())
        .then(json => {
            callBack(json)
        })
}
export const deleteSmsTemplateApi = (templateName, callBack) => {
    fetch(APIS.DELETE_TEMPLATE_SMS + templateName, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
        },
    })
        .then(res => res.json())
        .then(json => {
            callBack(json);
        })
        .catch(error => {
            console.error('Error deleting template:', error);
            callBack({ success: false, error: 'Error deleting template' });
        });
};