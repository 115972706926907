import React, { useState, useEffect } from "react";

import Filter from "../../maketingimages/filter.png";
import Stats from "./components/Stats";
import CountLeads from "./components/dashboard/countLeads";
import DailyLeadRecieved from "./components/dashboard/dailyLeadRecieved";
import BudgetBreakup from "./components/dashboard/budgetBreakup";
import ConversionRatio from "./components/dashboard/conversionRatio";
import RejectionStats from "./components/dashboard/rejectionStats";
import LeadBreakupForAggregator from "./components/dashboard/leadBreakupForAggregator";
import LeadParticularsAggregator from "./components/dashboard/leadParticularsAggregator";
import {
  getConverstionDetails,
  getCountByStatusByPartnerId,
  getDisbursedStats,
  getLeadParticular,
  getMarketingDashboardCounts,
  getRejectReasonCount,
  getTotalLeadsRecieved,
} from "../actioncreator";
import {
  formatDate,
  monthStartDate,
  todayDate,
  reverseFormat,
} from "../utils/utils";
import '../../maketingComponents/compo.scss'

const Component1 = ({aggregator}) => {
  const [payload, setPayload] = useState({
    partnerId: aggregator.partnerId,
    filter: "Daily",
    fromDate: monthStartDate(),
    toDate: todayDate(),
  });
  const [disbursedData, setDisbursedData] = useState({});
  const [countStatusData, setCountStatusData] = useState([]);
  const [rejectReasonData, setRejectReasonData] = useState([]);
  const [totalLeadsRecievedData, setTotalLeadsRecievedData] = useState([]);
  const [leadParticularData, setLeadParticularData] = useState('');
  const [conversionRatioData, setConversionRatioData] = useState({});
  const [countsData, setCounts] = useState('');
  const [dashboardcounts, setDashboardCounts] = useState('');

  useEffect(() => {
    getData()
    getCounts()
  }, [])

  const getData = () => {
    getDisbursedStats(
      payload,
      (callBack) => {
        setDisbursedData(callBack);
      },
      (error) => {
        console.log(error);
      }
    );
    getCountByStatusByPartnerId(
      payload,
      (callBack) => {
        setCountStatusData(callBack);
      },
      (error) => {
        console.log(error);
      }
    );
    getRejectReasonCount(
      payload,
      (callBack) => {
        setRejectReasonData(callBack);
      },
      (error) => {
        console.log(error);
      }
    );
    getTotalLeadsRecieved(
      payload,
      (callBack) => {
        setTotalLeadsRecievedData(callBack);
      },
      (error) => {
        console.log(error);
      }
    );
    getLeadParticular(
      payload,
      (callBack) => {
        setLeadParticularData(callBack);
      },
      (error) => {
        console.log(error);
      }
    );
    getConverstionDetails(
      payload,
      (callBack) => {
        setConversionRatioData(callBack);
      },
      (error) => {
        console.log(error);
      }
    );
    getCounts()
  };
  const handleChange = (e) => {
  
    setPayload({
      ...payload,
      [e.target.name]:
        e.target.type === "date" ? formatDate(e.target.value) : e.target.value,
    });

  };
  const getCounts = () => {
    getMarketingDashboardCounts(payload, callBack => {
      setDashboardCounts(callBack)
    })
  }
  return (
    <>
      <div className="container-fluid marketing-main-css">
        <div className="row">
          <div className="col-sm-2 col-xs-12">
            <img src={Filter} width={20} height={20} alt="" />
            <span className="ms-3 filterHeaders">Filter</span>
            <select
              name=""
              id=""
              value={payload.filter}
              className="form-select"
              style={{ width: '100%', padding: '7px' }}
            >
              <option value="daily">Daily</option>
            </select>
          </div>
          <div style={{ position: 'absolute', marginLeft: '15.5%', marginTop: '30px' }}>::</div>
          <div className="col-sm-2 col-xs-12">
            From
            <input
              type="date"
              id="startDate"
              placeholder="DD/MM/YY"
              name="fromDate"
              value={reverseFormat(payload.fromDate)}
              onChange={handleChange}
              className=" form-control"
              style={{
                border: "1px solid gray",
                borderRadius: "6px",
                padding: "10px",
              }}
            />

          </div>
          <div style={{ position: 'absolute', marginLeft: '31%', marginTop: '30px' }}>-</div>

          <div className="col-sm-2 col-xs-12">
            <div className="filterHeaders" style={{ marginTop: '4px' }}>to</div>
            <input
              type="date"
              id="endDate"
              name="toDate"
              max={new Date()}
              value={reverseFormat(payload.toDate)}
              onChange={handleChange}
              placeholder="DD/MM/YY"
              className=" form-control"
              style={{
                border: "1px solid gray",
                borderRadius: "6px",
                padding: "10px",
              }}
            />
          </div>
          <div className="col-sm-2 col-xs-12">
            <button
              className="btn btn-primary mt-4 "
              style={{
                color: "#2B78FF",
                borderColor: "#2B78FF",
                backgroundColor: "white",
                marginTop: '27px',
                padding: '5px',
                borderRadius: '5px'
              }}
              onClick={() => getData()}
            >
              Apply Filter
            </button>
          </div>

        </div>

        <hr />
        <div className="row" style={{ marginBottom: '20px' }}>
          <div className="col-sm-3 col-xs-12">
            <div className="new-card-market">
              <h4 style={{ color: '#999999' }}>Total Leads</h4>
              <h3 style={{ marginTop: '5px' }}><strong>{dashboardcounts.countTotalLeads}</strong></h3>
            </div>
          </div>
          <div className="col-sm-3 col-xs-12">
            <div className="new-card-market">
              <h4 style={{ color: '#999999' }}>Total Applied Leads</h4>
              <h3 style={{ marginTop: '5px' }}><strong>{dashboardcounts.countTotalAppliedLeads}</strong></h3>
            </div>
          </div>
          <div className="col-sm-3 col-xs-12">
            <div className="new-card-market">
              <h4 style={{ color: '#999999' }}>Total Disbursed Leads</h4>
              <h3 style={{ marginTop: '5px' }}><strong>{dashboardcounts.countTotalDisbursedLeads}</strong></h3>
            </div>
          </div>
          <div className="col-sm-3 col-xs-12">
            <div className="new-card-market">
              <h4 style={{ color: '#999999' }}>Total Cost Utilised (Till Date)</h4>
              <h3 style={{ marginTop: '5px' }}><strong>{dashboardcounts.totalUtilizedBudget}</strong></h3>
            </div>
          </div>

        </div>

        <div className="row mt-4">
          <div className="col-sm-6 col-xs-12">
            <DailyLeadRecieved data={totalLeadsRecievedData} />
          </div>
          <div className="col-sm-6 col-xs-12">
            {countStatusData ? <CountLeads data={countStatusData} /> : ""}
          </div>
        </div>

        <div className="row " >
          <div className="col-sm-5 col-xs-12 mt-4">
            <BudgetBreakup dashboardcounts={dashboardcounts} />
          </div>


          <div className="col-sm-7 col-sm-12 mt-4">
            <ConversionRatio data={conversionRatioData} />
          </div>
        </div>

        <div className="row mt-4">
          <div className="col-sm-5 col-xs-12">
            <RejectionStats data={rejectReasonData} />
          </div>

          <div className="col-sm-7 col-xs-12">
            
            <LeadBreakupForAggregator data={leadParticularData} />
          </div>
        </div>
        {Object.keys(disbursedData).length > 0 && (
          <div className="">
            <Stats data={disbursedData} />
          </div>
        )}
        {leadParticularData !== '' && leadParticularData !== undefined ?
          <div className="row" style={{ marginTop: '20px' }}>
            <LeadParticularsAggregator data={leadParticularData} />
          </div>
          : ""}
      </div>

    </>
  );
};

export default Component1;
