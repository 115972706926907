import React, { Component } from 'react'
import CompleteLeadsUi from '../../../presentationals/Admin/LeadManagement/collectionNewLeads.component'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
    saveNachDataApi,
    deductUserPaymentApi, getSourceOfEnach, deductUserPaymentByTechProcess,
    saveRecoveryCommentAPI,
    getManagementLeadApiOptimized, getUserDetailByLoanIdOptimisedApi, saveImportantLoans,
    getlistByApplication, getlistByEmail, getistByNumber, getCommentsApi
} from '../AdminActionCreator.component'
import MainDetailsPopup from '../CustomerPages/MainDetailPopup.container'
import UserDetail from '../CustomerPages/UserDetailMainNew.container';
import { STRING } from '../../../../utils/Constant'
import { _preFormatDate } from '../../../../utils/validation'
import { APIS } from '../../../../utils/api-factory'
import localStorageUtil from '../../../../utils/localStorageUtil';
import DotLoader from '../../../presentationals/Loader/layerLoader.component'
import moment from 'moment'
import Popup from '../../../presentationals/Popup/Popup.component';
import RecoveryCommunication from '../Recovery/RecoveryCommunication.container'

import ChequeNoticePopup from '../../../presentationals/Admin/Recovery/NoticeAndCheckDate.component'
import AmountDeductionPopup from '../../../presentationals/Admin/Recovery/GetAmountForDeduction.popup'
let leadType = ""
let empId = localStorageUtil.getFromLocalStorage("AdminemailId")

let nachCheckbox = false

let addLoanId = false

let paginationNumber = 100
class CompleteLeads extends Component {
    constructor(props) {
        super(props);
        this.state = {
            FilteredData: [],
            pageIndexToShow: 0,
            allUserDetail: [],
            userState: true,
            viewState: false,
            allDetail: [],
            userDetail: [],
            loadarState: false,
            forwordPageState: 1,
            actionButtonState: true,
            followUpStatus: STRING.PAYMENT_OVERDUE,
            dataFilterState: {
                searchByAppNo: '',
                searchByemail: '',
                searchBymobile: '',
                searchByDate: '',
                searchByDateShow: '',
                commentType: ''
            },
            todaysApplicationNo: '',
            markCompleteBtn: true,
            countforstatus: '',
            commentStateSelect: '',
            commentState: '',
            popupState: false,
            popupStatus: '',
            amountDeductionPopupState: false,
            loanIdForDeduction: '',
            amountForDeduction: '',
            SourceOfEnachUiState: false,
            source: '',
            userIdForSource: '',
            chequeNoticePopup: false,
            chequeNoticeData: '',
            chequeNoticeDateToShow: '',
            chequeNoticeDate: '',
            chequeNoticeType: '',
            recoveryPageState: true,
            dataForCommunication: '',
            searchByNach: '',
            tableMinPagination: 0,
            tableMaxPagination: paginationNumber,
            startDate: '',
            // dateForDeduction:'',
            dateTechProcess: '',
            startDate: moment(new Date()).format("DD-MM-YYYY"),
            commentsData: [],
            pageNumber: 1


        }
    }
    removePopup() {
        setTimeout(function () {
            this.setState({ popupState: false });
        }.bind(this), 5000)
    }
    handleChangeNewPagination(e, value) {
        this.setState({ pageNumber: value })
        const newMinPagination = (value - 1) * paginationNumber;
        const newMaxPagination = value * paginationNumber;
        this.setState({
            tableMinPagination: newMinPagination,
            tableMaxPagination: newMaxPagination,
        });
    };
    closePopup() {
        this.setState({ popupState: false });
    }
    paymentDeductHandler(data) {
        // this.setState({
        //     amountDeductionPopupState: true,
        //     loanIdForDeduction: data.loanId,
        //     amountForDeduction: data.loanPaybackAmount
        // })
        getSourceOfEnach(data.userId, callBack => {
            if (
                callBack != '' &&
                callBack != null &&
                callBack != '' &&
                Object.keys(callBack).length > 0
            ) {
                this.setState({
                    SourceOfEnachUiState: true,
                    amountDeductionPopupState: true,
                    userIdForSource: data.userId,
                    loanIdForDeduction: data.loanId,
                    amountForDeduction: data.loanPaybackAmount
                })
            } else {
                this.setState({
                    popupState: true,
                    popupStatus: 'No Mandate Registered'
                })
                this.removePopup()
            }
            this.setState({
                Sourcedata: callBack
            })
        })
    }

    deductionAmountHandler(type) {

        if (type == 'yes') {
            this.setState({
                disabled: true
            })
            if (this.state.source != 'techProcess') {
                deductUserPaymentApi(
                    this.state.loanIdForDeduction,
                    this.state.amountForDeduction,
                    '',
                    callBack => {
                        if (callBack == 'success') {
                            this.setState({
                                popupState: true,
                                popupStatus: 'success',
                                amountDeductionPopupState: false,
                                disabled: false
                            })
                            this.removePopup()
                        } else {
                            this.setState({
                                popupState: true,
                                popupStatus: 'Please try again',
                                amountDeductionPopupState: false,
                                disabled: false
                            })
                            this.removePopup()
                        }
                    }
                )
            } else {
                deductUserPaymentByTechProcess(
                    this.state.loanIdForDeduction,
                    this.state.amountForDeduction,
                    this.state.dateTechProcess,
                    callBack => {
                        if (callBack == 'success') {
                            this.setState({
                                popupState: true,
                                popupStatus: 'success',
                                amountDeductionPopupState: false,
                                disabled: false
                            })
                            this.removePopup()
                        } else {
                            this.setState({
                                popupState: true,
                                popupStatus: 'Please try again',
                                amountDeductionPopupState: false,
                                disabled: false
                            })
                            this.removePopup()
                        }
                    }
                )
            }
        } else if (type == 'no') {
            this.setState({ amountDeductionPopupState: false })
        }
        // if (type == 'yes') {
        //     this.setState({
        //         disabled: true
        //     })
        //     deductUserPaymentApi(this.state.loanIdForDeduction, this.state.amountForDeduction,
        //         (callBack) => {
        //             if (callBack == 'success') {
        //                 this.setState({
        //                     popupState: true,
        //                     popupStatus: 'success',
        //                     amountDeductionPopupState: false,
        //                     disabled: false
        //                 })
        //                 this.removePopup();
        //             } else {
        //                 this.setState({
        //                     popupState: true,
        //                     popupStatus: 'Please try again',
        //                     amountDeductionPopupState: false,
        //                     disabled: false
        //                 })
        //                 this.removePopup();
        //             }
        //         })
        // } else if (type == 'no') {
        //     this.setState({ amountDeductionPopupState: false })
        // }
    }
    deductionAmountChange(e) {
        this.setState({
            amountForDeduction: e.target.value
        })
    }
    getCountForStatus() {
        const { admin } = this.props

        fetch(APIS.GET_DASHBOARD_COUNT_FOR_STATUS + admin.emailId + '&startDate=' + this.state.startDate + '&endDate=' + this.state.dataFilterState.searchByDate + '&status=' + admin.department)
            .then(res => res.json())
            .then(res => {
                this.setState({ countforstatus: res })
            })
    }
    userCommentSelect(e) {
        this.setState({ commentStateSelect: e.target.value })

    }
    userCommentChange(e, type) {

        this.setState({ commentState: this.state.commentStateSelect + " " + e.target.value })

    }
    userCommentHandler(data) {
        const { admin } = this.props
        saveRecoveryCommentAPI(data, this.state.commentState, admin, '',
            (callBack) => {
                if (callBack == 'success') {
                    this.setState({
                        popupState: true,
                        popupStatus: 'Comment Saved Successfully',
                        commentState: ''
                    })
                    this.removePopup();
                } else {
                    this.setState({
                        popupState: true,
                        popupStatus: 'Please try again',
                        commentState: ''
                    })
                    this.removePopup();
                }
            })
    }

    componentWillMount() {
        localStorage.setItem('showComment', 'yes')

        this.getLoanLead()
        // this.getCountForStatus()
        // fetch(APIS.TODAYS_LOAN_COUNT + "&type=" + leadType)
        //     .then(res => res.json())
        //     .then(json => {
        //         this.setState({ todaysApplicationNo: json })
        //     })
    }

    getLoanLead() {
        this.setState({ loadarState: true })
        empId = localStorageUtil.getFromLocalStorage("AdminemailId")
        this.props.getManagementLeadApiOptimized(leadType, this.state.followUpStatus, this.state.forwordPageState, empId, this.state.dataFilterState.searchByDate, this.state.dataFilterState.commentType, '', '',
            (callBack) => {
                this.setState({ loadarState: false })


            })
    }

    getData() {

    }
    chequeNotice(e, inpType) {
        if (inpType == 'chequeNotice') {
            this.setState({
                chequeNoticeType: e.target.value,
            })
        } else if (inpType == 'chequeNoticeDate') {
            this.setState({
                chequeNoticeDateToShow: e,
                chequeNoticeDate: _preFormatDate(e)
            })
        }
    }

    chequeNoticeHandler() {
        const { admin } = this.props;
        if (this.state.chequeNoticeType != '' && this.state.chequeNoticeDate != '') {
            saveCHequeNoticeApi(this.state.chequeNoticeData.loanId, this.state.chequeNoticeType, this.state.chequeNoticeDate, admin, this.state.chequeNoticeData.emiId,
                (callBack) => {
                    if (callBack == 'success') {
                        this.setState({
                            chequeNoticePopup: false,
                            chequeNoticeType: '',
                            chequeNoticeDate: '',
                            chequeNoticeDateToShow: '',
                            popupState: true,
                            popupStatus: 'Data saved successfully',
                        })
                        this.removePopup();
                    }
                })
        } else {
            this.setState({
                popupState: true,
                popupStatus: 'Please select TYPE & DATE',
            })
            this.removePopup();
        }
    }

    closeNoticePopup() {
        this.setState({
            chequeNoticePopup: false,
        })
    }

    chequeNoticeShowHandler(data) {
        this.setState({
            chequeNoticePopup: true,
            chequeNoticeData: data
        })
    }
    recoveryComunicateHandler(data) {
        this.setState({
            recoveryPageState: false,
            communicationPageState: true,
            dataForCommunication: data
        })
    }
    phocketTablePagination(data, type) {
        if (type == 'back') {
            if (this.state.tableMinPagination >= paginationNumber) {
                this.setState({
                    tableMinPagination: this.state.tableMinPagination - paginationNumber,
                    tableMaxPagination: this.state.tableMaxPagination - paginationNumber,
                })
            }
        } else if (type == 'forward') {
            if (this.state.tableMaxPagination < data.length) {
                this.setState({
                    tableMaxPagination: this.state.tableMaxPagination + paginationNumber,
                    tableMinPagination: this.state.tableMinPagination + paginationNumber
                })
            }
        }
    }
    dateHandler(e) {
        this.setState({
            startDate: e,
            dateTechProcess: _preFormatDate(e)
        })
    }
    nashHandler(e, incomingData) {
        const { admin } = this.props;
        saveNachDataApi(e.target.checked, incomingData.userId, admin,
            (callBack) => {
                this.getLoanLead()
            })
    }

    render() {
        const { admin, leadManagementDataOptimized, allStatus, newDataBackground, collectionType } = this.props
        let allAgentData = leadManagementDataOptimized
        if (this.state.searchByNach) {
            allAgentData = allAgentData.filter(row => {
                if (row.nachStatus != null) {
                    if (this.state.searchByNach == true || this.state.searchByNach == "true") {
                        return row.nachStatus == true
                    } else if (this.state.searchByNach == false || this.state.searchByNach == "false") {
                        return row.nachStatus == false
                    }
                }
            })
        }


        return (
            <div className="full-height">
                {this.state.recoveryPageState == true ?
                    <CompleteLeadsUi
                        forwordPageState={this.state.forwordPageState}
                        handleChangeNewPagination={this.handleChangeNewPagination.bind(this)}
                        pageNumber={this.state.pageNumber}
                        commentsData={this.state.commentsData}
                        ignoreHandler={this.ignoreHandler.bind(this)}
                        nashHandler={this.nashHandler.bind(this)}

                        recoveryComunicateHandler={this.recoveryComunicateHandler.bind(this)}
                        phocketTablePagination={this.phocketTablePagination.bind(this)}
                        tableMinPagination={this.state.tableMinPagination}
                        tableMaxPagination={this.state.tableMaxPagination}
                        userCommentHandler={this.userCommentHandler.bind(this)}
                        userCommentChange={this.userCommentChange.bind(this)}
                        userCommentSelect={this.userCommentSelect.bind(this)}
                        commentState={this.state.commentState}
                        countforstatus={this.state.countforstatus}
                        allUserDetail={allAgentData}
                        userDetailHandler={this.userDetailHandler.bind(this)}
                        userViewHandler={this.userViewHandler.bind(this)}
                        getFilterValue={this.getFilterValue.bind(this)}
                        FilteredData={this.state.FilteredData}
                        getPageValue={this.getPageValue.bind(this)}
                        pageIndexToShow={this.state.pageIndexToShow}
                        paginationHandler={this.paginationHandler.bind(this)}
                        allStatus={allStatus}
                        dataFilterState={this.state.dataFilterState}
                        followUpStatus={this.state.followUpStatus}
                        dataFilter={this.dataFilter.bind(this)}
                        admin={admin}
                        markAsImportant={this.markAsImportant.bind(this)}
                        loadarState={this.state.loadarState}
                        newDataBackground={newDataBackground}
                        todaysApplicationNo={this.state.todaysApplicationNo}
                        showRepeateApplications={this.showRepeateApplications.bind(this)}
                        paymentDeductHandler={this.paymentDeductHandler.bind(this)}
                        chequeNoticeShowHandler={this.chequeNoticeShowHandler.bind(this)}
                        forwordpage={this.forwordpage.bind(this)}
                        backpage={this.backpage.bind(this)}

                    />
                    : null}

                {this.state.viewDetailPopupState == true ?
                    <MainDetailsPopup
                        getData={this.getData.bind(this)}
                        leadManagementData={leadManagementDataOptimized}
                        getviewDetailPopupState={this.getviewDetailPopupState.bind(this)}
                        admin={admin}
                        allDetail={this.state.viewPopupDetail}
                        userDetails={this.state.userDetail}

                    />
                    : null}

                {this.state.viewState == true ?
                    <UserDetail
                        getData={this.getData.bind(this)}
                        leadManagementData={leadManagementDataOptimized}
                        allDetail={this.state.allDetail}
                        userDetails={this.state.userDetail}
                        admin={admin}
                        backHandler={this.backHandler.bind(this)}
                        actionButtonState={this.state.actionButtonState}
                        markCompleteBtn={this.state.markCompleteBtn}
                    />
                    : ""}
                {this.state.loadarState == true ?
                    <DotLoader />
                    : ""}
                {this.state.popupState == true ?
                    <Popup
                        closePopup={this.closePopup.bind(this)}
                        popupStatus={this.state.popupStatus}
                    />
                    : ""}
                {this.state.amountDeductionPopupState ?
                    <AmountDeductionPopup
                        dateHandler={this.dateHandler.bind(this)}
                        dateTechProcess={this.state.dateTechProcess}
                        startDate={this.state.startDate}
                        source={this.state.source}
                        amountForDeduction={this.state.amountForDeduction}
                        deductionAmountChange={this.deductionAmountChange.bind(this)}
                        deductionAmountHandler={this.deductionAmountHandler.bind(this)}
                        disabled={this.state.disabled}
                    />
                    : null}
                {this.state.chequeNoticePopup == true ?
                    <ChequeNoticePopup
                        chequeNotice={this.chequeNotice.bind(this)}
                        chequeNoticeHandler={this.chequeNoticeHandler.bind(this)}
                        chequeNoticeDateToShow={this.state.chequeNoticeDateToShow}
                        chequeNoticeDate={this.state.chequeNoticeDate}
                        closeNoticePopup={this.closeNoticePopup.bind(this)} />
                    : ""}
                {this.state.communicationPageState == true ?
                    <RecoveryCommunication
                        dataForCommunication={this.state.dataForCommunication}
                        recoveryBackHandler={this.recoveryBackHandler.bind(this)}
                        admin={admin}
                        collectionType={collectionType}
                    />
                    : ""}
            </div>
        )
    }
    recoveryBackHandler() {
        const { admin } = this.props
        this.setState({
            recoveryPageState: true,
            communicationPageState: false
        }, () => this.getLoanLead())
        // if (this.state.searchByAppNo != '') {
        //     this.props.getlistByApplication(this.state.searchByAppNo, 1, '', admin,
        //         (callBack) => {
        //             this.setState({
        //                 loadarState: false
        //             })
        //         })
        // } else if (this.state.searchByemail != '') {
        //     this.props.getlistByEmail(this.state.searchByemail, 1, '', admin,
        //         (callBack) => {
        //             this.setState({
        //                 loadarState: false
        //             })
        //         })
        // } else if (this.state.searchBymobile != '') {
        //     this.props.getistByNumber(this.state.searchBymobile, 1, '', admin,
        //         (callBack) => {
        //             this.setState({
        //                 loadarState: false
        //             })
        //         })
        // } else {
        //     this.getLoanLead()
        // }
    }

    markAsImportant(allDetail, isImportant) {
        const { admin } = this.props
        saveImportantLoans(allDetail, isImportant, admin.emailId,
            (callBack) => {
                this.backHandler()
            })
    }

    showRepeateApplications() {
        this.setState({
            followUpStatus: 'repeate'
        }, () => this.getLoanLead())
    }
    ignoreHandler(e, loanId) {
        fetch(APIS.SAVE_EXTENSION_TIME + loanId + '&status=' + e.target.checked)
            .then(res => res.text())
            .then(res => {

                this.getLoanLead()
            })
    }

    dataFilter(e, chngType) {
        const { admin } = this.props
        this.setState({ loadarState: true })
        let dataFilterState = Object.assign({}, this.state.dataFilterState)
        if (chngType == 'status') {
            this.setState({ followUpStatus: e.target.value }, () => this.getLoanLead())
        } else if (chngType == 'applicationNo') {
            dataFilterState.searchByAppNo = e.target.value
            if (e.target.value.length > 4) {
                this.props.getlistByApplication(e.target.value, 1, leadType, admin, false,
                    (callBack) => {
                        this.setState({ loadarState: false })
                    })
            }
        } else if (chngType == 'email') {
            dataFilterState.searchByemail = e.target.value
            if (e.target.value.length > 2) {
                this.props.getlistByEmail(e.target.value, 1, leadType, admin,
                    (callBack) => {
                        this.setState({ loadarState: false })
                    })
            }
        } else if (chngType == 'mobileNo') {
            dataFilterState.searchBymobile = e.target.value
            if (e.target.value.length > 9) {
                this.props.getistByNumber(e.target.value, 1, leadType, admin,
                    (callBack) => {
                        this.setState({ loadarState: false })
                    })
            }
        } else if (chngType == "date") {
            dataFilterState.searchByDate = _preFormatDate(e)
            dataFilterState.searchByDateShow = e
            this.setState({
                dataFilterState
            }, () => this.getLoanLead())
        }
        if (chngType != "date" && e.target.value == "") {
            this.getLoanLead()
        }
        else if (chngType == "nach") {
            this.setState({ searchByNach: e.target.value, loadarState: false })
        }
        this.setState({
            dataFilterState
        })
    }

    getFilterValue(filtered) {
        this.setState({ FilteredData: filtered })
    }

    getPageValue(pageIndex) {
        this.setState({ pageIndexToShow: pageIndex })
    }

    userDetailHandler(userData) {
        this.setState({ loadarState: true })
        getUserDetailByLoanIdOptimisedApi(userData.loanId, userData.userId,
            (callBack) => {
                this.setState({
                    userState: false,
                    viewState: true,
                    allDetail: callBack,
                    userDetail: userData,
                    loadarState: false
                })
            })
    }

    userViewHandler(userData) {
        this.setState({ loadarState: true })

        getUserDetailByLoanId(userData.loanId, userData.userId,
            (callBack) => {
                this.setState({
                    viewDetailPopupState: true,
                    viewPopupDetail: callBack,
                    userDetail: userData,
                    loadarState: false
                })
            })
    }

    getviewDetailPopupState(state) {
        this.setState({
            viewDetailPopupState: state,
        })
    }

    backHandler() {
        const { admin } = this.props
        this.setState({
            userState: true,
            viewState: false,
            loadarState: true
        })
        if (this.state.dataFilterState.searchByAppNo != '') {
            this.props.getlistByApplication(this.state.dataFilterState.searchByAppNo, 1, leadType, admin, false,
                (callBack) => {
                    this.setState({
                        loadarState: false
                    })
                })
        } else if (this.state.dataFilterState.searchByemail != '') {
            this.props.getlistByEmail(this.state.dataFilterState.searchByemail, 1, leadType, admin,
                (callBack) => {
                    this.setState({
                        loadarState: false
                    })
                })
        } else if (this.state.dataFilterState.searchBymobile != '') {
            this.props.getistByNumber(this.state.dataFilterState.searchBymobile, 1, leadType, admin,
                (callBack) => {
                    this.setState({
                        loadarState: false
                    })
                })
        } else {
            this.getLoanLead()
        }
    }

    paginationHandler(btnType) {
        this.setState({
            loadarState: true,
            pageIndexToShow: 0
        })
        if (btnType == 'refresh') {
            this.getLoanLead()
        } else if (btnType == 'back') {
            if (this.state.forwordPageState > 1) {
                this.setState({
                    forwordPageState: this.state.forwordPageState - 1,
                }, () => this.getLoanLead())
            }
        } else if (btnType == 'forward') {
            this.setState({
                forwordPageState: this.state.forwordPageState + 1,
          
            }, () => this.getLoanLead())
        }
    }
    forwordpage() {
        this.setState({
            forwordPageState: this.state.forwordPageState + 1,
            pageIndexToShow: 0,
        }, () => this.getLoanLead())

    }
    backpage() {
        if (this.state.forwordPageState > 1) {
            this.setState({
                forwordPageState: this.state.forwordPageState - 1,
                pageIndexToShow: 0
            }, () => this.getLoanLead())
        }
    }
}


const mapStateToProps = ({ leadManagementDataOptimized }) => {
    return {
        leadManagementDataOptimized: leadManagementDataOptimized
    };
};

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        getistByNumber,
        getlistByEmail,
        getlistByApplication,
        getManagementLeadApiOptimized

    }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(CompleteLeads);