import React, { Component } from 'react'
import Feilds from '../../../presentationals/Admin/NbfcManagement/Feilds.component'

class Feildsnbfc extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    // componentWillMount() {
    //     fetch(APIS.COUPON_STATS)
    //         .then(res => res.json())
    //         .then(res => {
    //             this.setState({ couponStatData: res })
    //         })
    // }
    render() {
        return (
            <div className="">

                <div className="col-sm-3 col-xs-12">
                    <label className="margin-0 font-12px">Select Nbfc </label>
                    <select className="form-control" >
                        <option value="Loan Approval Leads">DMI</option>
                        {/* <option value="Loan Adda Leads">Pooja Finance</option> */}
                        <option value="Important Leads">Nbfc</option>
                        <option value="Company Leads">Nbfc </option>
                        <option value="Latest Document Leads">Nbfc </option>
                    </select>
                </div>
                <br/>
                <Feilds/>
            </div>
        )
    }


}
export default Feildsnbfc;