import React from 'react'

const ProductConfigUi = ({ changeHandler, submit, productConfig, validationMsg, editHadler }) => (
    <div className='container'>

        <h3 className='text-center' style={{ marginTop: '20px', marginBottom: '20px' }}><b>E-Sign Configuration</b></h3>
        <div className='row'>
            <div className="col-sm-3 col-xs-12" style={{ marginTop: '20px' }}>
                <label className="font-12px">ESign Request Url</label>
                <input type="text" placeholder='ESign Request Url' value={productConfig.eSignRequestUrl} className="form-control" onChange={e => changeHandler(e, 'eSignRequestUrl')} />
                <div style={{ marginBottom: '10px' }}> {validationMsg && validationMsg.field === 'eSignRequestUrl' ? <span className='text-danger'>{validationMsg.msg}</span> : ""}</div>
            </div>
            <div className="col-sm-3 col-xs-12" style={{ marginTop: '20px' }}>
                <label className="font-12px">Widget Token Url</label>
                <input type="text" placeholder='Widget Token Url' value={productConfig.widgetTokenUrl} className="form-control" onChange={e => changeHandler(e, 'widgetTokenUrl')} />
                <div style={{ marginBottom: '10px' }}> {validationMsg && validationMsg.field === 'widgetTokenUrl' ? <span className='text-danger'>{validationMsg.msg}</span> : ""}</div>

            </div>
            <div className="col-sm-3 col-xs-12" style={{ marginTop: '20px' }}>
                <label className="font-12px">Docket Info Url</label>
                <input type="text" placeholder='Docket Info Url' value={productConfig.docketInfoUrl} className="form-control" onChange={e => changeHandler(e, 'docketInfoUrl')} />
                <div style={{ marginBottom: '10px' }}> {validationMsg && validationMsg.field === 'docketInfoUrl' ? <span className='text-danger'>{validationMsg.msg}</span> : ""}</div>
            </div>
            <div className="col-sm-3 col-xs-12" style={{ marginTop: '20px' }}>
                <label className="font-12px">Signature Status Url</label>
                <input type="text" placeholder='signature Status Url' value={productConfig.signatureStatusUrl} className="form-control" onChange={e => changeHandler(e, 'signatureStatusUrl')} />
                <div style={{ marginBottom: '10px' }}> {validationMsg && validationMsg.field === 'signatureStatusUrl' ? <span className='text-danger'>{validationMsg.msg}</span> : ""}</div>
            </div>
        </div>
        <div className='row'>
        <div className="col-sm-3 col-xs-12" style={{ marginTop: '20px' }}>
                <label className="font-12px">Api Key</label>
                <input type="text" placeholder='Api Key' value={productConfig.apiKey} className="form-control" onChange={e => changeHandler(e, 'apiKey')} />
                <div style={{ marginBottom: '10px' }}> {validationMsg && validationMsg.field === 'apiKey' ? <span className='text-danger'>{validationMsg.msg}</span> : ""}</div>
            </div>

            <div className="col-sm-3 col-xs-12" style={{ marginTop: '20px' }}>
                <label className="font-12px">App Id</label>
                <input type="text" placeholder='App Id' value={productConfig.appId} className="form-control" onChange={e => changeHandler(e, 'appId')} />
                <div style={{ marginBottom: '10px' }}> {validationMsg && validationMsg.field === 'appId' ? <span className='text-danger'>{validationMsg.msg}</span> : ""}</div>
            </div>
            <div className="col-sm-3 col-xs-12" style={{ marginTop: '20px' }}>
                <label className="font-12px">Status</label>
                <select className='form-control' onChange={(e) => changeHandler(e, 'status')} value={productConfig.status}>
                    <option value=''>Select Value</option>
                    <option value={true}>Active</option>
                    <option value={false}>Inactive</option>

                </select>
                <div style={{ marginBottom: '10px' }}> {validationMsg && validationMsg.field === 'status' ? <span className='text-danger'>{validationMsg.msg}</span> : ""}</div>
            </div>
        </div>

        <div className='row text-center'>
            <button className='profile-save-btn' onClick={() => submit()}>Save</button>
        </div>


    </div>
)

export default ProductConfigUi