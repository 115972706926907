import React from 'react';


const Registereduser = ({ }) => (
        <div className="container-fluid">
            <h3 className="text-center blue-text">Registerd Nbfc's</h3>
            <div className="row text-center">
                <div className="col-xs-12 no-padding">
                    <div className="overflow-x-auto">
                        <table className="phocket-table">
                            <thead>
                                <tr>
                                    <th>Company Name</th>
                                    <th>contactPerson</th>
                                    <th>Email</th>
                                    <th>Date</th>
                                    <th>Mobile Number</th>
                                    <th>Password</th>
                                    {/* <th>Export Excel</th> */}
                                </tr>
                            </thead>
                            {/* <tbody>
                                {companyList.length > 0 ?
                                    companyList.slice(tableMinPagination, tableMaxPagination).map((bd, i) => {
                                        return (
                                            <tr key={i}>
                                                <td>{bd.companyName}</td>
                                                <td>{bd.contactPerson}</td>
                                                <td>{bd.email}</td>
                                                <td>{moment(new Date(bd.date)).format("DD-MM-YYYY")}</td>
                                                <td>{bd.mobileNumber}</td>
                                                <td>{bd.password}</td>
                                                <td>{bd.webAddress}</td>
                                                <td className="white-space-normal">{bd.registeredAddress}</td>
                                           
                                            </tr>
                                        )
                                    })
                                    : <tr>
                                        <td>
                                            <h3 className="text-center blue-text">No data found</h3>
                                        </td>
                                    </tr>
                                }
                            </tbody> */}
                        </table>
                    </div>
                    <div className="container-fluid">
                        {/* <div className="row">
                            <div className="col-sm-5 col-xs-12"><button type="button" className="btn btn-primary fa fa-chevron-left btn-lg btn-block" onClick={e => phocketTablePagination(companyList, 'back')}></button></div>
                            <div className="col-sm-2 col-xs-12 phoc">{(tableMinPagination + 1) + ' - ' + (tableMaxPagination) + ' of ' + companyList.length}</div>
                            <div className="col-sm-5 col-xs-12"><button type="button" className="btn btn-primary fa fa-chevron-right btn-lg btn-block" onClick={e => phocketTablePagination(companyList, 'forward')}></button></div>
                        </div> */}
                    </div>
                </div>

            </div>
        </div>
    )
export default Registereduser;