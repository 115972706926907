import React, { Component } from 'react'
import TransDetails from '../../../presentationals/Admin/CustomerPages/Transaction.component'
import { _preFormatDate } from '../../../../utils/validation';
import { APIS } from '../../../../utils/api-factory';
import { fetchTransaction, getBillDeskTxn, GetGeneratedPaymentLinkHistory, DeleteGeneratedPaymentLink } from '../AdminActionCreator.component';
import UpdateTransaction from '../CustomerPages/EditTransactionPopup.container'
import Popup from '../../../presentationals/Popup/Popup.component';
import { getTransaction } from '../AdminActionCreator.component'
import ConfirmationPopup from '../../../presentationals/Popup/ConfirmationPopup.component'
import Loader from '../../../presentationals/Loader/Loader.component'
import { getTransactionVyLoanId } from '../../Dashboard/DashboardActionCreator.component';
class Transaction extends Component {
    constructor(props) {
        super(props);
        this.state = {
            confirmationPopupState: false,
            confirmationText: '',
            popupState: false,
            popupStatus: '',
            trnsDateState: '',
            details: '',
            updatePopupState: false,
            transactionIdUpdate: '',
            transDetail: {
                trnsIdState: '',
                trnsDateState: '',
                trnsModeState: '',
                trnsAmountState: '',

            },
            billDeskData: '',
            viewPage: 'txn',
            loaderState: false,
            addPoup: false,
            PaymentLinkData: '',
        }
    }
    getBillDeskTxnData() {
        const { allDetail, userDetail } = this.props

        this.setState({ loaderState: true })
        getBillDeskTxn(userDetail.loanId, callBack => {
            if (callBack) {
                this.setState({ billDeskData: callBack, loaderState: false })
            } else {
                this.setState({ loaderState: false })

            }
        })
    }
    pageHandler(type) {
        this.setState({ viewPage: type })
        if (type === 'billdesk') {
            this.getBillDeskTxnData()
        }
        else if (type === 'PaymentLink') {
            this.GetPaymentLinkData()
        }
    }
    close() {
        this.setState({ addPoup: false })
    }
    openAddPopup() {
        this.setState({ addPoup: true })
    }

    GetPaymentLinkData() {
        const { userDetail } = this.props

        this.setState({ loaderState: true })
        GetGeneratedPaymentLinkHistory(userDetail.loanId, (callback) => {
            if (callback) {
                if (callback?.length > 0) {
                    this.setState({ PaymentLinkData: callback, loaderState: false })
                } else {
                    this.setState({ loaderState: false })
                }
            } else {
                this.setState({ loaderState: false })
            }
        })
    }

    handleExpireBtnchange(e) {
        this.setState({
            confirmationText: "Are You Sure To Delete this Payment Link",
            confirmationPopupState: true,
        })
    }

    convertDate(Dates) {
        let date = new Date(Dates);
        let day = date.getDate();
        let month = date.getMonth() + 1;
        let year = date.getFullYear();
        let hours = date.getHours();
        let minutes = date.getMinutes();
        if (day < 10) {
            day = '0' + day;
        }
        if (month < 10) {
            month = '0' + month;
        }
        if (hours < 10) {
            hours = '0' + hours;
        }
        if (minutes < 10) {
            minutes = '0' + minutes;
        }
        return `${day}-${month}-${year} ${hours}:${minutes}`;
    }

    render() {
        const { allDetail, userDetail } = this.props
        return (
            <div>
                {this.state.popupState == true ?
                    <Popup
                        closePopup={this.closePopup.bind(this)}
                        popupStatus={this.state.popupStatus}
                    />
                    : ""}
                <TransDetails
                    openAddPopup={this.openAddPopup.bind(this)}
                    close={this.close.bind(this)}
                    addPoup={this.state.addPoup}
                    editTransHandler={this.editTransHandler.bind(this)}
                    saveTransHandler={this.saveTransHandler.bind(this)}
                    transDetail={this.state.transDetail}
                    trnsDateState={this.state.trnsDateState}
                    details={this.state.details}
                    openPopup={this.openPopup.bind(this)}
                    DeleteTransaction={this.DeleteTransaction.bind(this)}
                    billDeskData={this.state.billDeskData}
                    viewPage={this.state.viewPage}
                    pageHandler={this.pageHandler.bind(this)}
                    PaymentLinkData={this.state.PaymentLinkData}
                    PaymentLinkcheckbox={this.state.PaymentLinkcheckbox}
                    handleExpireBtnchange={this.handleExpireBtnchange.bind(this)}
                    convertDate={this.convertDate.bind(this)}
                />
                {this.state.updatePopupState == true ?
                    <UpdateTransaction
                        getPopupState={this.getPopupState.bind(this)}
                        transactionIdUpdate={this.state.transactionIdUpdate}
                        userDetail={userDetail}
                        getConfirmPopupState={this.getConfirmPopupState.bind(this)}
                    />
                    : ""}
                {this.state.confirmationPopupState == true ?
                    <ConfirmationPopup
                        cancelLoanConfirm={this.cancelLoanConfirm.bind(this)}
                        confirmationText={this.state.confirmationText}
                    />
                    : ""}
                {this.state.loaderState ?
                    <Loader />
                    : ""}
            </div>
        )
    }
    sendHandler(type) {
        this.setState({
            pageViewState: type,
        })
    }

    DeleteTransaction(detail) {
        this.setState({
            confirmationText: "Are You Sure To Delete this Transaction",
            confirmationPopupState: true,
            transactionDataForDelete: detail
        })
    }

    cancelLoanConfirm(confirmType) {
        const { allDetail, userDetail, admin } = this.props
        if (confirmType == 'yes' && this.state.viewPage === 'PaymentLink') {
            DeleteGeneratedPaymentLink(allDetail.loanId, admin.emailId, (callback) => {
                if (callback == 'success') {
                    this.setState({
                        confirmationPopupState: false,
                        popupState: true,
                        popupStatus: 'Payment Link Deleted Successfully',
                    })
                    this.removePopup();
                    GetPaymentLinkData();
                }
            })
        }
        else if (confirmType == 'yes') {
            fetch(APIS.DELETE_TRANSACTION + this.state.transactionDataForDelete.transactionId + '&loanId=' + allDetail.loanId + '&empId=' + admin.emailId)
                .then(res => res.text())
                .then(text => {
                    if (text == 'success') {
                        getTransactionVyLoanId(userDetail.loanId,
                            (callBack) => {
                                this.setState({
                                    details: callBack,
                                    confirmationPopupState: false,
                                    popupState: true,
                                    popupStatus: 'Transaction Deleted Successfully',
                                })
                                this.removePopup();
                            })
                    }
                })
        } else if (confirmType == 'no') {
            this.setState({
                confirmationPopupState: false
            })
        }
    }

    componentWillMount() {
        const { allDetail, userDetail } = this.props
        getTransactionVyLoanId(userDetail.loanId,
            (callBack) => {
                this.setState({
                    details: callBack
                })
            })
    }
    removePopup() {
        setTimeout(function () {
            this.setState({ popupState: false });
        }.bind(this), 5000)
    }

    closePopup() {
        this.setState({ popupState: false });
    }

    getConfirmPopupState(state, msg) {
        this.setState({
            popupState: state,
            popupStatus: msg,
        })
        this.removePopup();
    }

    openPopup(e, transactionId) {
        this.setState({
            updatePopupState: true,
            transactionIdUpdate: transactionId
        })
    }

    getPopupState(state, details) {
        this.setState({
            updatePopupState: state,
            details: details
        })
    }

    editTransHandler(e, transType) {
        let transDetail = Object.assign({}, this.state.transDetail);
        if (transType == 'trnsId') {
            transDetail.trnsIdState = e.target.value
        } else if (transType == 'trnsDate') {
            transDetail.trnsDateState = _preFormatDate(e);
            this.setState({ trnsDateState: e })
        } else if (transType == 'trnsMode') {
            transDetail.trnsModeState = e.target.value
        } else if (transType == 'trnsAmount') {
            transDetail.trnsAmountState = e.target.value
        }
        this.setState({
            transDetail
        })
    }
    saveTransHandler() {
        const { userDetail, admin } = this.props
        fetchTransaction(userDetail.userId, userDetail.loanId, this.state.transDetail.trnsIdState, this.state.transDetail.trnsAmountState, this.state.transDetail.trnsDateState, this.state.transDetail.trnsModeState, admin,
            (callBack) => {
                this.setState({
                    details: [...this.state.details, callBack],
                    popupState: true,
                    popupStatus: 'Transaction Details save successfully',
                })
                this.removePopup()
            });
    }
}

export default Transaction;