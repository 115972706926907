import React, { Component } from 'react'
import ScheduledCallUi from '../../../presentationals/Admin/IVR/ScheduledCall.component';
import { APIS } from '../../../../utils/api-factory'
import { getUserDetailApiAdmin } from '../AdminActionCreator.component'
import UserDetail from '../CustomerPages/UserDetailMain.container';
import { _formatDateInYMD } from '../../../../utils/validation'
import Popup from '../../../presentationals/Popup/Popup.component';
import moment from 'moment'

class ScheduledCall extends Component {
    constructor(props) {
        super(props);
        this.state = {
            scheduledLoanData: '',
            FilteredData: [],
            pageIndexToShow: 0,
            actionButtonState: false,
            userState: true,
            viewState: false,
            allDetail: null,
            userDetail: null,
            popupState: false,
            popupStatus: '',
            searchByDateShow: '',
            searchByDate: _formatDateInYMD(new Date())
        }

    }

    componentWillMount() {
        fetch(APIS.GET_SCHEDULED_LOAN_DATA + this.state.searchByDate)
            .then(res => res.json())
            .then(json => {
                this.setState({ scheduledLoanData: json })
            })

    }


    render() {
        const { admin } = this.props;
        return (
            <div className="full-height">
                {this.state.popupState == true ?
                    <Popup
                        closePopup={this.closePopup.bind(this)}
                        popupStatus={this.state.popupStatus}
                    />
                    : ""}
                {this.state.userState == true ?
                    <ScheduledCallUi
                        scheduledLoanData={this.state.scheduledLoanData}
                        getFilterValue={this.getFilterValue.bind(this)}
                        FilteredData={this.state.FilteredData}
                        getPageValue={this.getPageValue.bind(this)}
                        pageIndexToShow={this.state.pageIndexToShow}
                        admin={admin}
                        dateFilter={this.dateFilter.bind(this)}
                        searchByDate={this.state.searchByDate}
                        userDetailHandler={this.userDetailHandler.bind(this)}
                    />
                    : null}

                {this.state.viewState == true ?
                    <UserDetail
                        allDetail={this.state.allDetail}
                        userDetail={this.state.userDetail}
                        admin={admin}
                        backHandler={this.backHandler.bind(this)}
                        actionButtonState={this.state.actionButtonState}
                    />
                    : null}
            </div>
        )
    }

    dateFilter(e) {
        this.setState({
            searchByDateShow: e,
            searchByDate: _formatDateInYMD(e)
        })
        fetch(APIS.GET_SCHEDULED_LOAN_DATA + _formatDateInYMD(e))
            .then(res => res.json())
            .then(json => {
                this.setState({ scheduledLoanData: json })
            })
    }

    removePopup() {
        setTimeout(function () {
            this.setState({ popupState: false });
        }.bind(this), 5000)
    }

    closePopup() {
        this.setState({ popupState: false });
    }

    backHandler() {
        this.setState({
            userState: true,
            viewState: false,
        })
    }

    userDetailHandler(allDetail) {
        if (allDetail.UserID != '' && allDetail.UserID != null && allDetail.UserID != undefined) {
            getUserDetailApiAdmin(allDetail.UserID,
                (callBack) => {
                    this.setState({
                        userState: false,
                        viewState: true,
                        allDetail: callBack,
                        userDetail: allDetail
                    })
                })
        } else {
            this.setState({
                popupState: true,
                popupStatus: 'User not registered',
            })
            this.removePopup();
        }
    }

    getFilterValue(filtered) {
        this.setState({ FilteredData: filtered })
    }

    getPageValue(pageIndex) {
        this.setState({ pageIndexToShow: pageIndex })
    }
}

export default ScheduledCall;