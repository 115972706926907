import React from 'react'
import { ACCOUNTDETAIL, MAIL_STRING } from '../../../utils/Constant'
import moment from 'moment';

// subject: Phocket: Revised repayment amount as on {moment(new Date()).format('DD-MM-YYYY')}.

const RevisedRepaymentAmount = ({ allDetail }) => (
    <div>
        <div>Hello {allDetail != null && allDetail.userName != null ? allDetail.userName : ""},</div>
        <br />
        <div>Kindly note the repayment amount as on {moment(new Date()).format('DD-MM-YYYY')}.</div>
        <div>Amount: {allDetail != null && allDetail.payOnToday != null ? allDetail.payOnToday : ""}</div>
        <br />
        <div>You can pay through Netbanking to this account:</div>
        <br />
        <div>{ACCOUNTDETAIL.COMPANY_NAME}</div>
        <div>{ACCOUNTDETAIL.BANK_NAME}</div>
        <div>{ACCOUNTDETAIL.BRANCH_NAME}</div>
        <div>{ACCOUNTDETAIL.ACCOUNT_NUMBER}</div>
        <div>{ACCOUNTDETAIL.IFSC_CODE}</div>
        <br />
        <div>Best Regards,</div>
        <div>Team Micromoney</div>
        <div>{MAIL_STRING.MOBILE_NO}</div>
    </div>
)

export default RevisedRepaymentAmount