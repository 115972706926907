import React from 'react'
import CibilSection from './ScoringDataView'
import DBRsection from './dbrDataView'
import { getallDBRdataApi } from '../AdminActionCreator.component'
import Nodata from '../../../../images/nodata.png'

class ScoringSection extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            flagData: ''
        }
    }
    getData() {

    }
    componentDidMount() {
        this.getFlagData()
    }
    getFlagData() {
        const { allDetail } = this.props

        getallDBRdataApi(allDetail.userId, callback => {

            if (callback !== '' && callback !== null ) {
                this.setState({ flagData: callback })

            }
        })


    }

    render() {
        const { allDetail, getCreditPopupState, creditScoringPopup, admin } = this.props
        return (
            <>
                {this.state.flagData !== '' && this.state.flagData !== null && this.state.flagData !== undefined ?
                    <div className="container-fluid " style={{ marginTop: '20px' }}>
                        <div className="card-credit-score">
                            <div className="row">
                                <div className='col-sm-4 col-xs-12'>
                                    <label>Our Decision</label>
                                    <div style={{ fontSize: '22px', color: (this.state.flagData.decision == 'For Quality Check' || this.state.flagData.decision == 'For Quality Check') ? '#9d8420' : this.state.flagData.decision == 'Rejected' ? '#fee5e3' : this.state.flagData.decision == 'Approved' ? '#4CC78C' : "", }}><b>{this.state.flagData.decision}</b></div>
                                </div>
                                <div className='col-sm-4 col-xs-12'>
                                    {this.state.flagData.decision === 'Approved' && this.state.flagData.approvedAmount
                                        ?
                                        <div className='text-center' style={{ marginBottom: '15px' }}>
                                            <label style={{ color: "" }}><b>{this.state.flagData.approvedAmount}</b> </label>
                                            {this.state.flagData.message ?
                                                <div style={{ fontSize: '18px', }} className='text-center'><b>{this.state.flagData.message}</b> </div>

                                                : ""}
                                        </div>
                                        : ""}
                                </div>
                                <div className='col-sm-4 col-xs-12 ' style={{ textAlign: 'right' }}>
                                    <label>DBR : &nbsp;&nbsp;<b style={{ fontSize: '18px' }}>{this.state.flagData.dbrpercentage}%</b></label>
                                    <br />
                                    <label> Scorecard: &nbsp;&nbsp;<b style={{ fontSize: '18px' }}>{this.state.flagData.scoring}</b></label>

                                </div>

                            </div>
                        </div>
                        <div className='row' >

                            <div className='col-sm-6 col-xs-12'>
                                <div className='credit-second-box-small' > 

                                    <CibilSection
                                        getData={this.getData.bind(this)}
                                        allDetail={allDetail}
                                        getCreditPopupState={getCreditPopupState}
                                        creditScoringPopup={creditScoringPopup}
                                        admin={admin}
                                        flagData={this.state.flagData}
                                    />
                                </div>
                            </div>
                            <div className='col-sm-6 col-xs-12'>
                                <div className='credit-second-box-small' > 

                                    <DBRsection allDetail={allDetail} getFlagData={this.getFlagData.bind(this)} />
                                </div>
                            </div>
                            {/* <hr/> */}
                        </div>
                    </div>
                    : <div className='col-sm-12 col-xs-12 text-center'><img src={Nodata} style={{ width: '18%', marginTop: '20px' }} /></div>
                }            </>
        )
    }
}
export default ScoringSection