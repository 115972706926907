import React from 'react'

const userCibil = ({ allDetail, cibilData }) => (
    <div className="container-fluid crm-user-cibil">
        {cibilData != '' ?
            <div className="row">
                {Object.keys(cibilData).map((heads, i) => {
                    if (cibilData[heads] != "" && cibilData[heads] != null) {
                        return (
                            <div key={i} className="col-sm-4 col-xs-12">
                                <p className="text-capitalize">
                                    <b>{heads}</b>&nbsp;:&nbsp;
                                {cibilData[heads]}
                                </p>
                            </div>
                        )
                    }
                })}
            </div>
            : null}
        {allDetail != null && allDetail.documentList != null && allDetail.documentList.cibilUrl != null ?
            <object data={allDetail.documentList.cibilUrl} type="application/pdf"></object>
            : <h3 className="blue-text text-center">No Cibil Report Uploaded</h3>}
    </div>
)

export default userCibil;