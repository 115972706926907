import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getistByNumber, getlistByEmail, getlistByApplication } from '../AdminActionCreator.component'
import SearchSelect from '../../../presentationals/Admin/ApplicationLeads/SearchSelect.component'

class Selectsearch extends Component {
    constructor(props) {
        super(props);
        this.myRef = React.createRef();

        this.state = {
            type: 'applicationNo',
            searchByAppNo: '',
            currentRequest: 0,
            applicationId: ''
        }
    }


    render() {
        const { admin } = this.props
        return (
            <SearchSelect
                searchByAppNo={this.state.searchByAppNo}
                admin={admin}
                ref={this.myRef}
                dataFilter={this.dataFilter.bind(this)}
                filterHandler={this.filterHandler.bind(this)}

            />
        )
    }

    filterHandler(e) {

        this.setState({ type: e.target.value })
    }
    setSearchApplicationId() {
        setTimeout(function () {
            this.applicationIdSearchFunction()
        }.bind(this), 2000)
    }

    applicationIdSearchFunction() {
        const { admin } = this.props

        this.props.getLoaderState(true)

        this.props.getlistByApplication(this.state.applicationId, 1, '', admin, true,
            (callBack) => {
                this.props.getLoaderState(false)
            })
    }
    dataFilter(e) {
        const { admin } = this.props
        if (this.state.type == "applicationNo") {
            this.setState({ searchByAppNo: e.target.value })
            this.setState({ applicationId: e.target.value })

            if (e.target.value.length >= 7) {
                var testVariable = e.target.value;
                var oldVar = this.state.applicationId;
                this.setSearchApplicationId()

            }
        } else if (this.state.type == "email") {
            this.setState({ searchByAppNo: e.target.value })
            if (e.target.value.includes("@")) {
                this.props.getLoaderState(true)
                this.props.getlistByEmail(e.target.value, 1, '', admin,
                    (callBack) => {
                        this.props.getLoaderState(false)
                    })
            }
        } else if (this.state.type == "mobileNo") {
            this.setState({ searchByAppNo: e.target.value })
            if (e.target.value.length > 9) {
                this.props.getLoaderState(true)
                this.props.getistByNumber(e.target.value, 1, '', admin,
                    (callBack) => {

                        this.props.getLoaderState(false)
                    })
            }
        } else if (this.state.type === 'state' || this.state.type === 'name' || this.state.type === 'device') {
            this.props.commonSearchHandler(e)
        }
        if (e.target.value == "") {
            this.props.getLoanLead()
        }
    }

}
const mapStateToProps = ({ allUserDetail, loanApprovalLead }) => {
    return {
        allUserDetail: allUserDetail,
        loanApprovalLead: loanApprovalLead,
    };
};


const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        getistByNumber,
        getlistByEmail,
        getlistByApplication,
    }, dispatch);
};


export default connect(mapStateToProps, mapDispatchToProps)(Selectsearch);
