import React from 'react'

const FBRegistration = ({ FBRegistration, FBRegistrationHandler, fieldBoyDetail,
    fieldBoyData, updateData, ViewDistance, fieldBoyDIstance, distancePopup,
    closeDistancePopup, startTimeHandler, deleteData, admin }) => (
        <div className="container-fluid">
            <div className="max-width-750px center-block">
                <div className="row">
                    <h3 className="text-center blue-text">Field Boy Registration</h3>
                </div>
                <div className="row form-group">
                    <div className="col-sm-6 col-xs-12">
                        <label className="font-12px">Full Name</label>
                        <input type="text" value={fieldBoyDetail.FBFullNameState} className="form-control" placeholder="Enter Full Name" onChange={(e) => FBRegistration(e, 'FBFullName')} />
                    </div>
                    <div className="col-sm-6 col-xs-12">
                        <label className="font-12px">Mobile No.</label>
                        <input type="number" value={fieldBoyDetail.FBMobileState} className="form-control" placeholder="Enter Mobile Number" onChange={(e) => FBRegistration(e, 'FBMobile')} />
                    </div>
                </div>
                <div className="row form-group">
                    <div className="col-sm-6 col-xs-12">
                        <label className="font-12px">Address</label>
                        <input type="text" value={fieldBoyDetail.FBAddressState} className="form-control" placeholder="Enter Address" onChange={(e) => FBRegistration(e, 'FBAddress')} />
                    </div>
                    <div className="col-sm-6 col-xs-12">
                        <label className="font-12px">Email Id</label>
                        <input type="email" value={fieldBoyDetail.FBEmailState} className="form-control" placeholder="Enter Email Id" onChange={(e) => FBRegistration(e, 'FBEmail')} />
                    </div>
                </div>
                <div className="row form-group">
                    <div className="col-sm-6 col-xs-12">
                        <label className="font-12px">Password for Login</label>
                        <input type="text" value={fieldBoyDetail.FBPasswordState} className="form-control" placeholder="Enter Password" onChange={(e) => FBRegistration(e, 'FBPassword')} />
                    </div>
                </div>

                <div className="row text-center form-group">
                    <button className="profile-save-btn" id="transSubmitBtn" onClick={e => FBRegistrationHandler()}>
                        <span className="fa fa-floppy-o"></span>&nbsp;&nbsp;Save
            </button>
                </div>
            </div>

            <div className="row overflow-auto">
                <div className="col-xs-12">
                    {fieldBoyData != null && fieldBoyData != '' ?
                        <table className="text-center loan-detail-table">
                            <thead>
                                <tr className="background-blue text-white">
                                    <th>ID</th>
                                    <th>Name</th>
                                    <th>Contact No.</th>
                                    <th>Address</th>
                                    <th>User Id</th>
                                    <th>password</th>
                                    <th>Created By</th>
                                    <th>Distance</th>
                                    <th>Edit</th>
                                    {admin.emailId == 'SuperUser' ?
                                        <th>Delete</th>
                                        : null}
                                    <th>Start</th>
                                </tr>
                            </thead>
                            <tbody>
                                {fieldBoyData.map((data, i) => {
                                    return (
                                        <tr key={i} className="text-black">
                                            <td>{data.id}</td>
                                            <td>{data.name}</td>
                                            <td>{data.phonenumber}</td>
                                            <td>{data.address}</td>
                                            <td>{data.userid}</td>
                                            <td>{data.password}</td>
                                            <td>{data.createdby}</td>
                                            <td onClick={e => ViewDistance(data)}><span className="fa fa-map-o pointer" /></td>
                                            <td onClick={e => updateData(data)}><span className="fa fa-pencil-square-o pointer" /></td>
                                            {admin.emailId == 'SuperUser' ?
                                                <td onClick={e => deleteData(data)}><span className="fa fa-trash pointer" /></td>
                                                : null}
                                            <td onClick={e => startTimeHandler(data)}><span className="fa fa-hourglass-start pointer" /></td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                        : ""}
                </div>
            </div>
            {distancePopup ?
                <div>
                    <div className="background-blur" onClick={e => closeDistancePopup()}></div>
                    <div className="popup-main small-popup container-fluid max-width-500px dbl-border">
                        <h3 className="text-center blue-text">Distance</h3>
                        {fieldBoyDIstance != null && fieldBoyDIstance != '' ?
                            <table className="text-center loan-detail-table form-group">
                                <thead>
                                    <tr>
                                        <th>Date</th>
                                        <th>Distance</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {Object.keys(fieldBoyDIstance).map((mainKeys, i) => {
                                        return (
                                            <tr key={i}>
                                                <td>{mainKeys.split('T')[0]}</td>
                                                <td>{Number(fieldBoyDIstance[mainKeys]).toFixed(3)}</td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                            : null}
                    </div>
                </div>
                : null}

        </div>
    )

export default FBRegistration;