import React, { Component } from 'react';
import RecoveryMsgUi from '../../../presentationals/Admin/Recovery/RecoveryComment.component';
import Popup from '../../../presentationals/Popup/Popup.component';
import { saveRecoveryCommentAPI } from '../AdminActionCreator.component'

class RecoveryMsgPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            popupState: false,
            popupStatus: '',
            commentBody: ''
        }
    }

    componentWillMount() {

    }

    render() {
        return (
            <div className="full-height">
                {this.state.popupState == true ?
                    <Popup
                        closePopup={this.closePopup.bind(this)}
                        popupStatus={this.state.popupStatus}
                    />
                    : ""}
                <RecoveryMsgUi
                    recoveryCommentChange={this.recoveryCommentChange.bind(this)}
                    recoveryCommentHandler={this.recoveryCommentHandler.bind(this)}
                />
            </div>
        )
    }

    removePopup() {
        setTimeout(function () {
            this.setState({ popupState: false });
        }.bind(this), 5000)
    }

    closePopup() {
        this.setState({ popupState: false });
    }

    recoveryCommentChange(e) {
        this.setState({ commentBody: e.target.value })
    }

    recoveryCommentHandler() {
        const { dataForCommunication, admin } = this.props;
        if (this.state.commentBody != '') {
            saveRecoveryCommentAPI(dataForCommunication, this.state.commentBody,'', admin,
                (callBack) => {
                    if (callBack == 'success') {
                        this.setState({
                            popupState: true,
                            popupStatus: 'Comment save Successfully',
                        })
                        this.removePopup();
                    } else {
                        this.setState({
                            popupState: true,
                            popupStatus: 'Please Try Again',
                        })
                        this.removePopup();
                    }
                })
        } else {
            this.setState({
                popupState: true,
                popupStatus: 'Please Type comment first',

            })
            this.removePopup();
        }
    }

}

export default RecoveryMsgPage;