import React, { Component } from 'react'
import Fields from './Fields.container'
import KeysCalculations from './KeysCalculations.container'
import RegisteredNbfc from './RegisteredNbfc.container'
import Registration from './Registration.container'
import Rights from './Rights.container'

class Tabs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pageViewState: 'registartionPage'
        }
    }

    render() {
        const { allDetail, admin, msgData, loadarState, deviceData, deviceDataLoader } = this.props;
        return (
            <div className="full-height container-fluid">
                <div className="row">
                    <div className="edit-profile-menu col-xs-12 bt-color nbfc-btn-group tex-center">
                        <div className="col-sm-2 col-xs-12"><button className={this.state.pageViewState == 'registartionPage' ? 'active' : ''} onClick={(e) => this.sendHandler('registartionPage')}>Registration</button></div>
                        <div className="col-sm-2 col-xs-12"><button className={this.state.pageViewState == 'registeredPage' ? 'active' : ''} onClick={(e) => this.sendHandler('registeredPage')}>Registered Nbfc</button></div>
                        <div className="col-sm-2 col-xs-12"><button className={this.state.pageViewState == 'feidsPage' ? 'active' : ''} onClick={(e) => this.sendHandler('feidsPage')}>Feilds</button></div>
                        <div className="col-sm-2 col-xs-12"><button className={this.state.pageViewState == 'rightsPage' ? 'active' : ''} onClick={(e) => this.sendHandler('rightsPage')}>Rights</button></div>
                        <div className="col-sm-2 col-xs-12"><button className={this.state.pageViewState == 'keycalculationPage' ? 'active' : ''} onClick={(e) => this.sendHandler('keycalculationPage')}>Key Calculation</button></div>
                    </div>
                </div>
                <div className="row">
                    {this.state.pageViewState == 'feidsPage' ?
                        <Fields
                            allDetail={allDetail}
                        />
                        : this.state.pageViewState == 'registartionPage' ?
                            <Registration
                                admin={admin}
                                loadarState={loadarState}
                                allDetail={allDetail}
                                msgData={msgData}
                            />
                            : this.state.pageViewState == 'registeredPage' ?
                                <RegisteredNbfc
                                    admin={admin}
                                    allDetail={allDetail}
                                />
                                : this.state.pageViewState == 'rightsPage' ?
                                    <Rights
                                        allDetail={allDetail} />
                                    : this.state.pageViewState == 'keycalculationPage' ?
                                        <KeysCalculations
                                            admin={admin}
                                            allDetail={allDetail} />

                                        : null}
                </div>
            </div>
        )
    }


    sendHandler(type) {
        this.setState({
            pageViewState: type,
        })
    }
}

export default Tabs;
