import React from 'react'
import { ACCOUNTDETAIL, MAIL_STRING } from '../../../utils/Constant'

// subject: Reminder: Phocket Loan Due (without NACH).

const LoanDueWithoutNach = ({ allDetail }) => (
    <div>
        <div>Hi {allDetail != null && allDetail.userName != null ? allDetail.userName : ""},</div>
        <br />
        <div>Kindly note the repayment amount due on {allDetail != null && allDetail.loanPaybackDate != null ? allDetail.loanPaybackDate.split(' ')[0] : ""}</div>
        <br />
        <div>Amount : {allDetail != null && allDetail.loanPaybackAmount != null ? allDetail.loanPaybackAmount : ""}.</div>
        <br />
        <div>You can pay through Netbanking to this account: </div>
        <br />
        <div>{ACCOUNTDETAIL.COMPANY_NAME}</div>
        <div>{ACCOUNTDETAIL.BANK_NAME}</div>
        <div>{ACCOUNTDETAIL.BRANCH_NAME}</div>
        <div>{ACCOUNTDETAIL.ACCOUNT_NUMBER}</div>
        <div>{ACCOUNTDETAIL.IFSC_CODE}</div>
        <br />
        <div>Best Regards,</div>
        <div>Team Micromoney</div>
        <div>{MAIL_STRING.MOBILE_NO}</div>
    </div>
)

export default LoanDueWithoutNach