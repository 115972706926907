import React from 'react'
import Ui from '../../../presentationals/CardsUtility/fundsDeatils.component'
import { addFundsApi, getCardFundDetails } from './actionCreator'
import Loader from '../../../presentationals/Loader/Loader.component'
import SidePopup from '../../../presentationals/Popup/Popup.component'
class Funds extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            addState: false,
            listState: true,
            amount: 0,
            fundDetails: '',
            loaderState: false,
            popupState: false,
            popupStatus: '',
            sourceOfFunds: ''
        }

    }
    removePopup() {
        setTimeout(function () {
            this.setState({ popupState: false });
        }.bind(this), 5000)
    }
    closePopup() {
        this.setState({ popupState: false })
    }
    getCardFundDetailsApi() {
        if (localStorage.getItem('cardId') !== 'null' && localStorage.getItem('cardId') !== '') {
            // console.log('1')
            this.setState({ loaderState: true })
            getCardFundDetails(localStorage.getItem('cardId'), callBack => {
                // console.log(callBack, 'callback')
                this.setState({ fundDetails: callBack, loaderState: false })
            })
        } else {
            if (this.props.cardId !== null && this.props.cardId !== undefined && this.props.cardId !== '') {
                // console.log('2')

                this.setState({ loaderState: true })
                getCardFundDetails(this.props.cardId, callBack => {
                    // console.log(callBack, 'callback')
                    this.setState({ fundDetails: callBack, loaderState: false })
                })
            } else {
                this.setState({ popupState: true, popupStatus: 'Card Id is not present !', loaderState: false }, () => this.removePopup())

            }
        }
    }
    componentDidMount() {

        if (this.props.cardId !== null && this.props.cardId !== undefined) {
            this.getCardFundDetailsApi()
        }
    }
    onChangeHandler(e, type) {
        if (type === 'amount') {
            this.setState({
                amount: e.target.value
            })
        }
        if (type === 'source') {
            this.setState({
                sourceOfFunds: e.target.value
            })
        }
    }
    addFunds(type) {
        if (type === 'add') {
            this.setState({
                addState: true,
                listState: false
            })
        }
        if (type === 'back') {
            this.setState({
                addState: false,
                listState: true
            })
        }
    }
    submit() {
        if (this.state.amount !== '' && this.state.sourceOfFunds !== '') {
            this.setState({ loaderState: true })

            addFundsApi(this.props.userDetailsByUserId, this.state.amount, this.state.sourceOfFunds, callBack => {
                // console.log(callBack)
                if (callBack == 'success') {
                    this.setState({ popupState: true, popupStatus: 'Added Successfully !', loaderState: false }, () => this.removePopup())

                } else {
                    this.setState({ popupState: true, popupStatus: 'Please Try agian later !', loaderState: false }, () => this.removePopup())

                }
            })
        } else {
            this.setState({ popupState: true, popupStatus: 'Please Enter All Details !', loaderState: false }, () => this.removePopup())

        }
    }
    render() {
        return (
            <div>
                <div className='text-center'>
                    {this.state.listState ?
                        <button className='profile-save-btn' onClick={() => this.addFunds('add')}>Add Funds</button>
                        : ""}
                    {this.state.addState ?
                        <button className='profile-save-btn' onClick={() => this.addFunds('back')}>View Funds</button>
                        : ""}

                </div>
                <Ui
                    // fundDetails={this.state.fundDetails}
                    state={this.state}
                    submit={this.submit.bind(this)}
                    onChangeHandler={this.onChangeHandler.bind(this)} />
                {this.state.loaderState ?
                    <Loader />
                    : ""}
                {this.state.popupState ?
                    <SidePopup popupStatus={this.state.popupStatus} closePopup={this.closePopup.bind(this)} />

                    : ""}
            </div>
        )
    }
}
export default Funds