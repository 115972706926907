import React from "react";
import { useState } from "react";
import { getAgreegatorListApi, saveBugetPlan } from "./actioncreatormarketing";
import { useEffect } from "react";
import { getPlaningDetailsOfAgreegator } from "./maketingComponents/actioncreator";
import moment from "moment";
import Loader from '../../presentationals/Loader/Loader.component'
import SidePopup from "./maketingComponents/screens/modals/sidepopup";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

const BudgetPlan = ({ editdata,pageViewHandler }) => {
    const [budgetData, setbudgetData] = useState({})
    const [errorMessage, seterrorMessage] = useState({})
    const [showEmail, setShowEmail] = useState(false)
    const [agreegatorData, setAgreegatorData] = useState(false)
    const [objectData, setObjectData] = useState('');
    const [loaderState, setLoader] = useState('')
    const [popupStatus, setpopupStatus] = useState('')
    const [popupState, setpopupState] = useState('')
    const [popupType, setpopupType] = useState('')
    const changeHandler = (e, type) => {
        let changeData = Object.assign({}, budgetData)
        changeData[type] = e.target.value
        setbudgetData(changeData)

    }
    const checkBoxHandler = (e) => {
        setShowEmail(!showEmail)
        let changeData = Object.assign({}, budgetData)
        changeData.notification = !showEmail
        setbudgetData(changeData)
    }
    const removePopup = () => {
        setTimeout(() => {
            setpopupState(false)
        }, 5000);
    }
    const saveData = () => {
        saveBugetPlan(budgetData, callback => {
            console.log(callback)
            if (callback === 'success') {
                getAgreegatorData()
                setLoader(false)
                setpopupType('success')
                setpopupStatus('Data Save Successfully !')
                setpopupState(true)
                removePopup()
            } else {
                setLoader(false)
                setpopupType('error')
                setpopupStatus('Please try again later !')
                setpopupState(true)
                removePopup()
            }
        })
    }
    const getAgreegatorData = () => {
        getAgreegatorListApi(callback => {
            setAgreegatorData(callback)
        })
    }
    useEffect(() => {
        getAgreegatorData()
        if (editdata) {
            getData(editdata.partnerId)
        }
    }, [])
    const getData = (id) => {
        getPlaningDetailsOfAgreegator(id, callback => {
            setObjectData(callback)
        })
    }
    const editdataConfig = (data) => {
        console.log(data)
        let changeData = Object.assign({}, budgetData)
        changeData.notification = data.notification,
            changeData.expectedLeadCount = data.expectedLeadCount,
            changeData.expectedDisbursedCount = data.expectedDisbursedCount,
            changeData.emailId = data.emailId,
            changeData.eDate = data.eDate,
            changeData.aggregatorId = data.aggregatorId,
            changeData.amount = data.amount,
            changeData.id = data.id,
            changeData.sDate = data.sDate,
            changeData.addedOn = data.addedOn

        setbudgetData(changeData)
        console.log(changeData)

    }

    return (
        <div className="marketing-css container-fluid full-height" >
            {loaderState ? <Loader /> : ""}
            {popupState ? <SidePopup popupType={popupType} msg={popupStatus} /> : ""}

            {/* <Bootstrap />  */}
            {/* <div className="row " style={{ marginTop: '20px', marginLeft: '20px' }}>
                <h4 className="mb-0">Budget Plan</h4>
            </div> */}
               <div className="row " style={{ marginTop: '20px', marginLeft: '20px' }}>
                    <h4 className="mb-0"  style={{display:'flex'}}><ArrowBackIosNewIcon onClick={(e) => pageViewHandler(e, 'landingPage')} style={{ cursor: 'pointer',fontSize:'19px' }} />&nbsp; <b onClick={(e) => pageViewHandler(e, 'landingPage')}>Add Budget Plan</b></h4>
                </div>
                <hr className="mb-5 mt-2" />
            {objectData ?
                <div className="">

                    <div className='credit-table' style={{ overflow: 'auto' }}>
                        <table>
                            <thead style={{ background: '#b9d8f9', color: '#000' }}>
                                <tr className="thead-class">
                                    <th>Added On</th>
                                    <th>Updated On</th>
                                    <th> Amount</th>
                                    <th>Expected Disbursed </th>
                                    <th>Expected Lead Count </th>
                                    <th>Start Date </th>
                                    <th>End Date</th>

                                    <th>Edit</th>

                                </tr>
                            </thead>
                            <tbody>
                                {objectData && objectData.map((data, i) => {
                                    return (
                                        <tr>
                                            <td>{data.addedOn ? moment(data.addedOn).format('DD-MM-YYYY') : ""}</td>
                                            <td>{data.updatedOn ? moment(data.updatedOn).format('DD-MM-YYYY') : ""}</td>
                                            <td>{data.amount}</td>
                                            <td>{data.expectedDisbursedCount}</td>
                                            <td>{data.expectedLeadCount} </td>
                                            <td>{data.sDate ? moment(data.sDate).format('DD-MM-YYYY') : ""} </td>
                                            <td>{data.eDate ? moment(data.eDate).format('DD-MM-YYYY') : ""} </td>

                                            <td><i className="fa fa-edit" style={{ cursor: 'pointer' }} onClick={() => editdataConfig(data)}></i></td>
                                        </tr>
                                    )
                                })}

                            </tbody>
                        </table>
                    </div>
                </div>
                : ""}
     
         
            <div className="collection-box " style={{ minHeight: '500px' }}>
                <div className="row">
                    <div className="col-sm-6 col-xs-12" style={{ marginTop: '20px' }}>
                        <label className="form-label">Aggregator &nbsp;<span style={{ color: 'red' }}>*</span></label>
                        <select value={budgetData.aggregatorId} className="form-select" onChange={(e) => changeHandler(e, 'aggregatorId')} >
                            <option value="" >Select Option</option>
                            {agreegatorData && agreegatorData.map((data, i) => {
                                return (
                                    <option value={data.aggregatorId}>{data.companyName}</option>
                                )
                            })}

                        </select>
                        {errorMessage && errorMessage.field === 'aggregatorId' ? <span style={{ color: 'red' }}>{errorMessage.msg}</span> : ""}
                    </div>
                    <div className="col-sm-3 col-xs-12" style={{ marginTop: '20px' }}>
                        <label className="form-label">Start Date&nbsp;<span style={{ color: 'red' }}>*</span></label>
                        <input type="date" value={budgetData.sDate} onChange={(e) => changeHandler(e, 'sDate')} className="form-control" placeholder="" />
                        {errorMessage && errorMessage.field === 'sDate' ? <span style={{ color: 'red' }}>{errorMessage.msg}</span> : ""}

                    </div>

                    <div className="col-sm-3 col-xs-12" style={{ marginTop: '20px' }}>
                        <div className="" >
                            <p style={{ marginTop: window.innerWidth > 1800 ? '53px' : '45px', background: '#000', height: '2px', width: '5%', marginLeft: window.innerWidth > 1800 ? '-68px':'-20px', marginRight: 'auto',  position: 'absolute' }}></p>
                            <label className="form-label">End Date&nbsp;<span style={{ color: 'red' }}>*</span></label>
                            <input type="date" value={budgetData.eDate} onChange={(e) => changeHandler(e, 'eDate')} className="form-control" placeholder="" />
                        </div>
                        {errorMessage && errorMessage.field === 'eDate' ? <span style={{ color: 'red' }}>{errorMessage.msg}</span> : ""}
                    </div>
                </div>
                <div className="row" >
                    <div className="col-sm-6 col-xs-12" style={{ marginTop: window.innerWidth > 1800 ? '40px':'20px' }}>
                        <label className="form-label">Total Budget&nbsp;<span style={{ color: 'red' }}>*</span></label>
                        <input type="text" value={budgetData.amount} onChange={(e) => changeHandler(e, 'amount')} className="form-control rupee-input" placeholder="Enter Total Budget (In Rupees)" />
                        {errorMessage && errorMessage.field === 'amount' ? <span style={{ color: 'red' }}>{errorMessage.msg}</span> : ""}
                    </div>
                    <div className="col-sm-6 col-xs-12" style={{ marginTop: window.innerWidth > 1800 ? '40px':'20px' }}>
                        <label className="form-label">No. of Leads Expected&nbsp;<span style={{ color: 'red' }}>*</span></label>
                        <input type="text" value={budgetData.expectedLeadCount} onChange={(e) => changeHandler(e, 'expectedLeadCount')} className="form-control" placeholder="Enter No. of Leads Expected" />
                        {errorMessage && errorMessage.field === 'expectedLeadCount' ? <span style={{ color: 'red' }}>{errorMessage.msg}</span> : ""}
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-6 col-xs-12" style={{ marginTop: window.innerWidth > 1800 ? '40px':'20px' }}>
                        <label className="form-label">No. of Disbursements Expected&nbsp;<span style={{ color: 'red' }}>*</span></label>
                        <input type="text" value={budgetData.expectedDisbursedCount} onChange={(e) => changeHandler(e, 'expectedDisbursedCount')} className="form-control" placeholder="Enter No. of Disbursements Expected" />
                        {errorMessage && errorMessage.field === 'expectedDisbursedCount' ? <span style={{ color: 'red' }}>{errorMessage.msg}</span> : ""}
                    </div>
                    <div className="col-sm-6 col-xs-12 custom-checkbox" style={{ marginTop: window.innerWidth > 1800 ? '80px':'50px' }}>
                        <div className="checkbox-wrapper-12">
                            <div className="cbx">
                                <input id="cbx-12" type="checkbox" checked={budgetData.notification} onChange={(e) => checkBoxHandler(e)} />
                                <label for="cbx-12">&nbsp;&nbsp;</label>
                                <svg width="15" height="14" viewbox="0 0 15 14" fill="none">
                                    <path d="M2 8.36364L6.23077 12L13 2"></path>
                                </svg>
                                <span style={{ whiteSpace: 'nowrap', marginLeft: '40px' }}>Notify on Budget/Lead Exceed</span>
                            </div>
                            <svg xmlns="http://www.w3.org/2000/svg" version="1.1">
                                <defs>
                                    <filter id="goo-12">
                                        <fegaussianblur in="SourceGraphic" stddeviation="4" result="blur"></fegaussianblur>
                                        <fecolormatrix in="blur" mode="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 22 -7" result="goo-12"></fecolormatrix>
                                        <feblend in="SourceGraphic" in2="goo-12"></feblend>
                                    </filter>
                                </defs>
                            </svg>
                        </div>

                    </div>
                </div>
                {budgetData.notification ?
                    <div className="row">
                        <div className="col-sm-6 col-xs-12" style={{ marginTop: '20px' }}>
                            <label className="form-label">Email-Id&nbsp;<span style={{ color: 'red' }}>*</span></label>
                            <input type="text" value={budgetData.emailId} onChange={(e) => changeHandler(e, 'emailId')} className="form-control email-input" placeholder="Enter Email Id" />
                            {errorMessage && errorMessage.field === 'emailId' ? <span style={{ color: 'red' }}>{errorMessage.msg}</span> : ""}
                        </div>
                    </div>
                    : ""}

                <div className="row " style={{ marginTop: '40px', marginLeft: '10px' }}>
                    <button type="submit" className="btn submit" onClick={() => saveData()} >Save</button>
                </div>



            </div>
        </div>
    )
}
export default BudgetPlan