import React from "react";

const TableComponent = ({ type, finBitDataNew, data }) => (
    <div className="new-table table-new">
        <table>
            <thead>
                <tr>
                    <th className="text-center fixed-header" style={{width:'100px'}}>Analytics pointers</th>
                    {Object.keys(finBitDataNew).map((key, i) => (
                        <th className="text-center fixed-header" style={{width:'100px'}} key={i}>{key.replace(/_/g, ' ')}</th>
                    ))}
                </tr>
            </thead>
            <tbody>
                {data.map((row, rowIndex) => (
                    <tr key={rowIndex}>
                        <th className="text-center fixed-column" style={{width:'50px'}}>{row}</th>
                        {Object.keys(finBitDataNew).map((key, i) => {
                            const detail = finBitDataNew[key];
                            return (
                                <td key={i} style={{width:'50px'}} className="text-center">{detail[row] !== undefined && detail[row] !== null && detail[row] !== '' ? (detail[row]): '-'}</td>
                            );
                        })}
                    </tr>
                ))}
            </tbody>
        </table>
    </div>
);

export default TableComponent;
