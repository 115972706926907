import React, { Component } from 'react'
import UserBasic from '../../../presentationals/Admin/CustomerPages/UserBasic.component'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { APIS } from '../../../../utils/api-factory';
import Loader from '../../../presentationals/Loader/Loader.component'
import CreditScore from './ScoringSection.container'
import Popup from '../../../presentationals/Popup/Popup.component';
import { _formatDateInDash } from '../../../../utils/validation';
import { sendverificationRequestApi } from '../AdminActionCreator.component'
import Confirmation from '../../../presentationals/Popup/ConfirmationPopup.component'
import EditProfile from '../../../containers/Admin/CustomerPages/EditUserDetails.container'
import Documents from '../../../containers/Admin/CustomerPages/UserDocs.container'
import Basic from '../../../containers/Admin/CustomerPages/detailingpage.container'
import Cibil from '../../../containers/Admin/CustomerPages/UserCibil.container'
import Scoring from '../../../containers/Admin/CustomerPages/Scoring.container'
import Crif from '../../../containers/Admin/CustomerPages/Crif.container'
import ConfirmationPopup from '../../../presentationals/Popup/crifConfirmationPopUp.component'
import BadaBuisnessEditProfile from '../../../containers/Admin/CustomerPages/BadabuisnessEdit.container'
// import CreditScore from './UserCreditScoring.container';
import { ROLE } from '../../../../utils/Constant'
import EditProfileTea from '../../../containers/Admin/CustomerPages/EditProfileTea.container'
import BasicTea from '../../../containers/Admin/CustomerPages/BasicTea.container'
import EditLoanId from '../../../containers/Admin/CustomerPages/editloanId'
import Action from './NewActionHandle';

class BasicUser extends Component {
    constructor(props) {
        super(props);
        this.state = {
            confirmationPopup: false,
            confirmationText: '',
            loaderState: false,
            creditScoringPopup: false,
            popupState: false,
            popupStatus: '',
            senderMailId: '',
            allDetail: this.props.allDetail,
            mailIdForVerification: '',
            pageViewState: '',
            linkState: false,
            cibilScoringTabState: false,
            creditScoreTabState: false,
            teaGardenId: '',
            teaGardenData: '',
            teaEdit: false,
            reasonData: ''
        }
    }
    tabsHandler(type, statetoset) {
        // console.log(type)
        if (type == 'cibilScoring') {
            if (this.state.cibilScoringTabState === true) {
                this.setState({ cibilScoringTabState: false })
            } else {
                this.setState({ cibilScoringTabState: true })
            }

        }
        if (type == 'creditScore') {
            if (this.state.creditScoreTabState === true) {
                this.setState({ creditScoreTabState: false })
            } else {
                this.setState({ creditScoreTabState: true })

            }
        }

    }

    userLinkHandler() {
        this.setState({ linkState: true })
    }
    componentDidMount() {
        const { allDetail, admin, showEditPorfile, showCrifDataTab, userDetail } = this.props;
        // console.log(this.props)
        this.setState({ loaderState: true })
        fetch(APIS.GET_USERS_DETAILS_BY_USER_ID + userDetail.userId)
            .then(res => res.json())
            .then(json => {
                if (Object.entries(json).length !== 0 && json.UserToEmpId.teaGardenId !== null && json.UserToEmpId.teaGardenId !== undefined) {
                    this.setState({ teaGardenId: json.UserToEmpId.teaGardenId, loaderState: false, teaGardenData: json, teaEdit: true, pageViewState: 'basicPageTea' })
                } else {
                    this.setState({ loaderState: false, teaEdit: false, pageViewState: 'basicPage' })

                }
            })
        fetch(APIS.GET_REASONS_API + userDetail.loanId)
            .then(res => res.json())
            .then(json => {
                this.setState({ reasonData: json })
            })
    }

    render() {
        const { allDetail, allStatus, admin, showEditPorfile, showCrifDataTab, userDetail } = this.props;
        return (
            <div>
                {/* <Action admin={admin} allStatus={allStatus} userdata={allDetail} userDetail={userDetail} /> */}

                {this.state.loaderState ?
                    <Loader />
                    : ""}
                {/* <div className="btn-group-tab"> */}
                {/* {this.state.teaEdit === true ?
                        <button className={this.state.pageViewState == 'basicPageTea' ? 'active' : ''} onClick={(e) => this.sendHandler('basicPageTea')}>Basic Details</button>

                        : ""}
                    {this.state.teaEdit !== true ?
                        <button className={this.state.pageViewState == 'basicPage' ? 'active' : ''} onClick={(e) => this.sendHandler('basicPage')}>Basic Details</button>

                        : ""}
                    {userDetail.loanFrom !== 'bazarBussiness' && admin != null && admin.rolelist.indexOf(ROLE.DOCS) >= 0 ?
                        <button className={this.state.pageViewState == 'docsPage' ? 'active' : ''} onClick={(e) => this.sendHandler('docsPage')}>Documents</button>
                        : ""}
                    {userDetail.loanFrom !== 'bazarBussiness' && this.state.teaEdit === false ?
                        <button className={this.state.pageViewState == 'cibil' ? 'active' : ''} onClick={(e) => this.sendHandler('cibil')}>Cibil</button>
                        : ""
                    } */}
                {/* {showCrifDataTab != false && admin != null && admin.rolelist.indexOf(ROLE.CRIF_DATA) >= 0 ?
                        <button className={this.state.pageViewState == 'popUp' ? 'active' : ''} onClick={(e) => this.sendHandler('popUp')}>CRIF</button>
                        : null} */}
                {/* {showEditPorfile != false && admin != null && admin.rolelist.indexOf(ROLE.EDIT) >= 0 ?
                    <button className={this.state.pageViewState == 'scoring' ? 'active' : ''} onClick={(e) => this.sendHandler('scoring')}>Scoring</button>
                    :null} */}

                {/* {showEditPorfile != false && this.state.teaEdit === false && admin != null && admin.rolelist.indexOf(ROLE.EDIT) >= 0 ?
                        <>
                            {admin.emailId === 'SuperUser' ? <button className={this.state.pageViewState == 'editPage' ? 'active' : ''} onClick={(e) => this.sendHandler('editPage')}>Edit Profile</button> : ""}
                            {admin.emailId !== 'SuperUser' && (userDetail.statusId === '101' || userDetail.statusId === '102' || userDetail.statusId === '103' || userDetail.statusId === '105' || userDetail.statusId === '113' || userDetail.statusId === '117' || userDetail.statusId === '110') ? <button className={this.state.pageViewState == 'editPage' ? 'active' : ''} onClick={(e) => this.sendHandler('editPage')}>Edit Profile</button> :

                                admin.emailId !== 'SuperUser' && (userDetail.statusId !== '101' || userDetail.statusId !== '102' || userDetail.statusId !== '103' || userDetail.statusId !== '105' || userDetail.statusId !== '117' || userDetail.statusId !== '119' || userDetail.statusId !== '110') ? <button className={this.state.pageViewState == 'editloanId' ? 'active' : ''} onClick={(e) => this.sendHandler('editloanId')}>Edit Profile</button> : ''}
                            {showEditPorfile != false && this.state.teaEdit === true && admin != null && admin.rolelist.indexOf(ROLE.TE_Edit_Profile) >= 0 ?
                                <button className={this.state.pageViewState == 'editPageTea' ? 'active' : ''} onClick={(e) => this.sendHandler('editPageTea')}>Edit Profile Tea</button>
                                : null}
                        </>
                        : ""} */}
                {/* </div> */}

                {this.state.pageViewState == 'basicPage' ?
                    <div>
                        {/* <div className='container-fluid'>
                            <div className='row'>
                            {admin != null && admin.rolelist.indexOf(ROLE.Cibil_Scoring) >= 0 ?
                                <div className='col-sm-1 col-xs-12'><button className='profile-save-btn' onClick={() => this.tabsHandler('cibilScoring', true)}>Cibil Scoring</button></div>
                                : ""}
                                  <div className='col-sm-1 col-xs-12'>
                                      </div>
                            {admin != null && admin.rolelist.indexOf(ROLE.Cibil_Scoring) >= 0 ?

                                <div className='col-sm-1 col-xs-12'><button className='profile-save-btn' onClick={() => this.tabsHandler('creditScore', true)}>Credit Score</button></div>
                                : ""}
                                </div>
                        </div> */}
                        {/* {userDetail.loanFrom !== 'bazarBussiness' && showEditPorfile != false && admin != null && admin.rolelist.indexOf(ROLE.Cibil_Scoring) >= 0 && this.state.cibilScoringTabState === true ?
                            <Scoring
                                getData={this.props.getData.bind(this)}
                                allDetail={allDetail}
                                admin={admin} />
                            : ""} */}
                        {/* {admin != null && admin.rolelist.indexOf(ROLE.Cibil_Scoring) >= 0 ?
                            // console.log('uytrdfghj')
                            <CreditScore
                                getData={this.props.getData.bind(this)}
                                allDetail={allDetail}
                                admin={admin}
                                creditScoringPopup={true}
                            />
                            : ""} */}
                        {/* {this.state.reasonData && this.state.reasonData.dataAnalysisRejectionReason ?
                            <div>
                                <h3 className='text-center'><strong>{this.state.reasonData.dataAnalysisRejectionReason}</strong></h3>
                            </div>
                            : ""} */}
                        <Basic
                            UserDetailTabsHandler={this.props.UserDetailTabsHandler}
                            allStatus={allStatus}
                            userDetailHandler={this.props.userDetailHandler}
                            reasonData={this.state.reasonData}
                            userLinkHandler={this.userLinkHandler.bind(this)}
                            linkState={this.state.linkState}
                            userDetail={userDetail}
                            closePopup={this.closePopup.bind(this)}
                            popupStatus={this.state.popupStatus}
                            allDetail={allDetail}
                            getCreditPopupState={this.getCreditPopupState.bind(this)}
                            creditScoringPopup={this.state.creditScoringPopup}
                            admin={admin}
                            cancelLoanConfirm={this.cancelLoanConfirm.bind(this)}
                            confirmationText={this.state.confirmationText}
                            arr={this.state.arr}
                        />
                    </div>
                    : this.state.pageViewState == 'editPage' && userDetail.loanFrom !== 'bazarBussiness' ?
                        <EditProfile
                            getData={this.props.getData.bind(this)}
                            allDetail={allDetail}
                            admin={admin}
                            userDetails={this.props.userDetail} />
                        :
                        this.state.pageViewState == 'editloanId' ?
                            <EditLoanId
                                getData={this.props.getData.bind(this)}
                                allDetail={allDetail}
                                admin={admin}
                                userDetails={this.props.userDetail} />
                            :
                            this.state.pageViewState == 'editPageTea' ?
                                <EditProfileTea
                                    getData={this.props.getData.bind(this)}
                                    allDetail={allDetail}
                                    admin={admin}
                                    userDetails={this.props.userDetail}
                                    teaGardenData={this.state.teaGardenData}
                                />
                                :
                                this.state.pageViewState == 'editPage' && userDetail.loanFrom === 'bazarBussiness' ?
                                    <BadaBuisnessEditProfile
                                        getData={this.props.getData.bind(this)}
                                        allDetail={allDetail}
                                        admin={admin}
                                        userDetails={this.props.userDetail} />
                                    : this.state.pageViewState == 'cibil' ?
                                        <Cibil
                                            allDetail={this.state.allDetail}
                                        />
                                        : this.state.pageViewState == 'popUp' ?
                                            <ConfirmationPopup
                                                ConfirmCrifDataGenerate={this.ConfirmCrifDataGenerate.bind(this)}
                                            />
                                            : this.state.pageViewState == 'crifData' ?
                                                <Crif
                                                    allDetail={this.state.allDetail}
                                                />
                                                : this.state.pageViewState == 'docsPage' ?
                                                    <Documents
                                                        markCompleteBtn={this.props.markCompleteBtn}
                                                        allDetail={allDetail}
                                                        admin={admin}
                                                        userDetail={userDetail}
                                                    />
                                                    // : this.state.pageViewState == 'scoring' ?
                                                    //     <Scoring
                                                    //     getData={this.props.getData.bind(this)}
                                                    //         allDetail={allDetail}
                                                    //         admin={admin} />

                                                    : this.state.pageViewState == 'basicPageTea' ?

                                                        <BasicTea
                                                            allDetail={allDetail}
                                                            teaGardenData={this.state.teaGardenData}
                                                            userDetail={userDetail}
                                                            admin={admin}
                                                            linkState={this.state.linkState}
                                                            userLinkHandler={this.userLinkHandler.bind(this)}
                                                        />
                                                        : null}
            </div>
        )
    }




    sendHandler(type) {
        this.setState({
            pageViewState: type,
        })
    }
    ConfirmCrifDataGenerate(dataType) {
        if (dataType == 'yes') {
            this.setState({
                pageViewState: 'crifData'
            })

        } if (dataType == 'no') {
            this.setState({
                pageViewState: 'basicPage'
            })
        }
    }


    cancelLoanConfirm(conType) {
        if (conType == 'yes') {
            sendverificationRequestApi(this.state.mailIdForVerification,
                (callBack) => {
                    if (callBack == 'success') {
                        this.setState({
                            popupState: true,
                            popupStatus: 'Mail has been successfully sent to user.',
                            confirmationPopup: false
                        })
                        this.removePopup()
                    } else {
                        this.setState({
                            popupState: true,
                            popupStatus: 'Please try again.',
                            confirmationPopup: false
                        })
                        this.removePopup()
                    }
                })
        } else if (conType == 'no') {
            this.setState({ confirmationPopup: false })
        }
    }

    removePopup() {
        setTimeout(function () {
            this.setState({ popupState: false });
        }.bind(this), 5000)
    }

    closePopup() {
        this.setState({ popupState: false });
    }


    getCreditPopupState(state) {
        this.props.getCreditPopupState(state)
    }

    detailBackHandler() {
        this.props.basicBackHandler()
    }

    verifyMail(mailId) {
        this.setState({
            mailIdForVerification: mailId,
            confirmationText: 'Are you sure for send verification mail to this user?',
            confirmationPopup: true
        })

    }

    componentWillUnmount() {

    }

}
const mapStateToProps = ({ pendingDetail }) => {
    return {
        pendingDetail: pendingDetail
    };
};

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
    }, dispatch);
};
export default connect(mapStateToProps, mapDispatchToProps)(BasicUser);