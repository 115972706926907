import React from 'react'
import Ui from '../../../presentationals/Admin/Recovery/BulkPaymentLeads.component'
import { APIS } from '../../../../utils/api-factory'
import { _preFormatDate } from '../../../../utils/validation'
import Loader from '../../../presentationals/Loader/graphloader.component'
import SourceOfEnachUi from '../../../presentationals/Admin/Recovery/SourceEnach.component'
import {
  deductUserPaymentApi,
    getSourceOfEnach,
    deductUserPaymentByTechProcess
} from '../AdminActionCreator.component';
import Popup from '../../../presentationals/Popup/Popup.component';
import AmountDeductionPopup from '../../../presentationals/Admin/Recovery/GetAmountForDeduction.popup'

class Bulk extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            popupState: false,
            popupStatus: '',
            bulkPaymentData: '',
            FilteredData: [],
            pageIndexToShow: 0,
            typeToSend: 'true',
            // dateToSend: _preFormatDate(new Date),
            dateToSend: _preFormatDate(new Date()),
            searchByDateShow: '',
            loaderState: true,
            SourceOfEnachUiState: false,
            Sourcedata: '',
            amountForDeduction: '',
            loanIdForDeduction: '',
            disabled: false,
            userIdForSource: '',
            emiId:'',
            amountDeductionPopupState:false,
            dateTechProcess:'',
            startDate: '',
            source:'',
            amountForDeduction:'',
            disabled:false



        }
    }
    deductionAmountChange(e){
        this.setState({
            amountForDeduction: e.target.value
        })
    }

    dateHandler (e) {
        this.setState({
          startDate: e,
          dateTechProcess: _preFormatDate(e)
        })
      }

    getFilterValue(filtered) {
        this.setState({ FilteredData: filtered })
    }

    getPageValue(pageIndex) {
        this.setState({ pageIndexToShow: pageIndex })
    }
    componentDidMount() {
        fetch(APIS.BULK_PAYMENT_LEADS + this.state.typeToSend + '&date=' + this.state.dateToSend)
            .then(res => res.json())
            .then(res => {
                this.setState({ bulkPaymentData: res, loaderState: false })
            })
    }
    typeHandler(e) {
        this.setState({ typeToSend: e.target.value })

    }
    dateFilter(e) {
        this.setState({
            dateToSend: _preFormatDate(e),
            // dateToSend: e
        })
    }
    searcHandler() {
        this.setState({ loaderState: true })
        fetch(APIS.BULK_PAYMENT_LEADS + this.state.typeToSend + '&date=' + this.state.dateToSend)
            .then(res => res.json())
            .then(res => {
                this.setState({ bulkPaymentData: res, loaderState: false })
            })
    }

    paymentDeductHandler(data) {
        this.setState({
            emiId: data.emiId,
        })
        getSourceOfEnach(data.userId, callBack => {
            if (
                callBack != '' &&
                callBack != null &&
                callBack != '' &&
                Object.keys(callBack).length > 0
            ) {
                this.setState({
                    SourceOfEnachUiState: true,
                    // amountDeductionPopupState: true,
                    userIdForSource: data.userId,
                    loanIdForDeduction: data.loanId,
                    amountForDeduction: data.loanPaybackAmount
                })
            } else {
                this.setState({
                    popupState: true,
                    popupStatus: 'No Mandate Registered'
                })
                this.removePopup()
            }
            this.setState({
                Sourcedata: callBack
            })
        })

    }
    close() {
        this.setState({
            SourceOfEnachUiState: false
        })
    }
    handler(e) {
        this.setState({
            source: e.target.value
        })
    }
    sourceHandler(e) {
        this.setState({
            SourceOfEnachUiState: false,
            amountDeductionPopupState: true,

        })

    }
    removePopup() {
        setTimeout(function () {
            this.setState({ popupState: false });
        }.bind(this), 5000)
    }

    closePopup() {
        this.setState({ popupState: false });
    }
    deductionAmountHandler (type) {
        if (type == 'yes') {
          this.setState({
            disabled: true
          })
          if (this.state.source != 'techProcess') {
            deductUserPaymentApi(
              this.state.loanIdForDeduction,
              this.state.amountForDeduction,
              this.state.emiId,
              callBack => {
                if (callBack == 'success') {
                  this.setState({
                    popupState: true,
                    popupStatus: 'success',
                    amountDeductionPopupState: false,
                    disabled: false
                  })
                  this.removePopup()
                } else {
                  this.setState({
                    popupState: true,
                    popupStatus: 'Please try again',
                    amountDeductionPopupState: false,
                    disabled: false
                  })
                  this.removePopup()
                }
              }
            )
          } else {
            deductUserPaymentByTechProcess(
              this.state.loanIdForDeduction,
              this.state.amountForDeduction,
              this.state.dateTechProcess,
              callBack => {
                if (callBack == 'success') {
                  this.setState({
                    popupState: true,
                    popupStatus: 'success',
                    amountDeductionPopupState: false,
                    disabled: false
                  })
                  this.removePopup()
                } else {
                  this.setState({
                    popupState: true,
                    popupStatus: 'Please try again',
                    amountDeductionPopupState: false,
                    disabled: false
                  })
                  this.removePopup()
                }
              }
            )
          }
        } else if (type == 'no') {
          this.setState({ amountDeductionPopupState: false })
        }
      }

    render() {
        return (
            <React.Fragment>
                {this.state.loaderState ?
                    <Loader />
                    : ""}
                {this.state.popupState == true ?
                    <Popup
                        closePopup={this.closePopup.bind(this)}
                        popupStatus={this.state.popupStatus}
                    />
                    : ""}
                <Ui
                    paymentDeductHandler={this.paymentDeductHandler.bind(this)}
                    typeToSend={this.state.typeToSend}
                    dateFilter={this.dateFilter.bind(this)}
                    dateToSend={this.state.dateToSend}
                    searcHandler={this.searcHandler.bind(this)}
                    typeHandler={this.typeHandler.bind(this)}
                    getFilterValue={this.getFilterValue.bind(this)}
                    getPageValue={this.getPageValue.bind(this)}
                    pageIndexToShow={this.state.pageIndexToShow}
                    FilteredData={this.state.FilteredData}
                    bulkPaymentData={this.state.bulkPaymentData}

                />
                {this.state.SourceOfEnachUiState == true ?
                    <SourceOfEnachUi
                        Sourcedata={this.state.Sourcedata}
                        close={this.close.bind(this)}
                        handler={this.handler.bind(this)}
                        sourceHandler={this.sourceHandler.bind(this)}
                    />
                    : ""}
                {this.state.amountDeductionPopupState ? (
                    <AmountDeductionPopup
                        dateHandler={this.dateHandler.bind(this)}
                        dateTechProcess={this.state.dateTechProcess}
                        startDate={this.state.startDate}
                        source={this.state.source}
                        amountForDeduction={this.state.amountForDeduction}
                        deductionAmountChange={this.deductionAmountChange.bind(this)}
                        deductionAmountHandler={this.deductionAmountHandler.bind(this)}
                        disabled={this.state.disabled}
                    />
                ) : null}
            </React.Fragment>
        )
    }
}
export default Bulk