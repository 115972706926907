import React from 'react'
import SampleCsv from '../../../../images/communicte.png'
import { MSG_STRING } from './MessageTemplates.component'

const ByMobile = ({ csvCommunicate, communicateByCsvHandler, uploadedCSV, uploadcsvHandler, communicateByCsv, mailTempleteClick,
    btnDisabled }) => (
    <div className="container-fluid">
        <div className='col-sm-6 col-xs-12'>
            <h3 className=" text-center blue-text" style={{ marginTop: '15px' }}><strong>Templates</strong></h3>
            <ol>
                <li>
                    <input type="radio" value={MSG_STRING.REVIEW_ON_PLAYSTORE} name="communicationMail" onClick={e => mailTempleteClick(e, 'REVIEW')} /> &nbsp;&nbsp;
                    <span><strong>REVIEW ON PLAYSTORE</strong></span>
                </li>
                {/* <li>
                <input type="radio" value={MSG_STRING.MONTH_END_SAL_ADVANCE} name="communicationMail" onClick={e => mailTempleteClick(e, 'MONTHEND')} /> &nbsp;&nbsp;
                        <span><strong>MONTH END SAL ADVANCE</strong></span>
            </li>
            <li>
                <input type="radio" value={MSG_STRING.BILL_PAY_SAL_ADVANCE} name="communicationMail" onClick={e => mailTempleteClick(e, 'BILLPAY')} /> &nbsp;&nbsp;
                        <span><strong>BILL PAY SAL ADVANCE</strong></span>
            </li> */}
                <li>
                    <input type="radio" value={MSG_STRING.MICROMONEY_APP_TRY_KARO} name="communicationMail" onClick={e => mailTempleteClick(e, 'TRYMICROMONEY')} /> &nbsp;&nbsp;
                    <span><strong>MICROMONEY APP TRY KARO</strong></span>
                </li>
                <li>
                    <input type="radio" value={MSG_STRING.JHATPAT_LOAN} name="communicationMail" onClick={e => mailTempleteClick(e, 'JHATPATLOAN')} /> &nbsp;&nbsp;
                    <span><strong>Repeat Application</strong></span>
                </li>
                <li>
                    <input type="radio" value={MSG_STRING.ELIGIBLE_FOR_ADVANCE_SAL_2_LAC} name="communicationMail" onClick={e => mailTempleteClick(e, 'ADVANCESALARY')} /> &nbsp;&nbsp;
                    <span><strong>ELIGIBLE FOR ADVANCE SAL 2 Lac</strong></span>
                </li>

                <li>
                    <input type="radio" value={MSG_STRING.FESTIVE_SHOPPING_LOAN} name="communicationMail" onClick={e => mailTempleteClick(e, 'FESTIVESHOPING')} /> &nbsp;&nbsp;
                    <span><strong>FESTIVE SHOPPING LOAN</strong></span>
                </li>
                <li>
                    <input type="radio" value={MSG_STRING.LOAN_WITHIN_FEW_MINUTES} name="communicationMail" onClick={e => mailTempleteClick(e, 'FEWMINUTELOAN')} /> &nbsp;&nbsp;
                    <span><strong>LOAN WITHIN FEW MINUTES</strong></span>
                </li>
                <li>
                    <input type="radio" value={MSG_STRING.LOAN_WITHIN_5_MINUTES} name="communicationMail" onClick={e => mailTempleteClick(e, 'FIVEMINUTELOAN')} /> &nbsp;&nbsp;
                    <span><strong>LOAN WITHIN 5 MINUTES</strong></span>
                </li>
                {/* <li>
                <input type="radio" value={MSG_STRING.LAST_CHANCE_LOAN} name="communicationMail" onClick={e => mailTempleteClick(e, 'LASTCHANCELOAN')} /> &nbsp;&nbsp;
                        <span><strong>LAST CHANCE LOAN</strong></span>
            </li> */}
                <li>
                    <input type="radio" value={MSG_STRING.UPTO_200000_LOAN} name="communicationMail" onClick={e => mailTempleteClick(e, 'UPTO100000')} /> &nbsp;&nbsp;
                    <span><strong>UPTO 200000 LOAN</strong></span>
                </li>
                <li>
                    <input type="radio" value={MSG_STRING.URGENT_CASH} name="communicationMail" onClick={e => mailTempleteClick(e, 'URGENTCASH')} /> &nbsp;&nbsp;
                    <span><strong>URGENT CASH</strong></span>
                </li>
                <li>
                    <input type="radio" value={MSG_STRING.LOAN_WAITING} name="communicationMail" onClick={e => mailTempleteClick(e, 'LOANWAITING')} /> &nbsp;&nbsp;
                    <span><strong>LOAN WAITING</strong></span>
                </li>

                <li>
                    <input type="radio" value={MSG_STRING.LOAN_AT_LOW_INTEREST} name="communicationMail" onClick={e => mailTempleteClick(e, 'LOWINTERST')} /> &nbsp;&nbsp;
                    <span><strong>LOAN AT LOW INTEREST</strong></span>
                </li>
                <li>
                    <input type="radio" value={MSG_STRING.MONEY_AT_SPEED_OF_ROCKET} name="communicationMail" onClick={e => mailTempleteClick(e, 'SPEEDOFROCKET')} /> &nbsp;&nbsp;
                    <span><strong>MONEY AT SPEED OF ROCKET</strong></span>
                </li>

                {/* <li>
                    <input type="radio" value={MSG_STRING.ADVANCE_SALARY_VIA_BANKBAZAAR} name="communicationMail" onClick={e => mailTempleteClick(e, 'BANKBAZAR')} /> &nbsp;&nbsp;
                    <span><strong>ADVANCE SALARY VIA BANKBAZAAR</strong></span>
                </li>
                <li>
                    <input type="radio" value={MSG_STRING.BANKBAZAAR} name="communicationMail" onClick={e => mailTempleteClick(e, 'BANKBAZAAR')} /> &nbsp;&nbsp;
                    <span><strong>BankBazaar</strong></span>
                </li> */}

                <li>
                    <input type="radio" value={MSG_STRING.SALARY_12_MONTH} name="communicationMail" onClick={e => mailTempleteClick(e, 'SALARY_12_MONTH')} /> &nbsp;&nbsp;
                    <span><strong>SALARY 12 MONTH</strong></span>
                </li>
                <li>
                    <input type="radio" value={MSG_STRING.GET_A_MICROMONEY_PL} name="communicationMail" onClick={e => mailTempleteClick(e, 'GET_A_MICROMONEY_PL')} /> &nbsp;&nbsp;
                    <span><strong>GET A MICROMONEY PL</strong></span>
                </li>
                <li>
                    <input type="radio" value={MSG_STRING.ADVANCE_SAL_BY_BUDDYLOAN} name="communicationMail" onClick={e => mailTempleteClick(e, 'ADVANCE_SAL_BY_BUDDYLOAN')} /> &nbsp;&nbsp;
                    <span><strong>ADVANCE SAL BY BUDDYLOAN</strong></span>
                </li>
                <li>
                    <input type="radio" value={MSG_STRING.PRE_QUALIFIED_BUDDY_LOAN_MICROMONEY} name="communicationMail" onClick={e => mailTempleteClick(e, 'PRE_QUALIFIED_BUDDY_LOAN_MICROMONEY')} /> &nbsp;&nbsp;
                    <span><strong>PRE-QUALIFIED BUDDY LOAN MICROMONEY</strong></span>
                </li>
                {/* <li>
                    <input type="radio" value={MSG_STRING.PHOCKET_BANKBAZAAR} name="communicationMail" onClick={e => mailTempleteClick(e, 'PHOCKET_BANKBAZAAR')} /> &nbsp;&nbsp;
                    <span><strong>PHOCKET BANKBAZAAR</strong></span>
                </li> */}
                <li>
                    <input type="radio" value={MSG_STRING.LOAN_FOR_15000_SALARY} name="communicationMail" onClick={e => mailTempleteClick(e, 'LOAN_FOR_15000_SALARY')} /> &nbsp;&nbsp;
                    <span><strong>LOAN FOR 15000 SALARY</strong></span>
                </li>
                <li>
                    <input type="radio" value={MSG_STRING.REPEAT_CAMPAIGN} name="communicationMail" onClick={e => mailTempleteClick(e, 'REPEAT_CAMPAIGN')} /> &nbsp;&nbsp;
                    <span><strong>REPEAT CAMPAIGN</strong></span>
                </li>
                <li>
                    <input type="radio" value={MSG_STRING.TWICE_APPROVED_AMOUNT} name="communicationMail" onClick={e => mailTempleteClick(e, 'TWICE_APPROVED_AMOUNT')} /> &nbsp;&nbsp;
                    <span><strong>2X APPROVED AMOUNT</strong></span>
                </li>
                <li>
                    <input type="radio" value={MSG_STRING.MAXIMIZE_APPROVED_AMOUNT} name="communicationMail" onClick={e => mailTempleteClick(e, 'MAXIMIZE_APPROVED_AMOUNT')} /> &nbsp;&nbsp;
                    <span><strong>MAXIMIZE APPROVED AMOUNT</strong></span>
                </li>
                {/* <li>
                    <input type="radio" value={MSG_STRING.Phocket_App_partnered_with_Anjraj_Talent} name="communicationMail" onClick={e => mailTempleteClick(e, 'Phocket_App_partnered_with_Anjraj_Talent')} /> &nbsp;&nbsp;
                    <span><strong style={{textTransform:'uppercase'}}>Phocket App partnered with Anjraj Talent</strong></span>
                </li>
                <li>
                    <input type="radio" value={MSG_STRING.Dear_Customer_Refer_your_friends} name="communicationMail" onClick={e => mailTempleteClick(e, 'Dear_Customer_Refer_your_friends')} /> &nbsp;&nbsp;
                    <span><strong style={{textTransform:'uppercase'}}>Dear Customer Refer your friends</strong></span>
                </li>
                <li>
                    <input type="radio" value={MSG_STRING.Phocket_App_partnered_with_Genesis} name="communicationMail" onClick={e => mailTempleteClick(e, 'Phocket_App_partnered_with_Genesis')} /> &nbsp;&nbsp;
                    <span><strong style={{textTransform:'uppercase'}}>Phocket App partnered with Genesis</strong></span>
                </li>
                <li>
                    <input type="radio" value={MSG_STRING.Phocket_App_partnered_with_Hyrexpert} name="communicationMail" onClick={e => mailTempleteClick(e, 'Phocket_App_partnered_with_Hyrexpert')} /> &nbsp;&nbsp;
                    <span><strong style={{textTransform:'uppercase'}}>Phocket App partnered with Hyrexpert</strong></span>
                </li>
                <li>
                    <input type="radio" value={MSG_STRING.Phocket_App_partnered_with_Karyarth} name="communicationMail" onClick={e => mailTempleteClick(e, 'Phocket_App_partnered_with_Karyarth')} /> &nbsp;&nbsp;
                    <span><strong style={{textTransform:'uppercase'}}>Phocket App partnered with Karyarth</strong></span>
                </li>
                <li>
                    <input type="radio" value={MSG_STRING.Phocket_App_partnered_with_SAM_Career} name="communicationMail" onClick={e => mailTempleteClick(e, 'Phocket_App_partnered_with_SAM_Career')} /> &nbsp;&nbsp;
                    <span><strong style={{textTransform:'uppercase'}}>Phocket App partnered with SAM Career</strong></span>
                </li>
                <li>
                    <input type="radio" value={MSG_STRING.PHONEPARLOAN} name="communicationMail" onClick={e => mailTempleteClick(e, 'PHONEPARLOAN')} /> &nbsp;&nbsp;
                    <span><strong style={{textTransform:'uppercase'}}>PHONEPARLOAN</strong></span>
                </li> */}
            </ol>

        </div>
        <div className='col-sm-6 col-xs-12'>
            <h3 className="text-center blue-text">Communicate By Mobile Csv</h3>
            <div className="max-width-500px">
                <div className="row">
                    <h6>Upload CSV &nbsp; <a href={SampleCsv} target="_blank">View Sample csv</a></h6>
                    <div className="display-flex">
                        <input type="text" readOnly value={uploadedCSV} id="uploadCSVFile" className="form-control height-42px" />
                        <div className="fileUpload btn btn--browse">
                            <span>Browse</span>
                            <input accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" id="uploadCSVBtn" type="file" className="upload" onChange={e => uploadcsvHandler(e)} />
                        </div>
                    </div>
                </div>
                <br />
                <div className="row">

                    <div className="col-xs-12">
                        <textarea className="form-control" rows="5" placeholder="Message..." value={communicateByCsv.body} onChange={(e) => csvCommunicate(e, 'body')} readOnly />
                        {communicateByCsv.body.length <= 160 ?
                            <h4 className="text-center">Message length = {communicateByCsv.body.length + '/1'}</h4>
                            : communicateByCsv.body.length > 160 && communicateByCsv.body.length <= 320 ?
                                <h4 className="text-center">Message length = {communicateByCsv.body.length + '/2'}</h4>
                                : communicateByCsv.body.length > 320 && communicateByCsv.body.length <= 4800 ?
                                    <h4 className="text-center">Message length = {communicateByCsv.body.length + '/3'}</h4> : null}
                    </div>
                </div>
                <div className="row text-center">
                    <div className="col-xs-12">
                        <button className="profile-save-btn" id="submitDetail" disabled={btnDisabled} onClick={e => communicateByCsvHandler('micromoney')}>
                            <span className="fa fa-send-o"></span>&nbsp;&nbsp;Send From Micromoney
                        </button>&nbsp;&nbsp;
                        <button className="profile-save-btn" id="submitDetail" disabled={btnDisabled} onClick={e => communicateByCsvHandler('other')}>
                            <span className="fa fa-send-o"></span>&nbsp;&nbsp;Send From Others
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
)

export default ByMobile;