import React, { Component } from 'react'
import UserCibil from '../../../presentationals/Admin/CustomerPages/UserCibil.component'
import { APIS } from '../../../../utils/api-factory'
import Loader from '../../../presentationals/Loader/DotLoader.component'

class CibilUser extends Component {
    constructor(props) {
        super(props);
        this.state = {
            allDetail: this.props.allDetail,
            cibilData: '',
            loaderState: '',
            documentsData: ''
        }
    }

    componentDidMount() {
        const { allDetail } = this.props
        this.setState({ loaderState: true })
        fetch(APIS.GET_CIBIL_SCORE_BY_USERID + this.state.allDetail.userId +'&loanId='+this.state.allDetail.loanId)
            .then(res => res.json())
            .then(res => {
                if (res.responseCode == 0) {

                } else {
                    this.setState({
                        cibilData: res
                    })
                }
            })
        fetch(APIS.GET_USER_DOCUMENTS_BY_USER_ID + allDetail.userId)
            .then(res => res.json())
            .then(res => {
                this.setState({ documentsData: res, loaderState: false })
            })
    }

    render() {
        return (
            <div>

                {this.state.loaderState ? <Loader /> : ""}


                <UserCibil

                    allDetail={this.state.documentsData}
                    cibilData={this.state.cibilData}
                />
            </div>
        )
    }
}


export default CibilUser;