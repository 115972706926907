import React from 'react'

const LocationAdd = ({ merchantData, listingOfCategory, postDataHandler, ulLocationData,
    getDataHandler, MerchantValidationMsg, deleteCity }) => (
        <div className="container-fluid">
            <div className="row">
                <h3 className="text-center blue-text">Add Location</h3>
            </div>
            <div className="row">
                <div className="col-xs-12">
                    <div className="offer-location">
                        <input type="text" value={merchantData.location} className="form-control" placeholder="Enter Location" id="couponLocation" onChange={e => getDataHandler(e, "location")} />
                        <div className="font-12px margin-left-25px height-25px validation-color">
                            {MerchantValidationMsg.field == "location" ? MerchantValidationMsg.msg : ""}
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-xs-12 text-center">
                    <button className="profile-save-btn" id="locationAdd" onClick={e => postDataHandler()}>
                        <span className="fa fa-plus"></span>&nbsp;&nbsp;ADD
                            </button>
                </div>
            </div>
            <div className="row form-group">
                <div className="col-xs-12">
                    {listingOfCategory != null && listingOfCategory != '' && listingOfCategory.length>0?
                        <table className="text-center loan-detail-table max-width-500px">
                            <thead>
                                <tr className="background-blue text-white">
                                    <th>ID</th>
                                    <th>LOCATIONS</th>
                                    <th>Delete</th>
                                </tr>
                            </thead>

                            {listingOfCategory.map((listing, i) => {
                                return (
                                    <tbody key={i}>
                                        {listing.Location != null && listing.Location.length > 0 ? listing.Location.map((data, i) => {
                                            return (
                                                <tr className="pointer text-black" onClick={e => ulLocationData(e, data)} key={i}>
                                                    <td>{data.locationid}</td>
                                                    <td>{data.location}</td>
                                                    <td><span className="fa fa-trash" onClick={e => deleteCity(data)} /></td>
                                                </tr>
                                            )
                                        }) : ""}
                                    </tbody>
                                )

                            })}
                        </table>
                        : ""}
                </div>
            </div>
        </div>
    )

export default LocationAdd;