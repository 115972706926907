import { Callbacks } from 'jquery'
import React, { useState, useEffect } from 'react'
import { SaveUpdateProcessingFee } from '../../AdminActionCreator.component'
import PopUp from "../../../../presentationals/Popup/Popup.component";
import Loader from '../../../../presentationals/Loader/Loader.component';
import {APIS} from '../../../../../utils/api-factory'
export default function AddEditProcessingFee({ editData, handleSavebtn }) {
    const PageType = (Object.keys(editData)?.length > 0 ? 'Edit' : '')
    const [loader,setloader] =useState(false)
    const [popupstate, setPopupState] = useState(false)
    const [popupStatus, setpopupStatus] = useState('')
    const [nbfcList,setnbfcList] =useState([]);
    const [data, setData] = useState({
        nbfcId: '',
        processingFee: '',
        processingType: 'fixed',
        minLoanAmount: '',
        maxLoanAmount: '',
        effectingFrom: '',
        oldEffected: 'false',
        gstIncluded: 'false',
        noOfEmi: '',
        productId: '',
    })

    useEffect(() => {
        getNbfcList()
        if (Object.keys(editData)?.length > 0) {
            setData({
                nbfcId: editData.nbfcId,
                processingFee: editData.processingFee,
                processingType: editData.processingType,
                minLoanAmount: editData.minLoanAmount,
                maxLoanAmount: editData.maxLoanAmount,
                effectingFrom: handleEditableDate(editData.effectingFrom),
                oldEffected: editData.oldEffected,
                gstIncluded: editData.gstIncluded,
                noOfEmi: editData.noOfEmi,
                productId: editData.productId,
            })
        }
    }, [editData])

    const handleEditableDate = (inputDate) => {
        if (PageType == 'Edit') {
            const [datePart, timePart] = inputDate.split(' ');
            const [day, month, year] = datePart.split('-').map(Number);

            const date = new Date(year, month - 1, day);

            const outputYear = date.getFullYear();
            const outputMonth = (date.getMonth() + 1).toString().padStart(2, '0');
            const outputDay = date.getDate().toString().padStart(2, '0');

            return `${outputYear}-${outputMonth}-${outputDay}`;
        }
    }


    const handlechange = (e) => {
        console.log(e.target.name, '-', e.target.value)
        const { name, value } = e.target;

        setData((prevData) => ({
            ...prevData,
            [name]: value
        }))
    }

    const handlesave = (type) => {
        if (type == 'update') {
            setloader(true)
            data.id = editData.id
            SaveUpdateProcessingFee(data, (Callback) => {
                setloader(false)
                if (Callback == 'success') {
                    setPopupState(true)
                    setpopupStatus('Updated Successfully')
                    setTimeout(() => {
                        handleSavebtn()
                    }, 1000)
                } else {
                    setPopupState(true)
                    setpopupStatus('Error')
                }
            })
        } else if (type == 'save') {
            setloader(true)
            SaveUpdateProcessingFee(data, (Callback) => {
                setloader(false)
                if (Callback == 'success') {
                    setPopupState(true)
                    setpopupStatus('Saved Successfully')
                    setTimeout(() => {
                        handleSavebtn()
                    }, 1000)
                } else {
                    setPopupState(true)
                    setpopupStatus('Error')
                }
            })
        }
        else if (type == 'back') {
            handleSavebtn()
        }
    }

    const closePopup = () => {
        setPopupState(false)
    }

    const getNbfcList = () => {
        fetch(APIS.GET_LIST_OF_NBFC)
        .then(res => res.json())
        .then(res => {
            setnbfcList(res)
        })
    }

    return (
        <>
            <div className="AddEditExecutor">

                {
                    loader ?
                        <Loader />
                        : ''}
                {
                    popupstate ?
                        <PopUp closePopup={closePopup} popupStatus={popupStatus} />
                        : ''}

                <h3 className='heading'>{PageType}</h3>

                <div className="forms">
                    <div className="row">
                        <div className="col-sm-6 col-xs-12">
                            <div className="formgrp newmargin">
                                <label htmlFor="nbfcId">NBFC Name</label>
                                {/* <input className='inputtag' type="text" id="nbfcId" placeholder='NBFC Id' name="nbfcId" value={data?.nbfcId} onChange={(e) => handlechange(e)}/> */}
                                <select value={data?.nbfcId} onChange={(e) => handlechange(e)} id="nbfcId" className='inputtag' name="nbfcId">
                                    <option value="">Select NBFC</option>
                                    {
                                        nbfcList && nbfcList?.length > 0 ?
                                        nbfcList?.map((value,index)=>{
                                            return(
                                                <option value={value?.nbfcId} key={index}>{value?.name}</option>
                                                )
                                            })
                                    :''}
                                </select>
                            </div>
                        </div>
                        <div className="col-sm-6 col-xs-12">
                            <div className="formgrp newmargin">
                                <label htmlFor="processingFee">Processing Fee</label>
                                <input className='inputtag' type="number" id="processingFee" placeholder='Processing Fee' name="processingFee" value={data?.processingFee} onChange={(e) => handlechange(e)} />
                            </div>
                        </div>
                        <div className="col-sm-6 col-xs-12">
                            <div className="formgrp newmargin">
                                <label htmlFor="processingType">Processing Type</label>
                                <select id="processingType" className='inputtag' name="processingType" value={data?.processingType} onChange={(e) => handlechange(e)}>
                                    <option value="fixed">Fixed</option>
                                </select>
                            </div>
                        </div>
                        <div className="col-sm-6 col-xs-12">
                            <div className="formgrp newmargin">
                                <label htmlFor="minLoanAmount">Min Loan Amount</label>
                                <input className='inputtag' type="number" id="minLoanAmount" placeholder='Min Loan Amount' name="minLoanAmount" value={data?.minLoanAmount} onChange={(e) => handlechange(e)} />
                            </div>
                        </div>
                        <div className="col-sm-6 col-xs-12">
                            <div className="formgrp newmargin">
                                <label htmlFor="maxLoanAmount">Max Loan Amount</label>
                                <input className='inputtag' type="number" id="maxLoanAmount" min={data.minLoanAmount} placeholder='Max Loan Amount' name="maxLoanAmount" value={data?.maxLoanAmount} onChange={(e) => handlechange(e)} />
                            </div>
                        </div>
                        <div className="col-sm-6 col-xs-12">
                            <div className="formgrp newmargin">
                                <label htmlFor="effectingFrom">Effecting From</label>
                                <input className='inputtag' type="date" id="effectingFrom" placeholder='effectingFrom' name="effectingFrom" value={data?.effectingFrom} onChange={(e) => handlechange(e)} />
                            </div>
                        </div>
                        <div className="col-sm-6 col-xs-12">
                            <div className="formgrp newmargin">
                                <label htmlFor="oldEffected">Old Effected</label>
                                <select name="oldEffected" className='inputtag' id="oldEffected" value={data?.oldEffected} onChange={(e) => handlechange(e)}>
                                    <option value="true">Yes</option>
                                    <option value="false">No</option>
                                </select>
                                 </div>
                        </div>
                        <div className="col-sm-6 col-xs-12">
                            <div className="formgrp newmargin">
                                <label htmlFor="gstIncluded">GST Included</label>
                                <select name="gstIncluded" className='inputtag' id="gstIncluded" value={data?.gstIncluded} onChange={(e) => handlechange(e)}>
                                    <option value="true">Yes</option>
                                    <option value="false">No</option>
                                </select>
                                 </div>
                        </div>
                        <div className="col-sm-6 col-xs-12">
                            <div className="formgrp newmargin">
                                <label htmlFor="noOfEmi">No Of EMI</label>
                                <select name="noOfEmi" className='inputtag' id="noOfEmi" value={data?.noOfEmi} onChange={(e) => handlechange(e)}>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="6">6</option>
                                    <option value="12">12</option>
                                </select>
                                 </div>
                        </div>
                        
                        <div className="col-sm-6 col-xs-12">
                            <div className="formgrp newmargin">
                                <label htmlFor="productId">Product Id</label>
                                <input className='inputtag' type="text" id="productId" placeholder='Product Id' name="productId" value={data?.productId} onChange={(e) => handlechange(e)} />
                            </div>
                        </div>
                    </div>


                    <div className='savebtn'>
                        <button className='btn back' onClick={() => handlesave('back')}>Back</button>
                        <button className='btn' onClick={() => handlesave(PageType == 'Edit' ? 'update' : 'save')}>{PageType == 'Edit' ? 'Update' : 'Save'}</button>
                    </div>
                </div>
            </div>

        </>
    )
}
