import React from 'react'
import Ui from '../../../presentationals/Admin/CustomerPages/EditProfileTea.component';
import moment from 'moment';
import { changeEmployeeIdApi, panVerificationApiKarza, saveLoanId, saveOrUpdateBasicDetailsAdmin, } from '../AdminActionCreator.component';
import PhocketLoader from '../../../presentationals/Loader/Loader.component';
import PopUp from '../../../presentationals/Popup/Popup.component';
class EditTea extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userData: {
                empId: '',
                name: '',
                dateOfBirth: '',
                gender: '',
                employmentType: '',
                ageOfEmployment: '',
                panNumber: '',
                aadharNumber: '',
                pinCode: 0,
                address: '',
                state: '',
                city: '',
                mobileNumber: '',
                ifBankAccount: '',
                dateOfJoining: '',
                teaGardenName: ''
            },
            otherData: {
                teaGardenName: null,
                teaGardenComment: null,
                // userId: localStorage.getItem("userId")
            },
            loaderState: false,
            popupState: false,
            popupStatus: '',
            newEmpId: '',
            applicationLoanId: ''

        }
    }
    closePopup() {
        this.setState({ popupState: false })
    }
    verifyPan() {
        const { teaGardenData, allDetail } = this.props

        if (this.state.userData.panNumber !== '' && this.state.userData.panNumber !== null) {
            this.setState({loaderState:true})
            panVerificationApiKarza(allDetail.userId, this.state.userData.panNumber, callBack => {
                if (callBack['status-code'] === '101') {
                    this.setState({ loaderState: false,popupState: true, popupStatus: ' Verified Successfully !' },()=>this.removePopup())

                } else {
                    this.setState({loaderState: false, popupState: true, popupStatus: 'Not Verified !' },()=>this.removePopup())
                }
            })
        }else{
            this.setState({ popupState: true, popupStatus: 'Enter Pan Number' },()=>this.removePopup())

        }
    }
    componentDidMount() {
        const { teaGardenData, allDetail } = this.props
        var dateOfJoin = allDetail.employmentDetailsPO.dateOfJoining !== null && allDetail.employmentDetailsPO.dateOfJoining !== undefined ? allDetail.employmentDetailsPO.dateOfJoining.split("-").reverse().join("-") : ""
        var dob = allDetail.dateOfBirth !== null && allDetail.dateOfBirth !== undefined ? allDetail.dateOfBirth.split("-").reverse().join("-") : ""
        // console.log(teaGardenData, 'teaGardenData.UserToEmpId.empId')
        let changeData = Object.assign({}, this.state.userData)
        changeData.empId = teaGardenData.UserToEmpId.empId
        changeData.teaGardenName = teaGardenData.TeaGardenDetail.tea_garden_name
        changeData.name = allDetail.firstName
        changeData.dateOfBirth = dob
        changeData.gender = allDetail.gender
        changeData.employmentType = allDetail.employmentDetailsPO.fullPartTime
        changeData.ageOfEmployment = allDetail.employmentDetailsPO.totalExperience
        changeData.panNumber = allDetail.panNumber
        changeData.aadharNumber = allDetail.aadharNumber
        changeData.pinCode = allDetail.addressDetailsPOList.currentPincode
        changeData.address = allDetail.addressDetailsPOList.currentAddress
        changeData.state = allDetail.addressDetailsPOList.currentState
        changeData.city = allDetail.addressDetailsPOList.currentCity
        changeData.mobileNumber = allDetail.mobileNumber
        changeData.ifBankAccount = teaGardenData.UserToEmpId.bankAccount
        changeData.dateOfJoining = dateOfJoin
        this.setState({ userData: changeData, applicationLoanId: allDetail.applicationLoanId })

    }
    compareDate(dateOfJoining) {
        var date1 = moment(new Date()).format('YYYY-MM-DD');
        var date2 = dateOfJoining;
        var diffDays = moment(date1).year() - moment(date2).year();
        let changeData = Object.assign({}, this.state.userData)
        changeData.dateOfJoining = dateOfJoining
        if (diffDays < 3) {
            changeData.ageOfEmployment = "1 - 3 years"

        }
        if (diffDays > 3 && diffDays <= 5) {
            changeData.ageOfEmployment = "3 - 5 years"

        }
        if (diffDays > 5) {
            changeData.ageOfEmployment = 'More than 5 years'

        }
        this.setState({ userData: changeData }, () => this.setData(changeData))

    }
    setData(changeData) {
        this.setState({ userData: changeData })
    }
    updateDetails() {
        const { teaGardenData, allDetail } = this.props
        this.setState({ loaderState: true })
        saveOrUpdateBasicDetailsAdmin(
            teaGardenData.UserToEmpId.staff,
            this.state.userData,
            '',
            this.state.otherData,
            teaGardenData.TeaGardenDetail.teaGardenId,
            allDetail.userId,
            this.state.userData.teaGardenName
            , callback => {

                this.setState({ loaderState: false, popupStatus: callback.message, popupState: true }, () => this.removePopup())

            })
    }
    changeEmployeeId() {
        const { teaGardenData, allDetail } = this.props
        if (this.state.newEmpId !== '') {
            this.setState({ loaderState: true })
            changeEmployeeIdApi(
                teaGardenData.TeaGardenDetail.teaGardenId,
                teaGardenData.UserToEmpId.empId,
                this.state.newEmpId,
                teaGardenData.UserToEmpId.staff, callback => {
                    if (callback === 'success') {
                        this.setState({ loaderState: false, popupStatus: 'updated successfully !', popupState: true }, () => this.removePopup())

                    } else {
                        this.setState({ loaderState: false, popupStatus: 'please try again later !', popupState: true }, () => this.removePopup())

                    }
                }
            )
        }
    }
    changeHandler(e, type) {
        let changeData = Object.assign({}, this.state.userData)
        changeData[type] = e.target.value
        if (type === 'dateOfJoining') {

            this.compareDate(e.target.value)
        }
        // changeData.empId=teaGardenData.UserToEmpId.empId
        // changeData.teaGardenName=teaGardenData.TeaGardenDetail.tea_garden_name
        // changeData.name= allDetail.firstName
        // changeData.dateOfBirth= allDetail.dateOfBirth 
        // changeData.gender= allDetail.gender 
        // changeData.employmentType= allDetail.employmentDetailsPO.fullPartTime 
        // changeData.ageOfEmployment=allDetail.employmentDetailsPO.totalExperience
        // changeData.panNumber= allDetail.panNumber 
        // changeData.aadharNumber= allDetail.aadharNumber 
        // changeData.pinCode=allDetail.addressDetailsPOList.currentPincode
        // changeData.address= allDetail.addressDetailsPOList.currentAddress 
        // changeData.state= allDetail.addressDetailsPOList.currentState 
        // changeData.city= allDetail.addressDetailsPOList.currentCity 
        // changeData.mobileNumber= allDetail.mobileNumber 
        // changeData.ifBankAccount= teaGardenData.UserToEmpId.bankAccount  
        // changeData.dateOfJoining= dateOfJoin
        this.setState({ userData: changeData })
    }

    removePopup() {
        setTimeout(
            function () {
                this.setState({ popupState: false })
            }.bind(this),
            5000
        )
    }
    newEmpIdHandler(e) {
        this.setState({ newEmpId: e.target.value })
    }
    onSubmitHandler(e, pageStatus) {
        const { teaGardenData, allDetail } = this.props

        if (pageStatus == 'LoanId') {
            if (this.state.applicationLoanId != '') {
                this.setState({ loaderState: true })
                saveLoanId(
                    this.state.applicationLoanId,
                    allDetail.userId,
                    callBack => {
                        if (callBack == 'success') {
                            this.setState({
                                popupState: true,
                                loaderState: false,
                                popupStatus: 'Loan Id save successfully',

                            }, () => this.props.getData('yes'))
                            this.removePopup()
                        } else {
                            this.setState({
                                loaderState: false,
                                popupState: true,
                                popupStatus: 'Loan Id not save'
                            })
                            this.removePopup()
                        }
                    }
                )
            } else {
                this.setState({
                    popupState: true,
                    popupStatus: 'Please enter loan id',
                    disburseLoanId: ''
                })
                this.removePopup()
            }
        }
    }
    onChangeHandler(e) {
        this.setState({ applicationLoanId: e.target.value })
    }

    render() {
        const { teaGardenData, allDetail } = this.props

        return (
            <div>
                {this.state.popupState ?
                    <PopUp
                        closePopup={this.closePopup.bind(this)}
                        popupStatus={this.state.popupStatus}
                    />
                    : ""}
                {this.state.loaderState ?
                    <PhocketLoader />
                    : ""}
                <Ui
                verifyPan={this.verifyPan.bind(this)}
                    onSubmitHandler={this.onSubmitHandler.bind(this)}
                    onChangeHandler={this.onChangeHandler.bind(this)}
                    newEmpId={this.state.newEmpId}
                    newEmpIdHandler={this.newEmpIdHandler.bind(this)}
                    changeEmployeeId={this.changeEmployeeId.bind(this)}
                    changeHandler={this.changeHandler.bind(this)}
                    updateDetails={this.updateDetails.bind(this)}
                    userData={this.state.userData}
                    teaGardenData={teaGardenData}
                    applicationLoanId={this.state.applicationLoanId}
                />
            </div>
        )
    }
}
export default EditTea