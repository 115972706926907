import React from 'react'
import { PLACEHOLDER, STRING } from '../../../../utils/Constant'
import DatePicker from 'react-datepicker';

const UpdatePopup = ({ editTransHandler, closeTransaction, saveTransHandler,
    trnsDateState, transDetail }) => (
        <div className="background-blur details-new">
            <div className="popup-main small-popup container-fluid dbl-border">
                <div className="row">
                    <div className="col-xs-12">
                        <a className="close" onClick={e => closeTransaction()}>&times;</a>
                        <h4 className="" style={{color:'#000'}}>Update Transaction Details</h4>
                    </div>
                </div>
                <div className="row form-group">
                    <div className="col-sm-6 col-xs-12">
                        <label className="font-12px">TRANSACTION ID</label>
                        <input readOnly type="text" className="form-control" placeholder="ENTER TRANSACTION ID" onChange={(e) => editTransHandler(e, 'trnsId')} value={transDetail.trnsIdState} />
                    </div>
                    <div className="col-sm-6 col-xs-12">
                        <label className="font-12px">TRANSACTION DATE</label>
                        <DatePicker
                            value={transDetail.trnsDateState}
                            selected={trnsDateState}
                            onChange={(e) => editTransHandler(e, 'trnsDate')}
                            className="date-picker form-control"
                            dateFormat="DD-MM-YYYY"
                            placeholderText="ENTER TRANSACTION DATE"
                        />
                    </div>
                </div>
                <div className="row form-group">
                    <div className="col-sm-6 col-xs-12">
                        <label className="font-12px">TRANSACTION MODE</label>
                        <select required className="form-control" id="paymentMode" onChange={(e) => editTransHandler(e, 'trnsMode')} value={transDetail.trnsModeState}>
                            <option value="">{PLACEHOLDER.SELECT_TRANSACTION_MODE}</option>
                            <option value={STRING.CHEQUE}>{STRING.CHEQUE}</option>
                            <option value={STRING.NET_BANKING}>{STRING.NET_BANKING}</option>
                            <option value={'UPI'}>UPI</option>

                        </select>
                    </div>
                    <div className="col-sm-6 col-xs-12">
                        <label className="font-12px">TRANSACTION AMOUNT</label>
                        <input type="number" className="form-control" placeholder="ENTER TRANSACTION AMOUNT" onChange={(e) => editTransHandler(e, 'trnsAmount')} value={transDetail.trnsAmountState} />
                    </div>
                </div>

                <div className="row">
                    <div className="col-xs-12 " style={{marginTop:'20px'}}>
                        <button className="btn btn-primary" id="personalSubmitBtn" onClick={(e) => saveTransHandler(e)}>
                            UPDATE
                    </button>
                    </div>
                </div>
            </div>
        </div>
    )

export default UpdatePopup;