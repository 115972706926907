import React from 'react';
import VerifyImage from '../../../../images/verified.png'
import NotVerified from '../../../../images/cross.png'
import moment from 'moment';

const EmiBreakUp = ({ close, payData, leadData }) => {
    const {
        bounceGst = 'N/A',
        createdAt,
        emiAmount,
        emiId,
        id,
        interestAmount,
        isActive,
        loanId,
        nachBounce = 'N/A',
        nachBounceWaived = 'N/A',
        overdueAmount = 'N/A',
        penalty = 'N/A',
        penaltyGst = 'N/A',
        penaltyWaived = 'N/A',
        principalAmount,
        rateOfInterest,
        updatedAt,
        updatedBy,
    } = payData;

    const formattedCreatedAt = new Date(createdAt).toLocaleString();
    const formattedUpdatedAt = new Date(updatedAt).toLocaleString();

    return (
        <>
            <div className="background-blur " onClick={close}></div>
            <div className="popup-main small-popup container-fluid dbl-border" style={{ marginTop: '40px',width:'90%',maxWidth:'90%' }}>
                <div className="popup-content "  >
                    <h4 className="text-center">Payment Breakdown for {leadData.applicationId}</h4>
                    <div className='phocket-table-new' style={{overflow:'auto'}}>
                    <table className="" >
                        <thead>
                            <tr>
                                <th>Bounce GST</th>
                                <th>Created At</th>
                                <th>EMI Amount</th>
                                <th>Interest Amount</th>
                                <th>Is Active</th>
                                <th>NACH Bounce</th>
                                <th>NACH Bounce Waived</th>
                                <th>Overdue Amount</th>
                                <th>Penalty</th>
                                <th>Penalty GST</th>
                                <th>Penalty Waived</th>
                                <th>Rate of Interest</th>
                                <th>Updated At</th>
                                {/* <th>Updated By</th> */}
                            </tr>

                        </thead>
                        <tbody>
                            {payData && payData.map((data, i) => {
                                return (
                                    <tr>
                                        <td style={{ textAlign: 'center' }}>{data.bounceGst}</td>
                                        <td style={{ textAlign: 'center' }}>{moment(data.createdAt).format('DD-MM-YYYY')}</td>
                                        <td style={{ textAlign: 'center' }}>{data.emiAmount}</td>
                                        <td style={{ textAlign: 'center' }}>{data.interestAmount}</td>
                                        <td style={{ textAlign: 'center' }}>{data.isActive ? <img src={VerifyImage} width="22px" alt="Verified" /> : <img src={NotVerified} width="20px" alt="Not Verified" />}</td>
                                        <td style={{ textAlign: 'center' }}>{data.nachBounce}</td>
                                        <td style={{ textAlign: 'center' }}>{data.nachBounceWaived}</td>
                                        <td style={{ textAlign: 'center' }}>{data.overdueAmount}</td>
                                        <td style={{ textAlign: 'center' }}>{data.penalty}</td>
                                        <td style={{ textAlign: 'center' }}>{data.penaltyGst}</td>
                                        <td style={{ textAlign: 'center' }}>{data.penaltyWaived}</td>
                                        <td style={{ textAlign: 'center' }}>{data.rateOfInterest}</td>
                                        <td style={{ textAlign: 'center' }}>{moment(data.updatedAt).format('DD-MM-YYYY')}</td>
                                        {/* <td style={{ textAlign: 'center' }}>{moment(data.updatedBy).format('DD-MM-YYYY')}</td> */}

                                    </tr>
                                )
                            })}

                        </tbody>
                        {/* <tbody>
                            <tr>
                                <th>Bounce GST:</th>
                                <td style={{ textAlign: 'center' }}>{bounceGst}</td>
                            </tr>
                            <tr>
                                <th>Created At:</th>
                                <td style={{ textAlign: 'center' }}>{formattedCreatedAt}</td>
                            </tr>
                            <tr>
                                <th>EMI Amount:</th>
                                <td style={{ textAlign: 'center' }}>{emiAmount}</td>
                            </tr>
                           
                            <tr>
                                <th>Interest Amount:</th>
                                <td style={{ textAlign: 'center' }}>{interestAmount}</td>
                            </tr>
                            <tr>
                                <th>Is Active:</th>
                                <td style={{ textAlign: 'center' }}>
                                    {isActive ? <img src={VerifyImage} width="22px" alt="Verified" /> : <img src={NotVerified} width="20px" alt="Not Verified" />}
                                </td>
                            </tr>

                            <tr>
                                <th>NACH Bounce:</th>
                                <td style={{ textAlign: 'center' }}>{nachBounce}</td>
                            </tr>
                            <tr>
                                <th>NACH Bounce Waived:</th>
                                <td style={{ textAlign: 'center' }}>{nachBounceWaived}</td>
                            </tr>
                            <tr>
                                <th>Overdue Amount:</th>
                                <td style={{ textAlign: 'center' }}>{overdueAmount}</td>
                            </tr>
                            <tr>
                                <th>Penalty:</th>
                                <td style={{ textAlign: 'center' }}>{penalty}</td>
                            </tr>
                            <tr>
                                <th>Penalty GST:</th>
                                <td style={{ textAlign: 'center' }}>{penaltyGst}</td>
                            </tr>
                            <tr>
                                <th>Penalty Waived:</th>
                                <td style={{ textAlign: 'center' }}>{penaltyWaived}</td>
                            </tr>
                            <tr>
                                <th>Principal Amount:</th>
                                <td style={{ textAlign: 'center' }}>{principalAmount}</td>
                            </tr>
                            <tr>
                                <th>Rate of Interest:</th>
                                <td style={{ textAlign: 'center' }}>{rateOfInterest}</td>
                            </tr>
                            <tr>
                                <th>Updated At:</th>
                                <td style={{ textAlign: 'center' }}>{formattedUpdatedAt}</td>
                            </tr>
                            <tr>
                                <th>Updated By:</th>
                                <td style={{ textAlign: 'center' }}>{updatedBy}</td>
                            </tr>
                        </tbody> */}
                    </table>
                    </div>
                </div>
            </div>
        </>
    );
};

export default EmiBreakUp;
