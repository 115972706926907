import React from "react";
import ProductConfig from './productConfig'
const ConfigProduct = ({closePopup,productData}) => {
    return (
        <div className={"product-sidepopup-right"}>
            <div className="container-fluid">
                <div className="row" style={{ background: ' #f8f8f8', zIndex: 9999, position: 'sticky', top: 0 }}>
                    <i className="fa fa-times" onClick={() => closePopup()} style={{ cursor: 'pointer', float: 'right', color: 'red', fontSize: '18px', marginRight:'20px',marginTop:'20px' }}></i>
                    <h4 className="mt-1" style={{marginTop:'10px',marginLeft:'20px'}}><b>Add/Edit Configuration</b></h4>

                    <hr style={{ width: '97%' }} />
                </div>
              <ProductConfig productData={productData} closePopup={closePopup}/>
            </div>
        </div>
    )
}
export default ConfigProduct