import React, { Component } from 'react';
import DocumentForm from '../../../presentationals/Admin/AdminCoupons/AddMerchantImage.component';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
    saveMerchantImages, getMerchantImages, saveMerchantAgreement,
    deleteMerchantImage, getOfferDetail, saveCouponImages, getCouponImages
} from './AddMerchentActionCreator.Component'
import Popup from '../../../presentationals/Popup/Popup.component'
import Confirmation from '../../../presentationals/Popup/ConfirmationPopup.component'

let interval;
let msg = "We will get back to you soon!";
let File1 = '';
let File2 = '';
let File3 = '';
let File4 = '';
let File5 = '';
let File6 = '';
let File = [];

class Documents extends Component {
    constructor(props) {
        super(props)
        this.state = {
            confirmationPopup: false,
            confirmationText: '',
            idForDelete: '',
            popupState: false,
            popupStatus: '',
            DocumentValidationMsg: "",
            progressBarState: false,
            progressValue: 20,
            docType: '',
            BankPasswordState: false,
            SalaryPasswordState: false,
            docPass: "",
            passwordErrorMsg: "",
            allImages: '',
            showCouponImage: '',
            couponImage: 'No File Chosen',
            merchantDetail: {
                image1: "No File Chosen",
                image2: "No File Chosen",
                image3: "No File Chosen",
                agreement: "No File Chosen"
            },
            offerDetails: '',
            merchantId: "",
            couponId: ''
        }
    }

    componentWillMount() {
        this.props.getOfferDetail((callback) => {
            this.setState({ offerDetails: callback })
        })
    }

    render() {
        const { listingOfCategory } = this.props
        return (
            <div id="formPage1" className="">
                {this.state.popupState ?
                    <Popup
                        popupStatus={this.state.popupStatus}
                        closePopup={this.closePopup.bind(this)}
                    />
                    : ""}
                <DocumentForm
                    merchantdocument={this.merchantdocument.bind(this)}
                    progressBarState={this.state.progressBarState}
                    docType={this.state.docType}
                    progressValue={this.state.progressValue}
                    merchantDetail={this.state.merchantDetail}
                    listingOfCategory={listingOfCategory}
                    merchantIdHandler={this.merchantIdHandler.bind(this)}
                    allImages={this.state.allImages}
                    showCouponImage={this.state.showCouponImage}
                    couponImage={this.state.couponImage}
                    deleteImage={this.deleteImage.bind(this)}
                    offerDetails={this.state.offerDetails}
                    coupondocument={this.coupondocument.bind(this)}
                    couponIdHandler={this.couponIdHandler.bind(this)}
                />
                {this.state.confirmationPopup == true ?
                    <Confirmation
                        cancelLoanConfirm={this.cancelLoanConfirm.bind(this)}
                        confirmationText={this.state.confirmationText} />
                    : ""}
            </div>
        )
    }

    couponIdHandler(e) {
        this.setState({ couponId: e.target.value })
        getCouponImages(e.target.value, (callBack) => {
            this.setState({showCouponImage: callBack})
        })
    }

    cancelLoanConfirm(conType) {
        if (conType == 'yes') {
            deleteMerchantImage(this.state.idForDelete, (callBack) => {
                if (callBack == 'success') {
                    this.setState({
                        confirmationPopup: false,
                    })
                }
            })
        } else if (conType == 'no') {
            this.setState({ confirmationPopup: false })
        }
    }

    deleteImage(data) {
        this.setState({
            idForDelete: data.merchantid,
            confirmationPopup: true,
            confirmationText: 'Are you sure to Delete this image'
        })
    }

    closePopup() {
        this.setState({ popupState: false });
    }

    removePopup() {
        setTimeout(function () {
            this.setState({ popupState: false });
        }.bind(this), 5000)
    }

    merchantIdHandler(e) {
        this.setState({ merchantId: e.target.value })
        getMerchantImages(e.target.value, (callBack) => {
            this.setState({ allImages: callBack })
        })
    }

    setInterval1() {
        interval = setInterval(function () {
            this.setState({
                progressValue: this.state.progressValue + 5
            })
        }.bind(this), 500)
    }

    coupondocument(e) {
        let valt = e.target.value;
        let val = valt.split(/[\\\/]/)[2];
        this.setInterval1();
        let reader = new FileReader();
        File1 = e.target.files[0];
        File2 = e.target.files[1];
        File3 = e.target.files[2];
        File4 = e.target.files[3];
        File5 = e.target.files[4];
        File6 = e.target.files[5];
        File = [File1, File2, File3, File4, File5, File6];
        let docType = val.split(".");
        let fileType = docType[docType.length - 1];
        if (this.state.couponId != '') {
            this.setState({ progressBarState: true, couponImage: val, docType: 'couponImage' })
            saveCouponImages(File, this.state.couponId, val,
                (callBack => {
                    document.getElementById("couponImageText").classList.remove("hidden")
                    this.setState({
                        progressBarState: false,
                    })
                })
            )
        }
    }

    merchantdocument(DocumentInfo, e) {
        let merchantDetail = Object.assign({}, this.state.merchantDetail);
        let valt = e.target.value;
        let val = valt.split(/[\\\/]/)[2];
        this.setInterval1();
        let reader = new FileReader();
        File1 = e.target.files[0];
        File2 = e.target.files[1];
        File3 = e.target.files[2];
        File4 = e.target.files[3];
        File5 = e.target.files[4];
        File6 = e.target.files[5];
        File = [File1, File2, File3, File4, File5, File6];
        let docType = val.split(".");
        let fileType = docType[docType.length - 1];
        if (this.state.merchantId != '') {
            if (DocumentInfo === 'image1') {
                this.setState({ progressBarState: true, })
                merchantDetail.image1 = val;
                saveMerchantImages(File, this.state.merchantId, val,
                    (callBack => {
                        document.getElementById("image1Text").classList.remove("hidden")
                        this.setState({
                            progressBarState: false,
                            merchantDetail
                        })
                    })
                );
            } else if (DocumentInfo === 'agreement') {
                this.setState({ progressBarState: true, })
                merchantDetail.agreement = val;
                saveMerchantAgreement(File, this.state.merchantId, val,
                    (callBack => {
                        document.getElementById("agreementText").classList.remove("hidden")
                        // merchantDetail.PhotoState = callBack;
                        this.setState({
                            progressBarState: false,
                            merchantDetail
                        })
                    })
                );
            }
        } else {
            this.setState({
                popupState: true,
                popupStatus: 'Please select merchant Id',
            })
            this.removePopup()
        }
        //  else if (DocumentInfo === 'image2') {
        //     merchantDetail.image2 = val;
        //     if (this.state.merchantId != '') {
        //         saveMerchantImages(File, this.state.merchantId, val,
        //             (callBack => {
        //                 document.getElementById("image2Text").classList.remove("hidden")
        //                 this.setState({
        //                     progressBarState: false,
        //                     merchantDetail
        //                 })
        //             })
        //         );
        //     } else {
        //         this.setState({
        //             popupState: true,
        //             popupStatus: 'Please select merchant Id',
        //         })
        //         this.removePopup()
        //     }
        // } else if (DocumentInfo === 'image3') {
        //     merchantDetail.image3 = val;
        //     if (this.state.merchantId != '') {
        //         saveMerchantImages(File, this.state.merchantId, val,
        //             (callBack => {
        //                 document.getElementById("image3Text").classList.remove("hidden")
        //                 this.setState({
        //                     progressBarState: false,
        //                     merchantDetail
        //                 })
        //             })
        //         );
        //     } else {
        //         this.setState({
        //             popupState: true,
        //             popupStatus: 'Please select merchant Id',
        //         })
        //         this.removePopup()
        //     }
        // }
        this.setState({
            merchantDetail,
            DocumentValidationMsg: "",
            docType: DocumentInfo,
            progressValue: 20
        });
    }

    componentWillUnmount() {
        clearInterval(interval);
    }

}

const mapStateToProps = ({ }) => {
    return {
    };
};

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        getOfferDetail
    }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Documents);