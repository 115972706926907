import React from 'react'

const linkPopu = ({ popupType, handleLink, confirmationText, changeHandler, PopupData, GeneratePaymentLink, paymentLink }) => (
    <div className="background-blur">
        {
            popupType === 'EmiLinkGeneratePopup' ?
                <div className="popup-new-emi">
                    <div className="modal">
                        <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <div className="header-text">
                                        <h6 className="modal-title">
                                            <strong>Generate Payment Recieved</strong>
                                        </h6>
                                    </div>

                                    <button
                                        data-dismiss="modal"
                                        className="btn"
                                        onClick={() => handleLink('close')}
                                    >
                                        <span className="font-weight-bold">X</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <h6 className=""> Type of Payment </h6>
                                    <div className="" style={{ marginBottom: '15px' }}>
                                        <select
                                            value={PopupData.PaymentType}
                                            className="form-select formSelect w-50"
                                            onChange={(e) => changeHandler(e, 'paymentType')}
                                        >
                                            <option value=''>Select Type</option>
                                            <option value="partpayment">Part Payment</option>
                                            <option value="fullpayment">Full Payment</option>
                                        </select>
                                    </div>


                                    <div>
                                        <h6 className=""> Enter Payable Amount </h6>
                                        <div className="" style={{ marginBottom: '15px' }}>
                                            <input value={PopupData.paymentAmount} readOnly={(PopupData.PaymentType === 'fullpayment') || (PopupData.PaymentType === '') ? true : false} type="number" className="form-control w-50" onChange={(e) => changeHandler(e, 'paymentAmount')} placeholder="Enter Payable Amount" />
                                        </div>
                                    </div>

                                    <h6>Send To Debtor by the mode of :</h6>
                                    <p className="sub-heading text-secondary">
                                        select Communication Mode ( multiple Selection Allowed ){" "}
                                    </p>
                                    <div className="" style={{ marginBottom: '15px' }}>


                                        <div class="form-check">
                                            <input
                                                class="form-check-input"
                                                type="checkbox"
                                                onChange={(e) => changeHandler(e, 'EMAIL')}
                                                checked={PopupData.email}
                                                id="emailCheckbox"

                                            />&nbsp;
                                            <label class="form-check-label" htmlFor="emailCheckbox">
                                                MAIL
                                            </label>
                                        </div>
                                        <div class="form-check">
                                            <input
                                                class="form-check-input"
                                                type="checkbox"
                                                onChange={(e) => changeHandler(e, 'SMS')}
                                                checked={PopupData.sms}
                                                id="smsCheckbox"

                                            />&nbsp;
                                            <label class="form-check-label" htmlFor="smsCheckbox">
                                                SMS
                                            </label>
                                        </div>
                                        {/* <div class="form-check">
                                            <input
                                                class="form-check-input"
                                                type="checkbox"
                                                onChange={(e) => changeHandler(e, 'WHATSAPP')}
                                                checked={PopupData.whatsApp}
id="whatsappCheckbox"

                                            />&nbsp;
                                            <label class="form-check-label" htmlFor="whatsappCheckbox">
                                                WHATSAPP
                                            </label>
                                        </div> */}
                                    </div>

                                    {paymentLink ?
                                    <>
                                    <h6>Link URL</h6>
                                    <div className=""style={{display:'flex',justifyContent:'space-between',marginBottom:'15px'}}>
                                        <div style={{width:'60%'}}>
                                        <input type="text" value={paymentLink} readOnly className="form-control" id='linkt'/> 
                                        </div>&nbsp;
                                        
                                            <button className="profile-save-btn display-inline" onClick={(e) => handleLink('copy')}style={{marginTop:'2px'}}>Copy Link &nbsp;<i className='fa fa-clipboard'></i></button>
                                    </div>
                                    </>
                                         : ""}

                                </div>
                                {
                                    paymentLink ? '' :
                                        <div className="modal-footer">
                                            <button
                                                className="btn btn-primary w-25 "
                                                data-bs-dismiss="modal"
                                                aria-label="Close"
                                                onClick={() => GeneratePaymentLink()}
                                                disabled={(PopupData.email || PopupData.sms) ? '' : "disabled"}
                                            >
                                                Generate Link
                                            </button>
                                        </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                : <div className="popup-main small-popup container-fluid  dbl-border" style={{ top: '22%' }}>
                    <div className="row from-group" style={{ marginTop: '20px' }}>
                        <div className="col-sm-3 col-xs-12">
                        </div>
                        <div className="col-sm-6 col-xs-12">
                            <input className="form-control" value={confirmationText} readOnly id='linkt' />
                        </div>
                        <div className="col-sm-3 col-xs-12">
                        </div>
                    </div>
                    <div className="row from-group text-center">
                        <div className="col-xs-3 col-sm-3">
                        </div>
                        <div className="col-xs-3 col-sm-3">
                            <button className="profile-save-btn display-inline" onClick={(e) => handleLink('copy')}>Copy Link &nbsp;<i className='fa fa-clipboard'></i></button>
                        </div>
                        <div className="col-xs-3 col-sm-3">
                            <button className="profile-save-btn display-inline" onClick={(e) => handleLink('close')}>Close</button>
                        </div>
                        <div className="col-xs-3 col-sm-3">
                        </div>
                    </div>
                </div>
        }
    </div>
)

export default linkPopu;