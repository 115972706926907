import React from 'react'
import { MAIL_STRING } from '../../../utils/Constant'

// subject: NACH Registration Clarification

const NachRegistrationClarification = ({ allDetail }) => (
    <div>
        <div>Hello {allDetail != null && allDetail.firstName != null ? allDetail.firstName : ""},</div>
        <br />
        <div>Greetings for the day!</div>
        <br />
        <div>We have entered into a 3 years agreement with you and during this period you can avail loan anytime from us without any physical process.</div>
        <br />
        <div>We have defined an overall limit of {allDetail != null && allDetail.employmentDetailsPO != null && allDetail.employmentDetailsPO.netTakeHomeSalary ? allDetail.employmentDetailsPO.netTakeHomeSalary : ""} equivalent to 100% of your salary.</div>
        <br />
        <div>As your credit history builds with us we will increase your loan limit from the current amount {allDetail != null && allDetail.approveAmount != null ? allDetail.approveAmount : ""} till your current salary.</div>
        <br />
        <div>Be rest assured we will only utilize the NACH only for the amount that is actually due on repayment date.</div>
        <br />
        <div>If you still have any concern our finance team can get in touch with you.</div>
        <br />
        <div>Best Regards,</div>
        <div>Team Micromoney</div>
        <div>{MAIL_STRING.MOBILE_NO}</div>
    </div>
)

export default NachRegistrationClarification