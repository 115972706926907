import moment from "moment";
import React from "react";
import Skeleton from "react-loading-skeleton";

function leadBreakupForAggregator({ data }) {
  console.log("Dashboard",data)
  return (
    <>
      <div
        className="bg-white p-4 shadow totalcount"
        style={{

          height: "435px",
          marginTop: '20px',
          overflowY: "scroll"
        }}
      >
        <div className="d-flex flex-column justify-content-around">
          <div className="d-flex flex-row justify-content-between">
            <div className="d-flex align-item-center">
              <h5 className="totalcount1 card-heading">
                <b>Leads Breakup </b>
              </h5>
            </div>
          </div>

          <div className='credit-table' style={{  fontSize: '12px', marginTop: '-25px' }}>
            <table style={{
              borderTopLeftRadius: '15px',
              borderTopRightRadius: '15px'
            }}>
              <thead style={{ background: '#b9d8f9', color: '#000', }}>
                <tr className="thead-class" >
                  <th className="">
                    <b>{data !== undefined && data !== '' && data !== null&&data[0] !== undefined && data[0] !== '' && data[0] !== null && data[0].aggregatorName !== undefined ? 'Aggregator Name' : 'Date'}</b>
                  </th>
                  
                  <th className="">
                    <b>Total Fresh Leads</b>
                  </th>
                  <th className="">
                    <b>Approved Fresh Leads</b>
                  </th>
                  {/* <th className="">
                    <b>Total Applied</b>
                  </th> */}
                  <th className="">
                    <b>Total Fresh Disbursed</b>
                  </th>
                  <th className="">
                    <b>Total Fresh Rejected</b>
                  </th>
                  <th className="">
                    <b>Conversion Ratio</b>
                  </th>
                  <th className="">
                    <b>Rejection %</b>
                  </th>
                </tr>
              </thead>
              {data ?
                <tbody>
                  {Object.keys(data).map((item, index) => {
                    return (
                      <tr style={{ borderBottom: "none", fontWeight: '400' }}>
                        <td scope="col" style={{whiteSpace:"nowrap"}}>
                          {data[item].date !== undefined ? moment(data[item].date).format('DD-MM-YYYY') : data[item].date}
                        </td>
                        <td scope="col">
                          {data[item].totalFreshLeads}
                        </td>
                        <td scope="col">
                          {data[item].approvedFreshLeads}
                        </td>
                        <td scope="col">
                          {data[item].disburseFreshLeads}
                        </td>
                        <td scope="col">
                          {data[item].rejectedFreshLeads}
                        </td>
                        <td scope="col">

                          {data[item].conversionRatio?.toFixed(2)}%

                        </td>
                        <td scope="col">

                          {data[item].rejectionRatio?.toFixed(2)}%

                        </td>
                      </tr>
                    );
                  })
                  }


                </tbody>
                : <tbody>
                  <tr>
                    <td><Skeleton height={40} /></td>
                    <td><Skeleton height={40} /></td>
                    <td><Skeleton height={40} /></td>
                    <td><Skeleton height={40} /></td>
                    <td><Skeleton height={40} /></td>
                    <td><Skeleton height={40} /></td>
                    <td><Skeleton height={40} /></td>


                  </tr>
                  <tr>
                    <td><Skeleton height={40} /></td>
                    <td><Skeleton height={40} /></td>
                    <td><Skeleton height={40} /></td>
                    <td><Skeleton height={40} /></td>
                    <td><Skeleton height={40} /></td>
                    <td><Skeleton height={40} /></td>
                    <td><Skeleton height={40} /></td>

                  </tr>
                  <tr>
                    <td><Skeleton height={40} /></td>
                    <td><Skeleton height={40} /></td>
                    <td><Skeleton height={40} /></td>
                    <td><Skeleton height={40} /></td>
                    <td><Skeleton height={40} /></td>
                    <td><Skeleton height={40} /></td>
                    <td><Skeleton height={40} /></td>

                  </tr>
                  <tr>

                    <td><Skeleton height={40} /></td>
                    <td><Skeleton height={40} /></td>
                    <td><Skeleton height={40} /></td>
                    <td><Skeleton height={40} /></td>
                    <td><Skeleton height={40} /></td>
                    <td><Skeleton height={40} /></td>
                    <td><Skeleton height={40} /></td>
                  </tr>
                  <tr>
                    <td><Skeleton height={40} /></td>
                    <td><Skeleton height={40} /></td>
                    <td><Skeleton height={40} /></td>
                    <td><Skeleton height={40} /></td>
                    <td><Skeleton height={40} /></td>
                    <td><Skeleton height={40} /></td>
                    <td><Skeleton height={40} /></td>

                  </tr>
                  <tr>

                    <td><Skeleton height={40} /></td>
                    <td><Skeleton height={40} /></td>
                    <td><Skeleton height={40} /></td>
                    <td><Skeleton height={40} /></td>
                    <td><Skeleton height={40} /></td>
                    <td><Skeleton height={40} /></td>
                    <td><Skeleton height={40} /></td>
                  </tr>

                </tbody>}
            </table>
          </div>
        </div>
      </div>
    </>
  );
}

export default leadBreakupForAggregator;
