import React, { Component } from 'react';
import { BankFormValidation, _formatDate } from '../../../../utils/validation';
import EditBank from '../../../presentationals/Admin/CustomerPages/BankDetailPopup.component'
import { fetchBankDetail, zipAndBranchDetail } from '../AdminActionCreator.component';
import { TYPE, FORM_STATUS } from '../../../../utils/Constant'
import Popup from '../../../presentationals/Popup/Popup.component';

class BankDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            popupState: false,
            popupStatus: '',
            BankValidationMsg: "",
            userDetail: {
                BankNameState: "",
                BankUserNameState: "",
                AccountNoState: "",
                IFSCCodeState: "",
                BankBranchState: "",
                PanNoState: "",
                aadharNoState: "",
                formStatus: FORM_STATUS.UPDATE
                // IfSalriedAccountState: "",
            },
        }
    }
    render() {
        const { allDetail } = this.props;
        return (
            <div className="full-height">
                {this.state.popupState == true ?
                    <Popup
                        closePopup={this.closePopup.bind(this)}
                        popupStatus={this.state.popupStatus}
                    />
                    : ""}
                <EditBank
                    handleChange={this.handleChange.bind(this)}
                    handleSubmit={this.handleSubmit.bind(this)}
                    userDetail={this.state.userDetail}
                    BankValidationMsg={this.state.BankValidationMsg}
                    allDetail={this.state.userDetail}
                    removeBankDetailPopup={this.removeBankDetailPopup.bind(this)}
                />
            </div>
        )
    }

    componentDidMount() {
        const { allDetail } = this.props
        let userDetail = Object.assign({}, this.state.userDetail);
        if (allDetail.nameAsBankAccount != null && allDetail.nameAsBankAccount != "") {
            userDetail.BankUserNameState = allDetail.nameAsBankAccount
        }
        if (allDetail.accountNumber != null && allDetail.accountNumber != 0) {
            userDetail.AccountNoState = allDetail.accountNumber
        }
        if (allDetail.ifscCode != null && allDetail.ifscCode != "") {
            userDetail.IFSCCodeState = allDetail.ifscCode
        }
        if (allDetail.branch != null && allDetail.branch != "") {
            userDetail.BankBranchState = allDetail.branch
        }
        if (allDetail.bankName != null && allDetail.bankName != "") {
            userDetail.BankNameState = allDetail.bankName
        }
        if (allDetail.ifSalariedAccount != null && allDetail.ifSalariedAccount != "") {
            userDetail.IfSalriedAccountState = allDetail.ifSalariedAccount
        }
        if (allDetail.aadharNumber != null && allDetail.aadharNumber != "") {
            userDetail.aadharNoState = allDetail.aadharNumber
        }
        if (allDetail.panNumber != null && allDetail.panNumber != "") {
            userDetail.PanNoState = allDetail.panNumber
        }
        this.setState({
            userDetail,
        })
    }

    removePopup() {
        setTimeout(function () {
            this.setState({ popupState: false });
        }.bind(this), 5000)
    }

    closePopup() {
        this.setState({ popupState: false });
    }

    handleChange(info, e) {
        let userDetail = Object.assign({}, this.state.userDetail);
        if (info === TYPE.BANK_USER_NAME) {
            userDetail.BankUserNameState = e.target.value;
        } else if (info === TYPE.ACCOUNT_NO) {
            userDetail.AccountNoState = e.target.value;
        } else if (info === TYPE.IFSC_CODE) {
            userDetail.IFSCCodeState = e.target.value;
            let ifscCode = e.target.value
            if (ifscCode.length == 11) {
                zipAndBranchDetail(e.target.value, 'branch', (res) => {
                    if (res.status === 'success') {
                        userDetail.BankBranchState = res.branchCode,
                            userDetail.BankNameState = res.branchName
                        document.getElementById('dashBankName').value = res.branchName
                        document.getElementById('dashBankBranch').value = res.branchCode
                    }
                })
            } else {
                userDetail.BankBranchState = '',
                    userDetail.BankNameState = ''
                document.getElementById('dashBankName').value = ''
                document.getElementById('dashBankBranch').value = ''
            }
        } else if (info === TYPE.PAN_NO) {
            userDetail.PanNoState = e.target.value;
        } else if (info === TYPE.AADHAAR_NO) {
            userDetail.aadharNoState = e.target.value;
        }
        this.setState({
            userDetail,
            BankValidationMsg: ""
        });
    }

    removeBankDetailPopup(){
        this.props.removeBankDetailPopup()
    }

    handleSubmit(e) {
        const { allDetail } = this.props
        let BankValidation = BankFormValidation(
            this.state.userDetail
        )
        if (BankValidation.status) {
            fetchBankDetail(this.state.userDetail, allDetail.userId,
                (callBack) => {
                    if (callBack == "success") {
                        this.props.removeBankDetailPopup()
                        this.setState({
                            popupState: true,
                            popupStatus: 'Data Updated Successfully',
                        })
                        this.removePopup();
                    }
                })
        } else {
            this.setState({
                BankValidationMsg: BankValidation
            })
        }
    }
}

export default BankDetail;