import React, { useEffect, useState } from 'react'
import LoanUtilityMain from './listKey'
import ProcessingFee from './savemailkey'
import axios from 'axios'
import { APIS } from '../../../../utils/api-factory'
import { deleteEmailKeyData, getEmailKeyData } from '../AdminActionCreator.component'
import Loader from '../../../presentationals/Loader/Loader.component'
export default function configMain() {
    const [tabs, setabs] = useState('list')
    const [editData, setEditData] = useState({})
    const [emailData, setemailData] = useState('')
    const [loaderState, setloaderState] = useState(false)

    useEffect(() => {
        getData()
    }, [])
    const getData = () => {
        setloaderState(true)
        getEmailKeyData(callback => {
            if(callback && callback?.length > 0){
                setemailData(callback)
            }else{
                setemailData('')
            }
            setloaderState(false)

        })
    }
    const handletabs = (type) => {
        setEditData({})
        setabs(type)
        if (type === 'list') {
            getData()
        }
    }
    const handlebtnclick = (type, data) => {
        if (type === 'edit') {
            setEditData(data)
            setabs('edit')
        }
        if (type === 'delete') {
            deleteEmailKeyData(data.key,callback=>{
                if(callback==='success'){
                    getData()
                }
            })
        }
    }
    return (
        <>
            {loaderState ?
                <Loader />
                : ""}
            <div className="LoanUtility">

                <div className="tabs">
                    <div className={`tab ${tabs === 'list' ? 'active' : ''}`} onClick={() => handletabs('list')}>
                        <h5>List</h5>
                    </div>
                    <div className={`tab ${tabs === 'edit' ? 'active' : ''}`} onClick={() => handletabs('edit')}>
                        <h5>Add New</h5>
                    </div>
                </div>
                <div style={{ marginTop: '50px' }}>
                    {
                        tabs === 'list' ?
                            <LoanUtilityMain emailData={emailData} handlebtnclick={handlebtnclick} />
                            : ''}
                    {
                        tabs === 'edit' ?
                            <ProcessingFee getData={getData} setabs={setabs} editData={editData} />
                            : ''}
                </div>
            </div>
        </>
    )
}
