import React, { useState } from 'react'
import { PieChart, Pie, Sector, Cell, ResponsiveContainer, AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip } from 'recharts';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import MenuItem from '@mui/material/MenuItem';

const PieChartColors = ['#8884d8', '#ff0000', '#ffc658', '#ff8042', '#1eb0d2'];
const PieChartColors2 = ['#808080', '#1eb0d2'];

export default function LoanDashboard() {
    const [nbfcFilter, setnbfcFilter] = useState('all')
    const [calculateLoan, setCalculateLoan] = useState({
        loanAmount: '',
        productType: '',
        paymentFrequency: '',
        interestRate: '',
        loanTerm: '',
    })

    const [tableData, setTableData] = useState([
        {
            "applicationId": "#4567890",
            "expire": '2 Days',
            "disclosure": 'Awaiting',
        },
        {
            "applicationId": "#4567890",
            "expire": '2 Days',
            "disclosure": 'Awaiting',
        },
        {
            "applicationId": "#4567890",
            "expire": '2 Days',
            "disclosure": 'Awaiting',
        },
        {
            "applicationId": "#4567890",
            "expire": '2 Days',
            "disclosure": 'Awaiting',
        },
        {
            "applicationId": "#4567890",
            "expire": '2 Days',
            "disclosure": 'Awaiting',
        },
        {
            "applicationId": "#4567890",
            "expire": '2 Days',
            "disclosure": 'Awaiting',
        },
        {
            "applicationId": "#4567890",
            "expire": '2 Days',
            "disclosure": 'Awaiting',
        },
        {
            "applicationId": "#4567890",
            "expire": '2 Days',
            "disclosure": 'Awaiting',
        },
        {
            "applicationId": "#4567890",
            "expire": '2 Days',
            "disclosure": 'Awaiting',
        },
    ]);

    const pieData1 = [
        { name: 'A1', value: 100 },
        { name: 'A2', value: 300 },
        { name: 'B1', value: 100 },
        { name: 'B2', value: 80 },
        { name: 'B2', value: 80 },
    ];
    const pieData2 = [
        { name: 'A1', value: 40 },
        { name: 'A2', value: 60 },
    ];

    const AreaChartData = [
        {
            name: 'Page A',
            uv: 4000,
            pv: 2400,
            amt: 2400,
        },
        {
            name: 'Page B',
            uv: 3000,
            pv: 1398,
            amt: 2210,
        },
        {
            name: 'Page C',
            uv: 2000,
            pv: 9800,
            amt: 2290,
        },
        {
            name: 'Page D',
            uv: 2780,
            pv: 3908,
            amt: 2000,
        },
        {
            name: 'Page E',
            uv: 1890,
            pv: 4800,
            amt: 2181,
        },
        {
            name: 'Page F',
            uv: 2390,
            pv: 3800,
            amt: 2500,
        },
        {
            name: 'Page G',
            uv: 3490,
            pv: 4300,
            amt: 2100,
        },
    ];

    const ProductType = [
        {
            value: 'Loan',
            label: 'Loan',
        }
    ];

    const loanTerm = [
        {
            value: '1 Month',
            label: '1 Month',
        },
        {
            value: '2 Month',
            label: '2 Month',
        },
        {
            value: '3 Month',
            label: '3 Month',
        },
        {
            value: '6 Month',
            label: '6 Month',
        },
        {
            value: '1 Year',
            label: '1 Year',
        },
    ];

    const RecentcontactData = [
        {
            image: '',
            name: 'Andy Jones'
        },
        {
            image: '',
            name: 'Andy Jones'
        },
        {
            image: '',
            name: 'Andy Jones'
        },
        {
            image: '',
            name: 'Andy Jones'
        },
        {
            image: '',
            name: 'Andy Jones'
        },

    ]

    const handlechange = (e) => {
        const { name, value } = e.target;
        setCalculateLoan({
            ...calculateLoan,
            [name]: value
        })
    }

    const calculateLoanfn = () => {
        console.log(calculateLoan)
    }

  

    return (
        <div className="LoanDashboard">
            {/* <div className="heading">
                <h4>Dashboard</h4>
            </div> */}
             <div className="MonthDateFilter" style={{marginBottom:'30px'}}>
                    <div className="row">

                        <div className="col-lg-2 col-md-3 col-xs-12">
                            <div className="inputGrp">
                                <label htmlFor="nbfc" className='labeltag'>NBFC</label>
                                <select className='MonthinputTag' id="nbfc" name="nbfc" value={nbfcFilter} onChange={(e)=>setnbfcFilter(e.target.value)} style={{ width: '100%' }} >
                                    <option value="all">ALL</option>
                                    <option value="yashikfinlease">Yashik Finlease</option>
                                    {/* <option value="citra">Citra</option> */}
                                </select>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-3 col-xs-12">
                            <button className='FilterApply'>Apply Filter</button>
                        </div>

                    </div>
                </div>
            <div className="row">
                <div className="col-lg-4 col-md-6 col-xs-12">
                    <div className="LoanDashboardcards">
                        <div className="heads">
                            <h5>APPLICATION STATUS</h5>
                        </div>
                        <div className="Piechart">
                            <ResponsiveContainer width="100%" height="100%">
                                <PieChart width={400} height={400}>
                                    <Pie data={pieData1} dataKey="value" cx="50%" cy="50%" innerRadius={90} outerRadius={120} >
                                        {pieData1.map((entry, index) => (
                                            <Cell key={`cell-${index}`} fill={PieChartColors[index % PieChartColors.length]} />
                                        ))}
                                    </Pie>
                                    <Tooltip />
                                    <text
                                        x="50%"
                                        y="45%"
                                        dy={8}
                                        textAnchor="middle"
                                        dominantBaseline="middle"
                                        fill="#ff8042"
                                        style={{ fontSize: '50px', fontWeight: '700' }}
                                    >
                                        34%
                                    </text>
                                    <text
                                        x="50%"
                                        y="60%"
                                        dy={8}
                                        textAnchor="middle"
                                        dominantBaseline="middle"
                                        fill="#000"
                                        style={{ fontSize: '20px', fontWeight: '500' }}
                                    >
                                        Funding
                                    </text>
                                </PieChart>
                            </ResponsiveContainer>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 col-xs-12">
                    <div className="LoanDashboardcards">
                        <div className="heads">
                            <h5>LEADS TO CUSTOMER</h5>
                        </div>
                        <div className="Piechart" style={{ position: 'relative' }}>
                            <ResponsiveContainer width="100%" height="100%">
                                <PieChart width={400} height={400}>
                                    <Pie data={pieData2} dataKey="value" cx="50%" cy="50%" innerRadius={90} outerRadius={100}>
                                        {pieData2.map((entry, index) => (
                                            <Cell key={`cell-${index}`} fill={PieChartColors2[index % PieChartColors2.length]} />
                                        ))}
                                    </Pie>
                                </PieChart>
                            </ResponsiveContainer>
                            <div className="innnerPieText">
                                <h5>60%</h5>
                                <br />
                                <i className="fa fa-user-o fa-3x" aria-hidden="true"></i>
                            </div>
                            <div className='bottomtext'>
                                <h6>Customer Conversion</h6>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 col-xs-12">
                    <div className="LoanDashboardcards">
                        <div className="heads">
                            <h5>OVERALL PERFORMANCE</h5>
                        </div>
                        <div className="Piechart">
                            <ResponsiveContainer width="100%" height="100%">
                                <AreaChart
                                    width={500}
                                    height={400}
                                    data={AreaChartData}
                                    margin={{
                                        top: 10,
                                        right: 0,
                                        left: -30,
                                        bottom: 0,
                                    }}
                                >
                                    <CartesianGrid stroke="none" />
                                    <XAxis dataKey="name" tick={false} axisLine={false} />
                                    <YAxis axisLine={false} tick={false} />
                                    <Tooltip />
                                    <Area type="monotone" dataKey="uv" stroke="#FFC000" fill="#FFC000" />
                                    <Area type="monotone" dataKey="pv" stroke="#ADD8E6" fill="#ADD8E6" />
                                    <Area type="monotone" dataKey="amt" stroke="#32CD32" fill="#32CD32" />
                                </AreaChart>
                            </ResponsiveContainer>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 col-xs-12">
                    <div className="LoanDashboardcards">
                        <div className="heads">
                            <h5>LOAN SIMULATOR</h5>
                        </div>
                        <div className="forms">
                            <div className="grp">
                                <TextField fullWidth id="outlined-basic" label="Loan Amount" className='inputtag' variant="outlined" type="number"
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                ₹
                                            </InputAdornment>
                                        ),
                                    }}
                                    name="loanAmount"
                                    value={calculateLoan?.loanAmount}
                                    onChange={(e) => handlechange(e)}
                                />
                            </div>
                            <div className="grp">
                                <TextField
                                    id="outlined-select-currency"
                                    fullWidth
                                    select
                                    label="Product Type"
                                    defaultValue=""
                                    name="productType"
                                    value={calculateLoan?.productType}
                                    onChange={(e) => handlechange(e)}
                                >
                                    {ProductType.map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </div>
                            <div className="grp">
                                <TextField
                                    id="outlined-select-currency"
                                    fullWidth
                                    select
                                    label="Payment Frequency"
                                    defaultValue=""
                                    name="paymentFrequency"
                                    value={calculateLoan?.paymentFrequency}
                                    onChange={(e) => handlechange(e)}
                                >
                                    <MenuItem value="once">Once</MenuItem>
                                </TextField>
                            </div>
                            <div className="grp">
                                <TextField fullWidth id="outlined-basic" label="Interest Rate" className='inputtag' variant="outlined" type="number"
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="start">
                                                %
                                            </InputAdornment>
                                        ),
                                    }}
                                    name="interestRate"
                                    value={calculateLoan?.interestRate}
                                    onChange={(e) => handlechange(e)}
                                />
                            </div>
                            <div className="grp">
                                <TextField
                                    id="outlined-select-currency"
                                    fullWidth
                                    select
                                    label="Loan Term"
                                    defaultValue=""
                                    name="loanTerm"
                                    value={calculateLoan?.loanTerm}
                                    onChange={(e) => handlechange(e)}
                                >
                                    {loanTerm.map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </div>
                            <div className="grp">
                                <button className='btns' onClick={(e) => calculateLoanfn(e)}>Calculate</button>
                            </div>
                        </div>

                    </div>
                </div>
                <div className="col-lg-4 col-md-6 col-xs-12">
                    <div className="LoanDashboardcards">
                        <div className="heads">
                            <h5>RECENT CONTACTS</h5>
                        </div>
                        <div className="contacts" style={{ overflowY: 'auto', height: '396px' }}>
                            {
                                RecentcontactData?.length > 0 ?
                                    RecentcontactData?.map((value, index) => {
                                        return (
                                            <div className="boxx" key={index}>
                                                <div className="leftimg">
                                                    <div className="user-circle">
                                                        {
                                                            value?.image !== '' && value?.image !== null && value?.image !== undefined ?
                                                                <img src={value?.image} alt="User" width={'100%'} />
                                                                :
                                                                <i className="fa fa-user-o fa-lg" aria-hidden="true"></i>
                                                        }
                                                    </div>
                                                </div>
                                                <div className="rightimg">
                                                    <h5>Andy Jones</h5>
                                                </div>
                                            </div>
                                        )
                                    })
                                    : ''}
                        </div>

                    </div>
                </div>
                <div className="col-lg-4 col-md-6 col-xs-12">
                    <div className="LoanDashboardcards">
                        <div className="heads">
                            <h5>EXPIRING APPLICATIONS</h5>
                        </div>
                        <div className="expireTable">
                            {
                                tableData?.length > 0 ?
                                    <div className="phocket-table-new" style={{marginTop:'0px'}}>
                                        <table>
                                            <thead style={{position:'sticky',top:0}}>
                                                <tr>
                                                    <th style={{borderTopLeftRadius:'0px'}}>Application Id</th>
                                                    <th>Expire</th>
                                                    <th>Disclosure</th>
                                                    <th style={{borderTopRightRadius:'0px'}}>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {tableData.map((data, index) => (
                                                    <tr key={index}>
                                                        <td>{data.applicationId}</td>
                                                        <td>{data.expire}</td>
                                                        <td>{data.disclosure}</td>
                                                        <td>
                                                            <div className='actions'>
                                                                <div className="left">
                                                                <i class="fa fa-phone" aria-hidden="true"></i>
                                                                </div>
                                                                <div className="right">
                                                                <i class="fa fa-envelope" aria-hidden="true"></i>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                    : ''}
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}
