import React from 'react'
import DatePicker from 'react-datepicker';
import ReactTable from 'react-table'
import moment from 'moment'
import { filterCaseInsensitive } from '../../../../utils/commonUtility'

const Bulk = ({ typeToSend,searcHandler,typeHandler,searchByDateShow,dateFilter, dateToSend,bulkPaymentData, getFilterValue, FilteredData, getPageValue, pageIndexToShow,paymentDeductHandler }) => (
    <div className="container-fluid">
        <div className="row">
            <div className="col-xs-12">
                <h3 className="text-center blue-text">Bulk Payment Leads</h3>
            </div>
        </div>
        <div className='row'>
            <div className='col-sm-3 col-xs-12'>
                <label>Is Icici Account?</label>
                <select value={typeToSend} className="form-control" onChange={typeHandler}>
                    <option value='true'>Yes</option>
                    <option value='false'>No</option>
                </select>
            </div>
            <div className='col-sm-3 col-xs-12'>
            <label>Seach By Date</label>

                <DatePicker
                    value={dateToSend}
                    selected={searchByDateShow}
                    onChange={e => dateFilter(e, "date")}
                    className="form-control "
                    dateFormat="DD-MM-YYYY"
                    placeholderText="Filter By Date"
                    minDate={moment().toDate()}
                />
            </div>
            <div className='col-sm-3 col-xs-12'>
                <button onClick={searcHandler} className='profile-save-btn'> Search</button>
            </div>
            <div className='col-sm-3 col-xs-12'>
            </div>

        </div>
        <div className="row">
            {bulkPaymentData != '' ?
                <ReactTable
                    getTrProps={(state, rowInfo, column) => {
                        return {
                            style: {
                                backgroundColor: rowInfo != undefined ? rowInfo.row.count >= 7 ? '#d2908f' : "":"",
                            }
                        };
                    }}
                  
                    data={bulkPaymentData}
                    columns={[{
                        Header: 'Application Id',
                        accessor: 'applicationId'
                    }, {
                        Header: 'Loan Id',
                        accessor: 'applicationLoanId'
                    }, {
                        Header: 'Name',
                        accessor: 'userName'
                    }, {
                        Header: 'Mobile No.',
                        accessor: 'mobileNumber'
                    }, {
                        Header: 'Disburse Date',
                        accessor: 'disburseDate'
                    }, {
                        Header: 'PayBack Date',
                        accessor: 'loanPaybackDate'
                    }, {
                        Header: 'Amount',
                        accessor: 'loanAmount'
                    }, {
                        Header: 'Repayment Amount',
                        accessor: 'loanPaybackAmount',
                    }, {
                        Header: 'Nach Status',
                        accessor: 'nachStatus',
                        Cell: row => (
                            <div>{row.original.nachStatus == true ? 'Active' : 'Inactive'}</div>
                        )
                    }, {
                        Header: 'Installment',
                        accessor: 'loanEmi'
                    }, {
                        Header: 'Tenure',
                        accessor: 'loanDuration',
                        className: 'width-30px',
                        headerClassName: 'width-30px content-none',
                    }, {
                        Header: 'status',
                        accessor: 'followUpStatusCode',
                        show: false,
                    }, {
                        Header: 'Deduct Payment',
                        accessor: 'loanId',
                        Cell: row => (
                            <div>
                                <button onClick={e => paymentDeductHandler(row.original)}><span className="fa fa-credit-card" /></button>
                            </div>
                        )
                    }]}
                    defaultPageSize={10}
                    className="-striped -highlight phocketAdmin_table text-center"
                    filterable={true}
                    defaultFilterMethod={filterCaseInsensitive}
                    onFilteredChange={(filtered) => getFilterValue(filtered)}
                    defaultFiltered={FilteredData}
                    onPageChange={(pageIndex) => getPageValue(pageIndex)}
                    page={pageIndexToShow}
                    previousText={<span className="fa fa-chevron-left" />}
                    nextText={<span className="fa fa-chevron-right" />}
                /> 
                
                
                : ""}
        </div>
    </div>
)

export default Bulk