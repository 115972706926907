import React, { Component } from 'react';
import { Outlet } from 'react-router-dom';

class AppLayout extends Component {
	render() {
		return (
			<div id="app-container" className="container-fluid full-height">
				<div className="row full-height">

						<Outlet />
				</div>
			</div>
		);
	}
}

export default AppLayout;