import React, { useEffect, useState } from 'react'
import Pieneedlechart from './needlecustomer';
import Areachart from './customerareachart';
import livechaticon from './images/livechaticon.png'
import clockimg from './images/clockimg.png'
import profileimg from './images/img.png'
import arrowup from './images/arrowup.png'
import arrowupred from './images/arrowupred.png'
import usericon from './images/usericon.png'
import downloadicon from './images/downloadicon.png'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import ReactApexChart from "react-apexcharts";
import { getCustomerServiceEmployee, getPerformanceDataApiCustomerService, getPerformanceDataGraphApiCustomerService, getSoftApprovalCommentsApiCustomerService, getProgressReportApi, getOperationTabularDataApiCustomerService, downloadOperationData } from './actioncreator';
import { getStartDateOfPreviousSevenDays, reverseFormat, todayDate } from '../../../Marketing/maketingComponents/utils/utils';
import Loader from '../../../../presentationals/Loader/dots'

function PerformanceDashboard() {
    const [fromdate, setfromdate] = useState(getStartDateOfPreviousSevenDays(new Date()));
    const [todate, settodate] = useState(todayDate());
    const [filtertype, setfiltertype] = useState('last7days');
    const [reconcilemodal, setreconcilemodal] = useState(false);
    const [popuplabeltype, setpopuplabeltype] = useState('');
    const [page, setpage] = useState(1);
    const [size, setsize] = useState(100);
    const [operationEmployees, setOperationEmployees] = useState([]);
    const [perfomanceData, setPeformanceData] = useState([]);
    const [emailId, setEmailId] = useState('');
    const [loaderState, setloaderState] = useState(false);
    const [areachartData, setareachartData] = useState('');
    const [softApprovalData, setSoftApprovalData] = useState([]);
    const [nameComment, setNameComments] = useState([]);
    const [dataComment, setDataComments] = useState([]);
    const [employeeData, setEmployeeData] = useState('');
    const [performanceLoader, setPerformanceGraphLoader] = useState('');
    const [commentLoader, setCommentLoader] = useState('');
    const [dashboardData, setSmallDashboardData] = useState({});
    const [tabularData, setTabularData] = useState('');
    const [process, setProcess] = useState('');
    const [downloadLoader, setloaderStateDownload] = useState(false);


    const handleChange = (e) => {
        
        if (e.target.name === "fromDate") {
            setfromdate((reverseFormat(e.target.value)));
        } else {
            settodate((reverseFormat(e.target.value)));
        }
    };
    const DowloadReport = () => {
        setloaderStateDownload(true)
        downloadOperationData(fromdate, todate, emailId, process, callback => {
            setloaderStateDownload(false)

        })

    }
    const applyfilter = () => {
        setloaderState(true)
        getPerformanceDataApiCustomerService(fromdate, todate, emailId, callback => {
            setPeformanceData(callback.data)
            setSmallDashboardData(callback.performanceResp)
            setloaderState(false)
        })
        setPerformanceGraphLoader(true)
        getPerformanceDataGraphApiCustomerService(fromdate, todate, emailId, callback => {
            setareachartData(callback.perfAnalysis)
            setPerformanceGraphLoader(false)


        })
        setCommentLoader(true)
        getSoftApprovalCommentsApiCustomerService(fromdate, todate, emailId, callback => {
            setSoftApprovalData(callback.commentStats)
            makegraphDataOpportunity(callback.commentStats)
            setCommentLoader(false)


        })
        // getProgressReportApi(fromdate, todate, emailId, callback => {
        //     console.log(callback)
        //     setSmallDashboardData(callback)
        // })
    }
    const handlefilter = (e) => {
       
        const selectedValue = e && e.target && e.target.value !== undefined ? e.target.value : e;
        const currentDate = new Date();
        let newStartDate = null;
        let newEndDate = currentDate;

        switch (selectedValue) {
            case 'last7days':
                newStartDate = new Date(currentDate);
                newStartDate.setDate(currentDate.getDate() - 6);
                break;
            case 'lastmonth':
                newStartDate = new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, currentDate.getDate());
                break;
            case 'last2month':
                newStartDate = new Date(currentDate.getFullYear(), currentDate.getMonth() - 2, currentDate.getDate());
                break;
            case 'last3month':
                newStartDate = new Date(currentDate.getFullYear(), currentDate.getMonth() - 3, currentDate.getDate());
                break;
            case 'last6month':
                newStartDate = new Date(currentDate.getFullYear(), currentDate.getMonth() - 6, currentDate.getDate());
                break;
            case 'lastyear':
                newStartDate = new Date(currentDate.getFullYear() - 1, currentDate.getMonth(), currentDate.getDate());
                break;
            default:
                break;

        }

        const start = newStartDate.toISOString().split('T')[0];
        const end = newEndDate.toISOString().split('T')[0];
       

        setfromdate(reverseFormat(start));
        settodate(reverseFormat(end));
        // applyfilter()
        applyfilterNew(reverseFormat(start), reverseFormat(end))

    };
    const applyfilterNew = (start, end) => {
        setloaderState(true)
        getPerformanceDataApiCustomerService(start, end, emailId, callback => {
            setPeformanceData(callback.data)
            setSmallDashboardData(callback.performanceResp)
            setloaderState(false)
        })
        setPerformanceGraphLoader(true)
        getPerformanceDataGraphApiCustomerService(start, end, emailId, callback => {
            setareachartData(callback.perfAnalysis)
            setPerformanceGraphLoader(false)


        })
        setCommentLoader(true)
        getSoftApprovalCommentsApiCustomerService(start, end, emailId, callback => {
            setSoftApprovalData(callback.commentStats)
            makegraphDataOpportunity(callback.commentStats)
            setCommentLoader(false)


        })
        // getProgressReportApi(start, end, emailId, callback => {
        //     console.log(callback)
        //     setSmallDashboardData(callback)
        // })
    }
    const sortedData = {
        "Category 1": 700,
        "Category 2": 500,
        "Category 3": 400,
        "Category 4": 300,
        "Category 5": 200
    };
    const sortedData3 = dataComment;
    sortedData3.sort((a, b) => b - a);
    const nameOpportunity = Object.keys(nameComment);

    var options = {
        series: [{
            data: Object.values(sortedData3),
        }],
        chart: {
            type: 'bar',
            height: 270,
        },

        plotOptions: {
            bar: {
                borderRadius: 0,
                horizontal: true,
                barHeight: '90%',
                isFunnel: true,
                borderRadius: 1,
                orientation: 'vertical',
                radius: 2,
                enableShades: true,
                shadeIntensity: 0.9,
                reverseNegativeShade: true,
                distributed: true,
                useFillColorAsStroke: false,


                colorScale: {
                    ranges: [{
                        from: 0,
                        to: 0,
                        color: undefined,
                        foreColor: undefined,
                        name: undefined,
                    }],
                    inverse: false,
                    min: undefined,
                    max: undefined
                },


            },
        },
        colors: ['#4B49AC', '#7978E9', '#7DA0FA', '#98BDFF', '#98BDFFCC'],
        fill: {
            colors: ['#4B49AC', '#7978E9', '#7DA0FA', '#98BDFF', '#98BDFFCC'],
            opacity: 0.3,
            type: 'gradient',
            gradient: {
                shade: 'light',
                type: "vertical",
                shadeIntensity: 1.8,
                gradientToColors: false,
                inverseColors: false,
                opacityFrom: 0.9,
                opacityTo: 1,
                colorStops: []
            },
        }, dataLabels: {
            enabled: true,
            formatter: function (val, opt) {
                const opportunityName = nameOpportunity[opt.dataPointIndex];
                return val;
            },

            dropShadow: {
                enabled: true,
            },
        },
        xaxis: {
            categories: nameComment,
        },
        legend: {
            position: 'right',
            show: false,
            markers: {
                fillColors: ['#4B49AC', '#7978E9', '#7DA0FA', '#98BDFF', '#98BDFFCC'],
            },
        },


    };


    let data = [
        // { label: 'Total Assigned Leads', percentage: 100 },
        // { label: 'Soft Follow up', percentage: 80 },
        // { label: 'Quality Check Done', percentage: 50 },
        // { label: 'E-sign/E-mandate', percentage: 40 },
        // { label: 'Reference Verified', percentage: 30 },
        // { label: 'Auto/Manual RFD', percentage: 20 },
        // { label: 'Pending Leads', percentage: 10 },
        // { label: 'Expired Leads', percentage: 5 },
    ];

    let b = "20%";
    let c = "80%";


    const handlefunnelgraphpopup = (label, process) => {
       
        setpopuplabeltype(label);
        setProcess(process)
        setloaderState(true)
        getOperationTabularDataApiCustomerService(fromdate, todate, emailId, process, callback => {
            setTabularData(callback)
            setreconcilemodal(true);
            setloaderState(false)


        })
    }
    const getemployees = () => {
        setloaderState(true)
        getCustomerServiceEmployee(page, size, callback => {
            setOperationEmployees(callback)
            setloaderState(false)
            getPerformanceData(callback.teamMembers[0])
            getEmpDashboardSata(callback.teamMembers[0].emailId)


        })
    }
    const getEmpDashboardSata = (data) => {
        // getProgressReportApi(fromdate, todate, data, callback => {
        //     console.log(callback)
        //     setSmallDashboardData(callback)
        // })
    }
    useEffect(() => {
        getemployees()

    }, [])
    const optionnews = {
        items: 3,
        loop: true,
        autoplay: true,
        // nav: true,
        // dots:true
    };

    const items = operationEmployees && operationEmployees.teamMembers
        ? operationEmployees.teamMembers.map((data, i) => (
            <div key={i + 1} className="itemcard" onClick={() => getPerformanceData(data)}>
                <div className="subcard">
                    <img className="imgbox" src={profileimg} alt="" />
                    <h4 className='h4'>{data.name} </h4>
                    <h6 className='h6'>{data.profile}</h6>
                </div>
            </div>
        ))
        : [];
    const getPerformanceData = (data) => {
        setEmployeeData(data)
        setEmailId(data.emailId)
        setloaderState(true)
        getPerformanceDataApiCustomerService(fromdate, todate, data.emailId, callback => {
            setPeformanceData(callback.data)
            setSmallDashboardData(callback.performanceResp)
            setloaderState(false)

        })
        // getProgressReportApi(fromdate, todate, data.emailId, callback => {
        //     console.log(callback)
        //     setSmallDashboardData(callback)
        // })

        setPerformanceGraphLoader(true)
        getPerformanceDataGraphApiCustomerService(fromdate, todate, data.emailId, callback => {
            setareachartData(callback.perfAnalysis)
            setPerformanceGraphLoader(false)
        })
        setCommentLoader(true)
        getSoftApprovalCommentsApiCustomerService(fromdate, todate, data.emailId, callback => {
            setSoftApprovalData(callback.commentStats)
            makegraphDataOpportunity(callback.commentStats)
            setCommentLoader(false)
        })
    }
    const makegraphDataOpportunity = (To) => {
        let name = [];
        let data = [];
        let hb = [{
            "count": 1,
            "label": "QC Done"
        }, {
            "count": 100,
            "label": "QC Done"
        },
        {
            "count": 10,
            "label": "QC Done"
        },
        {
            "count": 50,
            "label": "QC Done"
        }

        ]

        for (const item of To) {
            const { count, label, } = item;

            name.push(label);

            data.push(count);
        }

        setNameComments(name);
        setDataComments(data);

    }
    const sortedData1 = [...perfomanceData].sort((b, a) => a.value - b.value);

    return (
        <>

            <div className="Performancetrackermainview container-fluid">

                <h5 className='headingh5'>Customer Service Team</h5>
                <div className="row">
                    <div className="col-lg-4 col-sm-6">
                        <div className="boxperformance">
                            <div className="Performancedetailheader" style={{ display: 'flex' }}>
                                <div className="leftside">
                                    {/* rfdLeads
                                    :
                                    1
                                    rfdLeadsPercentage
                                    :
                                    33.33
                                    totalPendingLeads
                                    :
                                    2
                                    totalPendingLeadsPercentage
                                    :
                                    66.67 */}
                                    <div className="subleftside">
                                        <div className="bg-colorbox" style={{ background: '#496f49' }}></div>
                                        {employeeData !== '' ? <>
                                            <img className="imgbox" src={profileimg} alt="" />
                                            <h4 className='h4'>{employeeData.name}</h4>
                                            <h6 className='h6'>{employeeData.profile}</h6>
                                        </>
                                            : ""}
                                        <div className="timerbox" style={{ marginTop: employeeData === '' ? '120px' : 'auto' }}>
                                            <div className="clockimg">
                                                <img src={clockimg} width={'72%'} alt="" style={{ marginTop: '10px' }} />
                                            </div>
                                            <div className="timer">
                                                <h6 className='h6'>Average TAT / Lead</h6>
                                                <h6 className='h6' style={{ color: '#000' }}>01:00 hr</h6>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div className="rightside">
                                    <div className="subrightside">
                                        <h4 className='h4'>Performance Stats</h4>
                                        {dashboardData.markedAsCompleteLeadsPercentage !== undefined ?
                                            <div className="chart">
                                                {dashboardData.markedAsCompleteLeadsPercentage !== undefined ? <Pieneedlechart dashboardData={dashboardData} /> : ""}
                                                <div className="box" style={{ display: 'flex', justifyContent: 'space-between', marginTop: '-10%' }}>
                                                    <h5 style={{ marginLeft: '5%' }}>{dashboardData.markedAsCompleteLeadsPercentage !== undefined ? dashboardData.markedAsCompleteLeadsPercentage : 0}%</h5>
                                                    <h5 style={{ marginRight: '15%' }}>{dashboardData.totalAssignedLeadsPercentage !== undefined ? dashboardData.totalAssignedLeadsPercentage : 0}%</h5>
                                                </div>
                                            </div>
                                            : <div style={{ minHeight: '100px', width: '200px' }}></div>}
                                        <div className="row" style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <div className="">
                                                <h5 style={{ color: '#486F88', fontSize: '12px', marginTop: '10px' }}>Marked Complete</h5>
                                                <div className="boxtext">
                                                
                                                    <h5 className='text-center'>{dashboardData.markedAsCompleteLeads !== undefined ? dashboardData.markedAsCompleteLeads : 0}</h5>&nbsp;
                                                    {/* <div className="square bg-green">
                                                        <h6>{dashboardData.rfdLeadsPercentage !== undefined ? dashboardData.rfdLeadsPercentage : 0} % <img src={arrowup} alt="" /></h6>
                                                    </div> */}
                                                </div>
                                            </div>
                                            <div className="">
                                                <h5 style={{ color: '#486F88', fontSize: '12px', marginTop: '10px' }} >Total Assigned</h5>
                                                <div className="boxtext">
                                                    <h5 className='text-center'>{dashboardData.totalAssignedLeads !== undefined ? dashboardData.totalAssignedLeads : 0}</h5>&nbsp;
                                                    {/* <div className="square bg-red">
                                                        <h6>{dashboardData.totalPendingLeadsPercentage !== undefined ? dashboardData.totalPendingLeadsPercentage : 0} % <img src={arrowupred} alt="" /></h6>
                                                    </div> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="buttontext">
                                <h5><img src={livechaticon} width={'5%'} alt="" />&nbsp;Start Live Chat</h5>
                            </div> */}
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-8 colrights" style={{ float: 'inline-end' }}>

                        {items.length > 2 ?
                            <div className="boxteammembers">
                                <h5>Team Members</h5>
                                <div className="caraousel">
                                    <OwlCarousel
                                        {...optionnews}
                                    >
                                        {items}
                                    </OwlCarousel>

                                </div>
                            </div>
                            :
                            items.length > 0 && items.length <= 2 ?

                                <>
                                    <div className='boxteammembers-credit'>

                                        {operationEmployees && operationEmployees.teamMembers
                                            ? operationEmployees.teamMembers.map((data, i) => (
                                                <div key={i + 1} className="itemcard" onClick={() => getPerformanceData(data)}>
                                                    <div className="subcard">
                                                        <img className="imgbox" src={profileimg} alt="" />
                                                        <h4 className='h4'>{data.name} </h4>
                                                        <h6 className='h6'>{data.profile}</h6>
                                                    </div>
                                                </div>
                                            ))
                                            : ''}
                                    </div>
                                </>
                                : ""
                        }
                    </div>
                </div>

                <hr className='hrtag' />
                <div className="row searchboxrows">
                    <div className="col-sm-3 col-xs-12">
                        <div className="filterHeaders">
                            From
                        </div>
                        <div className="inputbox" style={{ display: 'flex' }}>

                            <input
                                type="date"
                                id="startDate"
                                placeholder="DD/MM/YY"
                                name="fromDate"
                                value={reverseFormat(fromdate)}
                                onChange={handleChange}
                                className="datefilter form-control"
                            />
                            <h5 style={{ marginLeft: '20px' }}>-</h5>
                        </div>
                    </div>

                    <div className="col-sm-3 col-xs-12">
                        <div className="filterHeaders">
                            To
                        </div>

                        <input
                            type="date"
                            id="endDate"
                            name="toDate"
                            min={fromdate}
                            value={reverseFormat(todate)}
                            onChange={handleChange}
                            placeholder="DD/MM/YY"
                            className="datefilter form-control"
                        />
                    </div>
                    <div className="col-sm-3 col-xs-12">
                        <button
                            className="btn btn-primary mt-4"
                            onClick={() => applyfilter()}
                        >
                            Apply Filter
                        </button>

                    </div>
                    <div className='col-sm-3 col-xs-12' style={{ float: 'right' }}>

                        <div className="search-comp" style={{ marginTop: '26px' }}>
                            <select className="searchform form-control" onChange={(e) => handlefilter(e)}>
                                <option value="">select Filter</option>

                                <option value="last7days">Last 7 Days</option>
                                <option value="lastmonth">Last Month</option>
                                <option value="last2month">Last 2 Months</option>
                                <option value="last3month">Last 3 Months</option>
                                <option value="last6month">Last 6 Months</option>
                                <option value="lastyear">Last Year</option>
                            </select>
                        </div>
                    </div>
                </div>

                <div className="row rowgraph">
                    <div className="col-sm-12" style={{ marginTop: '20px' }}>
                        <div className="operationprocessgraph">
                            <h5>Customer Service Process</h5>
                            <div className="funnelhorizontalgraph">
                                {loaderState ?
                                    <Loader />
                                    : ""}
                                <div className="cols">

                                    {loaderState === false && sortedData1.map((item, index) => {

                                        const currentB = index === 0 ? "20%" : b;
                                        const currentC = index === 0 ? "90%" : c;
                                        const parsedCurrentB = parseInt(currentB);
                                        const parsedCurrentC = parseInt(currentC);
                                        b = 100 - parsedCurrentB;
                                        c = 100 - parsedCurrentC;
                                        let currentB2 = parsedCurrentB + 3;
                                        let currentC2 = parsedCurrentC - 3;
                                        b = currentB2
                                        c = currentC2


                                        return (
                                            <>

                                                <div className="box-customer" key={index} style={{
                                                    borderRadius: index === 0 ? '6px 0px 0px 6px' :
                                                        index === 7 ? '0px 6px 6px 0px' : '0px'
                                                }} onClick={() => handlefunnelgraphpopup(item.label, item.process)}>
                                                    <h5><img src={usericon} width={'13%'} alt="" />&nbsp;{item.label}</h5>
                                                    <div className="subbox" style={{ clipPath: index === 0 ? `polygon(0px 5%, 100% 24%, 100% 87%, 0px 95%)` : `polygon(0 ${currentB}%, 100% ${currentB2}%, 100% ${currentC2}%, 0 ${currentC}%)` }}>
                                                    </div>
                                                    <h5 className='h5' style={{ color: item.value === 100 ? '#EE9B5E' : '#ffffff' }}>{item.value}</h5>
                                                    <h4>{item.percentage}%</h4>
                                                </div>
                                            </>
                                        );
                                    })}

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-xs-12" style={{ marginTop: '30px' }}>
                        <div className="operationprocessgraph">
                            <h5>Performance Analysis</h5>
                            <div className="chart">
                                {!performanceLoader ?
                                    <Areachart areachartData={areachartData} />
                                    : <Loader />}
                            </div>

                        </div>
                    </div>
                    <div className="col-lg-6 col-xs-12" style={{ marginTop: '30px' }}>
                        <div className="operationprocessgraph">
                            <h5>Soft Follow up (Comments) Stats</h5>
                            {!commentLoader ?
                                <div className='funnelchart2'>
                                    <div className="chart">
                                        <ReactApexChart options={options} series={options.series} type="bar" height={235} width={'100%'} />
                                    </div>
                                    <div className='text' style={{ height: "229px", overflow: 'auto' }}>
                                        {nameComment.map((name, index) => (
                                            <h6 key={index}>
                                                {name} - <b>{dataComment[index]}</b>
                                            </h6>
                                        ))}
                                    </div>


                                </div>
                                : <Loader />}
                        </div>
                    </div>
                </div>

                {
                    reconcilemodal && tabularData !== '' ?
                        <>
                            <div className="background-blur"></div>
                            < div className={`modal ${reconcilemodal === true ? 'animation-top' : ''}`}>
                                <div className="modal-dialog">
                                    <div className="modal-content">
                                        <div className="modalheader">
                                            <h5 className="popuph5"><img src={usericon} width={'13%'} alt="" />&nbsp;{popuplabeltype !== '' ? popuplabeltype : '-'}</h5>

                                            <div className="btnss">

                                                <button onClick={() => DowloadReport()} type="button" className='downloadbtn'>
                                                    <img src={downloadicon} width={'15%'} alt="" />&nbsp;
                                                    Download
                                                </button>&nbsp;

                                                <button type="button" onClick={() => setreconcilemodal(false)}>
                                                    <i className="fa fa-times fs-1"></i>
                                                </button>

                                            </div>

                                        </div>
                                        <div className="modal-body" style={{ overflowX: 'auto', maxHeight: '600px' }}>
                                            {downloadLoader ? <Loader /> : ""}

                                            <div className='credit-table tablesecond' >
                                                <table className=' table2'>
                                                    <thead>
                                                        <tr className="thead-className">

                                                            <th className="">
                                                                Application Id
                                                            </th>
                                                            <th className="">
                                                                Name
                                                            </th>

                                                            <th className="">
                                                                Approved Amount
                                                            </th>
                                                            <th className="">
                                                                Approved Date
                                                            </th>
                                                            <th className="">
                                                                Lead Age
                                                            </th>
                                                            <th className="">
                                                                No. of Comments
                                                            </th>
                                                            <th className="">
                                                                No. of Follow Ups
                                                            </th>
                                                        </tr>
                                                    </thead>

                                                    <tbody style={{ fontWeight: '400' }}>
                                                        {tabularData !== '' && tabularData.detailedBusinessDevelopmentProcessReport !== null && tabularData.detailedBusinessDevelopmentProcessReport.length > 0 && tabularData.detailedBusinessDevelopmentProcessReport.map((data, i) => {
                                                            return (
                                                                <tr key={i}>

                                                                    <td scope="col">
                                                                        {data.applicationId}
                                                                    </td>
                                                                    <td scope="col">
                                                                        {data.name}
                                                                    </td>

                                                                    <td scope="col" >
                                                                        ₹ {data.approvedAmount}
                                                                    </td>
                                                                    <td scope="col" >
                                                                        {data.loanApproveDate}
                                                                    </td>
                                                                    <td scope="col" >
                                                                        {data.leadage}
                                                                    </td>
                                                                    <td scope="col" >
                                                                        {data.commentCount}
                                                                    </td>
                                                                    <td scope="col" >
                                                                        {data.followUpCount}
                                                                    </td>

                                                                </tr>
                                                            )
                                                        })}


                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </>
                        : ""
                }
            </div>
        </>
    )
}
export default PerformanceDashboard;


function Card({ img, txt, operationEmployees }) {

    return (
        <>
            {operationEmployees && operationEmployees.teamMembers ? operationEmployees.teamMembers.map((data) => {
                return (
                    <div className="itemcard">
                        <div className="subcard">
                            <img className="imgbox" src={img} alt="" />
                            <h4 className='h4'>{data.name} </h4>
                            <h6 className='h6'>{data.profile}</h6>
                        </div>
                    </div>
                )
            })

                : ""}
        </>
    )
}
