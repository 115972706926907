import React, { Component } from 'react'
import CustomerAvailReport from '../../../presentationals/Admin/AdminCoupons/CustomerAvailedReport.component'
import CouponAvailedData from '../../../presentationals/Popup/CouponAvailedData.component';

class OfferAvReport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            CouponAvailedDataState: false,
            FilteredData: [],
            pageIndexToShow: 0,
        }
    }
    render() {
        return (
            <div>
                <CustomerAvailReport
                    getFilterValue={this.getFilterValue.bind(this)}
                    FilteredData={this.state.FilteredData}
                    getPageValue={this.getPageValue.bind(this)}
                    pageIndexToShow={this.state.pageIndexToShow}
                />
                {this.state.CouponAvailedDataState == true ?
                    <CouponAvailedData
                        getFilterValue={this.getFilterValue.bind(this)}
                        FilteredData={this.state.FilteredData}
                        getPageValue={this.getPageValue.bind(this)}
                        pageIndexToShow={this.state.pageIndexToShow}
                    />
                    : ""}
            </div>
        )
    }

    getFilterValue(filtered) {
        this.setState({ FilteredData: filtered })
    }

    getPageValue(pageIndex) {
        this.setState({ pageIndexToShow: pageIndex })
    }

    CouponAvailedDataHandler() {
        this.setState({ CouponAvailedDataState: true })
    }
}

export default OfferAvReport;