import React from 'react'

const SubCategoryAdd = ({ merchantData, postDataHandler, ulSubCategoryData,
    listingOfCategory, getDataHandler, catIdForTable, MerchantValidationMsg,
    deleteSubCategory }) => (
        <div className="container-fluid">
            <div className="row">
                <h3 className="text-center">Add Sub-Category Details</h3>
            </div>
            <div className="row form-group">
                <div className="col-sm-4 col-xs-12">
                    <label className="font-12px">Select Category</label>
                    <select className="form-control" id="subCatStatus" value={merchantData.categoryId} onChange={e => getDataHandler(e, "selectCatId")}>
                        <optgroup className="display-none">
                            <option value="">Select Category</option>
                        </optgroup>
                        {listingOfCategory != null && listingOfCategory != ''&& listingOfCategory.length>0 ?
                            listingOfCategory.map((list, i) => {
                                return (
                                    <optgroup key={i} label="Category">
                                        {list.Category != null && list.Category.length > 0 ?
                                            list.Category.map((category, i) => {
                                                return (
                                                    <option key={i} value={category.categoryid}>{category.category}</option>
                                                )
                                            })
                                            : ""}
                                    </optgroup>
                                )
                            })
                            : ""}
                    </select>
                    <div className="font-12px margin-left-25px height-25px validation-color">
                        {MerchantValidationMsg.field == "categoryId" ? MerchantValidationMsg.msg : ""}
                    </div>
                </div>
                <div className="col-sm-4 col-xs-12">
                    <label className="font-12px">Sub Category Name</label>
                    <input type="text" className="form-control" value={merchantData.subCatName} placeholder="Enter Promo Category Name" id="subCatName" onChange={e => getDataHandler(e, "subCatName")} />
                    <div className="font-12px margin-left-25px height-25px validation-color">
                        {MerchantValidationMsg.field == "subCatName" ? MerchantValidationMsg.msg : ""}
                    </div>
                </div>
                <div className="col-sm-4 col-xs-12">
                    <label className="font-12px">Promo Category Status</label>
                    <select className="form-control" id="subCatStatus" value={merchantData.selectSubCatStatus ? 'Active' : 'Inactive'} onChange={e => getDataHandler(e, "selectSubCatStatus")}>
                        <option value="">Select Status</option>
                        <option value="Active">Active</option>
                        <option value="Inactive">Inactive</option>
                    </select>
                    <div className="font-12px margin-left-25px height-25px validation-color">
                        {MerchantValidationMsg.field == "selectSubCatStatus" ? MerchantValidationMsg.msg : ""}
                    </div>
                </div>
            </div>
            <div className="row">
                <button className="profile-save-btn center-block" id="subCategoryAdd" onClick={e => postDataHandler()}>
                    <span className="fa fa-plus"></span>&nbsp;&nbsp;ADD
                        </button>
            </div>
            {listingOfCategory != null && listingOfCategory != ''&& listingOfCategory.length>0 ?
                <table className="text-center loan-detail-table max-width-500px">
                    <thead>
                        <tr className="background-blue text-white">
                            <th>ID</th>
                            <th>subCategory</th>
                            <th>status</th>
                            <th>delete</th>
                        </tr>
                    </thead>

                    {listingOfCategory.map((listing, i) => {
                        return (
                            <tbody key={i}>
                                {listing.SubCategory != null && listing.SubCategory.length > 0 ? listing.SubCategory.map((data, i) => {
                                    return (
                                        <tr key={i} style={{ display: catIdForTable == data.categoryid ? 'table-row' : 'none' }} className="pointer text-black" onClick={e => ulSubCategoryData(e, data)}>
                                            <td>{data.subcategoryid}</td>
                                            <td>{data.subcategory_name}</td>
                                            <td>{data.status == true ? 'Active' : 'Inactive'}</td>
                                            <td><span className="fa fa-trash" onClick={e => deleteSubCategory(data)} /></td>
                                        </tr>
                                    )
                                }) : ""}
                            </tbody>
                        )

                    })}
                </table>
                : ""}
        </div>
    )

export default SubCategoryAdd;