import React from 'react';
import ReactTable from "react-table";
import { STRING, ROLE } from '../../../../utils/Constant'
import DotLoader from '../../Loader/layerLoader.component'
import { filterCaseInsensitive } from '../../../../utils/commonUtility'

const BulkStatus = ({ userDetailHandler, dataFilter, forwordpage, backpage, allUserDetail,
    statusHandler, admin, loadarState, refreshpage, allStatus, changeStatusHandler, getPageValue,
    statusSelectHandler, statusSaveHandler, usersForChangeStatus, getFilterValue, FilteredData,
    pageIndexToShow }) => (
    <div className="container-fluid">
        <div className="row">
            <h3 className="text-center blue-text">Bulk Status Update</h3>
        </div>
        <div className="row form-group">
            <div className="col-sm-3 col-xs-12">
                <label className="font-12px"><span className="fa fa-search" /> By Status</label>
                <select className="form-control" onChange={(e) => statusHandler(e)} defaultValue={STRING.SELECT_ALL}>
                    <option value="" disabled>Search By Status</option>
                    {allStatus != null && allStatus != undefined && allStatus != '' ?
                        allStatus.map((status, i) => {
                            if (status.statusId != STRING.APPROVED_STATUS && status.statusId != STRING.PAID && status.statusId != STRING.DISBURSED && status.statusId != STRING.RECEIVED && status.statusId != STRING.SELECT_ALL
                                && status.adminStatus != 'Cancelled'
                                && status.adminStatus != 'Rejected'
                                && status.adminStatus != 'Approved'
                                && status.adminStatus != 'Disbursed'
                                && status.adminStatus != 'Paid'
                                && status.adminStatus != 'Permanent Reject'
                                && status.adminStatus != 'Incomplete Application'
                                && status.adminStatus != 'Soft Approval'
                                && status.adminStatus != 'Documents Collected'
                              



                            ) {
                                return (
                                    <option value={status.statusId} key={i}>{status.adminStatus}</option>
                                );
                            }
                        }) : ""}
                </select>
            </div>
            <div className="col-sm-3 col-xs-12">
                <label className="font-12px"><span className="fa fa-search" /> Application No.</label>
                <input type="number" className="form-control" onChange={e => dataFilter(e, "applicationNo")} />
            </div>
            <div className="col-sm-3 col-xs-12">
                <label className="font-12px"><span className="fa fa-search" /> Email ID</label>
                <input type="email" className="form-control" onChange={e => dataFilter(e, "email")} />
            </div>
            <div className="col-sm-3 col-xs-12">
                <label className="font-12px" ><span className="fa fa-search" /> Mobile No.</label>
                <input type="number" onChange={e => dataFilter(e, "mobileNo")} className="form-control" />
            </div>
        </div>

        <div className="row form-group">
            <div className="col-sm-3  ">
                <div className="">
                    <label className="font-12px"><span className="fa fa-search" />Select New Status</label>

                    <select className="form-control" onChange={(e) => statusSelectHandler(e)}>
                        <option value="" disabled>Select New Status</option>
                        {allStatus != null && allStatus != undefined && allStatus != '' ?
                            allStatus.map((status, i) => {
                                if (status.statusId != STRING.APPROVED_STATUS && status.statusId != STRING.PAID && status.statusId != STRING.DISBURSED && status.statusId != STRING.RECEIVED && status.statusId != STRING.SELECT_ALL && status.statusId != STRING.CANCEL_BY_USER && status.statusId != STRING.REJECT_STATUS
                                    && status.adminStatus != 'Cancelled'
                                    && status.adminStatus != 'Rejected'
                                    && status.adminStatus != 'Approved'
                                    && status.adminStatus != 'Disbursed'
                                    && status.adminStatus != 'Paid'
                                    && status.adminStatus != 'Permanent Reject'
                                    && status.adminStatus != 'Incomplete Application'
                                    && status.adminStatus != 'Soft Approval'
                                    && status.adminStatus != 'Documents Collected'
                                    && status.adminStatus != 'Write-Off'
                                    && status.adminStatus != 'Payment Overdue'
                                ) {
                                    return (
                                        <option value={status.statusId} key={i}>{status.adminStatus}</option>
                                    );
                                }
                            }) : ""}
                    </select>
                </div>

            </div>
            <div className="col-sm-2">
                <button style={{ marginTop: '20px' }} className="profile-save-btn " onClick={e => statusSaveHandler()}>Submit</button>
            </div>
            <div className="col-sm-6 text-right" style={{ marginTop: '20px' }}>
                <button className="admin-pagination-btn" id="forword" onClick={e => refreshpage()}><span className="fa fa-refresh" /></button>
                <button className="admin-pagination-btn" id="backword" onClick={e => backpage()}><span className="fa fa-chevron-left" /></button>
                <button className="admin-pagination-btn" id="forword" onClick={e => forwordpage()}><span className="fa fa-chevron-right" /></button>
                {/* <p>1-100</p> */}
            </div>
        </div>

        <div className="row text-center">
            <div className="col-xs-12 overflow-x-auto no-padding">
                {loadarState == false && allUserDetail != null && allUserDetail != '' ?
                    <div className='overflow-auto'>
                        <table className='phocket-table'>
                            <thead>

                                <tr>
                                    <td>Select</td>
                                    <td>Application Id</td>
                                    <td>Name</td>
                                    <td>Mobile Number</td>
                                    <td>Apply Date</td>
                                    <td>Loan Amount</td>
                                    <td>Tenure</td>
                                    <td>Loan Reason</td>
                                    <td>Status</td>
                                    <td>Device</td>
                                    {/* <td>Details</td> */}


                                </tr>

                            </thead>
                            <tbody>
                                {allUserDetail && allUserDetail.map((data, i) => {
                                    return (
                                        <tr key={i}>
                                            <td> <div>
                                                <input className="phocket-checkbox" checked={usersForChangeStatus.includes(data.loanId) ? true : false} name="bulkStatusChange" type="checkbox" id={data.loanId} onChange={(e) => changeStatusHandler(e, data)} />
                                                <label htmlFor={data.loanId}><span></span></label>
                                            </div></td>
                                            <td> {data.applicationId}</td>
                                            <td>{data.userName}</td>
                                            <td>{data.mobileNumber}</td>
                                            <td>{data.loanApplyDate}</td>
                                            <td>{data.loanAmount}</td>
                                            <td>{data.loanDuration}</td>
                                            <td>{data.loanReason}</td>
                                            <td>{data.applicationStatus}</td>
                                            <td>{data.loginDevice}</td>
                                            {/* <td>
                                                <div>
                                                    {admin.rolelist != undefined ? admin.rolelist.indexOf(ROLE.VIEW) >= 0 ?
                                                        <button onClick={e => userDetailHandler(data)}><span className="fa fa-info" /></button>
                                                        : "" : ""}
                                                </div></td> */}


                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>

                  
                    : ""}
                {loadarState == true ?
                    <DotLoader />
                    : ""}
            </div>
        </div>
    </div>
)

export default BulkStatus;
