import React from 'react'
import ReactTable from "react-table";
import { filterCaseInsensitive } from '../../../../utils/commonUtility'

const NoticeDelivered = ({ noticeData,
    getFilterValue, FilteredData, getPageValue, pageIndexToShow }) => (
    <div className="container-fluid">
        <div className="row">
            <div className="col-xs-12">
                <h3 className="text-center blue-text">Notice Page</h3>
            </div>
        </div>
        <div className="row">
          
                {noticeData !==''?
                      <table className='phocket-table -striped -highlight phocketAdmin_table'>

                      <thead>
                          <tr>
                              <td>Loan Id</td>
                              <td>Application Id</td>
                              <td>Name</td>
                              <td>Mobile Number</td>
                              <td>Email Id</td>
                              <td>Notice Delivery Date</td>
                              <td>Days Count</td>

                          </tr>
                      </thead>

                      <tbody>
                          {noticeData && noticeData.map((data, i) => {
                              return (
                                  <tr>
                                      <td> {data.applicationLoanId}</td>
                                      <td>{data.applicationId}</td>
                                      <td>{data.userName}</td>
                                      <td>{data.mobileNumber}</td>
                                      <td>{data.emailId}</td>
                                      <td>{data.date}</td>
                                      <td>{data.count}</td>

                                  </tr>
                              )
                          })}
                      </tbody>
                  </table>
            :""}
        </div>
    </div>
)

export default NoticeDelivered;