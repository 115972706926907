import React from 'react'
import moment from 'moment';

const TransactionDetails = ({  details
  }) => (
        <div className="container-fluid Phocket-transaction-details">
            <div className="row">
                <h3 className="text-center blue-text">Transaction Details</h3>
            </div>


            <div className="row form-group overflow-auto">
                {/* <h3 className="text-center">Transaction History</h3> */}
                <table>
                    <thead>
                        <tr>
                            <th>Date Of Emi</th>
                            <th>Amount</th>
                            <th>Transaction Id</th>
                            <th>Application Id</th>
                            <th>Date Of Submission</th>
                            <th>Name Of Agent</th>
                        </tr>
                    </thead>

                    <tbody>
                        {details != null && details != '' && details.length > 0 ? details.map((detail, i) => {
                            return (
                                <tr key={i}>
                                    <td>{moment(new Date(detail.dateOfEmi)).format('DD-MM-YYYY')}</td>
                                    <td>{detail.amount}</td>
                                    <td>{detail.transactionId}</td>
                                    <td>{detail.applicationId}</td>
                                    <td>{moment(new Date(detail.dateOfSubmission)).format('DD-MM-YYYY')}</td>

                                    {/* <td>{new Date(detail.dateOfSubmission)}</td> */}
                                    <td>{detail.nameOfAgent}</td>

                                     </tr>
                            )
                        })
                            : null}
                    </tbody>
                </table>
            </div>
        </div>
    )

export default TransactionDetails;