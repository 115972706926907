import React, { useState } from 'react'
import Nodataimg from '../../../../images/nodata.png'
import TablePopup from './TablePopup';
import EqualizerSharpIcon from '@mui/icons-material/EqualizerSharp';
import TableViewSharpIcon from '@mui/icons-material/TableViewSharp';
import moment from "moment";
const getMonth = (type) => {
    const date = new Date();
    const month = date.getMonth()
    const year = date.getFullYear()
    let newmonth = Number(month);
    if (type == 'current') {
        newmonth = ++newmonth;
    }
    const currentMonth = `${year}-${newmonth < 10 ? '0' + newmonth : newmonth}`
    return currentMonth

}


export default function DebtManagement() {
    const [fromDate, setFromDate] = useState(getMonth('prev'))
    const [toDate, setToDate] = useState(getMonth('current'))
    const [nbfcFilter,setnbfcFilter] =useState('all')
    const [tablePopup, setTablePopup] = useState(false);
    const [tablePopupData, setTablePopupData] = useState([]);
    const [popupType, setPopupType] = useState('')
    const [tableData, setTableData] = useState({
        'Jan': {
            data: [
                {
                    "Creditor": "Bank De",
                    "startingBalance": 1000.00,
                    "intrestRate": 5.0,
                    "Payment": 150.00,
                    "newBalance": 850.00,
                    "payoffDate": "2024-08-01"
                },
                {
                    "Creditor": "Credit Card B",
                    "startingBalance": 500.00,
                    "intrestRate": 18.0,
                    "Payment": 100.00,
                    "newBalance": 400.00,
                    "payoffDate": "2024-09-15"
                },
                {
                    "Creditor": "Loan C",
                    "startingBalance": 2000.00,
                    "intrestRate": 3.5,
                    "Payment": 200.00,
                    "newBalance": 1800.00,
                    "payoffDate": "2024-12-31"
                },
                {
                    "Creditor": "Loan C",
                    "startingBalance": 2000.00,
                    "intrestRate": 3.5,
                    "Payment": 200.00,
                    "newBalance": 1800.00,
                    "payoffDate": "2024-12-31"
                },
                {
                    "Creditor": "Loan C",
                    "startingBalance": 2000.00,
                    "intrestRate": 3.5,
                    "Payment": 200.00,
                    "newBalance": 1800.00,
                    "payoffDate": "2024-12-31"
                },
                {
                    "Creditor": "Loan C",
                    "startingBalance": 2000.00,
                    "intrestRate": 3.5,
                    "Payment": 200.00,
                    "newBalance": 1800.00,
                    "payoffDate": "2024-12-31"
                },
                {
                    "Creditor": "Loan C",
                    "startingBalance": 2000.00,
                    "intrestRate": 3.5,
                    "Payment": 200.00,
                    "newBalance": 1800.00,
                    "payoffDate": "2024-12-31"
                }
            ],
            totalData: {
                "Creditor": "Bank A",
                "startingBalance": 1000.00,
                "intrestRate": 5.0,
                "Payment": 150.00,
                "newBalance": 850.00,
                "payoffDate": "2024-08-01"
            },
        },
        'Feb': {
            data: [
                {
                    "Creditor": "Bank De",
                    "startingBalance": 1000.00,
                    "intrestRate": 5.0,
                    "Payment": 150.00,
                    "newBalance": 850.00,
                    "payoffDate": "2024-08-01"
                },
                {
                    "Creditor": "Credit Card B",
                    "startingBalance": 500.00,
                    "intrestRate": 18.0,
                    "Payment": 100.00,
                    "newBalance": 400.00,
                    "payoffDate": "2024-09-15"
                },
                {
                    "Creditor": "Loan C",
                    "startingBalance": 2000.00,
                    "intrestRate": 3.5,
                    "Payment": 200.00,
                    "newBalance": 1800.00,
                    "payoffDate": "2024-12-31"
                },
                {
                    "Creditor": "Loan C",
                    "startingBalance": 2000.00,
                    "intrestRate": 3.5,
                    "Payment": 200.00,
                    "newBalance": 1800.00,
                    "payoffDate": "2024-12-31"
                },
                {
                    "Creditor": "Loan C",
                    "startingBalance": 2000.00,
                    "intrestRate": 3.5,
                    "Payment": 200.00,
                    "newBalance": 1800.00,
                    "payoffDate": "2024-12-31"
                },
                {
                    "Creditor": "Loan C",
                    "startingBalance": 2000.00,
                    "intrestRate": 3.5,
                    "Payment": 200.00,
                    "newBalance": 1800.00,
                    "payoffDate": "2024-12-31"
                },
                {
                    "Creditor": "Loan C",
                    "startingBalance": 2000.00,
                    "intrestRate": 3.5,
                    "Payment": 200.00,
                    "newBalance": 1800.00,
                    "payoffDate": "2024-12-31"
                }
            ],
            totalData: {
                "Creditor": "Bank A",
                "startingBalance": 1000.00,
                "intrestRate": 5.0,
                "Payment": 150.00,
                "newBalance": 850.00,
                "payoffDate": "2024-08-01"
            },
        },


    }
    );

    const handleChange = (e) => {
        console.log(e.target.value)
        if (e.target.name === "fromDate") {
            setFromDate(e.target.value)
        } else if(e.target.name === "toDate") {
            setToDate(e.target.value)
        }else{
            setnbfcFilter(e.target.value)
        }
    };

    const handleTablePopup = (month, data, type) => {
        setPopupType(type)
        const obj = {
            month: month,
            data: data
        }
        console.log(obj)
        setTablePopup(true)
        setTablePopupData(obj)
    }

    const closePopup = () => {
        setTablePopup(false)
        setTablePopupData([])
        setPopupType('')
    }


    return (
        <>
            <div className="DebtManagement">
                <div className="MonthDateFilter">
                    <div className="row">
                        <div className="col-lg-2 col-md-3 col-xs-12">
                            <div className="inputGrp">
                                <label htmlFor="from" className='labeltag'>From</label>
                                <input type="month" id='from' className='MonthinputTag' name="fromDate" value={fromDate} onChange={handleChange} />
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-3 col-xs-12">
                            <div className="inputGrp">
                                <label htmlFor="from" className='labeltag'>To</label>
                                <input type="month" min={fromDate} id='from' className='MonthinputTag' name="toDate" value={toDate} onChange={handleChange} />
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-3 col-xs-12">
                            <button className='FilterApply'>Apply Filter</button>
                        </div>
                        <div className="col-lg-2 col-md-3 col-xs-12" style={{float:'inline-end'}}>
                            <div className="inputGrp">
                                <label htmlFor="nbfc" className='labeltag'>NBFC</label>
                                <select className='MonthinputTag' id="nbfc" name="nbfc" value={nbfcFilter} onChange={handleChange} style={{width:'100%'}} >
                                    <option value="all">ALL</option>
                                    <option value="yashikfinlease">Yashik Finlease</option>
                                    {/* <option value="citra">Citra</option> */}
                                </select>
                                 </div>
                        </div>

                    </div>
                </div>

                {
                    Object.keys(tableData)?.length > 0 ?
                        Object.entries(tableData)?.map(([month, data], index) => {
                            return (
                                <div className="tables" key={index}>
                                    <div className="row">
                                        <div className="col-sm-2 col-xs-12" style={{ height: '250px' }}>
                                            <div className="LeftBox">
                                                <div className="subbox">
                                                    <h5>{month}</h5>
                                                </div>
                                                <div className="eyeicons" style={{ flexDirection: 'column' }}>
                                                    <div className="circle-eye" onClick={() => handleTablePopup(month, data, "table")} style={{ margin: 'auto' }}>
                                                        <TableViewSharpIcon />
                                                        {/* <i class="fa fa-eye fa-2x" aria-hidden="true" style={{color:'#fff'}}></i> */}
                                                    </div>
                                                    <div className="circle-eye" onClick={() => handleTablePopup(month, data, "graph")} style={{ margin: 'auto' }}>
                                                        <EqualizerSharpIcon />
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                        {console.log(data)}
                                        <div className="col-sm-10 col-xs-12">
                                            {
                                                data?.data?.length > 0 ?
                                                    <div className="phocket-table-new newTable">
                                                        <table>
                                                            <thead>
                                                                <tr>
                                                                    <th>Creditor</th>
                                                                    <th>Starting Balance</th>
                                                                    <th>Interest Rate</th>
                                                                    <th>Payment</th>
                                                                    <th>New Balance</th>
                                                                    <th>Estimated Payoff Date</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {data?.data.map((data, index) => (
                                                                    <tr key={index}>
                                                                        <td>{data.Creditor}</td>
                                                                        <td>{data.startingBalance}</td>
                                                                        <td>{data.intrestRate}</td>
                                                                        <td>{data.Payment}</td>
                                                                        <td>{data.newBalance}</td>
                                                                        <td>{moment(data.payoffDate).format('DD-MM-YYYY')}</td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    : ''}
                                        </div>
                                    </div>
                                    <hr className="hrtag" style={{ display: index + 1 == Object.keys(tableData)?.length ? 'none' : '' }} />
                                </div>
                            )
                        })
                        :
                        <p style={{ marginTop: '100px' }} className="text-center">
                            <img src={Nodataimg} style={{ width: '30%' }} />
                        </p>
                }
                {
                    tablePopup ?
                        <TablePopup tablePopupData={tablePopupData} close={closePopup} tabName={popupType === 'table' ? 'DebtManagement' : 'DebtManagementGraph'} />
                        : ''}
            </div>
        </>
    )
}
