import React, { Component } from 'react'
import AddLocationUi from '../../../presentationals/Admin/AdminCoupons/AddLocation.component'
import { saveLocation, deleteCity } from './AddMerchentActionCreator.Component'
import Popup from '../../../presentationals/Popup/Popup.component';
import { AddLocationValidation } from './Validation'
import Confirmation from '../../../presentationals/Popup/ConfirmationPopup.component'

class LocationAdd extends Component {
    constructor(props) {
        super(props);
        this.state = {
            confirmationPopup: false,
            confirmationText: '',
            idForDelete: '',
            popupState: false,
            popupStatus: '',
            MerchantValidationMsg: '',
            merchantData: {
                location: '',
                locationId: ''
            }
        }
    }

    render() {
        const { listingOfCategory } = this.props
        return (
            <div>
                {this.state.popupState == true ?
                    <Popup
                        closePopup={this.closePopup.bind(this)}
                        popupStatus={this.state.popupStatus}
                    />
                    : ""}
                <AddLocationUi
                    merchantData={this.state.merchantData}
                    getDataHandler={this.getDataHandler.bind(this)}
                    listingOfCategory={listingOfCategory}
                    ulLocationData={this.ulLocationData.bind(this)}
                    postDataHandler={this.postDataHandler.bind(this)}
                    MerchantValidationMsg={this.state.MerchantValidationMsg}
                    deleteCity={this.deleteCity.bind(this)}
                />
                {this.state.confirmationPopup == true ?
                    <Confirmation
                        cancelLoanConfirm={this.cancelLoanConfirm.bind(this)}
                        confirmationText={this.state.confirmationText} />
                    : ""}
            </div>
        )
    }

    cancelLoanConfirm(conType) {
        if (conType == 'yes') {
            deleteCity(this.state.idForDelete, (callBack) => {
                if (callBack == 'success') {
                    this.setState({
                        confirmationPopup: false,
                        merchantData: {
                            location: '',
                            locationId: ''
                        }
                    })
                    this.props.getAllData()
                }
            })
        } else if (conType == 'no') {
            this.setState({ confirmationPopup: false })
        }
    }


    deleteCity(locationData) {
        this.setState({
            idForDelete: locationData.locationid,
            confirmationPopup: true,
            confirmationText: 'Are you sure to Delete ' + locationData.location
        })
    }

    removePopup() {
        setTimeout(function () {
            this.setState({ popupState: false });
        }.bind(this), 5000)
    }

    closePopup() {
        this.setState({ popupState: false });
    }

    ulLocationData(e, data) {
        let merchantData = Object.assign({}, this.state.merchantData);
        merchantData.location = data.location;
        merchantData.locationId = data.locationid
        this.setState({ merchantData })
    }

    getDataHandler(e, type) {
        let merchantData = Object.assign({}, this.state.merchantData);
        if (type == "location") {
            merchantData.location = e.target.value;
        }
        this.setState({ merchantData, MerchantValidationMsg: '' })
    }

    postDataHandler() {
        let MerchantValidation = AddLocationValidation(
            this.state.merchantData
        )
        if (MerchantValidation.status) {
            this.setState({
                MerchantValidationMsg: MerchantValidation,
            })
            saveLocation(this.state.merchantData, (callBack) => {
                if (callBack == 'success') {
                    this.setState({
                        popupState: true,
                        popupStatus: 'Location saved Successfully',
                        merchantData: {
                            location: '',
                            locationId: ''
                        }
                    })
                    this.removePopup();
                    this.props.getAllData()
                } else {
                    this.setState({
                        popupState: true,
                        popupStatus: 'Please try again',
                    })
                    this.removePopup();
                }
            })
        } else {
            this.setState({
                MerchantValidationMsg: MerchantValidation,
            })
        }
    }
}

export default LocationAdd;