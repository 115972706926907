import React from 'react'
import ReactTable from "react-table";
import DatePicker from 'react-datepicker';
import { filterCaseInsensitive } from '../../../../utils/commonUtility'

const OBR = ({ OBDData, tableKeyHandler, tableKey, getFilterValue, FilteredData,
    getPageValue, pageIndexToShow, menuExtensionResponse, getDataByNumber, dateFilter,
    searchByDateShow }) => (
        <div className="container-fluid full-height">
            <h3 className="text-center blue-text">Outbound Report</h3>
            {/* <div className="row form-group">
                <div className="col-sm-3 col-xs-12"></div>
                <div className="col-sm-3 col-xs-12">
                    <DatePicker
                        selected={searchByDateShow}
                        onChange={e => dateFilter(e)}
                        className="form-control"
                        dateFormat="DD/MM/YYYY"
                        placeholderText="Select Date"
                    />
                </div>
                <div className="col-sm-3 col-xs-12">
                    <select className="form-control" defaultValue="Missed" onClick={e => tableKeyHandler(e)}>
                        <optgroup className="display-none">
                            <option value="">Select One</option>
                        </optgroup>
                        <optgroup label="Call Transfer Status">
                            {IBDData != '' && IBDData != null ?
                                Object.keys(IBDData).map((mainKeys) => {
                                    return (
                                        <option value={mainKeys}>{mainKeys}</option>
                                    )
                                })
                                : null}
                        </optgroup>
                    </select>
                </div>
                <div className="col-sm-3 col-xs-12"></div>
            </div> */}
        
                {OBDData != null && OBDData != '' && OBDData.length > 0 ?
                <div className='overflow-auto'>
                    <table className='phocket-table'>
                        <thead>

                            <tr>
                                <th>Call Date</th>
                                <th>Call Status</th>
                                <th>Call Time</th>
                                <th>Customer Call Duration</th>
                                <th>Caller Number</th>
                                <th>Menu</th>
                                <th>Amount</th>
                                <th>date</th>
                            </tr>

                        </thead>
                        <tbody>
                            {OBDData && OBDData.map((promo, i) => {
                                return (
                                    <tr key={i}>
                                        <td>{promo.callDate}</td>
                                        <td>{promo.callStatus}</td>
                                        <td>{promo.callTime}</td>
                                        <td>{promo.customerCallDuration}</td>
                                        <td>{promo.callerNumber}</td>
                                        <td>{promo.menu}</td>
                                        <td>{promo.amount}</td>
                                        <td>{promo.date}</td>


                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
                : ""}
        </div>
    )

export default OBR;