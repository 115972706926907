import React, { Component } from 'react'
import { getAmbassadorDetailApiAdmin } from '../AdminActionCreator.component'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import AmbassadorDetails from './AmbassadorDetail.container'
import AmbassadorStats from './AmbassadorStats.container'
import AmbassadorAction from './AmbassadorAction'
import CommentsPage from './Comments.container'
import EditPage from './EditAmbassadorDetail.container'

class Ambassador extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pageViewState: 'AmbassadorDetails',
            registerBtnState: false
        }
    }

    componentWillMount() {
        const { ambassadorAllData } = this.props;
        this.props.getAmbassadorDetailApiAdmin(ambassadorAllData.ambassadorId,
            (callBack) => {

            })
    }

    render() {
        const { ambasadorDetails, ambassadorAllData, admin } = this.props;
        return (
            <div className="full-height container-fluid white-bg-transparent">
                <div className="row form-group">
                    <div className="col-xs-12">
                        <h3 className="text-center blue-text">
                            <span className="fa fa-chevron-left pull-left pointer" onClick={(e) => this.detailBackHandler()} />
                            {ambassadorAllData != null ? ambassadorAllData.fullName != null ? ambassadorAllData.fullName : "" : ""} Details ({ambassadorAllData != null ? ambassadorAllData.ambassadorId != null ? ambassadorAllData.ambassadorId : "" : ""})</h3>
                    </div>
                </div>
                <div className="row">
                    <div className="cal-xs-12">
                        <div className="btn-group">
                            <button className={this.state.pageViewState == 'AmbassadorDetails' ? 'active' : null} id="AmbassadorDetails" onClick={(e) => this.sendHandler('AmbassadorDetails')}>Details</button>
                            <button className={this.state.pageViewState == 'AmbassadorStats' ? 'active' : null} id="AmbassadorStats" onClick={(e) => this.sendHandler('AmbassadorStats')}>Stats</button>
                            <button className={this.state.pageViewState == 'AmbassadorAction' ? 'active' : null} id="AmbassadorAction" onClick={(e) => this.sendHandler('AmbassadorAction')}>Action</button>
                            <button className={this.state.pageViewState == 'AmbassadorComments' ? 'active' : null} id="AmbassadorComments" onClick={(e) => this.sendHandler('AmbassadorComments')}>Comment</button>
                            <button className={this.state.pageViewState == 'AmbassadorEditDetails' ? 'active' : null} id="AmbassadorEditDetails" onClick={(e) => this.sendHandler('AmbassadorEditDetails')}>Edit Details</button>
                        </div>
                    </div>
                </div>
                <div className="row">
                    {this.state.pageViewState == 'AmbassadorDetails' ?
                        <AmbassadorDetails
                            ambassadorAllData={ambassadorAllData}
                        />
                        : this.state.pageViewState == 'AmbassadorStats' ?
                            <AmbassadorStats
                                ambasadorDetails={ambasadorDetails} />
                            : this.state.pageViewState == 'AmbassadorAction' ?
                                <AmbassadorAction
                                    ambassadorAllData={ambassadorAllData}
                                    admin={admin}
                                />
                                : this.state.pageViewState == 'AmbassadorComments' ?
                                    <CommentsPage
                                        ambassadorAllData={ambassadorAllData}
                                        admin={admin}
                                    />
                                    : this.state.pageViewState == 'AmbassadorEditDetails' ?
                                        <EditPage
                                            ambassadorAllData={ambassadorAllData}
                                            admin={admin}
                                            registerBtnState={this.state.registerBtnState}
                                        />
                                        : null}
                </div>
            </div>
        )
    }

    detailBackHandler() {
        this.props.detailBackHandler()
    }

    sendHandler(type) {
        this.setState({ pageViewState: type })
    }
}

const mapStateToProps = ({ ambasadorDetails }) => {
    return {
        ambasadorDetails: ambasadorDetails
    };
};

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        getAmbassadorDetailApiAdmin
    }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Ambassador);
