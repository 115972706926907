import moment from 'moment'
import React from 'react'

const TransferPay = ({ popupHandle, changeHandler, razorPayTransactionData }) => (
    <div className='container'>
        <div className='row'>
            <div className='col-sm-3 col-xs-12'></div>
            <div className='col-sm-6 col-xs-12' style={{ marginTop: '50px' }}>
                <h3 className='text-center'><b>Transfer Amount From Razorpay</b></h3>
                <label style={{ marginTop: '40px' }}>Amount</label>
                <input className='form-control' onChange={(e) => changeHandler(e)} placeholder='Amount' />
                <div className='text-center'>
                    <button className='profile-save-btn' onClick={() => popupHandle()}>Transfer</button>
                </div>
            </div>
            <div className='col-sm-3 col-xs-12'></div>

        </div>
        {razorPayTransactionData !== undefined && razorPayTransactionData !== ''&& razorPayTransactionData !== null && razorPayTransactionData.length > 0 ?
            <div className='row'>
                <div className="overflow-x-auto">
                    <table className="phocket-table">
                        <thead>
                            <tr>
                                <th>Amount</th>
                                <th>Date</th>
                                <th>Status</th>

                            </tr>
                        </thead>
                        <tbody>
                            {razorPayTransactionData.map((data, i) => {
                                return (
                                    <tr key={i}>
                                        <td>{data.amount}</td>
                                        <td>{moment(data.date).format('DD-MM-YYYY')}</td>
                                        <td>{data.status}</td>

                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
            : ""}

    </div>
)
export default TransferPay