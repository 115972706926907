import React, { Component } from 'react'
import ManualAssignUi from '../../../presentationals/Admin/Settings/ManualAssign.component'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
    loanLeadsManualAssigned, getRoleAssignView, bulkAssignByLoanId,
    getlistByApplication, getlistByEmail, getistByNumber, getUserDetailByLoanId
} from '../AdminActionCreator.component'
import { STRING } from '../../../../utils/Constant'
import { _preFormatDate } from '../../../../utils/validation'
import Popup from '../../../presentationals/Popup/Popup.component';
import UsersForAssignUi from '../../../presentationals/Popup/AssignUserPopup.component'
import UserDetail from '../CustomerPages/UserDetailMain.container';
import DotLoader from '../../../presentationals/Loader/DotLoader.component'
import { APIS } from '../../../../utils/api-factory'
let loanIdList = []
class CompleteLeads extends Component {
    constructor(props) {
        super(props);
        this.myRef = React.createRef();

        this.state = {
            FilteredData: [],
            pageIndexToShow: 0,
            allUserDetail: [],
            allDetail: [],
            userDetail: [],
            userState: true,
            viewState: false,
            loadarState: false,
            forwordPageState: 1,
            actionButtonState: true,
            followUpStatus: STRING.SELECT_ALL,
            dataFilterState: {
                searchByAppNo: '',
                searchByemail: '',
                searchBymobile: '',
                searchByDate: '',
                searchByDateShow: ''
            },
            loanApprovalLeadData: [],
            loanIdForAssign: [],
            assignPopupState: false,
            emailIdForAssign: '',
            allAdminList: '',
            popupState: false,
            popupStatus: '',
            actionButtonState: false,
            markCompleteBtn: false,
            searchByEndDate: '',
            tabType: '',
            assignedToId: '',
            allstatusData: "",
            emailToAssign: '',
            typeInput: 'applicationNo'
        }
    }

    componentWillMount() {
        const { admin } = this.props
        this.getLoanLeads()

        getRoleAssignView(admin.emailId, (callBack) => {
            this.setState({ allAdminList: callBack })
        })
    }
    getData() {

    }
    componentDidMount() {
        fetch(APIS.GET_ALL_STATUS)
            .then(res => res.json())
            .then(json => {
                this.setState({ allstatusData: json })

            })

    }

    getLoanLeads() {
        this.setState({ loadarState: true })
        this.props.loanLeadsManualAssigned(this.state.followUpStatus, this.state.forwordPageState, this.state.assignedToId, this.state.dataFilterState.searchByDate, '', this.state.searchByEndDate, this.state.tabType, '', '',
            (callBack) => {
                this.getTableData(callBack)
                this.setState({ loadarState: false })
            })
    }

    getTableData(data) {
        this.setState({ loanApprovalLeadData: [] })
        data.length > 0 ?
            data.map(leadData => {
                if (this.state.loanIdForAssign.includes(leadData.loanId)) {
                    this.state.loanApprovalLeadData.push({ assign: true, applicationStatus: leadData.applicationStatus, assignUser: leadData.assignUser, applicationId: leadData.applicationId, userName: leadData.userName, emailId: leadData.emailId, mobileNumber: leadData.mobileNumber, loanId: leadData.loanId, loanApplyDate: leadData.loanApplyDate })
                } else {
                    this.state.loanApprovalLeadData.push({ assign: false, applicationStatus: leadData.applicationStatus, assignUser: leadData.assignUser, applicationId: leadData.applicationId, userName: leadData.userName, emailId: leadData.emailId, mobileNumber: leadData.mobileNumber, loanId: leadData.loanId, loanApplyDate: leadData.loanApplyDate })
                }
            })
            : null
        this.setState({ loanApprovalLeadData: this.state.loanApprovalLeadData })
    }
    getLoaderState(data) {
        this.setState({ loadarState: data })
    }
    filterHandler(e) {
        this.setState({typeInput:e.target.value})

    }
    render() {
        const { admin, allStatus } = this.props
        return (
            <div className="full-height">
                {this.state.userState == true ?
                    <ManualAssignUi
                    myRef={this.myRef}
                        typeInput={this.state.typeInput}
                        filterHandler={this.filterHandler.bind(this)}
                        admin={admin}
                        getLoaderState={this.getLoaderState.bind(this)}
                        getLoanLead={this.getLoanLeads.bind(this)}
                        allAdminList={this.state.allAdminList}
                        allUserDetail={this.state.loanApprovalLeadData}
                        getFilterValue={this.getFilterValue.bind(this)}
                        FilteredData={this.state.FilteredData}
                        getPageValue={this.getPageValue.bind(this)}
                        pageIndexToShow={this.state.pageIndexToShow}
                        paginationHandler={this.paginationHandler.bind(this)}
                        allStatus={this.state.allstatusData}
                        dataFilterState={this.state.dataFilterState}
                        followUpStatus={this.state.followUpStatus}
                        dataFilter={this.dataFilter.bind(this)}
                        loadarState={this.state.loadarState}
                        manualAssignCheck={this.manualAssignCheck.bind(this)}
                        assignProceedHandler={this.assignProceedHandler.bind(this)}
                        loanIdForAssign={this.state.loanIdForAssign}
                        userDetailHandler={this.userDetailHandler.bind(this)}
                    />
                    : null}
                {this.state.viewState == true ?
                    <UserDetail
                        getData={this.getData.bind(this)}

                        allDetail={this.state.allDetail}
                        userDetail={this.state.userDetail}
                        admin={admin}
                        backHandler={this.backHandler.bind(this)}
                        actionButtonState={this.state.actionButtonState}
                        markCompleteBtn={this.state.markCompleteBtn}
                    />
                    : ""}
                {this.state.loadarState ?
                    <DotLoader />
                    : ""}
                {this.state.assignPopupState ?
                    <UsersForAssignUi
                        emailToAssign={this.state.emailToAssign}
                        changeHandler={this.changeHandler.bind(this)}
                        closeTaskAssignPopup={this.closeTaskAssignPopup.bind(this)}
                        taskAssignHandler={this.taskAssignHandler.bind(this)}
                        emailIdForAssign={this.state.emailIdForAssign}
                        allAdminList={this.state.allAdminList} />
                    : null}
                {this.state.popupState == true ?
                    <Popup
                        closePopup={this.closePopup.bind(this)}
                        popupStatus={this.state.popupStatus}
                    />
                    : ""}
            </div>
        )
    }

    userDetailHandler(data) {
        getUserDetailByLoanId(data.loanId,
            (callBack) => {
                this.setState({
                    userState: false,
                    viewState: true,
                    allDetail: callBack,
                    userDetail: data
                })
            })
    }

    assignProceedHandler() {
        this.setState({ assignPopupState: true })
    }

    closeTaskAssignPopup() {
        this.setState({ assignPopupState: false })
    }
    changeHandler(e) {

        this.setState({ emailToAssign: e.target.value })
    }

    taskAssignHandler(e) {
        const { admin } = this.props
        if (this.state.emailToAssign !== '') {
            bulkAssignByLoanId(this.state.loanIdForAssign, this.state.emailToAssign, admin.emailId,
                callBack => {
                    if (callBack == 'success') {
                        this.setState({
                            popupState: true,
                            popupStatus: "Saved successfully",
                            loanIdForAssign: [],
                        })
                        this.removePopup();
                        this.getLoanLeads()
                    } else {
                        this.setState({
                            popupState: true,
                            popupStatus: "Please try again",
                        })
                        this.removePopup();
                    }
                })
            this.setState({ assignPopupState: false })
        } else {
            this.setState({
                popupState: true,
                popupStatus: "select Emailid for assignment",
            })
            this.removePopup();
        }
    }

    manualAssignCheck(e, data) {
        this.state.loanApprovalLeadData.length > 0 ?
            this.state.loanApprovalLeadData.map(leadData => {
                if (data.loanId == leadData.loanId) {
                    leadData.assign = e.target.checked
                }
            }) : null
        this.setState({ loanApprovalLeadData: this.state.loanApprovalLeadData })
        if (e.target.checked == true) {
            if (this.state.loanIdForAssign.includes(data.loanId)) {

            } else {
                this.state.loanIdForAssign.push(data.loanId)
            }
        } else {
            let index = this.state.loanIdForAssign.indexOf(data.loanId)
            if (index >= 0) {
                this.state.loanIdForAssign.splice(index, 1);
            }
        }
        this.setState({ loanIdForAssign: this.state.loanIdForAssign })
    }

    dataFilter(e, chngType) {
        const { admin } = this.props
        this.setState({ loanApprovalLeadData: [] })

        this.setState({ loadarState: true })
        let dataFilterState = Object.assign({}, this.state.dataFilterState)
        if (this.state.typeInput == 'status') {
            this.setState({
                followUpStatus: e.target.value
            }, () => this.getLoanLeads())
        } else if (this.state.typeInput == 'applicationNo') {
            dataFilterState.searchByAppNo = e.target.value
            if (e.target.value.length > 4) {

                this.props.getlistByApplication(e.target.value, 1, '', admin, true,
                    (callBack) => {
                        // console.log(callBack)
                        this.setState({ loanApprovalLeadData: callBack })

                        this.setState({ loadarState: false })
                    })
            }
        } else if (this.state.typeInput == 'email') {
            dataFilterState.searchByemail = e.target.value
            if (e.target.value.length > 2) {
                this.props.getlistByEmail(e.target.value, 1, '', admin,
                    (callBack) => {
                        // this.getTableData(callBack)
                        this.setState({ loanApprovalLeadData: callBack })

                        this.setState({ loadarState: false })
                    })
            }
        } else if (this.state.typeInput == 'mobileNo') {
            dataFilterState.searchBymobile = e.target.value
            if (e.target.value.length > 9) {
                this.props.getistByNumber(e.target.value, 1, '', admin,
                    (callBack) => {
                        // this.getTableData(callBack)
                        this.setState({ loanApprovalLeadData: callBack })

                        this.setState({ loadarState: false })
                    })
            }
        } else if (this.state.typeInput == "date") {
            dataFilterState.searchByDate = _preFormatDate(e.target.value)
            dataFilterState.searchByDateShow = e.target.value
            this.setState({
                dataFilterState
            }, () => this.getLoanLeads())
        } else if (this.state.typeInput == 'assignedTo') {
            this.setState({
                assignedToId: e.target.value
            }, () => this.getLoanLeads())
        }
        if (this.state.typeInput != "date" && e.target.value == "") {
            this.getLoanLeads()
        }
        this.setState({
            dataFilterState
        })
    }

    getFilterValue(filtered) {
        this.setState({ FilteredData: filtered })
    }

    getPageValue(pageIndex) {
        this.setState({ pageIndexToShow: pageIndex })
    }

    getviewDetailPopupState(state) {
        this.setState({
            viewDetailPopupState: state,
        })
    }

    backHandler() {
        const { admin } = this.props
        this.setState({
            userState: true,
            viewState: false,
            loadarState: true
        })
        if (this.state.dataFilterState.searchByAppNo != '') {
            this.props.getlistByApplication(this.state.dataFilterState.searchByAppNo, 1, '', admin, true,
                (callBack) => {
                    this.getTableData(callBack)

                    this.setState({
                        loadarState: false
                    })
                })
        } else if (this.state.dataFilterState.searchByemail != '') {
            this.props.getlistByEmail(this.state.dataFilterState.searchByemail, 1, '', admin,
                (callBack) => {
                    this.getTableData(callBack)
                    this.setState({
                        loadarState: false
                    })
                })
        } else if (this.state.dataFilterState.searchBymobile != '') {
            this.props.getistByNumber(this.state.dataFilterState.searchBymobile, 1, '', admin,
                (callBack) => {
                    this.getTableData(callBack)
                    this.setState({
                        loadarState: false
                    })
                })
        } else {
            this.getLoanLeads()
        }
    }

    paginationHandler(btnType) {
        
        this.setState({
            pageIndexToShow: 0
        })
        if (btnType == 'refresh') {
            this.setState({ loanApprovalLeadData: [],loadarState:true })

            this.getLoanLeads()
        } else if (btnType == 'back') {

            if (this.state.forwordPageState > 1) {
                this.setState({ loanApprovalLeadData: []})

                this.setState({
                    loadarState:true,
                    forwordPageState: this.state.forwordPageState - 1,
                }, () => this.getLoanLeads())
            }
        } else if (btnType == 'forward') {
            this.setState({ loanApprovalLeadData: [],loadarState:true })

            this.setState({
                forwordPageState: this.state.forwordPageState + 1
            }, () => this.getLoanLeads())
        }
    }

    removePopup() {
        setTimeout(function () {
            this.setState({ popupState: false });
        }.bind(this), 5000)
    }

    closePopup() {
        this.setState({ popupState: false });
    }
}

const mapStateToProps = ({ loanApprovalLead }) => {
    return {
        loanApprovalLead: loanApprovalLead
    };
};

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        getistByNumber,
        getlistByEmail,
        getlistByApplication,
        loanLeadsManualAssigned

    }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(CompleteLeads);