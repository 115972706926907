import { APIS } from '../../../../utils/api-factory'
import { POST_HEADER } from '../../../../utils/apiHeaders'
import moment from 'moment'
export const getAllCardUsersApi = (pageNumber, noOfEntry, date, cardStatus, email, mobileNo, callBack) => {
    fetch(APIS.GET_ALL_CARD_USERS + pageNumber + '&noOfEntry=' + noOfEntry + '&date=' + date + '&cardStatus=' + cardStatus + '&email=' + email + '&mobileNo=' + mobileNo)
        .then(res => res.json())
        .then(json => {
            callBack(json)
        })
}
export const getAllStatus = (type, callBack) => {
    fetch(APIS.GET_ALL_CARD_STATUS + type)
        .then(res => res.json())
        .then(json => {

            callBack(json)

        })

}
export const getUserDetailsByUserIdApi = (userId, callBack) => {
    fetch(APIS.GET_CARD_USER_DETAIL_BY_USER_ID + userId)
        .then(res => res.json())
        .then(json => {

            callBack(json)

        })

}
export const getTransactionDetailsByUserIdApi = (userId, callBack) => {
    fetch(APIS.GET_TRANSACTION_DETAIL_BY_USER_ID + userId)
        .then(res => res.json())
        .then(json => {

            callBack(json)

        })
}
export const getBankDetailsByUserIdApi = (userId, callBack) => {
    fetch(APIS.GET_BANK_DETAIL_BY_USER_ID + userId)
        .then(res => res.json())
        .then(json => {

            callBack(json)

        })
}
export const getAddressDetailsByUserIdApi = (userId, callBack) => {
    fetch(APIS.GET_ADDRESS_DETAIL_BY_USER_ID + userId)
        .then(res => res.json())
        .then(json => {

            callBack(json)

        })
}
export const getCardLimitByUserIdApi = (userId, callBack) => {
    fetch(APIS.GET_CARD_LIMIT_BY_USER_ID + userId)
        .then(res => res.json())
        .then(json => {

            callBack(json)

        })
}
export const getCardDetailsByUserIdApi = (userId, callBack) => {
    fetch(APIS.GET_CARD_DETAILS_BY_USER_ID + userId + '&showData=' + false)
        .then(res => res.json())
        .then(json => {

            callBack(json)

        })
}

export const ediBankApi = (
    data,
    bankData,
    callBack
) => {
    var data = {
        id: data.id,
        userId: data.userId,
        accountNo: bankData.accountNo,
        ifscCode: bankData.ifscCode,
        nameOnBank: bankData.nameOnBank,


    }

    fetch(APIS.EDIT_CARD_BANK_DETAILS, {
        method: 'POST',
        headers: POST_HEADER,
        body: JSON.stringify(data)
    })
        .then(res => res.text())
        .then(json => {
            callBack(json)
        })
}

export const editAddressApi = (
    data,
    addressData,
    callBack
) => {
    var data = {
        id: data.id,

        userId: data.userId,
        address1: addressData.address1,
        address2: addressData.address2,
        address3: addressData.address3,
        city: addressData.city,
        state: addressData.state,
        pinCode: addressData.pinCode,
        deliveryAddress1: addressData.deliveryAddress1,
        deliveryAddress2: addressData.deliveryAddress2,
        deliveryAddress3: addressData.deliveryAddress3,
        deliveryCity: addressData.deliveryCity,
        deliveryState: addressData.deliveryState,
        deliveryPinCode: addressData.deliveryPinCode


    }

    fetch(APIS.EDIT_CARD_ADDRESS_DETAILS, {
        method: 'POST',
        headers: POST_HEADER,
        body: JSON.stringify(data)
    })
        .then(res => res.text())
        .then(json => {
            callBack(json)
        })
}
export const editBasicApi = (
    data,
    basicData,
    callBack
) => {
    var data = {
        id: basicData.id,
        userId: data.userId,
        firstName: basicData.firstName,
        lastName: basicData.lastName,
        dateOfBirth: basicData.dateOfBirth,
        gender: basicData.gender,
        aadhaarNo: basicData.aadhaarNo,
        panNo: basicData.panNo,
        email: basicData.email,
        mobileNumber: basicData.mobileNumber,



    }
    fetch(APIS.EDIT_CARD_BASIC_DETAILS, {
        method: 'POST',
        headers: POST_HEADER,
        body: JSON.stringify(data)
    })
        .then(res => res.text())
        .then(json => {
            callBack(json)
        })
}


export const addFalconVirtualCardApi = (data, empId, callBack) => {
    fetch(APIS.ADD_FALCON_VIRTUAL_CARD + data.userId + '&empId=' + empId, {
        method: 'POST',
        headers: POST_HEADER,
        // body: JSON.stringify(data)
    })
        .then(res => res.text())
        .then(json => {
            callBack(json)
        })
}


export const addFundsApi = (
    data,
    amount,
    sourceOfFunds,
    callBack
) => {
    var data = {
        userId: data.userId,
        amount: Number(amount),
        sourceOfFunds: sourceOfFunds
    }

    fetch(APIS.ADD_CARD_FUNDS, {
        method: 'POST',
        headers: POST_HEADER,
        body: JSON.stringify(data)
    })
        .then(res => res.text())
        .then(json => {
            callBack(json)
        })
}



export const addFalconUser = (data, empId, callBack) => {
    fetch(APIS.ADD_FALCON_USER + data.userId + '&empId=' + empId, {
        method: 'POST',
        headers: POST_HEADER,
        // body: JSON.stringify(data)
    })
        .then(res => res.text())
        .then(json => {
            callBack(json)
        })
}

export const getCardFundDetails = (data, callBack) => {
    fetch(APIS.GET_CARD_FUNDS_DETAIL + data, {
        method: 'POST',
        headers: POST_HEADER,
        // body: JSON.stringify(data)
    })
        .then(res => res.json())
        .then(json => {
            callBack(json)
        })
}



export const uploadCardUserByCsv = (
    csvFile,
    callBack
) => {
    return new Promise((resolve, reject) => {
        let imageFormData = new FormData()
        imageFormData.append('csvFile', csvFile)
        var xhr = new XMLHttpRequest()
        xhr.open('post', APIS.UPLOAD_CARD_CSV, true)
        xhr.onload = function () {
            if (this.status == 200) {
                resolve(this.response)
                callBack(this.response)
            } else {
                reject(this.statusText)
            }
        }
        xhr.send(imageFormData)
    })
}

export const completeZeroTouchKyc = (data, otp, empId, callBack) => {
    fetch(APIS.COMPLETE_ZERO_TOUCH_KYC + data.userId + '&otp=' + otp + '&empId=' + empId, {
        method: 'POST',
        headers: POST_HEADER,
        // body: JSON.stringify(data)
    })
        .then(res => res.text())
        .then(json => {
            callBack(json)
        })
}

export const saveFollowUpStatus = (cardId, status, admin, userId, callBack) => {
    fetch(APIS.SAVE_FOLLOW_UP_STATUS_CARD + cardId + '&loanId=' + '' + '&status=' + status + '&empId=' + admin.emailId + '&userId=' + userId, {
        method: 'POST',
        headers: POST_HEADER,
        // body: JSON.stringify(data)
    })
        .then(res => res.text())
        .then(json => {
            callBack(json)
        })

}

export const getCardTransactionsDetails = (userId, startDate, endDate, callBack) => {
    fetch(APIS.GET_CARD_TRANSACTION_DETAILS + userId + '&startDate=' + startDate + '&endDate=' + endDate)
        .then(res => res.json())
        .then(json => {

            callBack(json)


        })
}


export const getActivityDetails = (userId, cardUserId, loanId, callBack) => {
    fetch(APIS.GET_CARD_ACTIVITY_API + userId + '&cardUserId=' + cardUserId + '&loanId=' + loanId)
        .then(res => res.json())
        .then(json => {

            callBack(json)

        })
}
export const enableOrDisableCard = (data, cardId, status, callBack) => {
    fetch(APIS.ENABLE_OR_DISABLE_CARD + data.userId + '&cardId=' + cardId + '&usageStatus=' + status, {
        method: 'PUT',
        headers: POST_HEADER,
        // body: JSON.stringify(data)
    })
        .then(res => res.text())
        .then(json => {
            callBack(json)
        })
}

export const blockCard = (data, cardId, reason, callBack) => {
    fetch(APIS.BLOCK_CARD + data.userId + '&cardId=' + cardId + '&reason=' + reason, {
        method: 'PUT',
        headers: POST_HEADER,
        // body: JSON.stringify(data)
    })
        .then(res => res.text())
        .then(json => {
            callBack(json)
        })
}

export const getCardStatusData = (cardId, callBack) => {
    fetch(APIS.CARD_STATUS_DATA + cardId)
        .then(res => res.json())
        .then(json => {
            callBack(json)
        })
}

export const getCvvData = (cardId, cardUserId, callBack) => {
    fetch(APIS.GET_CVV + cardId + '&cardUserId=' + cardUserId)
        .then(res => res.json())
        .then(json => {
            callBack(json)
        })
}
export const getCardPolicyDetails = (cardId, cardUserId, callBack) => {
    fetch(APIS.CARD_POLICY_DETAILS + cardId + '&cardUserId=' + cardUserId)
        .then(res => res.json())
        .then(json => {
            callBack(json)
        })
        .catch(e => {
            callBack('')
        })
}

export const enableDisableChanel = (cardId, cardUserId, paymentChannel, paymentChannelStatus, callBack) => {
    fetch(APIS.ENABLE_DISABLE_CAHNNEL + cardId + '&cardUserId=' + cardUserId + '&paymentChannel=' + paymentChannel + '&paymentChannelStatus=' + paymentChannelStatus, {
        method: 'PUT',
        headers: POST_HEADER,
        // body: JSON.stringify(data)
    })
        .then(res => res.text())
        .then(json => {
            callBack(json)
        })
}
export const addFalconPhysicalCardApi = (data, cardData, empId, callBack) => {
    fetch(APIS.ADD_PHYSICAL_CARD_API + data.userId + '&proxyNumber=' + cardData.proxyNumber + '&bin=' + cardData.bin + '&vpan=' + cardData.vpan + '&empId=' + empId, {
        method: 'POST',
        headers: POST_HEADER,
        // body: JSON.stringify(data)
    })
        .then(res => res.text())
        .then(json => {
            callBack(json)
        })
}