import React, { useEffect, useState } from 'react'
import { _preFormatDate } from '../../../../utils/validation'
import { downloadApiTrackerFile, getThirdParyHitData, GetLoanCountByDateNew } from '../AdminActionCreator.component'
import Loader from '../../../presentationals/Loader/Loader.component'
import DatePopup from '../../../presentationals/Popup/datePopup'
import moment from 'moment'
import { APIS } from '../../../../utils/api-factory';


const differenceDate = ()=>{
    let d = new Date()
    let todaysDate = d.getDate();
    return todaysDate - 1
}

let data1 = []
const thirtyDaysAgo = moment().subtract(differenceDate(), 'days');
// const thirtyDaysAgo = moment().subtract(30, 'days');

const listing = () => {
    console.log("differenceDate",differenceDate())
    const [startDate, setStartDate] = useState(_preFormatDate(new Date().setDate(new Date().getDate() - differenceDate())))
    // const [startDate, setStartDate] = useState(_preFormatDate(new Date().setDate(new Date().getDate() - 30)))
    const [endDate, setEndDate] = useState(_preFormatDate(new Date()))
    const [thirdPartyData, setThirdPartyData] = useState({})
    const [loaderState, setLoader] = useState(false)
    const [selectedData, setselectedData] = useState({})
    const [, updateState] = React.useState();
    const [sDate, setsDate] = useState(_preFormatDate(new Date()))
    const [eDate, setEDate] = useState(_preFormatDate(new Date()))
    const [popup, setPopup] = useState(false)
    const [type, setType] = useState('')
    const [TotalApplciations, setTotalApplciations] = useState('0')
    const [Totalcost, setTotalcost] = useState('0')
    const [searchByDateShow, setSearchDateToShow] = useState(thirtyDaysAgo.format('YYYY-MM-DD'))
    const [searchByEndDateTOShow, setsearchByEndDateTOShow] = useState(moment(new Date).format('YYYY-MM-DD'))
    const [sDateShow, setSDateToShow] = useState(moment(new Date).format('YYYY-MM-DD'))
    const [eByETOShow, setEDateTOShow] = useState(moment(new Date).format('YYYY-MM-DD'))
    const [Parentapiprovider, setParentapiprovider] = useState([
        // 'Karza- Aadhar Verification',
        'MSG91-OTP',
        // 'Karza- Pan',
        'TrackWizz',
        // 'Karza- Aadhar Download File',
        // 'Karza- Digilocker (Perfios)',
        // 'Finbit',
        'CRIF',
        // 'Karza- Pan Status Check',
        // 'Karza- Bank Account Verification',
        // 'Karza- Face Match',
        'Razorpay- eMandate',
        'Setu',
        'Digitap',
        'Stamp Duty',
        'Signdesk- eSign',
        'Razorpay- Auto Debit'

    ]);

    const forceUpdate = React.useCallback(() => updateState({}), []);
    const sortedData = { data: {} };
    useEffect(() => {
        data1 = []
        thirdParty()
    }, [])

    const updateHeight = () => {
        const leftDivHeight = document.getElementById('LeftBox')?.clientHeight;
        document.getElementById('RightBox').style.height = leftDivHeight + 'px';
    }

    const thirdParty = () => {
        setLoader(true)
        getThirdParyHitData(startDate, endDate, callback => {
            setTotalcost(callback?.totalCost)
            Parentapiprovider.forEach(provider => {
                if (callback?.data.hasOwnProperty(provider)) {
                    sortedData.data[provider] = callback?.data[provider];
                }
            });

            setThirdPartyData(sortedData);
            setTimeout(() => {
                updateHeight();
            }, 400)
            setLoader(false)

        })
        GetLoanCountByDateNew(startDate, endDate, (callback) => {
            setTotalApplciations(callback.totalApplications)
        })
    }
    const dateFilter = (e, type) => {
        if (type === 'dateTo') {
            setStartDate(_preFormatDate(e.target.value))
            setSearchDateToShow(e.target.value)
        }
        if (type === 'dateFrom') {
            setEndDate(_preFormatDate(e.target.value))
            setsearchByEndDateTOShow(e.target.value)

        }
        if (type === 'submit') {
            setLoader(true)
            setselectedData({});
            getThirdParyHitData(startDate, endDate, callback => {
                setTotalcost(callback?.totalCost)
                Parentapiprovider.forEach(provider => {
                    if (callback?.data.hasOwnProperty(provider)) {
                        sortedData.data[provider] = callback?.data[provider];
                    }
                });

                setThirdPartyData(sortedData);
                setTimeout(() => {
                    updateHeight();
                }, 400)
                setLoader(false)
                Object.entries(sortedData).map((da, i) => {
                    Object.entries(data1).map((j, m) => {
                        if (j[1].name == da[0]) {
                            data1[m].selectData = da[1]
                            setselectedData(data1)
                        }
                    })

                })

            })
            GetLoanCountByDateNew(startDate, endDate, (callback) => {
                setTotalApplciations(callback.totalApplications)
            })

        }
    }
    const particularData = (data, e, index) => {
        // console.log(data)
        // console.log(data1);
        // console.log({ name: data[0], selectData: data[1] })
        if (e.target.checked === true) {
            data1.push({ name: data[0], selectData: data[1] })
            console.log(data1);
            setselectedData(data1)
        } else {
            Object.entries(selectedData).map((j, m) => {
                if (data[0] === j[1].name) {
                    data1.splice(m, 1);
                    setselectedData(data1);
                }
            })

        }
    }
    const downlaodFileNew = (data) => {
        downloadApiTrackerFile(sDate, eDate, type, callback => {
            console.log(callback)
            setPopup(false)
        })
    }
    const dateFilterNew = (e, type) => {
        if (type === 'start') {
            setsDate(_preFormatDate(e.target.value))
            setSDateToShow(e.target.value)

        }
        if (type === 'end') {
            setEDate(_preFormatDate(e.target.value))
            setEDateTOShow(e.target.value)

        }


    }
    const downlaodFile = (data) => {
        if (data !== '') {

            if (data === 'Bureau Data') {
                setType('Crif')
            }
            else if (data === 'PDF Parsing') {
                setType('FinBit Pdf Upload')
            }
            else {
                setType(data)
            }
            setPopup(true)
        }
    }

    const orderDataByParent = (data) => {
        // console.log("datata",data);
        const orderedData = {};
        Parentapiprovider?.map((value, i) => {

            Object.keys(data).forEach(key => {
                const parent = data[key].parent;
                // console.log(`${key} parent:`, parent);
                if (parent == value) {
                    orderedData[parent] = data[key];
                }
            });

            console.log(orderedData)
        });
        return orderedData;
    };
    const todaysdate = () => {
        const today = new Date();
        const year = today.getFullYear();
        let month = today.getMonth() + 1;
        let day = today.getDate();
        month = month < 10 ? `0${month}` : month;
        day = day < 10 ? `0${day}` : day;
        const formattedDate = `${year}-${month}-${day}`;
        return formattedDate;
    }
    const startsWithNonDigit = /^[^\d]/;

    return (
        <div >
            {popup ?
                <DatePopup
                    sDateShow={sDateShow}
                    setPopup={setPopup}
                    download={downlaodFileNew}
                    dateFilter={dateFilterNew}
                    eByETOShow={eByETOShow}
                />
                : ""}
            {loaderState ?
                <Loader />
                : ""}
            <div className='container-fluid api-tracker-1900'>
                <div className='row'>
                    <div className='col-sm-2 col-xs-12  ' style={{ marginTop: '15px' }}>
                        <label>From Date</label>

                        <input type='date' className='date-input-admin' style={{ width: '100%' }} value={searchByDateShow} onChange={(e) => dateFilter(e, 'dateTo')} />
                    </div>
                    <div className='col-sm-2 col-xs-12  ' style={{ marginTop: '15px' }}>
                        <label>To Date</label>
                        <input type='date' className='date-input-admin' style={{ width: '100%' }} min={searchByDateShow} value={searchByEndDateTOShow} max={todaysdate()} onChange={(e) => dateFilter(e, 'dateFrom')} />
                    </div>

                    <div className='col-sm-2 col-xs-12 '>
                        <button className='new-cancle-btn' style={{ marginTop: '35px ' }} onClick={(e) => dateFilter(e, 'submit')}>Apply Filter</button>
                    </div>

                    <div className="col-lg-4 col-xs-6" style={{ float: 'inline-end' }}>
                        <div className='count-div'>
                            <div className='row'>
                                <div className='col-sm-6 col-xs-6'>

                                    <div className="btn-count-new text-center"><div style={{ fontSize: '14px', color: '#0e5768', fontWeight: '700' }}>TOTAL APPLICATIONS <br></br> <span className='spandate' style={{ fontSize: '11px' }}> ({startDate + " - " + endDate})</span><br /><br /></div> <span style={{ color: '#2B78FF', fontSize: '20px', fontWeight: 700 }}>{TotalApplciations}</span></div>

                                </div>
                                <div className='col-sm-6 col-xs-6'>

                                    <div className="btn-count-new text-center"><div style={{ fontSize: '14px', color: '#0e5768', fontWeight: '700', marginBottom: '16px' }}>TOTAL COST <br /><br /></div><span style={{ color: '#2B78FF', fontSize: '20px', fontWeight: 700 }}> {'₹ ' + (Totalcost || '0')}</span></div>

                                </div>
                            </div>

                        </div>

                    </div>

                </div>
                <hr />
                <div className='row'>
                    <div className='col-lg-6 col-md-6 col-xs-12'>
                        <div style={{ height: window.innerHeight - 200 }}>
                            {
                                thirdPartyData?.data && Object.keys(thirdPartyData?.data)?.length > 0 ?
                                    <div className='APITracker' id="LeftBox">
                                        <div className="outer-table table-responsive" style={{ width: '100%', margin: '0px' }}>
                                            <table className="table tracker1900table" style={{ margin: '0px', fontSize: '12px' }}>
                                                <thead className='table-heads2'>
                                                    <tr style={{ backgroundColor: '#0e5768' }}>
                                                        <th style={{ borderTopLeftRadius: '10px' }}>S.No.</th>
                                                        <th colSpan={2} style={{ width: '20%',verticalAlign:'middle' }}>API Name</th>
                                                        <th style={{ width: '20%' }}>Child Name</th>
                                                        {/* <th style={{ width: '20%' }}>Count</th> */}

                                                        <th style={{ borderTopRightRadius: '10px' }}>Count</th>
                                                        {/* <th style={{ borderTopRightRadius: '10px' }}>Download</th> */}
                                                    </tr>
                                                </thead>
                                                <tbody className='api-tracker-striped-data'>




                                                    {thirdPartyData?.data ? Object.entries(thirdPartyData?.data)?.map((data, i) => {
                                                        return (

                                                            <tr key={i}>

                                                                <td>{i + 1}</td>
                                                                <td> <input type='checkbox' style={{ cursor: 'pointer' }} onChange={(e) => particularData(data, e, i)} /></td>
                                                                <td style={{ width: '20%' }}>{data[0]}</td>
                                                                <td style={{ width: '20%' }}>
                                                                    {data ? data.map((u, k) => {
                                                                        console.log(u)
                                                                        const matchingKeys = Object.keys(u).filter(key => startsWithNonDigit.test(key) &&key!=='parentSuccessCount');

                                                                        return matchingKeys.length > 0 ? (
                                                                            <div key={k}>
                                                                                {matchingKeys.map((key, index) => (
                                                                                    <div style={{padding:'10px'}} key={index}>{key}</div>
                                                                                ))}
                                                                            </div>
                                                                        ) : null;
                                                                    }) : ""}

                                                                </td>
                                                                <td style={{ width: '20%' , color: 'rgb(76, 199, 140)'}}>
                                                                    {data ? data.map((u, k) => {
                                                                        const matchingKeys = Object.entries(u).filter(key => startsWithNonDigit.test(key) &&key!=='parentSuccessCount');
                                                                        console.log(matchingKeys)
                                                                        return matchingKeys.length > 0 ? (
                                                                            <div key={k}>
                                                                                {matchingKeys.map((key, index) => (
                                                                                    <div  style={{padding:key[1].successCount?'10px':''}}  key={index}>{key[1].successCount}</div>
                                                                                    // console.log(key)
                                                                                ))}
                                                                            </div>
                                                                        ) : null;
                                                                    }) : ""}

                                                                </td>
                                                                {/* <td><h5>{data[0] !== 'CRIF' ? <b >{data[1]?.parentSuccessCount ? <span style={{ color: 'rgb(76, 199, 140)', background: 'rgb(225, 246, 236)', padding: '3px 5px', borderRadius: '5px' }}>{data[1]?.parentSuccessCount}</span> : 'N/A'}</b> : ""}</h5>
                                                                    <h5>{data[0] === 'CRIF' ? <b >{data[1]?.parentSuccessCount ? (Number(data[1]?.parentSuccessCount) - (Number(data[1]?.parentSuccessCount) * 0.1)) > 0 ? <span style={{ color: 'rgb(76, 199, 140)', background: 'rgb(225, 246, 236)', padding: '3px 5px', borderRadius: '5px' }}>{Math.round(Number(data[1]?.parentSuccessCount) - (Number(data[1]?.parentSuccessCount) * 0.1))}</span> : 0 : 'N/A'}</b> : ""}</h5></td> */}
                                                                {/* <td>{data[0] === 'CRIF' || data[0] === 'FinBit Pdf Upload' ? <i style={{ cursor: 'pointer', marginTop: '10px', color: 'rgb(91 88 2)', background: 'rgb(249 212 91)', padding: '3px 5px', borderRadius: '5px' }} onClick={() => downlaodFile(data[0])} className='fa fa-download'></i> : ""}</td> */}

                                                            </tr>
                                                        )
                                                    }) : ""}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    : ''}
                            {/* {thirdPartyData ? Object.entries(thirdPartyData).map((data, i) => {
                                return (
                                    <div className='row'>
                                        <div className='col-sm-1 col-xs-1'>
                                            <input type='checkbox' style={{ cursor: 'pointer' }} onChange={(e) => particularData(data, e, i)} />
                                        </div>
                                        <div className='col-sm-7 col-xs-7'>
                                            {data[0]}
                                        </div>
                                        <div className='col-sm-2 col-xs-3' style={{ marginTop: '-6px' }}>
                                            <h5>{data[0] !== 'Crif' ? <b >{data[1].totalCost ? <span style={{ color: 'rgb(76, 199, 140)', background: 'rgb(225, 246, 236)', padding: '3px 5px', borderRadius: '5px' }}>{data[1].totalCost}</span> : 'N/A'}</b> : ""}</h5>
                                            <h5>{data[0] === 'Crif' ? <b >{data[1].totalCost ? (Number(data[1].totalCost) - (Number(data[1].totalCost) * 0.1)) > 0 ? <span style={{ color: 'rgb(76, 199, 140)', background: 'rgb(225, 246, 236)', padding: '3px 5px', borderRadius: '5px' }}>{Math.round(Number(data[1].totalCost) - (Number(data[1].totalCost) * 0.1))}</span> : 0 : 'N/A'}</b> : ""}</h5>
                                        </div>
                                        <div className='col-sm-2'>
                                            {data[0] === 'Crif' || data[0] === 'FinBit Pdf Upload' ? <i style={{ cursor: 'pointer', marginTop: '10px', color: 'rgb(91 88 2)', background: 'rgb(249 212 91)', padding: '3px 5px', borderRadius: '5px' }} onClick={() => downlaodFile(data[0])} className='fa fa-download'></i> : ""}
                                        </div>
                                        

                                    </div>
                                )
                            }) : ""} */}
                        </div>
                    </div>
                    <div className='col-lg-6 col-md-6 col-xs-12' id="RightBox" style={{ overflowY: 'scroll' }}>
                        {/* <div style={{ height: '520px', overflow: 'scroll' }}> */}
                        {selectedData ? Object.entries(selectedData).map((item, index) => (
                            <div key={index}>
                                {selectedData?.[index]?.selectData ? Object.entries(selectedData?.[index]?.selectData).map((data, i) => {

                                    if (data[0] == 'parentSuccessCount') {
                                        return null
                                    };
                                    return (
                                        <div className='row' key={data[0]} style={{ marginTop: '10px' }}>
                                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>

                                                <h4 style={{ marginLeft: '20px', marginTop: i !== 0 ? '30px' : '' }}><b>{data[0] + ` (${data[1]?.order})`}</b></h4>

                                                <button type="button" className='downloadbtn' onClick={() => downlaodFile(data[0])} style={{ height: '35px', marginTop: i !== 0 ? '20px' : '', marginRight: '10px', borderRadius: '8px', background: '#006fbc', cursor: 'pointer', color: '#fff' }}>
                                                    <i style={{ color: '#fff', padding: '6px 9px' }} className='fa fa-download'></i> Download
                                                </button>

                                            </div>
                                            <div className='col-lg-4 col-sm-5 col-xs-12' style={{ marginTop: '10px' }}>
                                                <div className='admin-card'>
                                                    <div className='row'>
                                                        <div className='col-sm-8 col-xs-12'>
                                                            <h5><b>Cost Per Hit</b></h5>
                                                            <h4><b>{data[1]?.costing ? data[1]?.costing : 'N/A'}</b></h4>
                                                        </div>
                                                        <div className='col-sm-4 col-xs-4'>
                                                            <div className='elipse-blue' style={{ marginTop: '8px', padding: '3px 9px', background: 'cornflowerblue' }}>
                                                                <i class="fa fa-inr" aria-hidden="true"></i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-lg-4 col-sm-5 col-xs-12' style={{ marginTop: '10px' }}>
                                                <div className='admin-card'>
                                                    <div className='row'>
                                                        <div className='col-sm-8 col-xs-12'>
                                                            <h5><b>Success</b></h5>
                                                            {data[0] !== 'Bureau Data' ? <h4><b>{data[1]?.successCount ? data[1]?.successCount : 'N/A'}</b></h4> : ""}
                                                            {data[0] === 'Bureau Data' ? <h4><b>{data[1]?.successCount ? (Number(data[1]?.successCount) - (Number(data[1]?.successCount) * 0.1)) > 0 ? Math.round(Number(data[1]?.successCount) - (Number(data[1]?.successCount) * 0.1)) : 0 : 'N/A'}</b></h4> : ""}
                                                        </div>
                                                        <div className='col-sm-4 col-xs-4'>
                                                            <div className='elipse-blue' style={{ marginTop: '8px', background: '#00bc83', padding: '2px 5px' }}>
                                                                <i class="fa fa-check" aria-hidden="true"></i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-lg-4 col-sm-5 col-xs-12' style={{ marginTop: '10px' }}>
                                                <div className='admin-card'>
                                                    <div className='row'>
                                                        <div className='col-sm-8 col-xs-12'>
                                                            <h5><b>Failure</b></h5>
                                                            <h4><b>{data[1]?.failureCount ? data[1]?.failureCount : 'N/A'}</b></h4>
                                                        </div>
                                                        <div className='col-sm-4 col-xs-4'>
                                                            <div className='elipse-blue' style={{ marginTop: '8px', background: 'red', padding: '1px 6px' }}>
                                                                <i class="fa fa-close" aria-hidden="true"></i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }) : ""}
                            </div>
                        )) : ''}
                    </div>
                    {/* </div> */}
                </div>


            </div>
        </div>
    )
}
export default listing