import { APIS } from "../../../../utils/api-factory"
import { POST_HEADER } from "../../../../utils/apiHeaders"

export const getCategoryList = (callBack) => {
  fetch(APIS.GET_SCORE_CATEGORY_LIST)
    .then(res => res.json())
    .then(json => {
      callBack(json)
    })
    .catch(err => {
      callBack(null)
    })
}
export const saveCategory = (
  name,
  callBack
) => {
  var data = {
    name: name
  }
  fetch(APIS.SAVE_SCORE_CATEGORY_LIST, {
    method: 'POST',
    headers: POST_HEADER,
    body: JSON.stringify(data)
  })
    .then(res => res.text())
    .then(json => {
      callBack(json)
    })
}
export const getCategoryPointers = (callBack) => {
  fetch(APIS.GET_POINTERS_CATEGORY_LIST)
    .then(res => res.json())
    .then(json => {
      callBack(json)
    })
    .catch(err => {
      callBack(null)
    })
}
export const getCategoryByUserId = (userid, callBack) => {
  fetch(APIS.GET_SAVED_CATEGORY_BY_USERID + userid)
    .then(res => res.json())
    .then(json => {
      callBack(json)
    })
    .catch(err => {
      callBack(null)
    })
}

export const getAutomatedScoringByUserId = (userid, loanId, regenerate, callBack) => {
  fetch(APIS.GET_AUTOMATED_SCORING + userid + '&loanId=' + loanId + '&regenerate=' + regenerate)
    .then(res => res.json())
    .then(json => {
      callBack(json)
    })
    .catch(err => {
      callBack(null)
    })
}
export const savePointerMap = (
  categoryId, scoreType, score, mandatory,
  callBack
) => {
  var data = {
    'categoryId': categoryId,
    'scoreType': scoreType,
    'score': score,
    'mandatoryScoring': mandatory

  }
  fetch(APIS.SAVE_POINTERS_MAP_API, {
    method: 'POST',
    headers: POST_HEADER,
    body: JSON.stringify(data)
  })
    .then(res => res.text())
    .then(json => {
      callBack(json)
    })
}
export const saveScoringApi = (
  json,
  callBack
) => {

  fetch(APIS.SAVE_SCORE_OF_AUTOMATED, {
    method: 'POST',
    headers: POST_HEADER,
    body: JSON.stringify(json)
  })
    .then(res => res.json())
    .then(json => {
      callBack(json)
    })
}