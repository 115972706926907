import React from 'react';

const PhocketLoader = ({ }) => (
  <div className="graph-loader">
    <div className="loader loader4 graph-loader-new">
      <div>
        <div>
          <div>
            <div>
              <div>
                <div>
                  <div>
                    <div>
                      <div>
                        <div></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>


)

export default PhocketLoader;