import React from "react";

const CrifAnalysis = ({ setcrifTab, crifData, analysisData, getCrifReport, crifTab, openFinbitPage }) => (
    <>
        <div className="row"  >
            <div style={{ border: '0.5px solid #E9ECF5', width: '80%', marginLeft: 'auto', marginRight: 'auto', marginBottom: '10px' }}></div>
            <div className="col-sm-2">
                <div><div style={{ background: '#DBE9FF', width: '2px', marginLeft: 'auto', marginRight: 'auto', height: '60px', marginTop: '-20px' }}>

                </div>
                </div>
            </div>

            <div className="col-sm-5" onClick={() => setcrifTab('obligation')} style={{ fontSize: '12px', cursor: 'pointer', color: crifTab === 'obligation' ? '#2B78FF' : '#000', fontWeight: crifTab === 'obligation' ? '700' : '400' }}>
                Obligation Amount</div>
            <div className="col-sm-5 text-right" onClick={() => setcrifTab('obligation')} style={{ fontSize: '12px', cursor: 'pointer' }} >

                {crifData && crifData.obligationData && crifData.obligationData.emiSum ?
                    <div style={{ color: '#2B78FF', fontWeight: '700' }}> INR {crifData ? Math.round(crifData.obligationData.emiSum) : 0} &nbsp;&nbsp;&nbsp;&nbsp;  <i className="fa fa-chevron-right" style={{ color: '#2B78FF', marginRight: '10px' }}></i></div> : <div className="col-sm-12 col-xs-12 text-blue text-right">N/A &nbsp;&nbsp; &nbsp;&nbsp; <i className="fa fa-chevron-right" style={{ color: '#2B78FF', marginRight: '10px' }}></i></div>}
            </div>
        </div>

        <div className="row" >
            <div className="col-sm-2">
                <div><div style={{ background: '#DBE9FF', width: '2px', marginLeft: 'auto', marginRight: 'auto', height: '60px', marginTop: '-20px' }}></div>
                </div>
            </div>

            <div className="col-sm-5" style={{ fontSize: '12px', cursor: 'pointer', color: crifTab === 'greenflag' ? '#2B78FF' : '#000', fontWeight: crifTab === 'greenflag' ? '700' : '400' }} onClick={() => setcrifTab('greenflag')}>

                Green Flag</div>

            <div className="col-sm-5 text-right" style={{ fontSize: '12px', cursor: 'pointer' }} onClick={() => setcrifTab('greenflag')}>
                {analysisData != null &&
                    analysisData != undefined &&
                    analysisData != '' &&
                    analysisData.approve != null &&
                    analysisData.approve != '' &&
                    analysisData.approve != undefined && analysisData.finalDecision != 'Rejected'
                    ? <span style={{ color: '#4CC78C', background: '#E1F6EC', borderRadius: '4px', padding: '2px 10px', marginRight: '5px', fontSize: '10px' }}><i className="fa fa-check-circle"></i>&nbsp;Found</span> :
                    <span style={{ color: '#FF5C5D', background: '#FFEFF2', borderRadius: '4px', padding: '2px', fontSize: '10px', marginRight: '5px' }}><i className="fa fa-times-circle"></i>&nbsp;Not Found</span>}
                <i className="fa fa-chevron-right" style={{ color: '#2B78FF', marginRight: '10px' }}></i></div>
        </div>
        <div className="row" >
            <div className="col-sm-2">
                <div><div style={{ background: '#DBE9FF', width: '2px', marginLeft: 'auto', marginRight: 'auto', height: '60px', marginTop: '-20px' }}></div>
                </div>
            </div>

            <div className="col-sm-5" style={{ fontSize: '12px', cursor: 'pointer', color: crifTab === 'exceptional' ? '#2B78FF' : '#000', fontWeight: crifTab === 'exceptional' ? '700' : '400' }} onClick={() => setcrifTab('exceptional')}>

                Yellow Flag</div>
            <div className="col-sm-5 text-right" style={{ fontSize: '12px', cursor: 'pointer' }} onClick={() => setcrifTab('exceptional')}>
                {analysisData != null &&
                    analysisData != undefined &&
                    analysisData != '' &&
                    analysisData.exceptionCase != null &&
                    analysisData.exceptionCase != '' &&
                    analysisData.exceptionCase != undefined
                    ? <span style={{ color: '#4CC78C', background: '#E1F6EC', borderRadius: '4px', padding: '2px 10px', marginRight: '5px', fontSize: '10px' }}><i className="fa fa-check-circle"></i>&nbsp;Found</span> :
                    <span style={{ color: '#FF5C5D', background: '#FFEFF2', borderRadius: '4px', padding: '2px', fontSize: '10px', marginRight: '5px' }}><i className="fa fa-times-circle"></i>&nbsp;Not Found</span>}
                <i className="fa fa-chevron-right" style={{ color: '#2B78FF', marginRight: '10px' }}></i></div>
        </div>
        <div className="row" >
            <div className="col-sm-2">
                <div><div style={{ background: '#DBE9FF', width: '2px', marginLeft: 'auto', marginRight: 'auto', height: '60px', marginTop: '-20px' }}></div>
                </div>
            </div>
            <div className="col-sm-5" style={{ fontSize: '12px', cursor: 'pointer', color: crifTab === 'clearrejection' ? '#2B78FF' : '#000', fontWeight: crifTab === 'clearrejection' ? '700' : '400' }} onClick={() => setcrifTab('clearrejection')}>
                Red Flag</div>
            <div className="col-sm-5 text-right" style={{ fontSize: '12px', cursor: 'pointer' }} onClick={() => setcrifTab('clearrejection')}>
                {analysisData != null &&
                    analysisData != undefined &&
                    analysisData != '' &&
                    analysisData.rejected != null &&
                    analysisData.rejected != '' &&
                    analysisData.rejected != undefined && analysisData.finalDecision == 'Rejected'
                    ? <span style={{ color: '#4CC78C', background: '#E1F6EC', borderRadius: '4px', padding: '2px 10px', marginRight: '5px', fontSize: '10px' }}><i className="fa fa-check-circle"></i>&nbsp;Found</span> :
                    <span style={{ color: '#FF5C5D', background: '#FFEFF2', borderRadius: '4px', padding: '2px', fontSize: '10px', marginRight: '5px' }}><i className="fa fa-times-circle"></i>&nbsp;Not Found</span>}
                <i className="fa fa-chevron-right" style={{ color: '#2B78FF', marginRight: '10px' }}></i></div>
        </div>
        <div className="row" >
            <div className="col-sm-2">
                <div><div style={{ background: '#DBE9FF', width: '2px', marginLeft: 'auto', marginRight: 'auto', height: '40px', marginTop: '-20px' }}></div>
                </div>
            </div>
            <div className="col-sm-5" style={{ fontSize: '12px', cursor: 'pointer', color: crifTab === 'crifreport' ? '#2B78FF' : '#000', fontWeight: crifTab === 'crifreport' ? '700' : '400' }} onClick={() => getCrifReport('')}>

                Detailed View</div>

            <div className="col-sm-5 text-right" style={{ fontSize: '12px', cursor: 'pointer' }} onClick={() => getCrifReport('')}>

                <i className="fa fa-chevron-right" style={{ color: '#2B78FF', marginRight: '10px' }}></i></div>
        </div>
    </>

)
export default CrifAnalysis