import React, { useEffect, useState } from "react";
import Popup from '../../../../../presentationals/Popup/Popup.component';
import { saveBusinessTargetCategoryData, updateBusinessTargetCategoryData } from "../../targetactioncreator";

const EditCategory = ({ editData }) => {
    const [categoryData, setCategoryData] = useState({
        name: "",
        categoryType: "",
        categoryDescription: ""
    });
    // const categoryOptions = [{
    //     "categoryId": "SWXaW31",
    //     "subCategoryType": "Sample1",
    //     "subCategoryDescription": "Sample1",
    //     "id": "2"

    // }]
    const [categoryOptions, setcategoryOptions] = useState('')

    const [sidePopup, setPopup] = useState(false);
    const [popupStatus, setPopupStatus] = useState('');
    const [errors, setErrors] = useState({});

    const closePopup = () => {
        setPopup(false);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setCategoryData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const validateForm = () => {
        let formErrors = {};
        let isValid = true;

        if (!categoryData.name) {
            formErrors.name = "Name is required";
            isValid = false;
        }
        if (!categoryData.categoryType) {
            formErrors.categoryType = "Category Type is required";
            isValid = false;
        }
        if (!categoryData.categoryDescription) {
            formErrors.categoryDescription = "Category Description is required";
            isValid = false;
        }

        setErrors(formErrors);
        return isValid;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validateForm()) {
            console.log("Category Data Saved", categoryData);
            if (editData.id) {
                categoryData.id = editData.id
                updateBusinessTargetCategoryData(categoryData, callback => {
                    if (callback.message === 'success') {

                        setPopup(true);
                        setPopupStatus('Data Saved Successfully!');
                } else {
                    setPopup(true);
                    setPopupStatus('Please try again later!');
                }
                removePopup();
            });
        }else{
            saveBusinessTargetCategoryData(categoryData, callback => {
                if (callback.message === 'success') {
                    setPopup(true);
                    setPopupStatus('Data Saved Successfully!');
            } else {
                setPopup(true);
                setPopupStatus('Please try again later!');
            }
            removePopup();
        });

        }
        }
    };

    const removePopup = () => {
        setTimeout(() => {
            closePopup();
        }, 5000);
    };

    useEffect(() => {
        if (editData.id) {
            setCategoryData({
                name: editData.name || "",
                categoryType: editData.categoryType || "",
                categoryDescription: editData.categoryDescription || "",
            });
        }

    }, [editData]);
    useEffect(() => {
        // getAllExpenseCategory(Callback => {
        //     setcategoryOptions(Callback.data)
        // })
    }, [])

    return (
        <div className="container">
            {sidePopup && <Popup popupStatus={popupStatus} closePopup={closePopup} />}
            <div className="target-box">
                <h3>Add/Update Business Target Category Information</h3>
                <hr style={{ borderTop: '1px solid #000' }} />
                <form onSubmit={handleSubmit}>
                    <div className="row">
                        <div className="col-sm-6 col-xs-12">
                            <div style={{ display: 'flex', marginTop: '20px' }}>
                                <div style={{ width: '50%' }}>
                                    <label style={{ marginTop: '10px' }}>Name</label>
                                </div>
                                <div style={{ width: '50%' }}>
                                    <input
                                        style={{ background: '#fff', border: '1px solid' }}
                                        className="form-control"
                                        type="text"
                                        name="name"
                                        value={categoryData.name}
                                        onChange={handleChange}
                                    />
                                    {errors.name && (
                                        <span style={{ color: 'red', fontSize: '10px', marginTop: '5px' }}>{errors.name}</span>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 col-xs-12">
                            <div style={{ display: 'flex', marginTop: '20px' }}>
                                <div style={{ width: '50%' }}>
                                    <label style={{ marginTop: '10px' }}>Category Type</label>
                                </div>
                                <div style={{ width: '50%' }}>
                                    <input
                                        style={{ background: '#fff', border: '1px solid' }}
                                        className="form-control"
                                        type="text"
                                        name="categoryType"
                                        value={categoryData.categoryType}
                                        onChange={handleChange}
                                    />
                                    {errors.categoryType && (
                                        <span style={{ color: 'red', fontSize: '10px', marginTop: '5px' }}>{errors.categoryType}</span>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row" style={{ marginTop: '20px' }}>
                        <div className="col-sm-12 col-xs-12">
                            <div style={{ width: '' }}>
                                <label style={{ marginTop: '10px' }}>Category Description</label>
                            </div>
                            <div style={{ width: '' }}>
                                <textarea
                                    style={{ background: '#fff', border: '1px solid' }}
                                    className="form-control"
                                    type="text"
                                    name="categoryDescription"
                                    value={categoryData.categoryDescription}
                                    onChange={handleChange}
                                />
                                {errors.categoryDescription && (
                                    <span style={{ color: 'red', fontSize: '10px', marginTop: '5px' }}>{errors.categoryDescription}</span>
                                )}
                            </div>
                        </div>

                    </div>

                    <div className="text-center" style={{ marginTop: '40px' }}>
                        <button type="submit" className="btn btn-primary">Save Business Target Category Data</button>
                    </div>
                </form>
            </div>
        </div>

    );
};

export default EditCategory;
