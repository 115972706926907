import React from "react";
import DatePicker from 'react-datepicker';
const BranchUi = ({ datehandler, selectHandler, submit, startDate, endDate, type, Start, End,submitConsolidated }) => (
    <div className='container'>
        <h3 className="text-center blue-text">Generate Branch Report </h3>
        <div className='row'>
            <div className='col-sm-4 col-xs-12'>
                <label className="font-12px">Date From</label>
                <DatePicker
                    value={startDate}
                    selected={Start}
                    onChange={(e) => datehandler(e, 'startDate')}
                    className="form-control"
                    dateFormat="DD/MM/YYYY"
                />
            </div>
            <div className='col-sm-4 col-xs-12'>
                <label className="font-12px">Date To</label>
                <DatePicker
                    value={endDate}
                    selected={End}
                    onChange={(e) => datehandler(e, 'endDate')}
                    className="form-control"
                    dateFormat="DD/MM/YYYY"
                />
            </div>
            <div className='col-sm-4 col-xs-12'>
                <div className='col-sm-4 col-xs-12'>
                    <label className="font-12px">Select Type</label>

                    <select className='form-control' value={type} onChange={(e) => selectHandler(e)}>
                        <option value=''>Select Type</option>
                        {/* <option value='G'>Gift Card</option> */}
                        <option value='L'>Loan</option>
                    </select>
                </div>
            </div>
        </div>
        <div className='text-center' style={{marginTop:'20px'}}>

            <button className='btn btn-primary' onClick={() => submit()}>Download</button>&nbsp;&nbsp;
            <button className='btn btn-primary' onClick={() => submitConsolidated()}>Download Consolidated Report</button>

        </div>
    </div>
)
export default BranchUi