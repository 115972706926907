import React, { useState } from 'react'
import LoanApproval from './LoanApproval';
import LoanInsights from './LoanInsights';
import dashboardimg from '../../DashboardAndReports/PerformanceTracker/images/dashboardimg.png'
import selecteddashboardimg from '../../DashboardAndReports/PerformanceTracker/images/selecteddashboardimg.png'
import BackLogo from '../../../../../images/rewampimages/Chevron.png'

export default function Main({pageViewHandler}) {
    const [tab, setTab] = useState('loanInsights');

    const handleTabs = (type) => {
        setTab(type)
    }
    return (
        <>
            <div className="LoanDashboardInsightsApproval">
                {/* <div className="tabs">
                    <div className={`subtabss ${tab === 'loanInsights' ? 'active' : ''}`} onClick={() => handleTabs('loanInsights')}>
                        <h5>Loan Insights</h5>
                    </div>
                    <div className={`subtabss ${tab === 'loanApproval' ? 'active' : ''}`} onClick={() => handleTabs('loanApproval')}>
                        <h5>Loan Approval</h5>
                    </div>
                </div> */}
                <div className="container-fluid details-new fixed-top"  >
                    <div className="row details-new-tab-div" style={{ display: 'flex', justifyContent: 'end' }}>
                        <div className="col-sm-6 col-xs-12 " style={{ marginTop: '15px' }}>
                            <img onClick={(e) => pageViewHandler(e, 'landingPage')} style={{ cursor: 'pointer' }} src={BackLogo} width={'30px'} /> <button style={{ color: '#1D3557', fontWeight: '700' }} onClick={(e) => pageViewHandler(e, 'landingPage')} >Back</button>
                        </div>

                        <div className=" col-lg-6 col-xs-12" style={{ fontSize: '10px', display: 'flex', justifyContent: 'end' }}>


                            <div className="btn-group-new">

                                <button className={(tab === 'loanInsights') ? "active" : ""} onClick={(e) => handleTabs('loanInsights')}><img src={tab === 'loanInsights' ? selecteddashboardimg : dashboardimg} style={{ width: '27px' }} /><br />Loan Insights</button>


                                <button className={(tab === 'loanApproval') ? "active" : ""} onClick={(e) => handleTabs('loanApproval')}><img src={tab === 'loanApproval' ? selecteddashboardimg : dashboardimg} style={{ width: '27px' }} /><br />Loan Approval</button>
                            </div>

                        </div>
                    </div>
                </div>
                <div className="section" style={{ padding: '100px 20px 50px 20px' }}>

                    {
                        tab === 'loanInsights' ?
                            <LoanInsights />
                            : ''}
                    {
                        tab === 'loanApproval' ?
                            <LoanApproval />
                            : ''}
                </div>
            </div>
        </>
    )
}
