import React, { Component } from 'react'
import AmbRegUi from '../../../presentationals/Admin/Ambassador/NewAmbassador.component'
import Popup from '../../../presentationals/Popup/Popup.component';
import { saveAndCheckForExistingUserApi, userToAmbassadorApi } from '../AdminActionCreator.component'
import ResponsePopup from '../../../presentationals/Admin/Ambassador/NewAmbassadorResponsePopup'
import AmbassadorForm from './EditAmbassadorDetail.container'
import { APIS } from '../../../../utils/api-factory'

class AmbReg extends Component {
    constructor(props) {
        super(props);
        this.state = {
            newAmbData: '',
            popupState: false,
            popupStatus: '',
            ambassadorId: '',
            responsePopupState: false,
            ambassadorFormState: false,
            eyeType: false,
            ambassadorregistrationState: true,
            registerBtnState: true,
            ambRegDetail: {
                fullName: '',
                emailId: '',
                mobileNumber: '',
                ambPasswordState: '',
            }
        }
    }

    componentWillMount() {

    }

    render() {
        return (
            <div>
                {this.state.popupState == true ?
                    <Popup
                        closePopup={this.closePopup.bind(this)}
                        popupStatus={this.state.popupStatus}
                    />
                    : ""}
                {this.state.ambassadorregistrationState == true ?
                    <AmbRegUi
                        ambRegDetail={this.state.ambRegDetail}
                        ambRegDetailChange={this.ambRegDetailChange.bind(this)}
                        ambRegDetailHandler={this.ambRegDetailHandler.bind(this)}
                        amShowPass={this.amShowPass.bind(this)}
                        eyeType={this.state.eyeType}
                    />
                    : null}
                {this.state.responsePopupState == true ?
                    <ResponsePopup
                        newAmbData={this.state.newAmbData}
                        popupType={this.state.popupType}
                        responseBtnHandler={this.responseBtnHandler.bind(this)} />
                    : null}
                {this.state.ambassadorFormState == true ?
                    <AmbassadorForm
                        ambassadorId={this.state.ambassadorId}
                        ambassadorAllData={this.state.ambRegDetail}
                        editBackHandler={this.editBackHandler.bind(this)}
                        registerBtnState={this.state.registerBtnState} />
                    : null}
            </div>
        )
    }

    amShowPass(eyeType) {
        this.setState({eyeType: eyeType})
        if (eyeType == true) {
            document.getElementById("ambPassword").type = 'text';
        } else {
            document.getElementById("ambPassword").type = 'password';
        }
    }

    removePopup() {
        setTimeout(function () {
            this.setState({ popupState: false });
        }.bind(this), 5000)
    }

    closePopup() {
        this.setState({ popupState: false });
    }

    ambRegDetailChange(e, type) {
        let ambRegDetail = Object.assign({}, this.state.ambRegDetail);
        if (type == 'name') {
            ambRegDetail.fullName = e.target.value
        } else if (type == 'email') {
            ambRegDetail.emailId = e.target.value
        } else if (type == 'mobile') {
            ambRegDetail.mobileNumber = e.target.value
        } else if (type == 'password') {
            ambRegDetail.ambPasswordState = e.target.value
        }
        this.setState({ ambRegDetail })
    }

    ambRegDetailHandler() {
        saveAndCheckForExistingUserApi(this.state.ambRegDetail,
            (callBack) => {
                if (callBack.status == 'existAmbassador') {
                    this.setState({
                        popupState: true,
                        popupStatus: 'Ambassador already exist',
                    })
                    this.removePopup();
                } else if (callBack.status == 'existingUser') {
                    this.setState({
                        newAmbData: callBack.userLoginDetail,
                        responsePopupState: true,
                        popupType: callBack.status
                    })
                } else if (callBack.status == 'newAmbassador') {
                    this.setState({
                        responsePopupState: true,
                        popupType: callBack.status
                    })
                }
            })
    }

    responseBtnHandler(resType) {
        if (resType == 'move') {
            userToAmbassadorApi(this.state.newAmbData,
                callBack => {
                    if (callBack == 'success') {
                        this.setState({
                            popupState: true,
                            popupStatus: 'User moved to Ambassador successfully',
                            responsePopupState: false
                        })
                        this.removePopup();
                    } else {
                        this.setState({
                            popupState: true,
                            popupStatus: 'Please try again',
                            responsePopupState: false
                        })
                        this.removePopup();
                    }
                })
        } else if (resType == 'new') {
            fetch(APIS.GET_AMBASSADOR_ID + this.state.ambRegDetail.emailId)
                .then(res => res.text())
                .then(text => {
                    this.setState({
                        ambassadorId: text,
                        responsePopupState: false,
                        ambassadorFormState: true,
                        ambassadorregistrationState: false
                    })
                })
        }
    }

    editBackHandler(){
        this.setState({
            responsePopupState: false,
            ambassadorFormState: false,
            ambassadorregistrationState: true,
        })
    }

}

export default AmbReg;