import React from 'react';
import DatePicker from 'react-datepicker';
import { EMPLOYER_TYPE, STRING, FORM_STATUS, TYPE, ROLE } from '../../../../utils/Constant'
import BankPasswordPopup from '../../Popup/BankPasswordPopup.component';
import SalaryPasswordPopup from '../../Popup/SalaryPasswordPopup.component'
import userBankDetails from './userBankDetails.component';

const CustomerProfile = ({ referenceValidationMsg,referenceUpdate,referencechange,referenceDataState,vreedhiData, userDetails, allDetail, vreedhiDataState, admin, onSubmitHandler, onChangeHandler, userDetail, BasicDOB, onSubmitHandlerVreedhi, onChangeHandlerBuisness,
    BankPasswordState, SalaryPasswordState, docPassword, docPasswordHandler,
    basicValidationMsg, residenceValidationMsg, occupationValidationMsg, docType,
    bankValidationMsg, loanValidationMsg, passwordErrorMsg, autoSuggestionState, uploadFile,
    suggestedCompanyName, setCompnyValue, autoSuggestionClose, userOtherInfo, progressStateWidth, progressState, AddressCheckHendler }) => (
    <div className="crm-edit-profile">
        <div className="container-fluid customer-dashbord-view-box">
            {/* {admin != null && admin.emailId != null ? admin.emailId == 'SuperUser' ? */}
            <div>
                {/* {console.log(userDetails,'userDetails')} */}
                <div className="row">
                    <h3>Mobile &amp; Email</h3>
                    <div className="col-sm-4 col-xs-12">
                        <h4>Mobile Number</h4>
                        <input readOnly={admin != null && admin.role != null && admin.role == 'superuser' ? false : true} type="number" className="form-control" value={userDetail.MobileNumberState} onChange={(e) => onChangeHandler(e, TYPE.MOBILE_NO)} />
                    </div>
                    <div className="col-sm-4 col-xs-12">
                        <h4>EmailId</h4>
                        <input readOnly={admin != null && admin.role != null && admin.role == 'superuser' ? false : true} type="email" className="form-control" value={userDetail.EmailIDState} onChange={(e) => onChangeHandler(e, TYPE.EMAIL_ID)} />
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-4 col-xs-12">
                        <button className="profile-save-btn center-block" onClick={(e) => onSubmitHandler(e, 'mobileNEmail')} disabled={admin != null && admin.role != null && admin.role == 'superuser' ? false : true}>
                            <span className="fa fa-floppy-o"></span>&nbsp;&nbsp;SAVE
                        </button>
                    </div>
                </div>
                {/* <div className="row">
                        <h3>Add Ambassador Id</h3>
                        <div className="col-sm-4 col-xs-12">
                            <h4>Ambassador Id</h4>
                            <input type="text" className="form-control" value={userDetail.ambassadorIdState} onChange={(e) => onChangeHandler(e, TYPE.AMBASSADOR_ID)} />
                        </div>
                    </div> */}
                {/* <div className="row">
                        <div className="col-sm-4 col-xs-12">
                            <button className="profile-save-btn center-block" onClick={(e) => onSubmitHandler(e, 'ambassadorId')}>
                                <span className="fa fa-floppy-o"></span>&nbsp;&nbsp;SAVE
                            </button>
                        </div>
                    </div> */}
            </div>
            {/* : "" : ""} */}
            {userOtherInfo != false ?
                <div className="row">
                    <div className="col-sm-4 col-xs-12">
                        <h4>Loan Id</h4>
                        <div className="display-flex">
                            <input className="form-control" type="text" onChange={(e) => onChangeHandler(e, TYPE.APP_LOAN_ID)} value={userDetail.appLoanId} />
                            <button className="profile-save-btn margin-0" onClick={(e) => onSubmitHandler(e, 'LoanId')}><span className="fa fa-save" /></button>
                        </div>
                    </div>
                    <div className="col-sm-4 col-xs-12">
                        <h4>Cheque Limit</h4>
                        <div className="display-flex">
                            <input className="form-control" type="text" onChange={(e) => onChangeHandler(e, TYPE.CHEQUE_LIMIT)} value={userDetail.chequeLimitState} />
                            <button className="profile-save-btn margin-0" onClick={(e) => onSubmitHandler(e, 'chequeLimit')}><span className="fa fa-save" /></button>
                        </div>
                    </div>
                </div>
                : null}
            <div className="row">
                <h3>Personal Details</h3>
            </div>
            <div className="row">
                <div className="col-sm-4 col-xs-12">
                    <h4>First Name</h4>
                    <input type="text" className="form-control" value={userDetail.firstNameState} onChange={(e) => onChangeHandler(e, TYPE.FIRST_NAME)} />
                    <div className="font-12px margin-left-25px height-25px validation-color">
                        {basicValidationMsg.field == "firstName" ? basicValidationMsg.msg : ""}
                    </div>
                </div>
                <div className="col-sm-4 col-xs-12">
                    <h4>Middle Name</h4>
                    <input type="text" className="form-control" value={userDetail.MiddleNameState} onChange={(e) => onChangeHandler(e, TYPE.MIDDLE_NAME)} />
                    <div className="font-12px margin-left-25px height-25px validation-color">
                        {basicValidationMsg.field == "middleName" ? basicValidationMsg.msg : ""}
                    </div>
                </div>
                <div className="col-sm-4 col-xs-12">
                    <h4>Last Name</h4>
                    <input type="text" className="form-control" value={userDetail.lastNameState} onChange={(e) => onChangeHandler(e, TYPE.LAST_NAME)} />
                    <div className="font-12px margin-left-25px height-25px validation-color">
                        {basicValidationMsg.field == "lastName" ? basicValidationMsg.msg : ""}
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-sm-4 col-xs-12">
                    <h4>D.O.B</h4>
                    <DatePicker
                        value={userDetail.BasicDOB}
                        selected={BasicDOB}
                        onChange={(e) => onChangeHandler(e, TYPE.DOB)}
                        className="form-control"
                        showYearDropdown
                        dateFormatCalendar="MMMM"
                        dateFormat="DD/MM/YYYY"
                        scrollableYearDropdown
                        yearDropdownItemNumber={50}
                    />
                    <div className="font-12px margin-left-25px height-25px validation-color">
                        {basicValidationMsg.field == "BasicDOB" ? basicValidationMsg.msg : ""}
                    </div>
                </div>
                <div className="col-sm-4 col-xs-12">
                    <h4>Gender</h4>
                    <select className="form-control" value={userDetail.GenderState} onChange={(e) => onChangeHandler(e, TYPE.GENDER)}>
                        <option value=""></option>
                        <option value={STRING.MALE}>{STRING.MALE}</option>
                        <option value={STRING.FEMALE}>{STRING.FEMALE}</option>
                        <option value={STRING.OTHERS}>{STRING.OTHERS}</option>
                    </select>
                    <div className="font-12px margin-left-25px height-25px validation-color">
                        {basicValidationMsg.field == "Gender" ? basicValidationMsg.msg : ""}
                    </div>
                </div>
                <div className="col-sm-4 col-xs-12">
                    <h4>Email Id</h4>
                    <input type="email" readOnly className="form-control" value={userDetail.EmailIDState} />
                    <div className="font-12px margin-left-25px height-25px validation-color">
                        {basicValidationMsg.field == "emailId" ? basicValidationMsg.msg : ""}
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-sm-4 col-xs-12">
                    <h4>Alternate Mobile Number</h4>
                    <input type="number" className="form-control" value={userDetail.alternateMobileState} onChange={(e) => onChangeHandler(e, TYPE.ALTERNATE_MOBILE)} />
                    <div className="font-12px margin-left-25px height-25px validation-color">
                        {basicValidationMsg.field == "alternateMobile" ? basicValidationMsg.msg : ""}
                    </div>
                </div>
            </div>
            <div className="row text-center">
                <div className="col-xs-12">
                    <button className="profile-save-btn" id="" onClick={(e) => onSubmitHandler(e, FORM_STATUS.BASIC)}>
                        <span className="fa fa-floppy-o"></span>&nbsp;&nbsp;Save
                    </button>
                </div>
            </div>
            {admin != null && admin.rolelist.indexOf(ROLE.EDIT_REFERENCE) >= 0 ?
            <React.Fragment>
            <div className="row">
                <h3>Reference Details</h3>
            </div>
            <div className="row">
                <h4>Reference 1</h4>
                <div className="col-sm-4 col-xs-12">
                    <label style={{ color: '#000' }}>Name</label>
                    <input onChange={(e)=>referencechange(e,'name1')} type="text" className="form-control" placeholder='Name' value={referenceDataState.name1} />
                    {/* <div className="font-12px margin-left-25px height-25px validation-color">
                        {residenceValidationMsg.field == "name1" ? residenceValidationMsg.msg : ""}
                    </div> */}
                </div>
                <div className="col-sm-4 col-xs-12">
                    <label style={{ color: '#000' }}>Number</label>

                    <input onChange={(e)=>referencechange(e,'number1')}  className="form-control" placeholder='Number' type='number' value={referenceDataState.number1} />
                    {/* <div className="font-12px margin-left-25px height-25px validation-color">
                        {residenceValidationMsg.field == "number1" ? residenceValidationMsg.msg : ""}
                    </div> */}
                </div>
                <div className="col-sm-4 col-xs-12">
                    <label style={{ color: '#000' }}>Relationship</label>
                    <select onChange={(e)=>referencechange(e,'relationShip1')} className='form-control' value={referenceDataState.relationShip1}>
                        <option value=''>Select Value</option>
                        <option value='Father'>Father</option>
                        <option value='Friend/Colleague'>Friend/Colleague</option>
                        <option value='Other Relative'>Other Relative</option>
                    </select>
                    {/* <div className="font-12px margin-left-25px height-25px validation-color">
                        {residenceValidationMsg.field == "relationShip1" ? residenceValidationMsg.msg : ""}
                    </div> */}
                </div>
            </div>
            <div className="row">
                <h4>Reference 2</h4>
                <div className="col-sm-4 col-xs-12">
                    <label style={{ color: '#000' }}>Name</label>
                    <input onChange={(e)=>referencechange(e,'name2')} type="text" className="form-control" placeholder='Name' value={referenceDataState.name2} />
                    {/* <div className="font-12px margin-left-25px height-25px validation-color">
                        {residenceValidationMsg.field == "name2" ? residenceValidationMsg.msg : ""}
                    </div> */}
                </div>
                <div className="col-sm-4 col-xs-12">
                    <label style={{ color: '#000' }}>Number</label>
                    <input onChange={(e)=>referencechange(e,'number2')}  className="form-control" placeholder='Number' type='number' value={referenceDataState.number2} />
                    {/* <div className="font-12px margin-left-25px height-25px validation-color">
                        {residenceValidationMsg.field == "number2" ? residenceValidationMsg.msg : ""}
                    </div> */}
                </div>
                <div className="col-sm-4 col-xs-12">
                    <label style={{ color: '#000' }}>Relationship</label>
                    <select onChange={(e)=>referencechange(e,'relationShip2')}  className='form-control' value={referenceDataState.relationShip2}>
                        <option value=''>Select Value</option>
                        <option value='Mother'>Mother</option>
                        <option value='Friend/Colleague'>Friend/Colleague</option>
                        <option value='Other Relative'>Other Relative</option>
                    </select>
                    {/* <div className="font-12px margin-left-25px height-25px validation-color">
                        {residenceValidationMsg.field == "relationShip2" ? residenceValidationMsg.msg : ""}
                    </div> */}
                </div>
            </div>
            <div className="row">
                <h4>Reference 3</h4>
                <div className="col-sm-4 col-xs-12">
                    <label style={{ color: '#000' }}>Name</label>

                    <input onChange={(e)=>referencechange(e,'name3')} type="text" className="form-control" placeholder='Name' value={referenceDataState.name3} />
                    {/* <div className="font-12px margin-left-25px height-25px validation-color">
                        {referenceValidationMsg.field == "name3" ? referenceValidationMsg.msg : ""}
                    </div> */}
                </div>
                <div className="col-sm-4 col-xs-12">
                    <label style={{ color: '#000' }}>Number</label>

                    <input onChange={(e)=>referencechange(e,'number3')}  className="form-control" placeholder='Number' type='number' value={referenceDataState.number3} />
                    {/* <div className="font-12px margin-left-25px height-25px validation-color">
                        {referenceValidationMsg.field == "number3" ? referenceValidationMsg.msg : ""}
                    </div> */}
                </div>
                <div className='col-sm-4 col-xs-12'>
                    <label style={{ color: '#000' }}>Relationship</label>
                    <select onChange={(e)=>referencechange(e,'relationShip3')}  className='form-control' value={referenceDataState.relationShip3}>
                        <option value=''>Select Value</option>
                        <option value='Sibling'>Sibling</option>
                        <option value='Spouse'>Spouse</option>
                        <option value='Friend/Colleague'>Friend/Colleague</option>
                        <option value='Other Relative'>Other Relative</option>
                    </select>
                    {/* <div className="font-12px margin-left-25px height-25px validation-color">
                        {referenceValidationMsg.field == "relationShip3" ? referenceValidationMsg.msg : ""}
                    </div> */}
                </div>
            </div>
            <div className="row text-center">
                <div className="col-xs-12">
                    <button className="profile-save-btn" id="" onClick={(e) => referenceUpdate(e)}>
                        <span className="fa fa-floppy-o"></span>&nbsp;&nbsp;Save
                    </button>
                </div>
            </div>
            </React.Fragment>
            :""}
            <hr />
            <div className="row">
                <h3>Residence Details</h3>
            </div>
            <div className="row">
                <h5>Current Address</h5>
            </div>
            <div className="row">
                <div className="col-sm-4 col-xs-12">
                    <h4>Address</h4>
                    <input type="text" className="form-control" value={userDetail.CurrentAddressState} onChange={(e) => onChangeHandler(e, TYPE.CURRENT_ADDRESS)} />
                    <div className="font-12px margin-left-25px height-25px validation-color">
                        {residenceValidationMsg.field == "CurrentAddress" ? residenceValidationMsg.msg : ""}
                    </div>
                </div>
                <div className="col-sm-4 col-xs-12">
                    <h4>Pincode</h4>
                    <input type="text" className="form-control" value={userDetail.CurrentPinCodeState} onChange={(e) => onChangeHandler(e, TYPE.CURRENT_PIN_CODE)} />
                    <div className="font-12px margin-left-25px height-25px validation-color">
                        {residenceValidationMsg.field == "CurrentPin" ? residenceValidationMsg.msg : ""}
                    </div>
                </div>
                <div className="col-sm-4 col-xs-12">
                    <h4>State</h4>
                    <input readOnly type="text" className="form-control" id="userCurrentState" value={userDetail.CurrentStateState} onChange={(e) => onChangeHandler(e, TYPE.CURRENT_STATE)} />
                    <div className="font-12px margin-left-25px height-25px validation-color">
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-sm-4 col-xs-12">
                    <h4>City</h4>
                    <input readOnly type="text" className="form-control" id="userCurrentCity" value={userDetail.CurrentCityState} onChange={(e) => onChangeHandler(e, TYPE.CURRENT_CITY)} />
                    <div className="font-12px margin-left-25px height-25px validation-color">
                    </div>
                </div>
                {/* <div className="col-sm-4 col-xs-12">
                        <h4>Residence Type</h4>
                        <select className="form-control" value={userDetail.CurrentResidenceTypeValue} onChange={(e) => onChangeHandler(e, TYPE.CURRENT_RESIDANCE_TYPE)}>
                            <option value=""></option>
                            <option value={STRING.OWN}>{STRING.OWN}</option>
                            <option value={STRING.RENTED}>{STRING.RENTED}</option>
                            <option value={STRING.HOSTEL}>{STRING.HOSTEL}</option>
                        </select>
                        <div className="font-12px margin-left-25px height-25px validation-color">
                            {residenceValidationMsg.field == "CurrentResidenceType" ? residenceValidationMsg.msg : ""}
                        </div>
                    </div> */}
                {/* <div className="col-sm-4 col-xs-12">
                        <h4>Stay Duration</h4>
                        <select className="form-control" value={userDetail.CurrentDurationState} onChange={(e) => onChangeHandler(e, TYPE.CURRENT_DURATION)}>
                            <option value=""></option>
                            <option value={STRING.EXP_1}>{STRING.EXP_1}</option>
                            <option value={STRING.EXP_2}>{STRING.EXP_2}</option>
                            <option value={STRING.EXP_3}>{STRING.EXP_3}</option>
                            <option value={STRING.EXP_4}>{STRING.EXP_4}</option>
                        </select>
                        <div className="font-12px margin-left-25px height-25px validation-color">
                            {residenceValidationMsg.field == "CurrentDuration" ? residenceValidationMsg.msg : ""}
                        </div>
                    </div> */}
            </div>
            <div className="row">
                <div className="col-sm-6 col-xs-12 ">

                    <h5>Permanent Address</h5>
                </div>
                <div className="col-sm-6 col-xs-12 check-box-div " style={{ textAlign: 'end' }}>
                    {/* &nbsp;&nbsp; */}
                    <div className="checkbox-label" style={{ float: 'right' }}>
                        <input className="phocket-checkbox" type="checkbox" id="phocketSameResidentialadmin" onChange={(e) => AddressCheckHendler(e)} />
                        <label htmlFor="phocketSameResidentialadmin"><span></span></label>
                    </div>
                    <label htmlFor="phocketSameResidentialadmin" className="">&nbsp;Same as current Address</label>&nbsp;&nbsp;
                </div>
            </div>
            <div className="row">
                <div className="col-sm-4 col-xs-12">
                    <h4>Address</h4>
                    <input type="text" className="form-control" value={userDetail.PermanentAddressState} onChange={(e) => onChangeHandler(e, TYPE.PERMANENT_ADDRESS)} />
                    <div className="font-12px margin-left-25px height-25px validation-color">
                        {residenceValidationMsg.field == "PermanentAddress" ? residenceValidationMsg.msg : ""}
                    </div>
                </div>
                <div className="col-sm-4 col-xs-12">
                    <h4>Pincode</h4>
                    <input type="text" className="form-control" value={userDetail.PermanentPinCodeState} onChange={(e) => onChangeHandler(e, TYPE.PERMANENT_PIN_CODE)} />
                    <div className="font-12px margin-left-25px height-25px validation-color">
                        {residenceValidationMsg.field == "PermanentPin" ? residenceValidationMsg.msg : ""}
                    </div>
                </div>
                <div className="col-sm-4 col-xs-12">
                    <h4>State</h4>
                    <input readOnly type="text" className="form-control" id="userPermanentState" value={userDetail.PermanentStateState} onChange={(e) => onChangeHandler(e, TYPE.PERMANENT_STATE)} />
                    <div className="font-12px margin-left-25px height-25px validation-color">
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-sm-4 col-xs-12">
                    <h4>City</h4>
                    <input readOnly type="text" className="form-control" id="userPermanentCity" value={userDetail.PermanentCityState} onChange={(e) => onChangeHandler(e, TYPE.PERMANENT_CITY)} />
                    <div className="font-12px margin-left-25px height-25px validation-color">
                    </div>
                </div>
                {/* <div className="col-sm-4 col-xs-12">
                        <h4>Residence Type</h4>
                        <select className="form-control" value={userDetail.PermanentResidenceTypeValue} onChange={(e) => onChangeHandler(e, TYPE.PERMANENT_RESIDANCE_TYPE)}>
                            <option value=""></option>
                            <option value={STRING.OWN}>{STRING.OWN}</option>
                            <option value={STRING.RENTED}>{STRING.RENTED}</option>
                            <option value={STRING.HOSTEL}>{STRING.HOSTEL}</option>
                        </select>
                        <div className="font-12px margin-left-25px height-25px validation-color">
                            {residenceValidationMsg.field == "PermanentResidenceType" ? residenceValidationMsg.msg : ""}
                        </div>
                    </div>
                    <div className="col-sm-4 col-xs-12">
                        <h4>Stay Duration</h4>
                        <select className="form-control" value={userDetail.PermanentDurationState} onChange={(e) => onChangeHandler(e, TYPE.PERMANENT_DURATION)}>
                            <option value=""></option>
                            <option value={STRING.EXP_1}>{STRING.EXP_1}</option>
                            <option value={STRING.EXP_2}>{STRING.EXP_2}</option>
                            <option value={STRING.EXP_3}>{STRING.EXP_3}</option>
                            <option value={STRING.EXP_4}>{STRING.EXP_4}</option>
                        </select>
                        <div className="font-12px margin-left-25px height-25px validation-color">
                            {residenceValidationMsg.field == "PermanentDuration" ? residenceValidationMsg.msg : ""}
                        </div>
                    </div> */}
            </div>
            <div className="row text-center">
                <div className="col-xs-12">
                    <button className="profile-save-btn" id="" onClick={(e) => onSubmitHandler(e, FORM_STATUS.RESIDANCE)}>
                        <span className="fa fa-floppy-o"></span>&nbsp;&nbsp;Save
                    </button>
                </div>
            </div>
            <hr />
            <div className="row">
                <h3>Occupation Details</h3>
            </div>
            <div className="row">
                <div className="col-sm-4 col-xs-12">
                    <h4>Highest Oualification</h4>
                    <select className="form-control" value={userDetail.HighQualificationState} onChange={(e) => onChangeHandler(e, TYPE.HIGHEST_QUALIFICATION)}>
                        <option value=""></option>
                        <option value={STRING.GRADUATE}>{STRING.GRADUATE}</option>
                        <option value={STRING.POST_GRADUATE}>{STRING.POST_GRADUATE}</option>
                        <option value={STRING.OTHERS}>{STRING.OTHERS}</option>
                    </select>
                    <div className="font-12px margin-left-25px height-25px validation-color">
                        {occupationValidationMsg.field == "HighQualification" ? occupationValidationMsg.msg : ""}
                    </div>
                </div>
                {/* <div className="col-sm-4 col-xs-12">
                    <h4>Job Type</h4>
                    <select className="form-control" value={userDetail.fullPartTimeState} onChange={(e) => onChangeHandler(e, TYPE.FULL_PART_TIME)}>
                        <option value=""></option>
                        <option value={STRING.PART_TIME}>{STRING.PART_TIME}</option>
                        <option value={STRING.FULL_TIME}>{STRING.FULL_TIME}</option>
                    </select>
                    <div className="font-12px margin-left-25px height-25px validation-color">
                        {occupationValidationMsg.field == "fullPartTime" ? occupationValidationMsg.msg : ""}
                    </div>
                </div> */}
                <div className="col-sm-4 col-xs-12">
                    <h4>Employer Type</h4>
                    <select className="form-control" value={userDetail.EmployeeTypeValue} onChange={(e) => onChangeHandler(e, TYPE.EMPLOYER_TYPE)}>
                        <option value=""></option>
                        <option value={EMPLOYER_TYPE.SALARY}>{EMPLOYER_TYPE.SALARY}</option>
                        <option value={EMPLOYER_TYPE.SELF_EMPLOYED}>{EMPLOYER_TYPE.SELF_EMPLOYED}</option>
                    </select>
                    <div className="font-12px margin-left-25px height-25px validation-color">
                        {occupationValidationMsg.field == "EmployeeTypeValue" ? occupationValidationMsg.msg : ""}
                    </div>
                </div>
            </div>
            {userDetail.EmployeeTypeValue == EMPLOYER_TYPE.SALARY ?
                <div className="sal-emp">
                    <div className="row">
                        <div className="col-sm-4 col-xs-12">
                            <h4>Organisation Name</h4>
                            <input type="text" className="form-control" value={userDetail.OrganizationNameState} onChange={(e) => onChangeHandler(e, TYPE.ORGANIZATION_NAME)} />
                            {autoSuggestionState == true ?
                                <div className="auto-suggestion">
                                    <button className="auto-suggestion-close" onClick={e => autoSuggestionClose()}>&times;</button>
                                    {suggestedCompanyName != null && suggestedCompanyName != "" ?
                                        suggestedCompanyName.map((name, i) => {
                                            return (
                                                <p key={i} onClick={e => setCompnyValue(name)}>{name}</p>
                                            )
                                        }) : ""}
                                </div>
                                : ""}
                            <div className="font-12px margin-left-25px height-25px validation-color">
                                {occupationValidationMsg.field == "OrganizationName" ? occupationValidationMsg.msg : ""}
                            </div>
                        </div>
                        <div className="col-sm-4 col-xs-12">
                            <h4>Office Address</h4>
                            <input type="text" className="form-control" value={userDetail.officeAddressState} onChange={(e) => onChangeHandler(e, TYPE.OFFICE_ADDRESS)} />
                            <div className="font-12px margin-left-25px height-25px validation-color">

                            </div>
                        </div>
                        <div className="col-sm-4 col-xs-12">
                            <h4>Office Email Id</h4>
                            <input type="email" className="form-control" value={userDetail.officeMailState} onChange={(e) => onChangeHandler(e, TYPE.OFFICE_EMAIL)} />
                            <div className="font-12px margin-left-25px height-25px validation-color">

                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-4 col-xs-12">
                                <h4>Designation</h4>
                                <input type="text" className="form-control" value={userDetail.designationState} onChange={(e) => onChangeHandler(e, TYPE.DESIGNATION)} />
                                <div className="font-12px margin-left-25px height-25px validation-color">
                                    {occupationValidationMsg.field == "designation" ? occupationValidationMsg.msg : ""}
                                </div>
                            </div>
                        {/* <div className="col-sm-4 col-xs-12">
                                <h4>No of employees</h4>
                                <input type="text" className="form-control" value={userDetail.noOfEmployeeState} onChange={(e) => onChangeHandler(e, TYPE.NO_OF_EMPLOYEE)} />
                                <div className="font-12px margin-left-25px height-25px validation-color">
                                    {occupationValidationMsg.field == "noOfEmployee" ? occupationValidationMsg.msg : ""}
                                </div>
                            </div> */}
                        <div className="col-sm-4 col-xs-12">
                            <h4>Total Experience</h4>
                            <select className="form-control" value={userDetail.WorkExperienceTotalState} onChange={(e) => onChangeHandler(e, TYPE.TOTAL_EXPERIENCE)}>
                                <option value=""></option>
                                <option value={STRING.EXP_1}>{STRING.EXP_1}</option>
                                <option value={STRING.EXP_2}>{STRING.EXP_2}</option>
                                <option value={STRING.EXP_3}>{STRING.EXP_3}</option>
                                <option value={STRING.EXP_4}>{STRING.EXP_4}</option>
                            </select>
                            <div className="font-12px margin-left-25px height-25px validation-color">
                                {occupationValidationMsg.field == "WorkExperienceTotal" ? occupationValidationMsg.msg : ""}
                            </div>
                        </div>
                        <div className="col-sm-4 col-xs-12">
                            <h4>Net Take Home Salary</h4>
                            <input type="number" className="form-control" value={userDetail.HomeSalaryState} onChange={(e) => onChangeHandler(e, TYPE.SALARY)} />
                            <div className="font-12px margin-left-25px height-25px validation-color">
                                {occupationValidationMsg.field == "HomeSalary" ? occupationValidationMsg.msg : ""}
                            </div>
                        </div>
                        {/* <div className="col-sm-4 col-xs-12">
                                <h4>Current Org. Experience</h4>
                                <select className="form-control" value={userDetail.WorkExperienceCurrentState} onChange={(e) => onChangeHandler(e, TYPE.CURRENT_WORK_EXPERIENCE)}>
                                    <option value=""></option>
                                    <option value={STRING.EXP_1}>{STRING.EXP_1}</option>
                                    <option value={STRING.EXP_2}>{STRING.EXP_2}</option>
                                    <option value={STRING.EXP_3}>{STRING.EXP_3}</option>
                                    <option value={STRING.EXP_4}>{STRING.EXP_4}</option>
                                </select>
                                <div className="font-12px margin-left-25px height-25px validation-color">
                                    {occupationValidationMsg.field == "WorkExperienceCurrent" ? occupationValidationMsg.msg : ""}
                                </div>
                            </div> */}
                        {/* <div className="col-sm-4 col-xs-12">
                                <h4>Is this your first job?</h4>
                                <select className="form-control" value={userDetail.IsFirstJobState} onChange={(e) => onChangeHandler(e, TYPE.IS_FIRST_JOB)}>
                                    <option value=""></option>
                                    <option value={STRING.YES}>{STRING.YES}</option>
                                    <option value={STRING.NO}>{STRING.NO}</option>
                                </select>
                                <div className="font-12px margin-left-25px height-25px validation-color">
                                    {occupationValidationMsg.field == "IsFirstJob" ? occupationValidationMsg.msg : ""}
                                </div>
                            </div> */}
                    </div>
                    <div className="row">

                        {/* <div className="col-sm-4 col-xs-12">
                                <h4>Is this your first job?</h4>
                                <select className="form-control" value={userDetail.IsFirstJobState} onChange={(e) => onChangeHandler(e, TYPE.IS_FIRST_JOB)}>
                                    <option value=""></option>
                                    <option value={STRING.YES}>{STRING.YES}</option>
                                    <option value={STRING.NO}>{STRING.NO}</option>
                                </select>
                                <div className="font-12px margin-left-25px height-25px validation-color">
                                    {occupationValidationMsg.field == "IsFirstJob" ? occupationValidationMsg.msg : ""}
                                </div>
                            </div> */}
                        {/* <div className="col-sm-4 col-xs-12">
                                <h4>Previous Org. Experience</h4>
                                <select className="form-control" value={userDetail.WorkExperiencePreviousState} onChange={(e) => onChangeHandler(e, TYPE.PREVIOUS_WORK)}>
                                    <option value=""></option>
                                    <option value={STRING.EXP_1}>{STRING.EXP_1}</option>
                                    <option value={STRING.EXP_2}>{STRING.EXP_2}</option>
                                    <option value={STRING.EXP_3}>{STRING.EXP_3}</option>
                                    <option value={STRING.EXP_4}>{STRING.EXP_4}</option>
                                </select>
                                <div className="font-12px margin-left-25px height-25px validation-color">
                                    {occupationValidationMsg.field == "PreviousWork" ? occupationValidationMsg.msg : ""}
                                </div>
                            </div> */}
                        {/* <div className="col-sm-4 col-xs-12">
                                <h4>Total Experience</h4>
                                <select className="form-control" value={userDetail.WorkExperienceTotalState} onChange={(e) => onChangeHandler(e, TYPE.TOTAL_EXPERIENCE)}>
                                    <option value=""></option>
                                    <option value={STRING.EXP_1}>{STRING.EXP_1}</option>
                                    <option value={STRING.EXP_2}>{STRING.EXP_2}</option>
                                    <option value={STRING.EXP_3}>{STRING.EXP_3}</option>
                                    <option value={STRING.EXP_4}>{STRING.EXP_4}</option>
                                </select>
                                <div className="font-12px margin-left-25px height-25px validation-color">
                                    {occupationValidationMsg.field == "WorkExperienceTotal" ? occupationValidationMsg.msg : ""}
                                </div>
                            </div> */}
                    </div>
                    <div className="row">

                    </div>
                </div>
                : ""}
            {userDetail.EmployeeTypeValue == EMPLOYER_TYPE.SELF_EMPLOYED ?
                <div className="self-emp">
                    <div className="row">
                        <div className="col-sm-4 col-xs-12">
                            <h4>Self-Employee Type</h4>
                            <select className="form-control" value={userDetail.EmployeeTypeState} onChange={(e) => onChangeHandler(e, TYPE.SELF_EMPLOYEE_TYPE)}>
                                <option value=""></option>
                                <option value={STRING.BUSINESS}>{STRING.BUSINESS}</option>
                                <option value={STRING.PROFESSIONAL}>{STRING.PROFESSIONAL}</option>
                            </select>
                            <div className="font-12px margin-left-25px height-25px validation-color">
                                {occupationValidationMsg.field == "SelfEmployeeType" ? occupationValidationMsg.msg : ""}
                            </div>
                        </div>
                        <div className="col-sm-4 col-xs-12">
                            <h4>Industry Type</h4>
                            <select className="form-control" value={userDetail.IndustrialTypeState} onChange={(e) => onChangeHandler(e, TYPE.INDUSTRY_TYPE)}>
                                <option value=""></option>
                                <option value={STRING.MANUFACTURING}>{STRING.MANUFACTURING}</option>
                                <option value={STRING.TRADING}>{STRING.TRADING}</option>
                                <option value={STRING.SERVICES}>{STRING.SERVICES}</option>
                                <option value={STRING.OTHERS}>{STRING.OTHERS}</option>
                            </select>
                            <div className="font-12px margin-left-25px height-25px validation-color">
                                {occupationValidationMsg.field == "IndustrialType" ? occupationValidationMsg.msg : ""}
                            </div>
                        </div>
                        <div className="col-sm-4 col-xs-12">
                            <h4>Monthly Income</h4>
                            <input type="number" className="form-control" value={userDetail.MonthlyIncomeState} onChange={(e) => onChangeHandler(e, TYPE.MONTHLY_INCOME)} />
                            <div className="font-12px margin-left-25px height-25px validation-color">
                                {occupationValidationMsg.field == "MonthlyIncome" ? occupationValidationMsg.msg : ""}
                            </div>
                        </div>
                    </div>
                </div>
                : ""}
            <div className="row text-center">
                <div className="col-xs-12">
                    <button className="profile-save-btn" id="" onClick={(e) => onSubmitHandler(e, FORM_STATUS.OCCUPATIONAL)}>
                        <span className="fa fa-floppy-o"></span>&nbsp;&nbsp;Save
                    </button>
                </div>
            </div>
            <hr />
            <div className="row">
                <h3>Bank Details</h3>
            </div>
            <div className="row form-group">
                <div className="col-sm-4 col-xs-12">
                    <h4>Name as in Bank Account</h4>
                    <input type="text" className="form-control" value={userDetail.BankUserNameState} onChange={(e) => onChangeHandler(e, TYPE.BANK_USER_NAME)} />
                    <div className="font-12px margin-left-25px height-25px validation-color">
                        {bankValidationMsg.field == "BankUserName" ? bankValidationMsg.msg : ""}
                    </div>
                </div>
                <div className="col-sm-4 col-xs-12">
                    <h4>IFSC Code</h4>
                    <input type="text" className="form-control" value={userDetail.IFSCCodeState} onChange={(e) => onChangeHandler(e, TYPE.IFSC_CODE)} />
                    <div className="font-12px margin-left-25px height-25px validation-color">
                        {bankValidationMsg.field == "IFSCCode" ? bankValidationMsg.msg : ""}
                    </div>
                </div>
                <div className="col-sm-4 col-xs-12">
                    <h4>Account Number</h4>
                    <input type="text" className="form-control" value={userDetail.AccountNoState} onChange={(e) => onChangeHandler(e, TYPE.ACCOUNT_NO)} />
                    <div className="font-12px margin-left-25px height-25px validation-color">
                        {bankValidationMsg.field == "AccountNo" ? bankValidationMsg.msg : ""}
                    </div>
                </div>
            </div>
            <div className="row form-group">
                <div className="col-sm-4 col-xs-12">
                    <h4>Bank Name</h4>
                    <input type="text" id="userBankName" className="form-control" value={userDetail.BankNameState} onChange={(e) => onChangeHandler(e, TYPE.BANK_NAME)} />
                    <div className="font-12px margin-left-25px height-25px validation-color">
                    </div>
                </div>
                <div className="col-sm-4 col-xs-12">
                    <h4>Branch</h4>
                    <input type="text" id="userBankBranch" className="form-control" value={userDetail.BankBranchState} onChange={(e) => onChangeHandler(e, TYPE.BANK_BRANCH)} />
                    <div className="font-12px margin-left-25px height-25px validation-color">
                    </div>
                </div>
                <div className="col-sm-4 col-xs-12">
                    <h4>If Salried Account?</h4>
                    <select required className="form-control" value={userDetail.IfSalriedAccountState} onChange={(e) => onChangeHandler(e, TYPE.IF_SALARIED_ACCOUNT)} >
                        <option value=""></option>
                        <option value={STRING.YES}>{STRING.YES}</option>
                        <option value={STRING.NO}>{STRING.NO}</option>
                    </select>
                    <div className="font-12px margin-left-25px height-25px validation-color">
                        {bankValidationMsg.field == "IfSalriedAccount" ? bankValidationMsg.msg : ""}
                    </div>
                </div>
            </div>
            <div className="row form-group">
                <div className="col-sm-4 col-xs-12">
                    <h4>Pan Number</h4>
                    <input type="text" id="panNumber" className="form-control" value={userDetail.PanNoState} onChange={(e) => onChangeHandler(e, TYPE.PAN_NO)} />
                    <div className="font-12px margin-left-25px height-25px validation-color">
                        {bankValidationMsg.field == "PanNo" ? bankValidationMsg.msg : ""}
                    </div>
                </div>
                {/* <div className="col-sm-4 col-xs-12">
                    <h4>Name As on PAN</h4>
                    <input type="text" readOnly className="form-control" placeholder='Name As On PAN' value={userDetail.nameAsOnPan} onChange={(e) => onChangeHandler(e, 'PanName')} />
                    <div className="font-12px margin-left-25px height-25px validation-color">
                    </div>
                </div> */}
                <div className="col-sm-4 col-xs-12">
                    <h4>Aadhaar Number</h4>
                    <input type="number" id="aadhaarNumber" className="form-control" value={userDetail.aadharNoState} onChange={(e) => onChangeHandler(e, TYPE.AADHAAR_NO)} />
                    <div className="font-12px margin-left-25px height-25px validation-color">
                        {bankValidationMsg.field == "aadharNo" ? bankValidationMsg.msg : ""}
                    </div>
                </div>
            </div>
            <div className="row text-center">
                <div className="col-xs-12">
                    <button className="profile-save-btn" id="" onClick={(e) => onSubmitHandler(e, FORM_STATUS.BANK)}>
                        <span className="fa fa-floppy-o"></span>&nbsp;&nbsp;Save
                    </button>
                </div>
            </div>
            <hr />
            {userDetails.loanFrom === 'vreedhi' && vreedhiDataState !== undefined ?
                <div>
                    <div className="row">
                        <h3>Vreedhi Details</h3>
                    </div>
                    <div className="row">
                        <div className="col-sm-4 col-xs-12">
                            <h4>Business Name</h4>
                            <input type="text" className="form-control" value={vreedhiDataState.name} onChange={(e) => onChangeHandlerBuisness(e, 'name')} />
                            <div className="font-12px margin-left-25px height-25px validation-color">
                                {/* {basicValidationMsg.field == "firstName" ? basicValidationMsg.msg : ""} */}
                            </div>
                        </div>
                        <div className="col-sm-4 col-xs-12">
                            <h4>Business Address</h4>
                            <input type="text" className="form-control" value={vreedhiDataState.address} onChange={(e) => onChangeHandlerBuisness(e, 'address')} />
                            <div className="font-12px margin-left-25px height-25px validation-color">
                                {/* {basicValidationMsg.field == "middleName" ? basicValidationMsg.msg : ""} */}
                            </div>
                        </div>
                        <div className="col-sm-4 col-xs-12">
                            <h4>Business Category</h4>
                            <input type="text" className="form-control" value={vreedhiDataState.category} onChange={(e) => onChangeHandlerBuisness(e, 'category')} />
                            <div className="font-12px margin-left-25px height-25px validation-color">
                                {/* {basicValidationMsg.field == "lastName" ? basicValidationMsg.msg : ""} */}
                            </div>
                        </div>
                        <div className="col-sm-4 col-xs-12">
                            <h4>Business segment</h4>
                            <input type="text" className="form-control" value={vreedhiDataState.segment} onChange={(e) => onChangeHandlerBuisness(e, 'segment')} />
                            <div className="font-12px margin-left-25px height-25px validation-color">
                                {/* {basicValidationMsg.field == "lastName" ? basicValidationMsg.msg : ""} */}
                            </div>
                        </div>
                        <div className="col-sm-4 col-xs-12">
                            <h4>Monthly Turnover</h4>
                            <input type="text" className="form-control" value={vreedhiDataState.monthlyTurnover} onChange={(e) => onChangeHandlerBuisness(e, 'monthlyTurnover')} />
                            <div className="font-12px margin-left-25px height-25px validation-color">
                                {/* {basicValidationMsg.field == "lastName" ? basicValidationMsg.msg : ""} */}
                            </div>
                        </div>
                        <div className="col-sm-4 col-xs-12">
                            <h4>Net Bussiness Income</h4>
                            <input type="text" className="form-control" value={vreedhiDataState.netBussinessIncome} onChange={(e) => onChangeHandlerBuisness(e, 'netBussinessIncome')} />
                            <div className="font-12px margin-left-25px height-25px validation-color">
                                {/* {basicValidationMsg.field == "lastName" ? basicValidationMsg.msg : ""} */}
                            </div>
                        </div>

                    </div>
                    <div className="row text-center">
                        <div className="col-xs-12">
                            <button className="profile-save-btn" id="" onClick={(e) => onSubmitHandlerVreedhi()}>
                                <span className="fa fa-floppy-o"></span>&nbsp;&nbsp;Save
                            </button>
                        </div>
                    </div>
                    <hr />
                </div>
                : ""}

            <div className="row">
                <h3>Loan Details</h3>
            </div>
            <div className="row form-group">
                <div className="col-sm-4 col-xs-12">
                    <h4>Loan Amount</h4>
                    <input type="number" id="loanAmount" className="form-control" value={userDetail.loanAmountState} onChange={(e) => onChangeHandler(e, TYPE.LOAN_AMOUNT)} />
                    <div className="font-12px margin-left-25px height-25px validation-color">
                        {loanValidationMsg.field == "loanAmount" ? loanValidationMsg.msg : ""}
                    </div>
                </div>
                <div className="col-sm-4 col-xs-12">
                    <h4>Tenure </h4>
                    <input type="number" id="loanTenure" className="form-control" value={userDetail.loanDaysState} onChange={(e) => onChangeHandler(e, TYPE.LOAN_DAYS)} />
                    <div className="font-12px margin-left-25px height-25px validation-color">
                        {loanValidationMsg.field == "loanDays" ? loanValidationMsg.msg : ""}
                    </div>
                </div>
                <div className="col-sm-4 col-xs-12">
                    <h4>Loan Reason </h4>
                    <input type="text" id="loanReason" className="form-control" value={userDetail.loanReasonState} onChange={(e) => onChangeHandler(e, TYPE.LOAN_REASON)} />
                    <div className="font-12px margin-left-25px height-25px validation-color">
                        {loanValidationMsg.field == "loanReason" ? loanValidationMsg.msg : ""}
                    </div>
                </div>
            </div>
            {/* <div className="row text-center">
                    <div className="col-xs-12">
                        <button className="profile-save-btn" id="" onClick={(e) => onSubmitHandler(e, FORM_STATUS.LOAN)}>
                            <span className="fa fa-floppy-o"></span>&nbsp;&nbsp;Save
                        </button>
                    </div>
                </div> */}
            <hr />

            <div className="row">
                <h3>Documents</h3>
            </div>
            <div className="row form-group">
                <div className="upload-docs col-sm-6 col-xs-12">
                    <div>
                        <h4>Photograph</h4>
                    </div>
                    <div>
                        <input multiple="multiple" accept="image/png , image/jpeg , application/pdf" type="file" className="form-control display-none" id="photograph" onChange={(e) => onChangeHandler(e, TYPE.PHOTO)} />
                        <label htmlFor="photograph" >Choose New File </label>
                        {docType == TYPE.PHOTO && progressState == true ? <div className="progress">
                            <div className="progress-bar progress" style={{ width: progressStateWidth < 100 ? progressStateWidth + "%" : 100 + "%" }}>{progressStateWidth < 100 ? progressStateWidth + "%" : 100 + "%"}</div>
                        </div> : null}
                    </div>
                    <div>
                        {userDetail.dashPhotoState.split("type=")[1] == "img" ?
                            <a target="_blank" href={userDetail.dashPhotoState}>
                                <img className="" src={userDetail.dashPhotoState} />
                            </a>
                            : ""}
                        {userDetail.dashPhotoState.split("type=")[1] == "pdf" ?
                            <a target="_blank" href={userDetail.dashPhotoState}>
                                <object data={userDetail.dashPhotoState} type="application/pdf"></object>
                            </a>
                            : ""}
                    </div>
                </div>
                <div className="upload-docs col-sm-6 col-xs-12">
                    <div>
                        <h4>Aadhaar Card</h4>
                    </div>
                    <div>
                        <input multiple="multiple" accept="image/png , image/jpeg , application/pdf" type="file" className="form-control display-none" id="identityProof" onChange={(e) => onChangeHandler(e, TYPE.ADHAR_CARD)} />
                        <label htmlFor="identityProof" >Choose New File  </label>
                        {docType == TYPE.ADHAR_CARD && progressState == true ? <div className="progress">
                            <div className="progress-bar progress" style={{ width: progressStateWidth < 100 ? progressStateWidth + "%" : 100 + "%" }}>{progressStateWidth < 100 ? progressStateWidth + "%" : 100 + "%"}</div>
                        </div> : null}

                    </div>
                    <div>
                        {userDetail.dashIdProofState.split("type=")[1] == "img" ?
                            <a target="_blank" href={userDetail.dashIdProofState}>
                                <img className="" src={userDetail.dashIdProofState} />
                            </a>
                            : ""}
                        {userDetail.dashIdProofState.split("type=")[1] == "pdf" ?
                            <a target="_blank" href={userDetail.dashIdProofState}>
                                <object data={userDetail.dashIdProofState} type="application/pdf"></object>
                            </a>
                            : ""}
                    </div>
                </div>
            </div>
            <div className="row form-group">
                <div className="upload-docs col-sm-6 col-xs-12">
                    <div>
                        <h4>Pan Card</h4>
                    </div>
                    <div>
                        <input multiple="multiple" accept="image/png , image/jpeg , application/pdf" type="file" className="form-control display-none" id="panProof" onChange={(e) => onChangeHandler(e, TYPE.PAN_CARD)} />
                        <label htmlFor="panProof">Choose New File</label>
                        {docType == TYPE.PAN_CARD && progressState == true ? <div className="progress">
                            <div className="progress-bar progress" style={{ width: progressStateWidth < 100 ? progressStateWidth + "%" : 100 + "%" }}>{progressStateWidth < 100 ? progressStateWidth + "%" : 100 + "%"}</div>
                        </div> : null}
                    </div>
                    <div>
                        {userDetail.dashPanState.split("type=")[1] == "img" ?
                            <a target="_blank" href={userDetail.dashPanState}>
                                <img className="" src={userDetail.dashPanState} />
                            </a>
                            : ""}
                        {userDetail.dashPanState.split("type=")[1] == "pdf" ?
                            <a target="_blank" href={userDetail.dashPanState}>
                                <object data={userDetail.dashPanState} type="application/pdf"></object>
                            </a>
                            : ""}
                    </div>
                </div>
                <div className="upload-docs col-sm-6 col-xs-12">
                    <div>
                        <h4>Bank Statement</h4>
                    </div>
                    <div>
                        <input multiple="multiple" accept="image/png , image/jpeg , application/pdf" type="file" className="form-control display-none" id="bankStatementProof" onChange={(e) => onChangeHandler(e, TYPE.BANK_STATMENT)} />
                        <label htmlFor="bankStatementProof">Choose New File</label>
                        {docType == TYPE.BANK_STATMENT && progressState == true ? <div className="progress">
                            <div className="progress-bar progress" style={{ width: progressStateWidth < 100 ? progressStateWidth + "%" : 100 + "%" }}>{progressStateWidth < 100 ? progressStateWidth + "%" : 100 + "%"}</div>
                        </div> : null}
                    </div>
                    <div>
                        {userDetail.dashBankStatmentState.split("type=")[1] == "img" ?
                            <a target="_blank" href={userDetail.dashBankStatmentState}>
                                <img className="" src={userDetail.dashBankStatmentState} />
                            </a>
                            : ""}
                        {userDetail.dashBankStatmentState.split("type=")[1] == "pdf" ?
                            <a target="_blank" href={userDetail.dashBankStatmentState}>
                                <object data={userDetail.dashBankStatmentState} type="application/pdf"></object>
                            </a>
                            : ""}
                    </div>
                </div>
            </div>
            <div className="row form-group">
                {/* {userDetail != null ? userDetail.EmployeeTypeValue == EMPLOYER_TYPE.SALARY  ? */}
                {userDetail != null ?

                    <div className="upload-docs col-sm-6 col-xs-12">
                        <div>
                            <h4>{userDetail.EmployeeTypeValue == EMPLOYER_TYPE.SALARY ? 'Salary Slip' : 'Income Proof'}</h4>
                        </div>
                        <div>
                            <input multiple="multiple" accept="image/png , image/jpeg , application/pdf" type="file" className="form-control display-none" id="salarySlip" onChange={(e) => onChangeHandler(e, TYPE.SALARY_PROOF)} />
                            <label htmlFor="salarySlip">Choose New File</label>
                            {docType == TYPE.SALARY_PROOF && progressState == true ? <div className="progress">
                                <div className="progress-bar progress" style={{ width: progressStateWidth < 100 ? progressStateWidth + "%" : 100 + "%" }}>{progressStateWidth < 100 ? progressStateWidth + "%" : 100 + "%"}</div>
                            </div> : null}
                        </div>
                        <div>
                            {userDetail.dashSalProofState.split("type=")[1] == "img" ?
                                <a target="_blank" href={userDetail.dashSalProofState}>
                                    <img className="" src={userDetail.dashSalProofState} />
                                </a>
                                : ""}
                            {userDetail.dashSalProofState.split("type=")[1] == "pdf" ?
                                <a target="_blank" href={userDetail.dashSalProofState}>
                                    <object data={userDetail.dashSalProofState} type="application/pdf"></object>
                                </a>
                                : ""}
                        </div>
                    </div>
                    : ""}
                {userDetail != null ? userDetail.EmployeeTypeValue == EMPLOYER_TYPE.SELF_EMPLOYED ?
                    <div className="upload-docs col-sm-6 col-xs-12">
                        <div>
                            <h4>ITR</h4>
                        </div>
                        <div>
                            <input multiple="multiple" accept="image/png , image/jpeg , application/pdf" type="file" className="form-control display-none" id="itrProof" onChange={(e) => onChangeHandler(e, TYPE.ITR_STATEMENT)} />
                            <label htmlFor="itrProof">Choose New File</label>
                            {docType == TYPE.ITR_STATEMENT && progressState == true ? <div className="progress">
                                <div className="progress-bar progress" style={{ width: progressStateWidth < 100 ? progressStateWidth + "%" : 100 + "%" }}>{progressStateWidth < 100 ? progressStateWidth + "%" : 100 + "%"}</div>
                            </div> : null}
                        </div>
                        <div>
                            {userDetail.dashItrState.split("type=")[1] == "img" ?
                                <a target="_blank" href={userDetail.dashItrState}>
                                    <img className="" src={userDetail.dashItrState} />
                                </a>
                                : ""}
                            {userDetail.dashItrState.split("type=")[1] == "pdf" ?
                                <a target="_blank" href={userDetail.dashItrState}>
                                    <object data={userDetail.dashItrState} type="application/pdf"></object>
                                </a>
                                : ""}
                        </div>
                    </div>
                    : "" : ""}
                <div className="upload-docs col-sm-6 col-xs-12">
                    <div>
                        <h4>CIBIL</h4>
                    </div>
                    <div>
                        <input multiple="multiple" accept="image/png , image/jpeg , application/pdf" type="file" className="form-control display-none" id="cibilReport" onChange={(e) => onChangeHandler(e, TYPE.CIBIL_REPORT)} />
                        <label htmlFor="cibilReport">Choose New File</label>
                        {docType == TYPE.CIBIL_REPORT && progressState == true ? <div className="progress">
                            <div className="progress-bar progress" style={{ width: progressStateWidth < 100 ? progressStateWidth + "%" : 100 + "%" }}>{progressStateWidth < 100 ? progressStateWidth + "%" : 100 + "%"}</div>
                        </div> : null}
                    </div>
                    <div>
                        {userDetail.dashCibilState.split("type=")[1] == "img" ?
                            <a target="_blank" href={userDetail.dashCibilState}>
                                <img className="" src={userDetail.dashCibilState} />
                            </a>
                            : ""}
                        {userDetail.dashCibilState.split("type=")[1] == "pdf" ?
                            <a target="_blank" href={userDetail.dashCibilState}>
                                <object data={userDetail.dashCibilState} type="application/pdf"></object>
                            </a>
                            : ""}
                    </div>
                </div>
                <div className="upload-docs col-sm-6 col-xs-12">
                    <div>
                        <h4>Miscellaneous</h4>
                    </div>
                    <div>
                        <input multiple="multiple" accept="image/png , image/jpeg , application/pdf" type="file" className="form-control display-none" id="miscellaneousReport" onChange={(e) => onChangeHandler(e, TYPE.MISCELLANEOUS)} />
                        <label htmlFor="miscellaneousReport">Choose New File</label>
                        {docType == TYPE.MISCELLANEOUS && progressState == true ? <div className="progress">
                            <div className="progress-bar progress" style={{ width: progressStateWidth < 100 ? progressStateWidth + "%" : 100 + "%" }}>{progressStateWidth < 100 ? progressStateWidth + "%" : 100 + "%"}</div>
                        </div> : null}
                    </div>
                    <div>
                        {userDetail.dashMiscellaneousState.split("type=")[1] == "img" ?
                            <a target="_blank" href={userDetail.dashMiscellaneousState}>
                                <img className="" src={userDetail.dashMiscellaneousState} />
                            </a>
                            : ""}
                        {userDetail.dashMiscellaneousState.split("type=")[1] == "pdf" ?
                            <a target="_blank" href={userDetail.dashMiscellaneousState}>
                                <object data={userDetail.dashMiscellaneousState} type="application/pdf"></object>
                            </a>
                            : ""}
                    </div>
                </div>
                <div className="upload-docs col-sm-6 col-xs-12">
                    <div>
                        <h4>Current Address Proof</h4>
                    </div>
                    <div>
                        <input multiple="multiple" accept="image/png , image/jpeg , application/pdf" type="file" className="form-control display-none" id="currentAddressProof" onChange={(e) => onChangeHandler(e, TYPE.CURRENT_ADD_PROOF)} />
                        <label htmlFor="currentAddressProof">Choose New File</label>
                        {docType == TYPE.CURRENT_ADD_PROOF && progressState == true ? <div className="progress">
                            <div className="progress-bar progress" style={{ width: progressStateWidth < 100 ? progressStateWidth + "%" : 100 + "%" }}>{progressStateWidth < 100 ? progressStateWidth + "%" : 100 + "%"}</div>
                        </div> : null}
                    </div>
                    <div>
                        {userDetail.currentAddresState.split("type=")[1] == "img" ?
                            <a target="_blank" href={userDetail.currentAddresState}>
                                <img className="" src={userDetail.currentAddresState} />
                            </a>
                            : ""}
                        {userDetail.currentAddresState.split("type=")[1] == "pdf" ?
                            <a target="_blank" href={userDetail.currentAddresState}>
                                <object data={userDetail.currentAddresState} type="application/pdf"></object>
                            </a>
                            : ""}
                    </div>
                </div>

            </div>
            {/* <div className="row form-group">
                <div className="col-sm-4 col-xs-12">
                    <h4>Photograph</h4>
                    <label htmlFor="photograph" className="input-field-label-new">
                        <span className="fa fa-paperclip"></span>
                    </label>
                    <span className="font-12px document-upload-ellipsis">{userDetail.dashPhotoState}</span>
                    <input accept="image/png , image/jpeg , application/pdf" type="file" className="form-control display-none" id="photograph" onChange={(e) => onChangeHandler(e, TYPE.PHOTO)} />

                </div>
                <div className="col-sm-4 col-xs-12">
                    <h4>Aadhaar Card</h4>
                    <label htmlFor="aadhaar" className="input-field-label-new">
                        <span className="fa fa-paperclip"></span>
                    </label>
                    <span className="font-12px document-upload-ellipsis">{userDetail.dashIdProofState}</span>
                    <input accept="image/png , image/jpeg , application/pdf" type="file" className="form-control display-none" id="aadhaar" onChange={(e) => onChangeHandler(e, TYPE.ADHAR_CARD)} />
                </div>
                <div className="col-sm-4 col-xs-12">
                    <h4>Pan Card</h4>
                    <label htmlFor="panCard" className="input-field-label-new">
                        <span className="fa fa-paperclip"></span>
                    </label>
                    <span className="font-12px document-upload-ellipsis">{userDetail.dashPanState}</span>
                    <input accept="image/png , image/jpeg , application/pdf" type="file" className="form-control display-none" id="panCard" onChange={(e) => onChangeHandler(e, TYPE.PAN_CARD)} />
                </div>
            </div>
            <div className="row form-group">
                <div className="col-sm-4 col-xs-12">
                    <h4>Bank Statement</h4>
                    <label htmlFor="bankStatement" className="input-field-label-new">
                        <span className="fa fa-paperclip"></span>
                    </label>
                    <span className="font-12px document-upload-ellipsis">{userDetail.dashBankStatmentState}</span>
                    <input accept="image/png , image/jpeg , application/pdf" type="file" className="form-control display-none" id="bankStatement" onChange={(e) => onChangeHandler(e, TYPE.BANK_STATMENT)} />
                </div>
                {userDetail.EmployeeTypeValue == EMPLOYER_TYPE.SALARY ?
                    <div className="col-sm-4 col-xs-12">
                        <h4>Salary Slip</h4>
                        <label htmlFor="salarySlip" className="input-field-label-new">
                            <span className="fa fa-paperclip"></span>
                        </label>
                        <span className="font-12px document-upload-ellipsis">{userDetail.dashSalProofState}</span>
                        <input accept="image/png , image/jpeg , application/pdf" type="file" className="form-control display-none" id="salarySlip" onChange={(e) => onChangeHandler(e, TYPE.SALARY_PROOF)} />
                    </div>
                    : ""}
                {userDetail.EmployeeTypeValue == EMPLOYER_TYPE.SELF_EMPLOYED ?
                    <div className="col-sm-4 col-xs-12">
                        <h4>ITR (Last 1 year Statement)</h4>
                        <label htmlFor="itr" className="input-field-label-new">
                            <span className="fa fa-paperclip"></span>
                        </label>
                        <span className="font-12px document-upload-ellipsis">{userDetail.dashItrState}</span>
                        <input accept="image/png , image/jpeg , application/pdf" type="file" className="form-control display-none" id="itr" onChange={(e) => onChangeHandler(e, TYPE.ITR_STATEMENT)} />
                    </div>
                    : ""}
                <div className="col-sm-4 col-xs-12">
                    <h4>Cibil</h4>
                    <label htmlFor="cibil" className="input-field-label-new">
                        <span className="fa fa-paperclip"></span>
                    </label>
                    <span className="font-12px document-upload-ellipsis">{userDetail.dashCibilState}</span>
                    <input accept="image/png , image/jpeg , application/pdf" type="file" className="form-control display-none" id="cibil" onChange={(e) => onChangeHandler(e, TYPE.CIBIL_REPORT)} />
                </div>
            </div> */}
        </div>
        {BankPasswordState == true ?
        
            <BankPasswordPopup
                docPassword={docPassword}
                docPasswordHandler={docPasswordHandler}
                passwordErrorMsg={passwordErrorMsg}
            />
            : ""}
        {SalaryPasswordState == true ?
            <SalaryPasswordPopup
                docPassword={docPassword}
                docPasswordHandler={docPasswordHandler}
                passwordErrorMsg={passwordErrorMsg}
            />
            : ""}
    </div >
)
export default CustomerProfile;