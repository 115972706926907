import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import CompanyLeads from '../../../presentationals/Admin/ApplicationLeads/CompanyLeads.component';
import {
    getLoanApprovalLeadPriority, getistByNumber, getlistByEmail, getlistByApplication,
    getRoleAssignView, getUserDetailByLoanId, saveImportantLoans
} from '../AdminActionCreator.component';
import UserDetail from '../CustomerPages/UserDetailMain.container';
import { APIS } from '../../../../utils/api-factory';
import { STRING } from '../../../../utils/Constant'
import { _preFormatDate } from '../../../../utils/validation'
import MainDetailsPopup from '../CustomerPages/MainDetailPopup.container'
import localStorageUtil from '../../../../utils/localStorageUtil';
import ConfirmationPopup from '../../../presentationals/Popup/ConfirmationPopup.component'

let columnHide = true;
let isDeleteUser = false

class CompanyLeadsContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userState: true,
            viewState: false,
            editState: false,
            allDetail: "",
            userDetail: "",
            followUpStatus: STRING.SELECT_ALL,
            forwordPageState: 1,
            loadarState: false,
            viewDetailPopupState: false,
            viewPopupDetail: '',
            logsUserId: '',
            downloadExcelState: '',
            actionButtonState: true,
            allAdminList: '',
            FilteredData: [],
            pageIndexToShow: 0,
            loadarState: false,
            searchByAppNo: '',
            searchByemail: '',
            searchBymobile: '',
            confirmationPopup: false,
            confirmationText: '',
            userIdForDelete: '',
            searchByDate: '',
            searchByDateShow: ''
        }
    }

    componentWillMount() {
        getRoleAssignView(localStorageUtil.getFromLocalStorage("AdminemailId"), (callBack) => {
            this.setState({ allAdminList: callBack })
        })
        this.getLoanLead()
    }

    getLoanLead() {
        this.setState({
            loadarState: true
        })
        this.props.getLoanApprovalLeadPriority(this.state.followUpStatus, this.state.forwordPageState, this.props.admin.emailId, this.state.searchByDate, 'priority',
            (callBack) => {
                this.setState({
                    loadarState: false
                })
            });
    }

    render() {
        const { loanApprovalLeadByPriority, admin, allStatus } = this.props;
        if (admin != null) {
            if (admin.role != undefined && (admin.role == 'member' || admin.role == '' || admin.role == null)) {
                columnHide = false;
            }
            if (admin.role != null && admin.role == 'superuser') {
                isDeleteUser = true
            }
        }
        return (
            <div className="full-height">
                {this.state.userState == true ?
                    <CompanyLeads
                        userDetailHandler={this.userDetailHandler.bind(this)}
                        allUserDetail={loanApprovalLeadByPriority}
                        statusHandler={this.statusHandler.bind(this)}
                        forwordpage={this.forwordpage.bind(this)}
                        backpage={this.backpage.bind(this)}
                        dataFilter={this.dataFilter.bind(this)}
                        editUserDetailHandler={this.editUserDetailHandler.bind(this)}
                        userViewHandler={this.userViewHandler.bind(this)}
                        admin={admin}
                        loadarState={this.state.loadarState}
                        refreshpage={this.refreshpage.bind(this)}
                        allStatus={allStatus}
                        downloadExcelState={this.state.downloadExcelState}
                        allAdminList={this.state.allAdminList}
                        taskAssignHandler={this.taskAssignHandler.bind(this)}
                        columnHide={columnHide}
                        isDeleteUser={isDeleteUser}
                        markAsImportant={this.markAsImportant.bind(this)}
                        getFilterValue={this.getFilterValue.bind(this)}
                        FilteredData={this.state.FilteredData}
                        getPageValue={this.getPageValue.bind(this)}
                        pageIndexToShow={this.state.pageIndexToShow}
                        followUpStatus={this.state.followUpStatus}
                        searchByAppNo={this.state.searchByAppNo}
                        searchByemail={this.state.searchByemail}
                        searchBymobile={this.state.searchBymobile}
                        deleteUserHandler={this.deleteUserHandler.bind(this)}
                        searchByDate={this.state.searchByDate}
                        searchByDateShow={this.state.searchByDateShow}
                    /> : ""}
                {this.state.viewState == true ?
                    <UserDetail
                        allDetail={this.state.allDetail}
                        userDetail={this.state.userDetail}
                        admin={admin}
                        backHandler={this.backHandler.bind(this)}
                        actionButtonState={this.state.actionButtonState}
                    />
                    : ""}
                {this.state.viewDetailPopupState == true ?
                    <MainDetailsPopup
                        getviewDetailPopupState={this.getviewDetailPopupState.bind(this)}
                        admin={admin}
                        allDetail={this.state.viewPopupDetail}
                    />
                    : ""}
                {this.state.confirmationPopup == true ?
                    <ConfirmationPopup
                        cancelLoanConfirm={this.cancelLoanConfirm.bind(this)}
                        confirmationText={this.state.confirmationText} />
                    : ""}
            </div>
        )
    }

    deleteUserHandler(userId) {
        this.setState({
            userIdForDelete: userId,
            confirmationPopup: true,
            confirmationText: 'Are you sure to Delete this user'
        })
    }

    cancelLoanConfirm(conType) {
        if (conType == 'yes') {
            fetch(APIS.DELETE_COMPLETE_USER + this.state.userIdForDelete)
                .then(res => res.text())
                .then(text => {
                    if (text == 'success') {
                        this.setState({ confirmationPopup: false })
                        this.getLoanLead()
                    }
                })
        } else if (conType == 'no') {
            this.setState({ confirmationPopup: false })
        }
    }

    getFilterValue(filtered) {
        this.setState({ FilteredData: filtered })
    }

    getPageValue(pageIndex) {
        this.setState({ pageIndexToShow: pageIndex })
    }

    markAsImportant(allDetail, isImportant) {
        saveImportantLoans(allDetail, isImportant,
            (callBack) => {
                this.getLoanLead()
            })
    }

    taskAssignHandler(e, loanId) {
        fetch(APIS.ASSIGN_LOAN + loanId + "&empId=" + e.target.value)
            .then(res => res.text())
            .then(text => {

            })
    }
    refreshpage() {
        this.getLoanLead()
    }

    dataFilter(e, type) {
        const { admin } = this.props;
        if (type == "applicationNo") {
            this.setState({ searchByAppNo: e.target.value })
            // appNo = e.target.value
            if (e.target.value.length > 4) {
                this.setState({
                    loadarState: true
                })
                this.props.getlistByApplication(e.target.value, 1, '', admin,
                    (callBack) => {
                        this.setState({
                            loadarState: false
                        })
                    })
            }
        } else if (type == "email") {
            this.setState({ searchByemail: e.target.value })
            // let email = e.target.value
            if (e.target.value.length > 2) {
                this.setState({
                    loadarState: true
                })
                this.props.getlistByEmail(e.target.value, 1, '', admin,
                    (callBack) => {
                        this.setState({
                            loadarState: false
                        })
                    })
            }
        } else if (type == "mobileNo") {
            this.setState({ searchBymobile: e.target.value })
            // let mobile = e.target.value
            if (e.target.value.length > 9) {
                this.setState({
                    loadarState: true
                })
                this.props.getistByNumber(e.target.value, 1, '', admin,
                    (callBack) => {
                        this.setState({
                            loadarState: false
                        })
                    })
            }
        } else if (type == "date") {
            this.setState({
                searchByDate: _preFormatDate(e),
                searchByDateShow: e,
            }, () => this.getLoanLead())
        }
        if (type != "date" && e.target.value == "") {
            this.getLoanLead()
        }
    }
    forwordpage() {
        this.setState({
            forwordPageState: this.state.forwordPageState + 1,
            pageIndexToShow: 0
        }, () => this.getLoanLead())

    }
    backpage() {
        if (this.state.forwordPageState > 1) {
            this.setState({
                forwordPageState: this.state.forwordPageState - 1,
                pageIndexToShow: 0
            }, () => this.getLoanLead())
        }
    }
    statusHandler(e) {
        this.setState({
            followUpStatus: e.target.value
        }, () => this.getLoanLead())
    }
    componentDidUpdate() {
        const { loanApprovalLeadByPriority } = this.props;
        if (loanApprovalLeadByPriority.length > 0) {
            if (document.getElementById("forword") != null)
                document.getElementById("forword").disabled = false;
        } else {
            if (document.getElementById("forword") != null)
                document.getElementById("forword").disabled = true;
        }

    }

    backHandler() {
        const { admin } = this.props
        this.setState({
            userState: true,
            viewState: false,
            editState: false
        })
        if (this.state.searchByAppNo != '') {
            this.props.getlistByApplication(this.state.searchByAppNo, 1, '', admin,
                (callBack) => {
                    this.setState({
                        loadarState: false
                    })
                })
        } else if (this.state.searchByemail != '') {
            this.props.getlistByEmail(this.state.searchByemail, 1, '', admin,
                (callBack) => {
                    this.setState({
                        loadarState: false
                    })
                })
        } else if (this.state.searchBymobile != '') {
            this.props.getistByNumber(this.state.searchBymobile, 1, '', admin,
                (callBack) => {
                    this.setState({
                        loadarState: false
                    })
                })
        } else {
            this.getLoanLead()
        }
    }
    editUserDetailHandler(allDetail) {
        getUserDetailByLoanId(allDetail.loanId,
            (callBack) => {
                this.setState({
                    editState: true,
                    userState: false,
                    viewState: false,
                    allDetail: callBack,
                    userDetail: allDetail,
                })
            })
    }
    userDetailHandler(allDetail) {
        getUserDetailByLoanId(allDetail.loanId,
            (callBack) => {
                this.setState({
                    userState: false,
                    viewState: true,
                    editState: false,
                    allDetail: callBack,
                    userDetail: allDetail
                })
            })
    }
    userViewHandler(allDetail) {
        getUserDetailByLoanId(allDetail.loanId,
            (callBack) => {
                this.setState({
                    viewDetailPopupState: true,
                    viewPopupDetail: callBack,
                    userDetail: allDetail
                })
            })
    }
    getviewDetailPopupState(state) {
        this.setState({
            viewDetailPopupState: state,
        })
    }

}

const mapStateToProps = ({ allUserDetail, loanApprovalLeadByPriority }) => {
    return {
        allUserDetail: allUserDetail,
        loanApprovalLeadByPriority: loanApprovalLeadByPriority,
    };
};

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        getistByNumber,
        getlistByEmail,
        getlistByApplication,
        getLoanApprovalLeadPriority

    }, dispatch);
};


export default connect(mapStateToProps, mapDispatchToProps)(CompanyLeadsContainer);