import React from 'react'
import Ui from '../../../presentationals/Admin/NbfcManagementNew/ProductDetails.component'
import { getNbfcProductByNbfcId } from '../AdminActionCreator.component';
import Loader from '../../../presentationals/Loader/Loader.component'
import ProductConfig from './addProductConfig'
import { APIS } from '../../../../utils/api-factory';
class Product extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            productData: '',
            noOfEntry: 10,
            pageNo: 1,
            loaderState: false,
            buttonDisable: false,
            view: 'list',
            nbfcData: '',
            count: ''
        }
    }
    componentDidMount() {
        if (this.props && this.props.nbfcData && this.props.nbfcData.nbfcId) {
            this.getProductData(this.props.nbfcData.nbfcId)
            fetch(APIS.GET_PRODUCT_NBFC_COUNT + this.props.nbfcData.nbfcId)
                .then(json => json.text())
                .then(res => {
                    this.setState({ count: res })
                })
        }
    }
    getProductData(data) {
        this.setState({ loaderState: true })
        getNbfcProductByNbfcId(data, this.state.pageNo, this.state.noOfEntry, callback => {
            this.setState({ productData: callback, loaderState: false })
            if (callback.length === 0 && this.state.pageNo > 1) {
                alert('last page')
                this.setState({
                    pageNo: this.state.pageNo - 1,
                    noOfEntry: 10,
                    buttonDisable: true
                }, () => this.getProductData(this.props.nbfcData.nbfcId))
            } else {
                this.setState({ buttonDisable: false })
            }
        })
    }

    paginationHandler(type) {
        if (type == 'left') {
            if (this.state.pageNo > 1) {
                this.setState({
                    pageNo: this.state.pageNo - 1,
                    noOfEntry: 10,
                }, () => this.getProductData(this.props.nbfcData.nbfcId))
            }
        }
        if (type == 'right') {
            if (this.state.productData.length >= 10) {
                this.setState({
                    pageNo: this.state.pageNo + 1,
                    noOfEntry: 10,
                }, () => this.getProductData(this.props.nbfcData.nbfcId))
            } else {

            }
        }
    }
    editHadler(data, type) {
        if (type === 'edit') {
            this.setState({ view: type, nbfcData: data })
        }
        if (type === 'list') {
            this.setState({ view: type })
        }
    }
    render() {
        return (
            <div>
                {this.state.loaderState ?
                    <Loader />
                    : ""}
                {this.state.view === 'edit' ?
                    <div className='row '>
                        <button className='profile-save-btn' style={{ marginTop: '50px', marginLeft: '20px' }} onClick={() => this.editHadler('', 'list')}>Back To Products</button>
                    </div>
                    : ""}
                {this.state.view === 'list' ?
                    < Ui
                        count={this.state.count}
                        editHadler={this.editHadler.bind(this)}
                        buttonDisable={this.state.buttonDisable}
                        paginationHandler={this.paginationHandler.bind(this)}
                        productData={this.state.productData} />
                    : ""}
                {this.state.view === 'edit' ?
                    <ProductConfig
                        nbfcData={this.state.nbfcData}
                    />
                    : ""}
            </div>
        )
    }
}
export default Product