import { data } from 'jquery';
import React from 'react'
import Nodata from '../../../../images/nodata.png'
import DatePicker from 'react-datepicker';

const Finbit = ({ fraudData, analysisData, bankDataFibit, datePickerState, finBitDataNew, typeHandler, viewFalse, type, finBitData, phocketTablePagination, tableMinPagination, tableMaxPagination, startDate, endDate, onChangeHandlerDate, searchHandler, finterChangeHandler }) => (

    <div className="container-fluid">

        {analysisData && analysisData.finBitDecision !== undefined ?
            <div className="row text-center" style={{ marginTop: '25px' }}>
                <h3>Finbit Decision:<b style={{ color: analysisData.finBitDecision === 'Rejected' ? 'red' : analysisData.finBitDecision === 'Approved' ? 'green' : 'yellow' }}>{analysisData.finBitDecision}</b></h3>
                {/* <h3 style={{ textDecorationLine: 'underline' }} className="text-center blue-text">Finbit Analysis</h3> */}
            </div>
            : ""}
        {analysisData !== '' && analysisData.rules !== '' && analysisData.rules !== null && analysisData.rules !== undefined&& analysisData.rules.length>0 ?
            <div className='row credit-table' >
                <table className="" style={{ width: '50%', marginLeft: 'auto', marginRight: 'auto', marginTop: '10px', marginBottom: '10px' }}>
                    <thead>
                        <tr>
                            <th>Rules </th>
                        </tr>

                    </thead>
                    <tbody>
                        {analysisData && analysisData.rules ? analysisData.rules.map((data, i) => {
                            return (
                                <tr key={i} >
                                    <td>{data} </td>

                                </tr>
                            )
                        }) : ""}

                    </tbody>
                </table>
            </div>
            : ""}
        {viewFalse ?
            <div>


                {datePickerState ?
                    <div className='row' style={{ marginBottom: '20px' }}>
                        <div className='col-sm-3 col-xs-12'>

                        </div>
                        <div className='col-sm-3 col-xs-12'>
                            <label style={{ fontSize: '12px' }}>Sart Date</label>
                            <DatePicker
                                value={startDate}
                                onChange={(e) => onChangeHandlerDate(e, 'startDate')}
                                className="form-control"
                                showYearDropdown
                                dateFormatCalendar="MMMM"
                                dateFormat="DD/MM/YYYY"
                                scrollableYearDropdown
                                yearDropdownItemNumber={50}
                            />
                        </div>
                        <div className='col-sm-3 col-xs-12'>
                            <label style={{ fontSize: '12px' }}>End Date</label>

                            <DatePicker
                                value={endDate}
                                onChange={(e) => onChangeHandlerDate(e, 'endDate')}
                                className="form-control"
                                showYearDropdown
                                dateFormatCalendar="MMMM"
                                dateFormat="DD/MM/YYYY"
                                scrollableYearDropdown
                                yearDropdownItemNumber={50}
                            />
                        </div>
                        <div className='col-sm-3 col-xs-12'>
                            <button className='btn btn default' style={{ background: '#00233C', color: '#fff', marginTop: '20px' }} onClick={searchHandler}>Search</button>
                        </div>

                    </div>
                    : ""}
                <div className='row income'>

                    <div className='container-fluid' style={{ background: '#0e5768', padding: '10px', borderTopLeftRadius: '15px', borderTopRightRadius: '15px' }} >
                        <div className='col-sm-4 col-xs-12' >
                            <select className="form-control " onChange={(e) => typeHandler(e)} style={{ borderRadius: '5px' }}>
                                <option value='transactionsDetail'>Transactions Detail</option>
                                <option value='averageMonthlyBalance'>Average Monthly Balance</option>
                                <option value='negativeBalanceTransactions'>Negative Balance Transactions</option>
                                <option value='income'>Income</option>
                                <option value='expenses'>Expenses</option>
                                <option value='creditCard'>CreditCard</option>
                                <option value='highValueTransactions'>HighValue Transactions</option>
                                <option value='moneyReceived'>Money Received</option>
                                <option value='moneyReceivedAnalysis'>Money Received Analysis</option>
                                <option value='moneyPaidAnalysis'>Money Paid Analysis</option>
                                {/* <option value='penaltyAnalysis'>Penalty Analysis</option> */}
                                <option value='loanRepayment'>Loan Repayment</option>
                                {/* <option value='averageUtilizationOcNCc'>Average Utilization OcNCc</option> */}
                                <option value='loanReceived'>loanReceived</option>
                                <option value='frequentTransaction'>Frequent Transaction</option>
                                {/* <option value='bounceTransactions'>Bounce Transactions</option> */}
                                {/* <option value='bounceTransactionsByDateRange'>Bounce Transactions By DateRange</option> */}
                                <option value='sanctionedAmount'>Sanctioned Amount</option>
                                <option value='ecsNNach'>EcsNNach</option>
                                <option value='creditCardPayment'>CreditCard Payment</option>
                                <option value='averageBankBalancePerMonth'>Average BankBalance Per Month</option>
                                <option value='monthSinceLastBounce'>Month Since Last Bounce</option>
                                <option value='checkInCompleteBankStatementDateDuration'>Check In Complete Bank Statement Date Duration</option>
                                <option value='summaryForSavingAccount'>Summary  for saving Account</option>

                                <option value='bankAccountSummary'>Bank Account Summary</option>

                            </select>
                        </div>
                    </div>
                    <div className='col-sm-12 col-xs-12 main-finbit-div' >
                        <div className='row'>
                            <div className='col-sm-4 col-xs-12' style={{ marginTop: '20px' }}>
                                <label className='' >Account Details For Account List</label>
                                <h5 ><b>{bankDataFibit != null && bankDataFibit != '' && bankDataFibit != undefined && bankDataFibit ? bankDataFibit.accountDetailsForAccountListPage : ""}</b></h5>
                            </div>
                            <div className='col-sm-4 col-xs-12' style={{ marginTop: '20px' }}>
                                <label className='' >Account Holder</label>
                                <h5 ><b>{bankDataFibit != null && bankDataFibit != '' && bankDataFibit != undefined ? bankDataFibit.accountHolder : ""}</b></h5>
                            </div>
                            <div className='col-sm-4 col-xs-12' style={{ marginTop: '20px' }}>
                                <label className='' >Account Limit</label>
                                <h5 ><b>{bankDataFibit != null && bankDataFibit != '' && bankDataFibit != undefined ? bankDataFibit.accountLimit : ""}</b></h5>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-sm-4 col-xs-12' style={{ marginTop: '20px' }}>
                                <label className='' >Account Number</label>
                                <h5 ><b>{bankDataFibit != null && bankDataFibit != '' && bankDataFibit != undefined ? bankDataFibit.accountNo : ""}</b></h5>
                            </div>
                            <div className='col-sm-4 col-xs-12' style={{ marginTop: '20px' }}>
                                <label className='' >Account Type</label>
                                <h5 ><b>{bankDataFibit != null && bankDataFibit != '' && bankDataFibit != undefined ? bankDataFibit.accountType.name : ""}</b></h5>
                            </div>
                            <div className='col-sm-4 col-xs-12' style={{ marginTop: '20px' }}>
                                <label className='' >IFSC CODE</label>
                                <h5 ><b>{bankDataFibit != null && bankDataFibit != '' && bankDataFibit != undefined ? bankDataFibit.ifsCode : ""}</b></h5>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-sm-4 col-xs-12' style={{ marginTop: '20px' }}>
                                <label className='' >Address </label>
                                <h5 ><b>{bankDataFibit != null && bankDataFibit != '' && bankDataFibit != undefined ? bankDataFibit.address : ""}</b></h5>
                            </div>
                            <div className='col-sm-4 col-xs-12' style={{ marginTop: '20px' }}>
                                <label className='' >Email</label>
                                <h5 ><b>{bankDataFibit != null && bankDataFibit != '' && bankDataFibit != undefined ? bankDataFibit.email : ""}</b></h5>
                            </div>
                            <div className='col-sm-4 col-xs-12' style={{ marginTop: '20px' }}>
                                <label className='' >Statement Upload</label>
                                <h5 ><b>{bankDataFibit != null && bankDataFibit != '' && bankDataFibit != undefined ? bankDataFibit.statementUpload : ""}</b></h5>
                            </div>
                        </div>
                    </div>

                </div>
                {type == 'summaryForSavingAccount' && fraudData ?
                    <div className='row credit-table' >
                        <table className="" style={{ width: '50%', marginLeft: 'auto', marginRight: 'auto', marginTop: '10px', marginBottom: '10px' }}>
                            <thead>
                                <tr>
                                    <th>Rule Name</th>
                                    <th>Status</th>
                                </tr>

                            </thead>
                            <tbody>
                                {fraudData && fraudData.FraudAnalytics.result ? fraudData.FraudAnalytics.result.map((data, i) => {
                                    return (
                                        <tr key={i} style={{ background: data.status === 'FAIL' ? 'red' : '#fff', color: data.status === 'FAIL' ? '#fff' : '#000' }}>
                                            <td>{data.ruleName} </td>
                                            <td>{data.status} </td>

                                        </tr>
                                    )
                                }) : ""}

                            </tbody>
                        </table>
                    </div>
                    : ""}

                {/* <br />   <br /> */}
                <div className='row' style={{ background: '#0e5768', padding: '10px', borderTopLeftRadius: '15px', borderTopRightRadius: '15px' }} >
                    {type == 'transactionsDetail' || type == 'income' || type == 'expenses' || type == 'creditCard' || type == 'highValueTransactions' || type == 'loanReceived' || type == 'frequentTransaction' || type == 'creditCardPayment' || type == 'ecsNNach' || type == 'summaryForSavingAccount' || type == 'moneyReceived' || type == 'loanRepayment' ?
                        <div className='col-sm-4 col-xs-12'>
                            <input style={{ borderRadius: '5px' }} placeholder='Enter Text To Search In Description' className='form-control' onChange={(e) => finterChangeHandler(e)} />
                        </div>
                        : ""}
                </div>
                <div className="row form-group overflow-auto credit-table-finbit">

                    <table className="" style={{ marginTop: '0px' }}>

                        <thead>
                            {type == 'transactionsDetail' ?
                                <tr>
                                    <th>Date</th>
                                    <th>Description</th>
                                    <th>Amount</th>
                                    <th>Type</th>
                                    <th>Balance After Transaction</th>
                                    <th>First Level Category</th>
                                    <th>Second Level Category</th>

                                </tr>
                                : ""}
                            {type == 'income' ?
                                <tr>
                                    <th>Date</th>
                                    <th>Description</th>
                                    <th>Amount</th>
                                    <td>Transaction Type</td>
                                    <th>Balance After Transaction</th>
                                    <th>Bank</th>
                                    <th>Category</th>


                                </tr>
                                : ""}
                            {type == 'expenses' ?
                                <tr>
                                    <th>Date</th>
                                    <th>Description</th>
                                    <th>Amount</th>
                                    <th>Bank</th>
                                    <th>Category</th>
                                </tr>
                                : ""}
                            {type == 'creditCard' ?
                                <tr>
                                    <th>Date</th>
                                    <th>Description</th>
                                    <th>Amount</th>
                                    <th>Bank</th>

                                    <th>Total</th>
                                </tr>
                                : ""}
                            {type == 'highValueTransactions' ?
                                <tr>
                                    <th>Date</th>
                                    <th>Description</th>
                                    <th>Amount</th>
                                    <th>Type</th>

                                    <th>Balance After Transcation</th>
                                    <th>Bank</th>
                                    <th>Category</th>

                                </tr>
                                : ""}
                            {(type == 'moneyReceivedAnalysis' || type == 'moneyPaidAnalysis') ?
                                <tr>
                                    <th>Atm Withdraw</th>
                                    <th>Cash</th>
                                    <th>Cheque</th>
                                    <th>DemandDraft</th>
                                    <th>EcsAndNach</th>
                                    <th>Imps</th>
                                    <th>InterBank Transfer</th>
                                    <th>Internal Amount</th>
                                    <th>Online Transfer</th>
                                    <th>Other</th>
                                    <th>Rtgs</th>
                                    <th>Total</th>
                                    <th>Total Transactions</th>
                                    <th>MonthAndYear</th>
                                    <th>Neft</th>
                                </tr>
                                : ""}
                            {type == 'loanReceived' ?
                                <tr>
                                    <th>Date</th>
                                    <th>Description</th>
                                    <th>Amount</th>
                                    <th>Balance</th>
                                    <th>Bank</th>

                                </tr>
                                : ""}
                            {type == 'frequentTransaction' ?
                                <tr>
                                    <th>Date</th>
                                    <th>Description</th>
                                    <th>Amount</th>
                                    <th>Bank</th>

                                </tr>
                                : ""}
                            {type == 'sanctionedAmount' ?
                                <tr>
                                    <th>Bank</th>
                                    <th>sanctionedAmount</th>
                                </tr>
                                : ""}
                            {type == 'ecsNNach' ?
                                <tr>
                                    <th>Date</th>
                                    <th>Description</th>
                                    <th>Amount</th>
                                    <th>TransactionType</th>
                                    <th>BalanceAfterTransaction</th>
                                    <th>Bank</th>

                                    <th>MonthAndYear</th>
                                    <th>Total</th>

                                </tr> : ""}
                            {type == 'creditCardPayment' ?
                                <tr>
                                    <th>Date</th>
                                    <th>Description</th>
                                    <th>Amount</th>
                                    <th>Bank</th>

                                    <th>MonthAndYear</th>
                                    <th>Total</th>
                                </tr> : ""}
                            {type == 'averageBankBalancePerMonth' ?
                                <tr>
                                    <th>Average Bank Balance Amount</th>
                                    <th>Bank</th>
                                    <th>FirstDayAmount</th>
                                    <th>FourteenDayAmount</th>
                                    <th>MonthAndYear</th>
                                    <th>ThirtyDayAmount</th >
                                </tr > : ""}
                            {type == 'bounceTransactions' ?
                                <tr>
                                    <th>InwardCheque Or EcsBounce Monthwise</th>
                                    <th>OutwardCheque Bounce Monthwise</th>

                                </tr > : ""}
                            {type == 'loanRepayment' ?
                                <tr>
                                    <th>Date</th>
                                    <th>Description</th>
                                    <th>Amount</th>
                                    <th>Type</th>
                                    <th>Balance After Transcation</th>
                                    <th>Bank</th>
                                    <th>Category</th>

                                </tr>
                                : ""}
                            {type == 'moneyReceived' ?

                                <tr >
                                    <th>Date</th>
                                    <th>Description</th>
                                    <th>Amount</th>
                                    <th>Transaction Type</th>
                                    <th>Balance After Transcation</th>
                                    <th>Bank</th>
                                    <th>Category</th>
                                    <th>MonthAndYear</th>
                                    <th>Total</th>



                                </tr>
                                : ""}
                            {type == 'summaryForSavingAccount' ?
                                <tr>
                                    <th>Date</th>
                                    <th>Description</th>
                                    <th>Amount</th>
                                    <th>Transaction Type</th>
                                    <th>Balance After Transaction</th>

                                    <th>Bank</th>
                                    <th>Category</th>

                                </tr>
                                : ""}
                        </thead >

                        <tbody>
                            {type === 'transactionsDetail' && finBitDataNew != null && finBitDataNew != '' && finBitDataNew != undefined ? finBitDataNew.slice(tableMinPagination, tableMaxPagination).map((detail, i) => {
                                //                          var result = Object.keys(detail).map((key) => detail[key]);


                                return (
                                    <tr key={i}>
                                        <td>{detail.dateTime}</td>
                                        <td>{detail.description}</td>
                                        <td>{detail.amount}</td>
                                        <td style={{ fontWeight: 'bold', color: detail.type === 'DEBIT' ? 'red' : 'green' }}>{detail.type}</td>

                                        <td>{detail.balanceAfterTransaction}</td>

                                        <td>{detail.firstLevelCategory}</td>
                                        <td>{detail.secondLevelCategory}</td>

                                    </tr>
                                )
                            })
                                : null}


                            {type == 'income' && finBitDataNew != null && finBitDataNew != '' && finBitDataNew != undefined && finBitDataNew.length > 0 ? finBitDataNew.slice(tableMinPagination, tableMaxPagination).map((detail, i) => {
                                return (
                                    <tr key={i}>
                                        <td>{detail.date}</td>
                                        <td>{detail.description}</td>
                                        <td>{detail.amount}</td>
                                        <td style={{ fontWeight: 'bold', color: detail.type === 'DEBIT' ? 'red' : 'green' }}>{detail.transactionType}</td>

                                        <td>{detail.balanceAfterTransaction}</td>

                                        <td>{detail.bank}</td>
                                        <td>{detail.category}</td>

                                        {/* <td>{detail.transactionType}</td> */}

                                    </tr>
                                )
                            })
                                : null}



                            {type == 'expenses' && finBitDataNew != null && finBitDataNew != '' && finBitDataNew != undefined && finBitDataNew.length > 0 ? finBitDataNew.slice(tableMinPagination, tableMaxPagination).map((detail, i) => {
                                return (
                                    <tr key={i}>
                                        <td>{detail.date}</td>
                                        <td>{detail.description}</td>
                                        <td>{detail.amount}</td>
                                        <td>{detail.bank}</td>
                                        <td>{detail.category}</td>



                                    </tr>
                                )
                            })
                                : ""}
                            {type == 'creditCard' && finBitDataNew != null && finBitDataNew != '' && finBitDataNew != undefined && finBitDataNew.length > 0 ? finBitDataNew.slice(tableMinPagination, tableMaxPagination).map((detail, i) => {
                                return (
                                    <tr key={i}>
                                        <td>{detail.date}</td>
                                        <td>{detail.description}</td>
                                        <td>{detail.amount}</td>
                                        <td>{detail.bank}</td>

                                        <td>{detail.total}</td>


                                    </tr>
                                )
                            })
                                : null}
                            {type == 'highValueTransactions' && finBitDataNew != null && finBitDataNew != '' && finBitDataNew != undefined && finBitDataNew.length > 0 ? finBitDataNew.slice(tableMinPagination, tableMaxPagination).map((detail, i) => {
                                return (
                                    <tr key={i}>
                                        <td>{detail.date}</td>
                                        <td>{detail.description}</td>
                                        <td>{detail.amount}</td>
                                        <td style={{ fontWeight: 'bold', color: detail.type === 'DEBIT' ? 'red' : 'green' }}>{detail.type}</td>
                                        <td>{detail.balanceAfterTranscation}</td>
                                        <td>{detail.bank}</td>
                                        <td>{detail.category}</td>

                                        {/* <td>{detail.type}</td> */}




                                    </tr>
                                )
                            })
                                : null}
                            {type == 'loanRepayment' && finBitDataNew != null && finBitDataNew != '' && finBitDataNew != undefined ? finBitDataNew.slice(tableMinPagination, tableMaxPagination).map((detail, i) => {
                                return (
                                    <tr key={i}>
                                        <td>{detail.dateTime}</td>
                                        <td>{detail.description}</td>
                                        <td>{detail.amount}</td>
                                        <td style={{ fontWeight: 'bold', color: detail.type === 'DEBIT' ? 'red' : 'green' }}>{detail.transactionType}</td>
                                        <td>{detail.balanceAfterTransaction}</td>
                                        <td>{detail.bank}</td>
                                        <td>{detail.category}</td>

                                        {/* <td>{detail.transactionType}</td> */}




                                    </tr>
                                )
                            })
                                : null}
                            {(type == 'moneyReceivedAnalysis' || type == 'moneyPaidAnalysis') && finBitData != null && finBitData != '' && finBitData != undefined && finBitData.length > 0 ? finBitData.slice(tableMinPagination, tableMaxPagination).map((detail, i) => {
                                return (
                                    <tr key={i}>
                                        <td>{detail.atmWithdraw}</td>
                                        <td>{detail.cash}</td>
                                        <td>{detail.cheque}</td>
                                        <td>{detail.demandDraft}</td>
                                        <td>{detail.ecsAndNach}</td>
                                        <td>{detail.imps}</td>
                                        <td>{detail.interBankTransfer}</td>
                                        <td>{detail.internalAmount}</td>
                                        <td>{detail.onlineTransfer}</td>
                                        <td>{detail.other}</td>
                                        <td>{detail.rtgs}</td>
                                        <td>{detail.total}</td>
                                        <td>{detail.totalTransactions}</td>
                                        <td>{detail.monthAndYear}</td>
                                        <td>{detail.neft}</td>



                                    </tr>
                                )
                            })
                                : null}

                            {type == 'moneyReceived' && finBitDataNew != null && finBitDataNew != '' && finBitDataNew != undefined ? finBitDataNew.slice(tableMinPagination, tableMaxPagination).map((detail, i) => {
                                return (
                                    <tr key={i}>
                                        <td>{detail.date}</td>
                                        <td>{detail.description}</td>
                                        <td>{detail.amount}</td>
                                        <td style={{ fontWeight: 'bold', color: detail.type === 'DEBIT' ? 'red' : 'green' }}>{detail.transactionType}</td>
                                        <td>{detail.balanceAfterTransaction}</td>
                                        <td>{detail.bank}</td>
                                        <td>{detail.category}</td>
                                        <td>{detail.monthAndYear}</td>
                                        <td>{detail.total}</td>

                                        {/* <td>{detail.transactionType}</td> */}


                                    </tr>
                                )
                            })
                                : null}
                            {type == 'loanReceived' && finBitDataNew != null && finBitDataNew != '' && finBitDataNew != undefined ? finBitDataNew.slice(tableMinPagination, tableMaxPagination).map((detail, i) => {
                                return (
                                    <tr key={i}>
                                        <td>{detail.date}</td>
                                        <td>{detail.description}</td>
                                        <td>{detail.amount}</td>
                                        <td>{detail.balance}</td>
                                        <td>{detail.bank}</td>



                                    </tr>
                                )
                            })
                                : null}
                            {type == 'frequentTransaction' && finBitDataNew != null ? finBitDataNew.slice(tableMinPagination, tableMaxPagination).map((detail, i) => {
                                return (
                                    <tr key={i}>
                                        <td>{detail.date}</td>
                                        <td>{detail.description}</td>
                                        <td>{detail.amount}</td>
                                        <td>{detail.bank}</td>



                                    </tr>
                                )
                            })
                                : null}
                            {type == 'sanctionedAmount' && finBitData != null && finBitData != '' && finBitData != undefined && finBitData.length > 0 ? finBitData.slice(tableMinPagination, tableMaxPagination).map((detail, i) => {
                                return (
                                    <tr key={i}>

                                        <td>{detail.bank}</td>
                                        <td>{detail.sanctionedAmount}</td>


                                    </tr>
                                )
                            })
                                : null}
                            {type == 'ecsNNach' && finBitDataNew != null && finBitDataNew != '' && finBitDataNew != undefined && finBitDataNew.length > 0 ? finBitDataNew.slice(tableMinPagination, tableMaxPagination).map((detail, i) => {
                                return (
                                    <tr key={i}>
                                        <td>{detail.date}</td>
                                        <td>{detail.description}</td>
                                        <td>{detail.amount}</td>
                                        <td style={{ fontWeight: 'bold', color: detail.type === 'DEBIT' ? 'red' : 'green' }}>{detail.transactionType}</td>
                                        <td>{detail.balanceAfterTransaction}</td>
                                        <td>{detail.bank}</td>
                                        <td>{detail.monthAndYear}</td>
                                        <td>{detail.total}</td>


                                    </tr>
                                )
                            })
                                : null}
                            {type == 'creditCardPayment' && finBitDataNew != null && finBitDataNew != '' && finBitDataNew != undefined && finBitDataNew.length > 0 ? finBitDataNew.slice(tableMinPagination, tableMaxPagination).map((detail, i) => {
                                return (
                                    <tr key={i}>
                                        <td>{detail.date}</td>
                                        <td>{detail.description}</td>
                                        <td>{detail.amount}</td>
                                        <td>{detail.bank}</td>
                                        <td>{detail.monthAndYear}</td>
                                        <td>{detail.total}</td>


                                    </tr>
                                )
                            })
                                : null}
                            {type == 'averageBankBalancePerMonth' && finBitData != null && finBitData != '' && finBitData != undefined && finBitData.length > 0 ? finBitData.slice(tableMinPagination, tableMaxPagination).map((detail, i) => {
                                return (
                                    <tr key={i}>
                                        <td>{detail.averageBankBalanceAmount}</td>
                                        <td>{detail.bank}</td>
                                        <td>{detail.firstDayAmount}</td>
                                        <td>{detail.fourteenDayAmount}</td>
                                        <td>{detail.monthAndYear}</td>
                                        <td>{detail.thirtyDayAmount}</td>


                                    </tr>
                                )
                            })
                                : null}



                            {type == 'summaryForSavingAccount' && finBitDataNew != null && finBitDataNew != '' && finBitDataNew != undefined ? finBitDataNew.slice(tableMinPagination, tableMaxPagination).map((detail, i) => {
                                //                          var result = Object.keys(detail).map((key) => detail[key]);


                                return (
                                    <tr key={i}>
                                        <td>{detail.dateTime}</td>
                                        <td>{detail.description}</td>
                                        <td>{detail.amount}</td>
                                        <td style={{ fontWeight: 'bold', color: detail.type === 'DEBIT' ? 'red' : 'green' }}>{detail.type}</td>

                                        <td>{detail.balanceAfterTransaction}</td>

                                        <td>{detail.bank}</td>
                                        <td>{detail.category}</td>
                                        {/* <td>{detail.type}</td> */}


                                    </tr>
                                )
                            })
                                : null}
                        </tbody>
                    </table >
                </div >
                {type === 'transactionsDetail' && finBitData != null ?
                    <div >

                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-sm-5 col-xs-12 no-padding"><button type="button" className="btn btn-default fa fa-chevron-left btn-lg btn-block   " style={{ background: '#BADBF9', borderRadius: '0' }} onClick={e => phocketTablePagination(finBitData.transactions !== undefined ? finBitData.transactions : finBitDataNew, 'back')}></button></div>
                                {/* {finBitData.length  0 ? */}
                                <div className="col-sm-2 col-xs-12  phoc text-center" >{(tableMinPagination + 1) + ' - ' + (tableMaxPagination) + ' of '} {finBitDataNew.length}</div>

                                {/* : ""} */}
                                <div className="col-sm-5 col-xs-12 no-padding" ><button type="button" className="btn btn-default fa fa-chevron-right btn-lg btn-block " style={{ background: '#BADBF9', borderRadius: '0' }} onClick={e => phocketTablePagination(finBitData.transactions !== undefined ? finBitData.transactions : finBitDataNew, 'forward')}></button></div>
                            </div>
                        </div>
                    </div> : ""}
                {(type !== 'transactionsDetail') && (finBitDataNew ? finBitDataNew.length > 20 : finBitData ? finBitData.length > 20 : '') ?
                    <div >
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-sm-5 col-xs-12 no-padding"><button type="button" className="btn btn-default fa fa-chevron-left btn-lg btn-block " style={{ background: '#BADBF9', borderRadius: '0' }} onClick={e => phocketTablePagination(finBitData.transactions !== undefined ? finBitData.transactions : finBitDataNew, 'back')}></button></div>
                                <div className="col-sm-2 col-xs-12  phoc text-center" >{(tableMinPagination + 1) + ' - ' + (tableMaxPagination) + ' of '} {finBitDataNew.length}</div>
                                <div className="col-sm-5 col-xs-12 no-padding" ><button type="button" className="btn btn-default fa fa-chevron-right btn-lg btn-block " style={{ background: '#BADBF9', borderRadius: '0' }} onClick={e => phocketTablePagination(finBitData.transactions !== undefined ? finBitData.transactions : finBitDataNew, 'forward')}></button></div>
                            </div>
                        </div>
                    </div> : ""
                }
            </div>
            : <h3 style={{ marginTop: '40px' }} className="text-center"><img src={Nodata} width="300px" /></h3>}
    </div >
)
export default Finbit