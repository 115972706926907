import React from "react";

const Kyc = ({ kycStateHandler, personalData, kycSubStatePan, kycSubStateAAdhaar, kycSubStateLocation }) => (
    <>
        <div className="row"  >
            <div style={{ border: '0.5px solid #E9ECF5', width: '80%', marginLeft: 'auto', marginRight: 'auto', marginBottom: '10px' }}></div>
            <div className="col-sm-2">
                <div><div style={{ background: '#DBE9FF', width: '2px', marginLeft: 'auto', marginRight: 'auto', height: '40px', marginTop: '-20px' }}>

                </div>
                </div>
            </div>

            <div className="col-sm-6" onClick={() => kycStateHandler('', 'pan')} style={{ fontSize: '12px', cursor: 'pointer', color: kycSubStatePan === true && kycSubStateAAdhaar === false && kycSubStateLocation === false ? '#2B78FF' : '#000', fontWeight: kycSubStatePan === true && kycSubStateAAdhaar === false && kycSubStateLocation === false ? '700' : '500' }}>

                PAN Verification</div>

            <div className="col-sm-4 text-right" style={{ fontSize: '12px', cursor: 'pointer' }} onClick={() => kycStateHandler('', 'pan')}>

                {personalData && personalData.panMatched ? <span style={{ color: '#4CC78C', background: '#E1F6EC', borderRadius: '4px', padding: '2px 8px', marginRight: '5px', fontSize: '10px' }}><i className="fa fa-check-circle"></i>&nbsp;Verified</span> : <span style={{ color: '#FF5C5D', background: '#FFEFF2', borderRadius: '4px', padding: '2px', fontSize: '10px', marginRight: '5px' }}><i className="fa fa-times-circle"></i>&nbsp;Unverified</span>}
                <i className="fa fa-chevron-right" style={{ color: '#2B78FF', marginRight: '10px' }}></i>
            </div>
        </div>

        <div className="row" >
            <div className="col-sm-2">
                <div><div style={{ background: '#DBE9FF', width: '2px', marginLeft: 'auto', marginRight: 'auto', height: '40px', marginTop: '-20px' }}></div>
                </div>
            </div>

            <div className="col-sm-6" style={{ fontSize: '12px', marginTop: '20px', cursor: 'pointer', color: kycSubStatePan === false && kycSubStateAAdhaar === true && kycSubStateLocation === false ? '#2B78FF' : '#000', fontWeight: kycSubStatePan === false && kycSubStateAAdhaar === true && kycSubStateLocation === false ? '700' : '500' }} onClick={() => kycStateHandler('', 'aadhaar')}>

                Aadhaar Verification</div>

            <div className="col-sm-4 text-right" style={{ fontSize: '12px', marginTop: '20px', cursor: 'pointer' }} onClick={() => kycStateHandler('', 'aadhaar')}>
                {personalData && personalData.aadhaarMatch ? <span style={{ color: '#4CC78C', background: '#E1F6EC', borderRadius: '4px', padding: '2px 8px', marginRight: '5px ', fontSize: '10px' }}><i className="fa fa-check-circle"></i>&nbsp;Verified</span> : <span style={{ color: '#FF5C5D', background: '#FFEFF2', borderRadius: '4px', padding: '2px', fontSize: '10px', marginRight: '5px' }}><i className="fa fa-times-circle"></i>&nbsp;Unverified</span>}
                <i className="fa fa-chevron-right" style={{ color: '#2B78FF', marginRight: '10px' }}></i></div>
        </div>
        <div className="row" >
            <div className="col-sm-2">
                <div><div style={{ background: '#DBE9FF', width: '2px', marginLeft: 'auto', marginRight: 'auto', height: '40px', marginTop: '-20px' }}></div>
                </div>
            </div>

            <div className="col-sm-6" style={{ fontSize: '12px', marginTop: '20px', cursor: 'pointer', color: kycSubStatePan === false && kycSubStateAAdhaar === false && kycSubStateLocation === true ? '#2B78FF' : '#000', fontWeight: kycSubStatePan === false && kycSubStateAAdhaar === false && kycSubStateLocation === true ? '700' : '500' }} onClick={() => kycStateHandler('', 'location')}>

                Location Verification</div>

            <div className="col-sm-4 text-right" style={{ fontSize: '12px', marginTop: '20px', cursor: 'pointer' }} onClick={() => kycStateHandler('', 'location')}>
                {personalData && personalData.addressMatch ? <span style={{ color: '#4CC78C', background: '#E1F6EC', borderRadius: '4px', padding: '2px 8px', marginRight: '5px', fontSize: '10px' }}><i className="fa fa-check-circle"></i>&nbsp;Verified</span> : <span style={{ color: '#FF5C5D', background: '#FFEFF2', borderRadius: '4px', padding: '2px', fontSize: '10px', marginRight: '5px' }}><i className="fa fa-times-circle"></i>&nbsp;Unverified</span>}

                <i className="fa fa-chevron-right" style={{ color: '#2B78FF', marginRight: '10px' }}></i></div>
        </div>
        <div className="row" >
            <div className="col-sm-2">
                <div><div style={{ background: '#DBE9FF', width: '2px', marginLeft: 'auto', marginRight: 'auto', height: '80px', marginTop: '-20px' }}></div>
                </div>
            </div>

            <div className="col-sm-6" style={{ fontSize: '12px', marginTop: '20px', cursor: 'pointer', color: kycSubStatePan === true && kycSubStateAAdhaar === true && kycSubStateLocation === true ? '#2B78FF' : '#000', fontWeight: kycSubStatePan === true && kycSubStateAAdhaar === true && kycSubStateLocation === true ? '700' : '500' }} onClick={() => kycStateHandler('', 'kycall')}>

                Detailed View</div>

            <div className="col-sm-4 text-right" style={{ fontSize: '12px', marginTop: '20px', cursor: 'pointer' }} onClick={() => kycStateHandler('', 'kycall')}>

                <i className="fa fa-chevron-right" style={{ color: '#2B78FF', marginRight: '10px' }}></i></div>
        </div>
    </>
)
export default Kyc