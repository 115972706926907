import React, { useEffect, useState } from "react";
import { getExpenseTarget } from "../../targetactioncreator";
import BarChartComponent from "../BarChart";
const TargetList = ({ editHandler,ActiveMonthFilter }) => {
    // const expenseData = {
    //     "categoryId": "123",
    //     "categoryName": "Utilities",
    //     "subCategoryId": "456",
    //     "subCategoryName": "Electricity",
    //     "targetAmount": 500.0,
    //     "targetPercentageASPerAUM": 5.0,
    //     "currentAmount": 450.0,
    //     "currentPercentageASPerAUM": 4.5,
    //     "deviationAmount": 50.0,
    //     "deviationPercentageASPerAUM": 0.5,
    //     "comment": "Regular monthly payment",
    //     "active": true,
    //     id: "2"
    // };
    const [expenseData, setExepensedata] = useState('')
    const [showgraph,setshowGraph] =useState(false)
    useEffect(() => {
        getExpenseTarget(callback => {
            setExepensedata(callback.data)
        })
    }, [ActiveMonthFilter])
    const handleDelete = () => {
        // Implement delete logic here
    };

    const handleSelectedData =()=>{
        setshowGraph(!showgraph)
    }

    return (
        <div className="container-fluid Executor">
            <div className="row">
                <div className={`${showgraph ? "col-md-6 col-xs-12" : 'col-xs-12'} transition-col`}>
                    <div
                        style={{
                            background: '#f2f2f2',
                            padding: '10px',
                            marginTop: '50px',
                            borderRadius: '5px',
                            overflow: 'auto',
                            // maxWidth: '1000px'
                        }}
                    >
                        <div>
                            <table className='table-logs' style={{ marginBottom: '10px', width: '100%', borderCollapse: 'collapse' }}>
                                <thead>
                                    <tr>
                                        <th>View</th>
                                        <th>Sub Category Name</th>
                                        <th>Target Amount</th>
                                        <th>Target % AUM</th>
                                        <th>Current Amount</th>
                                        <th>Current % AUM</th>
                                        <th>Deviation Amount</th>
                                        <th>Deviation % AUM</th>
                                        <th>Comment</th>
                                        <th>Edit</th>
                                        <th>Delete</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {expenseData!==''&&expenseData.length>0?expenseData.map((data,i)=>{
                                        return(
                                            <tr style={{ background: '#fff', padding: '5px', borderRadius: '5px' }}>
                                            <td><h5 onClick={()=>handleSelectedData()} style={{cursor:'pointer'}}><b>i</b></h5></td>
                                            <td>{data.subCategoryName}</td>
                                            <td>{data.targetAmount}</td>
                                            <td>{data.targetPercentageASPerAUM}%</td>
                                            <td>{data.currentAmount}</td>
                                            <td>{data.currentPercentageASPerAUM}%</td>
                                            <td>{data.deviationAmount}</td>
                                            <td>{data.deviationPercentageASPerAUM}%</td>
                                            <td>{data.comment}</td>
                                            <td>
                                                <i
                                                    className="fa fa-pencil-square-o fa-lg"
                                                    aria-hidden="true"
                                                    onClick={() => editHandler(data)}
                                                    style={{ cursor: 'pointer' }}
                                                    aria-label="Edit Expense"
                                                    title="Edit Expense"
                                                ></i>
                                            </td>
                                            <td>
                                                <i
                                                    className="fa fa-trash-o fa-lg"
                                                    aria-hidden="true"
                                                    onClick={() => handleDelete(data)}
                                                    style={{ cursor: 'pointer' }}
                                                    aria-label="Delete Expense"
                                                    title="Delete Expense"
                                                ></i>
                                            </td>
                                        </tr>
                                        )
                                    }):""}
                                  
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                {
                    showgraph ?
                    <div className="col-md-6 col-xs-12">
                    <BarChartComponent Data={expenseData} ActiveMonthFilter={ActiveMonthFilter} Heading={'Data'}/>
                </div>
                :''}
            </div>
        </div>
    );
};

export default TargetList;
