import React, { Component } from 'react'
import AddNBFCUi from '../../../presentationals/Admin/Utility/AddNbfc.component';
import { APIS } from '../../../../utils/api-factory'
import Popup from '../../../presentationals/Popup/Popup.component';

let paginationNumber = 10;

class AddNbfc extends Component {
    constructor(props) {
        super(props);
        this.state = {
            allNbfcList: [],
            searchState: '',
            tableMinPagination: 0,
            tableMaxPagination: paginationNumber,
            popupState: false,
            popupStatus: '',
            newNbfc: ''
        }
    }

    componentDidMount() {
        this.getNbfcList()
    }

    getNbfcList() {
        fetch(APIS.GET_LIST_OF_NBFC)
            .then(res => res.json())
            .then(res => {
                this.setState({
                    allNbfcList: res
                })
            })
    }

    render() {
        let allNbfcList = this.state.allNbfcList
        // if (this.state.searchState) {
        //     allNbfcList = allNbfcList.filter(row => {
        //         if (row != null) {
        //             return row.toLowerCase().includes(this.state.searchState.toLowerCase())
        //         }
        //     })
        // }
        return (
            <div>
                <AddNBFCUi
                    allNbfcList={allNbfcList}
                    searchHandler={this.searchHandler.bind(this)}
                    phocketTablePagination={this.phocketTablePagination.bind(this)}
                    tableMinPagination={this.state.tableMinPagination}
                    tableMaxPagination={this.state.tableMaxPagination}
                    addNbfcHandler={this.addNbfcHandler.bind(this)}
                    addNbfcChange={this.addNbfcChange.bind(this)}
                    newNbfc={this.state.newNbfc} />
                {this.state.popupState == true ?
                    <Popup
                        closePopup={this.closePopup.bind(this)}
                        popupStatus={this.state.popupStatus}
                    />
                    : ""}
            </div>
        )
    }

    addNbfcChange(e) {
        this.setState({ newNbfc: e.target.value });
    }

    addNbfcHandler() {

        fetch(APIS.SAVE_NBFC + this.state.newNbfc + '&razorpayApi=&razorpaySecret=')
            .then(res => res.text())
            .then(res => {
                if (res == 'success') {
                    this.setState({
                        popupState: true,
                        popupStatus: "Added successfully!",
                        newNbfc: ''
                    })
                    this.getNbfcList()
                    this.removePopup();
                } else {
                    this.setState({
                        popupState: true,
                        popupStatus: "Please try again",
                    })
                    this.removePopup();
                }
            })
    }


    phocketTablePagination(data, type) {
        if (type == 'back') {
            if (this.state.tableMinPagination >= paginationNumber) {
                this.setState({
                    tableMinPagination: this.state.tableMinPagination - paginationNumber,
                    tableMaxPagination: this.state.tableMaxPagination - paginationNumber,
                })
            }
        } else if (type == 'forward') {
            if (this.state.tableMaxPagination < data.length) {
                this.setState({
                    tableMaxPagination: this.state.tableMaxPagination + paginationNumber,
                    tableMinPagination: this.state.tableMinPagination + paginationNumber
                })
            }
        }
    }

    searchHandler(e) {
        this.setState({
            searchState: e.target.value,
            tableMinPagination: 0,
            tableMaxPagination: paginationNumber,
        })
    }

    removePopup() {
        setTimeout(function () {
            this.setState({ popupState: false });
        }.bind(this), 5000)
    }

    closePopup() {
        this.setState({ popupState: false });
    }
}

export default AddNbfc;