import React, { Component } from 'react'
import DocumentMail from '../../../presentationals/Admin/CustomerPages/Mail.component'
import Popup from '../../../presentationals/Popup/Popup.component';
import { STRING, MAIL_STRING } from '../../../../utils/Constant';
import localStorageUtils from '../../../../utils/localStorageUtil'
import { pushNotification, saveImportantLoans, giftCardpushNotification } from '../AdminActionCreator.component'
import StatusChangePopup from '../../../presentationals/Popup/StatusChangeToComunicate'
import { APIS } from '../../../../utils/api-factory'
import ConfirmationPopup from '../../../presentationals/Popup/ConfirmationPopup.component';
import { WEB_URL } from '../../../../utils/api-factory'

class DocumentPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            popupState: false,
            popupStatus: '',
            allDocState: true,
            title: '',
            comment: '',
            slryTextState: false,
            adharTextState: false,
            panTextState: false,
            bankTextState: false,
            executiveContactState: false,
            salarySlipTextState: false,
            officeIdTextState: false,
            CurrentAddressProofState: false,
            isStatusChange: 'false',
            statusChangePopup: false,
            mailBody: '',
            whatsappMsg: '',
            mailViewState: '',
            halfSalaryState: '',
            mailType: '',
            documentsData: '',
            link: '',
            approveAmount: '',
            confirmationText: "Are You Sure To Send Mail?",
            confirmpopup: false,
            commentType:''


        }
    }
    commentHandler(e){
        this.setState({commentType:e.target.value})
    }
    saveComment(data) {
        const { admin,allDetail } = this.props
        pushNotification(allDetail, this.state.commentType, '', admin.emailId, "comment", '', '', '', '', '',
            (callBack) => {
                if (callBack == 'success') {
                    this.setState({
                        popupState: true,
                        popupStatus: 'Comment Saved Successfully',
                        commentType: ''
                    })
                    this.removePopup();
                } else {
                    this.setState({
                        popupState: true,
                        popupStatus: 'Please try again',
                        commentType: ''
                    })
                    this.removePopup();
                }
            })
    }

    render() {
        const { allDetail, approveAmount } = this.props
        return (
            <div>
                {this.state.popupState == true ?
                    <Popup
                        closePopup={this.closePopup.bind(this)}
                        popupStatus={this.state.popupStatus}
                    />
                    : ""}
                <DocumentMail
                    approveAmount={approveAmount}
                    link={this.state.link}
                    sendHandler={this.sendHandler.bind(this)}
                    title={this.state.title}
                    DocsCheckHandler={this.DocsCheckHandler.bind(this)}
                    messageHandler={this.messageHandler.bind(this)}
                    allDocState={this.state.allDocState}
                    allDetail={allDetail}
                    slryTextState={this.state.slryTextState}
                    adharTextState={this.state.adharTextState}
                    panTextState={this.state.panTextState}
                    bankTextState={this.state.bankTextState}
                    salarySlipTextState={this.state.salarySlipTextState}
                    officeIdTextState={this.state.officeIdTextState}
                    CurrentAddressProofState={this.state.CurrentAddressProofState}
                    executiveContactState={this.state.executiveContactState}
                    mailTempleteClick={this.mailTempleteClick.bind(this)}
                    mailViewState={this.state.mailViewState}
                    halfSalaryState={this.state.halfSalaryState}
                />
                {this.state.statusChangePopup == true ?
                    <StatusChangePopup
                    commentHandler={this.commentHandler.bind(this)}
                        checkStatusChange={this.checkStatusChange.bind(this)}
                        checkStatusHandler={this.checkStatusHandler.bind(this)}
                        markAsImportant={this.markAsImportant.bind(this)}
                        allDetail={allDetail}
                        isStatusChange={this.state.isStatusChange}
                        closeStatusPopup={this.closeStatusPopup.bind(this)} />
                    : ""}
                {this.state.confirmpopup ?

                    <ConfirmationPopup cancelLoanConfirm={this.newmailSendHandler.bind(this)} confirmationText={this.state.confirmationText} />
                    : ""}
            </div>
        )
    }

    closeStatusPopup() {
        this.setState({ statusChangePopup: false })
    }

    markAsImportant(isImportant) {
        const { allDetail } = this.props
        saveImportantLoans(allDetail, isImportant,
            (callBack) => {
                if (callBack == 'success') {
                    this.setState({
                        popupState: true,
                        popupStatus: 'Application Marked as Important',
                    })
                    this.removePopup();
                }
            })
    }

    mailTempleteClick(e, mailType) {

        this.setState({
            mailViewState: mailType,
            title: e.target.value,
            mailType: mailType
        })
    }

    DocsCheckHandler(e, type) {
        if (type == 'adhar') {
            if (e.target.checked) {
                this.setState({ adharTextState: true })
            } else {
                this.setState({ adharTextState: false })
            }
        } else if (type == 'pan') {
            if (e.target.checked) {
                this.setState({ panTextState: true })
            } else {
                this.setState({ panTextState: false })
            }
        } else if (type == 'bank') {
            if (e.target.checked) {
                this.setState({ bankTextState: true })
            } else {
                this.setState({ bankTextState: false })
            }
        } else if (type == 'salary') {
            if (e.target.checked) {
                this.setState({ salarySlipTextState: true })
            } else {
                this.setState({ salarySlipTextState: false })
            }
        } else if (type == 'officeId') {
            if (e.target.checked) {
                this.setState({ officeIdTextState: true })
            } else {
                this.setState({ officeIdTextState: false })
            }
        } else if (type == 'halfSalary') {
            if (e.target.checked) {
                this.setState({ halfSalaryState: true })
            } else {
                this.setState({ halfSalaryState: false })
            }
        }
        else if (type == 'CurrentAddressProof') {
            if (e.target.checked) {
                this.setState({ CurrentAddressProofState: true })
            } else {
                this.setState({ CurrentAddressProofState: false })
            }
        }
    }

    componentDidMount() {
        const { allDetail, approveAmount } = this.props
        this.setState({ link: WEB_URL + 'userReference/' + allDetail.userId })
        fetch(APIS.GET_USER_DOCUMENTS_BY_USER_ID + allDetail.userId)
            .then(res => res.json())
            .then(res => {
                this.setState({ documentsData: res, loaderState: false })
            })
        if (this.state.documentsData.documentList != null) {

            if (this.state.documentsData.documentList.adharCardUrl == null) {
                if (document.getElementById("AadhaarMail") != null) {
                    document.getElementById("AadhaarMail").classList.add("red-text")
                    document.getElementById("docAdharCHeck").checked = true
                    this.setState({ adharTextState: true })
                }
            } if (this.state.documentsData.documentList.panCardUrl == null) {
                if (document.getElementById("PANMail") != null) {
                    document.getElementById("PANMail").classList.add("red-text")
                    document.getElementById("docPanCHeck").checked = true
                    this.setState({ panTextState: true })
                }
            } if (this.state.documentsData.documentList.bankStatementUrl == null) {
                if (document.getElementById("BankMail") != null) {
                    document.getElementById("BankMail").classList.add("red-text")
                    document.getElementById("docBankCHeck").checked = true
                    this.setState({ bankTextState: true })
                }
            } if (this.state.documentsData.documentList.salaryProofUrl == null) {
                if (document.getElementById("SalaryMail") != null) {
                    document.getElementById("SalaryMail").classList.add("red-text")
                    document.getElementById("docSalaryCHeck").checked = true
                    this.setState({ salarySlipTextState: true })
                }
            } if (this.state.documentsData.documentList.photographUrl == null) {
                if (document.getElementById("OfficeIdMail") != null) {
                    document.getElementById("OfficeIdMail").classList.add("red-text")
                    // document.getElementById("docOfficeIdCHeck").checked = true
                    this.setState({ officeIdTextState: true })
                }
            }
            if (this.state.documentsData.documentList.currentAddressUrl == null) {
                if (document.getElementById("CurrentAddressProof") != null) {
                    document.getElementById("CurrentAddressProof").classList.add("red-text")
                    document.getElementById("CurrentAddressProofId").checked = true
                    this.setState({ officeIdTextState: true })
                }
            }
        } else {
            if (document.getElementById("AadhaarMail") != null) {
                document.getElementById("AadhaarMail").classList.add("red-text")
            }
            if (document.getElementById("PANMail") != null) {
                document.getElementById("PANMail").classList.add("red-text");
            }
            if (document.getElementById("BankMail") != null) {
                document.getElementById("BankMail").classList.add("red-text");
            }
            if (document.getElementById("SalaryMail") != null) {
                document.getElementById("SalaryMail").classList.add("red-text")
            }
            if (document.getElementById("OfficeIdMail") != null) {
                document.getElementById("OfficeIdMail").classList.add("red-text")
            }
            if (document.getElementById("CurrentAddressProof") != null) {
                document.getElementById("CurrentAddressProof").classList.add("red-text")
            }

            document.getElementById("docAdharCHeck").checked = true
            document.getElementById("docPanCHeck").checked = true
            document.getElementById("docBankCHeck").checked = true
            document.getElementById("docSalaryCHeck").checked = true
            // document.getElementById("docOfficeIdCHeck").checked = true
            document.getElementById("CurrentAddressProofId").checked = true

            this.setState({
                adharTextState: true,
                panTextState: true,
                bankTextState: true,
                salarySlipTextState: true,
                officeIdTextState: true,
                CurrentAddressProofState: true
            })
        }
    }

    removePopup() {
        setTimeout(function () {
            this.setState({ popupState: false });
        }.bind(this), 5000)
    }

    closePopup() {
        this.setState({ popupState: false });
    }
    messageHandler(e, type) {
        if (type === "title") {
            this.setState({ title: e.target.value });
        }
    }

    checkStatusChange(e) {
        this.setState({ isStatusChange: e.target.value })
    }

    checkStatusHandler() {
        const { allDetail, admin, userDetail } = this.props;
        if (this.state.isStatusChange != '') {
            if (userDetail.typeOfProduct == 'Loan') {
                pushNotification(allDetail, this.state.mailBody, this.state.title, admin.emailId, STRING.COMMUNICATED, this.state.isStatusChange, this.state.notificationImage, this.state.mailType, this.state.whatsappMsg, '',
                    (callBack) => {

                        if (callBack == 'success') {
                            this.setState({
                                popupState: true,
                                popupStatus: 'Documents Mail Sent Successfully',
                                statusChangePopup: false,
                                isStatusChange: ''
                            })
                            this.removePopup();
                        } else {
                            this.setState({
                                popupState: true,
                                popupStatus: 'Documents Mail not sent',
                                statusChangePopup: false,
                                isStatusChange: ''
                            })
                            this.removePopup();
                        }
                    })
            } else if (userDetail.typeOfProduct == 'Gift Card') {
                giftCardpushNotification(userDetail, this.state.mailBody, this.state.title, admin.emailId, STRING.COMMUNICATED, this.state.isStatusChange, this.state.notificationImage, this.state.mailType, this.state.whatsappMsg,
                    (callBack) => {

                        if (callBack == 'success') {
                            this.setState({
                                popupState: true,
                                popupStatus: 'Documents Mail Sent Successfully',
                                statusChangePopup: false,
                                isStatusChange: ''
                            })
                            this.removePopup();
                        } else {
                            this.setState({
                                popupState: true,
                                popupStatus: 'Documents Mail not sent',
                                statusChangePopup: false,
                                isStatusChange: ''
                            })
                            this.removePopup();
                        }
                    })
            }
        }
        if(this.state.commentType!==''){
            this.saveComment()
        }
    }
    newmailSendHandler(type) {
        const { allDetail, admin, userDetail } = this.props;
if(type=='yes'){
        if (userDetail.typeOfProduct == 'Loan') {
            pushNotification(allDetail, this.state.mailBody, this.state.title, admin.emailId, STRING.COMMUNICATED, this.state.isStatusChange, this.state.notificationImage, this.state.mailType, this.state.whatsappMsg, '',
                (callBack) => {

                    if (callBack == 'success') {
                        this.setState({
                            popupState: true,
                            popupStatus: 'Documents Mail Sent Successfully',
                            statusChangePopup: false,
                            isStatusChange: '',
                            confirmpopup: false
                        })
                        this.removePopup();
                    } else {
                        this.setState({
                            popupState: true,
                            popupStatus: 'Documents Mail not sent',
                            statusChangePopup: false,
                            isStatusChange: '',
                            confirmpopup: false
                        })
                        this.removePopup();
                    }
                })
        } else if (userDetail.typeOfProduct == 'Gift Card') {
            giftCardpushNotification(userDetail, this.state.mailBody, this.state.title, admin.emailId, STRING.COMMUNICATED, this.state.isStatusChange, this.state.notificationImage, this.state.mailType, this.state.whatsappMsg,
                (callBack) => {

                    if (callBack == 'success') {
                        this.setState({
                            popupState: true,
                            popupStatus: 'Documents Mail Sent Successfully',
                            statusChangePopup: false,
                            isStatusChange: '',
                            confirmpopup: false
                        })
                        this.removePopup();
                    } else {
                        this.setState({
                            popupState: true,
                            popupStatus: 'Documents Mail not sent',
                            statusChangePopup: false,
                            isStatusChange: '',
                            confirmpopup: false
                        })
                        this.removePopup();
                    }
                })
        }
    }else{
        this.setState({confirmpopup:false})
    }
    }

    sendHandler(type) {
        this.props.getData('yes')
        const { allDetail } = this.props;
        let officeId = this.state.officeIdTextState ? "-Official Id\n" : ''
        let adharCard = this.state.adharTextState ? "-Aadhar Card\n" : ''
        let panCard = this.state.panTextState ? "-PanCard\n" : ''
        let bankStatement = this.state.bankTextState ? "-BankStatement\n" : ''
        let salarySlip = this.state.salarySlipTextState ? "-SalarySlip\n" : ''
        let currentAddressProf = this.state.CurrentAddressProofState ? "-Current Address Proof \n" : ''

        if (type == 'mail' && (this.state.mailViewState === 'DocumentMail' || this.state.mailViewState === 'repeatApplication')) {
            this.setState({
                statusChangePopup: true,
                mailBody: document.getElementById("communicationMail").innerHTML,
                // whatsappMsg: document.getElementById("communicationMail").textContent,
                whatsappMsg: "Dear " + allDetail.firstName + " Complete your Micromoney Loan Application by providing the following documents- " + officeId + adharCard + panCard + bankStatement + salarySlip + currentAddressProf + " Regards Team Micromoney " + MAIL_STRING.MOBILE_NO + " " + MAIL_STRING.FINANCE_MAIL_ID,
            })
        } else {
            this.setState({
                mailBody: document.getElementById("communicationMail").innerHTML,
                // whatsappMsg: "Dear " + allDetail.firstName + " Complete your Micromoney Loan Application by providing the following documents- " + officeId + adharCard + panCard + bankStatement + salarySlip + currentAddressProf + " Regards Team Micromoney " + MAIL_STRING.MOBILE_NO + " " + MAIL_STRING.FINANCE_MAIL_ID,
                confirmpopup: true
            })


        }
    }

}

export default DocumentPage;