import React from 'react'
import ReactHtmlParser from 'react-html-parser';

const CommentView = ({ commentDataState, communicationPopup, popupData }) => (
    <div className="container-fluid">
        <div className="row">
            <div className="col-sm-5 col-xs-12">
                <div className="comment-details">
                    <ul className="admin-detail-list">
                        <li><h4 className="text-center blue-text"><strong>Logs</strong></h4></li>
                        {commentDataState != '' && commentDataState != null && commentDataState.length > 0 ?
                            commentDataState.map((commentData,i) => {
                                return (
                                    <li key={i} onClick={(e) => communicationPopup(commentData)}>
                                        <div className="main">
                                            <div className="date">{commentData.date}</div>
                                            <div className="mail">Emp-id:-{commentData.empId}</div>
                                            <div className="type">{commentData.type}</div>
                                            <div className="appid">Application-id:-{commentData.applicationId != null ? commentData.applicationId : ""}</div>
                                            {/* <div className="display-flex">{ReactHtmlParser(commentData.body)}</div> */}
                                        </div>
                                    </li>
                                )
                            })
                            : <div className="text-center"><h3>No data to display</h3></div>}
                    </ul>
                </div>
            </div>
            <div className="col-sm-7 col-xs-12">
                <div className="comment-details">
                    {popupData != null && popupData != '' ?
                        <div>
                            <h5>Date:- {popupData.date}</h5>
                            <h5>Emp-id:- {popupData.empId}</h5>
                            <hr />
                            <h4 className="text-center cap">{popupData.type}</h4>
                            <div className="col-xs-12 popup-bdy">{ReactHtmlParser(popupData.body)}</div>
                        </div>
                        : <div className="text-center"><h3>No data to display</h3></div>}
                </div>
            </div>
        </div>
    </div>
)

export default CommentView;