import React, { Component } from 'react'
import CouponsButton from '../../../presentationals/Admin/AdminCoupons/CouponListingButtons.Component'

class CpnBtn extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pageBtnType: 'AddMerchantDetails'
        }
    }

    render() {
        return (
            <CouponsButton
                buttonActionHandler={this.buttonActionHandler.bind(this)}
                pageBtnType={this.state.pageBtnType}
            />
        )
    }

    buttonActionHandler(btnType) {
        this.setState({ pageBtnType: btnType })
        this.props.getPageShow(btnType)
    }
}

export default CpnBtn;