import React from 'react';
import ReactDOM from 'react-dom';
import 'jquery';
import 'font-awesome/css/font-awesome.min.css';
import AppRouter from './layouts/App.Router';
import reportWebVitals from './reportWebVitals';
// import './index.scss'
import './indexnew.scss'
//rewamp
import "react-datepicker/dist/react-datepicker.css";

const root = ReactDOM.createRoot(document.getElementById('phocketApp'));
root.render(
  <React.StrictMode >
    <AppRouter />
 </React.StrictMode>
);

reportWebVitals();
