import React, { Component } from 'react'
import ManualAssignUi from './ManualAssign.container'
import AssignByState from './AssignByState.container'
import AssignByStatus from '../Settings/assignmentRules/mainTabs'
import AssignForCollectionUi from './AssIgnForCollection.container'
import AssignViaCSV from './AssignViaCsv.container'
import { ROLE } from '../../../../utils/Constant'
class BulkAssign extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pageViewState: ''
        }
    }


    render() {
        const { admin, allStatus } = this.props
        return (
            <div className="container-fluid">
                <h3><b>Lead Management</b></h3>
                <div className="row">
                    <div className="col-xs-12">
                        {/* <div className="btn-group">
                            {admin.rolelist != undefined ? admin.rolelist.indexOf(ROLE.Manual_Lead_Assignment) >= 0 ? <button className={this.state.pageViewState == 'manualAssign' ? 'btn-active' : 'btn'} onClick={e => this.buttonActionHandler("manualAssign")}>Manual Assign</button> : "" : ""}
                            {admin.rolelist != undefined ? admin.rolelist.indexOf(ROLE.LM_Assignment_Rule) >= 0 ? <button className={this.state.pageViewState == 'assignByStatus' ? 'btn-active' : 'btn'} onClick={e => this.buttonActionHandler("assignByStatus")}>Assignment Rule</button> : "" : ""}
                            {admin.rolelist != undefined ? admin.rolelist.indexOf(ROLE.Manual_Lead_Assignment) >= 0 ? <button className={this.state.pageViewState == 'assignViaCsv' ? 'btn-active' : 'btn'} onClick={e => this.buttonActionHandler("assignViaCsv")}>Assignment Via CSV</button> : "" : ""}
                        </div> */}
                        <div className="btn-grp-own-lead-manage-new" role="group" aria-label="onboard-btn" style={{ padding: '10px' }}>
                            {admin.rolelist != undefined ? admin.rolelist.indexOf(ROLE.Manual_Lead_Assignment) >= 0 ? <button className={this.state.pageViewState == 'manualAssign' ? 'btn-active' : 'btn'} onClick={e => this.buttonActionHandler("manualAssign")}>Manual Assign</button> : "" : ""}
                            {admin.rolelist != undefined ? admin.rolelist.indexOf(ROLE.LM_Assignment_Rule) >= 0 ? <button className={this.state.pageViewState == 'assignByStatus' ? 'btn-active' : 'btn'} onClick={e => this.buttonActionHandler("assignByStatus")}>Assignment Rule</button> : "" : ""}
                            {admin.rolelist != undefined ? admin.rolelist.indexOf(ROLE.Manual_Lead_Assignment) >= 0 ? <button className={this.state.pageViewState == 'assignViaCsv' ? 'btn-active' : 'btn'} onClick={e => this.buttonActionHandler("assignViaCsv")}>Assignment Via CSV</button> : "" : ""}

                        </div>
                    </div>
                </div>
                {this.state.pageViewState == 'manualAssign' ?
                    <ManualAssignUi
                        admin={admin}
                        allStatus={allStatus} />
                    : this.state.pageViewState == 'assignForCollection' ?
                        <AssignForCollectionUi
                            admin={admin}
                            allStatus={allStatus} />
                        :
                        this.state.pageViewState == 'assignByState' ?
                            <AssignByState
                                admin={admin} />
                            : this.state.pageViewState == 'assignByStatus' ?
                                <AssignByStatus
                                    admin={admin} />
                                : this.state.pageViewState == 'assignViaCsv' ?
                                    <AssignViaCSV
                                        admin={admin} />
                                    : null}
            </div>
        )
    }

    buttonActionHandler(type) {
        this.setState({ pageViewState: type })
    }

}

export default BulkAssign;