import React, { Component } from 'react';
import AddUrl from '../../presentationals/Campaigntrack/AddCampaignUrl.Component'
import { getCompanyData, saveCampaignData, getAllCampaign, deleteThirdPartyUser } from './ThirdPartyActionCreater.component';
import { APIS } from '../../../utils/api-factory';
class AddCampaign extends Component {
    constructor(props) {
        super(props);
        this.state = {
            companyList: '',
            campaignUrl: '',
            companyName: '',
            campaigndata: '',
            FilteredData: [],
            pageIndexToShow: 0,
            mergedata:{}
        }
    }
    render() {
        return (
            <div>
                <AddUrl
                    companyList={this.state.companyList}
                    campaigndata={this.state.campaigndata}
                    getTextFieldValue={this.getTextFieldValue.bind(this)}
                    saveCampaignUrl={this.saveCampaignUrl.bind(this)}
                    getPageValue={this.getPageValue.bind(this)}
                    getFilterValue={this.getFilterValue.bind(this)}
                    pageIndexToShow={this.state.pageIndexToShow}
                    FilteredData={this.state.FilteredData}
                    deleteUserHandler={this.deleteUserHandler.bind(this)}
                />
            </div>
        );
    }
    deleteUserHandler(id) {
        deleteThirdPartyUser(id, 
            callback => {
                getAllCampaign(callback => {
                    this.setState({ campaigndata: callback })
                })
            })
    }
    
    getFilterValue(filtered) {
        this.setState({ FilteredData: filtered })
    }

    getPageValue(pageIndex) {
        this.setState({ pageIndexToShow: pageIndex })
    }

    componentWillMount() {
        getCompanyData(callback => {
            this.setState({ companyList: callback })
        })
        getAllCampaign(callback => {
            this.setState({ campaigndata: callback })
        }

        )
     

    }
    saveCampaignUrl(e) {
        saveCampaignData(this.state.companyName, this.state.campaignUrl, callback => {
            if (callback == "success") {
                getAllCampaign(callback => {
                    this.setState({ campaigndata: callback })
                }

                )
            }
        })
    }
    getTextFieldValue(e, type) {
        if (type == "campaignUrl") {
            this.setState({ campaignUrl: e.target.value })
        } else if (type == "CompanyName") {
            this.setState({ companyName: e.target.value })
        }


    }
}

export default AddCampaign;