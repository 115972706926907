import React from 'react';
import { PLACEHOLDER, TYPE } from '../../../../utils/Constant'

const CustomerBankDetails = ({ handleChange, allDetail, handleSubmit, BankValidationMsg,
    removeBankDetailPopup }) => (
        <div>
            <div className="background-blur" onClick={e => removeBankDetailPopup()}></div>
            <div className="popup-main container-fluid dbl-border overflow-auto">
                <h3 className="text-center blue-text">Kindly Fill the Bank Details</h3>
                <div className="row">
                    <div className="col-md-6 col-sm-6 col-xs-12">
                        <label className="font-12px">{PLACEHOLDER.BANK_USER_NAME}</label>
                        <input onChange={(e) => handleChange(TYPE.BANK_USER_NAME, e)} type="text" className="form-control" id="" placeholder={PLACEHOLDER.BANK_USER_NAME} value={allDetail.BankUserNameState} />
                        <div className="font-12px margin-left-25px height-25px validation-color">
                            {BankValidationMsg.field == "BankUserName" ? BankValidationMsg.msg : ""}
                        </div>
                    </div>
                    <div className="col-md-6 col-sm-6 col-xs-12">
                        <label className="font-12px">{PLACEHOLDER.ACCOUNT_NO}</label>
                        <input onChange={(e) => handleChange(TYPE.ACCOUNT_NO, e)} type="number" className="form-control" id="" placeholder={PLACEHOLDER.ACCOUNT_NO} value={allDetail.AccountNoState} />
                        <div className="font-12px margin-left-25px height-25px validation-color">
                            {BankValidationMsg.field == "AccountNo" ? BankValidationMsg.msg : ""}
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6 col-sm-6 col-xs-12">
                        <label className="font-12px">{PLACEHOLDER.IFSC_CODE}</label>
                        <input onChange={(e) => handleChange(TYPE.IFSC_CODE, e)} type="text" className="form-control" id="" placeholder={PLACEHOLDER.IFSC_CODE} value={allDetail.IFSCCodeState} />
                        <div className="font-12px margin-left-25px height-25px validation-color">
                            {BankValidationMsg.field == "IFSCCode" ? BankValidationMsg.msg : ""}
                        </div>
                    </div>
                    <div className="col-md-6 col-sm-6 col-xs-12">
                        <label className="font-12px">{PLACEHOLDER.BANK_NAME}</label>
                        <input onChange={(e) => handleChange(TYPE.BANK_NAME, e)} type="text" readOnly className="form-control" id="dashBankName" placeholder={PLACEHOLDER.BANK_NAME} value={allDetail.BankNameState} />
                        <div className="font-12px margin-left-25px height-25px validation-color">
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-6 col-sm-6 col-xs-12">
                        <label className="font-12px">{PLACEHOLDER.BANK_BRANCH}</label>
                        <input onChange={(e) => handleChange(TYPE.BANK_BRANCH, e)} type="text" readOnly className="form-control" id="dashBankBranch" placeholder={PLACEHOLDER.BANK_BRANCH} value={allDetail.BankBranchState} />
                        <div className="font-12px margin-left-25px height-25px validation-color">
                        </div>
                    </div>
                    <div className="col-md-6 col-sm-6 col-xs-12">
                    <label className="font-12px">{PLACEHOLDER.AADHAAR_NO}</label>
                        <input onChange={(e) => handleChange(TYPE.AADHAAR_NO, e)} type="text" className="form-control" id="dashBankName" placeholder={PLACEHOLDER.AADHAAR_NO} value={allDetail.aadharNoState} />
                        <div className="font-12px margin-left-25px height-25px validation-color">
                            {BankValidationMsg.field == "aadharNo" ? BankValidationMsg.msg : ""}
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-xs-12 text-center">
                    <button className="profile-save-btn" onClick={(e) => handleSubmit(e)}>
                            <span className="fa fa-floppy-o"></span>&nbsp;&nbsp;Save
                        </button>
                    {/* <label className="font-12px">{PLACEHOLDER.AADHAAR_NO}</label>
                        <input onChange={(e) => handleChange(TYPE.AADHAAR_NO, e)} type="text" className="form-control" id="dashBankName" placeholder={PLACEHOLDER.AADHAAR_NO} value={allDetail.aadharNoState} />
                        <div className="font-12px margin-left-25px height-25px validation-color">
                            {BankValidationMsg.field == "aadharNo" ? BankValidationMsg.msg : ""}
                        </div> */}
                        {/* <label className="font-12px">{PLACEHOLDER.PAN_NO}</label>
                        <input onChange={(e) => handleChange(TYPE.PAN_NO, e)} type="text" className="form-control" id="" placeholder={PLACEHOLDER.PAN_NO} value={allDetail.PanNoState} />
                        <div className="font-12px margin-left-25px height-25px validation-color">
                            {BankValidationMsg.field == "PanNo" ? BankValidationMsg.msg : ""}
                        </div> */}
                    </div>
                    {/* <div className="col-md-6 col-sm-6 col-xs-12 text-center">
                    <button className="profile-save-btn" onClick={(e) => handleSubmit(e)}>
                            <span className="fa fa-floppy-o"></span>&nbsp;&nbsp;Save
                        </button>
                        <label className="font-12px">{PLACEHOLDER.AADHAAR_NO}</label>
                        <input onChange={(e) => handleChange(TYPE.AADHAAR_NO, e)} type="text" className="form-control" id="dashBankName" placeholder={PLACEHOLDER.AADHAAR_NO} value={allDetail.aadharNoState} />
                        <div className="font-12px margin-left-25px height-25px validation-color">
                            {BankValidationMsg.field == "aadharNo" ? BankValidationMsg.msg : ""}
                        </div>
                    </div> */}
                </div>

                {/* <div className="row">
                    <div className="col-xs-12 text-center">
                        <button className="profile-save-btn" onClick={(e) => handleSubmit(e)}>
                            <span className="fa fa-floppy-o"></span>&nbsp;&nbsp;Save
                        </button>
                    </div>
                </div> */}
            </div>
        </div>
    )

export default CustomerBankDetails;