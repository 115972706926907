import React from 'react';
import ReactTable from "react-table";
import { filterCaseInsensitive } from '../../../../utils/commonUtility'

const MerchantOfferReport = ({ CouponAvailedDataHandler,
    getFilterValue, FilteredData, getPageValue, pageIndexToShow }) => (
        <div className="container-fluid">
            <div className="row text-center">
              
            </div>
        </div>
    )

export default MerchantOfferReport;
