import React from 'react'
import ReactTable from "react-table";
import { filterCaseInsensitive } from '../../../../utils/commonUtility'

const NachBounce = ({ NachBounceData, getFilterValue, FilteredData, getPageValue, pageIndexToShow }) => (
    <div className="container-fluid">
        <div className="row">
            <div className="col-xs-12">
                <h3 className="text-center blue-text">NACH Bounce Page</h3>
            </div>
        </div>
        <div className="row">
           
            {NachBounceData != null && NachBounceData != '' && NachBounceData.length > 0 ?
                <div className='overflow-auto'>
                    <table className='phocket-table'>
                        <thead>

                            <tr>
                                <td>Loan Id</td>
                                <td>Application Id</td>
                                <td>Name</td>
                                <td>Mobile No.</td>
                                <td>Email ID</td>
                                <td>Cheque Bounce Date</td>
                                <td>Days Count</td>

                            </tr>

                        </thead>
                        <tbody>
                            {NachBounceData && NachBounceData.map((data, i) => {
                                return (
                                    <tr key={i}>
                                        <td> {data.applicationLoanId}</td>
                                        <td> {data.applicationId}</td>
                                        <td>{data.userName}</td>
                                        <td> {data.mobileNumber}</td>
                                        <td> {data.emailId}</td>
                                        <td>{data.date}</td>
                                        <td>{data.count}</td>


                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
                : ""}

        </div>
    </div>
)

export default NachBounce;