import React from 'react'

const PromotionalData = ({ data, NextHandler, PreviousHandler, getFilterData,
    getPromotionalData, getDataByFilter, SendOnSubmit }) => (
        <div className="container promotional-data-table full-width">
            <div className="row form-group">
                <h3 className="text-center blue-text">Promotional Data</h3>
            </div>
            <div className="row form-group">
                <div className="col-sm-3 col-xs-12">
                    <select className="form-control" id="" onChange={(e) => getPromotionalData("City", e)}>
                        {getFilterData.length > 0 ? getFilterData.map((getFilterData, i) => {
                            return (
                                <option key={i} value={getFilterData}>{getFilterData}</option>
                            )
                        }) : ""}
                    </select>
                </div>
                <div>
                    <div className="col-sm-3 col-xs-12">
                        <select className="form-control" id="" onChange={(e) => getPromotionalData("Salary", e)} >
                            <option>Salary</option>
                            <option value=">3" className="selectOption">Greater than 3</option>
                            <option value=">5and<10">Greater than 3 Less than 10</option>
                            <option value=">10">Greater than 10</option>
                        </select>
                    </div>
                    <div className="col-sm-3 col-xs-12">
                        <select className="form-control" id="phocketGender" onChange={(e) => getPromotionalData("Experience", e)} >
                            <option>Experience</option>
                            <option value=">3" className="selectOption">Greater than 3</option>
                            <option value=">5and<10">Greater than 3 Less than 10</option>
                            <option value=">10">Greater than 10</option>
                        </select>
                    </div>
                    <div className="col-sm-3 col-xs-12">
                        <button className="btn btn-info center-block" onClick={e => getDataByFilter()}>Filter</button>

                    </div>
                </div>
            </div>
            <div className="row form-group">
                <div className="col-xs-12 overflow-auto">
                    <table className="table">
                        <thead>
                            <tr className="tableHeading">

                                {/* <input
                      type="checkbox"
                      
                     // value={label}
                     // checked={isChecked}
                     onChange={e=>emailSendAll(e,data)}
                  /> */}

                                <th colSpan="3">Personal Detail</th>
                                <th colSpan="2">Qualification</th>
                                <th>Designation</th>
                                <th colSpan="2">Employer</th>
                                <th>Salary</th>
                                <th>City</th>
                                <th>Experience</th>
                            </tr>
                            <tr className="tableHeading">
                                <th>Name</th>
                                <th>Mobile No.</th>
                                <th>Email id</th>
                                <th>One</th>
                                <th>Two</th>
                                <th></th>
                                <th>One</th>
                                <th>Two</th>
                                <th></th>
                                <th></th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.length > 0 ? data.map((data, i) => {
                                return (

                                    <tr key={i} className="tablecontent">

                                        {/* <input
                                            type="checkbox"
                                            id={i}
                                            //value={label}
                                            //checked={isChecked}   
                                            onChange={e=>emailSend(e,data)}
                                        /> */}

                                        <td>{data.name}</td>
                                        <td>{data.mobile}</td>
                                        <td>{data.emailId}</td>
                                        <td>{data.qualificationOne}</td>
                                        <td>{data.qualificationTwo}</td>
                                        <td>{data.designation}</td>
                                        <td>{data.employerOne}</td>
                                        <td>{data.employerTwo}</td>
                                        <td>{data.salary}</td>
                                        <td>{data.city}</td>
                                        <td>{data.experience}</td>
                                    </tr>
                                )
                            }) : null}

                        </tbody>
                    </table>
                </div>
            </div>
            <div className="row form-group">
                <div className="footer">
                    <button className="btn btn-success" onClick={e => PreviousHandler()}>Previous</button>
                    <button className="btn btn-success" onClick={e => NextHandler()}>Next</button>
                </div>
            </div>
            <div className="row form-group">
                <div className="footer">
                    <button className="btn btn-primary" onClick={(e) => SendOnSubmit("Mail", e)}>SendMail</button>
                    <button className="btn btn-primary" onClick={(e) => SendOnSubmit("Whatsapp", e)}>SendWhatsapp</button>
                    <button className="btn btn-primary" onClick={(e) => SendOnSubmit("Message", e)}>SendMessage</button>
                </div>
            </div>
        </div>
    )

export default PromotionalData;