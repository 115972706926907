export const LoanFormValidation = (userDetail) => {
    if (userDetail.loanAmountState === "" || userDetail.loanAmountState === null) {
        return { status: false, msg: 'Please enter desired Loan Amount', field: "loanAmount" };
    } else if (userDetail.loanAmountState > 200000 || userDetail.loanAmountState < 5000) {
        return { status: false, msg: 'Loan Amount should be between 5000-200000', field: "loanAmount" };
    } else if (userDetail.loanAmountState % 1000 != 0) {
        return { status: false, msg: 'Please enter Amount in denomination of Thousands', field: "loanAmount" };
    }
    // if (userDetail.loanDaysState === "" || userDetail.loanDaysState === null) {
    //     return { status: false, msg: 'Please enter desired Loan Tenure', field: "loanDays" };
    // } else if (userDetail.loanDaysState > 60 || userDetail.loanDaysState < 7) {
    //     return { status: false, msg: 'Days should be between 7-60', field: "loanDays" };
    // }
    // if (userDetail.PanNoState === "" || userDetail.PanNoState === null) {
    //     return { status: false, msg: 'Please enter your PAN Number', field: "PanNo" };
    // } else if (userDetail.PanNoState.length < 10 || userDetail.PanNoState.length > 10) {
    //     return { status: false, msg: 'Please enter valid 10 digit PAN Number', field: "PanNo" };
    // } else if (!pattern.test(userDetail.PanNoState)) {
    //     return { status: false, msg: 'Please enter valid PAN Number(eg- ABCDE1234F)', field: "PanNo" };
    // }
    // if (userDetail.aadharNoState === "" || userDetail.aadharNoState === null) {
    //     return { status: false, msg: 'Please enter your Aadhaar Number', field: "aadharNo" };
    // } else if (userDetail.aadharNoState.length > 12 || userDetail.aadharNoState.length < 12) {
    //     return { status: false, msg: 'Please enter valid 12 digit Aadhaar No', field: "aadharNo" };
    // }
    if (userDetail.loanReasonState === "" || userDetail.loanReasonState === null) {
        return { status: false, msg: 'Please enter the Loan Reason', field: "loanReason" };
    } else if (specialChar.test(userDetail.loanReasonState)) {
        return { status: false, msg: 'Special characters not Allowed', field: 'loanReason' };
    }
    return { status: true, msg: ' ' };
}
