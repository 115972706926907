import React, { Component } from 'react'
import AdminLogin from '../../presentationals/Admin/CRMLogin.component'
import { adminLoginApi, oauthTokenApi, getAdminUserCheck } from './AdminActionCreator.component'
import CRMMainPage from './CRMMainPage.container'
import localStorageUtil from '../../../utils/localStorageUtil.js'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import $ from 'jquery'
import { APIS } from '../../../utils/api-factory'
class CRMLogin extends Component {
  constructor(props) {
    super(props)
    this.state = {
      CRMUserState: '',
      CRMPasswordState: '',
      loginWarningMsg: '',
      loginStatus: false,
      adminData: '',
      notificationDataState: '',
      captchaSuccess: false,
      captchaText: '',
      userCaptchaInput: '',
      adminDataForLandingpage: []
    }
  }
  getCaptcha() {
    fetch(APIS.GET_CAPTCHA)
      .then(res => res.json())
      .then(res => {
        this.setState({
          captchaText: res
        })
      })
  }
  refresh() {
    this.getCaptcha()
  }
  componentDidMount() {
    if (localStorageUtil.getFromLocalStorage('AdminemailId') === 'null' || localStorageUtil.getFromLocalStorage('AdminemailId') === '' || localStorageUtil.getFromLocalStorage('AdminemailId') === null) {
      this.setState({ loginStatus: false })
      // localStorage.clear()
      // window.location.reload()
    }
  }
  componentWillMount() {
    this.getCaptcha()
    // navigator.serviceWorker.addEventListener('message', event => {
    //     this.notificationData(event)
    // });
    if (localStorageUtil.getFromLocalStorage('status') === 'true') {
      this.setState({ loginStatus: true })
      this.props.getAdminUserCheck(localStorageUtil.getFromLocalStorage('AdminemailId'), callBack => {
      })
    }
    // if (
    //   localStorageUtil.getFromLocalStorage('AdminemailId') != null &&
    //   localStorageUtil.getFromLocalStorage('password') != null
    // ) {
    //   this.props.adminLoginApi(
    //     localStorageUtil.getFromLocalStorage('AdminemailId'),
    //     localStorageUtil.getFromLocalStorage('password'),
    //     '',
    //     callBack => {}
    //   )
    // }
  }

  notificationData(event) {
    this.setState({ notificationDataState: event })
  }

  render() {
    const { admin } = this.props
    return (
      <div className='full-height overflow-auto'>
        {this.state.loginStatus == true ? (
          <CRMMainPage
            adminDataForLandingpage={this.state.adminDataForLandingpage}
            logOutHandler={this.logOutHandler.bind(this)}
            adminData={this.state.adminData}
            admin={admin}
            notificationDataState={this.state.notificationDataState}
          />
        ) : (
          <AdminLogin
            refresh={this.refresh.bind(this)}
            captchaText={this.state.captchaText}
            loginHandler={this.loginHandler.bind(this)}
            CRMUser={this.CRMUser.bind(this)}
            CRMPassword={this.CRMPassword.bind(this)}
            CRMCaptcha={this.CRMCaptcha.bind(this)}
            loginWarningMsg={this.state.loginWarningMsg}
            captchaSuccess={this.state.captchaSuccess}
            captchaChange={this.captchaChange.bind(this)}
          />
        )}
      </div>
    )
  }

  captchaChange(e) {
    this.setState({
      captchaSuccess: e
    })
  }

  CRMUser(e) {
    this.setState({
      CRMUserState: e.target.value,
      loginWarningMsg: ''
    })
  }

  CRMPassword(e) {
    this.setState({
      CRMPasswordState: e.target.value,
      loginWarningMsg: ''
    })
  }
  CRMCaptcha(e) {
    this.setState({
      userCaptchaInput: e.target.value
    })
  }

  logOutHandler() {
    this.setState({ loginStatus: false })
    localStorageUtil.saveInLocalStorage('status', false)
    localStorageUtil.deleteFromLocalStorage('AdminemailId')
    localStorageUtil.deleteFromLocalStorage('AdminRole')
    localStorageUtil.deleteFromLocalStorage('AdminRoleList')
    localStorageUtil.deleteFromLocalStorage('password')
    localStorageUtil.deleteFromLocalStorage('access_token')
    localStorageUtil.deleteFromLocalStorage('refresh_token')
  }

  loginHandler(KeyType, e) {
    if (KeyType == 'click') {
      this.loginMethod()
    } else if (KeyType == 'enter') {
      if (e.keyCode == 13) {
        this.loginMethod()
      }
    }
  }

  loginMethod() {
    if (this.state.CRMUserState != '' && this.state.CRMPasswordState != '') {
      // if (this.state.captchaSuccess) {
      if (this.state.userCaptchaInput === this.state.captchaText.captcha) {
        this.props.adminLoginApi(
          this.state.CRMUserState,
          this.state.CRMPasswordState,
          this.state.captchaText.captcha,
          callBack => {
            if (callBack.status == 'success') {
              this.setState({
                // loginStatus: true,
                adminData: callBack,
                loginWarningMsg: '',
                captchaSuccess: false
              })
              oauthTokenApi(callBack => {
                localStorageUtil.saveInLocalStorage(
                  'access_token',
                  callBack.access_token
                )
                localStorageUtil.saveInLocalStorage(
                  'refresh_token',
                  callBack.refresh_token
                )
                this.setState({
                  loginStatus: true
                })
              })
              localStorageUtil.saveInLocalStorage('status', true)
            } else if (callBack.status == 'failure') {
              this.setState({
                loginWarningMsg: 'Please Enter Correct Email and Password',
                captchaSuccess: ''
              })
              this.getCaptcha()
              $('[data-testid="captcha-refresh"]').trigger('click')
            } else {
              this.setState({
                loginWarningMsg: 'Please Try Again'
              })
              this.getCaptcha()
            }
          }
        )
      } else {
        this.setState({
          loginWarningMsg: 'Please Enter correct Captcha'
        })
        this.getCaptcha()
        $('[data-testid="captcha-refresh"]').trigger('click')
      }
    } else {
      this.setState({
        loginWarningMsg: 'Please Enter UserName and Password'
      })
    }
  }
}

const mapStateToProps = ({ admin }) => {
  return {
    admin: admin
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators({ adminLoginApi, getAdminUserCheck }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(CRMLogin)
