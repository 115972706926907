import React, { useEffect, useState } from "react";
import Multiselect from "multiselect-react-dropdown";
import { addSmsTemplateApI, deleteSmsTemplateApi, getSaveSmsTemplates } from "./apiCom";
import ConfirmationPopup from "../../../../presentationals/Popup/ConfirmationPopup.component";
import NodataImage from '../../../../../images/nodata.png';

const SmsCom = ({ admin }) => {
    const [commType, setCommType] = useState([]);
    const [text, setText] = useState("");
    const [templateName, setTemplateName] = useState("");
    const [subjectText, setSubjectText] = useState("");
    const [errorData, setError] = useState("");
    const [viewState, setViewState] = useState("list");
    const [objectData, setObjectData] = useState([]);
    const [templateId, settemplateId] = useState("");
    const [header, setheader] = useState("");
    const [showModal, setShowModal] = useState(false);
    const [templateToDelete, setTemplateToDelete] = useState("");
    const [apiError, setApiError] = useState("");
    const [messagetype, setmessageType] = useState("");
    const [id, setid] = useState("");


    useEffect(() => {
        getSaveSmsTemplates((data, error) => {
            if (error) {
                setApiError("Failed to load SMS templates. Please try again later.");
            } else {
                setObjectData(data);
            }
        });
    }, []);

    const handlesave = () => {
        // if (!templateName || !subjectText || !text || commType.length === 0) {
        //     setError("All fields are required.");
        //     return;
        // }

        // setError("");
        if (templateId !== '' && templateName !== '' && messagetype !== '' && header !== '' && text !== '' && commType.length > 0) {
            setError('')
            const apiData = {
                "templateId": templateId,
                "templateName": templateName,
                "messageType": messagetype,
                "smsHeader": header,
                "message": text,
                "type": commType.join(", "),
                "addedBy": admin.emailId,
                "id": id
            };
            console.log(apiData)

            addSmsTemplateApI(apiData, (callback, error) => {
                if (error) {
                    setApiError("Failed to save SMS template. Please try again later.");
                } else {
                    getSaveSmsTemplates((data, error) => {
                        if (error) {
                            setApiError("Failed to load SMS templates. Please try again later.");
                        } else {
                            setObjectData(data);
                            setViewState('list');
                            setCommType([]);
                            setText('');
                            setTemplateName('');
                            setSubjectText('');
                            settemplateId('');
                            setheader('');
                        }
                    });
                }
            });
        } else {
            setError('All fields are Mandatory !')
        }
    };

    const setEditData = (data) => {
        console.log(data)
        setViewState('add');
        setCommType(data.type.split(',').map(item => item.trim()));
        setText(data.body);
        setTemplateName(data.templateName);
        setText(data.message);
        settemplateId(data.templateId);
        setheader(data.smsHeader);
        setid(data.id)
        setmessageType(data.messageType)
    };

    const handleDelete = (templateName) => {
        setShowModal(true);
        setTemplateToDelete(templateName);
    };

    const confirmDelete = () => {
        deleteSmsTemplateApi(templateToDelete, (callback, error) => {
            if (error) {
                setApiError("Failed to delete SMS template. Please try again later.");
            } else {
                getSaveSmsTemplates((data, error) => {
                    if (error) {
                        setApiError("Failed to load SMS templates. Please try again later.");
                    } else {
                        setObjectData(data);
                    }
                });
            }
        });
        setShowModal(false);
    };

    const quillModules = {
        toolbar: [
            [{ font: [] }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            ["bold", "italic", "underline", "strike"],
            [{ color: [] }, { background: [] }],
            ["blockquote"],
            [
                { list: "ordered" },
                { list: "bullet" },
                { indent: "-1" },
                { indent: "+1" },
            ],
            [{ align: [] }],
            ["link", "image"],
        ],
    };

    const quillFormats = [
        "header",
        "font",
        "bold",
        "italic",
        "underline",
        "strike",
        "blockquote",
        "list",
        "bullet",
        "indent",
        "link",
        "image",
        "color",
        "background",
        "align",
    ];

    return (
        <div className="container-fluid">
            {showModal &&
                <ConfirmationPopup
                    confirmationText={'Are you sure you want to delete this template?'}
                    cancelLoanConfirm={(type) => {
                        if (type === 'yes') {
                            deleteSmsTemplateApi(templateToDelete, (callback, error) => {
                                if (error) {
                                    setApiError("Failed to delete SMS template. Please try again later.");
                                } else {
                                    getSaveSmsTemplates((data, error) => {
                                        if (error) {
                                            setApiError("Failed to load SMS templates. Please try again later.");
                                        } else {
                                            setObjectData(data);
                                        }
                                    });
                                }
                            });
                            setShowModal(false);
                        } else {
                            setShowModal(false);
                        }
                    }}
                />
            }
            <div className="row">
                <div>
                    {viewState === 'add' ?
                        <button onClick={() => setViewState('list')} className="btn btn-primary">View Templates</button>
                        : ""}
                    {viewState === 'list' ?
                        <button onClick={() => setViewState('add')} className="btn btn-primary">Add Templates</button>
                        : ""}
                </div>
                {viewState === 'add' &&
                    <div className="col-lg-8 co-xl-8 col-xxl-8">
                        <div className="shadow" style={{ minHeight: '500px' }}>
                            <h5 style={{ padding: '20px 10px 0px', marginTop: '10px' }}><b>Configure SMS Template</b></h5>
                            <hr style={{ color: '#c7c8c9', borderTop: '3px solid #c7c8c9' }} />
                            <div style={{ padding: '20px' }}>
                                <div className="row">
                                    <div className="col-sm-6 col-xs-12">
                                        <label > Message Type</label>
                                        <select style={{ width: '100%', padding: '16px' }} name="email" id="email" className="form-select mb-3" onChange={(e) => setmessageType(e.target.value)} value={messagetype}>
                                            <option value="" selected disabled>
                                                Select Message Type
                                            </option>
                                            <option value="Promotion">Promotion</option>
                                            <option value="Service - Explicit">Service - Explicit</option>
                                            <option value="Service Inferred">Service Inferred</option>
                                        </select>
                                    </div>
                                    <div className="col-sm-6 col-xs-12">
                                        <label> Communication Type</label>

                                        <Multiselect
                                            placeholder="Select Communication Type"
                                            className="form-select"
                                            style={{
                                                multiselectContainer: {
                                                    minHeight: "50px",
                                                },
                                                searchBox: {
                                                    border: "none",
                                                    lineHeight: "25px",
                                                    padding: 0,
                                                },
                                            }}
                                            isObject={false}
                                            onKeyPressFn={function noRefCheck() { }}
                                            onRemove={(e) => setCommType(e)}
                                            onSearch={function noRefCheck() { }}
                                            onSelect={(e) => setCommType(e)}
                                            selectedValues={commType}
                                            value={commType}
                                            showCheckbox={true}
                                            options={["One to One Communication", "Bulk Communication"]}
                                            onChange={(e) => console.log("foccussed")}
                                            avoidHighlightFirstOption={true}
                                        />
                                    </div>
                                </div>
                                <div>
                                    <label style={{ marginTop: '10px' }}>Template Name</label>
                                    <input
                                        style={{ width: '100%', padding: '16px' }}
                                        type="text"
                                        value={templateName}
                                        className="form-control w-100 mb-3"
                                        placeholder="Enter Template Name"
                                        onChange={(e) => setTemplateName(e.target.value)}
                                    />
                                    <div className="row">
                                        <div className="col-sm-6 col-xs-12">
                                            <label style={{ marginTop: '10px' }}> DLT Template Id</label>
                                            <input
                                                style={{ width: '100%', padding: '16px' }}
                                                type="text"
                                                className="form-control mb-3"
                                                placeholder="Enter DLT Template ID"
                                                value={templateId}
                                                onChange={(e) => settemplateId(e.target.value)}
                                            />
                                        </div>
                                        <div className="col-sm-6 col-xs-12">
                                            <label style={{ marginTop: '10px' }}>SMS Header</label>
                                            <select style={{ width: '100%', padding: '16px' }} name="email" id="email" className="form-select mb-2" onChange={(e) => setheader(e.target.value)} value={header}>
                                                <option value="" selected disabled>
                                                    Select SMS Header
                                                </option>
                                                <option value="PHOCKT">PHOCKT</option>
                                                <option value="191589">191589</option>
                                            </select>
                                        </div>
                                    </div>
                                    <label style={{ marginTop: '10px' }}>DLT Approved Message Text box</label>
                                    <div className="communicationBox">
                                        <textarea

                                            style={{ minHeight: "200px" }}
                                            value={text}
                                            onChange={(e) => setText(e.target.value)}
                                            className="form-control"
                                            placeholder="Write SMS here ..."
                                        />
                                    </div>
                                    {errorData && <div className="text-center text-danger " style={{ marginTop: '20px' }}>{errorData}</div>}
                                    <button style={{ marginTop: '20px' }} className="btn btn-primary" onClick={() => handlesave()}>Save</button>
                                    {/* {errorData && <div className="text-center text-danger mt-2">{errorData}</div>}
                                {apiError && <div className="text-center text-danger mt-2">{apiError}</div>} */}
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {viewState === 'list' &&
                    <div className="col-xs-12 col-sm-12">
                        {objectData && objectData.length > 0 ?
                            <div className="phocket-table-new">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th>Body</th>
                                            <th>Communication Type</th>
                                            <th>Message Type</th>
                                            <th>Added By</th>
                                            <th>Edit</th>
                                            <th>Delete</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {objectData.map((data, index) => (
                                            <tr key={index}>
                                                <td>{data.templateName}</td>
                                                <td>{data.message}</td>
                                                <td>{data.type}</td>
                                                <td>{data.messageType}</td>

                                                <td>{data.addedBy}</td>
                                                <td><i style={{cursor:'pointer'}} className="fa fa-edit" onClick={() => setEditData(data)}></i></td>
                                                <td><i style={{cursor:'pointer'}} className="fa fa-trash" onClick={() => handleDelete(data.templateName)}></i></td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                            :
                            <div className="text-center">
                                <img src={NodataImage} style={{ width: '30%', marginTop: '50px' }} />
                            </div>
                        }
                        {apiError && <div className="text-center text-danger mt-2">{apiError}</div>}
                    </div>
                }
            </div>
        </div>
    );
};

export default SmsCom;
