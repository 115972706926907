import React from 'react'
import { WEB_URL } from '../../../../utils/api-factory'
import { ROLE } from '../../../../utils/Constant'
import Nodata from '../../../../images/nodata.png'
import VerifyImage from '../../../../images/verified.png'
import NotVerified from '../../../../images/cross.png'
import moment from 'moment'

const userBasic = ({ bankData, deactivateNach, userReference, markincompleteapplication, appVersion, vreedhiData, userDetail, userLinkHandler, linkState, allDetail, panVerifiedstate, userScore, verifyMail, admin, randomValues, verifyHandler, saveVerify }) => (
    <div className="container-fluid ">
        <br />

        <div className='row'>
            <div className='col-sm-4 col-xs-12'>
                {admin != null && admin.rolelist != undefined && admin.rolelist.indexOf(ROLE.COMMUNICATION) >= 0 && linkState == false ?
                    <button className='profile-save-btn' onClick={() => userLinkHandler('yes')}>Click Here For User Links</button>
                    : ""}
            </div>

            <div className='col-sm-4 col-xs-12' style={{ textAlign: '-webkit-center' }}>
                {deactivateNach ?
                    <div className='highlight-new'>User Requested to Deactivate NACH</div>
                    : ""}
            </div>



        </div>


        {admin != null && admin.rolelist != undefined && admin.rolelist.indexOf(ROLE.COMMUNICATION) >= 0 && linkState == true ?
            <div className='row animated slideInLeft'>
                <button style={{ marginLeft: '15px' }} className='profile-save-btn' onClick={() => userLinkHandler('no')}>Close Links</button>

                <div className="col-xs-12">
                    <br />
                    <p><b>Upload Document Link :&nbsp;</b>{allDetail != null ? allDetail.userId != null ? WEB_URL + "uploadDocuments/" + allDetail.userId : "" : ""}</p>
                </div>
                <div className="col-xs-12">
                    <p><b>Feedback Link :&nbsp;</b>{allDetail != null ? allDetail.userId != null ? WEB_URL + "feedback/" + allDetail.userId : "" : ""}</p>
                </div>
                <div className="col-xs-12">
                    {userDetail.loanId != '' && userDetail.loanId != null ?
                        <p><b>E-Mandate with Rajorpay Link :&nbsp;</b>{userDetail != null ? userDetail.loanId != null ? WEB_URL + "enach/" + userDetail.loanId : "" : ""}</p>
                        : userDetail.giftCardId != '' && userDetail.giftCardId != null ? <p><b>E-Mandate with Rajorpay Link :&nbsp;</b>{userDetail != null ? userDetail.giftCardId != null ? WEB_URL + "enach/" + userDetail.giftCardId : "" : ""}</p> : ""}
                </div>
                <div className="col-xs-12">
                    {userDetail.loanId != '' && userDetail.loanId != null && userDetail.typeOfProduct == 'Loan' ?
                        <p><b>E-Mandate with TechProcess Link :&nbsp;</b>{userDetail != null ? userDetail.loanId != null ? WEB_URL + "eNach/" + userDetail.loanId : "" : ""}</p> : ""}
                    {/* //    :userDetail.giftCardId!= '' &&userDetail.giftCardId!= null? <p><b>E-Mandate with TechProcess Link :&nbsp;</b>{userDetail != null ? userDetail.giftCardId != null ? WEB_URL + "eNach/" + userDetail.giftCardId : "" : ""}</p>:""} */}
                </div>
                <div className="col-xs-12">
                    {userDetail.loanId != '' && userDetail.loanId != null ?
                        <p><b>E-Sign Link :&nbsp;</b>{userDetail != null ? userDetail.loanId != null ? WEB_URL + "esign/" + userDetail.loanId : "" : ""}</p>
                        : userDetail.giftCardId != '' && userDetail.giftCardId != null ? <p><b>E-Sign Link :&nbsp;</b>{userDetail != null ? userDetail.giftCardId != null ? WEB_URL + "esign/" + userDetail.giftCardId : "" : ""}</p> : ""}
                </div>

            </div>
            : null}
        <div className="row">
            <br />
            <div className="col-sm-6 col-xs-12">
                <div className="profile-details-box-new" style={{ minHeight: "300px" }}>
                    <div className="new-basic-head" >Personal Information</div>

                    <div className="">
                        <p className="word-break"><b>Email Id :&nbsp;</b>{allDetail != null ? allDetail.emailId != null ? allDetail.emailId : "" : ""}</p>
                    </div>

                    <div className="">
                        <p><b>Mobile Number :&nbsp;</b>{allDetail != null ? allDetail.mobileNumber != null ? allDetail.mobileNumber : "" : ""}</p>
                    </div>
                    <h4 className='new-basic-head' style={{ borderTopRightRadius: '0px', borderTopLeftRadius: '0px', marginBottom: '10px', marginTop: '15px' }}>Bank Details</h4>
                        <div className="">
                            <p><b>Bank Name :&nbsp;</b>
                                {bankData != null && bankData.bankName != null ? bankData.bankName : ""}</p>
                        </div>
                        <div className="">
                            <p><b>Account Number :&nbsp;</b>
                                {bankData != null && bankData.accountNumber != null ? bankData.accountNumber : ""}</p>
                        </div>
                        <div className="">
                            <p><b>Name As on Bank Account :&nbsp;</b>
                                {bankData != null && bankData.nameAsBankAccount != null ? bankData.nameAsBankAccount : ""}</p>
                        </div>
                        <div className="">
                            <p><b>IFSC Code :&nbsp;</b>
                                {bankData != null && bankData.ifscCode != null ? bankData.ifscCode : ""}</p>
                        </div>

                    </div>





            </div>

            <div className="col-sm-6 col-xs-12">
                <div className="profile-details-box-new" style={{ minHeight: "300px" }}>
                    <div className="new-basic-head" >Residential Information</div>

                    <div className="orange-text">
                        <p>Current Address</p>
                    </div>
                    <div className="">
                        <p><b>Address :&nbsp;</b>
                            {allDetail != null ? allDetail.addressDetailsPOList != null ?
                                <span>
                                    {allDetail.addressDetailsPOList.currentAddress != null ? allDetail.addressDetailsPOList.currentAddress + ", " : ""}
                                    {allDetail.addressDetailsPOList.currentCity != null ? allDetail.addressDetailsPOList.currentCity + ", " : ""}
                                    {allDetail.addressDetailsPOList.currentState != null ? allDetail.addressDetailsPOList.currentState + ", - " : ""}
                                    {allDetail.addressDetailsPOList.currentPincode != 0 && allDetail.addressDetailsPOList.currentPincode != null ? allDetail.addressDetailsPOList.currentPincode : ""}
                                </span>
                                : "" : ""}
                        </p>
                    </div>

                    <div >
                        <h4 className='new-basic-head' style={{ borderTopRightRadius: '0px', borderTopLeftRadius: '0px', marginBottom: '10px', marginTop: '15px' }}>Identity Information</h4>
                        <div className="">
                            <p><b>Pan Number :&nbsp;</b>{allDetail != null ? allDetail.panNumber != null ? allDetail.panNumber : "" : ""}&nbsp;{panVerifiedstate == true ? <img src={VerifyImage} width="22px" /> : <img src={NotVerified} width="20px" />}</p>
                        </div>
                        <div className="">
                            <p className="word-break"><b>Name as on PAN - :&nbsp;</b>{allDetail != null ? allDetail.nameAsOnPan != null ? allDetail.nameAsOnPan : "" : ""}</p>
                        </div>
                        {panVerifiedstate == false && admin != null && admin.rolelist != undefined && admin.rolelist.indexOf(ROLE.PAN_VERIFY) >= 0 ?
                            <div >
                                <p style={{ display: 'flex' }}><input className="form-control" placeholder="Name same as on Pancard" onChange={e => verifyHandler(e)} /><button style={{ marginTop: '0', marginBottom: '0' }} className="profile-save-btn" onClick={saveVerify}>Verify</button></p>
                            </div>
                            : ""}
                        <div className="">
                            <p className="word-break"><b>Pan Status By Nsdl :&nbsp;</b>{allDetail.nsdlPanStatus != '' && allDetail.nsdlPanStatus != null ? allDetail.nsdlPanStatus : "No Data Found"}</p>
                        </div>
                        <div className="">
                            <p className="word-break"><b>Aadhar No. - :</b>&nbsp;{allDetail != null ? allDetail.aadharNumber != null ? allDetail.aadharNumber : "" : ""}</p>
                        </div>
                    </div>
                </div>

            </div>
        </div>

    </div>
)

export default userBasic;