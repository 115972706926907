import React, { useEffect, useState } from "react";
import { getCategoryByUserId } from "../scoreConfig/actionCreatorScore";
import Popup from '../../../presentationals/Popup/Popup.component';
import Skeleton from "react-loading-skeleton";
import Nodata from '../../../../images/nodata.png';
import CreditPopup from "../CreditModule/categoriesPopup";
import Loader from '../../../presentationals/Loader/DotLoader.component'
import NoneditableScore from "../CreditModule/noneditablescore";

const CategorHistory = ({ allDetail, admin }) => {
    const [policyList, setPolicyList] = useState([]);
    const [sidePopUp, setSidepopup] = useState(false);
    const [popupStatus, setpopupStatus] = useState('');
    const [loaderState, setLoader] = useState(false);
    const [categoriesPopup, setCategoriesPopup] = useState(false);
    const [viewScore, setViewScore] = useState(false);

    useEffect(() => {
        getdata();
    }, []);

    const getdata = () => {
        setLoader(true)
        getCategoryByUserId(allDetail.userId, callback => {
            setPolicyList(callback);
            setLoader(false)
        });
    };
    const closePup = () => {
        setCategoriesPopup(false)
        getdata()
    }

    const closePopup = () => {
        setSidepopup(false);
    };
    const OpenPopup = () => {
        setCategoriesPopup(!categoriesPopup)
        getdata()

    }

    const uniqueCategories = Array.from(new Set(policyList.map(policy => policy.categoryName)));

    return (
        <div className="container-fluid">

            {sidePopUp &&
                <Popup
                    popupStatus={popupStatus}
                    closePopup={closePopup}
                />
            }
            <button className="brn btn-primary" style={{ borderRadius: '5px' }} onClick={() => OpenPopup()}>{categoriesPopup ? 'View' : 'Edit'} Score </button>
            {categoriesPopup === false && uniqueCategories.length > 0 &&
                <div className="excel-table">
                    <table>
                        <thead>
                            <tr>

                                <th>Score Particulars</th>
                                <th>Group</th>
                                <th>Report</th>
                                <th>Score</th>
                                <th>Comment</th>
                                <th>Creditor Score</th>
                                <th>Modified By</th>
                            </tr>
                        </thead>
                        <tbody>
                            {uniqueCategories.map((category, index) => {
                                const categoryPolicies = policyList.filter(policy => policy.categoryName === category);
                                return (
                                    <tr key={index}>

                                        <td className="text-left">
                                            {categoryPolicies.map((policy, idx) => (
                                                <React.Fragment key={idx}>
                                                    {policy.scoreType}
                                                    {idx !== categoryPolicies.length - 1 && <><br /><br /></>}
                                                </React.Fragment>
                                            ))}
                                        </td>
                                        <td className="text-left">{category}</td>
                                        <td className="text-left">
                                            {categoryPolicies.map((policy, idx) => (
                                                <React.Fragment key={idx}>
                                                    {policy.scoreIncludedFlag === true ? 'Yes' : 'No'}
                                                    {idx !== categoryPolicies.length - 1 && <><br /><br /></>}
                                                </React.Fragment>
                                            ))}
                                        </td>
                                        <td className="text-left">
                                            {categoryPolicies.map((policy, idx) => (
                                                <React.Fragment key={idx}>
                                                    {policy.score}
                                                    {idx !== categoryPolicies.length - 1 && <><br /><br /></>}
                                                </React.Fragment>
                                            ))}
                                        </td>
                                        <td className="text-left">
                                            {categoryPolicies.map((policy, idx) => (
                                                <React.Fragment key={idx}>
                                                    {policy.comment}
                                                    {idx !== categoryPolicies.length - 1 && <><br /><br /></>}
                                                </React.Fragment>
                                            ))}
                                        </td>
                                        <td className="text-left">
                                            {categoryPolicies.map((policy, idx) => (
                                                <React.Fragment key={idx}>
                                                    {policy.creditorScoring}
                                                    {idx !== categoryPolicies.length - 1 && <><br /><br /></>}
                                                </React.Fragment>
                                            ))}
                                        </td>
                                        <td className="text-left">
                                            {categoryPolicies.map((policy, idx) => (
                                                <React.Fragment key={idx}>
                                                    {policy.editedBy}
                                                    {idx !== categoryPolicies.length - 1 && <><br /><br /></>}
                                                </React.Fragment>
                                            ))}
                                        </td>

                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            }
            {categoriesPopup ?
                <CreditPopup admin={admin} allDetail={allDetail} close={closePup} />
                : ""}
            {categoriesPopup === false && !uniqueCategories.length > 0 ?
                <NoneditableScore  admin={admin} allDetail={allDetail} close={closePup}/>

                : ""}
            {loaderState ?
                <Loader />
                // <div className='excel-table'>
                //     <table>
                //         <tbody>
                //             {[...Array(5)].map((_, i) => (
                //                 <tr key={i}>
                //                     <td><Skeleton height={40} /></td>
                //                     <td><Skeleton height={40} /></td>
                //                     <td><Skeleton height={40} /></td>
                //                     <td><Skeleton height={40} /></td>
                //                     <td><Skeleton height={40} /></td>
                //                     <td><Skeleton height={40} /></td>
                //                 </tr>
                //             ))}
                //         </tbody>
                //     </table>
                // </div>
                : ""}
            {loaderState === false && uniqueCategories.length === 0 && categoriesPopup === false &&
                <div className="text-center">
                    {/* <img src={Nodata} alt="No Data" style={{ width: '20%' }} /> */}
                </div>
            }
        </div>
    );
};

export default CategorHistory;
