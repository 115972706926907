import React from 'react'
import DatePicker from 'react-datepicker';

const userAgreement = ({ agreementHandler, agreementInput, certificateNoSaveHandler,
    certificateNoValue, disburseDate, disburseDateDisable, disburseDateValue,
    uploadStampDuty }) => (
        <div className="container-fluid crm-user-cibil max-width-500px">
            <h3 className="text-center blue-text">Input for Agreement</h3>
            <div className="row form-group">
                <div className="col-sm-6 col-xs-12">
                    <label className="font-12px">Certificate No.</label>
                    <input type="text" className="form-control" placeholder="Enter Certificate Number" onChange={e => agreementInput(e, 'certiNo')} value={certificateNoValue}></input>
                </div>
                <div className="col-sm-6 col-xs-12">
                    <label className="font-12px">Disburse Date</label>
                    <DatePicker
                        value={disburseDateValue}
                        selected={disburseDate}
                        onChange={e => agreementInput(e, 'disburseDate')}
                        className="form-control"
                        placeholderText="select Date"
                        dateFormat="DD-MM-YYYY"
                        disabled={disburseDateDisable}
                    />
                </div>
            </div>
            <div className="row form-group">
                <div className="col-xs-12">
                    <button className="profile-save-btn center-block" onClick={e => certificateNoSaveHandler()}>
                        <span className="fa fa-save" />&nbsp;&nbsp;Save
                        </button>
                </div>
            </div>
            <div className="row form-group">
                <div className="col-sm-6 col-xs-12">
                    <label htmlFor="stampDuty" className="profile-save-btn" data-toggle="tooltip" data-placement="bottom" title="Upload Stamp Duty">
                        <span className="fa fa-cloud-upload" />&nbsp;&nbsp;Upload Stamp Duty
                </label>
                    <input multiple="multiple" accept="application/pdf" type="file" className="form-control display-none" id="stampDuty" onChange={(e) => uploadStampDuty(e)} />
                </div>
                <div className="col-sm-6 col-xs-12">
                    <button className="profile-save-btn center-block" onClick={e => agreementHandler('save')}><span className="fa fa-download" />&nbsp;&nbsp;Download Agreement</button>
                </div>
            </div>
            {/* <h3 className="text-center blue-text">Download Agreement</h3>
        <div className="row form-group">
            <div className="col-xs-12">
                <button className="profile-save-btn center-block" onClick={e => agreementHandler('save')}><span className="fa fa-download" />&nbsp;&nbsp;Agreement</button>
            </div>
        </div> */}
            <h3 className="text-center blue-text">Send Agreement</h3>
            <div className="row form-group">
                <div className="col-xs-12">
                    <label className="font-12px">Email Id.</label>
                    <div className="display-flex">
                        <input type="email" className="form-control" placeholder="Enter Email Id." onChange={e => agreementInput(e, 'senderId')} />
                        <button className="profile-save-btn margin-0" onClick={e => agreementHandler('send')}><span className="fa fa-paper-plane" /></button>
                    </div>
                </div>
            </div>
        </div>
    )

export default userAgreement;