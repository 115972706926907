import React, { Component } from 'react'
import UserBasic from '../../../../presentationals/Admin/CustomerPages/UserBasic.component'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { APIS } from '../../../../../utils/api-factory';
import Loader from '../../../../presentationals/Loader/Loader.component'
import CreditScore from '../UserCreditScoring.container'
import Popup from '../../../../presentationals/Popup/Popup.component';
import { _formatDateInDash } from '../../../../../utils/validation';
import { sendverificationRequestApi } from '../../AdminActionCreator.component'
import Confirmation from '../../../../presentationals/Popup/ConfirmationPopup.component'
import EditProfile from '../../../../containers/Admin/CustomerPages/EditUserDetails.container'
import Documents from '../../../../containers/Admin/CustomerPages/UserDocs.container'
import Basic from '../CardsUtilityPages/BasicDetailsUserCard.container'
import Cibil from '../../../../containers/Admin/CustomerPages/UserCibil.container'
import Scoring from '../../../../containers/Admin/CustomerPages/Scoring.container'
import Crif from '../../../../containers/Admin/CustomerPages/Crif.container'
import ConfirmationPopup from '../../../../presentationals/Popup/crifConfirmationPopUp.component'
import BadaBuisnessEditProfile from '../../../../containers/Admin/CustomerPages/BadabuisnessEdit.container'

import { ROLE } from '../../../../../utils/Constant'

class BasicUser extends Component {
    constructor(props) {
        super(props);
        this.state = {
            confirmationPopup: false,
            confirmationText: '',
            loaderState: false,
            creditScoringPopup: false,
            popupState: false,
            popupStatus: '',
            senderMailId: '',
            allDetail: this.props.allDetail,
            mailIdForVerification: '',
            pageViewState: 'basicPage',
            linkState: false
        }
    }

    userLinkHandler() {
        this.setState({ linkState: true })
    }

    render() {
        const { allDetail, admin, showEditPorfile, showCrifDataTab, userDetail } = this.props;
        return (
            <div>
                <div className="btn-group-tab">
                    {/* <button className={this.state.pageViewState == 'basicPage' ? 'active' : ''} onClick={(e) => this.sendHandler('basicPage')}>Basic Details</button> */}
                    {/* {userDetail.loanFrom !== 'bazarBussiness' && admin != null && admin.rolelist.indexOf(ROLE.DOCS) >= 0 ?
                        <button className={this.state.pageViewState == 'docsPage' ? 'active' : ''} onClick={(e) => this.sendHandler('docsPage')}>Documents</button>
                        : ""}
                    {userDetail.loanFrom !== 'bazarBussiness' ?
                        <button className={this.state.pageViewState == 'cibil' ? 'active' : ''} onClick={(e) => this.sendHandler('cibil')}>Cibil</button>
                        : ""
                    }        */}
                                 {/* {showCrifDataTab != false && admin != null && admin.rolelist.indexOf(ROLE.CRIF_DATA) >= 0 ?
                        <button className={this.state.pageViewState == 'popUp' ? 'active' : ''} onClick={(e) => this.sendHandler('popUp')}>CRIF</button>
                        : null} */}
                    {/* {showEditPorfile != false && admin != null && admin.rolelist.indexOf(ROLE.EDIT) >= 0 ?
                    <button className={this.state.pageViewState == 'scoring' ? 'active' : ''} onClick={(e) => this.sendHandler('scoring')}>Scoring</button>
                    :null} */}
                    {/* {showEditPorfile != false && admin != null && admin.rolelist.indexOf(ROLE.EDIT) >= 0 ?
                        <button className={this.state.pageViewState == 'editPage' ? 'active' : ''} onClick={(e) => this.sendHandler('editPage')}>Edit Profile</button>
                        : null} */}

                </div>

                {this.state.pageViewState == 'basicPage' ?
                    <div>
                        {/* {userDetail.loanFrom !== 'bazarBussiness' && showEditPorfile != false && admin != null && admin.rolelist.indexOf(ROLE.EDIT) >= 0 ?
                            <Scoring
                                getData={this.props.getData.bind(this)}
                                allDetail={allDetail}
                                admin={admin} />
                            : ""} */}
                        <Basic
                            userLinkHandler={this.userLinkHandler.bind(this)}
                            linkState={this.state.linkState}
                            userDetail={userDetail}
                            closePopup={this.closePopup.bind(this)}
                            popupStatus={this.state.popupStatus}
                            allDetail={this.state.allDetail}
                            getCreditPopupState={this.getCreditPopupState.bind(this)}
                            creditScoringPopup={this.state.creditScoringPopup}
                            admin={admin}
                            cancelLoanConfirm={this.cancelLoanConfirm.bind(this)}
                            confirmationText={this.state.confirmationText}
                            arr={this.state.arr}
                        />
                    </div>
                    : this.state.pageViewState == 'editPage' && userDetail.loanFrom !== 'bazarBussiness' ?
                        <EditProfile
                            getData={this.props.getData.bind(this)}
                            allDetail={allDetail}
                            admin={admin}
                            userDetails={this.props.userDetail} />
                        : this.state.pageViewState == 'editPage' && userDetail.loanFrom === 'bazarBussiness' ?
                            <BadaBuisnessEditProfile
                                getData={this.props.getData.bind(this)}
                                allDetail={allDetail}
                                admin={admin}
                                userDetails={this.props.userDetail} />
                            : this.state.pageViewState == 'cibil' ?
                                <Cibil
                                    allDetail={this.state.allDetail}
                                />
                                : this.state.pageViewState == 'popUp' ?
                                    <ConfirmationPopup
                                        ConfirmCrifDataGenerate={this.ConfirmCrifDataGenerate.bind(this)}
                                    />
                                    : this.state.pageViewState == 'crifData' ?
                                        <Crif
                                            allDetail={this.state.allDetail}
                                        />
                                        : this.state.pageViewState == 'docsPage' ?
                                            <Documents
                                                markCompleteBtn={this.props.markCompleteBtn}
                                                allDetail={allDetail}
                                                admin={admin} />
                                            // : this.state.pageViewState == 'scoring' ?
                                            //     <Scoring
                                            //     getData={this.props.getData.bind(this)}
                                            //         allDetail={allDetail}
                                            //         admin={admin} />
                                            : null}
            </div>
        )
    }




    sendHandler(type) {
        this.setState({
            pageViewState: type,
        })
    }
    ConfirmCrifDataGenerate(dataType) {
        if (dataType == 'yes') {
            this.setState({
                pageViewState: 'crifData'
            })

        } if (dataType == 'no') {
            this.setState({
                pageViewState: 'basicPage'
            })
        }
    }


    cancelLoanConfirm(conType) {
        if (conType == 'yes') {
            sendverificationRequestApi(this.state.mailIdForVerification,
                (callBack) => {
                    if (callBack == 'success') {
                        this.setState({
                            popupState: true,
                            popupStatus: 'Mail has been successfully sent to user.',
                            confirmationPopup: false
                        })
                        this.removePopup()
                    } else {
                        this.setState({
                            popupState: true,
                            popupStatus: 'Please try again.',
                            confirmationPopup: false
                        })
                        this.removePopup()
                    }
                })
        } else if (conType == 'no') {
            this.setState({ confirmationPopup: false })
        }
    }

    removePopup() {
        setTimeout(function () {
            this.setState({ popupState: false });
        }.bind(this), 5000)
    }

    closePopup() {
        this.setState({ popupState: false });
    }


    getCreditPopupState(state) {
        this.props.getCreditPopupState(state)
    }

    detailBackHandler() {
        this.props.basicBackHandler()
    }

    verifyMail(mailId) {
        this.setState({
            mailIdForVerification: mailId,
            confirmationText: 'Are you sure for send verification mail to this user?',
            confirmationPopup: true
        })

    }

    componentWillUnmount() {

    }

}
const mapStateToProps = ({ pendingDetail }) => {
    return {
        pendingDetail: pendingDetail
    };
};

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
    }, dispatch);
};
export default connect(mapStateToProps, mapDispatchToProps)(BasicUser);