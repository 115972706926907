import React from 'react'
import { ROLE } from '../../../utils/Constant'
import HomeImage from '../../../images/generate-lead (1).png'
import SecondLogo from '../../../images/Group (5).png'
import ThirdLogo from '../../../images/Vector (25).png'
import FourthLogo from '../../../images/marketing.png'
import FifthLogo from '../../../images/Layer 4.png'
import SixthLogo from '../../../images/access-control.png'
import SeventhLogo from '../../../images/dashboard (1).png'
import EighthLogo from '../../../images/Digital Key.png'
import NinthLogo from '../../../images/Group (6).png'

const SettingsNavOptions = ({
    navItemClick,
    pageViewHandler,
    admin,
    menuOpenState,
    generateReportState,
    subHandleTab,
    losSubHandle, pageViewState, lmsSubTab
}) => (
    <div className='phocket-crm-menu new-menu animate-crm-menu  ' id='CRMMenu'>
        <ul className='mail-list-new'>



            {/* <div className='mail-list'>
                <li
                    className={menuOpenState === 'lms' ? 'mail-list-item-active' : 'mail-list-item'}
                    onClick={e => navItemClick('lms')}
                >
                    <img src={SecondLogo} width='20px' style={{ marginTop: '-4px' }} /><span style={{ marginLeft: '10px' }}> LMS </span>{menuOpenState === 'lms' ? <i class="fa fa-caret-up" aria-hidden="true" style={{ float: 'right', color: '#fff' }}></i> : <i class="fa fa-caret-down" aria-hidden="true" style={{ float: 'right', color: '#fff' }}></i>}
                </li>
            </div> */}
            {menuOpenState == 'lms' ?
                <>
                    {admin.rolelist != undefined ?
                        admin.rolelist.indexOf(ROLE.Disbursed_Leads) >= 0 ?
                            <li
                                className={pageViewState === 'recoveryDisburse' ? 'sub-list-item-active' : 'sub-list-item'}
                                onClick={e => pageViewHandler('recoveryDisburse')}
                            >
                                Disbursed Leads
                            </li>
                            : "" : ""}
                    {admin.rolelist != undefined ?
                        admin.rolelist.indexOf(ROLE.Incoming_Leads) >= 0 ?
                            <li
                                className={pageViewState === 'recoveryIncoming' ? 'sub-list-item-active' : 'sub-list-item'}
                                onClick={e => pageViewHandler('recoveryIncoming')}
                            >
                                Incoming List
                            </li>
                            : "" : ""}
                    {admin.rolelist != undefined ?
                        admin.rolelist.indexOf(ROLE.Nach_Registration) >= 0 ?
                            <li
                                className={pageViewState === 'nachRegistrationReport' ? 'sub-list-item-active' : 'sub-list-item'}
                                onClick={e => pageViewHandler('nachRegistrationReport')}
                            >
                                NACH Registration
                            </li>
                            : "" : ""}
                    {admin.rolelist != undefined ?
                        (admin.rolelist.indexOf(ROLE.Bulk_Nach_Bounce) >= 0 || admin.rolelist.indexOf(ROLE.Initiate_Bulk_Payment) >= 0 || admin.rolelist.indexOf(ROLE.Bulk_Paid) >= 0 || admin.rolelist.indexOf(ROLE.Bulk_Nach_Status_Update) >= 0) ?
                            <>
                                <div className='mail-list' style={{ marginLeft: '20px' }}>
                                    <li
                                        className={lmsSubTab ? 'mail-list-item-active' : 'mail-list-item'}
                                        // onClick={e => navItemClick('bulkOps')}
                                        onClick={e => subHandleTab('bulkOps', 'lms')}>
                                        <span style={{ marginLeft: '5px' }}> Bulk Operations </span>{lmsSubTab ? <i class="fa fa-caret-up" aria-hidden="true" style={{ float: 'right', color: '#fff' }}></i> : <i class="fa fa-caret-down" aria-hidden="true" style={{ float: 'right', color: '#fff' }}></i>}
                                    </li>
                                </div>
                                {lmsSubTab ?
                                    <>
                                        {admin.rolelist != undefined ?
                                            admin.rolelist.indexOf(ROLE.Bulk_Nach_Bounce) >= 0 ?
                                                <li
                                                    className={pageViewState === 'bulkNachBounceByCsv' ? 'sub-list-item-active' : 'sub-list-item'}
                                                    onClick={e => pageViewHandler('bulkNachBounceByCsv')}
                                                >
                                                    Bulk Nach bounce By CSV
                                                </li>
                                                : "" : ""}
                                        {admin.rolelist != undefined ?
                                            admin.rolelist.indexOf(ROLE.Initiate_Bulk_Payment) >= 0 ?
                                                <li
                                                    className={pageViewState === 'bulkPayByRazorPay' ? 'sub-list-item-active' : 'sub-list-item'}
                                                    onClick={e => pageViewHandler('bulkPayByRazorPay')}
                                                >
                                                    Initiate Bulk Payment By CSV
                                                </li>
                                                : "" : ""}
                                        {admin.rolelist != undefined ?
                                            admin.rolelist.indexOf(ROLE.Bulk_Paid) >= 0 ?
                                                <li
                                                    className={pageViewState === 'covertToPaid' ? 'sub-list-item-active' : 'sub-list-item'}
                                                    onClick={e => pageViewHandler('covertToPaid')}
                                                >
                                                    Convert to Paid
                                                </li>
                                                : "" : ""}
                                        {admin.rolelist != undefined ?
                                            admin.rolelist.indexOf(ROLE.Bulk_Nach_Status_Update) >= 0 ?
                                                <li
                                                    className={pageViewState === 'addNachStatus' ? 'sub-list-item-active' : 'sub-list-item'}
                                                    onClick={e => pageViewHandler('addNachStatus')}
                                                >
                                                    Update Nach Status By Csv
                                                </li>
                                                : "" : ""}


                                    </>
                                    : ""}
                            </> : "" : ""}
                    {admin.rolelist != undefined ?
                        admin.rolelist.indexOf(ROLE.Approved_To_Disbursed_Disbursed_By_ICICI) >= 0 ?
                            <li
                                className={pageViewState === 'icici' ? 'sub-list-item-active' : 'sub-list-item'}
                                id='garden'
                                onClick={e => pageViewHandler('icici')}
                            >
                                Disburse By ICICI
                            </li>
                            : "" : ""}
                </> : ""}

        </ul>
    </div>
)

export default SettingsNavOptions