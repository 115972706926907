import React from 'react'

const ConfirmationPopup = ({  submit, reasonChangeHandler ,close}) => (
    <div className="background-blur">
        <div className="popup-main small-popup container-fluid max-width-500px dbl-border">
        <i className='fa fa-close' style={{color:'red',fontSize:'18px',float:'right'}} onClick={()=>close()}></i>

            <div className="row from-group">
                <div className="col-xs-12">
                    <h4 className="text-center">Block Card</h4>
                </div>
            </div>
            <div className="row from-group text-center">
                <div className='col-sm-12 col-xs-12'>
                    <label>Enter Reason for Card Block Card</label>
                    <input className='form-control' placeholder='Enter Reason' onChange={(e)=>reasonChangeHandler(e)}/>
                   
                </div>
            </div>
            <div className='row'>
                <div className='text-center'>
                    <button className='profile-save-btn' onClick={submit}>SAVE</button>
                </div>
            </div>
        </div>
    </div>
)

export default ConfirmationPopup;