import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import LatestDocUi from '../../../presentationals/Admin/ApplicationLeads/LatestDocLead.component';
import {
    getistByNumber, getlistByEmail, getlistByApplication, getLatestDocUpload,
    getIncomingPaymentByDate, getUserDetailByLoanId, saveNachDataApi
} from '../AdminActionCreator.component';
import UserDetail from '../CustomerPages/UserDetailMain.container';
import { APIS } from '../../../../utils/api-factory';
import { STRING } from '../../../../utils/Constant'
import ConfirmationPapup from '../../../presentationals/Popup/ConfirmationPopup.component'
import { _preFormatDate } from '../../../../utils/validation'
import MainDetailsPopup from '../CustomerPages/MainDetailPopup.container'
import Popup from '../../../presentationals/Popup/Popup.component';

class LatestDoc extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userState: true,
            viewState: false,
            allDetail: "",
            userDetail: "",
            followUpStatus: STRING.SELECT_ALL,
            forwordPageState: 1,
            loadarState: false,
            viewDetailPopupState: false,
            viewPopupDetail: '',
            logsUserId: '',
            dateToShow: '',
            dateToSend: '',
            actionButtonState: false,
            TXNConfirmationPopup: false,
            confirmationText: '',
            loanIdForTXN: '',
            popupState: false,
            popupStatus: '',
            loadarState: false,
            FilteredData: [],
            pageIndexToShow: 0,
            searchByAppNo: '',
            searchByemail: '',
            searchBymobile: ''
        }
    }

    getLeads() {
        this.setState({ loadarState: true })
        this.props.getLatestDocUpload(this.state.forwordPageState,
            (callBack) => {
                this.setState({ loadarState: false })
            })
    }

    componentDidMount() {
        this.getLeads()
    }

    render() {
        const { latestDocUpload, admin } = this.props;
        return (
            <div className="full-height">
                {this.state.userState == true ?
                    <LatestDocUi
                        userDetailHandler={this.userDetailHandler.bind(this)}
                        allUserDetail={latestDocUpload}
                        forwordpage={this.forwordpage.bind(this)}
                        backpage={this.backpage.bind(this)}
                        dataFilter={this.dataFilter.bind(this)}
                        userViewHandler={this.userViewHandler.bind(this)}
                        admin={admin}
                        loadarState={this.state.loadarState}
                        refreshpage={this.refreshpage.bind(this)}
                        dateToShow={this.state.dateToShow}
                        loanVarificationHandler={this.loanVarificationHandler.bind(this)}
                        loanTXNHandler={this.loanTXNHandler.bind(this)}
                        getFilterValue={this.getFilterValue.bind(this)}
                        FilteredData={this.state.FilteredData}
                        getPageValue={this.getPageValue.bind(this)}
                        pageIndexToShow={this.state.pageIndexToShow}
                        searchByAppNo={this.state.searchByAppNo}
                        searchByemail={this.state.searchByemail}
                        searchBymobile={this.state.searchBymobile}
                        dateToSend={this.state.dateToSend}
                    /> : ""}
                {this.state.viewState == true ?
                    <UserDetail
                        allDetail={this.state.allDetail}
                        userDetail={this.state.userDetail}
                        admin={admin}
                        backHandler={this.backHandler.bind(this)}
                        actionButtonState={this.state.actionButtonState}
                    />
                    : ""}
                {this.state.viewDetailPopupState == true ?
                    <MainDetailsPopup
                        getviewDetailPopupState={this.getviewDetailPopupState.bind(this)}
                        admin={admin}
                        allDetail={this.state.viewPopupDetail}
                    />
                    : ""}
                {this.state.TXNConfirmationPopup == true ?
                    <ConfirmationPapup
                        cancelLoanConfirm={this.cancelLoanConfirm.bind(this)}
                        confirmationText={this.state.confirmationText} />
                    : ""}
                {this.state.popupState == true ?
                    <Popup
                        closePopup={this.closePopup.bind(this)}
                        popupStatus={this.state.popupStatus}
                    />
                    : ""}
            </div>
        )
    }

    getFilterValue(filtered) {
        this.setState({ FilteredData: filtered })
    }

    getPageValue(pageIndex) {
        this.setState({ pageIndexToShow: pageIndex })
    }

    loanVarificationHandler(loanId) {
        fetch(APIS.LOAN_VARIFICATION + loanId)
            .then(res => res.text())
            .then(text => {
                this.setState({
                    popupState: true,
                    popupStatus: text,
                })
                this.removePopup();
            })
    }

    loanTXNHandler(loanId) {
        this.setState({
            TXNConfirmationPopup: true,
            confirmationText: 'Are you sure for this TRANSACTION',
            loanIdForTXN: loanId
        })
    }

    cancelLoanConfirm(btnType) {
        if (btnType == 'yes') {
            fetch(APIS.LOAN_TRANSACTION_SCHEDULE + this.state.loanIdForTXN)
                .then(res => res.text())
                .then(text => {
                    this.setState({
                        popupState: true,
                        popupStatus: text,
                        TXNConfirmationPopup: false,
                    })
                    this.removePopup();
                })
        } else if (btnType == 'no') {
            this.setState({
                TXNConfirmationPopup: false
            })
        }
    }

    removePopup() {
        setTimeout(function () {
            this.setState({ popupState: false });
        }.bind(this), 5000)
    }

    closePopup() {
        this.setState({ popupState: false });
    }

    refreshpage() {
        this.getLeads()
    }

    dataFilter(e, type) {
        const { admin } = this.props
        if (type == "applicationNo") {
            this.setState({ searchByAppNo: e.target.value })
            if (e.target.value.length > 4) {
                this.setState({
                    loadarState: true
                })
                this.props.getlistByApplication(e.target.value, 1, '', admin,
                    (callBack) => {
                        this.setState({
                            loadarState: false
                        })
                    })
            }
        } else if (type == "email") {
            this.setState({ searchByemail: e.target.value })
            if (e.target.value.length > 2) {
                this.setState({
                    loadarState: true
                })
                this.props.getlistByEmail(e.target.value, 1, '', admin,
                    (callBack) => {
                        this.setState({
                            loadarState: false
                        })
                    })
            }
        } else if (type == "mobileNo") {
            this.setState({ searchBymobile: e.target.value })
            if (e.target.value.length > 9) {
                this.setState({
                    loadarState: true
                })
                this.props.getistByNumber(e.target.value, 1, '', admin,
                    (callBack) => {
                        this.setState({
                            loadarState: false
                        })
                    })
            }
        }
        //  else if (type == "Date") {
        //     this.setState({
        //         dateToShow: e,
        //         dateToSend: _preFormatDate(e)
        //     })
        //     this.props.getIncomingPaymentByDate(_preFormatDate(e), 1,
        //         (callBack) => {
        //             this.setState({
        //                 loadarState: false
        //             })
        //         })
        // }
        if (type != "Date" && e.target.value == "") {
            this.getLeads()
        }
    }
    forwordpage() {
        this.setState({
            forwordPageState: this.state.forwordPageState + 1,
            pageIndexToShow: 0
        }, () => this.getLeads())

    }
    backpage() {
        if (this.state.forwordPageState > 1) {
            this.setState({
                forwordPageState: this.state.forwordPageState - 1,
                pageIndexToShow: 0
            }, () => this.getLeads())
        }
    }
    componentDidUpdate() {
        const { latestDocUpload, admin } = this.props;
        if (latestDocUpload.length > 0) {
            if (document.getElementById("forword") != null)
                document.getElementById("forword").disabled = false;
        } else {
            if (document.getElementById("forword") != null)
                document.getElementById("forword").disabled = true;
        }
    }

    backHandler() {
        const { admin } = this.props
        this.setState({
            userState: true,
            viewState: false,
        })
        if (this.state.searchByAppNo != '') {
            this.props.getlistByApplication(this.state.searchByAppNo, 1, '', admin,
                (callBack) => {
                    this.setState({
                        loadarState: false
                    })
                })
        } else if (this.state.searchByemail != '') {
            this.props.getlistByEmail(this.state.searchByemail, 1, '', admin,
                (callBack) => {
                    this.setState({
                        loadarState: false
                    })
                })
        } else if (this.state.searchBymobile != '') {
            this.props.getistByNumber(this.state.searchBymobile, 1, '', admin,
                (callBack) => {
                    this.setState({
                        loadarState: false
                    })
                })
        }
        //  else if (this.state.dateToSend != '') {
        //     this.props.getIncomingPaymentByDate(this.state.dateToSend, 1,
        //         (callBack) => {
        //             this.setState({
        //                 loadarState: false
        //             })
        //         })
        // }
        else {
            this.getLeads()
        }
    }
    userDetailHandler(allDetail) {
        getUserDetailByLoanId(allDetail.loanId,
            (callBack) => {
                this.setState({
                    userState: false,
                    viewState: true,
                    allDetail: callBack,
                    userDetail: allDetail
                })
            })
    }
    userViewHandler(allDetail) {
        getUserDetailByLoanId(allDetail.loanId,
            (callBack) => {
                this.setState({
                    viewDetailPopupState: true,
                    viewPopupDetail: callBack,
                    userDetail: allDetail
                })
            })
    }
    getviewDetailPopupState(state) {
        this.setState({
            viewDetailPopupState: state,
        })
    }

}

const mapStateToProps = ({ latestDocUpload }) => {
    return {
        latestDocUpload: latestDocUpload,
    };
};

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        getistByNumber,
        getlistByEmail,
        getlistByApplication,
        getLatestDocUpload,
        getIncomingPaymentByDate
    }, dispatch);
};


export default connect(mapStateToProps, mapDispatchToProps)(LatestDoc);