import React, { useState, useEffect } from 'react'
import Loader from '../../../presentationals/Loader/DotLoader.component';
import { GetThirdPartyHitsCountByApplicationId } from "../AdminActionCreator.component";
export default function APITracker({ allDetail, userDetail }) {
    const [loader, setloader] = useState(false);
    const [totalcost, settotalcost] = useState('0');
    const [data, setdata] = useState([]);
    useEffect(() => {

        setloader(true);
        GetThirdPartyHitsCountByApplicationId(userDetail?.applicationId, (response) => {
            console.log(response);
            if (response && Object.keys(response)?.length > 0 && response?.apiList?.length > 0) {
                setdata(response?.apiList);
                settotalcost(response?.totalCost)
            }
            else {
                setdata([]);
            }
            setloader(false);
        })
    }, [])

    return (
        <>
            {loader ?
                <Loader />
                :

                <div className="container-fluid APITracker animated fadeIn " style={{ marginTop: '100px' }}>
                    <div className="row form-group ">
                        <div className="col-xs-12 overflow-auto">
                            {data && data?.length > 0 ?
                                <div className="outer-table">
                                    <table className="table text-center">
                                        <thead className='table-heads'>
                                            <tr>
                                                <th style={{ borderTopLeftRadius: '10px' }}>Order</th>
                                                <th>API Name</th>
                                                <th>No. of Hits</th>
                                                <th>Success Count</th>
                                                <th>Failure Count</th>
                                                <th style={{ borderTopRightRadius: '10px' }}>Cost</th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            {data && data?.length > 0 ? data?.map((value, i) => {
                                                return (

                                                    <tr key={i}>
                                                        <td>{value?.order}</td>
                                                        <td>{value?.child || '-'}</td>
                                                        <td>{value?.count || '0'}</td>
                                                        <td>{value?.successCount || '0'}</td>
                                                        <td>{value?.failureCount || '0'}</td>
                                                        <td>{value?.cost || '0'}</td>
                                                    </tr>
                                                )
                                            }
                                            ) : ''}
                                            <tr className="Totalcost-row">
                                                <td style={{ borderBottomLeftRadius: '10px' }}>Total Cost</td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td style={{ borderBottomRightRadius: '10px' }}>{'₹ ' + totalcost || '₹ 0'}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                :
                                <h3 className='text-center' style={{ fontSize: '40px', color: '#0e5768', marginTop: '30px' }}>No Data Found</h3>}
                        </div>

                    </div>
                </div>
            }
        </>
    )
}
