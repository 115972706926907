import React from 'react';
import BackLogo from '../../../images/rewampimages/Chevron.png'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import LeadsMainPage from './ApplicationLeads/LeadsMainPage.container'
import PhocketMini from './ApplicationLeads/PhocketMini.container'
import LeadsTestingPage from './ApplicationLeads/LoanApprovalLeads.testing'
import RecoveryIncomingPayment from './Recovery/IncomingPayment.container'
import RecoveryPage from './Recovery/Recovery.container'
import DisbursePage from './ApplicationLeads/disburseleadsNew.container'
import ChequeBouncePage from './Recovery/ChequeBounce.container'
import NachBouncePage from './Recovery/NachBounce.container'
import NoticePage from './Recovery/Notice.container'
import ApplicationStatus from './ApplicationLeads/ApplicationStatus.container'
import Dashboard from './DashboardAndReports/Dashboard.container'

import DashboardNexopay from './DashboardAndReports/Dashboard.container'
import Offers from './Promotions/PromotionalCodeEntry.container'
import PromoData from './Promotions/PromotionalData.container'
import ReferralPage from './Promotions/Referral.container'
import BulkCommLog from './BulkActions/BulkCommunicationLogs.container'
import BulkCommStatus from './BulkActions/CommunicateByStatus.container'
import BulkCommCSV from './BulkActions/CommunicateByCsv.container'
import IncompleteApps from './BulkActions/IncompleteApplication.container'
import BulkStatus from './BulkActions/BulkStatusChange.container'
import InstalledApp from './Utility/InstalledApp.container'
import ChangePassword from './Settings/ChangePassword.container'
import AmbassadorLeads from './Ambassador/AmbassadorLeads.container'
import AmbRegister from './Ambassador/NewAmbassador.container'
import CustomSearchRep from './DashboardAndReports/CustomSearch.container'
import LoyalityOffer from './Loyality/LoyalityOffers.container'
import UserLoyality from './Loyality/LoyalityList.container'
import ReportByChoice from './DashboardAndReports/ReportByChoice.container';
import GenerateReportNew from './DashboardAndReports/2monthreport'
import CalcUtility from './Utility/CalcUtility.container'
import EmiCalculator from './Utility/EMICalculator.container'
import WebUtility from './Utility/WebContent.container'
import MarchantAddOffer from './AdminCoupons/CouponMain.container';
import CouponStats from './Promotions/CouponStats';
import AutoLogs from './BulkActions/AutomationLogs.container'
import FieldBoyRegistration from './FieldBoyApp/Registration.container'
import UpdateByCSV from './BulkActions/UpdateUsingCSV.container'
import ReportLogs from './DashboardAndReports/GenerateReportLogs.container'
import IBRPage from './IVR/IBR.container'
import OBRPage from './IVR/OBR.container'
import ScheduledCallPage from './IVR/ScheduledCall.container'
import AddPinCodeUi from './Utility/AddPinCode.container'
import UpdateNachUi from './Utility/BulkNachUpdate.container'
import LeadManagementUi from './LeadManagement/newLeadmanagementt.conatiner'
import BulkAssignUi from './Settings/BulkAssign.container'
import ThirdPartyRegistration from '../campaigntrack/Registration.Container'
import TrackRecords from '../campaigntrack/CampaignTrackAdmin.Container'
import AddCampaignUrl from '../campaigntrack/AddCampaignUrl.Container'
import Registereduser from '../campaigntrack/RegisterUserDetails.Container';
import RoleAssign from '../Admin/Settings/NewRoleAssignMent.container';
import ImportantCompanyRules from '../Admin/Settings/ImportantCompany.container';
import BulkAssign from '../Admin/Settings/BulkAssign.container';
import PriorityCompanyUi from './Utility/PriorityCompany.container'
import AddNbfcUi from './Utility/AddNbfc.container'
import UpdateAccInfoUi from './Utility/UpdateAccountInfo.container'
import UpdateCibilInfoUi from './Utility/UploadCibilInfo.container'
import NachRegistrationUi from './Recovery/NachReport.container'
import Content from '../BlogContent/content.container'
import EmployeeActivity from '../Admin/Settings/EmployeeActivity.container'
import BulkStatusUpdateUi from './Utility/BulkStatusUpdate.container'
import Nbfcmanagement from './NbfcManagement/Tabs.container'
import BIGRAPH from '../Admin/BiGraphs/Doghnut.container';
import Configuration from '../Admin/Configuration/ConfigTabs.container'
import Collection from '../Admin/Configuration/CollectionNotificationMedium.container'

import DisburseByIcici from '../../containers/Admin/BulkActions/DisbursebyiCICI.container'
import BuddyloanReport from '../../containers/Admin/DashboardAndReports/BuddyLoanReport.container'
import Rzorpaydata from '../../containers/Admin/Recovery/RAZORPAYdata.container'
import FlipkartBalance from '../../containers/Admin/DashboardAndReports/FlipkartBalanceCheck.container'
import BadaBuisnessLeads from '../../containers/Admin/ApplicationLeads/BadaBuisnessLeads.container'
import Cards from './CardsUtility/CardDetails.container'
import UploadCardUser from './CardsUtility/uploadCardUser.container'
import CardLeads from './CardsUtility/cardUsers.container'
import ConfigCards from './CardsUtility/Config.container'
import CardLoanLeads from '../../containers/Admin/ApplicationLeads/CardLoanLeads.container'
import UploadTeaUsers from '../../containers/Admin/Tea Garden/uploadUtility.container';
import Teaambassador from '../../containers/Admin/Tea Garden/addAmbassador.container'
import TeaGardenLeads from '../../containers/Admin/ApplicationLeads/TeagardenLeads.container'
import AllTeaGardenAmbassador from '../../containers/Admin/Tea Garden/ListOfAmbassador.container'
import AllTeaGardenAmbassadorGiftCard from '../../containers/Admin/ApplicationLeads/TeaGardenGiftCardsLeads.container'
import StampDuty from './Utility/StampDutyMail.container';
import BranchReport from '../../containers/Admin/DashboardAndReports/BranchData.container'
import InitiateBulkPayment from './Recovery/InitiateBulkPayment.container'
import GiftCardLeads from '../../containers/Admin/ApplicationLeads/giftCardLeads.container'
import BulkNachBounce from '../../containers/Admin/BulkActions/NachBounceBulk.container'
import CollectionRuleuI from '../../containers/Admin/Settings/AssIgnForCollection.container'
import BulkPaymentLeads from '../../containers/Admin/Recovery/BulkPaymentLeads.container'
import IncomingPaymentReport from '../../containers/Admin/Recovery/IncommingPaymentReport.container'
import VreedhiLeads from '../../containers/Admin/ApplicationLeads/vreedhiLeads.container'

import SimilarProfileCollection from './Configuration/ConfigurationSimilarProfile.container'
import ApproveToDisburse from './BulkActions/ApprovedToDisburse.container'
import CommunicateByMobile from './BulkActions/BulkCommunicateByMobile.container'
import ConvertToPaidUi from './BulkActions/CovertToPaid.container'
import AssetUi from './CustomerPages/AssetQuality.container'
import IncomeCollectedUi from './DashboardAndReports/IncomeCollected.container'
import MainConfiguration from '../../../components/containers/Admin/NbfcManagementNew/ConfigurationMain.container'
import AddNbfcPage from '../../../components/containers/Admin/NbfcManagementNew/addNbfc.container'
import AllNbfc from '../../../components/containers/Admin/NbfcManagementNew/AllNbfc.container'
import RazorpayTransfer from '../../../components/containers/Admin/Utility/RazorpayTransfer.container'
import APIConfig from '../../../components/containers/Admin/DashboardAndReports/apiTab'
import SaveManualUi from '../../../components/containers/Admin/DashboardAndReports/saveManualEntry'
import NachRegistrationUiBillDesk from './Recovery/NachRegistrationBilldesk.container'
import BranchData from '../Branch/allBranchData'
import AggregatorRegistration from '../Marketing/registrationForm';
// import MarketingList from '../Marketing/maketingComponents/screens/agreegatorlisting';
import BudgetPlan from '../Marketing/budgetPlan';
import CreditLeadsAnalysis from '../../../components/containers/Admin/ApplicationLeads/CreditAnalysisTab.container'
import RunCampaign from '../../../components/containers/Marketing/maketingComponents/screens/RunCampaign/Mainpage'
import DeviationReport from '../../../components/containers/Marketing/maketingComponents/screens/deviationReport'
import BuisnessAggregator from '../../../components/containers/Marketing/maketingComponents/screens/buisnessAggregator'
import MarketingList from '../Marketing/maketingComponents/screens/tabsagreegator.js';
import MaketingDashboard from '../Marketing/newdesigns/mainTab';
import PolicyMainTabs from './Policy/policymainTab';
import BIPage from '../BuisnessIntelligence/ApplicatonPipelineAnalysis'
import PolicyConfig from './Policy/configurationMainTab';
import RecoveryLogs from '../../containers/Admin/Recovery/Recovery.container'
import Category from './scoreConfig/Category'

import AccountReconciliation from './AccountReconciliation/ARMainTab';
import PerformanceTracker from './DashboardAndReports/PerformanceTracker/configMain';
import AddLoanId from '../Admin/LoanIdadd/newLoanIdUpdate'
import DeleteRazorpayToken from './ApplicationLeads/DeleteRazorpayToken';
import StartStopKyc from './Utility/startStopKyc';
import KycPendingLeads from '../Admin/ApplicationLeads/kycPendingLeads'
import ApprovedLeadsContainer from './BulkActions/approvedleads'; 
import BulkDeactivateDrpr from './BulkActions/bulkDeactivateNachDrp';
import BulkOverDueSms from './BulkActions/bulkOverDueSmsSend';
import BillDeskMandateHit from './BulkActions/billdeskmandateHit';
import StampMain from './stamdutyTracker/mainfiletracker';
import Allpayments from './Payments/allpayments';
import DebtListMain from './DebtCollectionList/DebtListMain';


import ComTab from './Utility/communicationTemplate/comTabs';
import CompleteApplicationTracker from '../Dashboard/completeApplicationsTracker';
// import ExecutorMain from './Utility/Executor/NewExecutor.js/MainExecutor';
import ExecutorMain from './Utility/Executor/ExecutorMain';
import LenderMain from './Settings/LoanUtility/configMain';
import DocProduct from './DoctorProduct/allProduct';
import AllMerchants from './DoctorProduct/allmerchants';
import LoanDashboardMain from './Recovery/LoanDashboards.js/Main';
import MISDashboardMain from './MISDashboard/Main';
import EmployeesTarget from './Targets/mainEmp';
import LandingpageNew from '../Admin/ApplicationLeads/newlandingpage';
import LandingpageOld from '../Admin/ApplicationLeads/landingpage';
import MainExpenses from './Targets/Expenses/tabs';
import BranchBuddyLoanMain from './DashboardAndReports/BranchBuddyLoanMain';
import BusinessManagementMain from './Targets/Business/tabs'
import SaveEmailForm from './emailKey/mainkey';
class CRMMain extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }
    pageHandleLandingpage(e, type) {
        console.log(type)
        this.props.pageViewHandler(e, type)
    }
    render() {
        const { admin, allStatus, newDataBackground, adminDataForLandingpage } = this.props;
        return (
            <div className={"phocket-crm-pages animated fadeIn"} style={{ marginTop: '5px', marginBottom: window.innerWidth > 1800 ? '100px' : '5px' }}>
                {
                    // this.props.pageViewState === 'landingPage' || this.props.pageViewState == 'leadMainPage' || this.props.pageViewState == 'maketingList' || this.props.pageViewState == 'maketingDashboard' || this.props.pageViewState == 'reportGenerateNew' || this.props.pageViewState == 'businessManagement' || this.props.pageViewState == 'expenses' || this.props.pageViewState == 'leadManagement' || this.props.pageViewState == 'combineBranchBuddyLoanReport' || this.props.pageViewState == 'LoanUtility'|| this.props.pageViewState == 'recoveryDisburse'  ?
                    this.props.pageViewState === 'landingPage' || this.props.pageViewState === 'expenses' || this.props.pageViewState === 'businessManagement' || this.props.pageViewState === 'maketingDashboard' || this.props.pageViewState === 'maketingList' || this.props.pageViewState === 'combineBranchBuddyLoanReport' || this.props.pageViewState === 'reportGenerateNew' || this.props.pageViewState === 'accountReconciliation' || this.props.pageViewState == 'misDashboard' || this.props.pageViewState === 'scoringConfig' ?

                        <div style={{ marginTop: '10px', marginBottom: window.innerWidth > 1800 ? '100px' : '0px' }}></div>
                        :
                        <div className='' style={{ marginTop: '10px', marginBottom: window.innerWidth > 1800 ? '100px' : '10px', display: 'inline-flex', alignItems: 'center', marginLeft: '15px' }} onClick={(e) => this.pageHandleLandingpage(e, 'landingPage')}>
                            <img src={BackLogo} alt="" width={'35%'} style={{ marginRight: '10px', cursor: 'pointer' }} />
                            <h5 style={{ cursor: 'pointer' }}>Back</h5>
                        </div>
                }
                {/* {this.props.pageViewState !== 'landingPage' ? <i className='fa fa-arrow-circle-o-left' style={{color:'rgb(38, 125, 255)',fontWeight:'700',cursor:'pointer', fontSize:'24px',marginTop:'5px',marginLeft:'10px',marginBottom:'5px'}} onClick={() => this.pageHandleLandingpage('landingPage')}></i> : ""} */}
                {this.props.pageViewState == 'leadMainPage' ?

                    <LeadsMainPage
                        admin={admin}
                        allStatus={allStatus}
                        newDataBackground={newDataBackground}
                    // pageState={this.state.pageState}

                    />
                    : this.props.pageViewState == 'addEmailKey' ?

                        <SaveEmailForm
                            admin={admin}
                            allStatus={allStatus}
                            newDataBackground={newDataBackground}
                        // pageState={this.state.pageState}

                        />
                        : this.props.pageViewState == 'combineBranchBuddyLoanReport' ?

                            <BranchBuddyLoanMain
                                admin={admin}
                                allStatus={allStatus}
                                newDataBackground={newDataBackground}
                                pageViewHandler={this.pageHandleLandingpage.bind(this)}
                            // pageState={this.state.pageState}

                            />
                            : this.props.pageViewState == 'misDashboard' ?

                                <MISDashboardMain
                                    admin={admin}
                                    allStatus={allStatus}
                                    newDataBackground={newDataBackground}
                                    pageViewHandler={this.pageHandleLandingpage.bind(this)}
                                // pageState={this.state.pageState}

                                />
                                : this.props.pageViewState == 'expenses' ?

                                    <MainExpenses
                                        admin={admin}
                                        allStatus={allStatus}
                                        newDataBackground={newDataBackground}
                                        pageViewHandler={this.pageHandleLandingpage.bind(this)}
                                    // pageState={this.state.pageState}

                                    />
                                    : this.props.pageViewState == 'ApprovedLeads' ?

                                    <ApprovedLeadsContainer
                                        admin={admin}
                                        allStatus={allStatus}
                                        newDataBackground={newDataBackground}
                                    // pageState={this.state.pageState}

                                    />
                                    : this.props.pageViewState == 'employees' ?

                                        <EmployeesTarget
                                            admin={admin}
                                            allStatus={allStatus}
                                            newDataBackground={newDataBackground}
                                        // pageState={this.state.pageState}

                                        />
                                        : this.props.pageViewState == 'businessManagement' ?

                                            <BusinessManagementMain
                                                admin={admin}
                                                allStatus={allStatus}
                                                newDataBackground={newDataBackground}
                                        pageViewHandler={this.pageHandleLandingpage.bind(this)}
                                            // pageState={this.state.pageState}

                                            />
                                            : this.props.pageViewState == 'loanDashboardInsightApproval' ?

                                                <LoanDashboardMain
                                                    admin={admin}
                                                    allStatus={allStatus}
                                                    newDataBackground={newDataBackground}
                                                    pageViewHandler={this.pageHandleLandingpage.bind(this)}
                                                // pageState={this.state.pageState}

                                                />
                                                : this.props.pageViewState == 'LoanUtility' ?

                                                    <LenderMain
                                                        admin={admin}
                                                        allStatus={allStatus}
                                                        newDataBackground={newDataBackground}
                                                    // pageState={this.state.pageState}

                                                    />
                                                    : this.props.pageViewState == 'allProduct' ?

                                                        <DocProduct
                                                            admin={admin}
                                                            allStatus={allStatus}
                                                            newDataBackground={newDataBackground}
                                                        // pageState={this.state.pageState}

                                                        />
                                                        : this.props.pageViewState == 'merchants' ?

                                                            <AllMerchants
                                                                admin={admin}
                                                                allStatus={allStatus}
                                                                newDataBackground={newDataBackground}
                                                            // pageState={this.state.pageState}

                                                            />
                                                            : this.props.pageViewState == 'Executor' ?

                                                                <ExecutorMain
                                                                    admin={admin}
                                                                    allStatus={allStatus}
                                                                    newDataBackground={newDataBackground}
                                                                // pageState={this.state.pageState}

                                                                />
                                                                : this.props.pageViewState == 'comTemplate' ?

                                                                    <ComTab
                                                                        admin={admin}
                                                                        allStatus={allStatus}
                                                                        newDataBackground={newDataBackground}
                                                                    // pageState={this.state.pageState}

                                                                    />
                                                                    : this.props.pageViewState == 'NPADashboard' ?

                                                                        <DebtListMain
                                                                            admin={admin}
                                                                            allStatus={allStatus}
                                                                            newDataBackground={newDataBackground}
                                                                            pageViewHandler={this.pageHandleLandingpage.bind(this)}

                                                                        />
                                                                        : this.props.pageViewState == 'completeApplicationTracker' ?

                                                                            <CompleteApplicationTracker
                                                                                admin={admin}
                                                                                allStatus={allStatus}
                                                                                newDataBackground={newDataBackground}
                                                                            // pageState={this.state.pageState}

                                                                            />
                                                                            : this.props.pageViewState == 'allPayments' ?

                                                                                <Allpayments
                                                                                    admin={admin}
                                                                                    allStatus={allStatus}
                                                                                    newDataBackground={newDataBackground}
                                                                                // pageState={this.state.pageState}

                                                                                />
                                                                                : this.props.pageViewState == 'bulkOverDueSms' ?

                                                                                    <BulkOverDueSms
                                                                                        admin={admin}
                                                                                        allStatus={allStatus}
                                                                                        newDataBackground={newDataBackground}
                                                                                    // pageState={this.state.pageState}

                                                                                    />
                                                                                    : this.props.pageViewState == 'stampTracker' ?

                                                                                        <StampMain
                                                                                            admin={admin}
                                                                                            allStatus={allStatus}
                                                                                            newDataBackground={newDataBackground}
                                                                                        // pageState={this.state.pageState}

                                                                                        />
                                                                                        : this.props.pageViewState == 'bulkDeactivateDrp' ?

                                                                                            <BulkDeactivateDrpr
                                                                                                admin={admin}
                                                                                                allStatus={allStatus}
                                                                                                newDataBackground={newDataBackground}
                                                                                            // pageState={this.state.pageState}

                                                                                            />
                                                                                            : this.props.pageViewState == 'billDeskMandateHit' ?

                                                                                                <BillDeskMandateHit
                                                                                                    admin={admin}
                                                                                                    allStatus={allStatus}
                                                                                                    newDataBackground={newDataBackground}
                                                                                                // pageState={this.state.pageState}

                                                                                                />
                                                                                                : this.props.pageViewState == 'kycHandle' ?

                                                                                                    <StartStopKyc
                                                                                                        admin={admin}
                                                                                                        allStatus={allStatus}
                                                                                                        newDataBackground={newDataBackground}
                                                                                                    // pageState={this.state.pageState}

                                                                                                    />
                                                                                                    : this.props.pageViewState == 'kycPendingLeads' ?

                                                                                                        <KycPendingLeads
                                                                                                            admin={admin}
                                                                                                            allStatus={allStatus}
                                                                                                            newDataBackground={newDataBackground}
                                                                                                        // pageState={this.state.pageState}

                                                                                                        />
                                                                                                        : this.props.pageViewState == 'deleteToken' ?

                                                                                                            <DeleteRazorpayToken
                                                                                                                admin={admin}
                                                                                                                allStatus={allStatus}
                                                                                                                newDataBackground={newDataBackground}
                                                                                                            // pageState={this.state.pageState}

                                                                                                            />
                                                                                                            : this.props.pageViewState == 'scoringConfig' ?

                                                                                                                <Category
                                                                                                                    admin={admin}
                                                                                                                    allStatus={allStatus}
                                                                                                                    newDataBackground={newDataBackground}
                                                                                                                    pageViewHandler={this.pageHandleLandingpage.bind(this)}
                                                                                                                // pageState={this.state.pageState}

                                                                                                                />
                                                                                                                : this.props.pageViewState == 'recoveryLogs' ?

                                                                                                                    <RecoveryLogs
                                                                                                                        admin={admin}
                                                                                                                        allStatus={allStatus}
                                                                                                                        newDataBackground={newDataBackground}
                                                                                                                    // pageState={this.state.pageState}

                                                                                                                    />

                                                                                                                    : this.props.pageViewState == 'landingPage' && admin.role === 'superuser' ?
                                                                                                                        <LandingpageNew
                                                                                                                            mainMenuHandler={this.props.mainMenuHandler}
                                                                                                                            pageViewHandler={this.pageHandleLandingpage.bind(this)}
                                                                                                                            admin={admin}
                                                                                                                            allStatus={allStatus}
                                                                                                                            newDataBackground={newDataBackground}

                                                                                                                        />
                                                                                                                        : this.props.pageViewState == 'landingPage' && admin.role !== 'superuser' ?
                                                                                                                            <LandingpageOld
                                                                                                                                mainMenuHandler={this.props.mainMenuHandler}
                                                                                                                                pageViewHandler={this.pageHandleLandingpage.bind(this)}
                                                                                                                                admin={admin}
                                                                                                                                allStatus={allStatus}
                                                                                                                                newDataBackground={newDataBackground}

                                                                                                                            />
                                                                                                                            :
                                                                                                                            this.props.pageViewState == 'allPolicy' ?
                                                                                                                                <PolicyMainTabs
                                                                                                                                    admin={admin}
                                                                                                                                    allStatus={allStatus}
                                                                                                                                    newDataBackground={newDataBackground}
                                                                                                                                />
                                                                                                                                :
                                                                                                                                this.props.pageViewState == 'policyConfig' ?
                                                                                                                                    <PolicyConfig
                                                                                                                                        admin={admin}
                                                                                                                                        allStatus={allStatus}
                                                                                                                                        newDataBackground={newDataBackground}
                                                                                                                                    />
                                                                                                                                    :
                                                                                                                                    this.props.pageViewState == 'BIPage' ?
                                                                                                                                        <BIPage
                                                                                                                                            admin={admin}
                                                                                                                                            allStatus={allStatus}
                                                                                                                                            newDataBackground={newDataBackground}
                                                                                                                                        />
                                                                                                                                        :
                                                                                                                                        this.props.pageViewState == 'deviation' ?
                                                                                                                                            <DeviationReport
                                                                                                                                                admin={admin}
                                                                                                                                                allStatus={allStatus}
                                                                                                                                                newDataBackground={newDataBackground}
                                                                                                                                            // pageState={this.state.pageState}

                                                                                                                                            />
                                                                                                                                            :
                                                                                                                                            this.props.pageViewState == 'maketingDashboard' ?
                                                                                                                                                <MaketingDashboard
                                                                                                                                                    admin={admin}
                                                                                                                                                    allStatus={allStatus}
                                                                                                                                                    newDataBackground={newDataBackground}
                                                                                                                                                    pageViewHandler={this.pageHandleLandingpage.bind(this)}
                                                                                                                                                    
                                                                                                                                                // pageState={this.state.pageState}

                                                                                                                                                />
                                                                                                                                                :
                                                                                                                                                this.props.pageViewState == 'buisnessAggregator' ?
                                                                                                                                                    <BuisnessAggregator
                                                                                                                                                        admin={admin}
                                                                                                                                                        allStatus={allStatus}
                                                                                                                                                        newDataBackground={newDataBackground}
                                                                                                                                                    // pageState={this.state.pageState}

                                                                                                                                                    />
                                                                                                                                                    :
                                                                                                                                                    this.props.pageViewState == 'runCampaign' ?
                                                                                                                                                        <RunCampaign
                                                                                                                                                            admin={admin}
                                                                                                                                                            allStatus={allStatus}
                                                                                                                                                            newDataBackground={newDataBackground}
                                                                                                                                                        // pageState={this.state.pageState}

                                                                                                                                                        />
                                                                                                                                                        :
                                                                                                                                                        this.props.pageViewState == 'creditleads' ?
                                                                                                                                                            <CreditLeadsAnalysis
                                                                                                                                                                admin={admin}
                                                                                                                                                                allStatus={allStatus}
                                                                                                                                                                newDataBackground={newDataBackground}
                                                                                                                                                            />
                                                                                                                                                            :
                                                                                                                                                            this.props.pageViewState == 'aggregatorRegistration' ?
                                                                                                                                                                <AggregatorRegistration
                                                                                                                                                                    admin={admin}
                                                                                                                                                                    allStatus={allStatus}
                                                                                                                                                                    newDataBackground={newDataBackground}
                                                                                                                                                                />
                                                                                                                                                                : this.props.pageViewState == 'maketingList' ?
                                                                                                                                                                    <div className='marketing-main-css'>
                                                                                                                                                                        <MarketingList
                                                                                                                                                                            admin={admin}
                                                                                                                                                                            allStatus={allStatus}
                                                                                                                                                                            newDataBackground={newDataBackground}
                                                                                                                                                                            
                                        pageViewHandler={this.pageHandleLandingpage.bind(this)}
                                                                                                                                                                        />
                                                                                                                                                                    </div>
                                                                                                                                                                    :
                                                                                                                                                                    this.props.pageViewState == 'budgetPlan' ?
                                                                                                                                                                        <BudgetPlan
                                                                                                                                                                            admin={admin}
                                                                                                                                                                            allStatus={allStatus}
                                                                                                                                                                            newDataBackground={newDataBackground}
                                                                                                                                                                        />
                                                                                                                                                                        :
                                                                                                                                                                        this.props.pageViewState == 'razorpayTransfer' ?
                                                                                                                                                                            <RazorpayTransfer
                                                                                                                                                                                admin={admin}
                                                                                                                                                                                allStatus={allStatus}
                                                                                                                                                                                newDataBackground={newDataBackground} />
                                                                                                                                                                            : this.props.pageViewState == 'allnbfclead' ?
                                                                                                                                                                                <AllNbfc
                                                                                                                                                                                    admin={admin} />
                                                                                                                                                                                : this.props.pageViewState == 'nbfcConfig' ?
                                                                                                                                                                                    <MainConfiguration
                                                                                                                                                                                        admin={admin} />
                                                                                                                                                                                    : this.props.pageViewState == 'addNbfcNew' ?
                                                                                                                                                                                        <AddNbfcPage
                                                                                                                                                                                            admin={admin} />
                                                                                                                                                                                        :
                                                                                                                                                                                        this.props.pageViewState == 'uploadStamDuty' ?
                                                                                                                                                                                            <StampDuty
                                                                                                                                                                                                admin={admin} />
                                                                                                                                                                                            : this.props.pageViewState == 'leadMainPageTesting' ?
                                                                                                                                                                                                <LeadsTestingPage
                                                                                                                                                                                                    admin={admin}
                                                                                                                                                                                                    allStatus={allStatus}
                                                                                                                                                                                                    newDataBackground={newDataBackground}
                                                                                                                                                                                                />
                                                                                                                                                                                                : this.props.pageViewState == 'vreedhiLeads' ?
                                                                                                                                                                                                    <VreedhiLeads
                                                                                                                                                                                                        admin={admin}
                                                                                                                                                                                                        allStatus={allStatus}
                                                                                                                                                                                                        newDataBackground={newDataBackground}

                                                                                                                                                                                                    />
                                                                                                                                                                                                    : this.props.pageViewState == 'BadaBuisnessLeads' ?
                                                                                                                                                                                                        <BadaBuisnessLeads
                                                                                                                                                                                                            admin={admin}
                                                                                                                                                                                                            allStatus={allStatus}
                                                                                                                                                                                                            newDataBackground={newDataBackground}

                                                                                                                                                                                                        />
                                                                                                                                                                                                        : this.props.pageViewState == 'AppStatus' ?
                                                                                                                                                                                                            <ApplicationStatus
                                                                                                                                                                                                                admin={admin}
                                                                                                                                                                                                            />
                                                                                                                                                                                                            : this.props.pageViewState == 'assetQuality' ?
                                                                                                                                                                                                                <AssetUi
                                                                                                                                                                                                                />
                                                                                                                                                                                                                : this.props.pageViewState == 'dashboard' ?
                                                                                                                                                                                                                    <Dashboard
                                                                                                                                                                                                                        admin={admin}
                                                                                                                                                                                                                    />
                                                                                                                                                                                                                    : this.props.pageViewState == 'offers' ?
                                                                                                                                                                                                                        <Offers
                                                                                                                                                                                                                            admin={admin}
                                                                                                                                                                                                                        />
                                                                                                                                                                                                                        : this.props.pageViewState == 'promoData' ?
                                                                                                                                                                                                                            <PromoData
                                                                                                                                                                                                                                admin={admin}
                                                                                                                                                                                                                            />
                                                                                                                                                                                                                            : this.props.pageViewState == 'referralCompany' ?
                                                                                                                                                                                                                                <ReferralPage
                                                                                                                                                                                                                                    admin={admin}
                                                                                                                                                                                                                                />
                                                                                                                                                                                                                                : this.props.pageViewState == 'marchantOffer' ?
                                                                                                                                                                                                                                    <MarchantAddOffer
                                                                                                                                                                                                                                        admin={admin}
                                                                                                                                                                                                                                    /> : this.props.pageViewState == 'couponStats' ?
                                                                                                                                                                                                                                        <CouponStats
                                                                                                                                                                                                                                            admin={admin}
                                                                                                                                                                                                                                        /> : this.props.pageViewState == 'incompleteApp' ?
                                                                                                                                                                                                                                            <IncompleteApps
                                                                                                                                                                                                                                                admin={admin}
                                                                                                                                                                                                                                                allStatus={allStatus}
                                                                                                                                                                                                                                            />
                                                                                                                                                                                                                                            : this.props.pageViewState == 'statusCommunicate' ?
                                                                                                                                                                                                                                                <BulkCommStatus
                                                                                                                                                                                                                                                    admin={admin}
                                                                                                                                                                                                                                                    allStatus={allStatus}
                                                                                                                                                                                                                                                />
                                                                                                                                                                                                                                                : this.props.pageViewState == 'communicateByCsv' ?
                                                                                                                                                                                                                                                    <BulkCommCSV
                                                                                                                                                                                                                                                        admin={admin}
                                                                                                                                                                                                                                                    />
                                                                                                                                                                                                                                                    : this.props.pageViewState == 'bulkLogs' ?
                                                                                                                                                                                                                                                        <BulkCommLog
                                                                                                                                                                                                                                                            admin={admin}
                                                                                                                                                                                                                                                            allStatus={allStatus}
                                                                                                                                                                                                                                                        />
                                                                                                                                                                                                                                                        : this.props.pageViewState == 'bulkStatusChange' ?
                                                                                                                                                                                                                                                            <BulkStatus
                                                                                                                                                                                                                                                                admin={admin}
                                                                                                                                                                                                                                                            />
                                                                                                                                                                                                                                                            : this.props.pageViewState == 'recovery' ?
                                                                                                                                                                                                                                                                <RecoveryPage
                                                                                                                                                                                                                                                                    admin={admin}
                                                                                                                                                                                                                                                                />
                                                                                                                                                                                                                                                                : this.props.pageViewState == 'recoveryDisburse' ?
                                                                                                                                                                                                                                                                    <DisbursePage
                                                                                                                                                                                                                                                                        admin={admin}
                                                                                                                                                                                                                                                                        allStatus={allStatus}
                                                                                                                                                                                                                                                                        newDataBackground={newDataBackground}
                                                                                                                                                                                                                                                                    />
                                                                                                                                                                                                                                                                    : this.props.pageViewState == 'recoveryIncoming' ?
                                                                                                                                                                                                                                                                        <RecoveryIncomingPayment
                                                                                                                                                                                                                                                                            admin={admin}
                                                                                                                                                                                                                                                                        />
                                                                                                                                                                                                                                                                        : this.props.pageViewState == 'chequeBounce' ?
                                                                                                                                                                                                                                                                            <ChequeBouncePage
                                                                                                                                                                                                                                                                                admin={admin}
                                                                                                                                                                                                                                                                            />
                                                                                                                                                                                                                                                                            : this.props.pageViewState == 'nachBounce' ?
                                                                                                                                                                                                                                                                                <NachBouncePage
                                                                                                                                                                                                                                                                                    admin={admin}
                                                                                                                                                                                                                                                                                />
                                                                                                                                                                                                                                                                                : this.props.pageViewState == 'NoticeDelivered' ?
                                                                                                                                                                                                                                                                                    <NoticePage
                                                                                                                                                                                                                                                                                        admin={admin}
                                                                                                                                                                                                                                                                                    />
                                                                                                                                                                                                                                                                                    : this.props.pageViewState == 'cusSearch' ?
                                                                                                                                                                                                                                                                                        <CustomSearchRep
                                                                                                                                                                                                                                                                                            admin={admin}
                                                                                                                                                                                                                                                                                            allStatus={allStatus}
                                                                                                                                                                                                                                                                                        />
                                                                                                                                                                                                                                                                                        : this.props.pageViewState == 'reportGenerate' ?
                                                                                                                                                                                                                                                                                            <ReportByChoice
                                                                                                                                                                                                                                                                                                admin={admin}
                                                                                                                                                                                                                                                                                                allStatus={allStatus}
                                                                                                                                                                                                                                                                                            />
                                                                                                                                                                                                                                                                                            : this.props.pageViewState == 'reportGenerateNew' ?
                                                                                                                                                                                                                                                                                                <GenerateReportNew
                                                                                                                                                                                                                                                                                                    admin={admin}
                                                                                                                                                                                                                                                                                                    allStatus={allStatus}   
                                                                                                                                                                                                                                                                                                    pageViewHandler={this.pageHandleLandingpage.bind(this)}
                                                                                                                                                                                                                                                                                                />
                                                                                                                                                                                                                                                                                                //  : this.props.pageViewState == 'SenderMail' ?
                                                                                                                                                                                                                                                                                                //     <SenderMail
                                                                                                                                                                                                                                                                                                //         admin={admin}
                                                                                                                                                                                                                                                                                                //     />
                                                                                                                                                                                                                                                                                                : this.props.pageViewState == 'changePassword' ?
                                                                                                                                                                                                                                                                                                    <ChangePassword
                                                                                                                                                                                                                                                                                                        admin={admin}
                                                                                                                                                                                                                                                                                                        signOutHandler={this.signOutHandler.bind(this)}
                                                                                                                                                                                                                                                                                                    />
                                                                                                                                                                                                                                                                                                    : this.props.pageViewState == 'ambassadorLead' ?
                                                                                                                                                                                                                                                                                                        <AmbassadorLeads
                                                                                                                                                                                                                                                                                                            admin={admin} />
                                                                                                                                                                                                                                                                                                        : this.props.pageViewState == 'ambassadorRegistration' ?
                                                                                                                                                                                                                                                                                                            <AmbRegister
                                                                                                                                                                                                                                                                                                                admin={admin} />
                                                                                                                                                                                                                                                                                                            : this.props.pageViewState == 'loyalityOffers' ?
                                                                                                                                                                                                                                                                                                                <LoyalityOffer
                                                                                                                                                                                                                                                                                                                    admin={admin} />
                                                                                                                                                                                                                                                                                                                : this.props.pageViewState == 'loyalityData' ?
                                                                                                                                                                                                                                                                                                                    <UserLoyality
                                                                                                                                                                                                                                                                                                                        admin={admin} />
                                                                                                                                                                                                                                                                                                                    : this.props.pageViewState == 'calcUtility' ?
                                                                                                                                                                                                                                                                                                                        <CalcUtility />
                                                                                                                                                                                                                                                                                                                        : this.props.pageViewState == 'emiCalculator' ?
                                                                                                                                                                                                                                                                                                                            <EmiCalculator
                                                                                                                                                                                                                                                                                                                                admin={admin} />
                                                                                                                                                                                                                                                                                                                            : this.props.pageViewState == 'webContentUtility' ?
                                                                                                                                                                                                                                                                                                                                <WebUtility
                                                                                                                                                                                                                                                                                                                                    admin={admin} />
                                                                                                                                                                                                                                                                                                                                : this.props.pageViewState == 'automationLogs' ?
                                                                                                                                                                                                                                                                                                                                    <AutoLogs
                                                                                                                                                                                                                                                                                                                                        admin={admin} />
                                                                                                                                                                                                                                                                                                                                    : this.props.pageViewState == 'fieldBoyRegistration' ?
                                                                                                                                                                                                                                                                                                                                        <FieldBoyRegistration
                                                                                                                                                                                                                                                                                                                                            admin={admin} />
                                                                                                                                                                                                                                                                                                                                        : this.props.pageViewState == 'installedApp' ?
                                                                                                                                                                                                                                                                                                                                            <InstalledApp
                                                                                                                                                                                                                                                                                                                                                admin={admin} />
                                                                                                                                                                                                                                                                                                                                            : this.props.pageViewState == 'updateByCSV' ?
                                                                                                                                                                                                                                                                                                                                                <UpdateByCSV
                                                                                                                                                                                                                                                                                                                                                    admin={admin} />
                                                                                                                                                                                                                                                                                                                                                : this.props.pageViewState == 'approveToDisburse' ?
                                                                                                                                                                                                                                                                                                                                                    <ApproveToDisburse
                                                                                                                                                                                                                                                                                                                                                        admin={admin}
                                                                                                                                                                                                                                                                                                                                                    />
                                                                                                                                                                                                                                                                                                                                                    : this.props.pageViewState == 'covertToPaid' ?
                                                                                                                                                                                                                                                                                                                                                        <ConvertToPaidUi
                                                                                                                                                                                                                                                                                                                                                            admin={admin} />
                                                                                                                                                                                                                                                                                                                                                        : this.props.pageViewState == 'GenReportLogs' ?
                                                                                                                                                                                                                                                                                                                                                            <ReportLogs
                                                                                                                                                                                                                                                                                                                                                                admin={admin} />
                                                                                                                                                                                                                                                                                                                                                            : this.props.pageViewState == 'inBondReport' ?
                                                                                                                                                                                                                                                                                                                                                                <IBRPage
                                                                                                                                                                                                                                                                                                                                                                    admin={admin} />
                                                                                                                                                                                                                                                                                                                                                                : this.props.pageViewState == 'outBondReport' ?
                                                                                                                                                                                                                                                                                                                                                                    <OBRPage
                                                                                                                                                                                                                                                                                                                                                                        admin={admin} />
                                                                                                                                                                                                                                                                                                                                                                    : this.props.pageViewState == 'scheduledCall' ?
                                                                                                                                                                                                                                                                                                                                                                        <ScheduledCallPage
                                                                                                                                                                                                                                                                                                                                                                            admin={admin} />
                                                                                                                                                                                                                                                                                                                                                                        : this.props.pageViewState == 'addPincode' ?
                                                                                                                                                                                                                                                                                                                                                                            <AddPinCodeUi
                                                                                                                                                                                                                                                                                                                                                                                admin={admin} />
                                                                                                                                                                                                                                                                                                                                                                            : this.props.pageViewState == 'addNachStatus' ?
                                                                                                                                                                                                                                                                                                                                                                                <UpdateNachUi
                                                                                                                                                                                                                                                                                                                                                                                    admin={admin} />
                                                                                                                                                                                                                                                                                                                                                                                : this.props.pageViewState == 'leadManagement' ?
                                                                                                                                                                                                                                                                                                                                                                                    <LeadManagementUi
                                                                                                                                                                                                                                                                                                                                                                                        admin={admin}
                                                                                                                                                                                                                                                                                                                                                                                        allStatus={allStatus}
                                                                                                                                                                                                                                                                                                                                                                                        newDataBackground={newDataBackground} />
                                                                                                                                                                                                                                                                                                                                                                                    : this.props.pageViewState == 'trackrecords' ?
                                                                                                                                                                                                                                                                                                                                                                                        <TrackRecords />
                                                                                                                                                                                                                                                                                                                                                                                        : this.props.pageViewState == 'RegisterUser' ?
                                                                                                                                                                                                                                                                                                                                                                                            <Registereduser
                                                                                                                                                                                                                                                                                                                                                                                                admin={admin}
                                                                                                                                                                                                                                                                                                                                                                                                allStatus={allStatus}
                                                                                                                                                                                                                                                                                                                                                                                                newDataBackground={newDataBackground} />
                                                                                                                                                                                                                                                                                                                                                                                            : this.props.pageViewState == 'AddCampaign' ?
                                                                                                                                                                                                                                                                                                                                                                                                <AddCampaignUrl
                                                                                                                                                                                                                                                                                                                                                                                                    admin={admin}
                                                                                                                                                                                                                                                                                                                                                                                                    allStatus={allStatus}
                                                                                                                                                                                                                                                                                                                                                                                                    newDataBackground={newDataBackground} />
                                                                                                                                                                                                                                                                                                                                                                                                :
                                                                                                                                                                                                                                                                                                                                                                                                this.props.pageViewState == 'BulkAssign' ?
                                                                                                                                                                                                                                                                                                                                                                                                    <BulkAssignUi
                                                                                                                                                                                                                                                                                                                                                                                                        admin={admin}
                                                                                                                                                                                                                                                                                                                                                                                                        allStatus={allStatus} />
                                                                                                                                                                                                                                                                                                                                                                                                    : this.props.pageViewState == 'thirdpartyreg' ?
                                                                                                                                                                                                                                                                                                                                                                                                        <ThirdPartyRegistration
                                                                                                                                                                                                                                                                                                                                                                                                            admin={admin}
                                                                                                                                                                                                                                                                                                                                                                                                            allStatus={allStatus}
                                                                                                                                                                                                                                                                                                                                                                                                            newDataBackground={newDataBackground} />
                                                                                                                                                                                                                                                                                                                                                                                                        : this.props.pageViewState == 'Roleassign' ?
                                                                                                                                                                                                                                                                                                                                                                                                            <RoleAssign
                                                                                                                                                                                                                                                                                                                                                                                                                admin={admin}
                                                                                                                                                                                                                                                                                                                                                                                                                allStatus={allStatus} />
                                                                                                                                                                                                                                                                                                                                                                                                            : this.props.pageViewState == 'importantCompanyRule' ?
                                                                                                                                                                                                                                                                                                                                                                                                                <ImportantCompanyRules
                                                                                                                                                                                                                                                                                                                                                                                                                    admin={admin} />
                                                                                                                                                                                                                                                                                                                                                                                                                : this.props.pageViewState == 'Bulkassign' ?
                                                                                                                                                                                                                                                                                                                                                                                                                    <BulkAssign
                                                                                                                                                                                                                                                                                                                                                                                                                        admin={admin} />
                                                                                                                                                                                                                                                                                                                                                                                                                    : this.props.pageViewState == 'priorityCompany' ?
                                                                                                                                                                                                                                                                                                                                                                                                                        <PriorityCompanyUi
                                                                                                                                                                                                                                                                                                                                                                                                                            admin={admin} />
                                                                                                                                                                                                                                                                                                                                                                                                                        : this.props.pageViewState == 'addNbfc' ?
                                                                                                                                                                                                                                                                                                                                                                                                                            <AddNbfcUi
                                                                                                                                                                                                                                                                                                                                                                                                                                admin={admin} />
                                                                                                                                                                                                                                                                                                                                                                                                                            : this.props.pageViewState == 'updateAccInfo' ?
                                                                                                                                                                                                                                                                                                                                                                                                                                <UpdateAccInfoUi
                                                                                                                                                                                                                                                                                                                                                                                                                                    admin={admin} />
                                                                                                                                                                                                                                                                                                                                                                                                                                : this.props.pageViewState == 'updateCibilInfo' ?
                                                                                                                                                                                                                                                                                                                                                                                                                                    <UpdateCibilInfoUi
                                                                                                                                                                                                                                                                                                                                                                                                                                        admin={admin} />
                                                                                                                                                                                                                                                                                                                                                                                                                                    : this.props.pageViewState == 'Content' ?
                                                                                                                                                                                                                                                                                                                                                                                                                                        <Content />
                                                                                                                                                                                                                                                                                                                                                                                                                                        : this.props.pageViewState == 'nachRegistrationReport' ?
                                                                                                                                                                                                                                                                                                                                                                                                                                            <NachRegistrationUi
                                                                                                                                                                                                                                                                                                                                                                                                                                                admin={admin} />
                                                                                                                                                                                                                                                                                                                                                                                                                                            : this.props.pageViewState == 'nachRegistrationReportbilldesk' ?
                                                                                                                                                                                                                                                                                                                                                                                                                                                <NachRegistrationUiBillDesk
                                                                                                                                                                                                                                                                                                                                                                                                                                                    admin={admin} />
                                                                                                                                                                                                                                                                                                                                                                                                                                                : this.props.pageViewState == 'Emprecord' ?
                                                                                                                                                                                                                                                                                                                                                                                                                                                    <EmployeeActivity />
                                                                                                                                                                                                                                                                                                                                                                                                                                                    : this.props.pageViewState == 'bulkStatusUpdate' ?
                                                                                                                                                                                                                                                                                                                                                                                                                                                        <BulkStatusUpdateUi
                                                                                                                                                                                                                                                                                                                                                                                                                                                            admin={admin} />
                                                                                                                                                                                                                                                                                                                                                                                                                                                        : this.props.pageViewState == 'Nbfcmanagement' ?
                                                                                                                                                                                                                                                                                                                                                                                                                                                            <Nbfcmanagement
                                                                                                                                                                                                                                                                                                                                                                                                                                                                admin={admin} />
                                                                                                                                                                                                                                                                                                                                                                                                                                                            : this.props.pageViewState == 'bulkByMobile' ?
                                                                                                                                                                                                                                                                                                                                                                                                                                                                <CommunicateByMobile
                                                                                                                                                                                                                                                                                                                                                                                                                                                                    admin={admin} />
                                                                                                                                                                                                                                                                                                                                                                                                                                                                : this.props.pageViewState == 'biGraph' ?
                                                                                                                                                                                                                                                                                                                                                                                                                                                                    <BIGRAPH
                                                                                                                                                                                                                                                                                                                                                                                                                                                                        admin={admin} />
                                                                                                                                                                                                                                                                                                                                                                                                                                                                    : this.props.pageViewState == 'incomeCollected' ?
                                                                                                                                                                                                                                                                                                                                                                                                                                                                        <IncomeCollectedUi
                                                                                                                                                                                                                                                                                                                                                                                                                                                                            admin={admin} />
                                                                                                                                                                                                                                                                                                                                                                                                                                                                        : this.props.pageViewState == 'configuration' ?
                                                                                                                                                                                                                                                                                                                                                                                                                                                                            <Configuration />
                                                                                                                                                                                                                                                                                                                                                                                                                                                                            : this.props.pageViewState == 'collections' ?
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                <Collection />
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                : this.props.pageViewState == 'similarp' ?
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    <SimilarProfileCollection />
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    : this.props.pageViewState == 'leadMainPageMini' ?
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        <PhocketMini
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            admin={admin}
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            allStatus={allStatus}
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            newDataBackground={newDataBackground}
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        />
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        : this.props.pageViewState == 'giftCardLeads' ?
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            <GiftCardLeads
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                admin={admin}
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                allStatus={allStatus}
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                newDataBackground={newDataBackground}

                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            />
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            : this.props.pageViewState == 'bulkPayByRazorPay' ?
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                <InitiateBulkPayment
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    admin={admin}

                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                />
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                : this.props.pageViewState == 'bulkNachBounceByCsv' ?
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    <BulkNachBounce
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        admin={admin}

                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    />
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    : this.props.pageViewState == 'CollectionRule' ?
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        <CollectionRuleuI
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            admin={admin}

                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        />
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        : this.props.pageViewState == 'BulkPaymentLeads' ?
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            <BulkPaymentLeads
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                admin={admin}

                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            />
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            : this.props.pageViewState == 'IncomingPaymentReport' ?
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                <IncomingPaymentReport
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    admin={admin}

                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                />
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                : this.props.pageViewState == 'icici' ?
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    <DisburseByIcici
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        admin={admin}

                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    />
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    : this.props.pageViewState == 'buddyLoanReport' ?
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        <BuddyloanReport
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            admin={admin}

                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        />
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        : this.props.pageViewState == 'razorpay' ?
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            <Rzorpaydata
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                admin={admin}
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            />
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            : this.props.pageViewState == 'cards' ?
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                <Cards
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    admin={admin}
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    allStatus={allStatus}
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    newDataBackground={newDataBackground}
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                />
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                : this.props.pageViewState == 'flipkartBalance' ?
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    <FlipkartBalance
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        admin={admin}
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    />
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    : this.props.pageViewState == 'upload-cards-users' ?
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        <UploadCardUser
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            admin={admin}
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        />
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        : this.props.pageViewState == 'all-cards-users' ?
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            <CardLeads
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                admin={admin}
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            />
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            : this.props.pageViewState == 'config-cards' ?
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                <ConfigCards
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    admin={admin}
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                />
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                : this.props.pageViewState == 'cardLoanLeads' ?
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    <CardLoanLeads
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        admin={admin}
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        allStatus={allStatus}
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        newDataBackground={newDataBackground}

                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    />
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    : this.props.pageViewState == 'upload-tea-garden-user' ?
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        <UploadTeaUsers
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            admin={admin}
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        />
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        : this.props.pageViewState == 'tea-ambassador-add' ?
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            <Teaambassador
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                admin={admin}
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            />
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            : this.props.pageViewState == 'teaGardenLeads' ?
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                <TeaGardenLeads
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    admin={admin}
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    allStatus={allStatus}
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    newDataBackground={newDataBackground}

                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                />
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                : this.props.pageViewState == 'allTeaGardenAmbassador' ?
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    <AllTeaGardenAmbassador
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        admin={admin}
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        allStatus={allStatus}
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        newDataBackground={newDataBackground}

                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    />
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    : this.props.pageViewState == 'teaGardenGiftCardLeads' ?
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        <AllTeaGardenAmbassadorGiftCard
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            admin={admin}
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            allStatus={allStatus}
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            newDataBackground={newDataBackground}

                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        />
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        : this.props.pageViewState == 'branchData' ?
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            <BranchReport


                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            />
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            : this.props.pageViewState == 'apiconfig' ?
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                <APIConfig
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    admin={admin}
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    allStatus={allStatus}
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    newDataBackground={newDataBackground}


                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                />
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                : this.props.pageViewState == 'performanceTracker' ?
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    <PerformanceTracker
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        admin={admin}
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        allStatus={allStatus}
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        newDataBackground={newDataBackground}
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        pageViewHandler={this.pageHandleLandingpage.bind(this)}

                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    />
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    : this.props.pageViewState == 'branch' ?
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        <BranchData />
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        : this.props.pageViewState == 'saveManualEntry' ?
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            <SaveManualUi


                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            />
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            : this.props.pageViewState == 'accountReconciliation' ?
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                <AccountReconciliation
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    admin={admin}
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    allStatus={allStatus}
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    newDataBackground={newDataBackground} 
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    pageViewHandler={this.pageHandleLandingpage.bind(this)}
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                // pageState={this.state.pageState}

                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                />
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                : this.props.pageViewState == 'branch' ?
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    <BranchData


                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    />
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    : this.props.pageViewState == 'addLoanId' ?
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        <AddLoanId
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            mainMenuHandler={this.props.mainMenuHandler}
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            pageViewHandler={this.pageHandleLandingpage.bind(this)}
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            admin={admin}
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            allStatus={allStatus}
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            newDataBackground={newDataBackground}

                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        />



                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        : null}

            </div>
        )
    }

    signOutHandler() {
        this.props.signOutHandler()
    }
}

const mapStateToProps = ({ allStatus }) => {
    return {
        allStatus: allStatus
    };
};

const mapDispatchToProps = dispatch => {
    return bindActionCreators({

    }, dispatch);
};


export default connect(mapStateToProps, mapDispatchToProps)(CRMMain);