import React from 'react'
import DatePicker from 'react-datepicker';
import YearMonthSelector from 'react-year-month-selector';
import { TYPE } from '../../../../utils/Constant'
import moment from 'moment'

const DashFilters = ({ filteringHandler, dayWiseState, monthlyState, monthClose, selectedMonth,
    betweenDateState, filterToggleHandler, handleChange, Startdate, Enddate, submitHandlet }) => (
        <div id="admin-filter-content" className="admin-filter-content rotateInUpRight">
            <div className="row form-group">
                <div className="col-xs-12">
                    <div className="filter-dropdown">
                        <button className="btn-info filter-btn" onClick={(e) => filterToggleHandler(e)}>Select Filter Type</button>
                        <div id="filterContent" className="filter-content">
                            <a onClick={e => submitHandlet(TYPE.OVER_ALL)}>Overall</a>
                            <a onClick={(e) => filteringHandler(e, "dayWise")}>Day wise</a>
                            <a onClick={(e) => filteringHandler(e, "monthly")}>Monthly</a>
                            <a onClick={(e) => filteringHandler(e, "btwnDates")}>Between Dates</a>
                        </div>
                    </div>
                </div>
            </div>

            {dayWiseState == true ?
                <div className="row form-group">
                    <div className="col-xs-12">
                        <p>Select Date</p>
                        <DatePicker
                            value={Startdate}
                            onChange={(e) => handleChange(TYPE.DAY_WISE, e, "start")}
                            className="date-picker form-control"
                            dateFormat="DD-MM-YYYY"
                            placeholderText="Select Date"
                        />
                    </div>
                    <div className="col-xs-12 text-center">
                        <button className="profile-save-btn" id="" onClick={e => submitHandlet(TYPE.DAY_WISE)}>
                            <span className="fa fa-floppy-o"></span>&nbsp;&nbsp;SAVE
                    </button>
                    </div>
                </div>
                : ""}
            {monthlyState == true ?
                <div className="row form-group">
                    <div className="col-xs-12">
                        <p>Select Month</p>
                        <YearMonthSelector
                            year={moment(new Date()).format("YYYY")}
                            open={true}
                            month={selectedMonth}
                            onClose={monthClose}
                            onChange={(e, m) => handleChange(TYPE.MONTH_WISE, e, m)}
                        />
                    </div>
                    <div className="col-xs-12 text-center">
                        <button className="profile-save-btn" id="" onClick={e => submitHandlet(TYPE.MONTH_WISE)}>
                            <span className="fa fa-floppy-o"></span>&nbsp;&nbsp;SAVE
                    </button>
                    </div>
                </div>

                : ""}
            {betweenDateState == true ?
                <div className="row form-group">
                    <div className="col-xs-12 form-group">
                        <p>Select Start Date</p>
                        <DatePicker
                            value={Startdate}
                            onChange={(e) => handleChange(TYPE.BETWEEN_DATE_WISE, e, "start")}
                            className="date-picker form-control"
                            dateFormat="DD-MM-YYYY"
                            placeholderText="Select Start Date"
                        />
                    </div>
                    <div className="col-xs-12 form-group">
                        <p>Select End Date</p>
                        <DatePicker
                            value={Enddate}
                            onChange={(e) => handleChange(TYPE.BETWEEN_DATE_WISE, e, "end")}
                            className="date-picker form-control"
                            dateFormat="DD-MM-YYYY"
                            placeholderText="Select End Date"
                        />
                    </div>
                    <div className="col-xs-12 text-center">
                        <button className="profile-save-btn" id="" onClick={e => submitHandlet(TYPE.BETWEEN_DATE_WISE)}>
                            <span className="fa fa-floppy-o"></span>&nbsp;&nbsp;SAVE
                    </button>
                    </div>
                </div>
                : ""}
        </div>
    )
export default DashFilters;