import React, { Component } from 'react'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import OverAllStatsUi from '../../../presentationals/Admin/DashboardAndReports/OverallStats.component'
import { getLoanApprovalLead } from '../AdminActionCreator.component';
import DotLoader from '../../../presentationals/Loader/DotLoader.component'
import MainLoader from '../../../presentationals/Loader/Loader.component'
import localStorageUtil from '../../../../utils/localStorageUtil';
import ShowDataUi from './ShowData.container'
import { STRING } from '../../../../utils/Constant'
import { _preFormatDate } from '../../../../utils/validation'

class OverAllStats extends Component {
    constructor(props) {
        super(props);
        this.state = {
            allStatus: '',
            piChartData: [],
            dotLoadarState: false,
            forwordPageState: 1,
            filterDetail: {
                statusId: '',
                leadCount: 0
            },
            pageViewState: 'stats',
            mainLoaderState: false,
            tabType: '',
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.allStatus != this.props.allStatus) {
            const { allStatus } = nextProps;
            let graphData= []
            for (let i = 0; i < allStatus.length; i++) {
                let piData = allStatus[i]
                if (piData.statusId != STRING.SELECT_ALL && piData.count > 0) {
                    graphData.push({ name: piData.adminStatus, value: piData.count })
                }
            }
            this.setState({
                piChartData: graphData,
                allStatus: allStatus
            })
        }
    }


    render() {
        const { loanApprovalLead, allStatus } = this.props
        return (
            <div className="">
                {this.state.pageViewState == 'stats' ?
                    this.props.loaderState == true ?
                        <DotLoader />
                        : <OverAllStatsUi

                            allStatus={this.state.allStatus}
                            piChartData={this.state.piChartData}
                            showDataHandler={this.showDataHandler.bind(this)}
                        /> : this.state.pageViewState == 'list' ?
                        <ShowDataUi
                            loanApprovalLead={loanApprovalLead}
                            backHandler={this.backHandler.bind(this)}
                            getPageState={this.getPageState.bind(this)}
                            dataCount={this.state.filterDetail.leadCount}
                        />
                        : null}
                {this.state.mainLoaderState ?
                    <MainLoader />
                    : null}
            </div>
        )
    }

    showDataHandler(status) {
        let filterDetail = Object.assign({}, this.state.filterDetail)
        if (status.count > 0) {
            filterDetail.statusId = status.statusId
            filterDetail.leadCount = status.count
            this.setState({
                filterDetail
            }, () => this.manageData())
        }
    }

    getPageState(pageNumber) {
        this.setState({
            forwordPageState: pageNumber
        }, () => this.manageData())
    }

    manageData() {
        let adminEmail = localStorageUtil.getFromLocalStorage("AdminemailId")
        let nbfcName = localStorageUtil.getFromLocalStorage("nbfcName")
        this.setState({
            mainLoaderState: true
        })
        this.props.getLoanApprovalLead(this.state.filterDetail.statusId, this.state.forwordPageState, adminEmail, this.props.startDate, nbfcName, this.props.endDate, this.state.tabType,'','','','','',
            (callBack) => {
                this.setState({
                    pageViewState: 'list',
                    mainLoaderState: false
                })
            })
    }

    backHandler() {
        this.setState({
            pageViewState: 'stats',
            forwordPageState: 1
        })
    }
}

const mapStateToProps = ({ loanApprovalLead }) => {
    return {
        loanApprovalLead: loanApprovalLead
    };
};

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        getLoanApprovalLead
    }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(OverAllStats);