import React from 'react'

const linkPopu = ({ calcamount, popupType, waivedAmount, waivedPenalty, PopupOverDueAmount, handleLink, confirmationText, changeHandler, PopupData, GeneratePaymentLink, paymentLink }) => (
    <div className="background-blur">
        {
            popupType === 'EmiLinkGeneratePopup' ?
                <div className="popup-new-emi">
                    <div className="modal" style={{top:'15%'}}>
                        <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <div className="header-text">
                                        <h6 className="modal-title">
                                            <strong>Generate Payment Recieved</strong>
                                        </h6>
                                  
                                    </div>

                                    <button
                                        data-dismiss="modal"
                                        className="btn"
                                        onClick={() => handleLink('close')}
                                    >
                                        <span className="font-weight-bold">X</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                <div> <h5 style={{color:'#000',background:'yellow',width:'140px'}}>Emi Amount: {PopupOverDueAmount.AMOUNT}</h5></div>

                                    <div className='row'>
                                        <div className='col-sm-6 col-xs-12'>
                                            <h6 className=""> Principle Amount</h6>
                                            <div className="" style={{ marginBottom: '15px' }}>
                                                <input value={Number(PopupOverDueAmount.princpleAmt).toFixed(2)} readOnly={true} type="number" className="form-control w-50" onChange={(e) => changeHandler(e, 'paymentAmount')} placeholder="Enter Payable Amount" />
                                            </div>
                                        </div>
                                        <div className='col-sm-6 col-xs-12'>
                                            <h6 className=""> Interest Amount </h6>
                                            <div className="" style={{ marginBottom: '15px' }}>
                                                <input value={(Number(PopupOverDueAmount.InterestAmount).toFixed(2))} readOnly={true} type="number" className="form-control w-50" onChange={(e) => changeHandler(e, 'paymentAmount')} placeholder="Enter Payable Amount" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-sm-6 col-xs-12'>
                                            <h6 className="">Penalty </h6>
                                            <div className="" style={{ marginBottom: '15px' }}>
                                                <input value={Math.round(PopupOverDueAmount.penalty) + Math.round(PopupOverDueAmount.penaltyGst)} readOnly={true} type="number" className="form-control w-50" onChange={(e) => changeHandler(e, 'paymentAmount')} placeholder="" />
                                            </div>
                                        </div>
                                        <div className='col-sm-6 col-xs-12'>
                                            <h6 className=""> Nach Bounce </h6>
                                            <div className="" style={{ marginBottom: '15px' }}>
                                                <input value={Math.round(PopupOverDueAmount.nachBounce) + Math.round(PopupOverDueAmount.bounceGst)} readOnly={true} type="number" className="form-control w-50" onChange={(e) => changeHandler(e, 'paymentAmount')} placeholder="" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-sm-6 col-xs-12'>

                                            <h6 className=""> Type of Payment </h6>
                                            <div className="" style={{ marginBottom: '15px', }}>
                                                <select
                                                    style={{ width: '100%' }}
                                                    value={PopupData.PaymentType}
                                                    className=" formSelect "
                                                    onChange={(e) => changeHandler(e, 'paymentType')}
                                                >
                                                    <option value=''>Select Type</option>
                                                    <option value="partpayment">Part Payment</option>
                                                    <option value="fullpayment">Full Payment</option>
                                                </select>
                                            </div>

                                        </div>
                                        <div className='col-sm-6 col-xs-12'>
                                            <h6 className=""> Waived NACH Bounce  </h6>
                                            <div className="" style={{ marginBottom: '15px' }}>
                                                <input value={waivedAmount} type="number" className="form-control w-50" onChange={(e) => changeHandler(e, 'waivedAmount')} placeholder="Enter Waived Amount" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-sm-6 col-xs-12'>
                                            <h6 className=""> Waived Penalty </h6>
                                            <div className="" style={{ marginBottom: '15px' }}>
                                                <input value={waivedPenalty} type="number" className="form-control w-50" onChange={(e) => changeHandler(e, 'waivedPenalty')} placeholder="Enter Waived Amount" />
                                            </div>
                                        </div>
                                        <div className='col-sm-6 col-xs-12'>


                                            <h6 className=""> Enter Payable Amount </h6>
                                            <div className="" style={{ marginBottom: '15px' }}>
                                                <input value={PopupData.paymentAmount} readOnly={true} type="number" className="form-control w-50" onChange={(e) => changeHandler(e, 'paymentAmount')} placeholder="Enter Payable Amount" />
                                            </div>

                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-sm-6 col-xs-12'>

                                            <h6>Send To Debtor by the mode of :</h6>

                                            <div className="" style={{ marginBottom: '15px', display: 'flex' }}>


                                                <div class="form-check">
                                                    <input
                                                        class="form-check-input"
                                                        type="checkbox"
                                                        onChange={(e) => changeHandler(e, 'EMAIL')}
                                                        checked={PopupData.email}
                                                        id="emailCheckbox"

                                                    />&nbsp;
                                                    <label class="form-check-label" htmlFor="emailCheckbox">
                                                        MAIL
                                                    </label>
                                                </div>
                                                &nbsp; &nbsp;
                                                <div class="form-check">
                                                    <input
                                                        class="form-check-input"
                                                        type="checkbox"
                                                        onChange={(e) => changeHandler(e, 'SMS')}
                                                        checked={PopupData.sms}
                                                        id="smsCheckbox"

                                                    />&nbsp;
                                                    <label class="form-check-label" htmlFor="smsCheckbox">
                                                        SMS
                                                    </label>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div>

                                        {/* <h6 className=""> Enter Payable Amount </h6>
                                        <div className="" style={{ marginBottom: '15px' }}>
                                            <input value={PopupData.paymentAmount} readOnly={(PopupData.PaymentType === 'fullpayment') || (PopupData.PaymentType === '') ? true : false} type="number" className="form-control w-50" onChange={(e) => changeHandler(e, 'paymentAmount')} placeholder="Enter Payable Amount" />
                                        </div> */}
                                    </div>




                                    {paymentLink ?
                                        <>
                                            <h6>Link URL</h6>
                                            <div className="" style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '15px' }}>
                                                <div style={{ width: '60%' }}>
                                                    <input type="text" value={paymentLink} readOnly className="form-control" id='linkt' />
                                                </div>&nbsp;

                                                <button className="profile-save-btn display-inline" onClick={(e) => handleLink('copy')} style={{ marginTop: '2px' }}>Copy Link &nbsp;<i className='fa fa-clipboard'></i></button>
                                            </div>
                                        </>
                                        : ""}

                                </div>

                                <div className="text-center">
                                    {PopupData.paymentAmount !== '' ?
                                        <button
                                            className="btn btn-primary w-25 "
                                            data-bs-dismiss="modal"
                                            aria-label="Close"
                                            onClick={() => GeneratePaymentLink()}
                                            disabled={(PopupData.email || PopupData.sms) ? '' : "disabled"}
                                        >
                                            Generate Link
                                        </button>
                                        : ""}&nbsp;&nbsp;

                                    <button className='btn btn-primary w-25' onClick={() => calcamount()}>Calculate Amount</button>
                                </div>

                                {/* <div className='text-center'>         <button className='btn btn-primary' onClick={() => calcamount()}>Calculate Amount</button></div> */}

                            </div>

                        </div>
                    </div>
                </div >
                : <div className="popup-main small-popup container-fluid  dbl-border" style={{ top: '22%' }}>
                    <div className="row from-group" style={{ marginTop: '20px' }}>
                        <div className="col-sm-3 col-xs-12">
                        </div>
                        <div className="col-sm-6 col-xs-12">
                            <input className="form-control" value={confirmationText} readOnly id='linkt' />
                        </div>
                        <div className="col-sm-3 col-xs-12">
                        </div>
                    </div>
                    <div className="row from-group text-center">
                        <div className="col-xs-3 col-sm-3">
                        </div>
                        <div className="col-xs-3 col-sm-3">
                            <button className="profile-save-btn display-inline" onClick={(e) => handleLink('copy')}>Copy Link &nbsp;<i className='fa fa-clipboard'></i></button>
                        </div>
                        <div className="col-xs-3 col-sm-3">
                            <button className="profile-save-btn display-inline" onClick={(e) => handleLink('close')}>Close</button>
                        </div>
                        <div className="col-xs-3 col-sm-3">
                        </div>
                    </div>
                </div>
        }
    </div >
)

export default linkPopu;