import React, { Component } from 'react'
import SimilarprofileUi from '../../../presentationals/Admin/Configuration/ConfigurationSimilarProfile.component'
import localStorageUtil from '../../../../utils/localStorageUtil'


class Config extends Component {
    constructor(props) {
        super(props);
        this.state = {
            MSG: "",
            valueState: {
                one: false,
            }



        }
    }

    componentWillMount() {
        let valueState = Object.assign({}, this.state.valueState)
        var dataOne = localStorageUtil.getFromLocalStorage('similarOneValue');
        if (dataOne != null && dataOne != undefined) {
            valueState.one = dataOne
        }
    }
    btnHandler(count, type) {

        switch (type) {
            case 'Include':
                for (var i = 0; i < 10; i++) {
                    if (count == i) {
                        var bgcol = document.getElementById(i)
                        bgcol.style.backgroundColor = "#b3fd8b"
                        document.getElementById(i + "Exclude").style.backgroundColor = "#87cefa"
                    }
                }
                break;
            case 'Exclude':
                for (var i = 0; i < 10; i++) {
                    if (count == i) {
                        var bgcol = document.getElementById(i)
                        bgcol.style.backgroundColor = "#87cefa"
                        document.getElementById(i + "Exclude").style.backgroundColor = "#b3fd8b"
                        localStorageUtil.saveInLocalStorage('')
                    }
                } break;

            default:
                break;
        }
    }
    render() {
        const { admin } = this.props
        return (
            <div>
                <SimilarprofileUi
                    btnHandler={this.btnHandler.bind(this)}
                    sendHandler={this.sendHandler.bind(this)}
                    MSG={this.state.MSG} />


            </div>
        )
    }

    // changeConfig(e, type, buttonType) {
    //     let valueState = Object.assign({}, this.state.valueState)
    //     if (buttonType == 'one') {
    //         valueState.one

    //         localStorageUtil.saveInLocalStorage('similarOneValue', true)

    //     }
    //     this.setState({
    //         valueState
    //     })
    // }

    removePopup() {
        setTimeout(function () {
            this.setState({ MSG: false });
        }.bind(this), 3000)
    }
    sendHandler() {
        {
            this.setState({ MSG: "saved successfully" })
        }
        this.removePopup();
    }

}
export default Config;