import React from 'react';
import ReactTable from "react-table";
import { STRING } from '../../../../utils/Constant'
import DatePicker from 'react-datepicker';
import DotLoader from '../../Loader/layerLoader.component'
import Workbook from 'react-excel-workbook'
import { filterCaseInsensitive } from '../../../../utils/commonUtility'
import { ROLE } from '../../../../utils/Constant';

const PhocketCustomerDetails = ({ userDetailHandler, userViewHandler, dataFilter,
    forwordpage, backpage, allUserDetail, admin, loadarState, refreshpage, dateToShow,
    loanVarificationHandler, loanTXNHandler, getFilterValue, searchBymobile, recoveryComunicateHandler,
    FilteredData, getPageValue, pageIndexToShow, searchByAppNo, searchByemail, dateToSend, tableMaxPagination, tableMinPagination,
    paymentDeductHandler, nachTypeHandler, phocketTablePagination }) => (
    <div className="container-fluid">
        <div className="row">
            <h3 className="text-center blue-text">Incoming Payment</h3>
        </div>
        <div className="row">
            <div className="col-sm-2 col-xs-12">
                <label className="font-12px"><span className="fa fa-search" /> By Date</label>
                <DatePicker
                    selected={dateToShow}
                    value={dateToSend}
                    onChange={e => dataFilter(e, "Date")}
                    className="form-control"
                    dateFormat="DD-MM-YYYY"
                />
            </div>
            <div className="col-sm-2 col-xs-12">
                <label className="font-12px"><span className="fa fa-search" /> Application No.</label>
                <input type="number" className="form-control" value={searchByAppNo} onChange={e => dataFilter(e, "applicationNo")} />
            </div>
            <div className="col-sm-2 col-xs-12">
                <label className="font-12px"><span className="fa fa-search" /> Email ID</label>
                <input type="email" className="form-control" value={searchByemail} onChange={e => dataFilter(e, "email")} />
            </div>
            <div className="col-sm-2 col-xs-12">
                <label className="font-12px" ><span className="fa fa-search" /> Mobile No.</label>
                <input type="number" value={searchBymobile} onChange={e => dataFilter(e, "mobileNo")} className="form-control" />
            </div>
            <div className="col-sm-2 col-xs-12">
                <label className="font-12px" ><span className="fa fa-search" /> Nach Type</label>
                <select className='form-control' onChange={(e) => nachTypeHandler(e)}>
                    <option value=''>All</option>
                    <option value='razorpay'>Razorpay</option>
                    <option value='etech'>Etech</option>
                    <option value='manual enach'>Manual Enach</option>
                </select>
            </div>
            <div className="col-sm-2 col-xs-12">
                <Workbook filename="ApplicationStatus.xlsx" element={<button className="profile-save-btn " style={{marginTop:'22px'}}><span className="fa fa-download"></span>&nbsp;&nbsp;Excel</button>}>
                    <Workbook.Sheet data={allUserDetail} name="Sheet A">
                        <Workbook.Column label="Loan Id" value="applicationLoanId" />
                        <Workbook.Column label="Application Id" value="applicationId" />
                        <Workbook.Column label="UserName" value="userName" />
                        <Workbook.Column label="Contact" value="mobileNumber" />
                        <Workbook.Column label="Disburse Date" value="disburseDate" />
                        <Workbook.Column label="Payback Date" value="loanPaybackDate" />
                        <Workbook.Column label="Payback Amount" value="loanPaybackAmount" />
                        <Workbook.Column label="Loan Amount" value="loanAmount" />
                        <Workbook.Column label="Tenure" value="loanDuration" />
                    </Workbook.Sheet>
                </Workbook>
                &nbsp;
                {/* <button className="admin-pagination-btn" id="forword" onClick={e => refreshpage()}><span className="fa fa-refresh fa-spin" /></button>
                <button className="admin-pagination-btn" id="backword" onClick={e => backpage()}><span className="fa fa-chevron-left" /></button>
                <button className="admin-pagination-btn" id="forword" onClick={e => forwordpage()}><span className="fa fa-chevron-right" /></button> */}
                {/* <p>1-100</p> */}
            </div>
            <br/>
            <br/>
        </div>

        {/*  */}
        {loadarState === false?
        <div className="row text-center">
            {allUserDetail && allUserDetail.length > 0  ?
                <div className="overflow-x-auto">
                    <table className="phocket-table">
                        <thead>
                            <tr>
                                <th>Communicate</th>
                                <th>Application Id</th>
                                <th>Loan Id</th>
                                <th>Name</th>
                                <th>Mobile No.</th>
                                <th>Disburse Date</th>
                                <th>PayBack Date</th>
                                <th>Amount</th>
                                <th>Repayment Amount</th>
                                <th>Nach Status</th>
                                <th>Installment </th>
                                <th>Tenure</th>
                                <th>status</th>
                                {/* {admin.rolelist.indexOf(ROLE.ACTION_AND_DISBURSE) >= 0 ?
                                    <th>Deduct Payment</th>
                                    : ""}
                                <th>Verification</th>
                                <th>Schedule</th> */}

                            </tr>

                        </thead>
                        <tbody>
                            {allUserDetail.slice(tableMinPagination, tableMaxPagination).map((data, i) => {
                                return (
                                    <tr key={i} >
                                        <td>
                                            <button onClick={e => recoveryComunicateHandler(data.loanId)}><span className="fa fa-envelope-o" /></button>                                                <label htmlFor={"ignore_" + data.loanId}><span></span></label>
                                        </td>
                                        <td> {data.applicationId}</td>
                                        <td> {data.applicationLoanId}</td>
                                        <td style={{whiteSpace:'break-spaces'}}>  {data.userName}</td>
                                        <td> {data.mobileNumber}</td>
                                        <td> {data.disburseDate}</td>
                                        <td> {data.loanPaybackDate}</td>
                                        <td> {data.loanAmount}</td>
                                        <td> {data.loanPaybackAmount}</td>
                                        <td>  <div>{data.nachStatus == true ? 'Active' : 'Inactive'}</div> </td>
                                        <td> {data.loanEmi}</td>
                                        <td>{data.loanDuration} </td>
                                        <td>{data.followUpStatusCode}</td>
                                        {/* {admin.rolelist.indexOf(ROLE.ACTION_AND_DISBURSE) >= 0 ?
                                            <td>
                                                <button onClick={e => paymentDeductHandler(data)}><span className="fa fa-credit-card" /></button></td>
                                            : ""}
                                        <td> <button onClick={e => loanVarificationHandler(data.loanId)}><span className="fa fa-viacoin" /></button></td>
                                        <td><button onClick={e => loanTXNHandler(data.loanId)}><span className="fa fa-credit-card" /></button></td> */}
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
                :<div>No Data</div>}
            {allUserDetail ? allUserDetail.length > 0 && loadarState === false ?
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-2 col-xs-12 no-padding"> <button type="button" className="btn btn-default fa fa-chevron-left btn-lg btn-block mage" id="backword" onClick={e => backpage()}><span className="fa fa-chevron-left" /></button></div>
                        <div className="col-sm-3 col-xs-12 no-padding"><button type="button" className="btn btn-default fa fa-chevron-left btn-lg btn-block red  " onClick={e => phocketTablePagination(allUserDetail, 'back')}></button></div>
                        <div className="col-sm-2 col-xs-12  phoc" >{(tableMinPagination + 1) + ' - ' + (tableMaxPagination) + ' of ' + allUserDetail.length}</div>
                        <div className="col-sm-3 col-xs-12 no-padding" ><button type="button" className="btn btn-default fa fa-chevron-right btn-lg btn-block red" onClick={e => phocketTablePagination(allUserDetail, 'forward')}></button></div>
                        <div className="col-sm-2 col-xs-12 no-padding"> <button type="button" className="btn btn-default fa fa-chevron-right btn-lg btn-block mage" id="forword" onClick={e => forwordpage()}><span className="fa fa-chevron-right" /></button></div> </div>
                </div>
                : "" : ""}
        </div >
        :""}
        {loadarState == true ?
            <DotLoader />
            : ""}
    </div >
)

export default PhocketCustomerDetails;
