import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import RecoveryUi from '../../../presentationals/Admin/Recovery/Recovery.component'
import RecoveryCommunication from './RecoveryCommunication.container'
import {
  getRoleAssignView,
  getistByNumber,
  getlistByEmail,
  getlistByApplication,
  getEmiDuePaymentByWeek,
  saveLoanId,
  getEmiDuePaymentByDate,
  getlistByAppLoanId,
  saveCHequeNoticeApi,
  saveNachDataApi,
  saveRecoveryCommentAPI,
  deductUserPaymentApi,
  getSourceOfEnach,
  deductUserPaymentByTechProcess
} from '../AdminActionCreator.component'
import { STRING, ROLE } from '../../../../utils/Constant'
import { _preFormatDate } from '../../../../utils/validation'
import Popup from '../../../presentationals/Popup/Popup.component'
import { APIS } from '../../../../utils/api-factory'
import AmountDeductionPopup from '../../../presentationals/Admin/Recovery/GetAmountForDeduction.popup'
import ChequeNoticePopup from '../../../presentationals/Admin/Recovery/NoticeAndCheckDate.component'
import SourceOfEnachUi from '../../../presentationals/Admin/Recovery/SourceEnach.component'

let nachCheckbox = false

let addLoanId = false

let paginationNumber = 100

// let Sourcedata = ['new']
let emailCheck = []

class DueContainer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      emiId: '',
      popupState: false,
      popupStatus: '',
      recoveryPageState: true,
      allDetail: '',
      userDetail: '',
      followUpStatus: STRING.SELECT_ALL,
      forwordPageState: 1,
      loadarState: false,
      viewPopupDetail: '',
      logsUserId: '',
      dateToSend: '',
      actionButtonState: false,
      loadarState: false,
      FilteredData: [],
      pageIndexToShow: 0,
      dataForCommunication: '',
      chequeNoticePopup: false,
      chequeNoticeData: '',
      chequeNoticeDateToShow: '',
      chequeNoticeDate: '',
      chequeNoticeType: '',
      searchByAppNo: '',
      searchByemail: '',
      searchBymobile: '',
      disburseLoanId: '',
      tableMinPagination: 0,
      tableMaxPagination: paginationNumber,
      commentState: '',
      searchByNach: '',
      amountDeductionPopupState: false,
      loanIdForDeduction: '',
      amountForDeduction: '',
      SourceOfEnachUiState: false,
      source: '',
      userIdForSource: '',
      newData: '',
      startDate: '',
      // dateForDeduction:'',
      dateTechProcess: '',
      Sourcedata: [],
      nachType: ''
    }
  }
  nachTypeHandler(e) {
    this.setState({ nachType: e.target.value }, () => this.getLeads())
  }
  getDepartment() {
    getRoleAssignView('', callBack => {
      callBack.map((data, i) => {
        if (data.department.includes('Recovery Team')) {
          emailCheck.push(data.emailId)
        }
      })

    })
  }
  getLeads() {
    this.setState({ loadarState: true })
    this.props.getEmiDuePaymentByWeek(this.state.forwordPageState, 'vreedhi', this.state.nachType, callBack => {
      this.setState({ loadarState: false })
    })
  }

  componentDidMount() {
    this.getDepartment()
    this.getLeads()
  }

  render() {
    const { duePaymentEmi, admin, collectionType } = this.props
    if (admin.rolelist != undefined) {
      if (admin.rolelist.indexOf(ROLE.NACH_CHANGE) >= 0) {
        nachCheckbox = true
      }
      if (admin.rolelist.indexOf(ROLE.ADD_LOAN_ID) >= 0) {
        addLoanId = true
      }
    }
    let allAgentData = duePaymentEmi
    if (this.state.searchByNach) {
      allAgentData = allAgentData.filter(row => {
        if (row.nachStatus != null) {
          if (
            this.state.searchByNach == true ||
            this.state.searchByNach == 'true'
          ) {
            return row.nachStatus == true
          } else if (
            this.state.searchByNach == false ||
            this.state.searchByNach == 'false'
          ) {
            return row.nachStatus == false
          }
        }
      })
    }
    return (
      <div className='full-height'>
        {this.state.popupState == true ? (
          <Popup
            closePopup={this.closePopup.bind(this)}
            popupStatus={this.state.popupStatus}
          />
        ) : (
          ''
        )}
        {this.state.recoveryPageState == true ? (
          <RecoveryUi
            department={emailCheck}
            nachTypeHandler={this.nachTypeHandler.bind(this)}
            allUserDetail={allAgentData}
            forwordpage={this.forwordpage.bind(this)}
            backpage={this.backpage.bind(this)}
            dataFilter={this.dataFilter.bind(this)}
            loadarState={this.state.loadarState}
            refreshpage={this.refreshpage.bind(this)}
            getFilterValue={this.getFilterValue.bind(this)}
            FilteredData={this.state.FilteredData}
            getPageValue={this.getPageValue.bind(this)}
            pageIndexToShow={this.state.pageIndexToShow}
            recoveryComunicateHandler={this.recoveryComunicateHandler.bind(
              this
            )}
            chequeNoticeShowHandler={this.chequeNoticeShowHandler.bind(this)}
            ignoreHandler={this.ignoreHandler.bind(this)}
            searchByAppNo={this.state.searchByAppNo}
            searchByemail={this.state.searchByemail}
            searchBymobile={this.state.searchBymobile}
            nashHandler={this.nashHandler.bind(this)}
            nachCheckbox={nachCheckbox}
            addLoanId={addLoanId}
            admin={admin}
            disburseLoanId={this.disburseLoanId.bind(this)}
            saveDisburseLoanId={this.saveDisburseLoanId.bind(this)}
            phocketTablePagination={this.phocketTablePagination.bind(this)}
            tableMinPagination={this.state.tableMinPagination}
            tableMaxPagination={this.state.tableMaxPagination}
            userCommentHandler={this.userCommentHandler.bind(this)}
            userCommentChange={this.userCommentChange.bind(this)}
            paymentDeductHandler={this.paymentDeductHandler.bind(this)}
          />
        ) : (
          ''
        )}
        {this.state.communicationPageState == true ? (
          <RecoveryCommunication
            dataForCommunication={this.state.dataForCommunication}
            recoveryBackHandler={this.recoveryBackHandler.bind(this)}
            admin={admin}
            collectionType={collectionType}
          />
        ) : (
          ''
        )}
        {this.state.SourceOfEnachUiState == true ? (
          <SourceOfEnachUi
            Sourcedata={this.state.Sourcedata}
            close={this.close.bind(this)}
            handler={this.handler.bind(this)}
            sourceHandler={this.sourceHandler.bind(this)}
          />
        ) : (
          ''
        )}
        {this.state.chequeNoticePopup == true ? (
          <ChequeNoticePopup
            chequeNotice={this.chequeNotice.bind(this)}
            chequeNoticeHandler={this.chequeNoticeHandler.bind(this)}
            chequeNoticeDateToShow={this.state.chequeNoticeDateToShow}
            chequeNoticeDate={this.state.chequeNoticeDate}
            closeNoticePopup={this.closeNoticePopup.bind(this)}
          />
        ) : (
          ''
        )}
        {this.state.amountDeductionPopupState ? (
          <AmountDeductionPopup
            dateHandler={this.dateHandler.bind(this)}
            dateTechProcess={this.state.dateTechProcess}
            startDate={this.state.startDate}
            source={this.state.source}
            amountForDeduction={this.state.amountForDeduction}
            deductionAmountChange={this.deductionAmountChange.bind(this)}
            deductionAmountHandler={this.deductionAmountHandler.bind(this)}
            disabled={this.state.disabled}
          />
        ) : null}
      </div>
    )
  }

  close() {
    this.setState({
      SourceOfEnachUiState: false
    })
  }
  handler(e) {
    // console.log(e.target.value)
    this.setState({
      source: e.target.value
    })
  }
  sourceHandler(e) {
    // console.log(this.state.source)
    this.setState({
      SourceOfEnachUiState: false,
      amountDeductionPopupState: true
    })
  }
  dateHandler(e) {
    this.setState({
      startDate: e,
      dateTechProcess: _preFormatDate(e)
    })
  }

  paymentDeductHandler(data) {
    this.setState({
      emiId: data.emiId
    })
    getSourceOfEnach(data.userId, callBack => {
      if (
        callBack != '' &&
        callBack != null &&
        callBack != '' &&
        Object.keys(callBack).length > 0
      ) {
        this.setState({
          SourceOfEnachUiState: true,
          // amountDeductionPopupState: true,
          userIdForSource: data.userId,
          loanIdForDeduction: data.loanId,
          amountForDeduction: data.loanPaybackAmount
        })
      } else {
        this.setState({
          popupState: true,
          popupStatus: 'No Mandate Registered'
        })
        this.removePopup()
      }
      this.setState({
        Sourcedata: callBack
      })
    })
    // this.setState({
    //     amountDeductionPopupState: true,
    //     loanIdForDeduction: data.loanId,
    //     amountForDeduction: data.loanPaybackAmount
    // })
    // if (
    //   this.state.Sourcedata != '' &&
    //   this.state.Sourcedata != null &&
    //   this.state.Sourcedata != ''
    // ) {
    //   this.setState({
    //     SourceOfEnachUiState: true,
    //     // amountDeductionPopupState: true,
    //     userIdForSource: data.userId,
    //     loanIdForDeduction: data.loanId,
    //     amountForDeduction: data.loanPaybackAmount
    //   })
    // } else {
    //   console.log('no mandate')
    //   this.setState({
    //     popupState: true,
    //     popupStatus: 'No Mandate Registered'
    //   })
    //   this.removePopup()
    // }
  }

  deductionAmountChange(e) {
    this.setState({
      amountForDeduction: e.target.value
    })
  }

  deductionAmountHandler(type) {
    if (type == 'yes') {
      this.setState({
        disabled: true
      })
      if (this.state.source != 'techProcess') {
        deductUserPaymentApi(
          this.state.loanIdForDeduction,
          this.state.amountForDeduction,
          this.state.emiId,
          callBack => {
            if (callBack == 'success') {
              this.setState({
                popupState: true,
                popupStatus: 'success',
                amountDeductionPopupState: false,
                disabled: false
              })
              this.removePopup()
            } else {
              this.setState({
                popupState: true,
                popupStatus: 'Please try again',
                amountDeductionPopupState: false,
                disabled: false
              })
              this.removePopup()
            }
          }
        )
      } else {
        deductUserPaymentByTechProcess(
          this.state.loanIdForDeduction,
          this.state.amountForDeduction,
          this.state.dateTechProcess,
          callBack => {
            if (callBack == 'success') {
              this.setState({
                popupState: true,
                popupStatus: 'success',
                amountDeductionPopupState: false,
                disabled: false
              })
              this.removePopup()
            } else {
              this.setState({
                popupState: true,
                popupStatus: 'Please try again',
                amountDeductionPopupState: false,
                disabled: false
              })
              this.removePopup()
            }
          }
        )
      }
    } else if (type == 'no') {
      this.setState({ amountDeductionPopupState: false })
    }
  }

  userCommentChange(e) {
    this.setState({ commentState: e.target.value })
  }

  userCommentHandler(data) {
    const { admin } = this.props
    saveRecoveryCommentAPI(data, this.state.commentState, admin, '', callBack => {
      if (callBack == 'success') {
        this.setState({
          popupState: true,
          popupStatus: 'Comment Saved Successfully',
          commentState: ''
        })
        this.removePopup()
      } else {
        this.setState({
          popupState: true,
          popupStatus: 'Please try again',
          commentState: ''
        })
        this.removePopup()
      }
    })
  }

  phocketTablePagination(data, type) {
    if (type == 'back') {
      if (this.state.tableMinPagination >= paginationNumber) {
        this.setState({
          tableMinPagination: this.state.tableMinPagination - paginationNumber,
          tableMaxPagination: this.state.tableMaxPagination - paginationNumber
        })
      }
    } else if (type == 'forward') {
      if (this.state.tableMaxPagination < data.length) {
        this.setState({
          tableMaxPagination: this.state.tableMaxPagination + paginationNumber,
          tableMinPagination: this.state.tableMinPagination + paginationNumber
        })
      }
    }
  }

  disburseLoanId(e) {
    this.setState({ disburseLoanId: e.target.value })
  }

  saveDisburseLoanId(data) {
    if (this.state.disburseLoanId != '') {
      saveLoanId(this.state.disburseLoanId, data.userId, callBack => {
        if (callBack == 'success') {
          this.setState({
            popupState: true,
            popupStatus: 'Loan Id save successfully',
            disburseLoanId: ''
          })
          this.removePopup()
        } else {
          this.setState({
            popupState: true,
            popupStatus: 'Loan Id not save'
          })
          this.removePopup()
        }
      })
    } else {
      this.setState({
        popupState: true,
        popupStatus: 'Please enter loan id',
        disburseLoanId: ''
      })
      this.removePopup()
    }
  }

  nashHandler(e, incomingData) {
    const { admin } = this.props
    saveNachDataApi(e.target.checked, incomingData.userId, admin, callBack => {
      this.recoveryBackHandler()
    })
  }

  removePopup() {
    setTimeout(
      function () {
        this.setState({ popupState: false })
      }.bind(this),
      5000
    )
  }

  closePopup() {
    this.setState({ popupState: false })
  }

  ignoreHandler(e, loanId) {
    fetch(APIS.SAVE_EXTENSION_TIME + loanId + '&status=' + e.target.checked)
      .then(res => res.text())
      .then(res => {
        this.getLeads()
      })
  }

  chequeNotice(e, inpType) {
    if (inpType == 'chequeNotice') {
      this.setState({
        chequeNoticeType: e.target.value
      })
    } else if (inpType == 'chequeNoticeDate') {
      this.setState({
        chequeNoticeDateToShow: e,
        chequeNoticeDate: _preFormatDate(e)
      })
    }
  }

  chequeNoticeHandler() {
    const { admin } = this.props
    if (
      this.state.chequeNoticeType != '' &&
      this.state.chequeNoticeDate != ''
    ) {
      saveCHequeNoticeApi(
        this.state.chequeNoticeData.loanId,
        this.state.chequeNoticeType,
        this.state.chequeNoticeDate,
        admin,
        this.state.chequeNoticeData.emiId,
        callBack => {
          if (callBack == 'success') {
            this.setState({
              chequeNoticePopup: false,
              chequeNoticeType: '',
              chequeNoticeDate: '',
              chequeNoticeDateToShow: '',
              popupState: true,
              popupStatus: 'Data saved successfully'
            })
            this.removePopup()
          }
        }
      )
    } else {
      this.setState({
        popupState: true,
        popupStatus: 'Please select TYPE & DATE'
      })
      this.removePopup()
    }
  }

  closeNoticePopup() {
    this.setState({
      chequeNoticePopup: false
    })
  }

  chequeNoticeShowHandler(data) {
    this.setState({
      chequeNoticePopup: true,
      chequeNoticeData: data
    })
  }

  recoveryComunicateHandler(data) {
    this.setState({
      recoveryPageState: false,
      communicationPageState: true,
      dataForCommunication: data
    })
  }
  recoveryBackHandler() {
    const { admin } = this.props
    this.setState({
      recoveryPageState: true,
      communicationPageState: false
    })
    if (this.state.searchByAppNo != '') {
      this.props.getlistByApplication(
        this.state.searchByAppNo,
        1,
        '',
        admin,
        true,
        callBack => {
          this.setState({
            loadarState: false
          })
        }
      )
    } else if (this.state.searchByemail != '') {
      this.props.getlistByEmail(
        this.state.searchByemail,
        1,
        '',
        admin,
        callBack => {
          this.setState({
            loadarState: false
          })
        }
      )
    } else if (this.state.searchBymobile != '') {
      this.props.getistByNumber(
        this.state.searchBymobile,
        1,
        '',
        admin,
        callBack => {
          this.setState({
            loadarState: false
          })
        }
      )
    } else {
      this.getLeads()
    }
  }

  getFilterValue(filtered) {
    this.setState({ FilteredData: filtered })
  }

  getPageValue(pageIndex) {
    this.setState({ pageIndexToShow: pageIndex })
  }

  refreshpage() {
    this.getLeads()
  }

  dataFilter(e, type) {
    const { admin } = this.props
    if (type == 'applicationNo') {
      this.setState({ searchByAppNo: e.target.value })
      if (e.target.value.length > 4) {
        this.setState({
          loadarState: true
        })
        this.props.getlistByApplication(
          e.target.value,
          1,
          '',
          admin,
          true,
          callBack => {
            this.setState({
              loadarState: false
            })
          }
        )
      }
    } else if (type == 'email') {
      this.setState({ searchByemail: e.target.value })
      if (e.target.value.length > 2) {
        this.setState({
          loadarState: true
        })
        this.props.getlistByEmail(e.target.value, 1, '', admin, callBack => {
          this.setState({
            loadarState: false
          })
        })
      }
    } else if (type == 'mobileNo') {
      this.setState({ searchBymobile: e.target.value })
      if (e.target.value.length > 9) {
        this.setState({
          loadarState: true
        })
        this.props.getistByNumber(e.target.value, 1, '', admin, callBack => {
          this.setState({
            loadarState: false
          })
        })
      }
    } else if (type == 'nach') {
      this.setState({ searchByNach: e.target.value })
    }
    if (e.target.value == '') {
      this.getLeads()
    }
  }
  forwordpage() {
    this.setState(
      {
        forwordPageState: this.state.forwordPageState + 1,
        pageIndexToShow: 0
      },
      () => this.getLeads()
    )
  }
  backpage() {
    if (this.state.forwordPageState > 1) {
      this.setState(
        {
          forwordPageState: this.state.forwordPageState - 1,
          pageIndexToShow: 0
        },
        () => this.getLeads()
      )
    }
  }
  componentDidUpdate() {
    const { duePaymentEmi, admin } = this.props
    if (duePaymentEmi.length > 0) {
      if (document.getElementById('forword') != null)
        document.getElementById('forword').disabled = false
    } else {
      if (document.getElementById('forword') != null)
        document.getElementById('forword').disabled = true
    }
  }
}

const mapStateToProps = ({ duePaymentEmi }) => {
  return {
    duePaymentEmi: duePaymentEmi
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getistByNumber,
      getlistByEmail,
      getlistByApplication,
      getEmiDuePaymentByWeek,
      getEmiDuePaymentByDate,
      getlistByAppLoanId
    },
    dispatch
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(DueContainer)
