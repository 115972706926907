import React from 'react'
import CibilSection from './Scoring.container'
import DBRsection from './DBR.container'
import { getallDBRdataApi } from '../../Admin/AdminActionCreator.component'
class ScoringSection extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            flagData: {

            }
        }
    }
    getData() {

    }
    componentDidMount() {
        this.getFlagData()
    }
    getFlagData() {
        const { allDetail } = this.props
        let flagData = Object.assign({}, this.state.flagData)

        getallDBRdataApi(allDetail.userId, callback => {
            console.log(callback)
            if (callback !== '' && callback !== null && callback.decision !== '' && callback.decision !== undefined) {
                this.setState({ flagData: callback })

            }
        })


    }

    render() {
        const { allDetail, getCreditPopupState, creditScoringPopup, admin } = this.props
        return (
            <div className="container-fluid " style={{ marginTop: '20px' }}>
                <div className="card-credit-score">
                    <div className="row">
                        <div className='col-sm-4 col-xs-12'>
                            <label>Our Decision</label>
                            <div style={{ fontSize: '22px', color: (this.state.flagData.decision == 'For Quality Check' || this.state.flagData.decision == 'For Quality Check') ? '#9d8420' : this.state.flagData.decision == 'Rejected' ? '#fee5e3' : this.state.flagData.decision == 'Approved' ? '#4CC78C' : "",  }}><b>{this.state.flagData.decision}</b></div>
                        </div>
                        <div className='col-sm-4 col-xs-12'>
                            {this.state.flagData.decision === 'Approved' && this.state.flagData.approvedAmount
                                ?
                                <div className='text-center' style={{ marginBottom: '15px' }}>
                                    <label style={{ color: "" }}><b>{this.state.flagData.approvedAmount}</b> </label>
                                    {this.state.flagData.message ?
                                        <div style={{ fontSize: '18px', }} className='text-center'><b>{this.state.flagData.message}</b> </div>

                                        : ""}
                                </div>
                                : ""}
                        </div>
                        <div className='col-sm-4 col-xs-12 ' style={{ textAlign: 'right' }}>
                            <label>DBR : &nbsp;&nbsp;<b style={{ fontSize: '18px' }}>{this.state.flagData.dbrpercentage}%</b></label>
                            <br />
                            <label> Scorecard: &nbsp;&nbsp;<b style={{ fontSize: '18px' }}>{this.state.flagData.scoring}</b></label>

                        </div>

                    </div>
                </div>
                <div className='row' >
                    
                    <div className='col-sm-6 col-xs-12'>
                    <div className='credit-second-box'>

                        <CibilSection
                            getData={this.getData.bind(this)}
                            allDetail={allDetail}
                            getCreditPopupState={getCreditPopupState}
                            creditScoringPopup={creditScoringPopup}
                            admin={admin}
                            flagData={this.state.flagData}
                        />
                        </div>
                    </div>
                    <div className='col-sm-6 col-xs-12'>
                    <div className='credit-second-box'>

                        <DBRsection allDetail={allDetail} getFlagData={this.getFlagData.bind(this)} />
</div>
                    </div>
                    {/* <hr/> */}
                </div>
            </div>
        )
    }
}
export default ScoringSection