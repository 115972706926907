import React from 'react';

const billdeskPopup = ({ cancelLoanConfirm, confirmationText, userData }) => (
    <div className="background-blur details-new">
        <div className="popup-main small-popup container-fluid max-width-500px dbl-border" style={{ marginTop: '10%' }}>
            <div className="row from-group">
                <div className="col-xs-12">
                    <h4 className="text-center">{confirmationText}</h4>
                </div>
            </div>

            <h4 className='text-center'>Application Id : {userData.applicationId}</h4>
            <h4 className='text-center'>Amount  : {userData.emiAmt}</h4>

            <div className="row from-group text-center">
                <div className="col-xs-6">
                    <button className="btn btn-success" style={{ marginTop: '20px' }} onClick={(e) => cancelLoanConfirm('yes')}>YES</button>
                </div>
                <div className="col-xs-6">
                    <button className="btn btn-danger" style={{ marginTop: '20px' }} onClick={(e) => cancelLoanConfirm('no')}>NO</button>
                </div>
            </div>
        </div>
    </div>
);

export default billdeskPopup;
