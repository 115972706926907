import React from 'react'
import ReactHtmlParser from 'react-html-parser';
import DotLoader from '../../Loader/layerLoader.component'

const Activity = ({ admin, activityLoaderState, loginURL, activityData, showMailPopup,
    sendHandler, pageViewState }) => (
        <div className="crm-activity-view activity-animate-left">
            <div className="btn-group">
                <button id="sendMail" onClick={(e) => sendHandler('mail')} className="active">Mail</button>
                <button id="sendActivity" onClick={(e) => sendHandler('activity')}>Activity</button>
            </div>
            {admin.isDeleteShow == true ?
                <a href={loginURL} target="_blank" className="crm-activity-login">LOGIN</a>
                : activityLoaderState == true ?
                    <DotLoader />

                    : activityData != '' ?
                        <div>
                            {pageViewState == 'mail' ? activityData.mailList.length > 0 ?
                                activityData.mailList.map((actData, i) => {
                                    return (
                                        <div key={i} className="activity-list">
                                            <div className="" onClick={e => showMailPopup(e, actData)}>
                                                <span className="activity-absolute activity-date">{actData.date}</span>
                                                <span className="activity-absolute activity-from"><b>From- </b>{actData.from}</span>
                                                <span className="activity-subject">{actData.subject}</span>
                                                <span className="activity-absolute activity-to"><b>To- </b>{actData.to != null ? actData.to.split("<")[0] : ""}</span>
                                            </div>
                                        </div>
                                    )
                                })
                                : "" : ""}
                            {pageViewState == 'activity' ? activityData.activityList != null &&activityData.activityList != '' && activityData.activityList.length > 0 ?
                                activityData.activityList.map((actData, i) => {
                                    return (
                                        <div key={i} className="activity-list">
                                            <div className="" onClick={e => showMailPopup(e, actData.value)}>
                                                <span className="activity-absolute activity-date">{actData.value.date}</span>
                                                <span className="activity-absolute activity-from"><b>From- </b>{actData.value.from}</span>
                                                <span className="activity-subject">{ReactHtmlParser(actData.value.body)}</span>
                                                <span className="activity-absolute activity-to"><b>To- </b>{actData.value.to != null ? actData.value.to.split("<")[0] : ""}</span>
                                            </div>
                                        </div>
                                    )
                                }) : "" : ""}
                        </div>
                        : ""}
        </div>
    )

export default Activity;