import React from "react";
import { Totalleads } from "../../../Data/Totalleads";

import {
  ResponsiveContainer,
  YAxis,
  XAxis,
  CartesianGrid,
  AreaChart,
  Area,
  Bar,
  BarChart,
  Tooltip,
} from "recharts";
import { CustomTooltip } from "../../../areacharttooltip";

function DailyLeadRecieved({ data }) {
  return (
    <>
      <div className="bg-white p-4 shadow totalcount" style={{ padding: '20px' }}>
        <div className="d-flex flex-column justify-content-around">
          <div className="d-flex flex-row justify-content-between">
            <div className="d-flex align-item-center">
              <h5 className="totalcount1 card-heading">
                <b>Daily lead Received </b>
              </h5>
            </div>
          </div>

          <div className="totalcount5">
            <ResponsiveContainer width={"100%"} height={'90%'} >
              <AreaChart
              //  height={200}
                data={data}
                margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
              >
                <defs>
                  <linearGradient
                    id="colorPv2"
                    x1="0"
                    y1="0"
                    x2="0"
                    y2="1"
                  >
                    <stop
                      offset="5%"
                      stopColor="#A4C1FE"
                      stopOpacity={0.9}
                    />
                    <stop
                      offset="95%"
                      stopColor="#fff"
                      stopOpacity={1}
                    />
                  </linearGradient>
                </defs>
                {/* <XAxis tick={{fontSize:'10px',color:'#000'}} tickLine={false} axisLine={false}  allowDataOverflow={false}    interval={0}     /> */}
                <YAxis axisLine={false} tickLine={false} />
                <CartesianGrid vertical={false} strokeDasharray="8 3" />
                <Tooltip
                  content={<CustomTooltip />}
                  // cursor={{ fill: "transparent" }}
              
                  wrapperStyle={{ outline: "none" }}
                />
                <Area
                  type="natural"
                  dataKey="count"
                  stroke="#5B8FF9"
                  fillOpacity={0.3}
                  fill="url(#colorPv2)"
                  strokeWidth={3}
                />
              </AreaChart>
              
            </ResponsiveContainer>
          
          </div>
        </div>
      </div>
    </>
  );
}

export default DailyLeadRecieved;
