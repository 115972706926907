import React from 'react'

const ProductConfigUi = ({ changeHandler, submit, productConfig, validationMsg,editHadler }) => (
    <div className='container'>
        
        <h3 className='text-center' style={{ marginTop: '20px', marginBottom: '20px' }}><b>Product Configuration</b></h3>
        <div className='row'>
            <div className="col-sm-3 col-xs-12" style={{ marginTop: '20px' }}>
                <label className="font-12px">ROI Reflect Date</label>
                <input type="date" placeholder='ROI Reflect Date' value={productConfig.roiReflectDate} className="form-control" onChange={e => changeHandler(e, 'roiReflectDate')} />
                <div style={{ marginBottom: '10px' }}> {validationMsg && validationMsg.field === 'roiReflectDate' ? <span className='text-danger'>{validationMsg.msg}</span> : ""}</div>
            </div>
            <div className="col-sm-3 col-xs-12" style={{ marginTop: '20px' }}>
                <label className="font-12px">Is Previously Affect</label>
                <select className='form-control' onChange={(e) => changeHandler(e, 'previouslyAffect')} value={productConfig.previouslyAffect}>
                    <option value=''>Select Value</option>
                    <option value={true}>Yes</option>
                    <option value={false}>No</option>

                </select>
                <div style={{ marginBottom: '10px' }}> {validationMsg && validationMsg.field === 'previouslyAffect' ? <span className='text-danger'>{validationMsg.msg}</span> : ""}</div>
            </div>
            <div className="col-sm-3 col-xs-12" style={{ marginTop: '20px' }}>
                <label className="font-12px">Stampduty Charges</label>
                <input type="text" placeholder='Stampduty Charge' value={productConfig.stampDutyCharge} className="form-control" onChange={e => changeHandler(e, 'stampDutyCharge')} />
                <div style={{ marginBottom: '10px' }}> {validationMsg && validationMsg.field === 'stampDutyCharge' ? <span className='text-danger'>{validationMsg.msg}</span> : ""}</div>
            </div>
            <div className="col-sm-3 col-xs-12" style={{ marginTop: '20px' }}>
                <label className="font-12px">Penalty Interest</label>
                <input type="text" placeholder='Penalty Interest' value={productConfig.penaltyInterest} className="form-control" onChange={e => changeHandler(e, 'penaltyInterest')} />
                <div style={{ marginBottom: '10px' }}> {validationMsg && validationMsg.field === 'penaltyInterest' ? <span className='text-danger'>{validationMsg.msg}</span> : ""}</div>
            </div>
        </div>
        <div className='row'>
            <div className="col-sm-3 col-xs-12" style={{ marginTop: '20px' }}>
                <label className="font-12px">Penalty Amount</label>
                <input type="text" placeholder='Penalty Amount' value={productConfig.penaltyAmount} className="form-control" onChange={e => changeHandler(e, 'penaltyAmount')} />
                <div style={{ marginBottom: '10px' }}> {validationMsg && validationMsg.field === 'penaltyAmount' ? <span className='text-danger'>{validationMsg.msg}</span> : ""}</div>
            </div>
            <div className="col-sm-3 col-xs-12" style={{ marginTop: '20px' }}>
                <label className="font-12px">Normal Interest</label>
                <input type="text" placeholder='Normal Interest' value={productConfig.normalInterest} className="form-control" onChange={e => changeHandler(e, 'normalInterest')} />
                <div style={{ marginBottom: '10px' }}> {validationMsg && validationMsg.field === 'normalInterest' ? <span className='text-danger'>{validationMsg.msg}</span> : ""}</div>
            </div>
            <div className="col-sm-3 col-xs-12" style={{ marginTop: '20px' }}>
                <label className="font-12px">EMI Penalty Interest</label>
                <input type="text" placeholder='EMI Penalty Interest' value={productConfig.emiPenaltyInterest} className="form-control" onChange={e => changeHandler(e, 'emiPenaltyInterest')} />
                <div style={{ marginBottom: '10px' }}> {validationMsg && validationMsg.field === 'emiPenaltyInterest' ? <span className='text-danger'>{validationMsg.msg}</span> : ""}</div>
            </div>
            <div className="col-sm-3 col-xs-12" style={{ marginTop: '20px' }}>
                <label className="font-12px">gstInterest</label>
                <input type="text" placeholder='GST Interest' value={productConfig.gstInterest} className="form-control" onChange={e => changeHandler(e, 'gstInterest')} />
                <div style={{ marginBottom: '10px' }}> {validationMsg && validationMsg.field === 'gstInterest' ? <span className='text-danger'>{validationMsg.msg}</span> : ""}</div>
            </div>
        </div>
        <div className='row'>
            <div className="col-sm-3 col-xs-12" style={{ marginTop: '20px' }}>
                <label className="font-12px">Cheque Bounce Charge</label>
                <input type="text" placeholder='Cheque Bounce Charge' value={productConfig.chequeBounceCharge} className="form-control" onChange={e => changeHandler(e, 'chequeBounceCharge')} />
                <div style={{ marginBottom: '10px' }}> {validationMsg && validationMsg.field === 'chequeBounceCharge' ? <span className='text-danger'>{validationMsg.msg}</span> : ""}</div>
            </div>
            <div className="col-sm-3 col-xs-12" style={{ marginTop: '20px' }}>
                <label className="font-12px">Mandate Bounce Charge</label>
                <input type="text" placeholder='Mandate Bounce Charge' value={productConfig.mandateBounceCharge} className="form-control" onChange={e => changeHandler(e, 'mandateBounceCharge')} />
                <div style={{ marginBottom: '10px' }}> {validationMsg && validationMsg.field === 'mandateBounceCharge' ? <span className='text-danger'>{validationMsg.msg}</span> : ""}</div>
            </div>
            {/* <div className="col-sm-3 col-xs-12" style={{ marginTop: '20px' }}>
                <label className="font-12px">EMI Penalty Interest</label>
                <input type="text" placeholder='Name' value={productConfig.emiPenaltyInterest} className="form-control" onChange={e => changeHandler(e, 'emiPenaltyInterest')} />
                <div style={{ marginBottom: '10px' }}> {validationMsg && validationMsg.field === 'emiPenaltyInterest' ? <span className='text-danger'>{validationMsg.msg}</span> : ""}</div>
            </div> */}
            <div className="col-sm-3 col-xs-12" style={{ marginTop: '20px' }}>
                <label className="font-12px">Interest Type</label>
                <select className='form-control' onChange={(e) => changeHandler(e, 'interestType')} value={productConfig.interestType}>
                    <option value=''>Select Value</option>
                    <option value='Flat'>Flat</option>
                    <option value='Reducing'>Reducing</option>
                    

                </select>
                <div style={{ marginBottom: '10px' }}> {validationMsg && validationMsg.field === 'interestType' ? <span className='text-danger'>{validationMsg.msg}</span> : ""}</div>
            </div>
            <div className="col-sm-3 col-xs-12" style={{ marginTop: '20px' }}>
                <label className="font-12px">Is GST Included?</label>
                <select className='form-control' onChange={(e) => changeHandler(e, 'gstIncluded')} value={productConfig.gstIncluded}>
                    <option value=''>Select Value</option>
                    <option value={true}>Yes</option>
                    <option value={false}>No</option>

                </select>
                <div style={{ marginBottom: '10px' }}> {validationMsg && validationMsg.field === 'gstIncluded' ? <span className='text-danger'>{validationMsg.msg}</span> : ""}</div>
            </div>
        </div>
        <div className='row'>


            <div className="col-sm-3 col-xs-12" style={{ marginTop: '20px' }}>
                <label className="font-12px">EMI Type</label>
                <select className='form-control' onChange={(e) => changeHandler(e, 'emiType')} value={productConfig.emiType}>
                    <option value=''>Select Value</option>
                    <option value='Weekly'>Weekly</option>
                    <option value='Monthly'>Monthly</option>
                    <option value='Fortnightly'>Fortnightly</option>

                </select>
                <div style={{ marginBottom: '10px' }}> {validationMsg && validationMsg.field === 'emiType' ? <span className='text-danger'>{validationMsg.msg}</span> : ""}</div>
            </div>
            {/* <div className="col-sm-3 col-xs-12" style={{ marginTop: '20px' }}>
                <label className="font-12px">Processing Type</label>
                <select className='form-control' onChange={(e) => changeHandler(e, 'processingType')} value={productConfig.processingType}>
                    <option value=''>Select Value</option>
                    <option value='fixed'>Fixed</option>
                    <option value='percentage'>Percentage</option>

                </select>
                <div style={{ marginBottom: '10px' }}> {validationMsg && validationMsg.field === 'emiType' ? <span className='text-danger'>{validationMsg.msg}</span> : ""}</div>
            </div> */}
        </div>
        <div className='row text-center'>
            <button className='profile-save-btn' onClick={() => submit()}>Save</button>
        </div>


    </div>
)

export default ProductConfigUi