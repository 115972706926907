import React from 'react'
import ReactTable from 'react-table'
import DatePicker from 'react-datepicker'
import { filterCaseInsensitive } from '../../../../utils/commonUtility'
import Loader from '../../../presentationals/Loader/layerLoader.component'

const IBR = ({
  statusFilterHandler,
  callbackStatusChange,
  loaderState,
  IBDData,
  tableKeyHandler,
  tableKey,
  getFilterValue,
  FilteredData,
  getPageValue,
  pageIndexToShow,
  menuExtensionResponse,
  getDataByNumber,
  dateFilter,
  searchByDate,
  tableMinPagination,
  tableMaxPagination,
  phocketTablePagination
}) => (
  <div className='container-fluid full-height'>
    <h3 className='text-center blue-text'>Inbound Report</h3>
    <div className='row form-group'>
      <div className='col-sm-3 col-xs-12'></div>
      <div className='col-sm-3 col-xs-12'>
        <DatePicker
          value={searchByDate}
          onChange={e => dateFilter(e)}
          className='form-control'
          dateFormat='DD/MM/YYYY'
          placeholderText='Select Date'
        />
      </div>
      <div className='col-sm-3 col-xs-12'>
        <select className='form-control' onChange={e => statusFilterHandler(e)}>
          <option value=''>ALL</option>
          <option value='Not Connected'>
            Not Connected / Callback requests
          </option>
          <option value='Missed'>Missed Calls</option>
          <option value='Callback Requests Ambassador'>
            Callback Requests for Ambassador
          </option>
        </select>
      </div>
      <div className='col-sm-3 col-xs-12'></div>
    </div>
    {loaderState ? <Loader /> : ''}
    {IBDData != null && IBDData != '' && loaderState != true ? (
      <div className='row'>
        <div className='col-xs-12'>
          <h3 className='text-center blue-text'>{tableKey}</h3>
      
          <div className='overflow-auto'>
            <table className='phocket-table'>
              <thead>

                <tr>
                  <td>Mobile Number</td>
                  <td>Call Date</td>
                  <td>Call Time</td>
                  <td>Reason for Call / Menu Extension</td>
                  <td>Call Transfer Status</td>
                  <td>Call Status</td>
                  <td>Callback Status</td>


                </tr>

              </thead>
              <tbody>
                {IBDData && IBDData.slice(tableMinPagination, tableMaxPagination).map((data, i) => {
                  return (
                    <tr key={i}>
                      <td> <div>
                        {data.userID != '' &&
                          data.userID != null ? (
                          <a
                            className='pointer blue-text'
                            onClick={e => getDataByNumber(data)}
                          >
                            {data.callerNumber}
                          </a>
                        ) : (
                          data.callerNumber
                        )}
                      </div>
                      </td>
                      <td> {data.callDate}</td>
                      <td> {data.callTime}</td>
                      <td> <div>
                        {menuExtensionResponse != '' && data.menuExtension != null && (Object.keys(menuExtensionResponse).indexOf(
                          data.menuExtension
                        ) >= 0 || Object.keys(menuExtensionResponse).indexOf(
                          data.menuExtension.split(',None')[0]
                        ) >= 0)

                          ? Object.keys(menuExtensionResponse).map((extRes, i) => {
                            return (
                              <div key={i}>
                                {data.menuExtension != null &&
                                  (data.menuExtension == extRes ||
                                    data.menuExtension.split(',None')[0] ==
                                    extRes) ? (
                                  <span
                                    className='pointer blue-text'
                                    data-toggle='tooltip'
                                    data-placement='left'
                                    title={menuExtensionResponse[extRes]}
                                  >
                                    {data.menuExtension}
                                  </span>
                                ) : null}
                              </div>
                            )
                          })
                          : data.menuExtension}

                      </div></td>
                      <td>{data.callTransferStatus}</td>
                      <td>{data.callStatus}</td>
                      <td> <div>
                        {menuExtensionResponse != '' && data.menuExtension != null && (Object.keys(menuExtensionResponse).indexOf(
                          data.menuExtension
                        ) >= 0 || Object.keys(menuExtensionResponse).indexOf(
                          data.menuExtension.split(',None')[0]
                        ) >= 0)
                          ? Object.keys(menuExtensionResponse).map((extRes, i) => {
                            return (
                              <div key={i}>
                                {data.menuExtension != null &&
                                  (data.menuExtension == extRes ||
                                    data.menuExtension.split(',None')[0] ==
                                    extRes) ? (
                                  <label
                                    className='switch'
                                    onClick={e =>
                                      callbackStatusChange(e, data)
                                    }
                                  >
                                    <input
                                      type='checkbox'
                                      checked={data.callbackStatus}
                                    />
                                    <span className='slider round'></span>
                                  </label>
                                ) : null}
                              </div>
                            )
                          })
                          : ''}
                      </div></td>




                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
          {!loaderState ?
            <div className="container-fluid">
              <div className="row">
                <div className="col-sm-4 col-xs-12 no-padding"><button type="button" className="btn btn-default fa fa-chevron-left btn-lg btn-block red  " onClick={e => phocketTablePagination(IBDData, 'back')}></button></div>
                <div className="col-sm-4 col-xs-12  phoc text-center" >{(tableMinPagination + 1) + ' - ' + (tableMaxPagination) + ' of ' + IBDData.length}</div>
                <div className="col-sm-4 col-xs-12 no-padding" ><button type="button" className="btn btn-default fa fa-chevron-right btn-lg btn-block red" onClick={e => phocketTablePagination(IBDData, 'forward')}></button></div>
              </div>
            </div>
            : ""}
        </div>

      </div>
    ) : loaderState != true ? (
      <h3 className='text-center'>No Data</h3>
    ) : (
      ''
    )}
  </div>
)

export default IBR
