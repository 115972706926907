import React from 'react'

const RecoveryMsg = ({ recoveryMsgChange, recoveryMsgHandler, msgTempleteClick, msgBody, collectionType }) => (
    <div className="container-fluid">
        <div className="row">
            <div className="col-sm-5 col-xs-12">
                <h3 className=" text-center blue-text">Templates</h3>
                {collectionType == 'recovery' ?
                    <ol>
                        <li>
                            <input type="radio" name="communicationMsg" onClick={e => msgTempleteClick('NachBounced')} /> &nbsp;&nbsp;
                            <span><strong>Nach Bounced</strong></span>
                        </li>
                        {/* <li>
                            <input type="radio" name="communicationMsg" onClick={e => msgTempleteClick('ChequeBounced')} /> &nbsp;&nbsp;
                            <span><strong>Cheque Bounced</strong></span>
                        </li>
                        <li>
                            <input type="radio" name="communicationMsg" onClick={e => msgTempleteClick('ChequeDepositAlert')} /> &nbsp;&nbsp;
                            <span><strong>Cheque Deposit Alert</strong></span>
                        </li>
                        <li>
                            <input type="radio" name="communicationMsg" onClick={e => msgTempleteClick('LegalNotice')} /> &nbsp;&nbsp;
                            <span><strong>Legal Notice</strong></span>
                        </li> */}
                    </ol>
                    : collectionType == 'disburse' ?
                        <ol>
                            <li>
                                <input type="radio" name="communicationMsg" onClick={e => msgTempleteClick('WithNach')} /> &nbsp;&nbsp;
                                <span><strong>With Nach</strong></span>
                            </li>
                            <li>
                                <input type="radio" name="communicationMsg" onClick={e => msgTempleteClick('WithoutNach')} /> &nbsp;&nbsp;
                                <span><strong>Without Nach</strong></span>
                            </li>
                            <li>
                                <input type="radio" name="communicationMsg" onClick={e => msgTempleteClick('EmiWithNach')} /> &nbsp;&nbsp;
                                <span><strong>EMI With Nach</strong></span>
                            </li>
                            <li>
                                <input type="radio" name="communicationMsg" onClick={e => msgTempleteClick('EmiWithoutNach')} /> &nbsp;&nbsp;
                                <span><strong>EMI Without Nach</strong></span>
                            </li>
                        </ol>
                        : null}
            </div>
            <div className="col-sm-7 col-xs-12">
                <div className="col-xs-12">
                    <h3 className="text-center blue-text">Message {msgBody.length > 0 ? msgBody.length : ""}</h3>
                </div>
                <div className="col-xs-12">
                    <textarea className="form-control" rows="7" placeholder="Max character is 160" value={msgBody} onChange={e => recoveryMsgChange(e)} readOnly></textarea>
                </div>
                <div className="col-xs-12">
                    <button className="profile-save-btn center-block" onClick={e => recoveryMsgHandler()}>SEND</button>
                </div>
            </div>
        </div>
    </div>
)

export default RecoveryMsg;