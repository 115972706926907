export const filterCaseInsensitive = (filter, row) => {
    const id = filter.pivotId || filter.id;
    if (row[id] !== null) {
        if (isNaN(row[id])) {
            return (
                row[id] !== undefined ?
                    String(row[id].toLowerCase()).startsWith(filter.value.toLowerCase())
                    :
                    true
            );
        } else {
            return (
                row[id] !== undefined ?
                    String(row[id]).startsWith(filter.value)
                    :
                    true
            );
        }
    }
};

export const csvReader = (data) => {
    let csvData = {}
    let csvDataArray = []
    for (let i = 1; i <= data.length - 1; i++) {
        let csvRow = data[i]
        if (csvRow.length === data[0].length) {
            let csvData = Object.assign({}, csvData)
            csvRow.map((rowValue, j) => {
                csvData[data[0][j]] = rowValue
            })
            csvDataArray.push(csvData)
        }
    }
    return csvDataArray;
};

export const getRendomNumber = (length) => {
    var result = '';
    var characters = '0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}