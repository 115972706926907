import React from 'react';

const BulkCSV = ({ uploadCSVFile, dataForMapping, csvTableMapping, updateData,
    mappedData, deletearrayitem }) => (
        <div className="container-fluid">
            <div className="row">
                <h3 className="text-center blue-text">Update with CSV</h3>
            </div>

            <div className="row form-group">
                <div className="col-xs-12">
                    <input type="file" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" onChange={e => uploadCSVFile(e)} />
                </div>
            </div>
            {dataForMapping != null && dataForMapping != '' ?
                <div className="row">
                    <div className="col-xs-6">
                        <table className="text-center loan-detail-table">
                            <thead>
                                <tr>
                                    <th>CSV</th>
                                    <th></th>
                                    {/* <th></th> */}
                                </tr>
                            </thead>
                            <tbody>
                                {dataForMapping.CSV.map((csvData,i) => {
                                    return (
                                        <tr key={i}>
                                            <td>{csvData}</td>
                                            <td>
                                                <select className="form-control" onChange={e => csvTableMapping(e, csvData)}>
                                                    <optgroup className="display-none">
                                                        <option value="">Select One</option>
                                                    </optgroup>
                                                    <optgroup label="select field name">
                                                        {dataForMapping.TABLE.map((tableData,i) => {
                                                            return (
                                                                <option key={i} value={tableData}>{tableData}</option>
                                                            )
                                                        })}
                                                    </optgroup>
                                                </select>
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                    <div className="col-xs-6">
                        <table className="text-center loan-detail-table">
                            <thead>
                                <tr>
                                    <th>DB</th>
                                    <th>CSV</th>
                                    <th>Remove</th>
                                </tr>
                            </thead>
                            <tbody>
                                {mappedData.length > 0 ? mappedData.map((tableData,i) => {
                                    return (
                                        <tr key={i}>
                                            <td>{Object.keys(tableData)}</td>
                                            <td>{tableData[Object.keys(tableData)]}</td>
                                            <td><i className="fa fa-trash" onClick={e => deletearrayitem(tableData)} /></td>
                                        </tr>
                                    )
                                }) : ""}
                            </tbody>
                        </table>
                    </div>
                    <div className="col-xs-12">
                        <button className="profile-save-btn center-block" onClick={e => updateData()}>SAVE</button>
                    </div>
                </div>
                : ""}
        </div>
    )

export default BulkCSV;
