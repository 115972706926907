import React from 'react'
import { getRepeatAutomationByUserId } from '../AdminActionCreator.component'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
class Repeat extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            automationData: "",
            loaderState: false
        }
    }
    componentDidMount() {
        // console.log(this.props)
        this.getDetails()
    }
    getDetails() {
        const { allDetail } = this.props
        this.setState({ loaderState: true })
        getRepeatAutomationByUserId(allDetail.userId, allDetail.loanId, callback => {
            this.setState({ automationData: callback, loaderState: false })
        })
    }
    render() {
        return (
            <div className='container-fluid'>
               
                    {/* <Loader />
                    : ""} */}
                <h3 className='heading-analysis'>Repeat Automation Decision </h3>

                <div className='row'>

                    <div className='col-xs-12 col-sm-12 income'>
                    {!this.state.loaderState ?
                        <table className='income-table'>
                            <thead>
                                <tr>
                                    <th style={{ padding: '40px' }}>Decision:</th>
                                    <td>{this.state.automationData ? this.state.automationData.decision : ""}</td>
                                </tr>
                                <tr>
                                    <th style={{ padding: '40px' }}>Logic:</th>
                                    <td>{this.state.automationData ? this.state.automationData.logic : ""}</td>
                                </tr>
                                <tr>
                                    <th style={{ padding: '40px' }}>Method:</th>
                                    <td>{this.state.automationData ? this.state.automationData.method : ""}</td>
                                </tr>
                                {this.state.automationData !== '' && this.state.automationData.amountApproved !== undefined && this.state.automationData.amountApproved !== '' ?
                                    <tr>
                                        <th style={{ padding: '40px' }}>Approved Amount:</th>
                                        <td>{this.state.automationData ? this.state.automationData.amountApproved : ""}</td>
                                    </tr>
                                    : ""}
                            </thead>
                        </table>
                        :""}
                        {this.state.loaderState ?
                        <table className='income-table'>
                            <thead>
                                <tr>
                                    <th style={{ padding: '40px' }}>Decision:</th>
                                    <td><SkeletonTheme baseColor="transparent" highlightColor="#85a6e1">
                                        <Skeleton height={40} /></SkeletonTheme></td>
                                </tr>
                                <tr>
                                    <th style={{ padding: '40px' }}>Logic:</th>
                                    <td><SkeletonTheme baseColor="transparent" highlightColor="#85a6e1">
                                        <Skeleton height={40} /></SkeletonTheme></td>
                                </tr>
                                <tr>
                                    <th style={{ padding: '40px' }}>Method:</th>
                                    <td><SkeletonTheme baseColor="transparent" highlightColor="#85a6e1">
                                        <Skeleton height={40} /></SkeletonTheme></td>
                                </tr>
                                <tr>
                                    <th style={{ padding: '40px' }}>Approved Amount:</th>
                                    <td><SkeletonTheme baseColor="transparent" highlightColor="#85a6e1">
                                        <Skeleton height={40} /></SkeletonTheme></td>
                                </tr>
                            </thead>
                        </table>
                        :""}

                    </div>
                    <div className='col-xs-12 col-sm-4 '>

                    </div>

                </div>
            </div>
        )
    }
}
export default Repeat